import i18next from 'i18next';
import executeIfFunction from 'execute-if-function';
import { assign, forEach, isObject, isString } from 'lodash';
import { info, warn } from 'service/log/log';

export default {
	KEY_SEPARATOR: '__',

	/**
	 * Translation function which returns translated string or function
	 * that returns a translated string if it isn't ready yet
	 * @method lang.get
	 * @param  {String} key
	 * @param  {String} [developmentString]
	 * @param  {Object} [renderVariables]
	 * @return {String|Function}
	 */

	get (key, developmentString = ' ', renderVariables) {
		this._construct({
			rawKey: key,
			key: this._replaceDots(key),
			variables: renderVariables
		});

		return this._translate(this.key, developmentString) ||
			this._translate.bind(this, this.key, developmentString);
	},

	_construct (params) {
		assign(this, params);
		return this;
	},

	_translate (entry, devString) {
		let text;

		if (!i18next.isInitialized()) {
			return;
		}

		if (isString(entry)) {
			const key = i18next.exists(this.rawKey) ? this.rawKey : entry;
			text = i18next.t(key);

			if (text.indexOf(this.KEY_SEPARATOR) > -1) {
				text = i18next.t(devString);
			}

			this._logWarnings(text, devString, key);

			isObject(this.variables) && forEach(this.variables, (val, k) => {
				text = text.replace(`{${executeIfFunction(k)}}`, val);
			});
		}

		return text;
	},

	_replaceDots (str) {
		return isString(str) ? str.replace(/\./g, this.KEY_SEPARATOR) : str;
	},

	_logWarnings (text, devString, key) {
		if (text.indexOf(this.KEY_SEPARATOR) > -1) {

			if (text.indexOf(this.KEY_SEPARATOR) > -1) {
				warn('not translated:`', text, `\`, replacing with dev string: \`${devString}\``);

			} else {
				info(`found translation for \`${devString}\` but the actual key is \`${key}\``);
			}

		} else if (!i18next.exists(key)) {
			warn(`%c ${key}`, 'font-weight: bold; color: #000; font-size: 110%', ': no translation for the key');
		}
	}
};
