import { Model } from 'backbone';
import UploadedFileModel from 'repo/assessment-instances/uploaded-file';

const { getDownloadURL, getFileDate, getFileName, getFileSize } = UploadedFileModel.prototype;

export default Model.extend({
	idAttribute: 'uploadFileId',

	initialize (params) {
		if (params) {
			this.assessmentInstanceId = params.assessmentInstanceId;
			this.assessmentInstanceCode = params.assessmentInstanceCode;
		}
	},

	url () {
		return this.isNew() ?
			'backend-commons/assessmentinstance/rest/upload-file/' :
			`backend-commons/assessmentinstance/rest/upload-file/${this.get(this.idAttribute)}`;
	},

	getId () {
		return this.get(this.idAttribute);
	},

	getDownloadURL,
	getFileName,
	getFileSize,
	getFileDate
});
