import SharedView from './shared';
import { isNaN, isUndefined } from 'lodash';
import { numericOptions } from '../shared/options';

export default SharedView.extend({
	type: 'NumericSlider',

	options () {
		return numericOptions({
			connect: false,
			displayValues: this.displayValues(),
			getUrl: this.model.mediaManager.getUrl,
			start: this.model.value() || 0,
			...this.model.attributes
		});
	},

	changeValue ({ decrease } = { decrease: false }) {
		return () => {
			const value = +this.slider.noUiSlider.get();
			const newValue = decrease ? value - this.opts.step : value + this.opts.step;

			this.slider.noUiSlider.set(newValue);
		};
	},

	onRender () {
		this.slider.noUiSlider.on('update', (values, handle) => {
			this.setAriaValue({ value: parseFloat(values[handle]) });
		});

		this.model.get('answerVisible') && this.renderLabel(parseFloat(this.model.value()));

		this.slider.noUiSlider.on('set', (values, handle) => {
			this.model.setValue(values[handle]);
			this.handleAnswered();
		});
	},

	renderLabel (selectedValue) {
		const value = parseFloat(selectedValue);

		if (!isNaN(value) && !isUndefined(value)) {
			this.$answer.html(this.answer(value));
			this.$slider.removeClass('unanswered');
			return;
		}
		this.$answer.html(this.answer(this.labels.none));
		this.$slider.addClass('unanswered');
	}
});
