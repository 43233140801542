import { constant } from 'lodash';
import t from 'service/lang/translate';

export const elementClassName = function (suffix) {
	return `${this.COMPONENT_NAME}__${suffix}`;
};

export const FORMATS = {
	year: constant('y'),
	month: constant('MMMM y'),
	week: () => `'${t('agenda.labels.week')}' W`,
	day: constant('MMM d y'),
	shortDayName: constant('ccc d')
};

export const generateUrls = (basicUrl) => [
	`${basicUrl}`,
	`treatment/:treatmentId/${basicUrl}`,
	`respondent/:respondentId/${basicUrl}`,
	`${basicUrl}?start=:datetime`,
	`treatment/:treatmentId/${basicUrl}?start=:datetime`,
	`respondent/:respondentId/${basicUrl}?start=:datetime`,
	`${basicUrl}?referral=:referral`,
	`treatment/:treatmentId/${basicUrl}?referral=:referral`,
	`respondent/:respondentId/${basicUrl}?referral=:referral`,
	`${basicUrl}?start=:datetime&referral=:referral`,
	`treatment/:treatmentId/${basicUrl}?start=:datetime&referral=:referral`,
	`respondent/:respondentId/${basicUrl}?start=:datetime&referral=:referral`
];
