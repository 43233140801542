import repository from 'repository';
import prefixify from '../__helpers/prefixify';
import { set } from '../__helpers/mutations';
import { exact } from '../__helpers/getters';
import { RESPONDENT } from 'store/respondents/respondent';
import { TREATMENT } from 'store/treatments/rp-treatment';

const prefix = prefixify('respondentExercises');

const DATA = 'data';
const SIZE = 'size';

const INIT = 'init';

const SET = 'set';

export const RESPONDENT_EXERCISES = {
	DATA: prefix(DATA),
	SIZE: prefix(SIZE),

	INIT: prefix(INIT)
};

export default {
	namespaced: true,

	state: {
		[DATA]: []
	},

	getters: {
		...exact([DATA]),
		[SIZE]: (state) => state[DATA].length
	},

	mutations: {
		[SET]: set()
	},

	actions: {
		[INIT]: async ({ commit, rootGetters }) => {
			const exercises = await repository.getExercises({
				respondentId: rootGetters[RESPONDENT.RESPONDENT_ID],
				treatmentId: rootGetters[TREATMENT.ID]
			});
			commit(SET, exercises);
		}

	}
};
