var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    [
      _vm._l(_vm.objVal, function(row, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass: "edit-object__row edit-object__row--edit"
          },
          [
            _c("p", { staticClass: "array-row__index" }, [
              _vm._v("\n\t\t\t" + _vm._s(index) + "\n\t\t")
            ]),
            _vm._v(" "),
            _vm._l([0, 1], function(i) {
              return _c("input", {
                key: i,
                attrs: {
                  type: "number",
                  readonly: _vm.readonly,
                  min: _vm.matrixMin[i],
                  max: _vm.matrixMax[i]
                },
                domProps: { value: row[i] },
                on: {
                  blur: function($event) {
                    return _vm.onMatrixValueChange(index, i, $event)
                  }
                }
              })
            }),
            _vm._v(" "),
            !_vm.readonly
              ? _c("button", {
                  staticClass: "edit-object__button--remove",
                  on: {
                    click: function($event) {
                      return _vm.removeMatrixRow(index)
                    }
                  }
                })
              : _vm._e()
          ],
          2
        )
      }),
      _vm._v(" "),
      _c("li", { staticClass: "edit-object__row edit-object__row--add" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.newMatrixRow[0],
              expression: "newMatrixRow[0]"
            }
          ],
          attrs: { type: "number", readonly: _vm.readonly },
          domProps: { value: _vm.newMatrixRow[0] },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.newMatrixRow, 0, $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.newMatrixRow[1],
              expression: "newMatrixRow[1]"
            }
          ],
          attrs: { type: "number", readonly: _vm.readonly },
          domProps: { value: _vm.newMatrixRow[1] },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.newMatrixRow, 1, $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        !_vm.readonly
          ? _c("button", {
              staticClass: "edit-object__button--add",
              on: { click: _vm.addMatrixRow }
            })
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }