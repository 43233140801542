import repository from 'repository';
import prefixify from '../__helpers/prefixify';
import { exact } from '../__helpers/getters';
import { set } from '../__helpers/mutations';

const prefix = prefixify('respondentContentPackageElement');

const DATA = 'data';
const INIT = 'init';

const SET = 'set';

export const RESPONDENT_CONTENT_PACKAGE_ELEMENT = {
	DATA: prefix(DATA),

	INIT: prefix(INIT)
};

export default {
	namespaced: true,

	state: {
		[DATA]: {}
	},

	getters: {
		...exact([DATA])
	},

	mutations: {
		[SET]: set()
	},

	actions: {
		[INIT]: async ({ commit }, { contentPackageId, elementId }) => {
			const contentPackageElement = await repository.contentPackageElement({
				contentPackageId,
				elementId
			});

			commit(SET, contentPackageElement);
		}
	}
};
