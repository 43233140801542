import { GET, PUT } from 'service/ajax/ajax';
import urls from './rp-urls';

export default {
	/**
	 * @function consentInstances
	 * @returns {object} - Promise.
	 * @example
	 * 		repository.consentInstances();
	 */
	consentInstances: ({ treatmentId }) => GET({ url: urls.instances({ treatmentId }) }),

	/**
	 * @function allConsentInstances
	 * @returns {object} - Promise.
	 * @example
	 * 		repository.allConsentInstances();
	 */
	allConsentInstances: () => GET({
		url: urls.allInstances()
	}),

	/**
	 * @function consentInstance
	 * @param {number} consentInstanceId - Consent instance ID.
	 * @returns {object}                 - Promise.
	 * @example
	 * 		repository.consentInstance(14);
	 */
	consentInstance: (consentInstanceId) => GET({ url: urls.instance(consentInstanceId) }),

	/**
	 * @function consentInstanceOptions
	 * @param {number} consentInstanceId - Consent instance ID.
	 * @returns {object}                 - Promise.
	 * @example
	 * 		repository.consentInstanceOptions(14);
	 */
	consentInstanceOptions: (consentInstanceId) => GET({ url: urls.options(consentInstanceId) }),

	/**
	 * @function submitConsentInstance
	 * @param {object} consentInstance - Consent instance.
	 * @returns {object}               - Promise.
	 * @example
	 * 		repository.submitConsentInstance(consentInstance);
	 */
	submitConsentInstance: (consentInstance) => PUT({
		url: urls.submit(consentInstance.id),
		data: consentInstance.answers
	})
};
