import Vue from 'vue';
import { clinicianName, respondentName } from 'service/display-name/display-name';
import cardUrl from 'cwcardurl';
import { constant, get, isEmpty } from 'lodash';

export const DEFAULT_CLINICIAN_CARD = 'clinician-profile';
export const DEFAULT_RESPONDENT_CARD = 'respondent-status';

export const UserLink = Vue.component('userLink', {
	props: {
		clinician: {
			type: Object,
			default: constant({})
		},
		respondent: {
			type: Object,
			default: constant({})
		},
		card: {
			type: String,
			default: ''
		}
	},

	computed: {
		userName: ({ clinician, respondent }) => {
			if (!isEmpty(clinician)) {
				return clinicianName(clinician, { preventEscape: true });

			} else if (!isEmpty(respondent)) {
				return respondentName(respondent, { preventEscape: true });
			}
			return '';
		},

		clinicianId: ({ clinician }) => get(
			clinician,
			'administratorId',
			get(clinician, 'id')
		),

		respondentId: ({ respondent }) => get(
			respondent,
			'respondentId',
			get(respondent, 'id')
		),

		url ({ card, clinician, clinicianId, respondent, respondentId }) {
			if (!isEmpty(clinician)) {
				return cardUrl.buildUrl(card || DEFAULT_CLINICIAN_CARD, {
					clinicianId
				});

			} else if (!isEmpty(respondent)) {
				return cardUrl.buildUrl(card || DEFAULT_RESPONDENT_CARD, {
					respondentId
				});
			}
			return '';
		}
	},

	template: `<a :href="url">{{ userName }}</a>`
});

export default ({ clinician, respondent, card }) => new Vue({
	el: document.createElement('div'),
	data: { clinician, respondent, card },
	template: '<user-link :clinician="clinician" :respondent="respondent" :card="card" />'
});
