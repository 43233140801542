<template>
	<time-content
		v-if="content"
		:format="column.dateFormat"
		:time="content"
		:timezone="timezone"
	/>
	<span v-else>{{emptyChar}}</span>
</template>

<script>
import TimeContent from 'components/time-content';
import { RESPONDENT } from 'store/respondents/respondent';
import TableComponentMixin from './_mixin';

export default {
	components: {
		TimeContent
	},

	mixins: [
		TableComponentMixin
	],

	computed: {
		timezone: ({ column, $store }) => {
			const getterName = {
				clinician: 'userTimezone',
				respondent: RESPONDENT.TIMEZONE,
				default: 'userTimezone'
			}[column.timezone || 'default'];

			return $store.getters[getterName];
		}
	},

	methods: {
		sortValue: ({ itemValue }) => itemValue
	}
};
</script>
