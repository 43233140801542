import MultipleEntities from '../shared/multiple-entities';
import Timezone from './entity';
import urls from './urls';

/**
 * Timezones Entity
 * @class Timezones
 * @extends MultipleEntities
 */

const TimezonesEntity = MultipleEntities.extend({
	model: Timezone,
	urls,
	idName: 'timezoneId',
	eventNamespace: 'timezone',

	/**
	 * Get all Timezone
	 * @method getAll
	 * @return {Object} new Timezone Collection
	 * @example
	 *        Repository.Timezone.getAll();
	 */
	getAll: function () {
		return this.retrieve('allTimezones');
	}
});

export default new TimezonesEntity();
