var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.attributeValues.length
    ? _c(
        "form",
        {
          staticClass: "form-view",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          }
        },
        [
          _vm._l(_vm.treatmentAttributes, function(item) {
            return _c("edit-attribute", {
              key: item.id,
              attrs: { attr: item },
              on: {
                input: function($event) {
                  return _vm.$v.$touch()
                }
              },
              model: {
                value: _vm.values[item.id],
                callback: function($$v) {
                  _vm.$set(_vm.values, item.id, $$v)
                },
                expression: "values[item.id]"
              }
            })
          }),
          _vm._v(" "),
          _c(
            "button-group",
            [
              _c("submit-button", {
                attrs: { label: "Save", "can-submit": _vm.canSubmit }
              }),
              _vm._v(" "),
              _c("plain-button", {
                attrs: { "on-click": _vm.reset, label: "Reset" }
              })
            ],
            1
          )
        ],
        2
      )
    : _c("p", [
        _vm._v("\n\t" + _vm._s(_vm.t("No attributes available")) + "\n")
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }