<template>
	<div class="circles-infobox">
		<span class="circles-infobox__text">
			{{t('You are identified in the circles as')}}
			<strong class="circles-infobox__nickname">{{nickname}}</strong>
			{{t('You\'re using the avatar')}}
			<avatar
				:name="avatarName"
				:color="avatarColour"
				classes="circles-infobox__avatar"
			/>
		</span>
		<div class="circles-infobox__link-container">
			<a
				class="circles-infobox__link"
				:href="profileUrl"
			>{{t('Modify your settings')}}</a>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import avatarNickname from '../../components/avatar-nickname';
import enableCirclesCheck from '../../helpers/enable-circles-check';
import cardUrl from 'core/engine/card/services/card-url';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints';
import treatmentContextParam from 'modules/treatments/util/treatment-context-param';

export default {
	acl: [{
		op: READ,
		checkpoint: CLINICIAN.CIRCLES.CIRCLES
	}],
	features: enableCirclesCheck,
	instant: true,
	components: { ...avatarNickname },
	computed: {
		...mapState('circles/profile', ['avatarColour', 'avatarName', 'nickname']),
		profileUrl: ({ $store }) => $store.getters['userType'] === 'respondent' ?
			cardUrl.buildUrl('respondent-my-profile', treatmentContextParam()) :
			cardUrl.buildUrl('my-profile')
	}
};
</script>
