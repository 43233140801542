var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tree-view__tree-add-item tree-add-item" }, [
    _c(
      "button",
      {
        ref: _vm.path,
        staticClass: "tree-item__button tree-add-item__action",
        on: { click: _vm.show }
      },
      [
        _c("icon", { attrs: { name: "tree-view" } }),
        _vm._v(" "),
        _c("span", { staticClass: "tree-item__button-title" }, [
          _vm._v(_vm._s(_vm.buttonTitle))
        ])
      ],
      1
    ),
    _vm._v(" "),
    _vm.visible
      ? _c(
          "ul",
          {
            directives: [
              {
                name: "on-click-outside",
                rawName: "v-on-click-outside",
                value: _vm.hide,
                expression: "hide"
              }
            ],
            staticClass: "tree-add-item__list"
          },
          _vm._l(_vm.blueprints(), function(item) {
            return _c(
              "li",
              {
                key: _vm.getName(item),
                staticClass: "tree-add-item__item",
                class: _vm.itemClassName(item)
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "tree-add-item__item-action",
                    on: {
                      click: function($event) {
                        return _vm.create(item)
                      }
                    }
                  },
                  [
                    _c("icon", {
                      staticClass: "tree-add-item__item-icon",
                      attrs: { name: _vm.iconName(item) }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "tree-add-item__item-text" }, [
                      _vm._v(_vm._s(_vm.getName(item)))
                    ])
                  ],
                  1
                )
              ]
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }