var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.containerClassName }, [
    _c(
      "button",
      {
        directives: [{ name: "translate", rawName: "v-translate" }],
        class: _vm.buttonClassName,
        attrs: { disabled: !_vm.canSubmit, type: "submit" },
        on: {
          click: function($event) {
            return _vm.$emit("click")
          }
        }
      },
      [_vm._v(_vm._s(_vm.label))]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }