import MultipleEntities from '../shared/multiple-entities';
import CircleCandidate from './candidate';
import urls from './urls';
import getTotalResults from 'service/search/get-total-results';

const CircleCandidates = new (MultipleEntities.extend({
	model: CircleCandidate,
	urls,
	eventNamespace: 'circle-candidate',

	list ({ circleId, start, limit }) {
		const list = this.retrieve('candidates', { circleId, start, limit });

		list.then((response, status, xhrObj) => {
			list.total = getTotalResults({
				xhrObj,
				url: this.url
			});
		});

		list.more = () => {
			const more = start + limit;
			const newCandidates = this.retrieve('candidates', { circleId, start: more, limit });

			newCandidates.then((response) => {
				list.add(response);
			});

			return newCandidates;
		};

		list.all = () => {
			const allCandidates = this.retrieve('candidates');

			allCandidates.then((response) => {
				list.add(response);
			});

			return allCandidates;
		};

		return list;
	}
}));

export { CircleCandidates };
