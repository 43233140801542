import $ from 'jquery';
import button from 'button';
import { Model } from 'backbone';
import sanitize from 'service/sanitize-string/sanitize-string';
import executeIfFunction from 'execute-if-function';
import { EMPTY_CHAR } from 'lib/chars';
import { assign, forEach, isFunction, isNil, isObject, isString, isUndefined } from 'lodash';
import celllabel from './cell-label';
import t from 'translate';

export default function (params) {
	const { columns, get, tr, actions, deleteFilter } = params;
	let item = params.item;
	const $tr = $(tr);
	const noClick = !!params.noClick(item);
	const removeRow = () => {
		tr.parentNode.removeChild(tr);
	};

	const set = function (item, key, value) {
		item[key] = value;
		return item[key];
	};

	const checkIfEmpty = function (params) {
		const nonEmptyParams = params !== '' && !isNil(params);

		if (isString(params) || !isObject(actions)) {
			return nonEmptyParams ? params : EMPTY_CHAR;
		}

		return params;
	};

	const getValue = (key) => item instanceof Model ?
		item.get(key) :
		get(item, key);

	const mountDelete = () => {
		celllabel(tr, '');
		const $actions = $('<td />').addClass('cwtable__row-actions').appendTo(tr);

		if (!deleteFilter || deleteFilter(item)) {
			const deleteButtonCfg = {
				tagName: 'button',
				icon: 'delete',
				preventCwbutton: true,
				css: 'delete-button__container',
				buttonClass: 'delete-button__action',
				parent: $actions[0],
				title: t('Remove item')
			};

			if (isFunction(actions['delete'])) {
				button(deleteButtonCfg, (event, btn) => {
					event.preventDefault();
					event.stopPropagation();
					$tr.addClass('deleting');
					btn.disable();
					actions['delete'].call(this, item, {
						cancel () {
							btn.enable();
							$tr.removeClass('deleting');
						},
						done: removeRow
					});
				});
			}
		}
	};

	assign(this, {
		replaceData: (data) => {
			item = data;
		},

		render: () => {
			const className = (col) =>  col.className || col.css;

			forEach(columns, (col) => {
				if (!col || executeIfFunction(col.skip)) {
					return;
				}
				const value = getValue(col.key);

				const $td = $('<td />').addClass(className(col)).appendTo(tr);

				if (isFunction(col.render)) {
					const opts = {
						$td,
						$tr,
						td: $td[0],
						value,
						col,
						item,
						tr,
						set (value) {
							set(this.item, this.col.key, value);
						}
					};
					const rendered = executeIfFunction(col.render.call(opts, value, opts));

					!isUndefined(rendered) && $td.html(sanitize(checkIfEmpty(rendered)));

				} else {
					$td.html(sanitize(checkIfEmpty(value)));
				}
				celllabel($td[0], col.label, className(col));
			});

			if (actions) {
				mountDelete();
			}

			noClick && $tr.addClass('no-click');
		}
	});
}
