import Vue from 'vue';
import { className } from './_helpers';

const COMPONENT_NAME = 'button-group';

export default Vue.component(COMPONENT_NAME, {
	props: {
		classPrefix: {
			type: String,
			default: ''
		}
	},
	template: `
		<div :class="containerClassName">
			<slot></slot>
		</div>
	`,
	computed: {
		containerClassName () {
			return this.className('buttons-container');
		}
	},

	methods: {
		className: className(COMPONENT_NAME)
	}
});
