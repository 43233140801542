import { assign } from 'lodash';

export default function (value) {
	assign(this, {
		value,
		getRawValue: () => value,
		getBoolean: () => !!value,
		getNumber: () => parseFloat(value),
		getString: () => `${value}`,
		toString: () => `{String:${this.getString()}}`
	});
}
