import { definitions } from './definitions';
import { assign, constant, forEach, includes, isArray } from 'lodash';

export default function (basicDef) {
	const definition = isArray(basicDef) && basicDef[0].type ? basicDef[0] : basicDef;
	let type = definition.type;

	if (includes([undefined, 'Question', 'Section', 'Dimension', 'Number'], type)) {
		type = 'assignment';
	}

	const elements = [];

	forEach(definition.elements, (element) => {
		forEach(definitions, (def) => {
			if (includes(def.list, element.type)) {
				def.handle({ element, elements });
				return false;
			}
		});
	});

	assign(this, { definition, elements, type }, {
		getType: () => type,
		getClass: constant('Expression'),
		getArguments: () => elements,
		getValueOfUndefinedElements: () => definition.valueOfUndefinedElements,

		getInvolvedQuestionCodesMap: (questionMap = {}, externals) => {
			forEach(elements, (element) => {
				element.getInvolvedQuestionCodesMap(questionMap, externals);
			});

			return questionMap;
		}
	});
}
