var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal-dialog", {
    attrs: { show: _vm.show, "on-close": _vm.onClose, loading: _vm.loading },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [
            _c(
              "span",
              { directives: [{ name: "translate", rawName: "v-translate" }] },
              [_vm._v(_vm._s(_vm.title))]
            )
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c("edit-goal", {
              attrs: {
                goal: _vm.goal,
                treatments: _vm.treatments,
                categories: _vm.categories,
                "on-submit": _vm.onSubmit,
                "on-cancel": _vm.onClose
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }