export default ({ layout, $container, runtime }) => {
	let hasParentLayout = false;

	for (
		let parentSection = runtime.getSection();
		parentSection;
		parentSection = parentSection.getSection()
	) {
		if (parentSection.getLayout().type) {
			hasParentLayout = true;
			break;
		}
	}

	const unit = {
		absolute: 'px',
		relative: '%'
	}[layout.unit];

	$container.css({
		'margin-bottom': layout.marginHeight + unit,
		'margin-top': layout.marginHeight + unit,
		'margin-right': layout.marginWidth + unit,
		'margin-left': layout.marginWidth + unit,
		'width': layout.width + unit,
		'position': 'relative'
	});

	if (hasParentLayout) {
		$container.css('height', layout.height + unit);

	} else {
		$container.css('height', `${layout.height}px`);
	}
};
