<template>
	<section class="assign-items">
		<div v-if="useSearch" class="assign-items__select-container">
			<p
				v-if="labels.selectLabel"
				:class="labelClasses"
			>{{labels.selectLabel}}</p>
			<v-select
				v-if="canAdd"
				:options="allItems"
				:placeholder="labels.selectItem"
				label="name"
				@input="add"
			>
				<template #no-options>
					{{$t('selectInput.noResults')}}
				</template>
			</v-select>
		</div>
		<div v-if="warning" class="assign-items__warning">
			<slot name="warning" />
		</div>
		<div class="table__container">
			<table v-if="items.length" class="table assign-items__table">
				<thead>
					<tr>
						<th v-for="(field, i) in fields" :key="i">
							{{labels[field]}}
						</th>
						<th />
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in items" :key="item.id">
						<td
							v-for="(field, i) in fields"
							:key="i"
							:class="compareAvailability(item.id, 'unavailable')"
						>
							<p class="table-cell-label">
								{{labels[field]}}
							</p>
							{{item[field]}}
						</td>
						<td>
							<button
								v-if="canEdit"
								class="icon-button__button icon icon--pencil"
								@click="edit(item.id)"
							/>
							<button
								v-if="canRemove"
								class="icon-button__button icon icon--delete"
								:class="compareAvailability(item.id, 'button-unavailable')"
								@click="remove(item.id)"
							/>
						</td>
					</tr>
				</tbody>
			</table>
			<p v-else class="assign-items__empty">
				{{labels.empty}}
			</p>
		</div>
	</section>
</template>

<script>
import Vue from 'vue';
import store from 'store';
import vSelect from 'vue-select';
import { differenceBy, find, forEach, isString } from 'lodash';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'service/cwalert';
import { warn } from '../../service/log/log';
import cardUrl from 'cwcardurl';

export default Vue.component('assign-items', {
	store,
	components: { vSelect },
	props: {
		canAdd: {
			type: Boolean,
			default: true
		},
		canRemove: {
			type: Boolean,
			default: true
		},
		canEdit: {
			type: Boolean,
			default: false
		},
		compare: {
			type: Boolean,
			default: false
		},
		useSearch: {
			type: Boolean,
			default: true
		},
		warning: {
			type: Boolean,
			default: false
		},
		labels: {
			type: Object,
			required: true,
			validator: (labels) => {
				let valid = true;

				forEach(labels, (label, key) => {
					if (!isString(label)) {
						warn(`Label ${key} is not string`);
						valid = false;
					}
				});

				return valid;
			}
		},
		getters: {
			type: Object,
			required: true,
			validator: (getters) => isString(getters.allItems) && isString(getters.items)
		},
		actions: {
			type: Object,
			required: true,
			validator: (actions) => isString(actions.removeItem) && isString(actions.addItem)
		},
		fields: {
			type: Array,
			default: () => ['name']
		},
		mandatory: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		allItems () {
			return differenceBy(
				this.$store.getters[this.getters.allItems],
				this.$store.getters[this.getters.items],
				(item) => +item.id
			);
		},
		items () {
			return this.$store.getters[this.getters.items];
		},
		labelClasses () {
			const classes = ['assign-items__label'];

			if (this.mandatory) {
				classes.push('assign-items__label--mandatory');
			}
			return classes;
		}
	},

	methods: {
		add (item) {
			if (!item) {
				return;
			}
			this.$store.dispatch(this.actions.addItem, item).then(() => {
				cwalert.success(this.labels.addSuccess);
			});
		},
		compareAvailability (id, notAvailableClass) {
			if (!this.compare) {
				return {};
			}
			const curClass = {};
			const available = find(
				this.$store.getters[this.getters.allItems],
				(element) => +element.id === +id
			);

			if (!available) {
				const className = `assign-items--${notAvailableClass}`;
				curClass[className] = true;
			}
			return curClass;
		},
		edit (optionId) {
			confirmation({
				icon: 'edit',
				warning: true,
				title: this.labels.editItem,
				message: this.labels.editQuestion
			}).then(() => {
				const consentId = store.state.cardData.consentId;
				cardUrl.openCard('edit-consent-option', {
					consentId,
					optionId
				});
			});
		},
		remove (id) {
			confirmation({
				icon: 'delete',
				warning: true,
				title: this.labels.removeItem,
				message: this.labels.removeQuestion
			}).then(() => {
				this.$store.dispatch(this.actions.removeItem, id).then(() => {
					cwalert.success(this.labels.removeSuccess);
				});
			});
		}
	}
});
</script>
