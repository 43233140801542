var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "assign-items" }, [
    _vm.useSearch
      ? _c(
          "div",
          { staticClass: "assign-items__select-container" },
          [
            _vm.labels.selectLabel
              ? _c("p", { class: _vm.labelClasses }, [
                  _vm._v(_vm._s(_vm.labels.selectLabel))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.canAdd
              ? _c("v-select", {
                  attrs: {
                    options: _vm.allItems,
                    placeholder: _vm.labels.selectItem,
                    label: "name"
                  },
                  on: { input: _vm.add },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "no-options",
                        fn: function() {
                          return [
                            _vm._v(
                              "\n\t\t\t\t" +
                                _vm._s(_vm.$t("selectInput.noResults")) +
                                "\n\t\t\t"
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3498908222
                  )
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.warning
      ? _c(
          "div",
          { staticClass: "assign-items__warning" },
          [_vm._t("warning")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "table__container" }, [
      _vm.items.length
        ? _c("table", { staticClass: "table assign-items__table" }, [
            _c("thead", [
              _c(
                "tr",
                [
                  _vm._l(_vm.fields, function(field, i) {
                    return _c("th", { key: i }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.labels[field]) +
                          "\n\t\t\t\t\t"
                      )
                    ])
                  }),
                  _vm._v(" "),
                  _c("th")
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.items, function(item) {
                return _c(
                  "tr",
                  { key: item.id },
                  [
                    _vm._l(_vm.fields, function(field, i) {
                      return _c(
                        "td",
                        {
                          key: i,
                          class: _vm.compareAvailability(item.id, "unavailable")
                        },
                        [
                          _c("p", { staticClass: "table-cell-label" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.labels[field]) +
                                "\n\t\t\t\t\t\t"
                            )
                          ]),
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(item[field]) +
                              "\n\t\t\t\t\t"
                          )
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("td", [
                      _vm.canEdit
                        ? _c("button", {
                            staticClass:
                              "icon-button__button icon icon--pencil",
                            on: {
                              click: function($event) {
                                return _vm.edit(item.id)
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canRemove
                        ? _c("button", {
                            staticClass:
                              "icon-button__button icon icon--delete",
                            class: _vm.compareAvailability(
                              item.id,
                              "button-unavailable"
                            ),
                            on: {
                              click: function($event) {
                                return _vm.remove(item.id)
                              }
                            }
                          })
                        : _vm._e()
                    ])
                  ],
                  2
                )
              }),
              0
            )
          ])
        : _c("p", { staticClass: "assign-items__empty" }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.labels.empty) + "\n\t\t")
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }