import { includes, indexOf } from 'lodash';
import appContext from 'app-context';
import cardsProps from './cards-props';
import cardUrl from 'cwcardurl';
import store from 'store';

const getName = (name) => name || store.getters.cardDefinition.name || store.state.cardData.cardId;

export default {
	getCurrent: () => cardsProps.currentOf('names'),

	canNavigate: () => cardsProps.views.length > 1,

	canClose (cardName) {
		return !includes(cardsProps.unclosableCardNames, cardName || this.getCurrent());
	},

	prevCard: () => {
		cardsProps.cursor.minus();
		cardUrl.go(cardsProps.currentOf('routes'));
	},

	closeCard ({ go, openCard, cardQuery, name, manualOverride = false } = {}) {
		const cardName = getName(name);
		const index = cardName ? indexOf(cardsProps.names, cardName) : cardsProps.cursor.val;
		const view = cardsProps.views[index];
		const canClose = () => view && (this.canClose(cardName) || manualOverride);

		if (canClose()) {
			view.kill();

			if (this.canClose(cardName)) {
				cardsProps.removeAllAtIndex(index);
				appContext
					.trigger('card.close', { name: cardsProps.names[index] })
					.trigger('dialog:close');
			}

			this.redirectAfterClose({ go, openCard, cardQuery });
		}
	},

	redirectAfterClose ({ go, openCard, cardQuery = {} }) {
		if (go) {
			cardUrl.go(go);

		} else if (openCard) {
			cardUrl.openCard(openCard, cardQuery);

		} else {
			this.prevCard();
		}
	}
};
