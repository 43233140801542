import $ from 'jquery';
import icon from 'service/icon';
import appbarLayout from 'core/engine/appbar/views/appbar-layout';
import executeIfFunction from 'execute-if-function';
import { View } from 'backbone';
import { assign, get, isUndefined } from 'lodash';

export default View.extend({
	initialize (params) {
		this.params = params;
	},

	className: 'appbar-button__container',

	events: {
		'click .appbar-button': 'handleClick'
	},

	render () {
		const title = $('<span class="appbar-button__title"/>')
			.html(executeIfFunction(this.params.title));
		const content = [title[0]];
		let tagName = 'button';
		const attrs = {
			title: executeIfFunction(this.params.title)
		};

		this.params.icon && content.push(icon(this.params.icon));

		if (this.params.url) {
			tagName = 'a';
			assign(attrs, {
				href: this.params.url,
				role: 'button'
			});
		}

		this.params.hide && this.hide();

		this.$button = $(`<${tagName} />`)
			.addClass(`appbar-button ${get(this.params, 'css', '')}`)
			.html(content)
			.attr(attrs)
			.appendTo(this.$el);
	},

	handleClick (event) {
		appbarLayout().trigger('appbar-button.click');

		if (!this.params.click) {
			return true;
		}

		const view = this;
		const result = view.params.click.call(view, {
			event,
			params: this.params,
			view
		});

		if (isUndefined(result)) {
			return false;
		}

		return result;
	},

	setLoading () {
		this.$el.addClass(`${this.className}--loading`);
	},

	setLoaded () {
		this.$el.removeClass(`${this.className}--loading`);
	},

	show () {
		this.$el.removeClass(`${this.className}--hidden`);
	},

	hide () {
		this.$el.addClass(`${this.className}--hidden`);
	}
});
