export default {
	props: {
		readonly: {
			type: Boolean,
			default: true
		},
		path: {
			type: String,
			required: true
		},
		onValueChange: {
			type: Function,
			required: true
		}
	},
	computed: {
		inputListeners () {
			return this.$listeners;
		}
	}
};
