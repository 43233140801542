import MultipleEntities from '../shared/multiple-entities';
import Respondent from './respondent';
import urls from './urls';

const RespondentsEntity = MultipleEntities.extend({
	model: Respondent,
	urls,
	idName: 'respondentId',
	eventNamespace: 'respondent',

	search (params) {
		return this.retrieve('searchRespondents', params);
	},

	getByUsername (username) {
		return this.retrieve('respondentByName', username);
	}
});

export default new RespondentsEntity();
