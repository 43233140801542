import Vue from 'vue';
import TableOfContents from './table-of-contents';

export default ({ entries, jumpTo }) => {
	const vm = new Vue({
		el: document.createElement('div'),
		components: { TableOfContents },
		data: { entries, jumpTo },
		template: `<table-of-contents :entries="entries" :jump-to="jumpTo" />`
	});

	return vm.$el;
};
