import repository from 'repository';
import categories from './page-categories';
import { filter } from 'lodash';
import { warn } from 'service/log/log';

export default {
	namespaced: true,
	modules: { categories },

	state: {
		data: [],
		collection: null
	},

	getters: {
		list: (state) => state.data,
		active: (state) => filter(state.data, (element) => element.instance.state === 'ACTIVATE')
	},

	mutations: {
		set: (state, { pages, collection }) => {
			state.data = pages;
			state.collection = collection;
		}
	},

	actions: {
		initForTreatment: ({ commit }, { respondentId = null, treatmentId }) => {
			const params = respondentId ?
				{ respondent: respondentId, treatment: treatmentId } :
				{ treatment: treatmentId };
			const collection = repository.getTreatmentPages(params);

			return collection.then(() => {
				commit('set', { collection, pages: collection.toJSON() });
			});
		},

		initForRespondent: ({ commit }, { respondentId }) => {
			const collection = repository.getTreatmentPages({ respondent: respondentId });

			return collection.then(() => {
				commit('set', { collection, pages: collection.toJSON() });
			});
		},

		// @TODO(2018-01-31): generalize -- it's present in other stores
		init: ({ dispatch, rootGetters }, allRespondents = false) => {
			const initTreatment = ({ respondent, treatment }) => {
				const params = allRespondents ?
					{ treatmentId: treatment } :
					{ respondentId: respondent, treatmentId: treatment };
				return dispatch('initForTreatment', params);
			};
			const respondentId = rootGetters['respondent/respondentId'];
			const treatmentId = rootGetters['treatment/treatmentId'];

			if (treatmentId && respondentId) {
				return initTreatment({ respondent: respondentId, treatment: treatmentId });

			} else if (respondentId) {
				return dispatch('initForRespondent', { respondentId });

			} else if (treatmentId) {
				return dispatch(
					{ type: 'treatment/initWithoutRespondent' },
					{ root: true }
				).then(() =>
					dispatch('initForTreatment', {
						respondentId: rootGetters['respondent/respondentId'],
						treatmentId
					}));
			}

			warn('Pages: respondent and treatment unavailable');
		}
	}
};
