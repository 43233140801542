import SingleEntity from '../shared/single-entity';
import urls from './urls';

/**
 * ReportContent Entity
 * @class ReportContent
 * @extends SingleEntity
 */
export default SingleEntity.extend({
    idAttribute: 'reportInstanceId',
    url: urls.reportContent.url,

    getHTML: function() {
        return this.get('html');
    }
});
