import t from 'translate';
import systemSettings from 'system-settings';
import repository from 'repository';
import { when } from 'jquery';
import store from 'store';

const card = {
	name: 'respondent-my-profile',
	title: t('My Profile'),
	url: 'treatment/:treatmentId/my-profile',

	ctrl: ({ cardData }) => {
		if (!systemSettings.getBoolean('ENABLE_RESPONDENT_PERSONAL_SETTINGS_PAGE')) {
			return;
		}
		const respondent = repository.getCurrentRespondent();
		const circleInit = systemSettings.getBoolean('ENABLE_CIRCLES') ?
			store.dispatch('circles/profile/init') :
			{};

		return when(
			store.dispatch('languages/init'),
			store.dispatch('timezones/init'),
			respondent,
			circleInit
		).then(() => {
			store.dispatch('languages/setSelected', respondent.get('respondentLanguageId'));
			cardData.set({
				model: respondent,
				userType: 'respondent'
			});
		});
	}
};

export { card as myProfile };
