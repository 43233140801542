import appUrl from 'service/app-url/app-url';

export default ({ option, getUrl, clickable }) => {
	if (option) {
		if (option.getType() === 'TextOption') {
			return `<div class="value value--text">${option.getLabel()}</div>`;
		} else if (option.getType() === 'ImageOption') {
			const imgUrl = appUrl(getUrl(option.image()));
			const imgAlt = option.getAlt();

			return clickable ?
				`<div class="value value--image">
					<img
						class="slider-answer--image"
						data-id="${option.getCode()}"
						src="${imgUrl}"
						alt="${imgAlt}"
					/>
				</div>` :
				`<div class="value value--image"><img src="${imgUrl}" alt="${imgAlt}" /></div>`;
		}
	}
};
