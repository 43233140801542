import { View } from 'backbone';
import { when } from 'jquery';
import {
	assign, forEach, isArray, isEmpty, isFunction, kebabCase, keys, map, noop, values
} from 'lodash';
import executeIfFunction  from 'execute-if-function';
import log from 'service/log/log';
import store from 'store';
import Vue from 'vue';
import aclCheck from 'core/engine/tile/helpers/acl-check';
import tileMixin from 'components/__mixins/tile-mixin';
import t from '../../../../service/lang/translate';
import appContext from 'app-context';

/**
 * @class TileView
 */

export default View.extend({
	acl: [],
	$store: store,

	/**
	 *
	 *
	 * @function TileView.actions
	 * @example
	 * export default TileView.extend({
	 *      actions: ['treatment/getContentPackageInstances']
	 * });
	 */
	actions: [],

	/**
	 * Get properties from the card and attach them to the tile object. This function is executed
	 * before `tileData()` and `loaded()`.
	 *
	 * @function TileView.cardData
	 * @returns {Array} - List of properties to be attached. They will be treated as promises.
	 * @example
	 * export default TileView.extend({
	 *     cardData: () => ['respondentId', 'treatmentId'],
	 *
	 *     loaded: ({ tile }) => {
	 *         if (tile.respondentId) {
	 *             // ...
	 *         }
	 *     }
	 * });
	 */
	cardData: () => [],

	/**
	 * Enhance a tile with properties.
	 *
	 * @function TileView.tileData
	 * @param {Object} {tile} - Tile object.
	 * @returns {Object}      - Properties that will enhance a tile. They will be treated as
	 *                          promises.
	 * @example
	 * export default TileView.extend({
	 *     tileData: () => ({
	 *         respondent: store.getters.respondent
	 *     }),
	 *
	 *     loaded: ({ tile }) => {
	 *         if (tile.respondentId) {
	 *             // ...
	 *         }
	 *     }
	 * });
	 */
	tileData: noop,

	t (phrase) {
		return this.config().translate ? t(phrase) : phrase;
	},

	initialize ({ skipInit = false, path }) {
		if (skipInit || !aclCheck(this.acl)) {
			return;
		}

		assign(this, { path, __cardData: this.cardData() });

		forEach(this.__cardData, (field) => {
			this[field] = this.cardContext().has(field) ? this.cardContext().get(field) : null;
		});

		this.__actions = isFunction(this.actions) ? this.actions(this) : this.actions;

		store.dispatch('addCurrentCardActions', {
			actions: this.__actions,
			source: `tile:${this.tileName()}`,
			type: 'tile'
		});
		this.__tileData = this.tileData({ tile: this });

		assign(this, this.__tileData);

		this.bindEvents();
		this.onInitialize({ tile: this });
	},

	bindEvents () {
		this.once('remove', this._onRemove);
		this.listenTo(appContext, 'card.populate', this.destroyVue);
	},

	/**
	 * Gets called at the end of initialization of a tile.
	 *
	 * @function TileView.onInitialize
	 * @param {Object} {tile} - Tile object.
	 * @example
	 * export default TileView.extend({
	 *    onInitialize: ({ tile }) => {
	 *        tile.customFlag = true;
	 *    }
	 * });
	 */
	onInitialize: noop,

	/**
	 * Gets called at the end of initialization of `render` function. `tile.el` and `tile.$el` are
	 * available.
	 *
	 * @function TileView.onBeforeRender
	 * @param {Object} {tile} - Tile object.
	 * @example
	 * export default TileView.extend({
	 *     onBeforeRender: ({ tile }) => {
	 *         tile.$el.addClass('on-before-render');
	 *     }
	 * });
	 */
	onBeforeRender: noop,

	render () {
		this.cardContext && this.listenToOnce(this.cardContext(), 'card:close', this.remove);
		this.onBeforeRender({ tile: this });
		this.setLoading();
	},

	onRender () {
		const data = map(this.__cardData, ((field) => ({ [field]: this[field] })))
			.concat(
				map(this.__tileData, (val, key) => ({ [key]: this[key] }))
			);

		if (
			!isEmpty(this.__actions) ||
			!isEmpty(this.__cardData) ||
			!isEmpty(this.__tileData) ||
			this.instant === true
		) {
			this.load(data);
		}
	},

	/**
	 * Gets called when all promises defined as properties returned by `cardData` or `tileData`
	 * methods are resolved. `tile.el` and `tile.$el` are available. It's called after attaching
	 * `Vue.js` View.
	 *
	 * @function TileView.loaded
	 * @param {Object} {tile} - Tile object.
	 * @example
	 * export default TileView.extend({
	 *     tileData: () => ({
	 *         respondent: store.getters.respondent
	 *     }),
	 *
	 *     loaded: ({ tile }) => {
	 *         tile.$el.html(`Respondent ID: ${tile.respondent.respondentId}`);
	 *     }
	 * });
	 */
	loaded: noop,
	load (...rawArgs) {
		let args = [];

		if (rawArgs.length === 1 && isArray(rawArgs[0])) {
			args = rawArgs[0];

		} else {
			args = rawArgs;
		}

		const toLoad = map(args, (arg) => values(arg)[0]);
		const data = {};
		forEach(args, (arg) => {
			assign(data, arg);
		});

		const promises = store.state.currentCard.promises[`tile:${this.tileName()}`] || [];
		return when(...promises, ...toLoad).then(() => {
			this.setLoaded();
			this.attachVue(this);
			this.loaded({ data, tile: this });
		});
	},

	/**
	 * Define a Vue.js View object that will be inserted after resolving promises returned by
	 * `cardData` or `tileData` methods. The view has got an `$el` and `store` already set to
	 * convenient defaults (`<div />` and Vuex store respectively).
	 *
	 * @function TileView.Vue
	 * @param {Object} {tile} - Tile object.
	 * @returns {Object}      - Vue.js View definition.
	 * @example
	 * import { mapGetters } from 'vuex';
	 *
	 * export default TileView.extend({
	 *     Vue: ({ tile }) => ({
	 *         template: `<p>Respondent ID: {{	respondent.respondentId }}</p>`,
	 *         computed: {
	 *             ...mapGetters(['respondent'])
	 *         }
	 *     })
	 * });
	 *
	 */
	Vue: noop,

	useVueComponent: (tile) => {
		if (!isFunction(tile.vueComponent)) {
			return;
		}

		const componentName = keys(tile.vueComponent())[0];
		const component = tile.vueComponent()[componentName];
		component.mixins = component.mixins ? component.mixins.concat(tileMixin) : [tileMixin];

		tile.Vue = (tile) => ({
			data: { tile },
			components: { [componentName]: component },
			template: `<${kebabCase(componentName)} :tile="tile" />`
		});
	},

	attachVue: (tile) => {
		tile.useVueComponent(tile);

		if (!tile.Vue) {
			return;

		} else if (!isFunction(tile.Vue)) {
			log.warn('tile.Vue should be a function');
			return;
		}
		const vm = tile.Vue(tile);

		if (!vm) {
			return;
		}

		tile.vueInstance = new Vue(assign({
			el: document.createElement('div'),
			store,
			template: ''
		}, vm));

		tile.$el.append(tile.vueInstance.$el);
	},

	/**
	 * Define a hook called when tile is removed.
	 *
	 * @function TileView.onRemove
	 * @param {Object} {tile} - Tile object.
	 * @example
	 * export default TileView.extend({
	 *     onRemove: ({ tile }) => {
	 *         tile.table.remove();
	 *     }
	 * });
	 *
	 */
	onRemove: noop,
	_onRemove () {
		forEach(['form', 'formView', 'filter'], (formPropName) => {
			if (this[formPropName]) {
				executeIfFunction(this[formPropName].close, this[formPropName]);
			}
		});

		this.destroyVue();

		this.onRemove.call(this, { tile: this });
	},

	destroyVue () {
		this.vueInstance && this.vueInstance.$destroy();
		this.vue && this.vue.$destroy();
	},

	tileName () {
		return this.__params && this.__params.tileContainerModel.get('tileName');
	}
});
