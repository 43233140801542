export default {
	diaryEntries: {
		url: 'backend-commons/diary/entry'
	},

	diaryEntry: {
		url: (id) => `backend-commons/diary/entry${id ? `/${id}` : ''}`
	}
};

