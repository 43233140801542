import { isNull } from 'lodash';
import SingleEntity from '../shared/single-entity';
import t from 'translate';
import emailCheck from 'lib/email-check';
import urls from './urls';
import defaults from './respondent-defaults';
import formLabels from './respondent-form-labels';
import AssessmentInstances from '../assessment-instances/assessment-instances';
import Flags from '../flags/flags';
import Decisions from '../decisions/decisions';
import RespondentClinicians from './clinicians';
import systemSettings from 'system-settings';
import { respondentName } from 'service/display-name/display-name';

export default SingleEntity.extend({
	idAttribute: 'respondentId',
	eventNamespace: 'respondent',
	checkpoint: 'administrator.respondents.respondents',
	urlRoot: urls.respondent,
	defaults,
	label: formLabels,

	_getEntities (entity) {
		return entity.getByRespondentId(this.getId());
	},

	getAssessmentInstances () {
		return this._getEntities(AssessmentInstances);
	},

	getFlags () {
		return this._getEntities(Flags);
	},

	getDecisions () {
		return this._getEntities(Decisions);
	},

	getClinicians () {
		return this._getEntities(RespondentClinicians);
	},

	validate (attrs) {
		// @todo: need a convention to automate that one
		const keys = [];
		const msgs = [];
		const usernameRegex = new RegExp(systemSettings.getString('RESPONDENT_USERNAME_REGEX'));
		const passwordRegex = new RegExp(systemSettings.getString('RESPONDENT_PASSWORD_REGEX'));

		if (this.isNew() && !usernameRegex.test(attrs.respondentUsername)) {
			keys.push('respondentUsername');
			msgs.push(t('Username has to match required pattern'));
		}

		if (!isNull(attrs.respondentPassword) && !passwordRegex.test(attrs.respondentPassword)) {
			keys.push('respondentPassword');
			msgs.push(t('Password has to match required pattern'));
		}

		if (!!attrs.respondentEmail && !emailCheck(attrs.respondentEmail)) {
			keys.push('respondentEmail');
			msgs.push(t('This email address seems to be invalid. Please recheck.'));
		}

		if (keys.length) {
			return {
				key: keys,
				msg: msgs
			};
		}
	},

	displayName () {
		return respondentName(this.toJSON());
	},

	getTimezone () {
		return this.get('respondentTimezoneName');
	}
});

