import acl from './attributes/acl';
import appEvents from './attributes/app-events/app-events';
import box from './functions/box';
import card from './functions/card';
import cardContext from './functions/card-context';
import clear from './functions/clear';
import closeModal from './functions/close-modal';
import config from './functions/config';
import disable from './functions/disable';
import enable from './functions/enable';
import features from './attributes/features';
import setDataLoaded from './functions/set-data-loaded';
import setDataLoading from './functions/set-data-loading';
import setLoaded from './functions/set-loaded';
import setLoading from './functions/set-loading';
import setTitle from './functions/set-title';
import showModal from './functions/show-modal';
import title from './attributes/title';
import validAcl from './validators/acl';
import validateTemplate from './validators/template';

export default {
	functions: {
		box,
		card,
		cardContext,
		clear,
		closeModal,
		config,
		disable,
		enable,
		setDataLoaded,
		setDataLoading,
		setLoaded,
		setLoading,
		showModal,
		setTitle
	},

	attributes: {
		acl,
		appEvents,
		features,
		title
	},

	validators: {
		acl: validAcl,
		template: validateTemplate
	}
};
