import TileView from 'tile-view';
import t from 'translate';
import cardUrl from 'cwcardurl';
import store from 'store';
import { respondentName } from 'service/display-name/display-name';
import datetime from 'datetime';
import { find, get, unescape } from 'lodash';
import showField from 'service/show-field';
import TimezoneWarning from 'components/timezone-warning';

export default TileView.extend({
	acl: [{
		checkpoint: 'administrator.respondents.respondents',
		op: 'READ'
	}],

	instant: true,
	loaded: ({ tile }) => {
		!store.getters.respondent && tile.disable();
	},

	Vue: (tile) => ({
		components: { TimezoneWarning },

		watch: {
			respondent (val) {
				val && tile.enable();
			}
		},
		template: `
		<div v-if="respondent" class="label">
			<section class="label__container">
				<section class="label__respondent-info">
					<p class="respondent-info__entry respondent-info__entry--respondent">
						<span class="respondent-info__label">${t('Respondent')}</span>
						<a
							:href="respondentLink(respondent)"
							class="
								respondent-info__link
								respondent-info__value
								respondent-info__value-container
							"
						>{{displayName(respondent)}}</a>
					</p>
					<p
						v-if="show.respondentUsername"
						class="respondent-info__entry respondent-info__entry--username"
					>
						<span class="respondent-info__label">${t('User id')}</span>
						<span class="respondent-info__value respondent-info__value-container">
							{{respondentUsername}}
						</span>
					</p>
					<p class="respondent-info__entry respondent-info__entry--login">
						<span class="respondent-info__label">${t('Last logged in')}</span>
						<span class="respondent-info__value respondent-info__value-container">
							{{lastLoginDate(respondent)}}
						</span>
					</p>
					<p
						v-if="treatmentExists"
						class="respondent-info__entry respondent-info__entry--treatment"
					>
						<span class="respondent-info__label">${t('Treatment')}</span>
						<span class="respondent-info__value-container">
							<a
								:href="treatmentLink(treatment)"
								class="respondent-info__value respondent-info__link"
							>{{treatment.name}}</a>
							<span>${t('of type')}</span>
							<span class="respondent-info__value">
								{{treatmentTypeName(treatment)}}
							</span>
							<template v-if="treatmentRoles.length">
								<span>${t('as the role')}</span>
								<span class="respondent-info__value">{{roleName(respondent)}}</span>
							</template>
						</span>
					</p>
				</section>

				<section v-if="treatmentExists && notMainRespondent" class="label__respondent-info">
					<p class="respondent-info__entry respondent-info__entry--respondent">
						<span class="respondent-info__label">${t('Respondent')}</span>
						<a
							:href="respondentLink(treatmentRespondent)"
							class="respondent-info__link respondent-info__value"
						>
							{{displayName(treatmentRespondent)}}
						</a>
					</p>
					<p class="respondent-info__entry respondent-info__entry--username">
						<span class="respondent-info__label">${t('User id')}</span>
						<span class="respondent-info__value  respondent-info__value-container">
							{{treatmentRespondentUsername}}
						</span>
					</p>
					<p class="respondent-info__entry respondent-info__entry--respondent">
						<span class="respondent-info__label">${t('Last logged in')}</span>
						<span class="respondent-info__value respondent-info__value-container">
							{{lastLoginDate(treatmentRespondent)}}
						</span>
					</p>
					<p class="respondent-info__entry respondent-info__entry--treatment">
						<span class="respondent-info__label">
							${t('Participates in treatment as the role')}
						</span>
						<span class="respondent-info__value  respondent-info__value-container">
							{{roleName(treatmentRespondent)}}
						</span>
					</p>
				</section>
			</section>
			<timezone-warning :respondent="respondent" />
		</div>
		`,
		computed: {
			respondent: () => store.getters['respondent'] || null,

			treatment: () => store.getters['treatment'] || {},

			treatmentExists: () => !!store.getters['treatment'],

			treatmentRespondent: () => get(store.state, 'treatment.mainRespondent'),

			notMainRespondent () {
				return this.respondent.respondentId !== get(this.treatment, 'respondent.id');
			},

			treatmentRoles: () => store.getters['treatment/roles'],

			respondentUsername: () => get(store.getters, 'respondent.respondentUsername', ''),

			treatmentRespondentUsername: () =>
				get(store.state, 'treatment.mainRespondent.respondentUsername'),

			show: ({ respondentUsername }) => showField({
				config: get(tile.config(), 'fields', {}),
				field: {
					respondentUsername,
					key: 'respondentUsername'
				}
			})
		},
		methods: {
			respondentLink: (respondent) => cardUrl.buildUrl('respondent-status', {
				respondentId: get(respondent, 'respondentId')
			}),

			treatmentLink: (treatment) => cardUrl.buildUrl('treatment', {
				treatmentId: treatment.id
			}),

			treatmentTypeName: (treatment) => get(treatment, 'treatmentType.name'),
			displayName: (respondent) => unescape(respondentName(respondent)),

			roleName: (respondent) => {
				const role = find(store.getters['treatment/roles'], (roleObj) =>
					+get(roleObj, 'respondent.id') === +get(respondent, 'respondentId')) || {};

				return get(role, 'treatmentTypeRole.name');
			},

			lastLoginDate: (respondent) => {
				const neverText = t('Never');

				if (get(respondent, 'respondentLastLogin')) {
					return datetime(respondent.respondentLastLogin)
						.setTimezone(respondent.respondentTimezoneName)
						.toMoment()
						.format(t('date.formats.dateTime'));

				}

				return neverText;
			}
		}
	})
});
