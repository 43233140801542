<template>
	<div class="table-view__container">
		<table-filters
			v-if="showFilters"
			:config="filtersConfig"
			:current-count="items.length"
			:total-count="totalCount"
			:tile-path="tilePath"
			@change="onFiltersChange"
		/>

		<div
			v-for="group in groupedItems"
			:key="groupLabel(group)"
			class="table-view__body"
		>
			<loader-overlay v-if="dataLoading" label="" />

			<h3
				v-if="groupLabel(group)"
				class="table-view__group-label"
			>
				{{groupLabel(group)}}
			</h3>

			<table-list
				:items="group"
				:columns="columns"
				:sortable="tileConfig.sortable"
				:item-value="itemValue"
				:item-key="tileConfig.idName"
				:tile-path="tilePath"
				class="table-view"
				@row-click="onRowClick"
			>
				<template #cell="{ column, content, item }">
					<component
						:is="component(column)"
						:column="column"
						:item="item"
						:tile-config="tileConfig"
						@change="onChange($event, { column, content, item })"
					/>
				</template>
			</table-list>

			<more-results
				v-if="items.length < totalCount"
				@more="moreResults"
			/>
		</div>
	</div>
</template>

<script>
import {
	assign,
	debounce,
	find,
	forEach,
	get,
	groupBy,
	isString,
	isMatch,
	isUndefined,
	map,
	pick,
	reduce,
	reject,
	size,
	some,
	sortBy
} from 'lodash';
import store from 'store';
import getComponent from './get-component';
import TableList from 'components/table-list';
import LoaderOverlay from 'components/loaders/loader-overlay';
import MoreResults from './more-results';
import TableFilters from './filters/table-filters';
import { RAW_ENTITIES } from 'store/general/raw-entities';
import { SEARCH } from 'store/general/search';
import cardUrl from 'core/engine/card/services/card-url';
import canAccess from 'core/engine/card/services/can-access';
import cwalert from 'service/cwalert';
import { error } from 'service/log/log';
import {
	saveIdKeyAction,
	fetchAction,
	updateAction,
	clearAction,
	enrichItem,
	searchParams,
	parseUrlParams
} from './helpers';
import { CARD } from 'store/engine/card';
import acl from 'service/acl/acl';

const shouldFetch = ({ tileConfig, $store, tilePath, dataUrl, searchQuery }) => isString(dataUrl) &&
	!(tileConfig.keepItems && size($store.getters[RAW_ENTITIES.DATA][tilePath])) &&
	(!tileConfig.startEmpty || searchQuery);

export default {
	title: 'List',

	actions (tile) {
		const $store = store;
		const tileConfig = tile.config();
		const tilePath = tile.path;

		const dataUrl = get(
			tileConfig,
			'data.url.many',
			get(tileConfig, 'data.url', '')
		);

		if (isUndefined(tileConfig.translate)) {
			tileConfig.translate = true;
		}

		const searchQuery = $store.getters[CARD.SEARCH_QUERY];

		if (shouldFetch({ tileConfig, $store, tilePath, dataUrl, searchQuery })) {
			return [
				saveIdKeyAction({
					id: tilePath,
					idKey: get(tileConfig, 'idName')
				}),
				[...fetchAction({
					dataUrl,
					id: tilePath,
					override: {
						start: 0,
						search: searchQuery,
						urlParams: parseUrlParams(get(tileConfig, 'data.urlParams', {}))
					},
					filters: tileConfig.filters
				}), {}, { instant: true }]
			];
		}
		return [];
	},

	components: {
		LoaderOverlay,
		TableFilters,
		TableList,
		MoreResults
	},

	data () {
		const tileConfig = this.tile.config();

		return {
			tileConfig,
			tilePath: this.tile.path,
			dataLoading: false,
			rawColumns: tileConfig.columns,
			additionalData: get(tileConfig, 'data.additional', {}),
			rowClasses: get(tileConfig, 'conditionedClassNames', {}),
			targetCard: get(tileConfig, 'open.card'),
			idKey: get(tileConfig, 'idName')
		};
	},

	computed: {
		groupByKey: ({ tileConfig }) => get(tileConfig, 'groupBy.key', ''),
		groupByLabel: ({ groupByKey, tileConfig }) => groupByKey && get(
			tileConfig,
			'groupBy.label',
			null
		),

		allowedColumns: ({ rawColumns }) => reject(
			rawColumns,
			(column) => column.ACL && !acl.checkAccess(column.ACL)
		),

		nonEmptyColumns: ({ allowedColumns, items }) => reject(
			allowedColumns,
			(column) => column.skippable && isUndefined(find(items, column.key))
		),

		columns: ({ mt, nonEmptyColumns }) => map(
			nonEmptyColumns,
			(column) => ({
				...column,
				label: mt(column.label)
			})
		),

		rawResource: ({ $store, tilePath }) => $store.getters[RAW_ENTITIES.DATA][tilePath],

		rawItems: ({ tileConfig, rawResource }) => tileConfig.data.parentKey ?
			get(rawResource, tileConfig.data.parentKey, []) :
			rawResource,

		items: ({ enhanceItem, rawItems }) => map(rawItems, enhanceItem),

		groupedItems: ({ items, groupByKey, tileConfig }) => sortBy(
			groupBy(items, groupByKey),
			(group) => {
				const sortVal = group[0][groupByKey];

				if (get(tileConfig, 'groupBy.emptyFirst') && !sortVal) {
					return -Infinity;
				}

				return sortVal;
			}
		),

		totalCount: ({ $store, tilePath }) => (
			$store.getters[RAW_ENTITIES.TOTAL_COUNT][tilePath] ||
			0
		),

		showFilters: ({ tileConfig }) => some(
			['searchable', 'filters'],
			(key) => !!tileConfig[key]
		),

		filtersAreEmpty: ({ $store, tileConfig, tilePath }) => {
			const searchParams = $store.getters[SEARCH.DATA][tilePath];
			let filtersEmpty = true;

			forEach(get(tileConfig, 'filters'), (filter) => {
				if (searchParams[filter.name] !== '') {
					filtersEmpty = false;
				}
			});

			if (get(tileConfig, 'searchable') && get(searchParams, 'search') !== '') {
				filtersEmpty = false;
			}

			return filtersEmpty;
		},

		filtersConfig: ({ mt, tileConfig }) => {
			const cfg = pick(
				tileConfig,
				['clearable', 'filters', 'limit', 'searchable']
			);

			if (!cfg.filters) {
				return cfg;
			}

			return {
				...cfg,
				filters: map(cfg.filters, (filter) => {
					if (get(filter, 'data.values')) {
						filter.data.values = map(filter.data.values, (value) => ({
							...value,
							label: mt(value.label)
						}));
					}

					return {
						...filter,
						label: mt(filter.label)
					};
				})
			};
		}
	},

	created () {
		this.tile.setTitle(this.mt(this.tileConfig.title));
	},

	methods: {
		// Moved from 5.0 vue mixin
		mt (text, opts = {}) { // maybeTranslate
			const { override } = opts;
			const should = !isUndefined(override) ? override : this.tileConfig.translate;
			return should ? this.$t(text) : text;
		},

		component: (column) => getComponent(column),

		// Used to sort items via table-list component
		itemValue: (itemValue, column) => {
			const sortValueFn = get(
				getComponent(column),
				'methods.sortValue'
			);

			if (!sortValueFn) {
				error('sortValue function is required to perform valid table sorting');
				return null;
			}

			return sortValueFn({
				itemValue,
				column
			});
		},

		onRowClick (item) {
			if (this.targetCard && canAccess(this.targetCard)) {
				const params = reduce(
					get(this.tileConfig, 'open.params', {}),
					(acc, val, key) => {
						acc[key] = get(item, val, '');
						return acc;
					},
					{}
				);

				cardUrl.openCard(this.targetCard, {
					...this.$store.getters.urlParams,
					...params
				});
			}
		},

		rowClassNames (item) {
			return reduce(
				this.rowClasses,
				(acc, conds, className) => assign(acc, { [className]: isMatch(item, conds) }),
				{
					'no-hover': !this.targetCard || !canAccess(this.targetCard)
				}
			);
		},

		enhanceItem (item) {
			return {
				...enrichItem({ item, additionalData: this.additionalData }),
				classNames: this.rowClassNames(item)
			};
		},

		moreResults () {
			const { start, limit } = searchParams({
				id: this.tilePath
			});

			this.$store.dispatch(SEARCH.SET_PARAM, {
				id: this.tilePath,
				param: {
					start: start + limit
				}
			});
			this.fetch({ add: true });
		},

		onFiltersChange: debounce(function () {
			if (get(this.tileConfig, 'startEmpty') && this.filtersAreEmpty) {
				this.$store.dispatch(...clearAction({ id: this.tilePath }));

			} else {
				this.fetch({ add: false });
			}
		}, 300),

		onValueChange (value, { column, item }) {
			const entityId = item[this.idKey];

			this.update({
				item,
				entityId,
				key: column.key,
				value,
				config: column.request
			});
		},

		// Data fetching and updating
		getUrl (key = 'many') {
			const url = get(this.tileConfig, `data.url.${key}`, get(this.tileConfig, 'data.url'));

			return isString(url) ? url : '';
		},

		async fetch ({ add = false }) {
			this.setDataLoading();

			await this.$store.dispatch(...fetchAction({
				id: this.tilePath,
				dataUrl: this.getUrl('many'),
				add
			}));

			this.setDataLoaded();
		},

		async update ({ item, entityId, key, value, config: { method = 'PUT' } }) {
			this.setDataLoading();

			await this.$store.dispatch(...updateAction({
				id: this.tilePath,
				dataUrl: this.getUrl('one'),
				entityId,
				method,
				payload: {
					key,
					value
				},
				override: { ...item }
			})).then(() => {
				cwalert.success(this.t('Success'));
			});

			this.setDataLoaded();
		},

		groupLabel (group) {
			const label = group[0][this.groupByLabel];
			return label ? this.mt(label) : label;
		},

		// Overwrites global vue-tile mixin to customize loader behavior
		setDataLoading () {
			this.dataLoading = true;
		},

		setDataLoaded () {
			this.dataLoading = false;
		}
	}
};
</script>
