import t from 'translate';

const card = {
	title: t('Page'),
	name: 'page',
	url: [
		'page/:id',
		'treatment/:treatmentId/page/:id'
	]
};

export { card as Page };
