<template>
	<li
		v-on-click-outside="hideMenu"
		class="appbar-system-buttons__item"
	>
		<button
			tabindex="0"
			class="appbar-system-buttons__action"
			:title="title"
			@click="onToggleClick"
		>
			<slot name="buttonContent" />
		</button>

		<div class="appbar-menu-items__container">
			<transition name="fade">
				<ul v-show="toggle" class="appbar-menu-items__list">
					<li
						:class="selectedItemClassName({ item: null, i: -1 })"
						class="appbar-menu-items__list-item"
					>
						<button
							:class="actionClassName"
							@click="$emit('firstItemClick', { item: null, i: -1 })"
						>
							<span class="appbar-menu-items__action-text">
								<slot name="firstItemContent" />
							</span>
						</button>
					</li>
					<li
						v-for="(item, i) in items"
						:key="i"
						:class="selectedItemClassName({ item, i })"
						class="appbar-menu-items__list-item"
					>
						<button
							:class="actionClassName"
							@click="$emit('itemClick', { item, i, hideMenu })"
						>
							<span class="appbar-menu-items__action-text">
								<slot name="itemContent" :item="item" />
							</span>
							<span
								v-if="isLoading({ item, i })"
								v-loader-spinner:small
								class="appbar-menu-items__loading"
							/>
						</button>
					</li>
				</ul>
			</transition>
		</div>

	</li>
</template>

<script>
import { constant, defer, noop } from 'lodash';

export default {
	props: {
		title: {
			type: String,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		isSelected: {
			type: Function,
			required: true
		},
		items: {
			type: Array,
			default: constant([])
		},
		isLoading: {
			type: Function,
			default: noop
		},
		allowToggle: {
			type: Boolean,
			default: true
		}
	},

	data: () => ({
		toggle: false
	}),

	computed: {
		actionClassName: ({ name }) => [
			'appbar-menu-items__action',
			`appbar-menu-items__action--${name}`
		]
	},

	methods: {
		hideMenu () {
			this.toggle = false;
		},

		toggleMenu () {
			/*
			 * `v-on-click-outside` callback seems to be fired when keyboard is being used for
			 * toggling. This is a workaround for that -- to be improved/removed when feasible
			 */
			const toggle = this.toggle;

			defer(() => {
				this.toggle = !toggle;
			});
		},

		onToggleClick () {
			this.$emit('toggleClick');

			if (this.allowToggle) {
				this.toggleMenu();
			}
		},

		selectedItemClassName ({ item, i }) {
			return {
				'appbar-menu-items__list-item--selected': this.isSelected({ item, i })
			};
		}
	}
};
</script>
