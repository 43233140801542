var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.groupedExercises, function(ref) {
      var exercises = ref.exercises
      var label = ref.label
      return _c(
        "section",
        { key: label, staticClass: "information-package-elements__container" },
        [
          label
            ? _c("h3", { staticClass: "information-package-elements__title" }, [
                _vm._v("\n\t\t\t" + _vm._s(label) + "\n\t\t")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "generic-list__list information-package-elements" },
            _vm._l(exercises, function(exercise) {
              return _c(
                "li",
                {
                  key: exercise.id,
                  staticClass: "generic-list__item assessment-list__item"
                },
                [
                  _c(
                    "a",
                    {
                      class: _vm.exerciseClassName(exercise),
                      attrs: { href: _vm.url(exercise) }
                    },
                    [
                      _c("icon", {
                        attrs: { name: "exercises", title: _vm.$t("Exercises") }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "\n\t\t\t\t\t\t\tgeneric-list__action-title-container\n\t\t\t\t\t\t\tinformation-package-element__action-title-container\n\t\t\t\t\t\t"
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "\n\t\t\t\t\t\t\t\tgeneric-list__action-title\n\t\t\t\t\t\t\t\tinformation-package-element__action-title\n\t\t\t\t\t\t\t"
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(exercise.elementVersion.alias) +
                                  "\n\t\t\t\t\t\t"
                              )
                            ]
                          ),
                          _vm._v(" "),
                          exercise.state === _vm.STATE_COMPLETED
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "information-package-element__run-once-more"
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.$t("You can do it more than once")
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "information-package-element__state-label"
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.stateLabel(exercise)) +
                                  "\n\t\t\t\t\t\t"
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            }),
            0
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }