<template>
	<section class="dashboard-table__wrapper">
		<h2 v-if="title" class="chart-tile__title">
			{{title}}
		</h2>

		<table class="dashboard-table">
			<thead>
				<tr>
					<th>{{maybeTranslate(config.headingLabel)}}</th>
					<th v-for="header in headers" :key="header">
						{{header}}
					</th>
					<th
						v-for="additionalColumn in config.additionalColumns"
						:key="additionalColumn.label"
					>
						{{maybeTranslate(additionalColumn.label)}}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(row, i) in rows"
					:key="i"
				>
					<th scope="row">
						{{rowHeaders[i]}}
					</th>
					<td
						v-for="(cell, j) in row"
						:key="j"
						:class="cellClassName(j)"
					>{{cell}}</td>
				</tr>
				<tr
					v-for="(additionalRow, i) in additionalRows"
					:key="additionalRow.label"
				>
					<th scope="row">
						{{maybeTranslate(config.additionalRows[i].label)}}
					</th>
					<td
						v-for="(cell, j) in additionalRow"
						:key="j"
						class="dashboard-table__cell--additional"
					>{{cell}}</td>
				</tr>
			</tbody>
		</table>
	</section>
</template>

<script>
import actions from '../../shared/actions';
import { CUBE } from 'store/cube/cube';
import cubeId from '../../shared/cube-id';
import {
	compact, find, forEach, groupBy, includes, isArray, isFinite, keys, map, reduce, split
} from 'lodash';
import { EMPTY_CHAR } from 'lib/chars';

export default {
	actions: (tile) => actions({
		title: tile.config().title,
		query: tile.config().cubeQuery,
		ignoreAccessRules: tile.config().ignoreAccessRules
	}),

	data: ({ tile }) => ({
		config: tile.config()
	}),

	acl: [],

	computed: {
		title: ({ maybeTranslate, config }) => maybeTranslate(config.title),
		results: ({ config, $store }) => $store.getters[CUBE.RESULTS][cubeId(config)].result,
		groupedResults: ({ config, parseHeader, results }) => [
			groupBy(
				results,
				(result) => parseHeader(result[config.headers])[0]
			),
			groupBy(
				results,
				(result) => parseHeader(result[config.headers])[1]
			)
		],
		flip: ({ config }) => config.flip === true,

		allHeaders: ({ flip, groupedResults }) => keys(groupedResults[+flip]),
		allRowHeaders: ({ flip, groupedResults }) => keys(groupedResults[+!flip]),

		headers: ({ allHeaders, config, flip, groupedResults }) => {
			if (config.hideEmptyCols) {
				const headers = [];

				forEach(allHeaders, (header) => {
					forEach(groupedResults[+flip], (values, colHeader) => {
						if (header === colHeader) {
							const flatValues = map(values, config.value);

							if (compact(flatValues).length) {
								headers.push(header);
							}
						}
					});
				});

				return headers;
			}

			return allHeaders;
		},
		rowHeaders: ({ allRowHeaders, config, flip, groupedResults }) => {
			if (config.hideEmptyRows) {
				const rowHeaders = [];

				forEach(allRowHeaders, (header) => {
					forEach(groupedResults[+!flip], (values, rowHeader) => {
						if (header === rowHeader) {
							const flatValues = map(values, config.value);

							if (compact(flatValues).length) {
								rowHeaders.push(header);
							}
						}
					});
				});

				return rowHeaders;
			}

			return allRowHeaders;
		},

		rows: (
			{ config, flip, groupedResults, headers, itemValue, op, parseHeader, rowHeaders }
		) => {
			const rows = compact(map(
				groupedResults[+!flip],
				(result, rowHeader) => {
					if (config.hideEmptyRows && !includes(rowHeaders, rowHeader)) {
						return false;
					}

					const row = map(
						headers,
						(header) => itemValue(
							find(
								result,
								(item) => parseHeader(item[config.headers])[+flip] === header
							)
						)
					);

					const additionalColumns = map(
						config.additionalColumns,
						(column) => op(column.op, row)
					);

					return [...row, ...additionalColumns];
				}
			));

			return rows;
		},

		additionalRows: ({ config, headers, op, rows }) => map(
			config.additionalRows,
			(additionalRow) => map(
				[...headers, ...config.additionalColumns],
				(column, i) => op(additionalRow.op, map(rows, (row) => row[i]))
			)
		)
	},

	methods: {
		maybeTranslate (text = '') {
			return this.config.translate ? this.t(text) : text;
		},

		cellClassName (index) {
			return {
				'dashboard-table__cell': true,
				'dashboard-table__cell--additional': !this.headers[index]
			};
		},

		itemValue (item) {
			return item ? item[this.config.value] : EMPTY_CHAR;
		},

		op (op, items) {
			return this[op](items);
		},

		parseHeader: (header) => isArray(header) ? header : split(header, ';'),

		SUM: (items) => reduce(items, (result, item) => {
			const itemValue = isFinite(item) ? item : 0;
			return result + itemValue;
		}, 0),

		MEAN (items) {
			return this.SUM(items) / items.length;
		}
	}
};
</script>
