<template>
	<div ref="chart" :class="classes" />
</template>

<script>
/* eslint-disable lodash/prefer-lodash-method */
import $ from 'jquery';
import Vue from 'vue';
import Chartist from 'Chartist';
import 'chartist-plugin-legend';
import tooltip from './tooltip';
import { defer, delay, forEach, get, isUndefined, split } from 'lodash';
import { renderThresholdLine, renderThresholdRange } from './helpers';
import { BAR } from './constants';

export default Vue.component('Chart', {
	props: {
		type: {
			type: String,
			required: true
		},
		method: {
			type: String,
			required: true
		},
		params: {
			type: Object,
			required: true
		},
		responsiveOptions: {
			type: Array,
			default: () => [[
				// layout(2column, true)
				'screen and (max-width: 72em) and (min-width: 36em)',
				{ seriesBarDistance: 16 }
			], [
				// layout(1column, true)
				'screen and (max-width: 36em)',
				{ seriesBarDistance: 10 }
			], [
				// layout(4columnNarrow, true)
				'screen and (max-width: 72em) and (min-width: 36em) and (orientation: landscape)',
				{ seriesBarDistance: 24 }
			]]
		},
		options: {
			type: Object,
			required: true
		},
		thresholdLine: {
			type: [Number, Array, Boolean],
			default: false
		},
		thresholdRange: {
			type: [Array, Boolean],
			default: false
		},
		fractionDigits: {
			type: Number,
			default: 2
		}
	},

	data: ({ thresholdRange }) => ({
		sortedThresholdRange: thresholdRange ? thresholdRange.sort((a, b) => a - b) : null
	}),

	computed: {
		classes: ({ options, type }) => ({
			'chart': true,
			[`${type}-chart`]: true,
			'chart--horizontal-labels': options.horizontalLabels !== false
		})
	},

	watch: {
		labels: {
			handler: 'renderChart',
			deep: true
		},
		series: {
			handler: 'renderChart',
			deep: true
		},
		thresholdLine: {
			handler: 'renderChart',
			deep: true
		},
		thresholdRange: {
			handler: 'renderChart',
			deep: true
		},
		responsiveOptions: {
			handler: 'renderChart',
			deep: true
		},
		listener (val, oldVal) {
			this.updateEventListener(oldVal, 'off');
			this.updateEventListener(val, 'on');
		}
	},

	mounted () {
		this.renderChart();
	},

	destroyed () {
		if (this.chartist) {
			this.chartist.detach();
		}
	},

	methods: {
		updateEventListener (listener = {}, type = 'on') {
			forEach(listener, (event) => {
				this.chartist[type](event, listener[event]);
			});
		},

		onDraw (data) {
			// all additional info is stored in meta prop divided by comma
			// example for gap: 'dd YYYY,gap'
			if (data.type === 'point' && split(data.meta, ',')[1] === 'gap') {
				const $el = $(data.element._node);

				data.element.replace(new Chartist.Svg('path', {
					'd': `m${data.x},${data.y + 2} l2,-2 l-2,-2 l-2,2`,
					'class': 'ct-point ct-point--gap',
					'ct:value': $el.attr('ct:value'),
					'ct:meta': split(data.meta, ',')[0]
				}));
			}

			this.handleIe11(data);
		},

		handleIe11 (data) { // The glorious browser
			const [isBarChart, isLabel, isHorizontalChart, supportsForeignObject] = [
				this.type === BAR,
				data.type === 'label',
				get(data.axis, 'units.pos') === 'x',
				this.chartist.supportsForeignObject
			];

			if (isBarChart && isLabel && isHorizontalChart && !supportsForeignObject) {
				data.element.attr({
					x: data.x + (data.width / 2)
				});
			}
		},

		onCreated (context) {
			tooltip({ el: this.chartist.container, fractionDigits: this.fractionDigits });
			this.thresholdLine && renderThresholdLine(
				context,
				this.params.thresholdLineColors
			);
			this.thresholdRange && renderThresholdRange(
				context,
				this.params.thresholdRangeColors
			);

			// svg element's z-index depends on DOM order
			const $chartEl = $(context.svg._node);
			const $chartGrids = $chartEl.find('.ct-grids');

			$chartEl.find('.ct-threshold-range').insertBefore($chartGrids);
			$chartEl.find('.ct-threshold-line').insertBefore($chartGrids);

			// hacking starts here. maybe.
			// legend plugin colored elements are pseudo-elements
			// it needs to be modified to be able to work with custom colors
			defer(() => {
				$chartEl.parent().find('li[class^="ct-series-"]').each((i, value) => {
					const $li = $(value);

					if ($li.find('.ct-legend__series-icon').length) {
						return;
					}

					const template = `
						<span class="ct-legend__series-icon"></span>
						<span class="ct-legend__series-title">${$li.html()}</span>
					`;

					$li.html(template);
				});
			});

			this.applyCustomColors($chartEl);
		},

		applyCustomColors ($chartEl) {
			// custom coloring - supports up to 16 custom colors per chart
			if (this.params.dataColors) {
				const $chartSeries = $chartEl.find('.ct-series');

				forEach(split('abcdefghijklmnop', ''), (i, index) => {
					if ($chartEl.attr('class') === 'ct-chart-pie') {
						$chartSeries
							.filter(`.ct-series-${i}`)
							.children()
							.css('fill', this.params.dataColors[index]);
					} else {
						$chartSeries
							.filter(`.ct-series-${i}`)
							.children()
							.css('stroke', this.params.dataColors[index]);
					}

					defer(() => {
						$chartEl
							.parent()
							.find(`.ct-series-${index} .ct-legend__series-icon`)
							.css({
								'background-color': this.params.dataColors[index],
								'border-color': this.params.dataColors[index]
							});
					});
				});
			}
		},

		renderChart () {
			const options = {
				fullWidth: true,
				lineSmooth: Chartist.Interpolation.none(),
				thresholdLine: {
					value: this.thresholdLine,
					class: 'ct-threshold-line'
				},
				thresholdRange: {
					value: this.thresholdRange,
					class: 'ct-threshold-range'
				},
				plugins: [],
				...this.options
			};

			!isUndefined(this.params.legendNames) && options.plugins.push(Chartist.plugins.legend({
				legendNames: this.params.legendNames
			}));

			if (this.chartist) {
				this.chartist.update(this.params, options, this.responsiveOptions);

			} else {
				// chart isn't rendered properly when there are no static dimensions provided
				delay(() => {
					// do not try to render the chart when mounting point is no longer available
					if (!this.$refs.chart) {
						return;
					}
					this.chartist =	new Chartist[this.method](
						this.$refs.chart,
						this.params,
						options,
						this.responsiveOptions
					);
					this.updateEventListener(this.listener, 'on');
					this.chartist.on('draw', this.onDraw);
					this.chartist.on('created', this.onCreated);
				}, 500);
			}
		}
	}
});
</script>
