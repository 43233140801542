import { Deferred } from 'jquery';
import { assign, defer, filter, findIndex, forEach, get, isUndefined, keyBy } from 'lodash';
import repository from 'repository';
import { TREATMENT } from '../treatments/treatment';
import { RESPONDENT } from '../respondents/respondent';

// reusable object - need to use a factory function since cloneDeep didn't help
// later there were problems with getters used in tiles
export default () => ({
	namespaced: true,

	state: {
		actionsCount: 0,
		requests: [],
		values: [],
		dfd: Deferred()
	},

	getters: {
		requestCount: (state) => state.requests.length,
		requests: (state) => filter(state.requests, 'ref'),
		valuesByRef: (state) => keyBy(state.values, 'ref')
	},

	mutations: {
		addAction: (state) => {
			state.actionsCount++;
		},

		removeAction: (state) => {
			state.actionsCount--;
		},

		addRequest: (state, request) => {
			state.requests.push(request);
		},

		setValue: (state, { ref, payload }) => {
			const index = findIndex(state.requests, { ref });
			state.values.push(assign(state.requests[index], payload));
		},

		reset: (state) => {
			state.requests = [];
			state.dfd = Deferred();
			state.actionsCount = 0;
		}
	},

	actions: {
		getValues: ({ getters, rootGetters, state, commit }, config) => {
			const scope = (dataSource) => {
				const roleId = get(
					rootGetters[TREATMENT.MAIN_RESPONDENT_ROLE],
					'treatmentTypeRole.id',
					'🐻' // ☞ ͠° ͜つ °☞
				);
				const treatmentTypeId = get(rootGetters.treatment, 'treatmentType.id');
				return {
					treatment: {
						treatmentId: rootGetters[TREATMENT.ID]
					},
					respondent: {
						respondentId: rootGetters[RESPONDENT.RESPONDENT_ID]
					},
					respondentGroup: {
						respondentId: rootGetters[RESPONDENT.RESPONDENT_ID]
					},
					treatmentType: isUndefined(dataSource.treatmentTypeId) ?
						{ treatmentTypeId } :
						null,
					role: isUndefined(dataSource.roleId) ?
						{
							roleId,
							treatmentId: rootGetters[TREATMENT.ID]
						} :
						{ treatmentId: rootGetters[TREATMENT.ID] }
				}[dataSource.scope];
			};

			const request = (source) => assign({}, source, scope(source));
			forEach(config.dataSources, (dataSource) => {
				assign(dataSource, { showGaps: config.gaps });
				commit('addRequest', request(dataSource));
			});
			commit('addAction');

			defer(() => {
				commit('removeAction');

				if (state.actionsCount === 0) {
					repository.assessmentValues(getters.requests).then((responses) => {
						forEach(responses, (response) => {
							commit('setValue', {
								ref: response.ref,
								payload: response
							});
						});

						state.dfd.resolve();
						commit('reset');
					});
				}
			});

			return state.dfd;
		}
	}
});
