import repository from 'repository';
import store from 'store';
import { TREATMENT } from 'store/treatments/rp-treatment';
import systemSettings from 'system-settings';
import afterTreatmentSelect from 'modules/treatments/components/treatment-selection/after-treatment-select';
import { HOME_RESPONDENT } from 'modules/home/constants';
import { LATEST_ASSESSMENT } from 'modules/assessment/constants';

const card = {
	name: 'start',
	url: [
		'start',
		'start?ref=:referral'
	],

	ctrl: ({ close }, { referral }) => {
		const contextTreatmentId = store.getters[TREATMENT.ID];

		if (referral === 'switch-treatment' || contextTreatmentId) {
			afterTreatmentSelect({
				treatmentId: contextTreatmentId,
				referral: 'switch-treatment'
			});

			return;
		}

		const assessmentInstances = repository.getSortedAssessmentInstances({ limit: 1 });
		assessmentInstances.then(() => {
			if (assessmentInstances.size() && systemSettings.getBoolean('RESPONDENT_ITERATE_ON_START')) {
				close({
					openCard: LATEST_ASSESSMENT,
					cardQuery: {
						referral: 'start'
					}
				});
			} else {
				close({ openCard: HOME_RESPONDENT });
			}
		});
	}
};

export { card as start };
