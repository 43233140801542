import DiaryBriefTile from './diary-brief/tile';
import DiaryAddTile from './diary-entry-add/tile';
import DiaryEntriesListTile from './diary-entries-list/tile';
import DiaryEntriesFilterTile from './diary-entries-filter/tile';

export default  {
	'diary-brief': DiaryBriefTile,
	'diary-entry-add': DiaryAddTile,
	'diary-entries-list': DiaryEntriesListTile,
	'diary-entries-filter': DiaryEntriesFilterTile
};
