import repository from 'repository';
import { cloneDeep, isArray, forEach } from 'lodash';
import { update, remove, idx } from '../__helpers/mutations';
import compareParams from '../__helpers/compare-params';

export default {
	namespaced: true,
	state: {
		data: {},
		list: [],
		searchParams: {},
		rolesList: [],
		initialParams: null,
		total: 0
	},
	getters: {
		count: (state) => state.list.length,
		data: (state) => state.data,
		list: (state) => state.list,
		rolesList: (state) => state.rolesList,
		total: (state) => state.total
	},
	mutations: {
		eraseData: (state) => {
			state.data = {};
		},
		setData: (state, data) => {
			state.data = data;
		},
		setList: (state, list) => {
			if (isArray(list)) {
				state.list = list;
			} else {
				state.list.push(list);
			}
		},
		setSearchParams: (state, searchParams) => {
			state.searchParams = searchParams;
		},
		setRolesList: (state, list) => {
			state.rolesList = list;
		},
		setTotal: (state, total) => {
			state.total = total;
		},
		setInitialParams: (state, params) => {
			state.initialParams = params;
		},
		update: update('list'),
		remove: remove('list')
	},
	actions: {
		addRoleSet: (context, data) => repository.addRoleSet(data),
		deleteRoleSet: async ({ commit, state }, id) => {
			const index = idx(state.list, +id);
			const result = await repository.deleteRoleSet(id);
			commit('remove', { index });
			return result;
		},
		search: ({ commit, state }, searchParams = state.searchParams) => {
			if (searchParams) {
				commit('setSearchParams', searchParams);
			}

			if (!state.initialParams) {
				const initialParams = cloneDeep(searchParams);
				delete initialParams.start;
				commit('setInitialParams', initialParams);
			}
			const noFilters = compareParams(state, searchParams);

			const results = repository.searchRoleSets(searchParams);
			return results.then((rawResults, statusText, xhrObj) => {
				const total = xhrObj.getResponseHeader('X-Total-Count');
				commit('setTotal', total);

				if (statusText === 'success') {
					if (noFilters) {
						forEach(rawResults, (item) => {
							const index = idx(state.list, item.id);

							if (index > -1) {
								commit('update', item);
							} else {
								commit('setList', item);
							}
						});
					} else {
						commit('setList', rawResults);
					}
				}

				return [results, statusText, xhrObj];
			});
		},
		searchAll: ({ commit }) => {
			repository.roleSets().then((results) => {
				commit('setList', results);
			});
		},
		searchSingle: ({ commit }, roleSetId) => {
			commit('eraseData');
			return repository.roleSetSingle(roleSetId).then((results) => {
				commit('setData', results);
			});
		},
		updateRoleSet: (context, data) => repository.updateRoleSet(data)
	}
};
