import TileView from 'tile-view';
import t from 'translate';
import acl from 'acl';

export default TileView.extend({
	acl: {},
	instant: true,
	init: ({ tile }) => {
		tile.cfg = tile.config() || {};

		(tile.cfg.ACL || tile.cfg.acl) && !acl.checkAccess(tile.cfg.ACL || tile.cfg.acl) && tile.disable();
	},

	Vue: ({ cfg }) => ({
		data: cfg,
		template: `
				<a v-once class="custom-url__link" :href="url" :target="target">
					<span class="custom-url__content">{{linkTitle}}</span>
				</a>
			`,

		computed: {
			target: function () {
				return this.external ? '_blank'	: '_self';
			},
			linkTitle: function () {
				return this.translate ? t(this.title) : this.title;
			}
		}
	})
});
