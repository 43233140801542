var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items.length
    ? _c(
        "table",
        { staticClass: "table table--long-text", class: _vm.classPrefix },
        [
          _c("thead", [
            _c(
              "tr",
              _vm._l(_vm.visibleFields, function(field, i) {
                return _c(
                  "th",
                  {
                    key: i,
                    class: _vm.classPrefix + "__header-cell--" + field.key
                  },
                  [_vm._t("header", null, { label: field.label })],
                  2
                )
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.items, function(item) {
              return _c(
                "tr",
                {
                  key: item.id,
                  staticClass: "clickable-row",
                  class:
                    _vm.classPrefix + "__row--" + item.status.toLowerCase(),
                  attrs: { tabindex: "0" },
                  on: {
                    click: function($event) {
                      return _vm.onClick(item)
                    }
                  }
                },
                _vm._l(_vm.visibleFields, function(field) {
                  return _c(
                    "td",
                    {
                      key: field.key + item.id,
                      class: _vm.classPrefix + "__cell--" + field.key,
                      attrs: { "aria-label": _vm.ariaLabel(field, item) }
                    },
                    [_vm._t("cell", null, { fieldKey: field.key, item: item })],
                    2
                  )
                }),
                0
              )
            }),
            0
          )
        ]
      )
    : _c("p", [_vm._v("\n\t" + _vm._s(_vm.$t("consents.noItems")) + "\n")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }