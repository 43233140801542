import { add, set } from '../__helpers/mutations';
import { assign, findIndex, map, get, filter } from 'lodash';
import repository from 'repository';
import initEntities from '../__helpers/init-entities';
import { NOT_SUBMITTED } from '../../modules/consents/shared/statuses';

// can't use remove mutation from helper because doesn't work as expected. Problem with index.
const mutation = (type, name) => ({
	remove: (state, id) => {
		const index = findIndex(state[name], { id });
		state[name].splice(index, 1);
	}
}[type]);

export default {
	namespaced: true,

	state: {
		data: [],
		list: [],
		totalConsents: 0,
		searchParams: {
			filters: {
				start: 0,
				limit: 10
			}
		},
		totalNewlyModifiedConsents: 0
	},

	getters: {
		items: (state) => map(
			state.data,
			(item) => assign({}, item, { name: item.consentVersion.name })
		),
		count: (state) => state.data.length,
		notSubmittedCount: (state, getters) => filter(
			getters.items,
			{ status: NOT_SUBMITTED }
		).length,
		data: (state) => state.data,
		isMore: (state) => state.data.length < +state.totalConsents,
		newlyModifiedCount: (state) => state.totalNewlyModifiedConsents
	},

	mutations: {
		add: add(),
		set: set('data'),
		remove: mutation('remove', 'data'),
		setSearchParams: (state, filters) => {
			assign(state.searchParams, filters);
		},
		increaseStart: (state) => {
			state.searchParams.filters.start += state.searchParams.filters.limit;
		},
		resetStart: (state) => {
			state.searchParams.filters.start = 0;
		},
		setTotalConsents: set('totalConsents'),
		setTotalNewlyModifiedConsents: set('totalNewlyModifiedConsents')
	},

	actions: {
		initForTreatment: (
			{ commit }, { treatmentId }
		) => repository.treatmentConsentInstances(treatmentId).then((response) => {
			commit('set', response);
		}),

		initForRespondent: (
			{ commit }, { respondentId }
		) => repository.respondentConsentInstances(respondentId).then((response) => {
			commit('set', response);
		}),

		init: initEntities('consentInstances/init', {
			acl: 'administrator.respondents.consents'
		}),

		initNewlyModifiedConsents: ({ commit }, { clinicianId, value }) =>
			repository.newlyModifiedConsents({ clinicianId, value }).then((response) => {
				commit('set', response);
			}),

		newlyModifiedConsentsCount: ({ commit, rootGetters }) => {
			const count = repository.newlyModifiedConsentsCount({
				clinicianId: get(rootGetters, 'userId')
			});

			return count.then((response) => {
				commit('setTotalNewlyModifiedConsents', response);
			});
		},
		search: ({ state, commit }, { push } = {}) => {
			const promise = repository.searchConsent({
				...state.searchParams
			});

			return promise.then((data) => {
				commit(push ? 'add' : 'set', data);
				commit('setTotalConsents', promise.getResponseHeader('X-Total-Count'));
			});
		},

		add: ({ commit }, payload) => {
			commit('add', payload);
		},

		remove: (
			{ commit }, consentInstanceId
		) => repository.removeConsentInstance({ consentInstanceId }).then(() => {
			commit('remove', consentInstanceId);
		})
	}
};
