import { forEach, isFunction, isUndefined } from 'lodash';
import ExpressionMap from '../expression/ExpressionMap';
import answeroptions from '../answer-options';
import { warn } from 'service/log/log';

export default (tpl) => {
	const options = {};

	/* auto reverse values */
	forEach(tpl.options, (option, i) => {
		if (isUndefined(option.reverseValue)) {
			option.reverseValue = tpl.options[tpl.options.length - 1 - i].value;
		}
	});

	forEach(tpl.options, (option) => {
		const type = option.type.replace(/^questionnaire:/, '');

		option.expressionMap = new ExpressionMap(option);

		if (isFunction(answeroptions[type])) {
			options[option.code] = new answeroptions[type](option);

		} else {
			warn(`Answer option of type '${type}' not implemented!`);
		}
	});

	return options;
};
