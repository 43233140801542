var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("label", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value"
          }
        ],
        staticClass: "edit-object__radio",
        attrs: {
          name: _vm.uniqueInputName,
          type: "radio",
          disabled: _vm.readonly
        },
        domProps: { value: true, checked: _vm._q(_vm.value, true) },
        on: {
          change: function($event) {
            _vm.value = true
          }
        }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "edit-object__value-description" }, [
        _vm._v("true")
      ])
    ]),
    _vm._v(" "),
    _c("label", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value"
          }
        ],
        staticClass: "edit-object__radio",
        attrs: {
          name: _vm.uniqueInputName,
          type: "radio",
          disabled: _vm.readonly
        },
        domProps: { value: false, checked: _vm._q(_vm.value, false) },
        on: {
          change: function($event) {
            _vm.value = false
          }
        }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "edit-object__value-description" }, [
        _vm._v("false")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }