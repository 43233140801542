import { findIndex, map, sortBy } from 'lodash';
import repository from 'repository';
import { cleanClinicianGroup } from '../__helpers/clean';

export default {
	namespaced: true,

	state: {
		data: {},
		clinicianGroups: []
	},

	getters: {
		data: (state) => state.data,
		clinicianGroups: (state) => sortBy(
			state.clinicianGroups,
			(group) => group.name.toLocaleLowerCase()
		)
	},

	mutations: {
		set: (state, data) => {
			state.data = data;
		},

		setClinicianGroups: (state, groups) => {
			state.clinicianGroups = map(groups, cleanClinicianGroup);
		},

		addClinicianGroup: (state, group) => {
			state.clinicianGroups.push(group);
		},

		removeClinicianGroup: (state, id) => {
			const index = findIndex(state.clinicianGroups, { id });
			state.clinicianGroups.splice(index, 1);
		}
	},

	actions: {
		init: ({ commit }, id) => {
			commit('set', { id });
		},
		initClinicianGroups: ({ commit }, contentPackageId) =>
			repository.contentPackageClinicianGroups(contentPackageId).then((groups) => {
				commit('setClinicianGroups', groups);
			}),

		addClinicianGroup: ({ commit, state }, group) => {
			commit('addClinicianGroup', group);

			return repository.addClinicianGroupToContentPackage({
				contentPackageId: state.data.id,
				clinicianGroupId: group.id
			});
		},

		removeClinicianGroup: ({ commit, state }, id) => {
			commit('removeClinicianGroup', id);

			return repository.removeClinicianGroupFromContentPackage({
				contentPackageId: state.data.id,
				clinicianGroupId: id
			});
		}
	}
};
