<template>
	<section>
		<h1 class="consent-content__header">
			{{consent.name}}
		</h1>
		<consent-content
			:options="options"
			:on-submit="onSubmit"
			:on-option-toggle="onOptionToggle"
			:submittable="submittable"
		>
			<template #content>
				<div v-html="consent.content" />
			</template>

			<template #optionContent="{ content }">
				<div v-html="content" />
			</template>

			<template v-if="!consent.canSubmit" #checkbox="{ option }">
				<icon
					:name="iconName(option.status)"
					class="option__answer"
					:class="className(option.status)"
				/>
			</template>
		</consent-content>
	</section>
</template>

<script>
import ConsentContent from '../../components/consent-content';
import confirmation from 'components/confirmation/confirmation';
import t from 'translate';
import cwalert from 'cwalert';
import { NOT_SUBMITTED, GIVEN, NOT_GIVEN } from '../../shared/statuses';
import store, { APP } from 'store';

export default {
	title: '',
	actions: (tile) => {
		const consentInstanceId = tile.cardContext().get('consentInstanceId');
		const obtainTreatment = !store.getters[APP.DETACHED];
		return [
			['respondentConsentInstance/init', { consentInstanceId, obtainTreatment }]
		];
	},
	components: { ConsentContent },
	computed: {
		consent: ({ $store }) => $store.getters['respondentConsentInstance/item'],
		options: ({ $store }) => $store.getters['respondentConsentInstance/options'],

		submittable: ({ consent, options }) => consent.canSubmit && !!options.length
	},
	methods: {
		onSubmit () {
			confirmation({
				title: t('consents.submitConsent'),
				message: t('consents.description.submit')
			}).done(() => {
				this.$store.dispatch('respondentConsentInstance/submit', this.options);
				cwalert.success(t('consents.submittedSuccess'));
				this.tile.card().close();
			});
		},

		iconName: (status) => ({
			[GIVEN]: 'check-circle',
			[NOT_GIVEN]: 'times-circle',
			[NOT_SUBMITTED]: 'minus-circle'
		}[status] || null),

		className: (status) => ({
			[GIVEN]: 'option__answer--given',
			[NOT_GIVEN]: 'option__answer--not-given',
			[NOT_SUBMITTED]: 'option__answer--not-submitted'
		}[status] || null),

		onOptionToggle ({ index }) {
			this.options[index].answer.answer = !this.options[index].answer.answer;
		}
	}
};
</script>
