import repository from 'repository';
import { find, cloneDeep, isArray, forEach } from 'lodash';
import { update, remove, idx } from '../__helpers/mutations';
import compareParams from '../__helpers/compare-params';

export default {
	namespaced: true,
	state: {
		emptyRelation: {
			description: '',
			name: '',
			permanent: false,
			relationType: [],
			respondent1RoleName: '',
			respondent2RoleName: '',
			substituted: false
		},
		list: [],
		relation: {},
		roleRelations: [
			{
				label: '>',
				value: 'OWNER'
			},
			{
				label: '=',
				value: 'EQUAL'
			}
		],
		searchParams: {},
		substituted: [{
			id: 0,
			name: 'Individual'
		}, {
			id: 1,
			name: 'Substituted'
		}],
		total: 0,
		initialParams: null,
		types: [{
			id: 1,
			name: 'Permanent'
		}, {
			id: 0,
			name: 'Inconstant'
		}]
	},
	getters: {
		types: (state) => state.types,
		count: (state) => state.list.length,
		list: (state) => state.list,
		model: (state) => state.model,
		relation: (state) => state.relation,
		roleRelations: (state) => state.roleRelations,
		substituted: (state) => state.substituted,
		total: (state) => state.total
	},
	mutations: {
		setEmptyRelation: (state) => {
			state.relation = { ...state.emptyRelation, relationType: state.roleRelations[0] };
		},
		setList: (state, list) => {
			if (isArray(list)) {
				state.list = list;
			} else {
				state.list.push(list);
			}
		},
		setSearchParams: (state, searchParams) => {
			state.searchParams = searchParams;
		},
		setRelation: (state, payload) => {
			const relationType = find(state.roleRelations, { value: payload.relationType });
			state.relation = { ...payload, relationType };
		},
		setTotal: (state, total) => {
			state.total = total;
		},
		setInitialParams: (state, params) => {
			state.initialParams = params;
		},
		update: update('list'),
		remove: remove('list')
	},
	actions: {
		addRelation: (context, data) => repository.addRelationType(data),

		delete: async ({ commit, state }, id) => {
			const index = idx(state.list, id);
			const result = await repository.deleteRelationType(id);
			commit('remove', { index });
			return result;
		},

		getSingleRelationType: ({ commit }, id) => {
			if (id === '0') {
				return commit('setEmptyRelation');
			}
			return repository.relationType(id).then((result) => {
				commit('setRelation', result);
			});
		},

		search: ({ commit, state }, searchParams = state.searchParams) => {
			if (searchParams) {
				commit('setSearchParams', searchParams);
			}

			if (!state.initialParams) {
				const initialParams = cloneDeep(searchParams);
				delete initialParams.start;
				commit('setInitialParams', initialParams);
			}
			const noFilters = compareParams(state, searchParams);

			const results = repository.searchRelationTypes(searchParams);
			return results.then((rawResults, statusText, xhrObj) => {
				const total = xhrObj.getResponseHeader('X-Total-Count');
				commit('setTotal', total);

				if (statusText === 'success') {
					if (noFilters) {
						forEach(rawResults, (item) => {
							const index = idx(state.list, item.id);

							if (index > -1) {
								commit('update', item);
							} else {
								commit('setList', item);
							}
						});
					} else {
						commit('setList', rawResults);
					}
				}

				return [results, statusText, xhrObj];
			});
		},

		updateRelation: (context, data) => repository.updateRelationType(data)
	}
};
