import repository from 'repository';
import t from 'translate';
import { map, sortBy, truncate } from 'lodash';
import prefixify from '../__helpers/prefixify';
import { exact } from '../__helpers/getters';

const prefix = prefixify('contexts');

// getters
const DATA = 'data';
const SORTED = 'sorted';
const SORTED_WITH_EMPTY = 'sortedWithEmpty';
const SHORT_LABEL = 'shortLabel';

// mutations
const SET = 'set';

// actions
const INIT = 'init';

export const CONTEXTS = {
	// getters
	DATA: prefix(DATA),
	SORTED: prefix(SORTED),
	SORTED_WITH_EMPTY: prefix(SORTED_WITH_EMPTY),
	SHORT_LABEL: prefix(SHORT_LABEL),

	// actions
	INIT: prefix(INIT)
};

export default {
	namespaced: true,

	state: {
		data: [],
		collection: {}
	},

	getters: {
		...exact([DATA]),
		[SORTED]: (state) => sortBy(state[DATA], 'assessmentInstanceContextOrder'),
		[SORTED_WITH_EMPTY]: (state) => [{
			assessmentInstanceContextLabel: t('assessment__properties__noContextAssigned'),
			assessmentInstanceContextId: ''
		}, ...sortBy(state[DATA], 'assessmentInstanceContextOrder')],
		[SHORT_LABEL]: (state) => map(state[DATA], (context) => {
			const { assessmentInstanceContextLabel: label } = context;
			context.assessmentInstanceContextLabel = truncate(label, { length: 64 });

			return context;
		})
	},

	mutations: {
		[SET]: (state, { contexts, collection }) => {
			state[DATA] = contexts;
			state.collection = collection;
		}
	},

	actions: {
		[INIT]: ({ commit }) => {
			const collection = repository.getContexts();

			return collection.then(() => {
				commit(SET, { contexts: collection.toJSON(), collection });
			});
		}
	}
};
