import repository from 'repository';
import { set } from '../__helpers/mutations';

export default {
	namespaced: true,
	state: {
		codeId: null
	},

	mutations: {
		setCodeId: set('codeId')
	},

	actions: {
		addAndRefresh: ({ state, dispatch }, data) =>
			dispatch(
				'add',
				{ codeId: data.codeId, variantId: data.code }
			).then(() => dispatch('dataIntegration/codes/getSingle', state.codeId, { root: true })),

		add: (context, data) => repository.addCodeVariation(data),

		deleteAndRefresh: ({ state, dispatch }, id) => dispatch(
			'deleteCodeVariation',
			id
		).then(() => dispatch('dataIntegration/codes/getSingle', state.codeId, { root: true })),

		deleteCodeVariation: (context, id) => repository.deleteCodeVariation(id),

		init: ({ commit }, codeId) => commit('setCodeId', codeId)
	}
};
