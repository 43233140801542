import SharedView from './shared';
import { compact, forEach, head, isObject, last, map } from 'lodash';
import optionLabel from '../helpers/option-label';
import { fixedOptions } from '../shared/options';

export default SharedView.extend({
	type: 'Range',

	startValues () {
		const found = [null, null];
		const orderedAnswers = this.orderedAnswers();

		if (!compact(this.model.values()).length) {
			found[0] = head(orderedAnswers);
			found[1] = last(orderedAnswers);

		} else {
			forEach(this.model.values(), (value) => {
				if (value.selectedMin) {
					found[0] = value;
				}

				if (value.selectedMax) {
					found[1] = value;
				}
			});
		}

		return found;
	},

	options () {
		return fixedOptions({
			connect: true,
			endLabelsVisible: this.model.get('endLabelsVisible'),
			getUrl: this.model.mediaManager.getUrl,
			labelsVisible: this.model.get('labelsVisible'),
			orderedAnswers: this.orderedAnswers(),
			start: this.startValues()
		});
	},

	changeValue ({ decrease } = { decrease: false }) {
		return (index) => {
			const answers = this.orderedAnswers();
			const values = this.slider.noUiSlider.get();
			const value = values[index];
			const answerIndex = answers.indexOf(value);
			const newAnswerIndex = decrease ? answerIndex - 1 : answerIndex + 1;

			if (answers[newAnswerIndex]) {
				values[index] = answers[newAnswerIndex];
			}
			this.slider.noUiSlider.set(values);
		};
	},

	onRender () {
		const selectValues = ({ values, handle }) => {
			if (this.model.isAnswered()) {
				values[0].selectAsMin();
				values[1].selectAsMax();

				// in some cases min value is lost, recover it
				if (!this.model.isAnswered()) {
					values[0].selectAsMin();
				}

			} else if (handle === 0) {
				values[0].selectAsMin();

			} else if (handle === 1) {
				values[1].selectAsMax();
			}
		};

		const selectPartially = ({ handle, modelValues, values, unsetIndex }) => {
			const newValues = [];

			if ((handle === 0 && modelValues[handle] > values[handle]) ||
				(handle === 1 && modelValues[handle] < values[handle])) {

				newValues[handle] = values[handle];
				newValues[unsetIndex] = modelValues[handle];

			} else {
				newValues[handle] = modelValues[handle];
				newValues[unsetIndex] = values[handle];
			}

			newValues[0].selectAsMin();
			newValues[1].selectAsMax();
			this.slider.noUiSlider.set(newValues);
		};

		this.slider.noUiSlider.on('update', (values, handle) => {
			const answeredValues = this.model.values();
			answeredValues[handle] = values[handle];

			this.setAriaValue({
				values: map(answeredValues, (option) => this.orderedAnswers().indexOf(option)),
				text: map(
					answeredValues,
					(option) =>	option && option.getType() === 'TextOption' ?
						option.getLabel() :
						' '
				).join('-')
			});
		});

		this.slider.noUiSlider.on('set', (values, handle) => {
			const modelValues = this.model.values();
			const unsetIndex = modelValues.indexOf(undefined);

			if (
				!this.model.isAnswered() &&
				compact(modelValues).length === 1 &&
				unsetIndex !== handle
			) {
				selectPartially({ values, modelValues, handle, unsetIndex });
				return;
			}

			selectValues({ handle, values });

			this.getHandle(handle).addClass('answered');
			const answeredValues = this.model.isAnswered() ? values : [values[handle]];

			this.model.setValues(map(answeredValues, (val) => parseInt(val, 10)));
			this.handleAnswered();
		});

		this.model.get('answerVisible') && this.renderLabel(this.startValues());
	},

	renderLabel (values) {
		const min = values[0];
		const max = values[1];

		const defaultValueOnStart = () => !this.model.get('valueSetByInput') &&
			!!compact(this.model.defaultValues()).length;
		const isAnswered = () => (this.model.isAnswered() || defaultValueOnStart()) &&
			isObject(min) &&
			isObject(max);
		const minLabel = optionLabel({
			option: min,
			getUrl: this.model.mediaManager.getUrl
		});
		const maxLabel = optionLabel({
			option: max,
			getUrl: this.model.mediaManager.getUrl
		});

		this.$answer.html(
			this.answer(isAnswered() ? `${minLabel} - ${maxLabel}` : this.labels.none)
		);
		this.$slider.toggleClass('slider--unanswered', !isAnswered());
	}
});
