var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "table-view__filters" },
    [
      _vm.totalCountExists
        ? _c("items-count", {
            attrs: { current: _vm.currentCount, total: _vm.totalCount }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-view__filter-controls" },
        _vm._l(_vm.config.filters, function(filterCfg) {
          return _c("filter-input", {
            key: filterCfg.name,
            attrs: {
              config: filterCfg,
              "filter-id": _vm.filterId(filterCfg.name)
            },
            model: {
              value: _vm.filters[filterCfg.name],
              callback: function($$v) {
                _vm.$set(_vm.filters, filterCfg.name, $$v)
              },
              expression: "filters[filterCfg.name]"
            }
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.config.searchable
        ? _c("filter-search", {
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.config.clearable
        ? _c("clear-filters", {
            attrs: { "can-clear": _vm.canClear },
            on: { click: _vm.clear }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }