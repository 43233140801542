import {
	every, forEach, includes, isArray, isBoolean, isNumber, isObject, isPlainObject, isString, size
} from 'lodash';

const checks = {
	boolean: (value) => isBoolean(value) || includes(['true', 'false'], value),
	number: isNumber,
	string: isString,
	objectArray: (value) =>
		size(value) &&
		isArray(value) &&
		every(value, (element) => isPlainObject(element)),
	object: (value) => !isArray(value) && isObject(value),
	matrix: (value) =>
		size(value) &&
		isArray(value) &&
		every(
			value,
			(element) =>
				isArray(element) &&
				element.length === 2 &&
				Number.isFinite(+element[0]) &&
				Number.isFinite(+element[1])
		),
	arrayArray: (value) =>
		size(value) &&
		isArray(value) &&
		every(value, (element) => isArray(element)),
	array: isArray
};

const valueType = (value) => {
	let result = 'string';

	forEach(checks, (check, type) => {
		if (check(value)) {
			result = type;
			return;
		}
	});

	return result;
};

export { checks, valueType };
