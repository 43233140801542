import repository from 'repository';
import { set } from '../__helpers/mutations';
import prefixify from '../__helpers/prefixify';

const prefix = prefixify('adapterInstanceSetting');

// getters
const DATA = 'data';

// actions
const FETCH = 'fetch';
const UPDATE = 'update';

export const ADAPTER_INSTANCE_SETTING = {
	DATA: prefix(DATA),

	FETCH: prefix(FETCH),
	UPDATE: prefix(UPDATE)
};

export default {
	namespaced: true,

	state: {
		data: null
	},

	getters: {
		[DATA]: (state) => state.data
	},

	mutations: {
		set: set()
	},

	actions: {
		[FETCH]: ({ commit }, { settingId }) =>
			repository.fetchAdapterInstanceSetting({ settingId })
				.then((data) => {
					commit('set', data);
				}),

		[UPDATE]: (context, { settingId, data }) =>
			repository.updateAdapterInstanceSetting({ settingId, data })
	}
};
