import SharedModel from './shared';
import { isNumber, isUndefined } from 'lodash';

export default SharedModel.extend({
	defaults: {
		config: {
			valuesCount: 1
		}
	},

	onInitialize () {
		this.defaultValue()() !== false && this.setValue(this.defaultValue()(), {
			defaultValue: true
		});
	},

	values () {
		return isNumber(this.value()) ? [this.value()] : [];
	},

	getResponse ({ submit } = { submit: false }) {
		if (submit && !this.isAnswered()) {
			return;
		}

		return {
			questionCode: this.get('id'),
			timeStamp: new Date().getTime(),
			responseType: 'number',
			number: this.get('val')
		};
	},

	setResponse (response) {
		this.setValue(response.number);
	},

	onClear () {
		this.setValue(undefined);
	},

	onUnclear ({ reset } = { reset: false }) {
		if (reset === true) {
			this.setValue(undefined);

		} else if (isUndefined(this.value())) {
			this.setValue(this.defaultValue()(), { defaultValue: true });
		}
	}
});
