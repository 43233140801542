import { isBoolean } from 'lodash';
import acl from 'service/acl/acl';
/*

A calculated boolean value that can be set in JSON configuration
1. Using simple boolean true/false
2. Using ACL with/out `negative` option
ACL - result is true when provided and condition met
negative - result value will be negated, default: false

*/
export default (conditionalValue) => {
	if (isBoolean(conditionalValue)) {
		return conditionalValue;
	}

	if (conditionalValue.ACL) {
		const result = acl.checkAccess(conditionalValue.ACL);

		return conditionalValue.negative ? !result : result;
	}

	return false;
};
