import { View } from 'marionette';
import { compile } from 'handlebars';

const CardLayout = View.extend({
	template: compile(`
		<div class="progress-bar progress-bar--card" role="presentation">
			<div class="progress-bar__indicator"></div>
		</div>
		<div class="card-container"></div>
	`),
	regions: {
		cardContent: '.card-container'
	}
});

export default new CardLayout();
