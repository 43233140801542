<template>
	<div>
		<section
			v-for="({links, label}) in groupedLinks"
			:key="label"
			class="information-package-elements__container"
		>
			<h3 v-if="label" class="information-package-elements__title">
				{{label}}
			</h3>
			<ul class="generic-list__list information-package-elements">
				<li
					v-for="link in links"
					:key="link.id"
					class="generic-list__item assessment-list__item"
				>
					<div
						:class="linkClassName(link)"
						tabindex="0"
						@click="handleLink(link)"
						@keyup.enter="handleLink(link)"
					>
						<icon name="link" :title="$t('Link')" />
						<div
							class="
								generic-list__action-title-container
								information-package-element__action-title-container
							"
						>
							<p
								class="
									generic-list__action-title
									information-package-element__action-title
								"
							>
								{{link.elementVersion.alias}}
							</p>
							<p class="information-package-element__state-label">
								{{stateLabel(link)}}
							</p>
						</div>
					</div>
				</li>
			</ul>
		</section>
	</div>
</template>

<script>
import systemSettings from 'system-settings';
import {
	RESPONDENT_CONTENT_PACKAGE_INSTANCES
} from 'store/content-packages/rp-content-package-instances';
import { CONTEXTS } from 'store/general/contexts';
import { TYPES } from 'store/general/types';
import { RESPONDENT_LINKS } from 'store/links/rp-links';
import Icon from 'components/icon';
import { filter, get, map, find, size } from 'lodash';
import t from 'translate';

export default {
	title: t('Links'),
	actions: () => [
		[RESPONDENT_CONTENT_PACKAGE_INSTANCES.INIT],
		[RESPONDENT_LINKS.INIT],
		systemSettings.getBoolean('ASSESSMENT_TYPES') ? [TYPES.INIT] : [CONTEXTS.INIT]
	],
	features: ['ENABLE_RESPONDENT_LINKS'],
	components: { Icon },
	computed: {
		groupBy: () => systemSettings.getBoolean('ASSESSMENT_TYPES') ? 'type' : 'context',
		informationPackages: ({ $store }) =>
			$store.getters[RESPONDENT_CONTENT_PACKAGE_INSTANCES.ITEMS],
		rplinks: ({ $store }) => $store.getters[RESPONDENT_LINKS.DATA],
		groups: ({ $store }) => systemSettings.getBoolean('ASSESSMENT_TYPES') ?
			$store.getters[TYPES.DATA] :
			$store.getters[CONTEXTS.DATA],
		groupedLinks: ({ rplinks, informationPackages, groupBy, groups }) => {
			const array = [];

			const linksWithoutGroup = filter(rplinks, (link) => {
				const instanceId = get(link, 'instance.id');
				const ipInstance = find(informationPackages, ({ id }) => +id === +instanceId);
				return !ipInstance[groupBy];
			});

			// add links without group
			array.push({
				label: '',
				links: linksWithoutGroup
			});

			const linksWithGroup = map(groups, (group) => {
				const id = +group.id || +group.assessmentInstanceContextId;
				const linksArr = filter(rplinks, (link) => {
					const instanceId = get(link, 'instance.id');
					const ipInstance = find(
						informationPackages,
						(informationPackage) => +informationPackage.id === +instanceId
					);
					return +ipInstance[groupBy] === +id;
				});

				return {
					label: group.assessmentInstanceContextLabel || group.label,
					links: linksArr
				};
			});

			array.push(...linksWithGroup);

			// remove empties
			return filter(array, (group) => size(group.links));
		}
	},
	methods: {
		linkClassName: (link) => `generic-list__action
				information-package-element__action
				information-package-element--${link.state}`,
		stateLabel (link) {
			return {
				NOT_STARTED: this.$t('Not started'),
				IN_PROGRESS: this.$t('In progress'),
				COMPLETED: this.$t('Completed')
			}[link.state];
		},
		async handleLink (link) {
			// mark as complete
			await this.$store.dispatch(
				RESPONDENT_LINKS.COMPLETE,
				{
					contentPackageId: link.instance.id,
					elementId: link.id
				}
			);

			// improve after decision from defect 12191 - use a tag with url() method
			if (link.link.openInNewWindow) {
				window.open(link.link.url, '_blank');
			} else {
				window.location = link.link.url;
			}
		}
	}
};
</script>
