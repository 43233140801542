<template>
	<select-input
		v-model="model"
		:options="items"
		:label="$t(label)"
		:reduce="(item) => item.id"
		class-prefix="table-view"
		container-class="filter-combo"
	/>
</template>

<script>
import { constant } from 'lodash';
import SelectInput from 'components/form/select-input';
export default {
	components: { SelectInput },
	props: {
		filterId: {
			type: String,
			default: ''
		},
		items: {
			type: Array,
			default: constant([])
		},
		label: {
			type: String,
			default: ''
		},
		loading: {
			type: Boolean,
			required: true
		},
		value: {
			type: [Number, String],
			required: true
		}
	},

	computed: {
		model: {
			get: ({ value }) => value,
			set (newVal) {
				this.$emit('input', newVal || '');
			}
		}
	}
};
</script>
