import SingleEntity from '../shared/single-entity';

/**
 * Report Entity
 * @class Report
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	idAttribute: 'reportId',
	eventNamespace: 'report-instance',

	isGenerated: function () {
		return !this.get('isNotGenerated');
	}
});
