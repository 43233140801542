<template>
	<div>
		<a v-if="token" :href="url" class="flat-button__button">
			{{buttonLabel}}
		</a>
		<p v-if="error">
			{{errorMessage}}
		</p>
	</div>
</template>

<script>
import repository from 'repository';
import cwalert from 'service/cwalert';
import { error } from 'service/log/log';

const wrapperUrl = ({ username, token }) => `wrapper://username=${username}_token=${token}`;

export default {
	props: {
		assessmentInstanceId: {
			type: Number,
			required: true
		}
	},

	data: ({ $t }) => ({
		username: 'placeholder', // Not needed by wrapper url, but anything should be passed
		token: null,
		error: false,
		buttonLabel: $t('ibdoc.measure'),
		errorMessage: $t('ibdoc.initError')
	}),

	computed: {
		url: ({ username, token }) => wrapperUrl({ username, token })
	},

	async created () {
		try {
			const data = await repository.ibdocToken(this.assessmentInstanceId);

			if (!data.api_key) {
				throw 'Credentials inaccessible.';
			}

			this.token = data.api_key;
		} catch (e) {
			error(this.errorMessage, e);
			cwalert.error(this.errorMessage);
			this.error = true;
		}
	}
};
</script>
