import MultipleEntities from '../shared/multiple-entities';
import Role from './role-entity';
import urls from './urls';

/**
 * TreatmentTypeRoles Entity
 * @class TreatmentTypeRoles
 * @extends MultipleEntities
 */
const TreatmentTypeRoles = MultipleEntities.extend({
	model: Role,
	urls,
	eventNamespace: 'treatment-type-role',

	canAdd: function (model) {
		return +model.get('treatmentType') === +this.ownerId;
	},

	/**
	 * Get all treatment type roles
	 * @method getByTreatmentType
	 * @return {Object} new RolesEntity Collection
	 * @example
	 *        Repository.TreatmentTypeRoles.getByTreatmentType();
	 */
	getByTreatmentType: function (treatmentTypeId) {
		return this.retrieve('listRoles', treatmentTypeId);
	}

});

export default new TreatmentTypeRoles();
