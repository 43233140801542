var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-click-outside",
          rawName: "v-on-click-outside",
          value: _vm.hide,
          expression: "hide"
        }
      ],
      class: _vm.containerClassNames,
      on: {
        keyup: [
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            ) {
              return null
            }
            return _vm.hide($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
            ) {
              return null
            }
            return _vm.onUp($event)
          },
          function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "down", 40, $event.key, [
                "Down",
                "ArrowDown"
              ])
            ) {
              return null
            }
            return _vm.onDown($event)
          }
        ]
      }
    },
    [
      _c(
        "button",
        {
          ref: "trigger",
          class: _vm.filterClassNames,
          attrs: { tabindex: "0" },
          on: {
            click: function($event) {
              return _vm.toggle()
            }
          }
        },
        [
          _vm._v("\n\t\t" + _vm._s(_vm.label) + "\n\t\t"),
          _c("span", { staticClass: "filter__toggle-number" }, [
            _vm._v("(" + _vm._s(_vm.items.length) + ")")
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "filter__list", class: _vm.listClassNames },
        _vm._l(_vm.items, function(option) {
          return _c(
            "li",
            {
              key: option.id,
              staticClass: "filter__list-item",
              class: _vm.listItemClassNames(option)
            },
            [
              _c(
                "button",
                {
                  ref: "items",
                  refInFor: true,
                  class: _vm.buttonClasses(option),
                  attrs: { type: "button", tabindex: "0" },
                  on: {
                    click: function($event) {
                      return _vm.optionToggle(option.id)
                    }
                  }
                },
                [_vm._v("\n\t\t\t\t" + _vm._s(option.label) + "\n\t\t\t")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "filter__remove" }, [
                _c("button", {
                  staticClass: "filter__remove-action",
                  attrs: { tabindex: "0", title: _vm.$t("Remove filter") },
                  on: {
                    click: function($event) {
                      return _vm.removeFilter(option.id)
                    }
                  }
                })
              ])
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }