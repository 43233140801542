import $ from 'jquery';

/**
 * Close the "modal" layer put above the tile.
 *
 * @function TileView.closeModal
 * @param {Object} opts - Options.
 * @param {boolean} opts.detach  - Should the "modal" be only detached from DOM without removing
 * event listeners.
 * @returns {Object} - Tile object.
 * @deprecated
 * @example
 * import $ from 'jquery';
 *
 * export default TileView.extend({
 *     loaded: ({ tile }) => {
 *         tile.showModal($('<div />');
 *         tile.closeModal();
 *     }
 * });
 */
export default function (opts = {}) {
	if (opts.detach) {
		$('.tile__modal', this.el.parentElement.parentElement).hide().children().detach();

	} else {
		$('.tile__modal', this.el.parentElement.parentElement).hide().empty();
	}

	$(this.el.parentElement).show();
	return this;
}

