import repository from 'repository';
import { findIndex } from 'lodash';

export default {
	namespaced: true,

	state: {
		data: []
	},
	mutations: {
		set: (state, payload) => {
			state.data = payload;
		},
		// it is like this because we use CWTable :(
		update: (state, { type, clinician }) => {
			if (type === 'add') {
				state.data.push(clinician);
			} else { // remove clinician
				const index = findIndex(state.data, ({ administratorId }) =>
					administratorId === clinician
				);
				state.data.splice(index, 1);
			}
		}
	},
	actions: {
		init: ({ commit, rootGetters }) => {
			const deniedClinicians = repository.respondentDenied({
				respondentId: rootGetters['respondent/respondentId']
			});

			return deniedClinicians.then((data) => {
				commit('set', data);
			});
		},

		add: ({ commit, rootGetters }, { administratorId: clinicianId }) => {
			const addDeniedClinician = repository.addRespondentDenied({
				respondentId: rootGetters['respondent/respondentId'],
				clinicianId
			});
			return addDeniedClinician.then((response) => {
				commit('update', { type: 'add', clinician: response });
			});
		},

		remove: ({ commit, rootGetters }, { administratorId: clinicianId }) => {
			const delDeniedClinician = repository.removeRespondentDenied({
				respondentId: rootGetters['respondent/respondentId'],
				clinicianId
			});
			return delDeniedClinician.then(() => {
				commit('update',{ type: 'remove', clinician: clinicianId });
			});
		}
	}
};
