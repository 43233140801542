import { compile } from 'handlebars';
import $ from 'jquery';
import { assign, get, invoke, isFunction, noop } from 'lodash';
import appContext from 'app-context';
import executeIfFunction from 'execute-if-function';

// @TODO(2017-04-24): make it a proper Vue component

const PREFIX = 'card-dialog__';

const $get = (selector) => $(`.${PREFIX}${selector}`);

const buildTheHtml = ({ $parent, mode }) => {
	const compact = mode === 'compact';

	return $parent
		.html(compile(`
		<div class="card-dialog__shadow"></div>
		<div aria-modal="true">
			{{#if compact}}
			<div class="card-dialog__top-bar">
				<button tabindex="0" class="card-dialog__dismiss">{{{icon 'times'}}}</button>
				<h2 class="card-dialog__title"></h2>
			</div>
			{{/if}}
			<div class="card-dialog__window">
				<div class="card-dialog__loader"></div>
				<div class="card-dialog__content"></div>
			</div>
		</div>
	`)({ compact }));
};

const trapFocus = function () {
	const $content = $get('content');

	$content
		.attr('tabindex', 0)
		.trigger('focus')
		.on('focusout', (event) => {
			const content = $get('content')[0];
			let focused = event.relatedTarget;

			if (!focused) {
				return; // msie bug
			}

			while (focused) {
				if (focused.parentNode === content) {
					return;

				}
				focused = focused.parentNode;
			}

			// focus out of the dialog
			$content.trigger('focus');
		});
};

export default function (params) {
	const mode = get(params, 'mode', 'compact');
	const $parent = $(
		`<div
			class="
				${PREFIX}container
				${PREFIX}container--${mode}
			"
		/>`
	).appendTo('.app__container');
	const $el = {
		cardModal: buildTheHtml({ $parent, mode }),
		content: $get('content'),
		loader: $get('loader'),
		dismiss: $get('dismiss'),
		title: $get('title')
	};

	$get('window').addClass(params.name || '');

	const addEventListeners = () => {
		if (mode === 'compact') {
			// 'close' button clicked
			$el.dismiss.on('click', this.cancel.bind(this));

			// ESC key pressed
			$(document).on('keyup', (e) => {
				if (e.keyCode === 27) {
					this.cancel();
				}
			});
		}

		// app event called (most probably -- in tile, card or form-view-dialog service)
		appContext.on({
			'dialog:close': this.close.bind(this),
			'dialog:cancel': this.cancel.bind(this)
		});
	};

	assign(this, {
		init () {
			const title = params.title || '';
			const className = params.className ? `${PREFIX}container--${params.className}` : '';

			addEventListeners();
			appContext.trigger('dialog.open');
			$el.cardModal.addClass(`${PREFIX}container--show`).addClass(className);
			!params.preventFocusTrap && trapFocus();
			$('.tile__content').addClass(`tile__content--invisible`);
			$el.title.text(title);
			this.setContent(params.content);
			window.scrollTo(window.scrollX, window.scrollY);
		},

		setLoading () {
			$el.content.hide();
			$el.loader.show();
			return this;
		},

		setLoaded () {
			$el.loader.hide();
			$el.content.show();
			return this;
		},

		setContent (content) {
			$el.content.append(content);
			return this;
		},

		close () {
			if (isFunction(params.close)) {
				const result = params.close.call(this);

				if (result === false) {
					return false;
				}
			}
			$el.content.off('focusout');
			$('.tile__content').removeClass(`tile__content--invisible`);
			$el.cardModal.removeClass(`${PREFIX}container--show`);
			appContext.trigger('dialog.close');
			appContext.off('dialog:close');
			// remove cardDialog from DOM
			invoke($parent[0], 'parentNode.removeChild', $parent[0]);
		},

		cancel () {
			const onCancel = params.onCancel || noop;
			onCancel();
			// @TODO ups... we have to callbacks, refactor needed
			executeIfFunction(this.onCancel);
			this.close();
		}
	});

	this.init();
}
