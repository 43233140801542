import codes from './codes';
import codeTypes from './code-types';
import codeVariations from './code-variations';
import dataMapping from './data-mapping';
import enterprises from './enterprises';
import maintenanceMode from './maintenance-mode';
import roleMapping from './role-mapping';
import ruleSets from './rule-sets';
import { clone, concat, merge, size } from 'lodash';
import repository from 'repository';
import t from 'service/lang/translate';

export default merge(
	{
		namespaced: true,
		modules: {
			codes,
			codeTypes,
			codeVariations,
			enterprises,
			ruleSets
		},

		state: {
			treatmentTypes: []
		},

		getters: {
			treatmentTypesFilterList: (state, getters) => {
				const empty = { id: null, name: t('dataIntegration.ruleSets.options.default') };
				return concat(empty, getters.treatmentTypes);
			},

			treatmentTypes: (state) => {
				const types = clone(state.treatmentTypes);
				size(types) && types.sort((a, b) => a.name.localeCompare(b.name));
				return types;
			}
		},

		mutations: {
			setTreatmentTypes: (state, list) => {
				state.treatmentTypes = list;
			}
		},

		actions: {
			treatmentTypes: ({ commit }) => repository.getTreatmentTypesWithEnterprises()
				.then((response) => {
					commit('setTreatmentTypes', response);
				})
		}
	},
	dataMapping,
	maintenanceMode,
	roleMapping
);
