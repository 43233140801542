import Vue from 'vue';
import { assignIn, compact, findIndex, get, noop, toLower } from 'lodash';
import appContext from 'app-context';
import t from 'translate';
import { mapGetters } from 'vuex';

export default Vue.extend({
	props: {
		filters: {
			type: Array,
			default: () => []
		},
		filterKey: {
			type: String,
			default: ''
		},
		label: {
			type: String,
			default: ''
		},
		values: {
			type: Array,
			default: () => []
		},
		onChange: {
			type: Function,
			default: noop
		},
		title: {
			type: Object,
			default: () => ({})
		},
		startValue: {
			type: [String, Object],
			default: ''
		},
		validation: {
			type: Function,
			default: (validity) => {
				if (get(validity, 'badInput')) {
					return { message: t('Bad input provided') };
				}

				return {};
			}
		}
	},

	data: () => ({ focused: -1 }),

	computed: {
		...mapGetters({ focusedFilter: 'filter/focusedFilter' }),
		validity () {
			return get(this.$store.state.filter.validity, this.label, {});
		},

		anyToggled () {
			return !!compact(this.$store.state.filter.isFilterToggled).length;
		},

		anySelected () {
			return !!compact(this.viewValue).length;
		},

		tabIndex () {
			return this.toggled ? 0 : -1;
		},

		viewValue () {
			return this.$store.state.filter.values[this.label] ?
				this.$store.state.filter.values[this.label] :
				{};
		},

		invalid () {
			return get(this.$store.state.filter.validity, `${this.label}.valid`) === false;
		},

		toggled () {
			return this.$store.state.filter.isFilterToggled[this.label];
		},

		selfName () {
			return `filter__${toLower(this.label)}`;
		}
	},

	watch: {
		focusedFilter (newVal, oldVal) {
			if (oldVal === this.label) {
				this.onEscape();
				this.toggleFilters({ label: newVal, toggle: true });
			} else if (newVal === this.label) {
				this.setFocus(this.selfName, true);
			}
		},

		toggled (newVal) {
			if (!newVal) {
				this.focused = -1;
			}
		}
	},

	created () {
		appContext.on('filter.controls.clear', () => {
			this.clear();
		});

		if (this.startValue) {
			this.setValues(this.startValue);
		}
	},

	methods: {
		findFilterIndex () {
			const curIndex = findIndex(this.filters, { label: this.focusedFilter });
			return curIndex !== -1 ? curIndex : null;
		},

		onEscape () {
			this.focused = -1;
			this.toggleFilters({ label: this.label, toggle: false });
			const handler = this.$refs[`filter__${toLower(this.focusedFilter)}`];
			handler.scrollIntoView(false);
		},

		setFocus (element, noArray = false) {
			const toBeFocused = noArray ? this.$refs[element] : this.$refs[element][0];
			this.$nextTick(() => toBeFocused.focus());
			toBeFocused.scrollIntoView(false);
		},

		toggleFilters (data) {
			this.$store.dispatch('filter/toggleFilter', data);
		},

		clear: noop,

		setValues (value) {
			this.$store.dispatch('filter/setKeys', { [this.filterKey]: value });
			this.$store.dispatch('filter/setValues', { [this.label]: value });
		},

		hideControls () {
			this.$store.dispatch('filter/hideControls');
			this.toggleFilters({ label: this.label, toggle: false });
		},

		validate (e) {
			this.$store.dispatch('filter/setValidity', {
				[this.label]: assignIn({}, e.target.validity, this.validation(e.target.validity))
			});
		}
	}
});
