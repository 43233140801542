<template>
	<ol :class="listClasses">
		<tree-item
			v-for="(item, index) in children"
			:key="index"
			class="tree-item--nested"
			:active-parent="parentActive"
			:blueprints="blueprints"
			:checked-items="checkedItems"
			:copy="copy"
			:copy-path="copyPath"
			:disabled="disabled"
			:dispatch="dispatch"
			:getters="getters"
			:icons="icons"
			:incomplete-paths="incompletePaths"
			:indeterminate="indeterminate"
			:item="item"
			:mirror-details="mirrorDetails"
			:mirrors="mirrors"
			:parent-root="root"
			:pass-button-refs="passButtonRefs"
			:position="position"
			:root-mirrors="rootMirrors"
			:selected="selected"
			:set-copy-path="setCopyPath"
			:toggle="toggle"
			:toggle-edit="toggleEdit"
			:name-path="namePath"
			:warning-paths="warningPaths"
			:on-add="onAdd"
			@passButtonRefs="passButtonRefs"
		/>
		<li v-if="!mirror" :class="addClasses">
			<tree-add
				:blueprints="blueprints"
				:disabled="disabled"
				:icons="icons"
				:path="path"
				:name-path="namePath"
				:on-add="onAdd"
				@addButtonRefs="passButtonRefs"
			/>
		</li>
	</ol>
</template>

<script>
import Vue from 'vue';
import sharedProps from '../shared/props';
import TreeAdd from './tree-add';

const LIST_PROPS = {
	children: {
		type: Array,
		default: () => []
	},
	mirror: {
		type: Boolean,
		default: false
	},
	path: {
		type: String,
		default: ''
	},
	parentActive: {
		type: Boolean,
		default: false
	},
	parentExpanded: {
		type: Boolean,
		default: false
	},
	root: {
		type: Boolean,
		default: false
	}
};

export default Vue.component('tree-list', {
	components: { TreeAdd },
	props: { ...sharedProps, ...LIST_PROPS },

	computed: {
		listClasses () {
			return {
				'tree-list': true,
				'tree-list--nested': true,
				'tree-list--root': this.root,
				'tree-list-active': this.activeAndExpanded()
			};
		},

		addClasses () {
			const classes = ['tree-item', 'tree-item--nested', 'tree-item--action'];

			if (this.root) {
				classes.push('tree-item--root');
			}
			return classes;
		}
	},

	methods: {
		activeAndExpanded () {
			return this.parentActive && this.parentExpanded;
		}
	}
});
</script>
