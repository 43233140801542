<template>
	<simple-list
		:items="items"
		:class-name="className"
		:get-title="title"
		:get-url="getUrl"
		:content="content"
	/>
</template>

<script>
import cardUrl from 'cwcardurl';
import { title } from '../../shared/get-title';
import SimpleList from 'components/simple-list/index';
import store from 'store';
import t from 'translate';
import formatDate from '../../shared/format-date';
import { NOT_SUBMITTED, GIVEN, PARTIALLY_GIVEN, NOT_GIVEN } from '../../shared/statuses';
import { kebabCase } from 'lodash';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints';
import { RESPONDENT } from 'store/respondents/respondent';
import { RESPONDENT_CONSENT_INSTANCES } from 'store/consents/rp-consent-instances';

const STATE_NEGATIVE = 'state-negative';
const STATE_NEUTRAL = 'state-neutral';
const STATE_PARTIAL = 'state-partial';
const STATE_POSITIVE = 'state-positive';

const startDate = (item) => formatDate(item.startAt, store.getters[RESPONDENT.TIMEZONE]);

export default {
	title: t('consents.available'),
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.CONSENTS,
		op: READ
	}],
	actions: ({ $store }) => [{
		respondent: RESPONDENT_CONSENT_INSTANCES.INIT,
		clinician: 'consentInstances/init'
	}[$store.getters['userType']]],
	components: { SimpleList },

	computed: {
		items: ({ $store }) => $store.getters[{
			respondent: RESPONDENT_CONSENT_INSTANCES.ITEMS,
			clinician: 'consentInstances/items'
		}[$store.state.user.type]]
	},

	methods: {
		title,

		className: (item) => ({
			[NOT_SUBMITTED]: STATE_NEUTRAL,
			[GIVEN]: STATE_POSITIVE,
			[PARTIALLY_GIVEN]: STATE_PARTIAL,
			[NOT_GIVEN]: STATE_NEGATIVE
		}[item.status]),

		getUrl: (item) => cardUrl.buildUrl(
			{
				respondent: 'consent',
				clinician: 'consent-instance'
			}[store.state.user.type],
			{ consentInstanceId: item.id }
		),

		containerClasses (item) {
			const status = kebabCase(item.status);
			return `fa fa-2x simple-list__item-icon icon--consent-${status}`;
		},

		startDate (item) {
			return formatDate(item.startAt, this.$store.getters[RESPONDENT.TIMEZONE]);
		},

		content (item) {
			return `
				<span class="${this.containerClasses(item)}"></span>
				<div class="simple-list__item-description">
					<h3 class="item-description__title">${item.name}</h3>
					<p class="item-description--small">
						${t('Start date')}: ${startDate(item)}
					</p>
				</div>
			`;
		}
	}
};
</script>
