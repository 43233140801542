import { assign, isFunction, omit, pick } from 'lodash';
import { Events } from 'backbone';
import cardManager from '../card-manager';
import appbar from 'app-bar';
import appEventsExtension from './app-events';
import cardConfig from 'core/engine/card/config/card-config';
import store from 'store';
import { when } from 'jquery';

// Wire up cardData with Vuex store
const wireUpStore = ({ cardData, populate }) => {
	store.dispatch('resetCardData');
	store.dispatch('clearCurrentCard');
	store.dispatch('setCardData', cardData.toJSON());
	cardData.on('change', () => {
		store.dispatch('setCardData', cardData.toJSON());
	});

	store.subscribe((mutation, state) => {
		if (mutation.type === 'setVariantIndex') {
			populate({ variantIndex: state.currentCard.variantIndex });
		}
	});
};

export default function ({ action, appEvents, cardName, context, router, view }) {
	assign(this, Events);
	this.appEvents = appEventsExtension.bind(this);
	appEvents && this.appEvents(appEvents);

	assign(this, {
		action,
		actions (actionsDefinition) {
			const actions = isFunction(actionsDefinition) ?
				actionsDefinition(this) :
				actionsDefinition;

			store.dispatch('addCurrentCardActions', {
				actions,
				source: `card:${cardName}`,
				type: 'card'
			});
			const promises = store.state.currentCard.promises[`card:${cardName}`] || [];
			return when(...promises);
		},

		cardConfig: cardConfig(cardName),
		cardData: context,

		setLoading () {
			appbar.loading(true);
			view.loading(true);
		},

		setLoaded () {
			appbar.loading(false);
			view.loading(false);
		},

		populate ({ data, variantIndex } = {}) {
			appbar.loading(false);
			return when(cardManager.showCard(view, {
				controllerContext: this,
				data,
				variantIndex
			}));
		},

		navigate (hash) {
			router.navigate(hash, { replace: true });
		},

		close: (opts) => {
			this.cardData.trigger('card:close', opts);
		}
	});

	this.cardData.set(omit(this.cardConfig, 'card-name', 'tiles'));

	// Wire up cardData with Vuex store
	wireUpStore(pick(this, ['cardData', 'populate']));

	this.cardContext = function () {
		return this.cardData;
	};

	this.setLoading();
}
