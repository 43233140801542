var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canAccess
    ? _c("div", { class: _vm.mainClassName }, [
        _c(
          "a",
          {
            class: _vm.className("link"),
            attrs: { href: _vm.href, target: _vm.target },
            on: { click: _vm.handleClick }
          },
          [
            _c(
              "span",
              { class: _vm.className("icon-container") },
              [
                _vm.item.icon
                  ? _c("icon", {
                      attrs: {
                        name: _vm.item.icon,
                        "class-name": _vm.className("icon")
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("span", { class: _vm.className("label") }, [
              _vm._v(_vm._s(_vm.text))
            ]),
            _vm._v(" "),
            _c("sidebar-counter", {
              attrs: { counter: _vm.item.counter, type: _vm.type }
            })
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }