import layoutAdjustment from '../../rendering/layout-adjustment';
import $ from 'jquery';
import { cwtagsNl2br, orderedList, unorderedList } from '../../helpers/string';
import { assign, noop } from 'lodash';

const contentHtml = (type) => ({
	heading1: '<h1 aria-level="2" />',
	heading2: '<h2 aria-level="3" />',
	heading3: '<h3 aria-level="4" />',
	strong: '<strong />',
	important: '<p class="important" />'
})[type] || '<p class="text-content assessment" />';

const inMatrix = ({ section, model }) => section.isMatrix() && section.getChildIndex(model) > 0;

export default function (model) {
	const isMatrix = inMatrix({ section: model.getSection(), model });

	const $container = $(isMatrix ? '<tr />' : '<div />')
		.attr('id', model.getElementId())
		.addClass([
			'assessment-container',
			'assessment-text-container',
			model.alignment() ? `assessment-text-container--align-${model.alignment()}` : ''
		].join(' '));

	!model.isVisible() && $container.hide();

	layoutAdjustment(model, $container[0]);
	const $content = $(contentHtml(model.getContentStyle()));

	if (isMatrix) {
		$('<td class="question-text-cell" colspan="100%" />').append($content).appendTo($container);

	} else {
		$container.append($content);
	}

	assign(this, {
		updateText: () => {
			const text = model.getContent();
			const processedText = {
				orderedList: orderedList({
					string: text,
					baseClassName: 'assessment-runtime-text-content'
				}),
				unorderedList: unorderedList({
					string: text,
					baseClassName: 'assessment-runtime-text-content'
				}),
				normal: cwtagsNl2br(text)
			}[model.getContentStyle()];

			$content.html(processedText || cwtagsNl2br(text));
		},

		getElementBox: () => $container[0],
		clear: noop
	});

	this.updateText();
}

