import { View } from 'backbone';
import formView from 'components/form-view/form-view';
import t from 'translate';
import systemSettings from 'system-settings';
import DiaryEntry from 'repo/diary-entries/diary-entry';
import moodField from '../shared/mood-field';

export default View.extend({
	className: 'diary-entry__form',
	render () {
		const fields = [{
			key: 'content',
			type: 'textarea',
			placeholder: t('Add text to diary here'),
			required: true,
			autoresize: true
		}];

		if (systemSettings.getBoolean('RESPONDENT_DIARY_MOOD')) {
			fields.push(moodField());
		}

		this.form = formView({
			model: new DiaryEntry(),
			listenTo: ['submit'],
			successMessage: t('New diary entry added'),
			onAfterSave: () => {
				this.form.close();
				this.render();
			},
			fields,
			buttons: [{
				caption: t('Add'),
				name: 'add',
				key: 'add',
				type: 'submit'
			}]
		});
		this.$el.html(this.form.$el);

		return this;
	}
});
