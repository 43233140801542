import repository from 'repository';
import { TREATMENT } from 'store/treatments/rp-treatment';
import { set } from '../__helpers/mutations';

export default {
	namespaced: true,

	state: {
		data: []
	},

	getters: {
		size: (state) => state.data.length
	},

	mutations: {
		set: set()
	},

	actions: {
		init: async ({ commit, rootGetters }) => {
			const assessmentInstances = await repository.getAssessmentInstances({
				treatmentId: rootGetters[TREATMENT.ID]
			});

			commit('set', assessmentInstances);
		}
	}
};
