import agenda from './agenda';
import cardShortcut from './card-shortcut/tile';
import customUrl from './custom-url/tile';
import devTest from './dev-test/tile';
import label from './label/tile';
import miniShortcut from './mini-shortcut';
import heading from './heading';
import tableView from './table-view';
import itemsView from './items-view';

export default {
	agenda,
	'card-shortcut': cardShortcut,
	'custom-url': customUrl,
	'dev-test': devTest,
	heading,
	label,
	'mini-shortcut': miniShortcut,
	'table-view': tableView,
	'items-view': itemsView
};
