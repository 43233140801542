<template>
	<li
		v-if="!detached"
		v-on-click-outside="hide"
		class="appbar-system-buttons__item menu-button"
	>
		<sidebar-trigger
			:expanded="show"
			@click="toggle"
		/>
		<sidebar-container
			:show="show"
			:items="items"
			:on-click="hide"
			:can-access="canAccess"
			:type="type"
		/>
	</li>
</template>

<script>
import SidebarTrigger from './sidebar-trigger';
import SidebarContainer from './sidebar-container';
import store, { APP } from 'store';
import { defer } from 'lodash';

export default {
	components: {
		SidebarTrigger,
		SidebarContainer
	},
	store,
	props: {
		items: {
			type: Array,
			required: true
		},
		canAccess: {
			type: [Boolean, Function],
			default: true
		},
		type: {
			type: String,
			default: ''
		}
	},
	data: () => ({
		show: false
	}),
	computed: {
		detached: ({ $store }) => $store.getters[APP.DETACHED]
	},
	methods: {
		hide () {
			this.show = false;
		},
		toggle () {
			/*
			 * `v-on-click-outside` callback seems to be fired when keyboard is being used for
			 * toggling. This is a workaround for that -- to be improved/removed when feasible
			 */
			const show = this.show;

			defer(() => {
				this.show = !show;
			});
		}
	}
};
</script>
