var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-click-outside",
          rawName: "v-on-click-outside",
          value: _vm.hideList,
          expression: "hideList"
        }
      ],
      staticClass: "view-report"
    },
    [
      _c("agenda-item-action", {
        attrs: { action: _vm.showReport, title: _vm.action.label }
      }),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.loading
          ? _c("span", {
              directives: [
                {
                  name: "loader-spinner",
                  rawName: "v-loader-spinner:small",
                  arg: "small"
                }
              ],
              staticClass: "view-report__loader"
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.toggled && !_vm.loading
          ? _c("div", { staticClass: "view-report__popup" }, [
              _vm.generatedReports.length > 1
                ? _c(
                    "ul",
                    { staticClass: "view-report__list" },
                    _vm._l(_vm.reports, function(report) {
                      return _c(
                        "li",
                        {
                          key: report.id,
                          staticClass: "view-report__list-item"
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "view-report__link",
                              attrs: { href: _vm.buildUrl(report) }
                            },
                            [
                              _c("icon", { attrs: { name: "report" } }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "view-report__link-text" },
                                [_vm._v(_vm._s(report.name))]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    0
                  )
                : _vm.pendingCount
                ? _c("p", { staticClass: "view-report__info" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.$t("assessmentInstance.reports.pending", {
                            pendingCount: _vm.pendingCount
                          })
                        ) +
                        "\n\t\t\t"
                    )
                  ])
                : _c("p", { staticClass: "view-report__info" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.$t("assessment.properties.no-reports")) +
                        "\n\t\t\t"
                    )
                  ])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }