<template>
	<div class="dashboard-value-tile">
		<div class="dashboard-value-tile__container">
			<h2 class="dashboard-value-tile__title">
				{{title}}
			</h2>
			<p class="dashboard-value-tile__count">
				{{value}}
			</p>
		</div>
	</div>
</template>

<script>
import cubeId from '../../shared/cube-id';
import actions from '../../shared/actions';
import { CUBE } from 'store/cube/cube';

export default {
	acl: [],
	actions: (tile) => actions({
		title: tile.config().title,
		query: tile.config().cubeQuery,
		ignoreAccessRules: tile.config().ignoreAccessRules
	}),
	data: ({ tile }) => ({
		config: tile.config()
	}),

	computed: {
		result: ({ config, $store }) => $store.getters[CUBE.RESULTS][cubeId(config)].result,
		title: ({ config, t }) => config.translate ? t(config.title) : config.title,
		value: ({ config, result }) => result[0][config.cubeQuery.measures[0]]
	}
};
</script>
