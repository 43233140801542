import ajax, { GET } from 'service/ajax/ajax';
import { forEach, values } from 'lodash';

export default {
	/**
	 * @function agenda
	 * @param {object} sources - Hashmap containing entity types as keys and full URLs as values.
	 * @returns {object}       - Promise.
	 * @example
 	 * 	repository.agenda({
 	 * 		assessments: 'https://checkware.ext/helium/clinician/respondent/44/assessmentInstance'
 	 * 	}
	 */
	agenda: (sources = {}) => new Promise((resolve) => {
		const promises = {};
		const data = {};
		forEach(sources, (url, type) => {
			promises[type] = GET({ url, prepend: false, separator: '&' }).then((response) => {
				data[type] = response;
			});
		});
		Promise.all(values(promises)).then(() => {
			resolve(data);
		});
	}),

	/**
	 * @function agendaAction
	 * @param {object} request         - Request definition.
	 * @param {string} request.type    - Request type (GET, PUT, POST, DELETE).
	 * @param {string} request.url     - Request URL.
	 * @param {object} request.payload - Request paylaod.
	 * @returns {object}               - Promise.
	 * @example
	 * 	repositoty.agendaAction({
	 * 		type: 'POST',
	 * 		url: 'https://checkware.ext/helium/clinician/entity',
	 * 		payload: { name: 'New entity' }
	 * 	})
	 */
	agendaAction: ({ type = 'GET', url, payload }) => ajax[type]({
		url,
		data: payload,
		prepend: false
	})
};
