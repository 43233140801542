import {
	assign, clone, compact, every, forEach, get, includes, isBoolean, isFunction, isString, map,
	omit, transform
} from 'lodash';
import ACL from 'service/acl/acl';
import { compute } from './compute';
import { formatValue } from './format-value';
import { parseGet } from './parse-get';
import { parseStr } from './parse-str';
import { transcribe } from './transcribe';

const canAccess = ({ action, entity }) => {
	if (!ACL.checkAccess(action.acl)) {
		return false;
	}

	if (action.if) {
		return every(
			action.if,
			(val, key) => includes(val, parseGet({ entity, key }))
		);
	}

	return true;
};

const whenThen = ({ when, then }) => when && then();

/**
 * Transform entity values per provided rules.
 *
 * @param {object} params -            Parameters.
 * @param {object} params.entity -     Source entity.
 * @param {object} params.rules -      Rules to be applied.
 * @param {object} params.vars -       Variables that should be applied.
 * @param {boolean} params.translate - Should result be translated.
 * @returns {object} - Transformed entity.
 * @example
 *        applyTransform({
 * 			entity: { start: '2019-02-19T23:23:23', respondentId: 19 },
 * 			rules: {
 * 				labels: [{
 *					title: 'Start',
 *					value: 'start',
 *					icon: 'activity',
 *					type: 'date',
 *					dateFormat: 'YYYY-MM-DD HH:mm',
 *					whenEmpty: 'none'
 *				}],
 *				actions: [{
 *					label: 'RESPONDENT',
 *					acl: [{}],
 *					card: 'respondent-status',
 *					args: {
 *						respondentId: '${respondentId}'
 *					}
 *				}
 *			},
 * 			vars: { respondentId: 19 },
 * 			translate: false
 * 		});
 *        -> {
 * 		 	start: '2019-02-19T23:23:23',
 * 		 	respondentId: 19,
 * 		 	labels: [{
 * 		 		title: 'Start',
 *				icon: 'activity',
 *				value: '2019-02-19 23:23'
 * 		 	}],
 * 		 	actions: [{
 * 		 		label: 'RESPONDENT',
 * 		 		card: 'respondent-status',
 * 		 		args: { respondentId: '19' }
 * 		 	}]
 * 		}
 */
export const applyTransform = ({
	entity = {},
	rules = [],
	vars = {},
	translate = false,
	timezone = 'UTC'
}) => transform(
	rules,
	(result, rule, to) => {
		let value;
		let skip = false;

		const labelValue = (label) => {
			const labelVal = label.type ?
				formatValue({
					value: get(entity, label.value),
					...omit(label, 'value'),
					timezone
				})() :
				get(result, label.value) || transcribe(get(label, 'whenEmpty', '--'), translate);

			return {
				title: transcribe(label.title, translate),
				icon: label.icon,
				value: labelVal
			};
		};

		const router = {
			boolean: {
				when: () => isBoolean(rule),
				value: () => rule
			},

			basic: {
				when: () => isString(rule),
				value: () => parseGet({ entity: result, key: rule })
			},

			typed: {
				when: () => rule.value && rule.type,
				value: () => formatValue({
					value: get(entity, rule.value),
					...omit(rule, 'value'),
					timezone
				})()
			},

			operator: {
				when: () => rule.op && rule.args,
				value: () => compute({ entity: result, ...rule, vars, timezone })()
			},

			labels: {
				when: () => includes(['labels', 'horizontalLabels'], to),
				value: () => map(rule, labelValue)
			},

			openAction: {
				when: () => to === 'openAction',
				then: () => {
					if (ACL.checkAccess(rule.acl)) {
						value = assign({}, rule, {
							args: transform(rule.args, (args, arg, key) => {
								args[key] = parseGet({ entity, key: arg, vars });
							}, {})
						});

					} else {
						skip = true;
					}
				}
			},

			actions: {
				when: () => to === 'actions',
				value: () => {
					const actionItems = map(
						rule,
						(actionItem) => canAccess({ action: actionItem, entity }) && assign({
							label: transcribe(actionItem.label, translate)
						},

						whenThen({
							when: actionItem.confirm,
							then: () => ({
								confirm: actionItem.confirm
							})
						}),

						whenThen({
							when: actionItem.popup,
							then: () => ({
								popup: actionItem.popup,
								entity
							})
						}),

						whenThen({
							when: actionItem.customComponent,
							then: () => ({
								component: actionItem.customComponent,
								entity
							})
						}),

						whenThen({
							when: actionItem.card,
							then: () => ({
								card: actionItem.card,
								args: transform(actionItem.args, (args, arg, key) => {
									args[key] = parseGet({ entity, key: arg, vars });
								}, {})
							})
						}),

						whenThen({
							when: actionItem.action,
							then: () => ({
								action: {
									name: actionItem.action.name,
									payload: assign(
										{},
										entity,
										transform(actionItem.action.payload, (args, arg, key) => {
											args[key] = parseGet({ entity, key: arg, vars });
										}, {})
									)
								}
							})
						}),

						whenThen({
							when: actionItem.request,
							then: () => ({
								request: assign(actionItem.request, {
									url: parseStr(actionItem.request.url, assign({}, vars, entity))
								})
							})
						}))
					);
					return compact(actionItems);
				}
			}
		};

		forEach(router, (route) => {
			if (!route.when()) {
				return true;
			}

			if (isFunction(route.value)) {
				value = route.value();

			} else if (isFunction(route.then)) {
				route.then();
			}

			return false;
		});

		if (!skip) {
			result[to] = value;
		}
	},
	clone(entity)
);
