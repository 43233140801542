/*
 * This is a list of system settings flags that are used in systemSettings service in getter
 * functions (e.g. `systemSettings.getBoolean('ALLOW_ADMIN_LANGUAGE_SWITCH').
 *
 * The list is kept in alphabetical order.
 */
export default {
	ADMIN_WORKFLOW_PAUSE_ENABLE: 'admin.workflow.pause-enable',
	ACTIVITY_ITEMS_PER_PAGE: 'administrator.treatment-activity.number-items-per-page',
	ALLOW_ADMIN_LANGUAGE_SWITCH: 'admin.personal-settings.allowLanguageSwitch',
	ALLOW_ADMIN_TIMEZONE_SWITCH: 'admin.personal-settings.allowTimezoneSwitch',
	ALLOW_RESPONDENT_LANGUAGE_SWITCH: 'respondent.personal-settings.allowLanguageSwitch',
	ALLOW_RESPONDENT_TIMEZONE_SWITCH: 'respondent.personal-settings.allowTimezoneSwitch',
	ASSESSMENT_POPUPS: 'system.assessment.show-unanswered-questions-popup',
	ASSESSMENT_TYPES: 'system.assessment-types.enable',
	CHANGE_OWNER_IN_SUBMITTED: 'system.workflow.change-document-owner-in-submitted-assessments',
	CLINICIAN_MULTIPLE_ACCESS_ROLES: 'administrator.multiple-access-roles',
	CLINICIAN_PASSWORD_EXPIRE_DAYS: 'administrator.personal-settings.password-expire-days',
	CLINICIAN_PASSWORD_EXPIRE_DAYS_WARNING:
		'administrator.personal-settings.password-expire-days-warning',
	CLINICIAN_PASSWORD_REGEX: 'administrator.personal-settings.password-validate-expression',
	CLINICIAN_USERNAME_REGEX: 'administrator.personal-settings.username-validate-expression',
	CONFIRM_MESSAGE_SENDING: 'administrator.message.confirm-send',
	DEFAULT_ADMIN_LANGUAGE: 'admin.i18n.defaultLanguage',
	DEFAULT_ADMIN_TIMEZONE: 'admin.i18n.defaultTimezone',
	DEFAULT_ASSESSMENT_DEADLINE: 'admin.workflow.assessment.deadline.defaultValue',
	DEFAULT_ASSESSMENT_DEADLINE_UNIT: 'admin.workflow.assessment.deadline.defaultUnit',
	DEFAULT_ASSESSMENT_TIME: 'system.assessment.defaultTime',
	DEFAULT_RESPONDENT_LANGUAGE: 'respondent.i18n.defaultLanguage',
	DEFAULT_RESPONDENT_TIMEZONE: 'respondent.i18n.defaultTimezone',
	DISABLE_COMM: 'respondent.disable-communication',
	ENABLE_CIRCLES: 'system.circle.enable',
	ENABLE_CLINICIAN_PERSONAL_SETTINGS_PAGE: 'admin.personal-settings.enable',
	ENABLE_RESPONDENT_CIRCLES: 'respondent.circle.enable',
	ENABLE_CLINICIAN_CIRCLES: 'administrator.circle.enable',
	ENABLE_RESPONDENT_CREATE_CIRCLE: 'respondent.circle.allow_create',
	ENABLE_ASSESSMENT_PORTAL: 'assessment-portal.enable',
	ENABLE_ASSESSMENT_SEND_CODE_EMAIL: 'assessment-portal.send-code.enable-email',
	ENABLE_MESSAGING_SYSTEM: 'system.communication.enable',
	ENABLE_RESPONDENT_ASSESSMENTS: 'respondent.assessments.enable',
	ENABLE_RESPONDENT_EXERCISES: 'respondent.exercises.enable',
	ENABLE_RESPONDENT_GOALS: 'respondent.goals.enable',
	ENABLE_RESPONDENT_INFORMATION_PACKAGES: 'respondent.information-packages.enable',
	ENABLE_RESPONDENT_LINKS: 'respondent.links.enable',
	ENABLE_RESPONDENT_MEDIA: 'respondent.media.enable',
	ENABLE_RESPONDENT_PAGES: 'respondent.pages.enable',
	ENABLE_RESPONDENT_PERSONAL_SETTINGS_PAGE: 'respondent.personal-settings.enable',
	ENABLE_RESPONDENT_REPORTS_PAGE: 'respondent.reports.enable',
	ENABLE_TREATMENT_STATUS: 'system.treatment.status.enable',
	ENABLE_WORKFLOWS: 'system.workflows.enable',
	ENABLE_ATTACHMENTS: 'system.communication.enable-attachments',
	FIRST_DAY_OF_WEEK: 'system.i18n.firstDayOfWeek',
	MANDATORY_CLINICIAN_ROLES: 'system.admin.mandatory-access-roles',
	RESPONDENT_DIARY: 'respondent.diary',
	RESPONDENT_DIARY_EDITABLE: 'respondent.diary.editable',
	RESPONDENT_DIARY_MINITILELIMIT: 'respondent.diary.miniTileLimit',
	RESPONDENT_DIARY_MOOD: 'respondent.diary.mood',
	RESPONDENT_GROUPS_MANDATORY: 'respondent.group.is.mandatory',
	RESPONDENT_PASSWORD_EXPIRE_DAYS: 'respondent.personal-settings.password-expire-days',
	RESPONDENT_PASSWORD_EXPIRE_DAYS_WARNING:
		'respondent.personal-settings.password-expire-days-warning',
	RESPONDENT_PASSWORD_REGEX: 'respondent.personal-settings.password-validate-expression',
	RESPONDENT_USERNAME_REGEX: 'respondent.personal-settings.username-validate-expression',
	RESPONDENT_SECURITY_DEFAULT_AUTH_LVL: 'respondent.security.default-auth-level',
	RESPONDENT_SECURITY_ENABLE_AUTH_LVL_SELECT: 'respondent.security.auth-level-selection-enabled',
	SMS_FIXEDTEXT: 'system.sms.fixedText',
	RESPONDENT_VIDEO_CHAT: 'video-chat.allow-respondents-to-connect',
	RESPONDENT_ITERATE_ON_START: 'respondent.assessments.iterate-on-start',
	RESPONDENT_ITERATE_AFTER_TREATMENT_SELECT: 'respondent.assessments.iterate-after-treatment-select',
	RESPONDENT_ITERATE_IN_TREATMENT_CONTEXT: 'respondent.assessments.iterate-in-treatment-context'
};
