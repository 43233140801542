import repository from 'repository';
import { reject } from 'lodash';
import initEntities from '../__helpers/init-entities';
import { CLINICIAN } from 'service/acl/checkpoints';

const prefix = 'decisions';

const SIZE = 'size';
const UNANSWERED = 'unanswered';
const UNANSWERED_COUNT = 'unansweredCount';

export const DECISIONS = {
	SIZE: `${prefix}/${SIZE}`,
	UNANSWERED: `${prefix}/${UNANSWERED}`,
	UNANSWERED_COUNT: `${prefix}/${UNANSWERED_COUNT}`
};

export default {
	namespaced: true,

	state: {
		data: [],
		collection: {}
	},

	getters: {
		[SIZE]: (state) => state.data.length,
		[UNANSWERED]: (state) => reject(state.data, 'responseDate'),
		[UNANSWERED_COUNT]: (state, getters) => getters[UNANSWERED].length
	},

	mutations: {
		set: (state, { decisions, collection }) => {
			state.data = decisions;
			state.collection = collection;
		}
	},

	actions: {
		initForTreatment: ({ commit }, { treatmentId }) => {
			const collection = repository.treatmentDecisions(treatmentId);

			return collection.then(() => {
				commit('set', { decisions: collection.toJSON(), collection });
			});
		},

		initForRespondent: ({ commit }, { respondentId }) => {
			const collection = repository.respondentDecisions(respondentId);

			return collection.then(() => {
				commit('set', { decisions: collection.toJSON(), collection });
			});
		},

		init: initEntities('decisions/init', { acl: CLINICIAN.RESPONDENTS.DECISIONS }),

		refresh: ({ commit, state }) => {
			commit('set', { decisions: state.collection.toJSON(), collection: state.collection });
		}
	}
};
