import attributes from './treatment-attributes';
import currentCardAction from '../__helpers/current-card-action';
import deniedClinicians from './denied-clinicians';
import myTreatments from './my-treatments';
import repository from 'repository';
import treatmentOverview from './treatment-overview';
import { when } from 'jquery';
import { assign, clone, find, findIndex, get, map, reduce, reject, set } from 'lodash';
import { RESPONDENT } from '../respondents/respondent';

const prefix = 'treatment';

// getters
const ASSIGNED_ROLES = 'assignedRoles';
const ATTRIBUTE_VALUES = 'attributeValues';
const CONSENTS = 'consents';
const ID = 'treatmentId';
const MAIN_RESPONDENT = 'mainRespondent';
const MAIN_RESPONDENT_ROLE = 'mainRespondentRole';
const READY = 'ready';
const RESPONDENT_ROLES = 'respondentRoles';
const RESPONDENTS = 'treatmentRespondents';
const ROLES = 'roles';
const TREATMENT_ROLES = 'treatmentRoles';
const TREATMENT_ROLES_COLLECTION = 'treatmentRolesCollection';
// actions
const RETRIEVE_CONSENT_TEMPLATES = 'retrieveConsentTemplates';

export const TREATMENT = {
	// getters
	ASSIGNED_ROLES: `${prefix}/${ASSIGNED_ROLES}`,
	ATTRIBUTE_VALUES: `${prefix}/${ATTRIBUTE_VALUES}`,
	CONSENTS: `${prefix}/${CONSENTS}`,
	ID: `${prefix}/${ID}`,
	MAIN_RESPONDENT: `${prefix}/${MAIN_RESPONDENT}`,
	MAIN_RESPONDENT_ROLE: `${prefix}/${MAIN_RESPONDENT_ROLE}`,
	READY: `${prefix}/${READY}`,
	RESPONDENT_ROLES: `${prefix}/${RESPONDENT_ROLES}`,
	RESPONDENTS: `${prefix}/${RESPONDENTS}`,
	ROLES: `${prefix}/${ROLES}`,
	TREATMENT_ROLES: `${prefix}/${TREATMENT_ROLES}`,
	TREATMENT_ROLES_COLLECTION: `${prefix}/${TREATMENT_ROLES_COLLECTION}`,
	// actions
	RETRIEVE_CONSENT_TEMPLATES: `${prefix}/${RETRIEVE_CONSENT_TEMPLATES}`
};

const RESPONDENT_ID = 'respondent.id';

export default {
	namespaced: true,
	modules: {
		attributes,
		deniedClinicians,
		myTreatments,
		treatmentOverview
	},

	state: {
		data: null,
		model: null,
		mainRespondent: null,
		attributesValues: {
			data: null,
			collection: null
		},
		currentTreatmentId: undefined,
		assignableConsents: [],
		treatmentConsentInstances: [],
		treatmentRoles: [],
		treatmentRolesCollection: {}
	},

	getters: {
		[ROLES]: (state) => ((state.data || {}).treatmentRoles) || [],

		[ASSIGNED_ROLES]: (state, getters) => map(getters[ROLES], (role) => ({
			roleId: role.id,
			treatmentTypeRoleId: role.treatmentTypeRole.id,
			respondent: role.respondent,
			roleName: role.role.name
		})),

		[RESPONDENT_ROLES]: (store, getters) => reject(
			getters[ASSIGNED_ROLES],
			{ respondent: null }
		),

		[MAIN_RESPONDENT]: (state) => state.data.respondent,

		[MAIN_RESPONDENT_ROLE]: (state) => find(
			get(state.data, 'treatmentRoles'),
			(treatmentRole) =>
				get(treatmentRole, RESPONDENT_ID) === get(state.data, RESPONDENT_ID)
		),

		[ID]: (state, getters, rootState) => get(
			rootState.cardData,
			'urlParams.treatmentId',
			get(state, 'currentTreatmentId', get(state.data, 'id'))
		),

		[RESPONDENTS]: (state) => {
			const roles = get(state.data, 'treatmentRoles', []);

			if (!roles.length) {
				return get(state.data, 'respondent');
			}

			return reduce(roles, (memo, role) => {
				if (role.respondent) {
					memo.push(role.respondent);
				}

				return memo;
			}, []);
		},
		[TREATMENT_ROLES]: (state) => state.treatmentRoles,
		[TREATMENT_ROLES_COLLECTION]: (state) => state.treatmentRolesCollection,
		[CONSENTS]: (state) => map(state.assignableConsents, (consent) => ({
			id: consent.id,
			status: consent.status,
			name: consent.name
		})),

		[READY]: (state) => get(state.data, 'complete') && get(state.data, 'enabled'),

		[ATTRIBUTE_VALUES]: (state) => state.attributesValues.data
	},

	mutations: {
		set: (state, { treatment, model }) => {
			state.data = treatment;
			state.model = model;
		},
		setConsentInstances: (state, instances) => {
			state.treatmentConsentInstances = instances;
		},
		setId: (state, id) => {
			state.currentTreatmentId = id;
		},

		setAttributesValues: (state, { attributesValues, collection }) => {
			state.attributesValues = {
				data: attributesValues,
				collection
			};
		},

		setMainRespondent: (state, { respondent }) => {
			state.mainRespondent = respondent;
		},

		reset: (state) => {
			state.currentTreatmentId = undefined;
			state.data = null;
			state.model = null;
			state.attributesValues = {
				data: null,
				collection: null
			};
			state.assignableConsents = [];
			state.treatmentConsentInstances = [];
			state.treatmentRoles = [];
			state.treatmentRolesCollection = {};
		},
		setConsentTemplates: (state, consents) => {
			state.assignableConsents = consents;
		},

		setTreatmentRoles: (state, { collection, roles }) => {
			state.treatmentRoles = roles;
			state.treatmentRolesCollection = collection;
		},

		update: (state, payload) => {
			state.data = assign({}, state.data, payload);
			state.model && state.model.set(payload);
		}
	},

	actions: {
		init: (
			{ commit, dispatch, getters, rootGetters, state, rootState },
			{ treatmentId } = { treatmentId: 0 }
		) => {
			const id = treatmentId || getters[ID];
			const viable = () => id && rootGetters.userType === 'clinician';

			if (!viable()) {
				return null;
			}
			const model = state.model || repository.treatment(id);
			const promises = [model];

			if (rootGetters[RESPONDENT.RESPONDENT_ID]) {
				promises.push(...currentCardAction({ dispatch, rootState }, {
					action: 'respondent/init',
					source: 'treatment/init'
				}));
			}

			// when treatments are loaded into respondent card (progress-bar) only current treatment
			// should be shown on treatment card - instead of all respondent treatments
			dispatch('treatments/reset', {}, { root: true });

			return when(...promises).then(() => {
				const treatment = model.toJSON();
				const dfd = [];
				commit('set', { model, treatment });

				if (!rootGetters.respondent) {
					dfd.push(...currentCardAction({ dispatch, rootState }, {
						action: 'respondent/init',
						source: 'treatment/init'
					}));
				}

				if (+treatment.respondent.id !== +rootGetters[RESPONDENT.RESPONDENT_ID]) {
					const respondent = repository.respondent(treatment.respondent.id);
					respondent.then(() => {
						commit('setMainRespondent', { respondent: respondent.toJSON() });
					});

					dfd.push(respondent);
				}

				return when(...dfd);
			});
		},

		initWithoutRespondent: ({ commit, getters }, { treatmentId } = { treatmentId: 0 }) => {
			const id = treatmentId || getters[ID];
			const model = repository.treatment(id);

			return model.then(() => {
				const treatment = model.toJSON();
				commit('set', { model, treatment });
			});
		},
		initWithMainRespondent: ({ commit }, { treatmentId }) => {
			const model = repository.treatment(treatmentId);

			return model.then(() => {
				const treatment = model.toJSON();
				const respondent = repository.respondent(treatment.respondent.id);
				commit('set', { model, treatment });

				return respondent.then(() => {
					commit('setMainRespondent', { respondent: respondent.toJSON() });
				});
			});
		},

		update: ({ commit }, payload) => {
			commit('update', payload);
		},

		destroy: async ({ commit }, treatmentId) => {
			await repository.removeTreatment(treatmentId);
			commit('reset');
		},

		getMessages: ({ dispatch, state }, { treatmentId, respondentId } = {}) =>
			dispatch('messages/init', {
				treatmentId: treatmentId || get(state.data, 'id'),
				respondentId: respondentId || get(state.data, 'respondent.id')
			}, { root: true }),

		terminatePlanInstances: ({ dispatch, state }) => dispatch(
			'planInstances/terminateInTreatment',
			{ treatmentId: state.data.id },
			{ root: true }
		),

		initOverview: ({ dispatch, state }) => dispatch('treatmentOverview/init', {
			treatmentId: state.data.id
		}),

		getAttributesDefinition: ({ dispatch, state }) => dispatch('treatmentType/initAttributes', {
			treatmentTypeId: get(state.data, 'treatmentType.id')
		}, { root: true }),

		getAttributesValues: ({ commit, getters }) => {
			const collection = repository.treatmentAttributesValues(getters[ID]);

			return collection.then(() => {
				commit('setAttributesValues', {
					attributesValues: collection.toJSON(),
					collection
				});
			});
		},

		setId: ({ commit }, treatmentId) => commit('setId', treatmentId),

		resetId: ({ commit }) => {
			commit('setId', undefined);
		},

		fillRespondentDataAtRole: ({ commit, getters, rootGetters, state }, { roleId }) => {
			const respondentId = get(find(getters[ASSIGNED_ROLES], { roleId }), RESPONDENT_ID);
			const mainRespondent = rootGetters[RESPONDENT.DATA];

			const setRespondentAtRole = (respondent) => {
				const cloned = clone(state.data);
				const index = findIndex(getters[ASSIGNED_ROLES], { roleId });
				const path = `treatmentRoles[${index}].respondent`;
				const currentRespondent = get(cloned, path);
				set(cloned, path, assign(currentRespondent, respondent));
				commit('update', cloned);
			};

			if (mainRespondent.respondentId === respondentId) {
				setRespondentAtRole(mainRespondent);
				return null;
			}

			const respondent = repository.respondent(respondentId);
			return respondent.then(() => {
				setRespondentAtRole(respondent.toJSON());
			});
		},
		[RETRIEVE_CONSENT_TEMPLATES]: ({ commit }, treatmentId, respondentId) => {
			const consents = repository.treatmentConsentTemplates(treatmentId, respondentId);
			return consents.then((consents) => {
				commit('setConsentTemplates', consents);
			});
		},
		treatmentConsentInstances: ({ commit }, treatmentId) => {
			const consentInstances = repository.treatmentConsents(treatmentId);
			return consentInstances.then((consentInstances) => {
				commit('setConsentInstances', consentInstances);
			});
		},
		treatmentRoles: ({ commit }, treatmentId) => {
			const collection = repository.getTreatmentRoles(treatmentId);
			return collection.then(() =>
				commit('setTreatmentRoles', { collection, roles: collection.toJSON() }));
		},

		reset: ({ commit }) => {
			commit('reset');
		}
	}
};
