import { UserLink } from 'components/user-link/user-link';
import { respondentName } from 'service/display-name/display-name';

export default ({ rawContent }) => ({
	component: UserLink,
	props: {
		respondent: rawContent
	},
	value: respondentName(rawContent)
});
