import SingleEntity from '../shared/single-entity';
import urls from './urls';

/**
 * MediaModel
 * @class MediaModel
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	eventNamespace: 'media',
	urlRoot: urls.single.url,

	getType: function () {
		return this.get('mimeType') ? this.get('mimeType') : (this.get('file').mimeType || '');
	},

	getPermalink: function () {
		return urls.permalink.url(this.get('alias'));
	}
});
