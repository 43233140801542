/**
 * @class Repository
 */
export default {
	/**
	 * @method getLinks
	 * @return {Object} new {{#crossLink "{Links"}}{{/crossLink}} Collection
	 * @example
	 *        Repository.getLinks();
	 */
	getLinks: function () {
		return this.Links.getList();
	},

	/**
	 * @method getLink
	 * @param {String|Number} id
	 * @return {Object} new {{#crossLink "{Link"}}{{/crossLink}} Collection
	 * @example
	 *        Repository.getLink(3);
	 */
	getLink: function (id) {
		return this.Links.getById(id);
	},

	/**
	 * @method getLinkCategories
	 * @return {Collection} new {{#crossLink "{LinkCategories"}}{{/crossLink}} Collection
	 * @example
	 *        Repository.getLinkCategories();
	 */
	getLinkCategories: function () {
		return this.LinkCategories.getAll();
	},

	/**
	 * @method searchLinks
	 * @param {Object} params Search params
	 * @return {Object} new Collection
	 * @example
	 *        Repository.searchLinks({
     * 			query: 'example',
     * 			limit: 10,
     * 			start: 0
     * 		});
	 */
	searchLinks: function (params) {
		return this.Links.search(params);
	}
};
