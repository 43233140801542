import assessmentRuntime from './assessment-runtime/respondent-assessment-runtime';
import treatments from './treatments/respondent-treatments';
import circles from './circles/respondent-circles';
import consents from './consents/rp-consents';
import diary from './diary/diary';
import goals from './goals/respondent-goals';
import links from './links/respondent-links';
import media from './media/respondent-media';
import messages from './messages/respondent-messages';
import pages from  './pages/respondent-pages';
import assessment from './assessment/respondent-assessment';
import contentPackages from './respondent-information-package/respondent-information-package';
import reports from './respondent-reports/respondent-reports';
import respondent from './respondent/respondent';
import appsManagement from './apps-management/respondent-store-apps';
import videoChat from './video-chat/respondent-video-chat';
import shared from './shared';

export default [
	assessment, assessmentRuntime, circles, consents, contentPackages, diary, goals, links, media,
	messages, pages, reports, respondent, appsManagement, treatments, videoChat, ...shared
];
