/**
 * Returns the controller context for the card that the tile is on.
 *
 * @function TileView.card
 * @returns {Object} - ControllerContext.
 * @deprecated
 * @example
 * export default TileView.extend({
 *     loaded: ({ tile }) => {
 *         tile.card().close();
 *     }
 * });
 */
export default function () {
	return this.__params.controllerContext;
}

