/**
 * Returns the config object passed via card configuration.
 *
 * @function TileView.config
 * @returns {Object} - Tile configuration.
 * @example
 * export default TileView.extend({
 *     loaded: ({ tile }) => {
 *         const { limit } = tile.config();
 *     }
 * });
 */
export default function () {
	return this.__params.tileConfig || {};
}
