import { assign } from 'lodash';

import common from './message-common';
import SingleEntity from '../shared/single-entity';
import { message } from './urls';

/**
 * Message entity
 *
 * @class Message
 * @extends SingleEntity
 */
export default SingleEntity.extend(assign(common, {
	urlRoot: message.url,
	eventNamespace: 'message'
}));
