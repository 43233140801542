import repository from 'repository';
import { assign, get, omit } from 'lodash';

export default {
	namespaced: true,

	state: {
		data: {
			assessment: {},
			assessmentsUnseen: {},
			flags: {},
			flagsRaised: {},
			flagsUnseen: {},
			inputs: {},
			messages: {},
			overdue: {}
		}
	},

	mutations: {
		set: (state, { notifications }) => {
			assign(state.data, omit(notifications, 'type'));
		}
	},

	getters: {
		assessments: (state) => +get(state.data, 'assessment.newCount', 0),
		decisions: (state) => +get(state.data, 'inputs.newCount', 0),
		flags: (state) => +get(state.data, 'flags.newCount', 0),
		messages: (state) => +get(state.data, 'messages.newCount', 0),
		overdueAssessments: (state) => +get(state.data, 'overdue.newCount', 0),
		raisedFlags: (state) => +get(state.data, 'flagsRaised.newCount', 0),
		unseenAssessments: (state) => +get(state.data, 'assessmentsUnseen.newCount', 0),
		unseenFlags: (state) => +get(state.data, 'flagsUnseen.newCount', 0)
	},

	actions: {
		init: ({ commit }, { type }) => {
			const model = repository.notification(type);

			return model.then(() => {
				commit('set', { notifications: model.toJSON() });
			});
		}
	}
};
