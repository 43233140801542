import { when } from 'jquery';
import profile from './profile-store';
import members from './members-store';
import abuseReports from './abuse-reports';
import categories from './categories-store';
import currentCircle from './current-circle';
import { push, remove, toggleStatus } from './helpers';
import { concat, filter, find, findIndex, keyBy } from 'lodash';
import repository from 'repository';
import Candidate from 'repo/circles/candidate';
import Member from 'repo/circles/member';

export default {
	namespaced: true,
	modules: { members, profile, abuseReports, categories, currentCircle },

	state: {
		collection: {},
		current: []
	},

	getters: {
		my: (state) => filter(
			state.current,
			(circle) => circle.memberStatus.isMember === true ||
				circle.memberStatus.isCandidate === true
		),

		owner: (state) => state.currentCircle.data.owner,

		circleId: (state) => state.currentCircle.id,

		byId: (state) => keyBy(concat(state.current, state.currentCircle.data), 'id'),

		isOwner: (state, getters) => getters.owner &&
			getters['profile/profileData'].id === getters.owner.id
	},

	mutations: {
		set: (state, { circles, collection }) => {
			state.collection = collection;
			state.current = circles;
		},
		add: (state, { circles, collection }) => {
			push({ from: circles, to: state.current });

			if (!state.collection.add) {
				state.collection = collection;

			} else {
				state.collection.add(collection.models);
			}
		},

		removeCircle: (state, { circle }) => {
			remove({ item: circle, from: state.current });
		},

		join: (state, id) => {
			const circles = state.current.length ? state.current : [state.currentCircle.data];
			const idx = findIndex(circles, { id });
			state.current[idx] = toggleStatus({ circle: circles[idx], status: 'join' });
		},

		leave: (state, id) => {
			const circles = state.current.length ? state.current : [state.currentCircle.data];
			toggleStatus({ circle: find(circles, { id }), status: 'leave' });
		},

		clear: (state) => {
			state.collection = {};
			state.current = [];
		}
	},

	actions: {
		initMy: ({ commit, dispatch, rootState }) => {
			commit('clear');
			commit('currentCircle/clear');
			const collection = repository.circlesByUser(rootState.user.circleUser.id);

			return when(collection, dispatch('profile/init')).then(() => {
				commit('set', { circles: collection.toJSON(), collection });
			});
		},

		initSingle: ({ commit, dispatch }, { circleId }) => {
			commit('clear');
			commit('currentCircle/clear');
			return when(dispatch('currentCircle/init', { circleId }), dispatch('profile/init'));
		},

		set: ({ commit }, { circles, collection }) => {
			commit('set', { circles, collection });
		},

		add: ({ commit }, { circles, collection }) => {
			commit('add', { circles, collection });
		},

		remove: ({ commit, state }, { circle }) =>
			state.collection.get(circle.id).destroy().then(() => {
				commit('removeCircle', { circle });
			}),

		join: ({ commit, getters }, { circleId }) => {
			const request = new Candidate({
				id: getters['profile/profileData'].id,
				circle: circleId
			});
			return request.save().then(() => {
				commit('join', circleId);
			});
		},

		leave: ({ commit, getters }, { circleId }) => {
			const member = new Member({ id: getters['profile/profileData'].id, circle: circleId });
			return member.destroy().then(() => {
				commit('leave', circleId);
			});
		},

		cancelRequest: ({ commit, getters }, { circleId }) => {
			const request = new Candidate({
				id: getters['profile/profileData'].id,
				circle: circleId
			});
			return request.destroy().then(() => {
				commit('leave', circleId);
			});
		}
	}
};
