import featuresCheck from '../../helpers/features-check';

/**
 * Features definition for the tile. If check fails, tile won't initialize. Apart form array, there
 * can be a function passed.
 *
 * @name TileView.features
 * @param {*} features  - Features set.
 * @param {Object} tile - Tile object, here only for jsdoc compliance reasons.
 * @example
 * export default TileView.extend({
 *     features: ['ENABLE_MESSAGING_SYSTEM']
 * });
 */
export default (features, tile) => {
	if (tile.__params.tileContainerModel.get('preventInit') !== true) {
		tile.__params.tileContainerModel.set('preventInit', !featuresCheck(features));
	}
};
