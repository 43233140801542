<template>
	<!-- eslint-disable vue/valid-v-bind vue/attribute-hyphenation -->
	<component
		:is="component(item.type)"
		v-model="val"
		:label="item.label || ''"
		:translate="false"
		:multiline="isMultiline(item)"
		:numeric="isNumeric(item)"
		:options="getOptions(item)"
		:mandatory="isRequired(item)"
		:required="isRequired(item)"
		:readonly="isReadonly(item)"
		:tel="config.type === 'tel'"
		:email="config.type === 'email'"
		:pattern="pattern"
		:placeholder="config.placeholder"
		:[maybeDateInputType(item.type)]="'date'"
		:[maybeDateFormat(item.type)]="t('date.formats.dateTimeLuxon')"
		@input="$emit('input', format(attrVal, item.type))"
	/>
</template>

<script>
import { assign, constant, forEach, includes, isNull, isUndefined, split } from 'lodash';
import t from 'service/lang/translate';

export default {
	props: {
		value: {
			type: [String, Number, Boolean],
			default: undefined
		},

		attr: {
			type: Object,
			required: true
		},

		mapping: {
			type: Object,
			default: () => ({
				isRequired: 'isRequired',
				label: 'label',
				parameters: 'parameters',
				type: 'type'
			})
		},

		config: {
			type: Object,
			default: constant({})
		}
	},

	data: () => ({
		attrVal: undefined
	}),

	computed: {
		val: {
			get: ({ item, value }) => item.type === 'CHECKBOX' ?
				includes([true, 1, '1', 'true'], value)  :
				value,
			set (newVal) {
				this.attrVal = newVal;
			}
		},

		item: ({ attr, mapping }) => {
			const item = assign({}, attr);
			forEach(mapping, (to, from) => {
				item[from] = item[to];
			});

			return item;
		},

		pattern: ({ config }) => config.required && config.pattern
	},

	methods: {
		t,
		component (type) {
			return {
				CHECKBOX: 'checkbox-input',
				DATE: 'datetime-input',
				DROPDOWN: 'select-input'
			}[type] || 'text-input';
		},

		getOptions (item) {
			return !isNull(item.parameters) ?
				split(item.parameters, /;/) :
				null;
		},

		isRequired (item) {
			if (!isUndefined(this.config.required)) {
				return this.config.required;
			}

			return item.isRequired;
		},

		isMultiline (item) {
			if (this.config.type) {
				return this.config.type === 'textarea';
			}
			return item.type === 'TEXTAREA';
		},

		isNumeric (item) {
			if (this.config.type) {
				return this.config.type === 'number';
			}
			return item.type === 'NUMERIC';
		},

		isReadonly (item) {
			return item.isReadonly;
		},

		maybeDateInputType: (type) => type === 'DATE' ? 'type' : 'fakeType',
		maybeDateFormat: (type) => type === 'DATE' ? 'format' : 'fakeFormat',

		format: (value, type) => {
			if (type !== 'DATE') {
				return value;
			}

			return value ? value.substring(0, 10) : '';
		}

	}
};
</script>
