import handlebarsHelpers from './handlebars/handlebars-helpers';
import timepicker from './datetimepicker/timepicker';
import datepicker from './datetimepicker/datepicker';
import datetimepicker from './datetimepicker/datetimepicker';
import modernizrTests from './modernizr-tests/modernizr-tests';
import inputFocus from './input-focus/input-focus';
import { extendPrototypePeerAdded } from './webrtc/extensions';

import './vue/components';
import './vue/directives';
import './vue/filters';
import './vue/plugins';
import 'jquery.datetimepicker';
import 'jquery.autoresize';
import 'jquery.fileupload';
import 'jquery.floatThead';
import 'jquery.tablesorter';
import 'jqueryui.touch-punch';
import 'jquery.iframe-transport';
export default () => {
	handlebarsHelpers();
	timepicker();
	datepicker();
	datetimepicker();
	modernizrTests();
	inputFocus();
	extendPrototypePeerAdded();
};
