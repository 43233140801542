import { Collection, Model } from 'backbone';
import getTotalResults from './get-total-results';

const ViewModel = Model.extend({
	defaults: {
		search: '',
		start: 0,
		limit: 10
	},

	initialize: function () {
		this.set('results', new Collection());
	},

	setResults: function ({ xhrObj, url, resultsCollection }) {
		const searchResults = this.get('results');

		searchResults.total = getTotalResults({ xhrObj, url });

		if (this.get('start') === 0) {
			searchResults.reset(resultsCollection.models, { silent: true });

		} else {
			searchResults.add(resultsCollection.models, { silent: true });
		}

		this.trigger('change:results');
	}
});

export default () => new ViewModel();
