import SingleEntity from '../shared/single-entity';
import urls from './urls';
import t from 'translate';

export default SingleEntity.extend({
	urlRoot: urls.diaryEntry.url,
	eventNamespace: 'diary-entry',
	getMoodName () {
		return t({
			smile: 'Good mood',
			meh: 'Neutral mood',
			frown: 'Bad mood',
			// deprecated
			iconSmile: 'Good mood',
			iconMeh: 'Neutral mood',
			iconFrown: 'Bad mood'
		}[this.get('mood')] || '');
	}
});
