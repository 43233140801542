import { forEach } from 'lodash';
import acl from './acl';
import store from 'store';

const fnDefinitions = {
	read: 'READ',
	add: 'ADD',
	edit: 'EDIT',
	delete: 'DELETE',
	remove: 'DELETE',
	supervise: 'SUPERVISOR',
	administrate: 'ADMINISTRATOR'
};
const aclCan = {};

forEach(fnDefinitions, (op, fnName) => {
	aclCan[fnName] = (checkpoint) => store.state.user.type === 'clinician' ?
		acl.checkAccess({ checkpoint, op }) :
		true;
});

export default aclCan;
