import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import Member from './member';
import getTotalResults from 'service/search/get-total-results';

const CircleMembers = new (MultipleEntities.extend({
	model: Member,
	urls,
	eventNamespace: 'circle-member',

	list ({ circleId, start, limit }) {
		const list = this.retrieve('members', { circleId, start, limit });

		list.then((response, status, xhrObj) => {
			list.total = getTotalResults({
				xhrObj,
				url: this.url
			});
		});

		list.more = (start) => {
			const newMembers = this.retrieve('members', { circleId, start, limit });

			newMembers.then((response) => {
				list.add(response);
			});

			return newMembers;
		};

		return list;
	}
}))();

export { CircleMembers };
