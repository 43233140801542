const prefix = 'backend-commons/information-package/instance/';
const elementsSuffix = ({ type, respondentId, treatmentId }) =>
	`?ongoing=1&treatmentEnabled=1&filterEq[type]=${type}&respondent=${
		respondentId
	}&treatment=${treatmentId}`;

export default {
	contentPackages: ({ respondentId, treatmentId }) =>
		`${prefix}?active=1&ongoing=1&treatmentEnabled=1&respondent=${
			respondentId
		}&treatment=${treatmentId}`,

	contentPackage: (id = '') => `backend-commons/information-package/instance/${id}`,

	byCPInstance: ({ cpInstanceId, respondentId }) =>
		`${prefix}${cpInstanceId}/element?active=1&respondent=${respondentId}`,

	contentPackageElement: ({ contentPackageId, elementId }) =>
		`backend-commons/information-package/instance/${
			contentPackageId
		}/element/${elementId}`,

	elements: ({ respondentId, treatmentId, type }) =>
		`${prefix}element/${elementsSuffix({ type, respondentId, treatmentId })}`,

	complete: ({ contentPackageId, elementId }) =>
		`backend-commons/information-package/instance/${
			contentPackageId
		}/element/${elementId}/mark-as-completed/`
};
