import SharedView from './shared';
import { filter, isUndefined } from 'lodash';
import optionLabel from	'../helpers/option-label';
import { fixedOptions } from '../shared/options';

export default SharedView.extend({
	type: 'Slider',

	startValue () {
		const found = filter(this.model.answers, (answer) => answer.isSelected());
		return this.model.isAnswered() ? [found[0]] : 0;
	},

	options () {
		return fixedOptions({
			connect: false,
			endLabelsVisible: this.model.get('endLabelsVisible'),
			getUrl: this.model.mediaManager.getUrl,
			labelsVisible: this.model.get('labelsVisible'),
			orderedAnswers: this.orderedAnswers(),
			start: this.startValue()
		});
	},

	changeValue ({ decrease } = { decrease: false }) {
		return () => {
			const answers = this.orderedAnswers();
			const value = this.slider.noUiSlider.get();
			const index = answers.indexOf(value);
			const newIndex = decrease ? index - 1 : index + 1;

			answers[newIndex] && this.slider.noUiSlider.set(answers[newIndex]);
		};
	},

	onRender () {
		this.slider.noUiSlider.on('update', (values, handle) => {
			const option = values[handle];
			this.setAriaValue({
				value: this.orderedAnswers().indexOf(option),
				text: option.getType() === 'TextOption' ? option.getLabel() : ' '
			});
		});

		this.model.get('answerVisible') && this.renderLabel(this.startValue()[0]);

		this.slider.noUiSlider.on('set', (values, handle) => {
			values[handle].select({ byInput: true });
			this.handleAnswered();
		});
	},

	renderLabel (selectedItem) {
		const label = optionLabel({
			option: selectedItem,
			getUrl: this.model.mediaManager.getUrl
		});
		const isAnswered = !isUndefined(selectedItem);

		if (isAnswered) {
			this.$answer.html(this.answer(label));
			this.$container.removeClass('slider--unanswered');
			return;
		}
		this.$answer.html(this.answer(this.labels.none));
		this.$container.addClass('slider--unanswered');
	}
});
