import GoalCategories from './categories';
import urls from './urls';
import { GET, PUT, POST, DELETE } from 'service/ajax/ajax';

export default {

	/**
	 * @function goal
	 * @param {object} params -              Parameters.
	 * @param {number} params.goalId -       Goal ID.
	 * @param {number} params.respondentId - Respondent ID.
	 * @returns {object}                   - Promise.
	 * @example
	 * 		repository.goal({ goalId: 5, respondentId: 19 });
	 */
	goal: ({ goalId, respondentId }) => GET({
		url: urls.goal({ goalId, respondentId }),
		separator: '&'
	}),

	/**
	 * @function createGoal
	 * @param {object} goal - New goal data.
	 * @returns {object}    - Promise.
	 * @example
	 * 		repository.createGoal(newGoal);
	 */
	createGoal: (goal) => POST({
		url: urls.goal({
			respondentId: goal.respondent,
			goalId: '',
			includeCategory: false
		}),
		data: goal
	}),

	/**
	 * @function updateGoal
	 * @param {object} goal - Goal data.
	 * @returns {object}    - Promise.
	 * @example
	 * 		repository.updateGoal(goal);
	 */
	updateGoal: (goal) => PUT({
		url: urls.goal({
			goalId: goal.id,
			respondentId: goal.respondent,
			includeCategory: false
		}),
		data: goal
	}),

	/**
	 * @function deleteGoal
	 * @param {object} goal - Goal data.
	 * @returns {object}    - Promise.
	 * @example
	 * 		repository.deleteGoal(goal);
	 */
	deleteGoal: (goal) => DELETE({
		url: urls.goal({
			goalId: goal.id,
			respondentId: goal.respondent,
			includeCategory: false
		})
	}),

	/**
	 * @function goalActivities
	 * @param {object} params -              Parameters.
	 * @param {number} params.respondentId - Respondent ID.
	 * @param {number} params.goalId -       Goal ID.
	 * @returns {object}                   - Promise.
	 * @example
	 * 		repository.goalActivities({ respondentId: 19, goalId: 77 });
	 */
	goalActivities: ({ respondentId, goalId }) => GET({
		url: urls.goalActivities({ respondentId, goalId })
	}),

	/**
	 * @function respondentActivities
	 * @param {number} respondentId - Respondent ID.
	 * @returns {object}            - Promise.
	 * @example
	 * 		repository.respondentActivities(19);
	 */
	respondentActivities: (respondentId) => GET({
		url: urls.respondentActivities(respondentId)
	}),

	/**
	 * @function treatmentActivities
	 * @param {number} treatmentId - Treatment ID.
	 * @returns {object}           - Promise.
	 * @example
	 * 		repository.treatmentActivities(44);
	 */
	treatmentActivities: (treatmentId) => GET({
		url: urls.treatmentActivities(treatmentId)
	}),

	/**
	 * @function activity
	 * @param {object} params -              Parameters.
	 * @param {number} params.goalId -       Goal ID.
	 * @param {number} params.respondentId - Respondent ID.
	 * @param {number} params.activityId -   Activity ID.
	 * @returns {object}                   - Promise.
	 * @example
	 * 		repository.goal({ goalId: 5, respondentId: 19, activityId: 84 });
	 */
	activity: ({ respondentId, goalId, activityId }) => GET({
		url: urls.activity({ respondentId, goalId, activityId })
	}),

	/**
	 * @function createActivity
	 * @param {object} activity - New activity data.
	 * @returns {object}        - Promise.
	 * @example
	 * 		repository.createActivity(newActivity);
	 */
	createActivity: (activity) => POST({
		url: urls.activity({
			respondentId: activity.respondent,
			goalId: activity.goal,
			activityId: ''
		}),
		data: activity
	}),

	/**
	 * @function updateActivity
	 * @param {object} activity - Activity data.
	 * @returns {object}        - Promise.
	 * @example
	 * 		repository.updateActivity(activity);
	 */
	updateActivity: (activity) => PUT({
		url: urls.activity({
			activityId: activity.id,
			goalId: activity.goal,
			respondentId: activity.respondent
		}),
		data: activity
	}),

	/**
	 * @function deleteActivity
	 * @param {object} activity - Activity data.
	 * @returns {object}        - Promise.
	 * @example
	 * 		repository.deleteActivity(activity);
	 */
	deleteActivity: (activity) => DELETE({
		url: urls.activity({
			activityId: activity.id,
			goalId: activity.goalId,
			respondentId: activity.respondentId
		})
	}),

	/**
	 * @function respondentGoals
	 * @param {number} respondentId - Respondent ID.
	 * @returns {object} - Promise.
	 * @example
	 * 		repository.respondentGoals(19);
	 */
	respondentGoals: (respondentId) => GET({
		url: urls.respondentGoals(respondentId),
		separator: '&'
	}),

	/**
	 * @function treatmentGoals
	 * @param {number} treatmentId - Treatment ID.
	 * @returns {object} - Promise.
	 * @example
	 * 		repository.treatmentGoals(44);
	 */
	treatmentGoals: (treatmentId) => GET({
		url: urls.treatmentGoals(treatmentId),
		separator: '&'
	}),

	/**
	 * @function goalCategories
	 * @returns {object} - Promise.
	 * @example
	 * 		repository.goalCategories();
	 */
	goalCategories: () => GET({ url: urls.categories }),

	OLDgoalCategories: () => GoalCategories.getAll()
};
