import TimeContent from 'components/time-content/index';
import { RESPONDENT } from 'store/respondents/respondent';
import store from 'store';

/*
Example config
itemConfig = {
	"type": "date",
	"timezone": "respondent",
	"dateFormat": "DD.MM.YYYY, HH:mm"
}
*/

const timezone = (itemConfig) => {
	const getterName = {
		clinician: 'userTimezone',
		respondent: RESPONDENT.TIMEZONE,
		default: 'userTimezone'
	}[itemConfig.timezone || 'default'];

	return store.getters[getterName];
};

export default ({ itemConfig, rawContent }) => ({
	component: TimeContent,
	props: {
		format: itemConfig.dateFormat,
		time: rawContent,
		timezone: timezone(itemConfig)
	},
	value: rawContent
});
