import { assign, filter, find, findIndex, get, map, sortBy } from 'lodash';
import repository from 'repository';
import { cleanClinicianGroup, cleanAssessment, cleanFlag } from '../__helpers/clean';

const mutation = (type, name) => {
	const clean = {
		clinicianGroups: cleanClinicianGroup,
		expiredAssessments: cleanAssessment,
		flags: cleanFlag
	}[name];

	return {
		set: (state, items) => {
			state[name] = map(items, clean);
		},
		add: (state, item) => {
			state[name].push(item);
		},
		update: (state, item) => {
			const index = findIndex(state[name], { id: item.id });
			state[name][index] = assign(state[name][index], item);
		},
		remove: (state, id) => {
			const index = findIndex(state[name], { id });
			state[name].splice(index, 1);
		}
	}[type];
};

export default {
	namespaced: true,

	state: {
		data: null,
		model: null,
		revisions: [],
		revision: {},
		clinicianGroups: [],
		expiredAssessments: [],
		flags: [],
		treatmentTypeRoleSets: [],
		roles: [],
		roleSets: []
	},

	getters: {
		respondentRoles: (state, getters) => filter(getters.sortedRoles, { type: 'RESPONDENT' }),
		clinicianRoles: (state, getters) => filter(getters.sortedRoles, { type: 'CLINICIAN' }),
		current: (state) => state.data,
		diagram: (state) => get(state.data, 'diagram', []),
		details: (state) => get(state.data, 'details', {}),
		planId: (state) => get(state.data, 'details.workflowId', 0),
		treatmentType: (state) => get(state.data, 'details.treatmentType', 0),
		history: (state) => sortBy(state.revisions, (revision) => -1 * revision.workflowHistoryId),
		currentRevisionDiagram: (state) => JSON.parse(
			get(state.revision, 'workflowHistoryJson', '{}')
		).diagram,
		clinicianGroups: (state) => sortBy(
			state.clinicianGroups,
			(group) => group.name.toLocaleLowerCase()
		),
		// eslint-disable-next-line max-params
		expiredAssessments: (state, getters, rootState, rootGetters) => sortBy(
			map(state.expiredAssessments, (assessment) => {
				const assessmentObj = find(rootGetters['assessments/enabled'], {
					code: assessment.code
				});
				return assign(assessment, {
					id: assessmentObj.id,
					name: assessmentObj.name
				});
			}),
			(assessment) => get(assessment, 'name', '').toLocaleLowerCase()
		),
		// eslint-disable-next-line max-params
		flags: (state, getters, rootState, rootGetters) => sortBy(
			map(state.flags, (flag) => assign(flag, {
				code: get(
					find(rootGetters['assessmentFlags/all'], { triggerId: flag.triggerId }),
					'code',
					''
				)
			})),
			(flag) => get(flag, 'code', '').toLocaleLowerCase()
		),
		roleSets: (state) => state.roleSets,
		sortedRoles: (state) => sortBy(state.roles, 'name'),
		treatmentTypeRoleSets: (state) => state.treatmentTypeRoleSets
	},

	mutations: {
		set: (state, { plan, model }) => {
			state.data = plan;
			state.model = model;
		},

		setRevisions: (state, revisions) => {
			state.revisions = revisions;
		},

		setRevision: (state, revision) => {
			state.revision = revision;
		},

		reset: (state) => {
			state.data = null;
			state.model = null;
			state.revisions = [];
		},

		update: (state, payload) => {
			state.data = assign({}, state.data, payload);
			state.model.set(payload);
		},

		addRoleSet: (state, payload) => {
			state.roleSets.push(payload);
		},

		deleteRoleSet: (state, id) => {
			const index = findIndex(state.roleSets, { id });

			if (index !== -1) {
				state.roleSets.splice(index, 1);
			}
		},

		setRoles: (state, payload) => {
			state.roles = payload;
		},

		setRoleSets: (state, payload) => {
			state.roleSets = payload;
		},

		setTreatmentTypeRoleSets: (state, payload) => {
			state.treatmentTypeRoleSets = payload;
		},

		setClinicianGroups: mutation('set', 'clinicianGroups'),
		addClinicianGroup: mutation('add', 'clinicianGroups'),
		updateClinicianGroup: mutation('update', 'clinicianGroups'),
		removeClinicianGroup: mutation('remove', 'clinicianGroups'),

		setExpiredAssessments: mutation('set', 'expiredAssessments'),
		addExpiredAssessment: mutation('add', 'expiredAssessments'),
		updateExpiredAssessment: mutation('update', 'expiredAssessments'),
		removeExpiredAssessment: mutation('remove', 'expiredAssessments'),

		setFlags: mutation('set', 'flags'),
		addFlag: mutation('add', 'flags'),
		updateFlag: mutation('update', 'flags'),
		removeFlag: mutation('remove', 'flags')
	},

	actions: {
		checkTreatmentTypeRoleSets: ({ commit }, treatmentId) => {
			const roleSets = repository.treatmentTypeRoleSets(treatmentId);
			return roleSets.then((result) => {
				commit('setTreatmentTypeRoleSets', result);
			});
		},

		init: ({ commit, dispatch }, { planId }) => {
			const model = repository.getPlanById(planId);

			return model.then(() => {
				const plan = model.toJSON();
				commit('set', { plan, model });

				if (plan.details.treatmentType) {
					const treatmentTypeId = plan.details.treatmentType;
					dispatch('checkTreatmentTypeRoleSets', treatmentTypeId);
				}
			});
		},

		refetch: ({ commit, getters }) => {
			const model = repository.getPlanById(getters.planId);

			return model.then(() => {
				commit('set', { plan: model.toJSON(), model });
			});
		},

		getRevisions: ({ commit, getters }, { planId } = { planId: 0 }) =>
			repository.planRevisions(planId || getters.planId).then((revisions) => {
				commit('setRevisions', revisions);
			}),

		getRevision: ({ commit }, { revisionId }) => {
			const model = repository.planRevision(revisionId);
			return model.then(() => {
				commit('setRevision', model.toJSON());
			});
		},

		update: ({ commit }, payload) => {
			commit('update', payload);
		},

		save: ({ state, getters }) => {
			const model = state.model;

			return state.model.save().then(() => {
				model.set('details', getters.details);
			});
		},

		destroy: async ({ commit }, { planId }) => {
			await repository.deletePlan(planId);
			commit('reset');
		},

		initClinicianGroups: ({ commit }, planId) =>
			repository.planClinicianGroups(planId).then((groups) => {
				commit('setClinicianGroups', groups);
			}),

		addClinicianGroup: ({ commit, state }, group) => {
			commit('addClinicianGroup', group);

			return repository.addClinicianGroupToPlan({
				planId: state.data.workflowId,
				clinicianGroupId: group.id
			}).then((newGroup) => {
				commit('updateClinicianGroup', {
					id: group.id,
					relationId: cleanClinicianGroup(newGroup).relationId
				});
			});
		},

		removeClinicianGroup: ({ commit, state }, id) => {
			const relationId = get(find(state.clinicianGroups, { id }), 'relationId');
			commit('removeClinicianGroup', id);

			return repository.removeClinicianGroupFromPlan({ relationId });
		},

		initExpiredAssessments: ({ commit }, planId) =>
			repository.planExpiredAssessments(planId).then((expiredAssessments) => {
				commit('setExpiredAssessments', expiredAssessments);
			}),

		addExpiredAssessment: ({ commit, state }, expiredAssessment) => {
			commit('addExpiredAssessment', expiredAssessment);

			return repository.addExpiredAssessmentToPlan({
				planId: state.data.workflowId,
				assessmentCode: expiredAssessment.code
			}).then((newExpiredAssessment) => {
				commit('updateExpiredAssessment', {
					id: expiredAssessment.id,
					relationId: newExpiredAssessment.relationId
				});
			});
		},

		removeExpiredAssessment: ({ commit, state }, id) => {
			const relationId = get(find(state.expiredAssessments, { id }), 'relationId');
			commit('removeExpiredAssessment', id);

			return repository.removeExpiredAssessmentFromPlan({ relationId });
		},

		initFlags: ({ commit }, planId) =>
			repository.planFlags(planId).then((flags) => {
				commit('setFlags', flags);
			}),

		addFlag: ({ commit, state }, flag) => {
			commit('addFlag', flag);

			return repository.addFlagToPlan({
				planId: state.data.workflowId,
				triggerId: flag.triggerId
			}).then((newFlag) => {
				commit('updateFlag', {
					id: flag.id,
					relationId: newFlag.relationId
				});
			});
		},

		removeFlag: ({ commit, state }, id) => {
			const relationId = get(find(state.flags, { id }), 'relationId');
			commit('removeFlag', id);

			return repository.removeFlagFromPlan({ relationId });
		},

		addRoleSet: ({ commit, state }, data) => {
			data.planId = state.data.details.workflowId;
			const result = repository.addPlanInstanceRoleSet(data);
			return result.then((response) => {
				commit('addRoleSet', response);
			});
		},

		deleteRoleSet: ({ commit, state }, id) => {
			const data = { id, planId: state.data.details.workflowId };
			const result = repository.deletePlanInstanceRoleSet(data);
			return result.then(() => commit('deleteRoleSet', id));
		},

		getRoleSets: ({ commit, state }, planId = null) => {
			const id = !planId ? state.data.details.workflowId : planId;
			repository.planInstanceRoleSets(id).then((response) => {
				commit('setRoleSets', response);
			});
		},

		getAvailableRoles: ({ commit }, { planId }) => repository.getAvailableRolesById(planId)
			.then((response) => {
				commit('setRoles', response);
			})
	}
};
