<template functional>
	<a
		v-if="props.link"
		:href="props.link"
		class="mini-value-tile__link mini-value-tile__container"
	><slot /></a>
	<div v-else class="mini-value-tile__container">
		<slot />
	</div>
</template>

<script>
export default {
	props: {
		subtitle: {
			type: String,
			required: true
		},
		link: {
			type: String,
			required: true
		}
	}
};
</script>
