<template>
	<div
		:class="mainClasses"
	>
		<component
			:is="tagName"
			:href="url"
			:class="className"
		>
			<div v-if="imageUrl" class="mini-shortcut-tile__image-container">
				<img
					:src="imageUrl"
					:alt="image.alt"
					:width="image.width"
					:height="image.height"
					class="mini-shortcut-tile__image"
				/>
			</div>

			<div v-if="contentExists" class="mini-shortcut-tile__content-container">
				<icon v-if="cfg.icon" :name="cfg.icon" />
				<div class="mini-shortcut-tile__title-container">
					<p class="mini-shortcut-tile__title">
						{{tileTitle}}
					</p>
					<p v-if="subtitle" class="mini-value-tile__subtitle">
						<span>{{tileSubtitle}}</span>
					</p>
				</div>
				<p
					v-if="shouldShow('count') || shouldShow('totalCount')"
					class="mini-shortcut-tile__value"
				>
					<span v-if="shouldShow('count')">{{count}}</span><!--
					--><span
						v-if="shouldShow('totalCount')"
						class="mini-tile__total counter-tile__total"
					>/{{totalCount}}</span>
				</p>
				<span class="mini-shortcut-tile__icon" aria-hidden="true">
					<span class="icon icon--angle-right icon-2x" />
				</span>
			</div>
		</component>
	</div>
</template>

<script>
import acl from 'acl';
import aclCheck from 'core/engine/tile/helpers/acl-check';
import canAccess from 'core/engine/card/services/can-access';
import cardUrl from 'cwcardurl';
import mapping from './store-mapping';
import t from 'translate';
import {
	assign, defaults, forEach, get, isFinite, isNumber, isNaN, isUndefined, has, omitBy, isArray,
	isObject
} from 'lodash';
import { warn } from 'service/log/log';
import referral from 'service/referral';
import urls from 'repo/media/urls';
import { TREATMENT } from 'store/treatments/rp-treatment';

const hasCard = (tile) => has(tile.cfg.link, 'card');
const emptyCard = (tile) => hasCard(tile) && !get(tile.cfg.link, 'card');
const handleAclArray = (tile) => {
	let hasAccess = true;
	forEach(tile.cfg.ACL, (item) => {
		if (!acl.checkAccess(item)) {
			hasAccess = false;
		}
	});
	return hasAccess;
};

const hasNoAccess = (tile) => isObject(tile.cfg.ACL) && !aclCheck(tile.cfg.ACL);

const enabled = (tile) => {
	tile.cfg = tile.config();

	if (emptyCard(tile)) {
		return false;
	}

	if (isArray(tile.cfg.ACL)) {
		return handleAclArray(tile);
	}

	if (hasNoAccess(tile)) {
		return false;
	}

	return hasCard(tile) ? canAccess(get(tile.cfg.link, 'card')) : true;
};

export default {
	instant: true,
	actions: (tile) => enabled(tile) && !isUndefined(mapping[tile.config().counterAlias]) ?
		mapping[tile.config().counterAlias].actions() :
		'',
	data: ({ tile }) => ({
		cfg: tile.config()
	}),
	computed: {
		url: ({ $store, cfg }) => {
			if (get(cfg.link, 'url')) {
				return cfg.link.url;

			} else if (get(cfg.link, 'card')) {
				const { card, ...rest } = cfg.link;

				assign(rest, {
					respondentId: $store.getters['respondent/respondentId'],
					treatmentId: $store.getters['userType'] === 'respondent' ?
						$store.getters[TREATMENT.ID] :
						$store.getters['treatment/treatmentId'],
					referral: referral()
				});
				return cardUrl.buildUrl(card, omitBy(rest, isUndefined));

			} else if (get(cfg.link, 'link')) {
				return cardUrl.buildUrl('view-link', { id: cfg.link.link });
			}
			return '';
		},
		tagName: ({ url }) => url ? 'a' : 'div',
		mainClasses: ({ cfg, url }) => [
			'mini-shortcut-tile',
			url ? 'mini-shortcut--with-link' : '',
			...get(cfg, 'classes', [])
		],

		tileTitle: ({ cfg }) => {
			if (cfg.translate && isUndefined(cfg.title)) {
				warn('You must define tile title');
				return '';
			}
			return cfg.translate ? t(cfg.title) : cfg.title;
		},

		subtitle: ({ cfg }) => get(cfg, 'subtitle', ''),

		tileSubtitle: ({ cfg, subtitle }) => cfg.translate ? t(subtitle) : subtitle,

		count: ({ $store, cfg }) => {
			const getterCounter = get(mapping, `${cfg.counterAlias}.getter.count`);

			if (getterCounter) {
				return +$store.getters[getterCounter];

			} else if (cfg.counterAlias) {
				warn(`counterAlias "${cfg.counterAlias}" for count doesn't exist`);
			}

			return null;
		},

		totalCount: ({ $store, cfg }) => {
			const getterTotal = get(mapping, `${cfg.counterAlias}.getter.total`);

			if (getterTotal) {
				return $store.getters[getterTotal];
			}

			return null;
		},

		className: ({ url, alarming, warning }) => {
			const classes = [
				'mini-shortcut-tile__container',
				url ? 'mini-shortcut-tile__link' : '',
				'mini-shortcut-tile__text-container'
			];

			if (alarming) {
				classes.push('important mini-shortcut-tile__container--alarm');

			} else if (warning) {
				classes.push('mini-shortcut-tile__container--warning');
			}

			return classes;
		},

		alarming: ({ count, cfg }) => isFinite(count) && count >= cfg.alarm,
		warning: ({ count, cfg }) => isFinite(count) && count >= cfg.warning,

		image: ({ cfg }) => defaults(get(cfg, 'image', {}), {
			alt: '',
			permalink: '',
			height: '',
			scale: true,
			url: '',
			width: ''
		}),

		imageUrl: ({ image, imagePermalink }) => image.url || imagePermalink,

		imagePermalink: ({ image }) => image.permalink ? urls.permalink.url(image.permalink) : '',

		contentExists: ({ cfg }) => cfg.title || cfg.subtitle || cfg.icon
	},

	mounted () {
		if (!enabled(this.tile)) {
			this.tile.disable();
		}
	},

	methods: {
		shouldShow (field) {
			return isNumber(this[field]) && !isNaN(this[field]);
		}
	}
};
</script>
