import t from 'service/lang/translate';
import categoryTag from 'components/category-tag/category-tag';
import { isNull } from 'lodash';
import datetime from 'service/datetime/datetime';
import store from 'store';
import { statusButton } from '../components/index';

export default (tile) => [{
	key: 'name',
	label: t('Circle name')
}, {
	key: 'categories',
	label: t('Category'),
	render: (item) => categoryTag(item)[0]
}, {
	key: 'owner',
	label: t('Owner'),
	render: (item) => item.nickname
}, {
	key: 'code',
	label: t('Connection code')
}, {
	key: 'membersCount',
	label: t('Members'),
	className: 'circles-list__members'
}, {
	key: 'lastActivityAt',
	label: t('Last post'),
	render: (lastActivity) => isNull(lastActivity) ?
		'' :
		datetime(lastActivity)
			.setTimezone(store.state.user.timezoneName)
			.toMoment()
			.format(t('date.formats.dateTime'))
}, {
	key: 'memberStatus',
	label: t('Your status'),
	css: 'circles-list__status',
	render: (value, { item }) => {
		const circle = item.toJSON ? item.toJSON() : item;
		const button = statusButton(circle);
		tile.buttons.push(button);

		return button.$el;
	}
}];
