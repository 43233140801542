import appUrl from 'service/app-url/app-url';
import { filter, map } from 'lodash';

const html = ({ value, valueLabels, getUrl }) => {
	const labelsHtml = (labels) => map(labels, (label, i) =>
		(i > 0 ? '<br />' : '') + {
			'questionnaire:ImageValueLabel':
				`<img src="${appUrl(getUrl(label.imageFile))}" />`,
			'questionnaire:TextValueLabel': `${label.label}`
		}[label.type]
	).join('');

	if (valueLabels && valueLabels.length) {
		const found = filter(valueLabels, (valueLabel) =>
			parseFloat(valueLabel.value) === parseFloat(value));

		if (!found) {
			return '';
		}

		return `<div class="value-label">${labelsHtml(found)}</div>`;
	}

	return '';
};

export default ({ value, valueLabels, getUrl }) => `
	<div class="value">${parseFloat(value)}</div>
	${html({ value, valueLabels, getUrl })}
`;
