import t from 'translate';
import TileView from 'tile-view';
import Circle from 'repo/circles/circle';
import formView from 'components/form-view/form-view';
import cardUrl from 'cwcardurl';
import store from 'store';
import { concat } from 'lodash';
import enableCirclesCheck from '../../helpers/enable-circles-check';
import treatmentContextParam from 'modules/treatments/util/treatment-context-param';

const features = () => {
	if (store.state.user.type === 'respondent') {
		return concat(enableCirclesCheck(), 'ENABLE_RESPONDENT_CREATE_CIRCLE');
	}

	return enableCirclesCheck();
};

export default TileView.extend({
	title: t('Create a new circle'),
	acl: [{
		op: 'READ',
		checkpoint: 'administrator.circles.circles'
	}],
	features,

	tileData: () => ({
		circle: new Circle({ owner: store.getters['circles/profile/profileData'].id })
	}),

	loaded: ({ tile }) => {
		tile.form = formView({
			model: tile.circle,
			listenTo: ['submit'],
			saveIndicator: true,
			autocomplete: false,
			onAfterSave: () => {
				tile.card().close({
					go: cardUrl.buildUrl('circle', {
						circleId: tile.circle.getId(),
						...treatmentContextParam()
					})
				});
			},

			fields: [{
				key: 'name',
				label: t('Name'),
				mandatory: true
			}, {
				key: 'code',
				type: 'username',
				variant: 'circle',
				propName: 'code',
				label: t('Code'),
				mandatory: true,
				customize (view) {
					view.ui.input.on('change', () => {
						// Prevent displaying form overlay on blur
						// to allow clicking on submit button
						view.setLoaded();
					});
				},
				description: {
					available: t('This code is available'),
					unavailable: t('This code is already in use'),
					error: t('Failed to check the code'),
					progress: t('Checking the code availability')
				}
			}, {
				key: 'categories',
				type: 'multiselect',
				label: t('Category'),
				collection: store.state.circles.categories.collection,
				title: 'link'
			}, {
				key: 'exactMatch',
				type: 'checkbox',
				label: t('Exact match of code only')
			}, {
				key: 'description',
				type: 'textarea',
				label: t('Description')
			}],
			buttons: [{
				caption: t('Save'),
				name: 'save',
				key: 'save',
				type: 'submit'
			}, {
				caption: t('Reset'),
				name: 'button',
				type: 'reset',
				key: 'cancel',
				role: 'reset'
			}]
		});

		tile.$el.append(tile.form.$el);
	}
});
