import select from './select/select';
import Search from './search/search';
import text from './text/text';
import Checkbox from './checkbox/checkbox';

export default () => ({
	'single-select': select('single'),
	'multi-select': select('multi'),
	'search': Search,
	'datetime': text('datetime-local'),
	'text-input': text('text'),
	'checkbox': Checkbox
});
