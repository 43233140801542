import Marionette from 'marionette';

const respondentRouter = Marionette.AppRouter.extend({
	appRoutes: {
		'': 'home'
	}
});

export default respondentRouter;

