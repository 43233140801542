import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import MediaReference from './media-reference';

const MediaReferences = MultipleEntities.extend({
	urls,
	model: MediaReference,

	getByAssessmentInstanceId (assessmentInstanceId) {
		return this.retrieve('mediaReferences', assessmentInstanceId);
	},

	getFileUrl (filename) {
		const fileModel = this.findWhere({ filename });
		return fileModel.getFileUrl();
	}
});

export default new MediaReferences();

