export default {
	config: () => ({
		appUrl: window.DEVCONF.clinician.appUrl,
		baseUrl: window.DEVCONF.baseUrl,
		backendRootUrl: window.DEVCONF.clinician.backendRootUrl,
		authUrl: window.DEVCONF.clinician.authRootUrl,
		planEditorAppUrl: window.DEVCONF.clinician.planEditorAppUrl,
		cmsAppUrl: window.DEVCONF.clinician.cmsAppUrl,
		cardsDefinitionRoot: window.DEVCONF.clinician.cardsDefinitionRoot
	})
};
