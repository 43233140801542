import { assign } from 'lodash';

// based on https://gist.github.com/jtsternberg/c272d7de5b967cec2d3d
export default ($el2, $el1) => {
	const e1 = {
		offset: $el1.offset(),
		height: $el1.outerHeight(true),
		width: $el1.outerWidth(true)
	};

	assign(e1, {
		distanceFromTop: e1.offset.top + e1.height,
		distanceFromLeft: e1.offset.left + e1.width
	});

	const e2 = {
		offset: $el2.offset(),
		height: $el2.outerHeight(true),
		width: $el2.outerWidth(true)
	};

	assign(e2, {
		distanceFromTop: e2.offset.top + e2.height,
		distanceFromLeft: e2.offset.left + e2.width
	});

	const notColliding = (
		e1.distanceFromTop < e2.offset.top ||
		e1.offset.top > e2.distanceFromTop ||
		e1.distanceFromLeft < e2.offset.left ||
		e1.offset.left > e2.distanceFromLeft
	);

	return {
		collides: !notColliding,
		height: e1.height
	};
};
