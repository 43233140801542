import { View } from 'backbone';
import { compile } from 'handlebars';

export default View.extend({
	// Unable to do much better with formatting, more newlines break rendering
	template: compile(`
<div class="
	spinner
	spinner--single-color
	{{#if variant}} spinner--{{ variant }} {{/if}}" role="presentation">
	<div class="spinner__layer spinner__layer-1">
		<div class="spinner__circle-clipper spinner__left"><div class="spinner__circle"></div></div><div class="spinner__gap-patch"><div class="spinner__circle"></div></div><div class="spinner__circle-clipper spinner__right"><div class="spinner__circle"></div></div>
	</div>
	<div class="spinner__layer spinner__layer-2">
		<div class="spinner__circle-clipper spinner__left"><div class="spinner__circle"></div></div><div class="spinner__gap-patch"><div class="spinner__circle"></div></div><div class="spinner__circle-clipper spinner__right"><div class="spinner__circle"></div></div>
	</div>
	<div class="spinner__layer spinner__layer-3">
		<div class="spinner__circle-clipper spinner__left"><div class="spinner__circle"></div></div><div class="spinner__gap-patch"><div class="spinner__circle"></div></div><div class="spinner__circle-clipper spinner__right"><div class="spinner__circle"></div></div>
	</div>
	<div class="spinner__layer spinner__layer-4">
		<div class="spinner__circle-clipper spinner__left"><div class="spinner__circle"></div></div><div class="spinner__gap-patch"><div class="spinner__circle"></div></div><div class="spinner__circle-clipper spinner__right"><div class="spinner__circle"></div></div>
	</div>
</div>
	`),
	className: 'spinner__container',

	initialize (opts) {
		this.opts = opts || {};
	},

	render () {
		this.$el.html(this.template(this.opts));
		return this;
	}
});
