<template>
	<treatment-grid-item
		:image="treatmentImage"
		@click="click"
	>
		<treatment-grid-aux v-if="!isMainRespondent">
			<div class="treatment-selection__role-container">
				<span class="treatment-selection__role-name">
					{{roleName}}
				</span>
				<span class="treatment-selection__main-respondent">
					{{$t('treatmentSelection.of')}}
					<strong>{{respondentName(mainRespondent)}}</strong>
				</span>
			</div>
		</treatment-grid-aux>

		<treatment-grid-header icon="treatment">
			{{title}}
		</treatment-grid-header>

		<treatment-grid-label :label="$t('general-list.StartDate')">
			{{startAt}}
		</treatment-grid-label>
	</treatment-grid-item>
</template>

<script>
import TreatmentGridItem from '../treatment-grid/item';
import TreatmentGridHeader from '../treatment-grid/header';
import TreatmentGridLabel from '../treatment-grid/label';
import TreatmentGridAux from '../treatment-grid/auxiliary';
import { find, get } from 'lodash';
import appUrl from 'service/app-url/app-url';
import { respondentName } from 'service/display-name/display-name';
import formatDate from 'service/format-date';

export default {
	components: {
		TreatmentGridItem,
		TreatmentGridHeader,
		TreatmentGridLabel,
		TreatmentGridAux
	},

	props: {
		treatment: {
			type: Object,
			required: true
		}
	},

	computed: {
		titleProperty: ({ $store }) => {
			const startCard = find(
				$store.getters['engine/cards/current'],
				{ 'card-name': 'start' }
			);
			return get(startCard, '__temp__titleProperty') || 'treatmentType.name';
		},
		treatmentImage: ({ treatment })	=> {
			const file = get(treatment, 'treatmentType.cover.url');
			return appUrl(file, { forcePrepend: true, forcePrefix: 'shared' });
		},

		roleBased: ({ treatment }) => !!treatment.treatmentRoles.length,

		mainRespondent: ({ treatment }) => treatment.respondent,

		isMainRespondent: ({ $store, mainRespondent, roleBased }) => (
			!roleBased || +mainRespondent.id === +$store.getters.userId
		),

		role: ({ $store, roleBased, treatment }) => {
			if (!roleBased) {
				return '';
			}

			return find(
				treatment.treatmentRoles,
				(role) => +role.respondent.id === +$store.getters.userId
			);
		},

		roleName: ({ role }) => get(role, 'role.name'),

		title: ({ treatment, titleProperty }) => get(treatment, titleProperty, ''),

		timezone: ({ $store }) => $store.getters['respondent/timezone'],

		startAt: ({ treatment, timezone }) => formatDate(treatment.startAt, timezone)
	},

	methods: {
		respondentName,
		click () {
			this.$emit('click');
		}
	}
};
</script>
