import SingleEntity from '../shared/single-entity';

/**
 * Timezone Entity
 * @class Timezone
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	idAttribute: 'timezoneId',
	eventNamespace: 'timezone'
});

