import { when } from 'jquery';
import currentCardAction from './current-card-action';
import ACL from 'acl';
import { TREATMENT } from '../treatments/rp-treatment';

const accessDenied = (acl) =>
	acl && acl.length && !ACL.checkAccess({ checkpoint: acl, op: 'READ' });

const obtainTreatmentId = (rootGetters) => {
	if (rootGetters.userType === 'respondent') {
		return rootGetters[TREATMENT.ID];
	}

	return rootGetters['treatment/treatmentId'];
};

export default (
	actionName,
	{ acl, allRespondents } = { acl: '', allRespondents: false }
) => ({ dispatch, rootGetters, rootState }) => {
	if (accessDenied(acl)) {
		return when({});
	}
	const respondentId = rootGetters['respondent/respondentId'];
	const treatmentId = obtainTreatmentId(rootGetters);

	if (respondentId && !treatmentId) {
		const promises = currentCardAction({ dispatch, rootState }, {
			action: 'respondent/init',
			source: actionName
		}).concat(dispatch('initForRespondent', { respondentId, allRespondents }));

		return when(...promises);

	} else if (treatmentId) {
		const promises = currentCardAction({ dispatch, rootState }, {
			action: 'treatment/init',
			source: actionName
		});

		promises.push(dispatch('initForTreatment', { treatmentId, allRespondents }));
		return when(...promises);
	}
};
