import { constant, noop } from 'lodash';

export default ({
	blueprints: {
		type: Function,
		required: true
	},
	checkedItems: {
		type: Array,
		default: constant([])
	},
	copy: {
		type: Array,
		default: constant([])
	},
	copyPath: {
		type: String,
		default: ''
	},
	disabled: {
		type: Boolean,
		default: false
	},
	dispatch: {
		type: Function,
		required: true
	},
	getters: {
		type: Function,
		required: true
	},
	icons: {
		type: Object,
		default: constant({})
	},
	incompletePaths: {
		type: Array,
		default: noop
	},
	indeterminate: {
		type: Array,
		default: constant([])
	},
	mirrorDetails: {
		type: Object,
		default: constant({})
	},
	mirrors: {
		type: Array,
		default: constant([])
	},
	namePath: {
		type: String,
		default: 'name'
	},
	onAdd: {
		type: Function,
		default: noop
	},
	passButtonRefs: {
		type: Function,
		default: noop
	},
	position: {
		type: Number,
		default: 0
	},
	rootMirrors: {
		type: Array,
		default: constant([])
	},
	selected: {
		type: Array,
		default: constant([])
	},
	setCopyPath: {
		type: Function,
		required: true
	},
	toggle: {
		type: Function,
		required: true
	},
	toggleEdit: {
		type: Function,
		required: true
	},
	warningPaths: {
		type: Array,
		default: constant([])
	}
});
