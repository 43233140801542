var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "treatment-selection" },
    [
      _vm.enabledCount && !_vm.loading
        ? _c("h2", { staticClass: "treatment-selection__title" }, [
            _vm._v(
              "\n\t\t" + _vm._s(_vm.$t("treatmentSelection.title")) + "\n\t"
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("span", {
            directives: [
              { name: "loader-spinner", rawName: "v-loader-spinner" }
            ]
          })
        : _vm.enabledCount
        ? [
            _c("checkbox-input", {
              attrs: {
                translate: false,
                label: _vm.$t("treatmentSelection.asDefault")
              },
              model: {
                value: _vm.asDefault,
                callback: function($$v) {
                  _vm.asDefault = $$v
                },
                expression: "asDefault"
              }
            }),
            _vm._v(" "),
            _c("treatment-selection-list", {
              attrs: { treatments: _vm.enabledTreatments },
              on: { click: _vm.select }
            })
          ]
        : _c("div", { staticClass: "treatment-selection__empty" }, [
            _c("div", [
              _c("img", {
                staticClass: "treatment-selection__empty-image",
                attrs: {
                  src: _vm.emptyIcon,
                  alt: _vm.$t("treatmentSelection.noTreatments")
                }
              })
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("treatmentSelection.noTreatments")))])
          ]),
      _vm._v(" "),
      !_vm.loading
        ? _c("ul", { staticClass: "treatment-selection__action-items" }, [
            _c("li", { staticClass: "treatment-selection__action-item" }, [
              _vm.consentsCount
                ? _c(
                    "button",
                    {
                      staticClass: "treatment-selection__action",
                      attrs: { tabindex: "0" },
                      on: { click: _vm.openConsents }
                    },
                    [
                      _c("icon", {
                        staticClass: "treatment-selection__action-icon",
                        attrs: { name: "consent-template" }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "treatment-selection__action-text" },
                        [_vm._v(_vm._s(_vm.$t("consents.my")))]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "treatment-selection__action-item" }, [
              _c(
                "button",
                {
                  staticClass: "treatment-selection__action",
                  attrs: { tabindex: "0" },
                  on: { click: _vm.logout }
                },
                [
                  _c("icon", {
                    staticClass: "treatment-selection__action-icon",
                    attrs: { name: "logout" }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "treatment-selection__action-text" },
                    [_vm._v(_vm._s(_vm.$t("Logout")))]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }