import t from 'translate';

export default () => ({
	label: t('My mood'),
	key: 'mood',
	type: 'radio',
	values: [{
		smile: `<span class="fa icon--smile" title="${t('Good mood')}"></span>`
	}, {
		meh: `<span class="fa icon--meh" title="${t('Neutral mood')}"></span>`
	}, {
		frown: `<span class="fa icon--frown" title="${t('Bad mood')}"></span>`
	}],
	hideDefaultLabel: true
});
