import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import Post from './post';
import getTotalResults from 'service/search/get-total-results';

const CirclePosts = new (MultipleEntities.extend({
	model: Post,
	urls,
	eventNamespace: 'circle-post',

	list ({ circleId, start, limit }) {
		const list = this.retrieve('posts', { circleId, start, limit });

		list.then((response, status, xhrObj) => {
			list.total = getTotalResults({
				xhrObj,
				url: JSON.stringify({ circleId, start, limit })
			});
		});

		list.more = () => {
			const more = start + limit;
			const newPosts = this.retrieve('posts', { circleId, start: more, limit });

			newPosts.then((response) => {
				list.add(response);
			});

			return newPosts;
		};

		list.all = () => {
			const allPosts = this.retrieve('posts', { circleId });

			allPosts.then((response) => {
				list.add(response);
			});

			return allPosts;
		};

		return list;
	}
}))();

export { CirclePosts };
