import { exact } from '../__helpers/getters';
import { set } from '../__helpers/mutations';
import repository from 'repository';
import prefixify from '../__helpers/prefixify';
import { get } from 'lodash';

const prefix = prefixify('session');

const DATA = 'data';
const EXPIRED = 'expired';
const IDLE_TIME = 'idleTime';
const MAX_IDLE_TIME = 'maxIdleTime';
const REMAINING = 'remaining';
const SET = 'set';
const INIT = 'init';

export const SESSION = {
	EXPIRED: prefix(EXPIRED),
	INIT: prefix(INIT),
	REMAINING: prefix(REMAINING)
};

export default {
	namespaced: true,
	state: {
		[DATA]: null
	},

	getters: {
		...exact([DATA]),
		[IDLE_TIME]: (state, getters) => get(getters[DATA], 'idleTime'),
		[MAX_IDLE_TIME]: (state, getters) => get(getters[DATA], 'maxIdleTime'),
		[EXPIRED]: (state, getters) => getters[REMAINING] < 0,
		[REMAINING]: (state, getters) => getters[MAX_IDLE_TIME] - getters[IDLE_TIME]
	},

	mutations: {
		[SET]: set()
	},

	actions: {
		[INIT]: async ({ commit }) => {
			commit(SET, await repository.idleTime());
		}
	}
};
