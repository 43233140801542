<template>
	<div
		v-on-click-outside="hide"
		:class="containerClassNames"
		@keyup.esc="hide"
		@keyup.up="onUp"
		@keyup.down="onDown"
	>
		<button
			ref="trigger"
			tabindex="0"
			:class="filterClassNames"
			@click="toggle()"
		>
			{{label}}
			<span class="filter__toggle-number">({{items.length}})</span>
		</button>

		<ul
			class="filter__list"
			:class="listClassNames"
		>
			<li
				v-for="option in items"
				:key="option.id"
				class="filter__list-item"
				:class="listItemClassNames(option)"
			>
				<button
					ref="items"
					type="button"
					:class="buttonClasses(option)"
					tabindex="0"
					@click="optionToggle(option.id)"
				>
					{{option.label}}
				</button>

				<div class="filter__remove">
					<button
						tabindex="0"
						class="filter__remove-action"
						:title="$t('Remove filter')"
						@click="removeFilter(option.id)"
					/>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import { constant, includes, indexOf, isUndefined, split } from 'lodash';

export default {
	props: {
		filterId: {
			type: String,
			default: ''
		},
		items: {
			type: Array,
			default: constant([])
		},
		label: {
			type: String,
			default: ''
		},
		loading: {
			type: Boolean,
			required: true
		},
		value: {
			type: [Number, String],
			required: true
		},
		type: {
			type: String,
			default: 'single'
		}
	},

	data: () => ({
		focusIndex: -1,
		toggled: false
	}),

	computed: {
		model: {
			get: ({ value }) => value,
			set (newVal) {
				this.$emit('input', newVal || '');
			}
		},
		containerClassNames: ({ classNames, model, multi }) => ({
			'table-view__filter-select': true,
			...classNames('container', model),
			'filter__container--multi-select': !!multi,
			'filter__container--single-select': !multi
		}),

		multi: ({ type }) => type === 'multiselect',
		filterClassNames: ({ classNames, toggled }) => classNames('toggle', toggled),
		listClassNames: ({ classNames, toggled }) => classNames('list', toggled)
	},

	methods: {
		classNames (block, condition) {
			return {
				[`filter__${block}`]: true,
				[`filter__${block}--active`]: condition,
				[`filter__${block}--dormant`]: !condition
			};
		},

		toggle (bool) {
			this.toggled = isUndefined(bool) ? !this.toggled : bool;
		},

		hide (event) {
			if (!event || event.target !== this.$refs.trigger) {
				this.toggle(false);
			}
		},

		buttonClasses (option) {
			return this.classNames(
				'action',
				includes(JSON.stringify(this.model), JSON.stringify(option.id))
			);
		},

		listItemClassNames (option) {
			return this.classNames(
				'list-item',
				includes(JSON.stringify(this.model), JSON.stringify(option.id))
			);
		},

		optionToggle (newVal) {
			if (this.multi) {
				const model = split(this.model, '&');
				const idx = indexOf(model, newVal);

				if (idx < 0) {
					model.push(newVal);

				} else {
					model.splice(idx, 1);
				}

				this.model = model.join('&');

			} else {
				this.model = newVal === this.model ? '' : newVal;
				this.hide();
			}

		},

		removeFilter (index) {
			this.optionToggle(index);
		},

		onUp () {
			if (this.focusIndex > 0) {
				this.focusIndex--;
			}
			this.triggerFocus();
		},

		onDown () {
			if (this.focusIndex <= this.items.length) {
				this.focusIndex++;
			}
			this.triggerFocus();
		},

		triggerFocus () {
			if (this.focusIndex >= 0) {
				const el = this.$refs.items[this.focusIndex];
				el.focus();
				el.scrollIntoView(false);
			}
		}
	}
};
</script>
