import { set } from '../__helpers/mutations';
import repository from 'repository';
import { assign, filter, find, map, reject } from 'lodash';
import { NOT_SUBMITTED } from '../../modules/consents/shared/statuses';
import prefixify from '../__helpers/prefixify';
import { TREATMENT } from 'store/treatments/rp-treatment';
import { TREATMENTS } from '../treatments/treatments';

const prefix = prefixify('respondentConsentInstances');

const DATA = 'data';

const ITEMS = 'items';
const ALL_COUNT = 'allCount';
const NOT_SUBMITTED_COUNT = 'notSubmittedCount';
const FROM_DISABLED_TREATMENTS = 'fromDisabledTreatments';
const FROM_RELATIONS = 'fromRelations';

const SET = 'set';

const INIT = 'init';
const INIT_ALL = 'initAll';

export const RESPONDENT_CONSENT_INSTANCES = {
	ALL_COUNT: prefix(ALL_COUNT),
	FROM_DISABLED_TREATMENTS: prefix(FROM_DISABLED_TREATMENTS),
	FROM_RELATIONS: prefix(FROM_RELATIONS),
	ITEMS: prefix(ITEMS),
	NOT_SUBMITTED_COUNT: prefix(NOT_SUBMITTED_COUNT),

	INIT: prefix(INIT),
	INIT_ALL: prefix(INIT_ALL)
};

export default {
	namespaced: true,

	state: {
		[DATA]: []
	},

	getters: {
		[ITEMS]: (state) => map(state[DATA], (item) => assign({}, item, {
			name: item.consentVersion.name
		})),
		[ALL_COUNT]: (state, getters) => getters[ITEMS].length,
		[NOT_SUBMITTED_COUNT]: (state, getters) => filter(
			getters[ITEMS],
			{ status: NOT_SUBMITTED }
		).length,

		[FROM_DISABLED_TREATMENTS]: (
			state, getters, rootState, rootGetters
			// eslint-disable-next-line max-params
		) => filter(getters[ITEMS], (consent) => find(
			rootGetters[TREATMENTS.DISABLED],
			(treatment) => +treatment.id === +consent.treatment.id
		)),

		[FROM_RELATIONS]: (
			state, getters, rootState, rootGetters
			// eslint-disable-next-line max-params
		) => reject(
			getters[ITEMS],
			(consent) => +consent.respondent.id === +rootGetters.userId
		)
	},

	mutations: {
		[SET]: set()
	},

	actions: {
		[INIT]: async ({ commit, rootGetters }) => {
			const consentInstances = await repository.consentInstances({
				treatmentId: rootGetters[TREATMENT.ID]
			});
			commit(SET, consentInstances);
		},

		[INIT_ALL]: async ({ commit }) => {
			const consentInstances = await repository.allConsentInstances();
			commit(SET, consentInstances);
		}
	}
};
