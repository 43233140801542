import { includes, indexOf, isUndefined } from 'lodash';
import { history } from 'backbone';
import cardsProps from './cards-props';
import renderCurrent from './render-current';
import cardControls from './card-controls';

const CardManager = function () {
	/*
	 * Show a Card View
	 * @method CardManager#showCard
	 * @param  {cardView} cardView Card View to be showed
	 * @param  {Object} [options] Options hashtable
	 * @return {CardManager}
	 */
	this.showCard = function (cardView, options = {}) {
		const cardName = cardView.model.get('cardName');

		if (!includes(cardsProps.names, cardName)) {
			this.registerCard(cardView, options);
		}

		if (isUndefined(options.variantIndex)) {
			const index = indexOf(cardsProps.names, cardName);
			cardsProps.moveToTopFromIndex(index);
		}

		renderCurrent(options);

		return this;
	};

	/*
	 * Register a Card View
	 * Contrary to popular logic this function is called every time a
	 * router hits something, i.e. every time card gets rendered and it's
	 * not because other card was closed.
	 *
	 * @method CardManager#registerCard
	 * @param {cardView} cardView Card View to be registered
	 * @param {Object} [options] Options hashtabe
	 * @return {CardManager}
	 */
	this.registerCard = function (cardView, options = {}) {
		const cardName = cardView.model.get('cardName');
		const props = {
			routes: options.route || history.fragment || 'home',
			views: cardView,
			names: cardName
		};

		const existingIndex = indexOf(cardsProps.names, cardName);

		cardView.model.get('context').on('card:close', (opts = {}) => {
			cardControls.closeCard(opts);
		});

		if (existingIndex > -1) {
			cardsProps.views[existingIndex].destroy(); // remove zombie view
			cardsProps.update({ index: existingIndex, props });

		} else {
			cardsProps.add(props);
			cardsProps.urls[cardName] = cardView.model.get('cardUrl');
		}
	};
};

export default new CardManager();
