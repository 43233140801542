import { isEmpty, toArray } from 'lodash';
import Backbone from 'backbone';
import CollectionView from './list';

export default (items, { iconClassName } = { iconClassName: 'icon--categories' }) => {

	if (isEmpty(items)) {
		return '';
	}

	const collection = new Backbone.Collection(toArray(items));
	const view = new CollectionView({ collection, iconClassName });
	view.render();

	return view.$el;
};
