import { set as mSet } from '../__helpers/mutations';
import { assign, filter, find, findIndex, forEach,  get, map, reduce, sortBy } from 'lodash';
import repository from 'repository';

export default {
	namespaced: true,
	state: {
		data: {},
		options: [],
		clinicianRoles: []
	},
	getters: {
		consentInstance: (state) => state.data.consentVersion,
		clinicianCanSubmit: (state) => get(state.data, 'consentVersion.submittableByAdministrator'),
		options: (state) => sortBy(state.options, ['order', 'id']),

		respondents: (state) => reduce(state.options, (result, option) => {
			const answers = filter(option.answers, { isPreviousRespondent: false });
			forEach(answers, (answer) => {
				if (!find(result, { id: answer.respondent.id })) {
					result.push(assign(
						answer.respondent,
						{ roleName: answer.respondentRoleName }
					));
				}
			});
			return result;
		}, []),

		optionsForCurrentRespondents: (state, getters) => map(
			getters.options, (opt) => assign({}, opt, {
				answers: filter(opt.answers, { isPreviousRespondent: false })
			})
		)
	},
	mutations: {
		set: mSet(),
		setOptions: mSet('options')
	},
	actions: {
		init: ({ commit }, consentInstanceId) =>
			repository.clinicianConsentInstance(consentInstanceId).then((response) => {
				commit('set', response);
			}),

		initOptions: ({ commit }, consentInstanceId) =>
			repository.consentInstanceOptions(consentInstanceId).then((response) => {
				commit('setOptions', response);
			}),

		addConsentInstance: ({ commit }, {
			consent,
			treatment,
			respondent,
			startAt
		}) => repository.addConsentInstance({
			consent,
			treatment,
			respondent,
			startAt
		}).then((response) => {
			commit('set', response);
			return response;
		}),

		submit: (context, { data, consentInstanceId }) => repository.saveConsentInstance({
			consentInstanceId, data
		}),

		submitForRespondent: ({ dispatch, getters, state }, { options, respondentId }) => {
			const data = map(getters.optionsForCurrentRespondents, (option, i) => {
				const idx = findIndex(option.answers, ['respondent.id', respondentId]);
				return assign(option, {
					answers: [options[i].answers[idx]]
				});
			});

			return dispatch('submit', { consentInstanceId: state.data.id, data });
		}
	}
};
