import repository from 'repository';
import { set } from '../__helpers/mutations';
import { assign, get } from 'lodash';
import initWith from '../__helpers/init-with';

const prefix = 'activity/';
const IS_COMPLETED = 'isCompleted';
const ITEM = 'item';
const INIT = 'init';
const UPDATE = 'update';

export const ACTIVITY = {
	INIT: `${prefix}${INIT}`,
	IS_COMPLETED: `${prefix}${IS_COMPLETED}`,
	ITEM: `${prefix}${ITEM}`,
	UPDATE: `${prefix}${UPDATE}`
};

export default {
	namespaced: true,

	state: {
		data: {},
		goalId: 0,
		respondentId: 0
	},

	getters: {
		[IS_COMPLETED]: (state) => get(state, 'data.isCompleted', false),
		[ITEM]: (state) => assign(state.data, {
			goal: state.goalId,
			respondent: state.respondentId
		})
	},

	mutations: {
		set: set(),
		setGoalId: set('goalId'),
		setRespondentId: set('respondentId')
	},

	actions: {
		[INIT]: (
			{ commit, dispatch, rootState },
			{ goalId, respondentId, activityId }
		) => {
			const resource = repository.activity({ goalId, respondentId, activityId });

			return initWith({ rootState, dispatch }, {
				actionName: ACTIVITY.INIT,
				resource,
				action: (activity) => {
					commit('set', activity);
					commit('setGoalId', goalId);
					commit('setRespondentId', respondentId);

					return {
						treatmentId: activity.treatmentId,
						respondentId: activity.respondent
					};
				}
			});
		},

		[UPDATE]: ({ commit }, activity) => repository.updateActivity(activity).then((result) => {
			commit('set', result);
		})
	}
};
