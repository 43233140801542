/**
 * Get the status basing on entity fields. Returns the first one that is truthy.
 *
 * @param {object} entity  - Source entity.
 * @param {Array} statuses - Optional list of available statuses.
 * @returns {string}       - The status.
 * @example
 *        status({ completed: false, emergency: true, inactive: true });
 *        -> 'emergency'
 */
export const status = (
	entity = {},
	statuses = ['completed', 'open', 'emergency', 'not-completed', 'overdue', 'inactive']
) => {
	for (let i = 0; i < statuses.length; i++) {
		const statusName = statuses[i];

		if (entity[statusName]) {
			return statusName;
		}
	}

	return 'stateless';
};
