import datetime from 'datetime';
import repository from 'repository';
import t from 'translate';
import { assign, clone, find, map, reduce, sortBy, toLower } from 'lodash';
import { cleanExportClinicianGroup } from '../__helpers/clean';
import { when } from 'jquery';
import prefixify from '../__helpers/prefixify';

const prefix = prefixify('exportTemplate');
const CLINICIAN_GROUPS = 'clinicianGroups';
const TREATMENT_TYPE_WARNING = 'treatmentTypeWarning';
const TREATMENTS = 'treatments';

export const EXPORT_TEMPLATE = {
	CLINICIAN_GROUPS: prefix(CLINICIAN_GROUPS),
	TREATMENT_TYPE_WARNING: prefix(TREATMENT_TYPE_WARNING),
	TREATMENTS: prefix(TREATMENTS)
};

const blankPayload = {
	dataExportInstanceCreated: null,
	dataExportInstanceModified: null,
	dataExportInstanceRangeStart: null,
	dataExportInstanceRangeStop: null
};

const setDate = (instance, property) => {
	const finalProperty = `dataExportInstance${property}`;

	if (!instance[finalProperty]) {
		return null;
	}
	return datetime(instance[finalProperty])
		.toMoment()
		.format(t('date.formats.dateTime'));
};

const sortListByName = (list) => {
	const clonedList = clone(list);
	clonedList.length && clonedList.sort((a, b) => a.name.localeCompare(b.name));
	return clonedList;
};

const stringifyIds = (list) => map(list, (item) => assign({}, item, { id: `${item.id}` }));

export default {
	namespaced: true,

	state: {
		availableAssessments: [],
		clinicianGroups: [],
		id: 0,
		instance: {},
		treatmentTypeOptions: [],
		treatmentTypes: [],
		treatmentTypeErrors: false
	},

	getters: {
		[CLINICIAN_GROUPS]: (state) => map(state.clinicianGroups, cleanExportClinicianGroup),
		[TREATMENTS]: (state) => stringifyIds(
			sortListByName(state.treatmentTypes)
		),

		instance: (state) => state.instance,

		treatmentTypeOptions: (state) => state.treatmentTypeOptions,
		treatmentTypes: (state) => state.treatmentTypes,
		[TREATMENT_TYPE_WARNING]: (state) => state.treatmentTypeErrors,
		sortedAssessments: (state) => sortBy(
			state.availableAssessments,
			(assessment) => toLower(assessment.assessmentName)
		),
		sortedByName: (state) => {
			const types = clone(state.treatmentTypes);
			types.length && types.sort((a, b) => a.name.localeCompare(b.name));

			return types;
		},
		sortedByNameIdStringified: (state, getters) =>
			map(getters.sortedByName, (type) => assign({}, type, { id: `${type.id}` }))
	},

	mutations: {
		destroy: (state) => {
			state.instance = {};
		},
		setAvailableAssessments: (state, payload) => {
			state.availableAssessments = payload;
		},
		setClinicianGroups: (state, payload) => {
			state.clinicianGroups = payload;
		},
		setId: (state, id) => {
			state.id = id;
		},
		setInstance: (state, payload) => {
			state.instance = payload;
		},
		setTreatmentTypeOptions: (state, payload) => {
			state.treatmentTypeOptions = payload;
		},
		setTreatmentTypes: (state, payload) => {
			state.treatmentTypes = payload;
		},
		setTreatmentTypesErrors: (state, treatmentTypesError) => {
			state.treatmentTypeErrors = treatmentTypesError;
		}
	},

	actions: {
		addAndRefreshClinicianGroup: ({ dispatch, state }, data) => {
			data.templateId = state.id;
			return dispatch('addClinicianGroup', data)
				.then(() => dispatch('refresh', state.id));
		},

		addClinicianGroup: (content, data) =>
			repository.addExportTemplateClinicianGroup(data),

		addAndRefreshTreatmentType: ({ dispatch, state }, data) => {
			data.templateId = state.id;
			return dispatch('addTreatmentType', data)
				.then(() => dispatch('getTreatmentTypes'));
		},

		addTreatmentType: (content, data) =>
			repository.addExportTemplateTreatmentType(data),

		deleteAndRefreshClinicianGroup: ({ dispatch, state }, id) => {
			const data = { id, origin: 'clinician-group', templateId: state.id };
			return dispatch('deleteClinicianGroup', data)
				.then(() => dispatch('refresh', state.id));
		},

		deleteClinicianGroup: (content, data) =>
			repository.deleteExportTemplateClinicianGroup(data),

		deleteAndRefreshTreatmentType: ({ dispatch, state }, id) => {
			const data = { id, origin: 'treatment-type', templateId: state.id };
			return dispatch('deleteTreatmentType', data)
				.then(() => dispatch('getTreatmentTypes'));
		},

		deleteTreatmentType: (content, data) =>
			repository.deleteExportTemplateTreatmentType(data),

		destroy: ({ commit }) => commit('destroy'),

		getAvailableAssessments: ({ commit }, templateId) =>
			repository.getAvailableAssessments(templateId)
				.then((response) => {
					commit('setAvailableAssessments', response);
				}),

		getClinicianGroups: ({ commit, state }, templateId = null) => {
			if (templateId) {
				commit('setId', templateId);
			}
			return repository.getExportTemplateClinicianGroups(templateId || state.id)
				.then((response) => {
					commit('setClinicianGroups', response);
				});
		},

		getTreatmentTypeOptions: ({ commit, dispatch }, templateId) => repository
			.getExportTemplateTreatmentTypeOptions(templateId)
			.then((response) => {
				commit('setTreatmentTypeOptions', response);
				dispatch('setError');
			}),

		getTreatmentTypes: ({ commit, dispatch, state }, templateId = null) => {
			if (templateId) {
				commit('setId', templateId);
			}
			return when(dispatch('getTreatmentTypeOptions', state.id))
				.then(repository.getExportTemplateTreatmentTypes(state.id)
					.then((response) => {
						commit('setTreatmentTypes', response);
						dispatch('setError');
					}));
		},

		init: ({ commit }, id) => {
			const exportInstance = repository.ExportInstances.getById(id);
			exportInstance.then(() => {
				const instance = assign(exportInstance.toJSON(), blankPayload);
				assign(instance, {
					dataExportInstanceCreated: setDate(instance, 'CreatedDate'),
					dataExportInstanceModified: setDate(instance, 'LastModifiedDate'),
					dataExportInstanceRangeStart: setDate(instance, 'FilterDateRangeStart'),
					dataExportInstanceRangeStop: setDate(instance, 'FilterDateRangeStop')
				});
				commit('setInstance', instance);
			});
		},

		refresh: ({ dispatch }, id) => when(dispatch('getClinicianGroups'))
			.then(dispatch('getTreatmentTypeOptions', id)),

		setError: ({ commit, state }) => {
			const treatmentTypesError = reduce(
				state.treatmentTypes,
				(result, item) => find(state.treatmentTypeOptions,
					(element) => +element.id === +item.id) ?
					result :
					true,
				false
			);
			commit('setTreatmentTypesErrors', treatmentTypesError);
		}
	}

};
