<template>
	<section :class="mainClassName">
		<agenda-items
			:collapsed="collapsed"
			:items="items"
			:direction="direction"
			:store-path="storePath"
		/>
		<agenda-add-item :datetime="datetime" :scope="scope" :items="addable" />
	</section>
</template>

<script>
import Vue from 'vue';
import './agenda-items';
import './agenda-add-item';
import './agenda-group-title';
import { optionalArray, requiredArray, requiredObject, requiredString } from './props';
import agendaMixin from './agenda-mixin';
import { AGENDA_GROUP } from './constants';

export default Vue.component(AGENDA_GROUP, {
	mixins: [agendaMixin],
	props: {
		addable: optionalArray,
		collapsed: {
			type: [Boolean, String],
			default: false
		},
		datetime: requiredObject,
		direction: requiredString,
		items: requiredArray,
		scope: requiredString,
		storePath: requiredString
	},

	computed: {
		type: ({ datetime, scope }) => (scope === 'month' || scope === 'week') &&
			datetime.weekday > 5 ?
			'holiday' :
			'default',

		mainClassName: ({ $options, type }) => [$options.name, `${$options.name}--${type}`]
	}
});
</script>

