import SingleEntity from '../shared/single-entity';
import { activated } from './urls';
import { sync } from 'backbone';

export default SingleEntity.extend({
	eventNamespace: 'activated-app',
	urlRoot: activated.url,

	onSync (method, model, options) {
		if (method === 'update') {
			options.type = 'PATCH';
		}
		return sync.call(this, method, model, options);
	}
});
