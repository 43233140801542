/**
 * @class Repository
 */
export default {
	/**
	 * Get all Languages
	 * @method getLanguages
	 * @return {Object} {{#crossLink "Languages"}}{{/crossLink}}
	 * @example
	 *        Repository.getLanguages();
	 */
	getLanguages: function () {
		return this.Languages.getAll();
	},

	/**
	 * Get enabled Languages
	 * @method getEnabledLanguages
	 * @return {Object} {{#crossLink "Languages"}}{{/crossLink}}
	 * @example
	 *        Repository.getEnabledLanguages();
	 */
	getEnabledLanguages: function () {
		return this.Languages.getEnabled();
	}
};
