var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("figure", { staticClass: "circle-progress" }, [
    _c("svg", { attrs: { height: _vm.size, width: _vm.size } }, [
      _c("circle", {
        staticClass: "circle-progress__background",
        attrs: {
          fill: "transparent",
          r: _vm.radius,
          cx: _vm.size / 2,
          cy: _vm.size / 2,
          stroke: _vm.backgroundStroke,
          "stroke-width": _vm.strokeWidth
        }
      }),
      _vm._v(" "),
      _c("circle", {
        staticClass: "circle-progress__value",
        attrs: {
          fill: "transparent",
          r: _vm.radius,
          cx: _vm.size / 2,
          cy: _vm.size / 2,
          stroke: _vm.stroke,
          "stroke-width": _vm.strokeWidth,
          "stroke-dasharray": _vm.strokeDashArray,
          "stroke-dashoffset": _vm.progress
        }
      })
    ]),
    _vm._v(" "),
    _c(
      "figcaption",
      { staticClass: "circle-progress__text" },
      [_vm._t("default", [_vm._v(_vm._s(_vm.percent) + "%")])],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }