<template>
	<component
		:is="`filter-${config.type}`"
		v-model="model"
		:filter-id="filterId"
		:loading="loading"
		:items="items"
		:label="config.label"
		:type="config.type"
	/>
</template>

<script>
import { get, map } from 'lodash';
import FilterCombo from './filter-combo';
import FilterSelect from './filter-select';
import FilterCheckbox from './filter-checkbox';
import { fetchAction, enrichItem } from '../helpers';
import { RAW_ENTITIES } from 'store/general/raw-entities';

export default {
	components: { FilterCombo, FilterSelect, FilterCheckbox, FilterMultiselect: FilterSelect },
	props: {
		filterId: {
			type: String,
			default: ''
		},

		config: {
			type: Object,
			required: true
		},

		value: {
			type: [Number, String],
			required: true
		}
	},

	data: () => ({
		loading: false
	}),

	computed: {
		rawItems: ({ $store, config, filterId }) => {
			if (get(config, 'data.values')) {
				return config.data.values;
			}
			return $store.getters[RAW_ENTITIES.DATA][filterId];
		},

		items: ({ config, rawItems }) => map(
			rawItems,
			(item) => enrichItem({ item, additionalData: config.data.additional })
		),

		model: {
			get: ({ value }) => value,
			set (newVal) {
				this.$emit('input', newVal);
			}
		}
	},

	created () {
		this.init();
	},

	methods: {
		init () {
			if (get(this.config, 'data.url')) {
				this.fetchData();
			}
		},

		async fetchData () {
			this.loading = true;
			await this.$store.dispatch(...fetchAction({
				dataUrl: this.config.data.url,
				id: this.filterId
			}));
			this.loading = false;
		}
	}
};
</script>
