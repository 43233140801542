import appContext from 'app-context';
import urlify from 'service/urlify/urlify';

const prefix = 'backend-commons/respondent';
const prefix2 = 'backend-commons/respondents'; // #justb💩ckendthings

export default {
	respondent: `${prefix2}/`,

	respondentByName: {
		url: (username) => `${prefix2}/username/${username}`
	},

	searchRespondents: {
		url: (params) => `${prefix2}/?${urlify(params)}`
	},

	currentRespondent: {
		url: () => `${prefix2}/${appContext.get('user').get('userId')}`
	},

	listClinicians: {
		url: (id) => `backend-commons/administrator/rest/respondentadmin/by-respondent/${id}`
	},

	singleClinician: {
		url: 'backend-commons/administrator/rest/respondentadmin/'
	},

	listGroupsByCurrentClinician: {
		// eslint-disable-next-line max-len
		url: `backend-commons/administrator/rest/administrator_group_has_respondent_group/all?filter=currentAdministrator`
	},

	currentClinicianGroupsWithCommon: {
		// eslint-disable-next-line max-len
		url: `backend-commons/administrator/rest/administrator_group_has_respondent_group/all?filter=currentAdministrator&includeCommonGroups=1`
	},

	listGroups: {
		url: `${prefix}/rest/group/all`
	},

	singleGroup: {
		url: `${prefix}/rest/group/`
	},

	groupWithClinicianGroups: {
		url: (id) => `${prefix}/rest/group/${id}?include=administratorGroup`
	},

	attributeDefinition: {
		url: 'backend-commons/respondent-attributes/'
	},

	singleAttributeValue: {
		url: 'backend-commons/respondent-attribute-instances/'
	},

	listAttributeValues: {
		url: (id) => `backend-commons/respondent-attribute-instances/by-respondent/${id}`
	},

	respondentInGroup: {
		url: `${prefix}/rest/respondentgroup/`
	},

	respondentInGroups: {
		url: (id) => `${prefix}/rest/respondentgroup/by-respondent/${id}`
	},

	deniedClinicians: {
		url: (respondentId) => `${prefix}/${respondentId}/denied-administrator/`
	},

	deniedClinician: {
		url: (
			{ respondentId, clinicianId }
		) => `${prefix}/${respondentId}/denied-administrator/${clinicianId}`
	},

	addRelation: () => `backend-commons/respondent-relation/`,

	deleteRelation: {
		url: (
			{ relationTypeId, respondent1Id, respondent2Id }
			// eslint-disable-next-line max-len
		) => `backend-commons/respondent-relation/relation-type/${relationTypeId}/respondent1/${respondent1Id}/respondent2/${respondent2Id}`
	},

	respondentRelations: {
		url: (respondentId) => `${prefix}/${respondentId}/respondent-relation/`
	},

	respondentConsents: {
		url: ({ respondentId }) => `${prefix}/${respondentId}/consent-instance/`
	},

	respondents: {
		url: () => `${prefix2}/`
	},

	lockStatus: (respondentId) =>
		`backend-commons/username-authentication-locks/user/respondent/${respondentId}`,
	unlock: (respondentId) =>
		`backend-commons/username-authentication-locks/user/respondent/${respondentId}`
};
