import { assign } from 'lodash';

/**
 * Simple cursor to iterate over an array
 * @author Waldemar
 * @class Cursor
 * @param {Object} opt options
 * @param {Array} opt.container array to be iterated
 */

export default function ({ container }) {
	container && assign(this, {
		val: 0,

		plus () {
			this.val++;
			(this.val > container.length - 1) && (this.val = 0);
		},

		minus () {
			this.val--;
			(this.val < 0) && (this.val = container.length - 1);
		}
	});
}
