<template>
	<button
		tabindex="0"
		class="appbar-system-buttons__action"
		:aria-expanded="expanded"
		aria-controls="sidebar-container"
		:title="$t('Main menu')"
		@click="$emit('click')"
	><icon name="menu" /></button>
</template>
<script>

export default {
	props: {
		expanded: {
			type: Boolean,
			default: false
		}
	}
};
</script>
