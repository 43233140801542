import MultipleEntities from '../shared/multiple-entities';
import TreatmentTypeAttribute from './attribute-entity';
import urls from './urls';

/**
 * TreatmentTypeAttributes Entity
 * @class TreatmentTypes
 * @extends MultipleEntities
 */
const TreatmentTypeAttributesEntity = MultipleEntities.extend({
	model: TreatmentTypeAttribute,
	urls,
	eventNamespace: 'treatment-type-attribute',

	canAdd: function (model) {
		return +model.get('treatmentType') === +this.ownerId;
	},

	/**
	 * Get all TreatmentTypeAttributes
	 * @method getByTreatmentType
	 * @return {Object} new TreatmentTypeAttributes Collection
	 * @example
	 *        Repository.TreatmentTypeAttributes.getAll();
	 */
	getByTreatmentType: function (treatmentTypeId) {
		return this.retrieve('listAttributes', treatmentTypeId);
	}

});

export default new TreatmentTypeAttributesEntity();
