import repository from 'repository';
import prefixify from '../__helpers/prefixify';
import { set } from '../__helpers/mutations';
import { exact } from '../__helpers/getters';

const prefix = prefixify('respondentLock');

const SET = 'set';
const DATA = 'data';

const INIT = 'init';
const UNLOCK = 'unlock';

export const RESPONDENT_LOCK = {
	DATA: prefix(DATA),
	INIT: prefix(INIT),
	UNLOCK: prefix(UNLOCK)
};

export default {
	namespaced: true,

	state: {
		[DATA]: null
	},

	getters: {
		...exact([DATA])
	},

	mutations: {
		[SET]: set()
	},

	actions: {
		[INIT]: async ({ commit }, respondentId) =>
			commit(SET, await repository.respondentLockStatus(respondentId)),

		[UNLOCK]: async (context, respondentId) =>
			await repository.unlockRespondent(respondentId)
	}
};
