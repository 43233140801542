import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import store from 'store';
import { CARD } from 'store/engine/card';

export default () => {
	const { protocol, host } = window.location;
	const token = window.DEVCONF.sentry.token;

	if (!token) {
		return false;
	}

	Sentry.init({
		dsn: `${protocol}//${token}@${host}/sentry//1`,
		release: 'webapp@4.2.0',
		integrations: [
			new VueIntegration({
				Vue,
				attachProps: true,
				logErrors: true
			}),
			new Integrations.BrowserTracing()
		]
	});

	const onInitUser = (payload) => {
		const { user, type } = payload;
		const { username, userId, displayName, timezoneName } = user.toJSON();

		Sentry.setContext('session', {
			appType: type,
			username,
			userId,
			displayName,
			timezoneName
		});
	};

	const onCardInit = (card) => {
		Sentry.addBreadcrumb({
			type: 'default',
			message: `Navigated to card: ${card.cardName} (${card.title})`,
			data: {
				url: window.location.href,
				cardUrl: card.cardUrl
			}
		});
	};

	store.subscribeAction((action) => {
		if (action.type === CARD.INIT) {
			onCardInit(action.payload);
		}
	});

	store.subscribe((mutation) => {
		if (mutation.type === 'initUser') {
			onInitUser(mutation.payload);
		}
	});

	return true;
};
