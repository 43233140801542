export default {
	props: {
		show: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: 'goals.editActivity.addTitle'
		},
		onClose: {
			type: Function,
			required: true
		},
		loading: {
			type: Boolean,
			default: false
		},
		onSubmit: {
			type: Function,
			required: true
		}
	}
};
