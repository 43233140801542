import { CompositeView } from 'marionette';
import { compile } from 'handlebars';

const Fieldset = CompositeView.extend({
	ui: {
		fieldset: 'fieldset'
	},
	template: compile(`
		<fieldset class="
			form-view__fieldset
			{{#if squeeze }} form-view__fieldset--squeeze {{/if}}
			">
			{{#if caption }}
				<legend class="form-view__fieldset__caption {{ name }}">{{ caption }}</legend>
			{{/if}}
		</fieldset>
	`)
});

export { Fieldset };
