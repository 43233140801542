import reports from '../assessments/reports';
import respondentAssessmentInstance from '../assessments/rp-assessment-instance';
import respondentAssessmentInstances from '../assessments/rp-assessment-instances';
import respondentConsentInstances from '../consents/rp-consent-instances';
import respondentConsentInstance from '../consents/rp-consent-instance';
import respondentContentPackage from '../content-packages/rp-content-package';
import respondentContentPackageElement from '../content-packages/rp-content-package-element';
import respondentContentPackageElements from '../content-packages/rp-content-package-elements';
import respondentContentPackageInstances from '../content-packages/rp-content-package-instances';
import respondentExercises from '../content-packages/rp-exercises';
import respondentLinks from '../links/rp-links';
import respondentMedia from '../media/rp-media';
import respondentPages from '../pages/rp-pages';
import respondentTreatment from '../treatments/rp-treatment';

export default {
	reports,
	respondentAssessmentInstance,
	respondentAssessmentInstances,
	respondentConsentInstance,
	respondentConsentInstances,
	respondentContentPackage,
	respondentContentPackageElement,
	respondentContentPackageElements,
	respondentContentPackageInstances,
	respondentExercises,
	respondentLinks,
	respondentMedia,
	respondentPages,
	respondentTreatment
};
