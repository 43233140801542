import FormComponentView from '../form-component-view';
import { compile } from 'handlebars';
import ClinicianUsernameCheck from './check-clinician-username-availability';
import RespondentUsernameCheck from './check-respondent-username-availability';
import MediaCheck from 'repo/media/unique-check';
import CircleCheck from 'repo/circles/unique-check';
import t from 'translate';
import { Deferred } from 'jquery';

const username = FormComponentView.extend({
	template: compile(`
		<label class="form-view__label-container">
			<p class="form-view__label-text">{{ label }}</p>
			<input
				type="text"
				class="form-view__input form-view__text"
				name="{{ uniqueName }}"
				{{#if value}} value="{{ value }}" {{/if}}
				{{#if required}} required="required" {{/if}}
				{{#if readonly}} readonly="readonly" {{/if}}
				{{#if disabled}} disabled="disabled" {{/if}}
				{{#if hint}} aria-describedby="{{ name }}-hint" {{/if}}
				{{#if noAutocomplete}} autocomplete="nope" {{/if}} />
				{{! tricky one, see more details
					https://developer.mozilla.org/en-US/docs/Web/Security` +
					`/Securing_your_site/Turning_off_form_autocompletion }}
		</label>
		{{#if hint}}
			<div class="form-component-view__hint form-view__hint" id="{{ name }}-hint">
				{{ hint }}
			</div>
		{{/if}}
	`),

	initialize () {
		this.UserNameCheck = {
			clinician: ClinicianUsernameCheck,
			respondent: RespondentUsernameCheck,
			media: MediaCheck,
			circle: CircleCheck
		}[this.model.get('variant') || 'respondent'];
	},

	onAfterRender () {
		this.oldValue = this.ui.input.val();

		// thankfully it's cleaned in customRemove
		this.ui.input.on('change', this.inputHasChanged.bind(this));
	},

	inputHasChanged () {
		this.inputChanged();
		const usernameCheck = new this.UserNameCheck();
		const msg = this.model.get('description') || {
			available: t('The username is available'),
			unavailable: t('The username is already in use'),
			error: t('Failed to check the username'),
			progress: t('Checking the username availability')
		};
		const newValue = this.ui.input.val();

		if (newValue !== this.oldValue) {
			this.setLoading();
			this.model.set('hint', msg.progress);

			const data = {
				[this.model.get('propName') || 'username']: newValue
			};

			usernameCheck.save(data, {
				success: (response) => {
					this.handleResponse({
						valid: response.get('available'),
						hint: response.get('available') ? msg.available : msg.unavailable
					});
				},
				error: () => {
					this.handleResponse({
						valid: false,
						hint: msg.error
					});
				}
			});

		} else {
			this.resetModel();
		}
	},

	resetModel () {
		this.setInitial();
		this.model.set('hint', '');
	},

	handleResponse (opts) {
		const validFnName = opts.valid ? 'setValid' : 'setInvalid';
		this[validFnName](t(opts.hint)).setLoaded();
		this.model.set('hint', '');
	},

	customRemove () {
		this.ui.input.off('change');
		this.remove();
	},

	validate () {
		const dff = Deferred();

		if (this.isValid()) {
			dff.resolve();

			if (this.model.get('mandatory') && !this.ui.input.val().length) {
				dff.reject();
				this.setInvalid(t('Field is required'));
			}
		} else {
			dff.reject();
		}
		return [dff.promise()];
	}
});

export { username };

