import { View } from 'marionette';
import { compile } from 'handlebars';
import $ from 'jquery';
import fallbackViewModel from './view-model';
import t from 'translate';
import { get } from 'lodash';
import cwalert from 'cwalert';
import backendConfig from 'service/backend-config';

export default View.extend({
	template: compile(`
		<div class="form-view__file-preview--fallback">
			{{#if url}}
				<a
					href="{{ url }}"
					target="_blank"
					class="form-view__file-image-container form-view__file-image-container--{{ type }}">
					{{#if isImage }}<img src="{{ url }}" class="form-view__file-image" />{{/if}}
				</a>
			{{/if}}

			<p class="form-view__file-name--fallback">{{ name }}</p>
		</div>
		<input
			class="form-view__file-input--fallback"
			type="file"
			name="file"
			multiple="false"
			data-url="{{ uploadUrl }}"
		/>

	`),
	modelEvents: {
		change: 'render'
	},
	model: fallbackViewModel,

	initialize () {
		this.model.clear();
		!this.options.fileModel.isNew() && this.model.set(this.options.fileModel.toJSON());
		this.model.set('uploadUrl', this.options.uploadUrl);
		this.model.determineType();
	},

	onRender () {
		$(this.$('.form-view__file-input--fallback')).fileupload({
			dataType: 'json',
			formData () {
				// do not attach other fields to data which is being sent
				return [];
			},
			add: (e, data) => {
				const maxFilesize = get(backendConfig, 'attachment.max_file_size', 2000000);
				const filesize = data.files[0].size;

				if (filesize && filesize >= maxFilesize) {
					cwalert.error(t(
						'File is too big ({filesize}MiB) Max filesize {maxFilesize}MiB',
						{
							filesize: Math.round(filesize / 10000) / 100,
							maxFilesize: Math.round(maxFilesize / 10000) / 100
						}
					));
					return;
				}
				data.submit();
			},
			progress: () => {
				this.$el.find('.form-view__file-preview--fallback').html(t('Uploading a file…'));
			},
			done: (e, data) => {
				this.model.determineType(data.result);
				this.$el.find('.form-view__file-preview--fallback').html(data.files[0].name);
			}
		});
	}
});

