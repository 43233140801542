import repository from 'repository';
import AbuseReportStatus from 'repo/circles/abuse-report-status';
import { push } from './helpers';
import { assign, find } from 'lodash';

export default {
	namespaced: true,

	state: {
		collection: {},
		current: []
	},

	getters: {
		defaultStatus: (state) => find(state.current, { isDefault: true })
	},

	mutations: {
		set: (state, { collection, statuses }) => {
			state.current = statuses;
			state.collection = collection;
		},

		add: (state, { statuses, models }) => {
			state.collection.add(models);
			push({ from: statuses, to: state.current });
		},

		updateStatus: (state, { status }) => {
			assign(find(state.current, { id: status.id }), status);
		}
	},

	actions: {
		init: ({ commit }) => {
			const collection = repository.circleAbuseReportStatuses();

			return collection.then(() => {
				commit('set', { collection, statuses: collection.toJSON() });
			});
		},

		create: ({ commit }, { status }) => {
			const newStatus = new AbuseReportStatus(status);

			return newStatus.save().then(() => {
				commit('add', { statuses: [newStatus.toJSON()], models: [newStatus] })
			});
		},

		update: ({ commit }, { status }) => {
			commit('updateStatus', { status });

			return new AbuseReportStatus(status).save();
		},

		setDefault: ({ commit, getters }, { status }) => {
			commit('updateStatus', {
				status: assign(getters.defaultStatus, { isDefault: false })
			});

			return new AbuseReportStatus(assign(status, { isDefault: true })).save();
		}
	}
};
