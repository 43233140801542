var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.token
      ? _c(
          "a",
          { staticClass: "flat-button__button", attrs: { href: _vm.url } },
          [_vm._v("\n\t\t" + _vm._s(_vm.buttonLabel) + "\n\t")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.error
      ? _c("p", [_vm._v("\n\t\t" + _vm._s(_vm.errorMessage) + "\n\t")])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }