import MultipleEntities from '../shared/multiple-entities';
import DiaryEntry from './diary-entry';
import urls from './urls';
import moment from 'moment';

const DiaryEntries = MultipleEntities.extend({
	model: DiaryEntry,
	urls,
	eventNamespace: 'diary-entry',

	getAll () {
		return this.retrieve('diaryEntries');
	},

	comparator: (a, b) => {
		const aDate = moment(a.get('createdAt'), 'YYYY-MM-DD HH:mm:ss').toDate();
		const bDate = moment(b.get('createdAt'), 'YYYY-MM-DD HH:mm:ss').toDate();

		return aDate > bDate ? -1 :
			aDate < bDate ? 1 :
				0;
	}
});

export default new DiaryEntries();

