import TileView from 'tile-view';
import t from 'translate';
import repository from 'repository';

export default TileView.extend({
	title: t('Random page by category'),
	acl: [],

	tileData: ({ tile }) => {
		tile.tileConfig = tile.config() || {};
		return { randomPage: repository.Pages.getRandom(tile.tileConfig.pageCategory) };
	},

	loaded: ({ data, tile }) => {
		tile.$el.append(data.randomPage.models[0].get('content'));
	}
});
