import $, { parseJSON } from 'jquery';
import assessmentConfig from './assessment-config';
import clinicianConfig from './clinician-config';
import planeditorConfig from './planeditor-config';
import respondentConfig from './respondent-config';
import atomicConfig from './atomic-config';
import { error } from 'service/log/log';
import { includes } from 'lodash';
import { CSRF_HEADER, csrfToken } from 'service/csrf';

const unauthorised = ({ appConfig, responseText }) => {
	const currentHref = window.location.href;
	let redirectLocation = parseJSON(responseText).redirect.location;

	// replace start or home card if defaultCardAfterLogin is enabled
	if (
		!!appConfig.config().defaultCardAfterLogin &&
		/23(start|home)$/.test(redirectLocation)
	) {
		const base = redirectLocation.substr(0, redirectLocation.indexOf('%23'));
		const afterLogin = appConfig.config().defaultCardAfterLogin;
		redirectLocation = `${base}%23${afterLogin}`;
	}

	if (!includes(redirectLocation, 'ReturnTo')) {
		redirectLocation += `?ReturnTo=${encodeURIComponent(currentHref)}`;
	}

	// add redirect location provided by 🚽ackend directly to the base url if it begins with `/`
	const rootUrl = redirectLocation[0] === '/' ?
		appConfig.config().baseUrl :
		appConfig.config().authUrl;

	window.location = rootUrl + redirectLocation;
};

const badRequest = ({ responseText }) => {
	error('backend-exception', parseJSON(responseText));
};

let appConfig = {};

export const handleError = (jqXhr) => {
	if (jqXhr.status === 401) {
		unauthorised({ appConfig, responseText: jqXhr.responseText });

	} else if (jqXhr.status === 400) {
		badRequest(jqXhr);
	}
};

export default ({ type }) => {
	appConfig = {
		assessment: assessmentConfig,
		atomic: atomicConfig,
		clinician: clinicianConfig,
		planeditor: planeditorConfig,
		respondent: respondentConfig
	}[type];

	$.ajaxSetup({
		headers: {
			'x-mobile-gui': 1,
			'x-mobile-url': window.location.href
		},
		xhrFields: {
			withCredentials: true
		},
		ifModified: true,

		error: handleError,

		beforeSend: (xhr) => {
			xhr.setRequestHeader(CSRF_HEADER, csrfToken());
		}
	});

	$.support.cors = true;
};

