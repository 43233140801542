<template>
	<div class="mini-chart-tile">
		<span class="mini-chart-tile__title" :title="title">{{title}}</span>
		<plot-chart
			:params="params"
			:options="options"
		/>
	</div>
</template>

<script>
import Chartist from 'chartist';
import { assign, constant, filter, get, map } from 'lodash';
import { mapGetters } from 'vuex';
import { calculateTicks, dateType } from '../assessment-value-plot-chart/helpers';
import { EMPTY_CHAR } from 'lib/chars';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints.json';

export default {
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.ASSESSMENTPLANS,
		op: READ
	}, {
		checkpoint: CLINICIAN.RESPONDENTS.ASSESSMENTCONTENT,
		op: READ
	}],
	actions: (tile) => [[
		'assessmentValuePlotSeries/getValues',
		assign({}, tile.config(), { gaps: false }),
		{},
		{ instant: true }
	]],
	data: ({ tile }) => ({
		config: tile.config()
	}),
	computed: {
		...mapGetters('assessmentValuePlotSeries', ['valuesByRef']),
		date: ({ config }) => dateType(config.dataSources[0].seriesCategory),
		title: ({ config }) => config.title || EMPTY_CHAR,
		series: ({ config, date, valuesByRef }) => map(
			config.series,
			(ref) => map(
				get(valuesByRef[ref], 'values'),
				(item) => ({
					x: date.parse(item.label),
					y: item.value,
					meta: date.tooltipFormat
				})
			)
		),
		params: ({ config, series }) => ({
			dataColors: config.dataColors,
			series
		}),
		// calculate min/max dates to provide this data to chart's options
		// while axisX's type is 'FixedScaleAxis' - it needs a precise ticks list
		ticks: ({ series, date }) => calculateTicks(series, date.type),
		options: ({ ticks }) => ({
			height: '100px',
			axisX: {
				position: 'end',
				type: Chartist.FixedScaleAxis,
				low: +ticks.minTime,
				high: +ticks.maxTime,
				labelInterpolationFnc: constant('')
			}
		})
	},
	created () {
		// eslint-disable-next-line lodash/prop-shorthand
		const checkValues = filter(this.series, (line) => line.length);
		!checkValues.length && !this.config.showIfEmpty && this.tile.disable();
	}
};
</script>
