import { cloneDeep } from 'lodash';

export const initialGlob = {
	expressions: {},
	guiAttrs: {},
	renderers: {},
	roleSummaryView: null
};

export default cloneDeep(initialGlob);
