import MultipleEntities from '../shared/multiple-entities';
import Flag from './flag';
import urls from './urls';

/**
 * Flags Entity
 * @class Flags
 * @extends MultipleEntities
 */
const FlagsEntity = MultipleEntities.extend({
	model: Flag,
	urls: urls,
	eventNamespace: 'flag',
	order: function (item) {
		return item.get('isRaised') ? -1 : 1;
	},

	getList: function (params) {
		return this.retrieve('list', params);
	},

	getByAssessmentInstanceId: function (assessmentInstanceId) {
		return this.retrieve('byAssessmentInstanceId', assessmentInstanceId);
	},

	getByRespondentId: function (respondentId) {
		return this.retrieve('byRespondentId', respondentId);
	},

	getByTreatmentId: function (treatmentId) {
		const flags = this.retrieve('byTreatmentId', treatmentId);
		flags.canAdd = function (model) {
			return +model.get('treatment') === +this.ownerId;
		};
		return flags;
	}
});

export default new FlagsEntity();
