import { CollectionView } from 'marionette';
import AppBarItemView from './AppBarItemView';
import appbarLayout from './appbar-layout';

export default CollectionView.extend({
	childView: AppBarItemView,
	tagName: 'ul',
	className: 'appbar-elements__list',
	initialize ({ type }) {
		this.type = type;
		this.$el.attr('role', 'presentation');
	},

	collectionEvents: {
		add: 'itemsAddedOrRemoved',
		remove: 'itemsAddedOrRemoved'
	},

	itemsAddedOrRemoved (model) {
		if (this.type === 'main') {

			if (this.collection.length === 1) {
				this.firstModel = model;
				this.firstModel.set('preventHide', true);
			}

			if (this.collection.length === 2) {
				this.firstModel.set('preventHide', false);
			}
		}

		if (this.type === 'outer') {
			const $region = appbarLayout().getRegion('outerContainer').$el;
			$region.toggleClass('has-children', !!this.collection.length);
		}

		this.setAriaHidden();
	},

	onShow () {
		this.setAriaHidden();
	},

	setAriaHidden () {
		const $contextContainer = appbarLayout().getRegion('contextContainer').$el;
		const $outerContainer = appbarLayout().getRegion('outerContainer').$el;
		this.type === 'main' &&
			$contextContainer &&
			$contextContainer.attr('aria-hidden', `${!this.collection.length}`);
		this.type === 'outer' &&
			$outerContainer &&
		$outerContainer.attr('aria-hidden', `${!this.collection.length}`);
	},

	attachHtml (collectionView, itemView, index) {
		if (index === 0) {
			return collectionView.$el.prepend(itemView.el);
		}

		const childAtIndex = collectionView.$el.children().eq(index);

		if (childAtIndex.length) {
			return childAtIndex.before(itemView.el);
		}

		return collectionView.$el.append(itemView.el);

	}
});

