/* eslint-disable complexity, max-statements */
import { assign, defer, forEach, includes, isArray, isObject, isString, keys } from 'lodash';
import $ from 'jquery';
import { Collection, Model } from 'backbone';
import { compile } from 'handlebars';
import FormComponentView from '../form-component-view';
import OptionView from './option';
import executeIfFunction from 'execute-if-function';

const select = FormComponentView.extend({
	template: compile(`
		<label class="form-view__label-container">
			<p class="form-view__label-text">{{ label }}</p>
			<select
				class="form-view__input form-view__select"
				name="{{ uniqueName }}"
				{{#if required}} required="required" {{/if}}
				{{#if disabled}} disabled="disabled" {{/if}}
				{{#if multiple}} multiple {{/if}}
				{{#if hint}} aria-describedby="{{ name }}-hint"{{/if}}>
			</select>
		</label>
		{{#if hint}}
			<div class="form-view__hint" id="{{ name }}-hint">{{ hint }}</div>
		{{/if}}
	`),
	childView: OptionView,
	childViewContainer: 'select',

	childViewOptions (model) {
		const options = {
			formClass: this.model.get('formClass')
		};

		if (this.collection) {
			let isSelected = false;
			let fieldValue = model.get(this.labelField);

			if (this.model.get('value')) {
				isSelected = String(model.get(this.keyField)) === String(this.model.get('value'));

				if (isArray(this.model.get('value'))) {
					isSelected = includes(this.model.get('value'), model.get(this.keyField));
				}
			}

			if (this.model.get('transformLabel')) {
				fieldValue = executeIfFunction(this.model.get('transformLabel'), fieldValue);
			}
			assign(options, {
				fieldKey: model.get(this.keyField),
				fieldValue,
				selected: isSelected
			});
		}

		return options;
	},

	initialize () {
		const values = this.model.get('values');
		this.keyField = this.model.get('keyField') || 'id';
		this.labelField = this.model.get('labelField') || 'name';

		if (this.model.get('collection')) {
			this.collection = this.model.get('collection');

			this.collectionEvents = {
				request: 'setLoading',
				sync: 'onCollectionSync'
			};
		}
		this.listenTo(this.collection, 'load-start', () => {
			this.setLoading();
		});
		this.listenTo(this.collection, 'load-end', () => {
			this.setLoaded();
		});

		if (!this.collection && isArray(values)) {
			this.collection = new Collection(this.formatValues());
		}

		this.model.set('disabled', this.model.get('disabled') || this.model.get('readonly'));
	},

	onAfterRender () {
		this.setDescription();
		this.setNullLabel();
		const $select = this.ui.select;

		$select.on('change', () => {
			$select.trigger('blur');
		});
		this.determineTheOnlyOption();
	},

	onCollectionSync () {
		this.setDescription();
		this.determineTheOnlyOption();
	},

	determineTheOnlyOption () {
		if (
			this.collection &&
			this.model.get('required') &&
			this.model.get('determineTheOnlyOption') !== false
		) {
			// eslint-disable-next-line lodash/prefer-lodash-method
			const nonEmptyValues = this.collection.filter((model) => !!model.get(this.keyField));

			if (nonEmptyValues.length === 1) {
				this.descriptionView && this.descriptionView.remove();
				const theOnlyOption = nonEmptyValues[0].get(this.keyField);

				// form listeners are attached after components init
				// so need to fire change right after that, not before
				defer(() => {
					this.collection.reset(nonEmptyValues);
					this.model.set('value', theOnlyOption);
				});

				// make sure that with only one option available, the value
				// shouldn't be reset or set to anything else
				this.listenTo(this.model, 'change:value', function (model, value) {
					if (`${value}` !== `${theOnlyOption}`) {
						defer(() => {
							this.model.set('value', theOnlyOption);
						});
					}
				});
			}
		}
	},

	setDescription () {
		if (!this.model.get('description')) {
			return;
		}

		// Add options and dummy model
		const options = {
			formClass: this.model.get('formClass'),
			fieldKey: null,
			fieldValue: this.model.get('description'),
			selected: !this.model.get('value'),
			model: new Model()
		};

		this.descriptionView = new OptionView(options);
		this.ui.select.prepend(this.descriptionView.render().el);

		// IE8…
		setTimeout(() => {
			this.descriptionView.$el.prop('disabled', true);
			this.model.get('value') || this.descriptionView.$el.prop('selected', true);
		}, 100);

		this.listenTo(this.model, 'change:description', () => {
			this.descriptionView.model.set('fieldValue', this.model.get('description'));
		});
	},

	setNullLabel () {
		if (!this.model.get('setNullLabel')) {
			return;
		}

		// Add options and dummy model
		const options = {
			formClass: this.model.get('formClass'),
			fieldKey: null,
			fieldValue: this.model.get('setNullLabel'),
			selected: !this.model.get('value'),
			model: new Model()
		};

		const setNullLabelView = new OptionView(options);
		this.ui.select.prepend(setNullLabelView.render().el);

		// IE8…
		setTimeout(() => {
			this.model.get('value') || setNullLabelView.$el.prop('selected', true);
		}, 100);

		this.listenTo(this.model, 'change:setNullLabel', () => {
			setNullLabelView.model.set('fieldValue', this.model.get('setNullLabel'));
		});
	},

	// Convert some string values like 'false' to their expression counterparts
	sanitize: (value) => {
		if (includes(['null', 'false', 'true'], value)) {
			return JSON.parse(value);
		}

		return value;

	},

	// Convert some expressions back to strings
	unsanitize: (value) => {
		if (includes([null, false, true], value)) {
			return `${value}`;
		}

		return value;
	},

	formatValues () {
		const values = [];

		forEach(this.model.get('values'), (value) => {
			if (isString(value)) {
				values.push({
					fieldKey: value,
					fieldValue: value,
					/* @TODO until b💩ckend returns the same data in POST/PUT/GET we have to deal
					 * with the situation, so type casting to string will save us
					 */
					selected: `${value}` === `${this.model.get('value')}`
				});

			} else if (isObject(value)) {
				const { id, name } = value;

				const fieldKey = value[this.model.get('keyField')] || id || keys(value)[0];
				const fieldValue = value[this.model.get('labelField')] || name || value[fieldKey];

				values.push({
					fieldKey,
					fieldValue,
					/* @TODO until b💩ckend returns the same data in POST/PUT/GET we have to deal
					 * with the situation, so type casting to string will save us
					 */
					selected: `${fieldKey}` === `${this.model.get('value')}`
				});
			}
		});
		return values;
	},

	disabledChanged (model, disabled) {
		if (this.ui.select instanceof $) {
			this.ui.select.prop('disabled', disabled);
			this.toggleDisabledClass(disabled);
		}
	},

	readonlyChanged (model, readonly) {
		this.ui.select.prop('disabled', readonly);
	},

	getLabel () {
		// eslint-disable-next-line lodash/prefer-lodash-method
		return this.ui.select.find('option:selected').html();
	},

	setReadOnly (bool) {
		this.ui.select.toggleClass('form-view__select--readonly', bool);
	}
});

export { select };
