import repository from 'repository';
import { isEmpty, map } from 'lodash';
import {
	cleanClinician, cleanClinicianAssignedGroup, cleanClinicianAssignedRole
} from '../__helpers/clean';
import { remove } from '../__helpers/mutations';
import { toUtc } from 'service/datetime/datetime';

export default {
	namespaced: true,

	state: {
		clinician: {},
		assignedGroups: [],
		assignedRoles: []
	},

	getters: {
		clinician: (state) => cleanClinician(state.clinician),
		assignedGroups: (state) => state.assignedGroups,
		assignedRoles: (state) => state.assignedRoles
	},

	mutations: {
		clinician: (state, payload) => {
			state.clinician = payload;
		},

		initAssignedGroups: (state, { assigned }) => {
			state.assignedGroups = map(assigned, cleanClinicianAssignedGroup);
		},

		assignGroup: (state, { group }) => {
			state.assignedGroups.push(cleanClinicianAssignedGroup(group));
		},

		removeAssignedGroup: remove('assignedGroups'),

		initAssignedRoles: (state, { assigned }) => {
			state.assignedRoles = map(assigned, cleanClinicianAssignedRole);
		},

		assignRole: (state, { role }) => {
			state.assignedRoles.push(cleanClinicianAssignedRole(role));
		},

		removeAssignedRole: remove('assignedRoles')
	},

	actions: {
		init: ({ commit }, id) => {
			const clinician = repository.clinician(id);
			return clinician.then(() => {
				commit('clinician', clinician.toJSON());
			});
		},

		initAssignedGroups: ({ commit }, { clinicianId }) =>
			repository.clinicianAssignedGroups({
				clinicianId
			}).then((assigned) => {
				commit('initAssignedGroups', { assigned });
			}),

		initAssignedRoles: ({ commit }, { clinicianId }) =>
			repository.clinicianAssignedRoles({
				clinicianId
			}).then((assigned) => {
				commit('initAssignedRoles', { assigned });
			}),

		assignGroup: async (
			{ commit, state: { clinician } },
			{ data: { group, validFrom, validTo }, timezone }
		) => {
			const convertToUtc = (date) => toUtc({ date, timezone });
			const from = !isEmpty(validFrom) ? convertToUtc(validFrom) : null;
			const to = !isEmpty(validTo) ? convertToUtc(validTo) : null;

			const groupAssignment = await repository.assignClinicianGroup({
				clinicianId: clinician.administratorId,
				clinicianGroupId: group.id,
				timeRestriction: {
					validFrom: from,
					validTo: to
				}
			});
			commit('assignGroup', { group: groupAssignment });
		},

		assignRole: async (
			{ commit, state: { clinician } },
			{ data: { role, validFrom, validTo }, timezone }
		) => {
			const convertToUtc = (date) => toUtc({ date, timezone });
			const from = !isEmpty(validFrom) ? convertToUtc(validFrom) : null;
			const to = !isEmpty(validTo) ? convertToUtc(validTo) : null;

			const roleAssignment = await repository.assignClinicianRole({
				clinicianId: clinician.administratorId,
				clinicianRoleId: role.id,
				timeRestriction: {
					validFrom: from,
					validTo: to
				}
			});
			commit('assignRole', { role: roleAssignment });
		},

		removeAssignedGroup: ({ commit, state: { clinician }  }, { group, index }) =>
			repository.removeClinicianGroup({
				clinicianId: clinician.administratorId,
				clinicianGroupId: group.id,
				timeRestriction: {
					validFrom: group.validFrom,
					validTo: group.validTo
				}
			}).then(() => {
				commit('removeAssignedGroup', { index });
			}),

		removeAssignedRole: ({ commit, state: { clinician }  }, { role, index }) =>
			repository.removeClinicianRole({
				clinicianId: clinician.administratorId,
				clinicianRoleId: role.id,
				timeRestriction: {
					validFrom: role.validFrom,
					validTo: role.validTo
				}
			}).then(() => {
				commit('removeAssignedRole', { index });
			})
	}
};
