import BaseComponent from '../base-component';
import moment from 'moment';
import t from 'translate';
import { get, size } from 'lodash';
import Datetime from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import Vue from 'vue';

Vue.use(Datetime);

const nullify = (query) => query.length ? query : null;

const DATETIME_LOCAL = 'datetime-local';

export default (variant) => BaseComponent.extend({
	template: `
		<div
			class="filter__container"
			:class="containerClassNames"
		>
			<button
				tabindex="0"
				class="filter__toggle"
				@click.prevent="toggleFilters({ label: label, toggle: !toggled })"
				@keyup.40="onArrowDown"
				:class="{
					'filter__toggle--active': toggled,
					'filter__toggle--dormant': !toggled,
					'filter__toggle--sibling-active': anyToggled
				}"
				:ref="selfName"
			>
				{{ label }}
			</button>

			<ul
				class="filter__list"
				:class="{
					'filter__list--active': toggled,
					'filter__list--dormant': !toggled
				}">
				<li
					class="filter__list-item"
					:class="{
						'filter__list-item--active': query.length,
						'filter__list-item--dormant': !query.length
					}"
				>
					<datetime
						v-if="datetimePolyfill"
						v-model="query"
						type="datetime"
						input-class="filter__input filter__input--datetime-local"
						@keyup.38="onArrowUp"
					/>

					<input
						v-else
						:class="'filter__input filter__input--' + variant"
						:type="variant"
						tabindex="0"
						:value="query"
						@change="setValue"
						@keyup.enter="setValue"
						@keyup.27="onEscape"
						@keyup.38="onArrowUp"
						ref="input"
					/>

					<div class="filter__remove">
						<span class="filter__remove-caption">{{ readableValue }}</span>
						<button
							tabindex="0"
							class="filter__remove-action"
							:title="title.removeFilter"
							@click="removeFilter"
						></button>
					</div>

				</li>
			</ul>
			<p v-if="invalid" class="filter__validity">
				{{ validity.message }}
			</p>
		</div>
	`,

	data: () => ({
		cachedValue: '',
		variant
	}),

	mounted () {
		if (size(this.viewValue)) {
			this.onChange(this.query);
		}
	},

	methods: {
		onArrowDown () {
			if (this.focused === -1) {
				this.focused++;
				this.setFocus('input', true);
			}
		},

		onArrowUp () {
			if (this.focused === 0) {
				this.focused = -1;
				this.setFocus(this.selfName, true);
			}
		},

		setValue (e) {
			this.validate(e);
			this.setValues({ query: e.target.value });
			this.onChange(nullify(e.target.value));
		},

		removeFilter () {
			this.setValues({ query: '' });
			this.onChange(null);
		},

		cancel () {
			this.onChange(this.query);
		}
	},

	computed: {
		datetimePolyfill () {
			return this.variant === DATETIME_LOCAL && !Modernizr.inputtypes[DATETIME_LOCAL];
		},

		readableValue () {
			if (this.variant !== DATETIME_LOCAL) {
				return this.query;
			}

			return moment(this.query).format(t('date.formats.dateTime'));
		},

		containerClassNames () {
			return {
				'filter__container--active': this.anySelected,
				'filter__container--dormant': !this.anySelected,
				'filter__container--text': this.variant === 'text',
				'filter__container--datetime-local': this.variant === DATETIME_LOCAL,
				'filter__container--invalid': this.invalid
			};
		},

		query: {
			get () {
				return get(this.viewValue, 'query', '');
			},
			set (value) {
				this.setValues({ query: value });
				this.onChange(nullify(value));
			}
		}
	}
});
