import cardUrl from 'cwcardurl';
import store from 'store';
import {
	RESPONDENT_CONTENT_PACKAGE_ELEMENT
} from 'store/content-packages/rp-content-package-element';
import { RESPONDENT_PAGES } from 'store/pages/rp-pages';

const card = {
	name: 'information-package-element',
	url: 'treatment/:treatmentId/information-package-element/:informationPackage/:id',
	controller (treatmentId, informationPackage, id) {
		const types = {
			ASSESSMENT (item) {
				// @see assessment is marked as in-progress and completed at entity level
				// (appContext events)
				return cardUrl.buildUrl('assessment', {
					id: item.assessmentInstance.id,
					treatmentId
				});
			},
			EXERCISE (item) {
				return cardUrl.buildUrl('exercise', {
					informationPackage,
					id: item.id,
					treatmentId
				});
			},
			PAGE (item) {
				// page when it is opened is always marked as completed
				store.dispatch(
					RESPONDENT_PAGES.COMPLETE,
					{
						contentPackageId: item.instance.id,
						elementId: item.id
					}
				);
				return cardUrl.buildUrl('page', {
					id: item.page.id,
					treatmentId
				});
			}
		};

		const element = store.dispatch(RESPONDENT_CONTENT_PACKAGE_ELEMENT.INIT, {
			contentPackageId: informationPackage,
			elementId: id
		});

		element.then(() => {
			const contentPackageElement = store.getters[RESPONDENT_CONTENT_PACKAGE_ELEMENT.DATA];
			const redirectTo = types[contentPackageElement.type](contentPackageElement);
			this.cardContext().trigger('card:close', {
				go: redirectTo
			});
		});

	}
};

export { card as informationPackageElements };
