import store from 'store';
import { ACTIVITY } from 'store/goals/activity';
import cwalert from 'cwalert';
import t from 'translate';
import confirmation from 'components/confirmation/confirmation';
import cardUrl from 'cwcardurl';
import { ACTIVITIES } from 'store/goals/activities';
import { TREATMENT } from 'store/treatments/rp-treatment';

export const toggleGoalComplete = ({ card, goal = store.getters['goal/item'], done }) => ({
	sourceCard: card.name,
	icon: 'achieved',
	title: goal.isCompleted ?
		t('goals.markNotAchieved') :
		t('goals.markAchieved'),
	click: () => {
		goal.isCompleted = !goal.isCompleted;

		store.dispatch('goal/update', goal).then(() => {
			const msg = goal.isCompleted ?
				t('goals.achieveSuccess') :
				t('goals.reopenSuccess');

			cwalert.success(msg);
			done();
		});
	}
});

export const editGoal = ({ card, goalId, respondentId }) => ({
	sourceCard: card.name,
	icon: 'edit',
	title: t('goals.editGoalTitle'),
	click: () => {
		const params = { goalId };

		if (respondentId) {
			params.respondentId = respondentId;
		}

		if (store.getters['userType'] === 'respondent') {
			params.treatmentId = store.getters[TREATMENT.ID];
		}

		cardUrl.openCard('edit-goal', params);
	}
});

export const removeGoal = ({ card, goal = store.getters['goal/item'], done }) => ({
	sourceCard: card.name,
	icon: 'delete',
	title: t('goals.removeTitle'),
	click: () => {
		confirmation({
			icon: 'delete',
			warning: true,
			title: t('Delete goal'),
			message: t('goals.removeQuestion')
		}).done(() => {
			store.dispatch('goals/remove', goal).then(() => {
				cwalert.success(t('goals.removeSuccess'));
				store.dispatch(ACTIVITIES.INIT).then(() => {
					done();
				});
			});
		});
	}
});

export const toggleActivityComplete = (
	{ card, activity = store.getters[ACTIVITY.ITEM], done }
) => ({
	sourceCard: card.name,
	icon: 'achieved',
	title: activity.isCompleted ?
		t('goals.markActivityNotCompleted') :
		t('goals.markActivityCompleted'),
	click: () => {

		activity.isCompleted = !activity.isCompleted;
		store.dispatch(ACTIVITY.UPDATE, activity).then(() => {
			const msg = activity.isCompleted ?
				t('goals.achieveActivitySuccess') :
				t('goals.reopenActivitySuccess');

			cwalert.success(msg);
			done();
		});
	}
});

export const removeActivity = (
	{ card, goalId, activity = store.getters[ACTIVITY.ITEM], done }
) => ({
	sourceCard: card.name,
	icon: 'delete',
	title: t('goals.deleteActivity'),
	click: () => {
		confirmation({
			icon: 'delete',
			warning: true,
			title: 'Delete activity',
			message: t('goals.removeActivity')
		}).done(() => {
			const data = {
				id: activity.id,
				goalId,
				respondentId: activity.respondent
			};
			store.dispatch(
				'activities/remove',
				{ activity: data, inGoal: true }
			).then(() => {
				cwalert.success(t('goals.removeActivitySuccess'));
				done();
			});
		});
	}
});
