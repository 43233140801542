import t from 'translate';
import TileView from 'tile-view';
import store from 'store';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import formView from 'components/form-view/form-view';
import QRious from 'qrious';
import { compact, get, replace, split } from 'lodash';
import { Collection } from 'backbone';
import can from 'acl-can';
import { SYSTEM } from 'service/acl/checkpoints';

const urlRoot = (suffix = '') => {
	const currentUrl = replace(window.location.href, window.location.hash, '');
	const root = compact(split(currentUrl, '/'));
	root.pop();
	root[0] += '/';

	return `${root.join('/')}/${suffix}`;
};

export default TileView.extend({
	title: t('App connection details'),
	acl: [],
	instant: true,

	loaded: ({ tile }) => {
		const model = store.state.apps.currentAppModel;

		tile.vm = new Vue({
			el: document.createElement('div'),
			store,
			computed: {
				...mapGetters('apps', ['downloadUrls'])
			},
			template: `
				<ul class="app-download-container__list">
					<li v-if="downloadUrls.storeAndroidUrl">
						<a
							class="app-download-container__link"
							:href="downloadUrls.storeAndroidUrl"
						>
							<img
								class="app-download-container__image"
								src="../../../../../images/badges/google-play-badge.png"
								title="Google Play"
							/>
						</a>
					</li>
					<li v-if="downloadUrls.storeIosUrl">
						<a class="app-download-container__link" :href="downloadUrls.storeIosUrl">
							<img
								class="app-download-container__image"
								src="../../../../../images/badges/apple-store-badge.png"
								title="Apple Store"
							/>
						</a>
					</li>
					<li v-if="downloadUrls.storeWindowsUrl">
						<a class="app-download-container__link" :href="downloadUrls.storeIosUrl">
							<img
								class="app-download-container__image"
								src="../../../../../images/badges/microsoft-badge.png"
								title="Microsoft"
							/>
						</a>
					</li>
				</ul>
			`
		});
		const role = get(store.state, 'apps.currentApp.role');

		tile.form = formView({
			model,
			keysNotSubmitted: ['connectionQrCode'],
			fields: [{
				label: t('Connected App'),
				key: 'name',
				type: 'paragraph',
				value: model.get('activeApp').name
			}, store.getters['userType'] === 'clinician' && {
				key: 'treatmentTypes',
				keyField: 'id',
				label: t('Treatment'),
				collection: new Collection(model.get('treatment')),
				type: 'select',
				disabled: !can.edit(SYSTEM.SETTINGS.SETTINGS)
			}, {
				label: t('Connection URL'),
				key: 'websiteUrl',
				type: 'paragraph',
				value: get(store.state, 'user.backend-config.clientApiUrl')
			}, {
				label: t('Connection token'),
				key: 'connectionToken',
				type: 'paragraph'
			}, {
				label: t('Connection QR code'),
				key: 'connectionQrCode',
				type: 'paragraph',
				customize: (view) => {
					// eslint-disable-next-line lodash/prefer-lodash-method
					tile.generateQrCode(view.$el.find('.form-view__paragraph-value'));
				}
			}, {
				label: t('Role'),
				key: 'role',
				type: 'paragraph',
				value: role ? role.name : t('No specific role')
			}],
			saveIndicator: true
		});

		tile.$el.append(tile.form.$el);
		tile.$el.append(tile.vm.$el);
	},

	generateQrCode ($el) {
		const canvas = document.createElement('canvas');
		const qrValue = {
			api: get(store.state, 'user.backend-config.clientApiUrl'),
			token: get(store.state, 'apps.currentApp.connectionToken'),
			respondentPortal: urlRoot('respondent'),
			assessmentPortal: urlRoot('assessment'),
			clinicianPortal: urlRoot('clinician')
		};

		$el.append(canvas);

		this.qr = new QRious({
			element: canvas,
			value: JSON.stringify(qrValue),
			size: 200
		});
	}
});
