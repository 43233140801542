var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("time", { attrs: { datetime: _vm.isoString } }, [
    _vm._v("\n\t" + _vm._s(_vm.content) + "\n")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }