import FormComponentView from '../form-component-view';
import { compile } from 'handlebars';
import { assign } from 'lodash';

const text = FormComponentView.extend({
	template: compile(`
		<label class="form-view__label-container">
			{{#if label}}
				<p class="form-view__label-text">{{ label }}</p>
			{{/if}}
			<input
				type="text"
				class="form-view__input form-view__text"
				name="{{ uniqueName }}"
				id="{{ uniqueName }}"
				{{#if title}} title="{{ title }}" {{/if}}
				{{#if value}} value="{{ value }}" {{/if}}
				{{#if required}} required="required" {{/if}}
				{{#if readonly}} readonly="readonly" {{/if}}
				{{#if disabled}} disabled="disabled" {{/if}}
				{{#if hint}} aria-describedby="{{ name }}-hint"{{/if}}
				{{#if min}} minlength="{{ min }}" {{/if}}
				{{#if max}} maxlength="{{ max }}" {{/if}}
				{{#if hint}} aria-describedby="{{ name }}-hint" {{/if}}
				{{#if noAutocomplete}} autocomplete="off" {{/if}}
				{{#if placeholder }} placeholder="{{ placeholder }}"{{/if}} />
		</label>
		{{#if hint}}
			<div class="form-view__hint" id="{{ name }}-hint">{{ hint }}</div>
		{{/if}}
	`),

	events () {
		return assign({}, FormComponentView.prototype.events, {
			'keyup': 'determineChange'
		});
	},

	determineChange () {
		this.trigger('formChange', this);
	},

	getFormValue () {
		return this.ui.input.val();
	}
});

export { text };

