import Vue from 'vue';
import {
	addChild, emptyNode, insertElements, mirrorList, mirrorPaths, mirrorsLength, node, parentPaths,
	removeChildren, removeLastPathElement, setChildrenMirrors, targetPath,
	traverse, updateList, updateMirrors, updateWarnings, validateFields, warning
} from './helpers';
import { set } from '../../__helpers/mutations.es6';
import {
	assign, cloneDeepWith, filter, find, findIndex, forEach, has, get, includes, isObject, map,
	merge, reduce, reject, sortBy, split, toLower, transform, uniq, unset, without
} from 'lodash';

const mirrorParentContext = (path, elements) => {
	const parentPathsList = parentPaths(path);

	if (parentPathsList.length) {
		const parent = get(elements, parentPathsList[0]);
		return parent.fields.contextLabel;
	}
	// return parent's context
	return elements.fields.contextLabel;
};

export default () => ({
	namespaced: true,
	state: {
		copy: [],
		copyPath: null,
		currentNode: null,
		enabledMirror: false,
		expanded: [],
		handleParentsCheck: false,
		handleParentsUncheck: false,
		highlightedObservable: Vue.observable({ highlighted: false }),
		activePath: '',
		localState: {},
		matched: 0,
		modifiedNotSaved: false,
		observable: null,
		parent: null,
		phrase: '',
		position: 0,
		selected: [],
		editItem: null,
		types: {},
		update: {},
		unavailableElements: [],
		warningElements: []
	},
	getters: {
		// rename it to `checkedPaths` or something
		checkedItems: (state) => {
			const checked = [];
			const pushChildren = (children) => {
				forEach(children, (child) => {
					checked.push(child.path);

					if (child.children) {
						pushChildren(child.children);
					}
				});
			};
			forEach(state.selected, (selected) => {
				checked.push(selected);
				const element = node({ parent: state.localState, path: selected }).element;

				if (get(element, 'children')) {
					pushChildren(element.children);
				}
			});
			return uniq(checked);
		},

		// rename it to `checkedItems` or something
		checked: (state, getters) => map(
			getters.checkedItems,
			(path) => node({ parent: state.localState, path })
		),

		checkedTopItems: (state, getters) => reject(
			getters.checked,
			(item) => find(getters.checked, ['element.path', item.parent.path])
		),

		checkedParents: (state, getters) => filter(
			getters.checked, ['element.type', 'folder']
		),

		checkedTopParents: (state, getters) => reject(
			getters.checkedParents,
			(item) => find(getters.checkedParents, ['element.path', item.parent.path])
		),

		singleParentOfChecked: (state, getters) => {
			if (!getters.checked.length) {
				return false;
			}

			let parent = getters.checked[0].parent;
			const parentPath = parent.path;

			// eslint-disable-next-line lodash/prefer-filter
			forEach(getters.checkedTopItems, (item) => {
				if (item.parent.path !== parentPath) {
					parent = false;
				}
				return parent;
			});

			return parent;
		},

		indeterminate: (state) => {
			if (!state.handleParentsCheck) {
				return [];
			}
			let list = [];
			forEach(state.selected, (selected) => {
				const parents = parentPaths(selected);

				if (parents.length) {
					list = [...list, ...parents];
				}
			});
			return list;
		},
		copy: (state) => state.copy,
		copyPath: (state) => state.copyPath,
		editItem: (state) => state.editItem,
		enabledMirror: (state) => state.enabledMirror,
		incompletePaths: (state) => {
			const checkIncomplete = (list, iteratee = []) => reduce(list, (result, child) => {
				if (child.type !== 'folder' && !child.fields.identity) {
					result.push(child.path);
				}

				if (child.children) {
					checkIncomplete(child.children, result);
				}
				return result;
			}, iteratee);
			return checkIncomplete(state.localState.children);
		},
		localItems: (state) => state.localState,
		matched: (state) => state.matched,
		mirrorDetails: (state, getters) =>
			transform(getters.mirrors, (result, child) => {
				let currentMirror = 0;
				let suffix = 1;
				const setSuffix = (children, element) => {
					forEach(children, (child) => {
						if (child.mirror === element.mirror) {
							currentMirror++;
						}

						if (child.path === element.path) {
							suffix = currentMirror;
							return false;
						} else if (child.children) {
							setSuffix(child.children, element);
						}
						return true;
					});
					return suffix;
				};
				const nodeObj = node({ parent: state.localState, path: child });
				const origin = node({ parent: state.localState, path: nodeObj.element.mirror });

				result[child] = {
					name: origin.element.fields.name,
					suffix: setSuffix(state.localState.children, nodeObj.element)
				};

				if (origin.element.mirror) {
					currentMirror = 0;
					suffix = 1;
					result[child].mirrorSuffix = setSuffix(origin.parent.children, origin.element);
				}
			}, {}),
		mirrors: (state) => mirrorPaths({ elements: state.localState }),
		modifiedNotSaved: (state) => state.modifiedNotSaved,
		parentContext: (state) => state.parent && state.parent.contextLabel,
		parentContextId: (state) => state.parent && state.parent.assessmentContext,
		position: (state) => state.position,
		rootMirrors: (state) => mirrorPaths({ elements: state.localState, root: true }),
		saveAllowed: (state, getters) => {
			if (!state.localState.children.length) {
				return true;
			}
			const mandatoryFields = validateFields({
				elements: state.localState.children,
				entry: true,
				types: state.types
			});
			return mandatoryFields && !getters.invalidItems.length;
		},
		selected: (state) => state.selected,
		invalidItems: (state, getters) => [
			...state.warningElements,
			...state.unavailableElements,
			...getters.invalidParents
		],
		invalidParents: (state) => reduce([
			...state.warningElements,
			...state.unavailableElements
		], (result, warningElement) => {
			const parents = parentPaths(warningElement);
			const uniquePaths = reduce(parents, (result, singlePath) => {
				if (!find(state.warningElements, (warning) => warning === singlePath)) {
					result.push(singlePath);
				}
				return result;
			}, []);
			return [...result, ...uniquePaths];
		}, []),

		activePath: (state) => state.activePath
	},
	mutations: {
		add: (state, { item, path }) => {
			const addMirror = ({ addData, mirrorElement, mirrorPath }) => {
				const finalPath = `${mirrorElement.path}.children.${mirrorElement.children.length}`;
				const elementData = { mirror: mirrorPath, path: finalPath, warning: true };
				const currentElement = merge({}, addData, elementData);
				currentElement.fields = state.update.fields({
					element: mirrorElement,
					item: currentElement
				});
				mirrorElement.children.push(currentElement);

				if (currentElement.type !== 'folder') {
					currentElement.warning = warning({
						item: currentElement,
						state: state.localState
					});
					state.warningElements = updateWarnings(currentElement, state.warningElements);
				}
			};

			const handleMirrors = (mirrorPath, mirrorData) => {
				const curElement = get(state.localState, mirrorPath);

				if (!curElement.root) {
					const mirrors = mirrorList({
						elements: state.localState.children,
						path: mirrorPath
					});

					if (mirrors.length) {
						const elIndex = curElement.children.length - 1;
						const curPath = `${curElement.path}.children.${elIndex}`;
						forEach(mirrors, (mirror) => {
							const mirrorElement = get(state.localState, mirror);
							addMirror({
								addData: mirrorData,
								mirrorElement,
								mirrorPath: curPath
							});
							handleMirrors(mirror, mirrorData);
						});
					}
				}
			};

			const setElement = (item, parent) => {
				const data = assign(item, state.observable, { path });
				data.fields = state.update.fields({ element: parent, item });

				if (data.type !== 'folder') {
					data.path = (`${path}.children.${parent.children.length}`);
					data.warning = warning({ item: data, state: state.localState });
				}
				return data;
			};

			const element = path !== 'root' ?
				node({ parent: state.localState, path }).element :
				state.localState;
			const newElement = setElement(item, element);
			element.children.push(newElement);
			handleMirrors(path, newElement);
		},

		breakConnection: (state, path) => {
			const removeMirrors = ({ checkPath, children, element = [] }) =>
				transform(children, (result, child) => {
					const unsetMirror = checkPath ? child.path === path : true;

					if (unsetMirror) {
						child.mirror = false;
						unset(child, 'mirrorName');
						unset(child, 'mirrorSuffix');

						if (child.children) {
							child.children = removeMirrors({
								checkPath: false,
								children: child.children
							});
						}
					}
					result.push(child);
				}, element);

			if (node({ parent: state.localState, path }).element.mirror) {
				state.localState.children = removeMirrors({
					checkPath: true,
					children: state.localState.children
				});
			}
			state.editItem = null;
		},

		calculatePaths: (state) => {
			const traverse = (obj, parentPath = 'children.') => {
				forEach(obj.children, (item, index) => {
					item.path = parentPath + index;

					if (item.children) {
						traverse(item, `${item.path}.children.`);
					}
				});
				return obj;
			};
			traverse(state.localState);
		},

		check: (state, path) => {
			const removeSelectedParent = (path) => reduce(parentPaths(path), (result, child) =>
				filter(result, (selectePath) => selectePath !== child), state.selected);
			state.selected.push(path);
			state.selected = removeSelectedParent(path);

			if (state.handleParentsCheck) {
				const allChildrenSelected = (children) =>
					reduce(children, (result, child) =>
						!includes(state.selected, child.path) ? false : result, true);

				forEach(state.selected, (selected) => {
					const parents = parentPaths(selected);
					forEach(parents, (parent) => {
						const parentEl = get(state.localState, parent);
						const allSelected = allChildrenSelected(parentEl.children);

						if (allSelected && !includes(state.selected, parent)) {
							state.selected.push(parent);
							state.selected = removeChildren(parentEl.children, state.selected);
						}
					});
				});
			}
		},

		delete: (state) => {
			const remove = ({ changeIndex, parent }) => {
				parent.children.splice(changeIndex, 1);
				const parentPath = !parent.root ? `${parent.path}.children.` : `children.`;
				parent.children = updateMirrors({ parent, changeIndex, rows: 1 });
				traverse({ parent, path: parentPath });
			};
			const removeWarningElement = (path) => {
				const index = findIndex(state.warningElements, (warning) => warning === path);

				if (index !== -1) {
					state.warningElements.splice(index, 1);
				}
			};
			const updateWarningElements = (element) => {
				if (element.children) {
					forEach(element.children, (child) => {
						removeWarningElement(child.path);
					});
				}
				removeWarningElement(element.path);
			};
			const setFinalList = (list) =>
				reduce(list, (result, singlePath) => {
					const mirrors = mirrorList({
						elements: state.localState.children,
						path: singlePath
					});

					if (mirrors.length) {
						const finalMirrors = [...mirrors, ...setFinalList(mirrors)];
						forEach((finalMirrors), (mirror) => {
							if (!includes(list, mirror)) {
								result.push(mirror);
							}
						});
					}
					return result;
				}, list);
			state.selected = setFinalList(state.selected);
			const sorted = sortBy(state.selected, (single) => {
				const splitted = split(single, '.');
				return +splitted[splitted.length - 1];
			});
			forEach(sorted.reverse(), (selected) => {
				const curNode = node({ parent: state.localState, path: selected });
				const element = curNode.element;

				if (element) {
					const parent = curNode.parent;
					updateWarningElements(element);
					const changeIndex = findIndex(parent.children, { path: element.path });

					if (changeIndex !== -1) {
						remove({ changeIndex, parent });
					}
				}
			});
			traverse({ parent: state.localState });
			state.selected = [];
		},

		emptyCopy: (state) => {
			state.copy = [];
			state.copyPath = null;
		},

		paste: (state) => {
			const getChildren = (el) => el.root ? state.localState : el.parent;
			const setElementList = (list, mirrors) => {
				if (mirrors.length) {
					forEach(mirrors, (mirror) => {
						const element = node({ parent: state.localState, path: mirror }).element;
						list.push(element);
					});
				}
				return list;
			};
			const newValues = { checked: false, mirror: false, warning: true };
			const setPasteItem = (origin, callback) => merge({}, origin, { ...newValues },
				origin.children ? { children: callback({ item: origin.children }) } : {});
			const updatePastedContent = (element) => {
				const executeFunction = ({ item }) => {
					const newEl = merge({}, item, { ...newValues });

					if (newEl.children) {
						newEl.children = map(newEl.children, (el) =>
							setPasteItem(el, executeFunction));
					}
					return newEl;
				};
				return executeFunction({ item: element });
			};

			const target = state.copyPath === 'root' ?
				{ element: state.localState, parent: state.localState } :
				node(emptyNode(state.localState, state.copyPath));
			const elements = map(state.copy, (el) =>
				node(emptyNode(state.localState, el)).element);
			const pasteSelected = (from, target) => {
				const element = target.element;
				const processed = updatePastedContent(from);
				const asChild = element.root || element.mirrorSuffix || element.type !== 'folder';

				if (asChild) {
					target.parent = addChild({
						copyPath: state.copyPath,
						from,
						parent: getChildren(target),
						processed,
						update: state.update.paste
					});
				} else {
					const mirrors = mirrorList({
						elements: element.children,
						path: from.path
					});
					state.localState.children = insertElements({
						children: state.localState.children,
						elementList: setElementList([element], mirrors),
						from,
						parent: getChildren(target),
						processed,
						update: state.update.paste
					});
				}
			};

			forEach(elements, (selectedEl) => {
				pasteSelected(selectedEl, target);
			});
		},

		removeEditItem: (state) => {
			state.editItem = null;
			state.parent = null;
		},

		removeSelectedChildren: (state, path) => {
			const element = node({ parent: state.localState, path }).element;

			if (get(element, 'children')) {
				state.selected = removeChildren(element.children, state.selected);
			}
		},

		search: (state) => {
			state.expanded = [];
			state.matched = 0;
			const updateSearch = (obj) => {
				if (obj.children) {
					forEach(obj.children, (child) => {
						if (includes(toLower(child.fields.name), state.phrase)) {
							state.matched++;
							child.highlighted = true;
							child.position = state.matched;

							if (!find(state.expanded, child.path)) {
								state.expanded.push(child.path);
							}
							forEach(parentPaths(child.path), (el) => {
								if (!find(state.parents, el)) {
									state.expanded.push(el);
								}
							});
						} else {
							child.highlighted = child.position = false;
						}

						if (child.children) {
							updateSearch(child);
						}
					});
				}
			};
			updateSearch(state.localState);
		},

		setCopy: (state, { paths, action }) => {
			state.copy = [];
			forEach(paths, (path) => {
				const item = node({ parent: state.localState, path }).element;

				if (item) {
					state.copy.push(item.path);
				}
			});
			action(state.copy.length);
		},

		setCopyPath: (state, path) => {
			state.copyPath = path;
		},

		setElementsWarning: (state, { item }) => {
			const updateElements = (item, element) =>
				transform(item.children, (result, child) => {
					if (child.type !== 'folder') {
						child.warning = warning({ item: child, state: state.localState });
						const warnings = get(state, 'update.warnings', []);

						if (!child.warning && warnings.length) {
							forEach(warnings, (warningFn) => {
								const curWarning = warningFn(child);
								child.warning = curWarning || child.warning;
							});
						}
						state.warningElements = updateWarnings(child, state.warningElements);
					}

					if (child.children) {
						result.children = updateElements(child, []);
					}
					result.push(child);
				}, element);
			assign(item.children, updateElements(item, []));
		},

		setExpanded: (state) => {
			const updateExpanded = (obj) => {
				if (obj.children) {
					forEach(obj.children, (child) => {
						const childIndex = findIndex(state.expanded, (el) => el === child.path);

						if (!child.expanded) {
							child.expanded = child.root || childIndex !== -1;
						}
						updateExpanded(child);
					});
				}
			};
			updateExpanded(state.localState);
		},

		setInvalidElements: (state, { field, warning }) => {
			const addToWarnings = (children, element) => reduce(children, (result, child) => {
				if (child[warning] && child.type !== 'folder') {
					result.push(child.path);
				}

				if (child.children) {
					return addToWarnings(child.children, result);
				}
				return result;
			}, element);
			state[field] = addToWarnings(state.localState.children, []);
		},

		setItems: (state, { items, observable }) => {
			const customizer = (value) => {
				if (isObject(value)) {
					if (has(value, 'type') && !value.root) {
						assign(value, observable);
					} else if (value.root) {
						Vue.set(value, 'expanded', true);
					}
				}
			};
			state.localState = cloneDeepWith(items, customizer);
		},

		setMatched: (state, matched) => {
			state.matched = matched;
		},

		setMirror: (state, { path }) => {
			let suffix = 0;
			const uncheckChildren = (element, final) => {
				if (!element.children) {
					return null;
				}
				return transform(element.children, (result, child) => {
					result.push(child);
					result.children = child.children ? uncheckChildren(child, []) : null;
					return result;
				}, final);
			};
			const adjustElement = (element) => {
				element.children = uncheckChildren(element, []);
			};
			const updateChildren = ({ mirror, parent, changeIndex }) => {
				parent.children.splice(changeIndex, 0, merge({}, mirror));
				const parentPath = !parent.root ? `${parent.path}.children.` : `children.`;
				traverse({ parent, path: parentPath });
				parent.children = updateMirrors({ parent, changeIndex: changeIndex + 1 });
			};
			const addMirror = ({ element, parent, changeIndex, currentPath }) => {
				if (changeIndex !== -1) {
					const curLength = mirrorsLength(parent, currentPath);
					const finalIndex = curLength ? changeIndex + curLength + 1 : changeIndex + 1;
					suffix = curLength + 1;
					adjustElement(element);
					updateChildren({
						mirror: merge({}, element, {
							children: setChildrenMirrors(element.children),
							mirror: element.path,
							mirrorName: element.fields.name,
							mirrorSuffix: suffix,
							fields: element.fields
						}),
						parent,
						changeIndex: finalIndex
					});
				}
			};

			const setPath = (path) => {
				const splicedPath = split(path, '.');
				splicedPath[splicedPath.length - 1] = +splicedPath[splicedPath.length - 1] + suffix;
				return splicedPath.join('.');
			};

			const mirrorsPaths = filter(mirrorList({
				elements: state.localState.children,
				path
			}), (mirrorPath) => removeLastPathElement(mirrorPath) !== removeLastPathElement(path));
			const curNode = node({ parent: state.localState, path });
			addMirror({
				element: curNode.element,
				parent: curNode.parent,
				changeIndex: findIndex(curNode.parent.children, { path: curNode.element.path }),
				currentPath: path
			});
			forEach(mirrorsPaths, (singleMirror) => {
				const originNode = node({ parent: state.localState, path: setPath(path) });
				const mirrorNode = node({ parent: state.localState, path: singleMirror });
				const origin = merge({}, originNode.element);
				origin.fields.contextLabel = mirrorNode.element.fields.contextLabel;
				const newElement = updateList({
					origin,
					update: state.update,
					parentContext: mirrorParentContext(singleMirror, state.localState)
				});
				const changeMirrorIndex = (findIndex(
					mirrorNode.parent.children,
					{ path: mirrorNode.element.path }
				) + suffix - 1);
				addMirror({
					element: newElement,
					parent: mirrorNode.parent,
					changeIndex: changeMirrorIndex,
					currentPath: singleMirror
				});
			});
		},

		setMirrorEnabled: (state, path) => {
			if (!state.localState || state.selected.length !== 1) {
				state.enabledMirror = false;
			} else {
				const element = node({ parent: state.localState, path }).element;

				if (!get(element, 'children') || get(element, 'mirror')) {
					state.enabledMirror = false;

				} else {
					state.enabledMirror = true;
				}
			}
		},

		setModifiedNotSaved: set('modifiedNotSaved'),

		setObservable: (state, config = {}) => {
			if (config.handleUncheck) {
				state.handleParentsUncheck = true;
				delete (config.handleUncheck);
			}
			const observable = {};
			forEach(config, (value, key) => {
				observable[key] = value;
			});
			state.observable = Vue.observable(observable);

			if (config) {
				state.handleParentsCheck = config.hasOwnProperty('indeterminate');
			}
		},

		setPhrase: set('phrase'),

		setPosition: set('position'),

		setSelected: set('selected'),

		setTypes: set('types'),

		setUpdate: set('update'),

		setWarnings: (state, item) => {
			item.warning = warning({ item, state: state.localState });
			state.warningElements = updateWarnings(item, state.warningElements);
		},

		syncMirrors: (state, { commit, path, updateName, updateValues, updateFields }) => {
			const updateElement = (mirror, origin) => {
				const element = get(state.localState, mirror);

				if (element.type !== 'folder') {
					element.fields = updateFields({ element, item: origin });
					element.fields.name = element.fields.customName ?
						origin.fields.name :
						updateName({ ...element.fields, type: element.type });

					element.warning = warning({ item: element, state: state.localState });
					state.warningElements = updateWarnings(element, state.warningElements);
				} else {
					element.mirrorName = origin.fields.name;

					if (element.children) {
						element.children = updateValues({
							fields: element.fields,
							origin: element.children,
							element: []
						});
					}
					commit('setElementsWarning', { item: element });
				}
			};
			const handleMirrors = (mirrors, mirrorPath) => {
				const handleInnerMirrors = (list) => {
					forEach(list, (single) => {
						handleMirrors(single.innerMirrors, single.path);
					});
				};
				const innerMirrorsList = [];
				const origin = get(state.localState, mirrorPath);
				forEach(mirrors, (mirror) => {
					const innerMirrors = mirrorList({
						elements: state.localState.children,
						path: mirror
					});
					innerMirrors.length ?
						innerMirrorsList.push({ innerMirrors, path: mirror }) :
						innerMirrors;
					updateElement(mirror, origin);
				});

				handleInnerMirrors(innerMirrorsList);
			};
			const mirrorsList = mirrorList({ elements: state.localState.children, path });
			handleMirrors(mirrorsList, path);
		},

		toggleEdit: (state, { item, itemSelected } = {}) => {
			if (state.selected.length === 1 || itemSelected || item.root) {
				state.editItem = item;
				const parent = node({ parent: state.localState, path: item.path }).parent;

				if (parent) {
					state.parent = parent.fields;
				}
			}
		},

		uncheckSingle: (state, path) => {
			const parentPathsList = parentPaths(path);

			forEach(parentPathsList, (parentPath) => {
				if (includes(state.selected, parentPath)) {
					state.selected = without(state.selected, parentPath);
					const parent = get(state.localState, parentPath);

					if (parent.children) {
						const childPaths = reduce(parent.children, (result, child) => {
							result.push(child.path);
							return result;
						}, []);
						state.selected = [...state.selected, ...childPaths];
					}
				}
			});
			state.selected = without(state.selected, path);
		},

		updateExpanded: (state, path) => {
			const closeChildren = (children) => {
				forEach(children, (child) => {
					child.expanded = false;

					if (child.children) {
						closeChildren(child.children);
					}
				});
			};

			const updateExpanded = (children) => {
				forEach(children, (child) => {
					if (child.path === path) {
						if (child.expanded && child.children) {
							closeChildren(child.children);
						}
						child.expanded = !child.expanded;
					} else if (child.children) {
						updateExpanded(child.children);
					}
				});
			};

			if (state.localState.children) {
				updateExpanded(state.localState.children);
			}
		},

		setActivePath: set('activePath')
	},

	actions: {
		init: ({ commit, dispatch, state }, { items, types, update, config }) => {
			commit('setUpdate', update);
			commit('setTypes', types);
			commit('setObservable', config);
			const parsedItems = JSON.parse(JSON.stringify(items));
			commit('setItems', { items: parsedItems, observable: state.observable });
			commit('calculatePaths');
			dispatch('updateWarnings');
			dispatch('updateUnavailable');
			dispatch('cancelCopy');
		},

		check: ({ commit }, { path }) => {
			commit('check', path);
			commit('removeSelectedChildren', path);
			commit('setMirrorEnabled', path);
		},

		copyNode: ({ commit }, data) => {
			commit('setCopy', data);
		},

		uncheckSingle: ({ commit, state }, { path }) => {
			commit('uncheckSingle', path);

			if (!state.selected.length) {
				commit('emptyCopy', path);
			}
			commit('setMirrorEnabled', path);
		},

		addItem: ({ commit, dispatch, state }, { item, path }) => {
			commit('add', { commit, item, path });
			commit('calculatePaths');
			dispatch('updateWarnings');

			// @TODO consider keeping check/uncheck props in getters only, so it's synced on the go
			// then remove the code below to trigger uncheck on new item added
			const newItemPath = (path) => {
				const index = get(state.localState, targetPath(path)).length - 1;
				return `${targetPath(path)}.${index}`;
			};

			if (path !== 'root') {
				commit('uncheckSingle', newItemPath(path));
			}
		},

		breakConnection: ({ commit }, path) => {
			commit('breakConnection', path);
		},

		delete: ({ state, commit, dispatch }) => {
			if (state.editItem && includes(state.selected, state.editItem.path)) {
				commit('removeEditItem');
			}
			commit('delete');
			dispatch('updateWarnings');
		},

		paste: ({ commit, dispatch }) => {
			commit('paste');
			dispatch('updateWarnings');
			commit('emptyCopy');
			commit('setSelected', []);
		},

		removeEditItem: ({ commit }) => {
			commit('removeEditItem');
			commit('setActivePath', '');
		},

		restoreItems: ({ commit, state }) => {
			commit('setMatched', 0);
			commit('setPosition', 0);
			const items = JSON.parse(JSON.stringify(state.localState));
			commit('setItems', { items, observable: state.highlightedObservable });
		},

		setModified: ({ commit }, bool) => {
			commit('setModifiedNotSaved', bool);
		},

		setPosition: ({ commit }, position) => {
			commit('setExpanded');
			commit('setPosition', position);
		},

		search: ({ commit }, phrase) => {
			commit('setPhrase', phrase);
			commit('search');
			commit('setExpanded');
		},

		setCopyPath: ({ commit }, paths) => {
			commit('setCopyPath', paths);
		},

		setMirror: ({ commit, dispatch }, { path }) => {
			commit('setMirror', { path });
			commit('setSelected', []);
			commit('setMirrorEnabled', false);
			dispatch('updateWarnings');
		},

		setWarning: ({ dispatch, commit }, { item }) => {
			if (item.type !== 'folder') {
				commit('setWarnings', item);
			}
			dispatch('updateWarnings');
		},

		syncMirrors: ({ commit, dispatch }, { path, updateName, updateValues, updateFields }) => {
			commit('syncMirrors', { commit, path, updateName, updateValues, updateFields });
			dispatch('updateWarnings');
		},

		toggle: ({ commit }, path) => {
			commit('updateExpanded', path);
		},

		toggleEdit: ({ commit, state }, { item } = {}) => {
			const itemSelected = includes(state.selected, item.path);
			commit('toggleEdit', { item, itemSelected });
		},

		uncheckAll: ({ commit }) => {
			commit('setSelected', []);
		},

		updateWarnings: ({ commit, state }) => {
			commit('setElementsWarning', { item: state.localState });
			commit('setInvalidElements', { field: 'warningElements', warning: 'warning' });
		},

		updateUnavailable: ({ commit }) => {
			commit('setInvalidElements', { field: 'unavailableElements', warning: 'unavailable' });
		},

		cancelCopy: ({ commit }) => {
			commit('emptyCopy');
			commit('setSelected', []);
		},

		setActivePath: ({ commit }, path) => {
			commit('setActivePath', path);
		}
	}
});
