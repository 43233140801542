import { find, get, invoke } from 'lodash';
import canAccess from 'core/engine/card/services/can-access';
import acl from 'acl';
import clinicianCfg from './clinician-config';
import respondentCfg from './respondent-config';

const aclCheck = (aclEntry) => !(get(aclEntry, 'checkpoint') && !acl.checkAccess(aclEntry));

export default {
	respondent: {
		storeModule: 'engine/respondentSidebar',
		canAccess: (item) => {
			const allowance = find(respondentCfg, { card: item.target });

			return !(allowance && !allowance.condition());
		}
	},
	clinician: {
		storeModule: 'engine/clinicianSidebar',
		canAccess: (item) => {
			if (item.linkType !== 'card') {
				return aclCheck(item.acl);
			}

			if (!canAccess(item.target)) {
				return false;
			}
			const allowance = find(clinicianCfg, { card: item.target });

			return aclCheck(item.acl) && invoke(allowance, 'condition') !== false;
		}
	}
};
