import appContext from 'app-context';
import homeClinicianCard from './cards/home-clinician';
import homeRespondentCard from './cards/home-respondent';
import assessmentPortalAssessmentCard from
	'modules/assessment-runtime/cards/assessment-portal-assessment';
import { atomic } from 'modules/atomic/cards/atomic';
import cardBuilder from 'card-builder';
import cardManager from 'core/engine/card/services/card-manager';
import HomeCardView from './views/home-card';
import store from 'store';
import { NotFound } from 'modules/general/cards/not-found';

// This one's special, need to have it fired on event
appContext.on('app.start', () => {
	const card = {
		clinician: homeClinicianCard,
		assessment: assessmentPortalAssessmentCard,
		respondent: homeRespondentCard,
		atomic
	}[store.state.user.type];

	cardBuilder.cards([NotFound, card]);
	cardManager.registerCard(new HomeCardView({ cardName: card.name }));
});
