import { escape, get, unescape } from 'lodash';

const getName = (name, { preventEscape }) => preventEscape ? unescape(name) : escape(name);

const stringifyName = (user, propNames, model) => {
	const name = get(user, propNames[model], '');

	if (name && name.length) {
		return name;
	}
	// unable to use lodash get due to backend returning correct keys with null values
	return user && user[model] ? user[model] : '';
};

const name = ({ user, propNames, config }) => {
	const displayName = stringifyName(user, propNames, 'displayName');
	const firstName = stringifyName(user, propNames, 'firstName');
	const lastName = stringifyName(user, propNames, 'lastName');
	const username = stringifyName(user, propNames, 'username');

	if (displayName.length) {
		return config.preventEscape ? unescape(displayName) : escape(displayName);
	} else if (firstName.length || lastName.length) {
		return `${getName(firstName, config)} ${getName(lastName, config)}`;
	}
	return getName(username, config);
};

const clinicianName = (clinician, config = {}) => name({
	config,
	user: clinician,
	propNames: {
		displayName: 'administratorDisplayName',
		firstName: 'administratorFirstName',
		lastName: 'administratorLastName',
		username: 'administratorUsername'
	}
});

const respondentName = (respondent, config = {}) => name({
	config,
	user: respondent,
	propNames: {
		displayName: 'respondentDisplayName',
		firstName: 'respondentFirstName',
		lastName: 'respondentLastName',
		username: 'respondentUsername'
	}
});

export { clinicianName, respondentName };
