var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "dashboard-table__wrapper" }, [
    _vm.title
      ? _c("h2", { staticClass: "chart-tile__title" }, [
          _vm._v("\n\t\t" + _vm._s(_vm.title) + "\n\t")
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("table", { staticClass: "dashboard-table" }, [
      _c("thead", [
        _c(
          "tr",
          [
            _c("th", [
              _vm._v(_vm._s(_vm.maybeTranslate(_vm.config.headingLabel)))
            ]),
            _vm._v(" "),
            _vm._l(_vm.headers, function(header) {
              return _c("th", { key: header }, [
                _vm._v("\n\t\t\t\t\t" + _vm._s(header) + "\n\t\t\t\t")
              ])
            }),
            _vm._v(" "),
            _vm._l(_vm.config.additionalColumns, function(additionalColumn) {
              return _c("th", { key: additionalColumn.label }, [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(_vm.maybeTranslate(additionalColumn.label)) +
                    "\n\t\t\t\t"
                )
              ])
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        [
          _vm._l(_vm.rows, function(row, i) {
            return _c(
              "tr",
              { key: i },
              [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(
                    "\n\t\t\t\t\t" + _vm._s(_vm.rowHeaders[i]) + "\n\t\t\t\t"
                  )
                ]),
                _vm._v(" "),
                _vm._l(row, function(cell, j) {
                  return _c("td", { key: j, class: _vm.cellClassName(j) }, [
                    _vm._v(_vm._s(cell))
                  ])
                })
              ],
              2
            )
          }),
          _vm._v(" "),
          _vm._l(_vm.additionalRows, function(additionalRow, i) {
            return _c(
              "tr",
              { key: additionalRow.label },
              [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(
                        _vm.maybeTranslate(_vm.config.additionalRows[i].label)
                      ) +
                      "\n\t\t\t\t"
                  )
                ]),
                _vm._v(" "),
                _vm._l(additionalRow, function(cell, j) {
                  return _c(
                    "td",
                    {
                      key: j,
                      staticClass: "dashboard-table__cell--additional"
                    },
                    [_vm._v(_vm._s(cell))]
                  )
                })
              ],
              2
            )
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }