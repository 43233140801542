import datetime from 'service/datetime/datetime';
import store from 'store';

const CUSTOM_FORMAT = 'YYYY-MM-DD HH:mm'; // #justb💩ckendthings

export const fromUTC = (date) => datetime(date)
	.setTimezone(store.getters['respondent/timezone'])
	.toMoment()
	.format('YYYY-MM-DDTHH:mm');

const naiveToUTC = (date) => datetime(date)
	.toUtcFromTimezone(store.getters['respondent/timezone'])
	.toMoment()
	.format(CUSTOM_FORMAT);
/*
 * datetime polyfill seems to be setting empty date on start which may result in "invalid date"
 * string being saved as a date itself (@see defect #11816)
 */
export const toUTC = (newVal) => newVal ? naiveToUTC(newVal) : newVal;
