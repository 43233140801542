import { map, find } from 'lodash';
import appUrl from 'service/app-url/app-url';
import repository from 'repository';
import { set } from '../__helpers/mutations';
import { RESPONDENT } from '../respondents/respondent';
import { cleanAssessmentInstanceReports } from 'store/__helpers/clean';

const prefix = 'assessmentInstanceReports';

// getters
const DATA = 'data';
const ASSESSMENT_REPORTS = 'assessmentReports';
const PENDING_COUNT = 'pendingReportsCount';
const REPORT_INSTANCES = 'reportInstances';
const MEDIA_REFERENCES = 'mediaReferences';

// actions
const INIT = 'init';
const INIT_REPORT_INSTANCES = 'initReportInstances';
const INIT_MEDIA_REFERENCES = 'initMediaReferences';
const RESET = 'reset';

export const ASSESSMENT_INSTANCE_REPORTS = {
	GETTERS: {
		DATA: `${prefix}/${DATA}`,
		ASSESSMENT_REPORTS: `${prefix}/${ASSESSMENT_REPORTS}`,
		PENDING_COUNT: `${prefix}/${PENDING_COUNT}`,
		REPORT_INSTANCES: `${prefix}/${REPORT_INSTANCES}`,
		MEDIA_REFERENCES: `${prefix}/${MEDIA_REFERENCES}`
	},

	ACTIONS: {
		INIT: `${prefix}/${INIT}`,
		INIT_REPORT_INSTANCES: `${prefix}/${INIT_REPORT_INSTANCES}`,
		INIT_MEDIA_REFERENCES: `${prefix}/${INIT_MEDIA_REFERENCES}`,
		RESET: `${prefix}/${RESET}`
	}
};

const mapGeneratedReport = (reportInstances) => (report) => ({
	...report,
	generatedReport: find(reportInstances, ['assessmentReportCode', report.code])
});

const buildInstructionFileUrl = (mediaReference, respondentId) => {
	if (mediaReference) {
		return appUrl(`${mediaReference.url}?respondentId=${respondentId}`);
	}

	return null;
};

const mapInstructionFiles = (mediaReferences, respondentId) => (generatedReport) => {
	const buildUrl = (filename) =>
		buildInstructionFileUrl(
			find(mediaReferences, ['filename', filename]),
			respondentId
		);

	const instructionFiles = map(
		generatedReport.instructionFiles,
		(filename) => ({
			name: filename,
			url: buildUrl(filename)
		})
	);

	return {
		...generatedReport,
		instructionFiles
	};
};

export default {
	namespaced: true,

	state: {
		assessmentReports: [],
		reportInstances: [],
		mediaReferences: []
	},

	getters: {
		[DATA]:
			(state, getters) => map(
				getters[ASSESSMENT_REPORTS], mapGeneratedReport(getters[REPORT_INSTANCES])
			),
		[ASSESSMENT_REPORTS]:
			(state) => map(state.assessmentReports, cleanAssessmentInstanceReports),
		[REPORT_INSTANCES]:
		// eslint-disable-next-line max-params
			(state, getters, rootState, rootGetters) => map(
				state.reportInstances,
				mapInstructionFiles(
					getters[MEDIA_REFERENCES],
					rootGetters[RESPONDENT.RESPONDENT_ID]
				)
			),
		[MEDIA_REFERENCES]:
			(state) => state.mediaReferences,
		[PENDING_COUNT]: (state, getters) =>
			getters[ASSESSMENT_REPORTS].length - getters[REPORT_INSTANCES].length
	},

	mutations: {
		setAssessmentReports: set('assessmentReports'),
		setReportInstances: set('reportInstances'),
		setMediaReferences: set('mediaReferences')
	},

	actions: {
		[INIT]: async ({ commit, dispatch }, { assessmentInstanceId, assessmentId }) => {
			try {
				const [reports] = await Promise.all([
					repository.getReportsByData({ assessmentId }),
					dispatch(INIT_REPORT_INSTANCES, assessmentInstanceId),
					dispatch(INIT_MEDIA_REFERENCES, assessmentInstanceId)
				]);

				commit('setAssessmentReports', reports);
				return reports;

			} catch (err) {
				return err;
			}
		},

		[INIT_REPORT_INSTANCES]: async ({ commit }, assessmentInstanceId) => {
			try {
				const reportInstances = await repository
					.assessmentInstanceReportInstances(assessmentInstanceId);

				commit('setReportInstances', reportInstances);

			} catch (err) {
				return err;
			}
		},

		[INIT_MEDIA_REFERENCES]: async ({ commit }, assessmentInstanceId) => {
			try {
				const mediaReferences = await repository
					.getMediaReferences(assessmentInstanceId);

				commit('setMediaReferences', mediaReferences);

			} catch (err) {
				return err;
			}
		}
	}
};
