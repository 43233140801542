import { warning } from 'service/log/log';

export default function (definition) {
	if (typeof definition.name === 'object') {
		const href = definition.name;

		definition.name = href.code;
		definition.target = href.target;
	}

	/* debug */
	this.__debug = {
		raw: definition,
		option: definition.option,
		name: definition.name
	};

	this.getType = function () {
		return 'Calc';
	};

	this.getClass = function () {
		return 'ExpressionRef';
	};

	this.getInvolvedQuestionCodesMap = function (questionMap, externals) {
		if (typeof externals.calcs[definition.name] === 'object') {
			return externals.calcs[definition.name]
				.getInvolvedQuestionCodesMap(questionMap, externals);

		}
		warning({ msg: 'Invalid reference', source: 'Calc Type' });

	};

	this.getBoolean = function () {
		return (typeof definition.name === 'string') && (definition.name.length > 0);
	};

	this.getNumber = function () {
		return NaN;
	};

	this.getString = function () {
		return (typeof definition.name !== 'string') ?
			'Undefined calc reference' :
			definition.name;
	};

	this.toString = function () {
		return `{Calc:${this.getString()}}`;
	};

	this.getRawValue = function () {
		return definition.name;
	};
}
