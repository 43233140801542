import repository from 'repository';
import { map, sortBy, transform } from 'lodash';

// b💩ckend...
const ATTR_ID = 'fk_administrator_attribute_administrator_attribute_meta_id';

export default {
	namespaced: true,

	state: {
		currentClinicianValues: [],
		definitions: []
	},

	getters: {
		currentClinicianValuesbyAttributeId: (state) => transform(
			state.currentClinicianValues,
				(result, value) => {
				result[value[ATTR_ID]] = value.administratorAttributeValue;
			}, {}),

		definitions: (state) => {
			const definitions = map(state.definitions, (definition) => ({
				id: definition.administratorAttributeMetaId,
				label: definition.administratorAttributeMetaLabel
			}));

			return sortBy(definitions, ['label']);
		}
	},

	mutations: {
		setCurrentClinicianValues: (state, values) => {
			state.currentClinicianValues = values;
		},

		setDefinitions: (state, definitions) => {
			state.definitions = definitions;
		}
	},

	actions: {
		getCurrentClinicianValues: ({ commit, rootGetters }) => {
			const values = repository.getClinicianAttributeValues(rootGetters.userId);

			return values.then(() => {
				commit('setCurrentClinicianValues', values.toJSON());
			});
		},

		initDefinitions: ({ commit }) => {
			const definitions = repository.getClinicianAttributeDefinitions();

			return definitions.then(() => {
				commit('setDefinitions', definitions.toJSON());
			});
		}
	}
};
