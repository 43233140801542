var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("agenda-view", {
        attrs: {
          name: _vm.name,
          items: _vm.items,
          addable: _vm.addable,
          types: _vm.types,
          "tile-path": _vm.tilePath,
          settings: _vm.settings,
          refresh: _vm.refresh,
          loading: _vm.loading,
          timezone: _vm.timezone,
          statuses: ["completed", "open", "overdue"]
        }
      }),
      _vm._v(" "),
      _c("activity-dialog", {
        attrs: {
          show: _vm.addingActivity,
          activity: _vm.newActivity,
          "on-close": _vm.closeActivityDialog,
          loading: _vm.addActivityLoading,
          "on-submit": _vm.addActivity,
          goals: _vm.goals
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }