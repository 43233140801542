import $ from 'jquery';
import { isFunction } from 'lodash';

let layout;

export default function (runtime, box) {
	var isProper = isFunction(runtime.get);
	var getLayoutData = function () {
		return isProper ? runtime.get('layoutData') : runtime.getLayoutData();
	};
	var getClass = function () {
		return isProper ? runtime.get('class') : runtime.getClass();
	};
	var getSection = function () {
		return isProper ? runtime.section() : runtime.getSection();
	};

	var layoutData = getLayoutData();

	var $box = $(box);
	$box.addClass('not-gridlayout');

	if (getClass() === 'Section' && runtime.getLayout()) {
		layout = runtime.getLayout();
		if (layout.type === 'questionnaire:GridLayout') {
			$box.addClass('row');
		}

		var mw = +layout.marginWidth;
		var mh = +layout.marginHeight;

		if (mw) {
			$box.css({
				'margin-left': mw + 'rem',
				'margin-right': mw + 'rem'
			});
		}
		if (mh) {
			$box.css({
				'margin-top': mh + 'rem',
				'margin-bottom': mh + 'rem'
			});
		}
	}

	/* Check if we're in a nested grid layout */
	var hasParentGrid = false;
	for (var parentSection = getSection(); parentSection; parentSection = parentSection.getSection()) {
		if (parentSection.getLayout().type === 'questionnaire:GridLayout') {
			hasParentGrid = true;
			break;
		}
	}

	/* Don't add grid layout to matrix rows */
	if (hasParentGrid && getSection().isMatrix()) {
		return false;
	}

	/* Check if there is no griddata set for the element. If not, we'll default to full width */
	if (hasParentGrid && layoutData.type !== 'questionnaire:GridData') {
		layoutData.type = 'questionnaire:GridData';
		layoutData.horizontalSpan = 12;
		layoutData.horizontalIndent = 0;
	}

	if (layoutData.type === 'questionnaire:FormData') {
		var unit = 'px';

		if (getSection ()) {
			unit = getSection().getLayout().unit === 'absolute' ? 'px' : '%';
		}

		$box.addClass('positioned formdata');
		$box.css({
			position: 'absolute',
			height: layoutData.height + unit,
			width: layoutData.width + unit,
			left: layoutData.left + unit,
			top: layoutData.top + unit
		});
	} else if (layoutData.type === 'questionnaire:GridData') {

		var maxspan = 12;

		// Make sure that the column span is an integer between 1 and 12
		// If there's a problem, set to 12.
		var span = Math.round(+layoutData.horizontalSpan) || maxspan;

		if (span < 1 || span > maxspan) {
			span = maxspan;
		}

		var lg = Math.min(span, maxspan); // large col span
		var md = Math.min(span * 2, maxspan); // medium col span
		var sm = Math.min(span * 4, maxspan); // small col span

		var offset = +layoutData.horizontalIndent;
		var offsetclasses = '';

		if (offset) {

			var lgo = offset; // large offset
			var mdo = Math.floor(offset / 2); //medium offset
			var smo = Math.floor(offset / 4); // small offset

			// Prevent offset from going off screen

			if (lg + lgo > maxspan) {
				lgo = maxspan - lg; // e.g. if this evals to 6 + 8 > 14, large offset will be 12 -
									// 6 => 6, so it fits
			}
			if (md + mdo > maxspan) {
				mdo = maxspan - md;
			}
			if (sm + smo > maxspan) {
				smo = maxspan - sm;
			}

			// Add computed offset classes
			offsetclasses = ' col-lg-offset-' + lgo + ' col-md-offset-' + mdo +
				' col-sm-offset-' + smo + ' col-xs-offset-0';
		}

		//Add computed column classes
		$(box)
			.removeClass('not-gridlayout')
			.addClass('assessment-container-gridlayout col-lg-' + lg + ' col-md-' +
				md + ' col-sm-' + sm + ' col-xs-12' + offsetclasses);
	}
}
