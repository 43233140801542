import SingleEntity from '../shared/single-entity';
import urls from './urls';

export default SingleEntity.extend({
	idAttribute: 'assessmentInstanceContextId',
	eventNamespace: 'assessment-context',
	urlRoot: urls.context.url,

	getLabel () {
		return this.get('assessmentInstanceContextLabel');
	}
});
