import { isString } from 'lodash';

const capitalize = (str) => str.replace(/\b\w/g, (letter) => letter.toUpperCase());

const removeAccents = (str) => {
	const accents =    'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
	const accentsOut = 'AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';
	return isString(str) ?
		str.split('')
		.map((letter) => {
			const accentIndex = accents.indexOf(letter);
			return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
		})
		.join('') : str;
};

export { capitalize, removeAccents };
