import repository from 'repository';
import { filter } from 'lodash';
import { set } from '../__helpers/mutations';
import translateItems from '../__helpers/translate-items';

const LABEL = 'dataExportTemplateColumnRespondentFieldLabel';

export default {
	namespaced: true,
	state: {
		attributes: [],
		customAttributes: []
	},

	getters: {
		list: (state) => state.attributes,
		translatedList: (state, getters) =>  translateItems({
			items: getters.list,
			label: LABEL,
			prefix: 'backendDictionary.'
		}),
		custom: (state) => state.customAttributes,
		customHistory: (state) => filter(state.customAttributes, {
			respondentAttributeMetaKeepHistory: true
		})
	},

	mutations: {
		set: set('attributes'),
		setCustom: set('customAttributes')
	},

	actions: {
		init: ({ commit }) => repository.getRespondentFields().then((response) => {
			commit('set', response);
		}),

		initCustom: ({ commit }) =>
			repository.getRespondentAttributeDefinitions().then((response) => {
				commit('setCustom', response);
			})
	}
};
