import repository from 'repository';
import { set } from '../__helpers/mutations';

export default {
	state: {
		maintenanceData: {
			locked: false,
			lockedAt: null,
			lockedBy: null
		}
	},
	getters: {
		maintenanceData: (state) => state.maintenanceData
	},
	mutations: {
		setMaintenanceData: set('maintenanceData')
	},
	actions: {
		getMaintenanceData: ({ commit }) => repository.maintenanceMode()
			.then((response) => commit('setMaintenanceData', response)),
		updateAndRefreshMaintenanceMode: ({ dispatch }, data) =>
			dispatch('updateMaintenanceMode', data).then(() => {
				dispatch('getMaintenanceData');
			}),
		updateMaintenanceMode: (context, { action, userId }) =>
			repository.updateMaintenanceMode({ action, userId })
	}
};
