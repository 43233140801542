var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      directives: [
        {
          name: "on-click-outside",
          rawName: "v-on-click-outside",
          value: _vm.hideMenu,
          expression: "hideMenu"
        }
      ],
      staticClass: "appbar-system-buttons__item"
    },
    [
      _c(
        "button",
        {
          staticClass: "appbar-system-buttons__action",
          attrs: { tabindex: "0", title: _vm.title },
          on: { click: _vm.onToggleClick }
        },
        [_vm._t("buttonContent")],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "appbar-menu-items__container" },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.toggle,
                    expression: "toggle"
                  }
                ],
                staticClass: "appbar-menu-items__list"
              },
              [
                _c(
                  "li",
                  {
                    staticClass: "appbar-menu-items__list-item",
                    class: _vm.selectedItemClassName({ item: null, i: -1 })
                  },
                  [
                    _c(
                      "button",
                      {
                        class: _vm.actionClassName,
                        on: {
                          click: function($event) {
                            return _vm.$emit("firstItemClick", {
                              item: null,
                              i: -1
                            })
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "appbar-menu-items__action-text" },
                          [_vm._t("firstItemContent")],
                          2
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.items, function(item, i) {
                  return _c(
                    "li",
                    {
                      key: i,
                      staticClass: "appbar-menu-items__list-item",
                      class: _vm.selectedItemClassName({ item: item, i: i })
                    },
                    [
                      _c(
                        "button",
                        {
                          class: _vm.actionClassName,
                          on: {
                            click: function($event) {
                              return _vm.$emit("itemClick", {
                                item: item,
                                i: i,
                                hideMenu: _vm.hideMenu
                              })
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "appbar-menu-items__action-text" },
                            [_vm._t("itemContent", null, { item: item })],
                            2
                          ),
                          _vm._v(" "),
                          _vm.isLoading({ item: item, i: i })
                            ? _c("span", {
                                directives: [
                                  {
                                    name: "loader-spinner",
                                    rawName: "v-loader-spinner:small",
                                    arg: "small"
                                  }
                                ],
                                staticClass: "appbar-menu-items__loading"
                              })
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                })
              ],
              2
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }