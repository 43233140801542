import appContext from 'app-context';
import { param } from 'jquery';

const prefix = 'backend-commons/report/rest/report';

export default {
	allReports: {
		url: (params) => {
			const userId = appContext.get('user').get('userId');
			const query = params ? `?${param(params)}` : '';
			return `${prefix}/instance/respondent/${userId}${query}&include=treatment`;
		},
		logEntry: {
			msg: 'Respondent <em>views</em> all Reports',
			typeId: 'GET REPORT INSTANCES'
		}
	},
	reportContent: {
		url () {
			return `${prefix}/generate/${this.get('assessmentInstanceId')}/${this.get('reportId')}`;
		},
		logEntry: {
			msg: (report) => [
				'Respondent <em>views</em> report:<br />',
				`Title: <b>${report.title}</b>`
			].join('• '),
			typeId: 'GET ASSESSMENT INSTANCES',
			attrs: () => ({ title: 'test' })
		}
	}
};
