import { includes, replace } from 'lodash';
import store from 'store';
import config from 'core/config';
import cardsProps from 'core/engine/card/services/cards-props';

export default ({ usePreviousUrl } = { usePreviousUrl: false }) => {
	const defaultCard = config().defaultCardAfterLogin;
	const currentHref = usePreviousUrl ?
		replace(
			window.location,
			window.location.hash,
			`#${cardsProps.routes[cardsProps.routes.length - 2]}`
		) :
		window.location;
	let redirectUrl = store.state.user.logoutUrl;

	if (!includes(redirectUrl, 'ReturnTo')) {
		redirectUrl += `?ReturnTo=${encodeURI(currentHref)}`;
	}

	// add redirect location provided by 🚽ackend directly to the base url if it begins with `/`
	const rootUrl = redirectUrl[0] === '/' ?
		config().baseUrl :
		config().authUrl;

	const removeHash = (url) => includes(url, '%23') ? url.substr(0, url.indexOf('%23')) : url;
	const addCardSuffix = (url) => defaultCard ? `${url}%23${defaultCard}` : url;

	const location = (url) => addCardSuffix(removeHash(url));

	return location(rootUrl + redirectUrl);
};
