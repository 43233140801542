import { READ } from 'service/acl/access-levels';
import t from 'service/lang/translate';

export default () => ({
	title: t('Comparison chart'),
	acl: [{
		checkpoint: 'administrator.respondents.assessmentplans',
		op: READ
	}, {
		checkpoint: 'administrator.respondents.assessmentcontent',
		op: READ
	}]
});
