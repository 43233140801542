import { forEach, get, includes, isArray, reduce, toUpper } from 'lodash';

const transforms = {
	ASSESSMENT: {
		fields: ['id', 'code', 'name', 'relationId'],
		suffix: 'assessment',
		skip: ['relationId']
	},

	ASSESSMENT_INSTANCE_REPORTS: {
		fields: ['id', 'code', 'name'],
		suffix: 'assessmentReport',
		skip: []
	},

	ASSESSMENT_ATTRIBUTE: {
		fields: ['id', 'label', 'name', 'type'],
		suffix: 'dataExportTemplateColumnAssessmentField'
	},

	CLINICIAN: {
		fields: ['accountEnabled', 'authenticationProviderReference', 'email', 'firstName', 'id',
			'languageId', 'lastName', 'phone', 'testAccount', 'timezoneName', 'username'],
		suffix: 'administrator'
	},

	CLINICIAN_ASSIGNED_GROUP: {
		fields: ['id', 'name', 'category', 'validFrom', 'validTo'],
		suffix: 'administratorGroup.administratorGroup',
		skip: ['validFrom', 'validTo']
	},

	CLINICIAN_ASSIGNED_ROLE: {
		fields: ['id', 'name', 'validFrom', 'validTo'],
		skip: ['validFrom', 'validTo'],
		exception: {
			id: 'administratorRole.id',
			name: 'administratorRole.name'
		}
	},

	CLINICIAN_GROUP: {
		fields: ['id', 'category', 'name', 'relationId'],
		suffix: 'administratorGroup',
		exception: {
			relationId: ['relationId', 'administratorGroupHasWorkflowId']
		}
	},

	CLINICIAN_ROLE: {
		fields: ['id', 'name'],
		suffix: 'administratorRole'
	},

	EXPORT_CLINICIAN_GROUP: {
		fields: ['id', 'name'],
		suffix: 'administratorGroup'
	},

	FLAG: {
		fields: ['id', 'code', 'name', 'triggerId', 'relationId'],
		suffix: 'assessmentFlag',
		skip: ['relationId'],
		exception: { triggerId: 'workflowTriggerFlagId' }
	},

	RESPONDENT_GROUP: {
		fields: ['id', 'name', 'category', 'access', 'common'],
		suffix: 'respondentGroup',
		exception: {
			access: 'respondentGroupHasAccessToClinician',
			common: 'respondentGroupIsCommon'
		}
	},

	RESPONDENT_GROUP_RELATION: {
		fields: ['id', 'category', 'name', 'relationId'],
		suffix: 'respondentGroup',
		skip: ['relationId'],
		exception: {
			category: 'group.respondentGroupCategory',
			name: 'group.respondentGroupName'
		}
	}
};

const clean = (transformed, leaveSourceData = null) => (entity) => {
	const cleaned = {};

	forEach(transformed, (from, to) => {
		const source = isArray(from) ?
			get(entity, from[0]) || get(entity, from[1]) :
			get(entity, from);

		// empty strings and false values remain when needed
		cleaned[to] = leaveSourceData ? source : source || entity[to];
	});
	return cleaned;
};

export const generateTransform = ({
	fields,
	suffix = '',
	skip = [],
	exception = {}
}) =>
	reduce(fields, (result, field) => {
		if (includes(skip, field)) {
			result[field] = field;

		} else if (exception[field]) {
			result[field] = exception[field];

		} else {
			result[field] = `${suffix}${toUpper(field.charAt(0)) + field.slice(1)}`;
		}

		return result;
	}, {});

const cleanTransformed = (entity) => clean(generateTransform(entity));

export const cleanAssessment = cleanTransformed(transforms['ASSESSMENT']);
export const cleanAssessmentAttribute = cleanTransformed(transforms['ASSESSMENT_ATTRIBUTE']);
export const cleanAssessmentInstanceReports = cleanTransformed(
	transforms['ASSESSMENT_INSTANCE_REPORTS']
);
export const cleanClinician = cleanTransformed(transforms['CLINICIAN']);
export const cleanClinicianAssignedGroup = cleanTransformed(transforms['CLINICIAN_ASSIGNED_GROUP']);
export const cleanClinicianAssignedRole = cleanTransformed(transforms['CLINICIAN_ASSIGNED_ROLE']);
export const cleanClinicianGroup = cleanTransformed(transforms['CLINICIAN_GROUP']);
export const cleanClinicianRole = cleanTransformed(transforms['CLINICIAN_ROLE']);
export const cleanExportClinicianGroup = cleanTransformed(transforms['EXPORT_CLINICIAN_GROUP']);
export const cleanFlag = cleanTransformed(transforms['FLAG']);
export const cleanRespondentGroup = cleanTransformed(transforms['RESPONDENT_GROUP']);
export const cleanRespondentGroupRelation = cleanTransformed(
	transforms['RESPONDENT_GROUP_RELATION']
);

export default clean;
