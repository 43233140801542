var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.className },
    [
      _c("custom-tag", { attrs: { name: _vm.labelTag } }, [
        _vm.keyLabel
          ? _c("p", { staticClass: "edit-object__label-text" }, [
              _vm._v("\n\t\t\t" + _vm._s(_vm.keyLabel) + "\n\t\t")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "edit-object__toggle-container" }, [
        _vm.allowToggle
          ? _c(
              "button",
              { staticClass: "edit-object__toggle", on: { click: _vm.toggle } },
              [_vm._v(_vm._s(_vm.toggleLabel))]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [
          _vm.componentExists(_vm.componentType)
            ? _c(_vm.componentType, {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.toggled,
                    expression: "toggled"
                  }
                ],
                tag: "component",
                attrs: {
                  "obj-key": _vm.objKey,
                  "obj-val": _vm.objVal,
                  values: _vm.values,
                  readonly: _vm.readonly,
                  "on-value-change": _vm.onValueChange,
                  "on-object-key-change": _vm.onObjectKeyChange,
                  config: _vm.config,
                  path: _vm.path
                },
                on: { focus: _vm.onFocus, blur: _vm.onBlur }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }