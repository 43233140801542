import Vue from 'vue';
import Vuex from 'vuex';
import { assign, debounce, get, noop } from 'lodash';
import respondentModules from './__modules-definitions/respondent';
import clinicianModules from './__modules-definitions/clinician';
import sharedModules from './__modules-definitions/shared';
import repository from 'repository';
import prefixify from './__helpers/prefixify';
import { exact } from './__helpers/getters';
import { set } from './__helpers/mutations';

Vue.use(Vuex);  // need to be sure that Vuex is set up to work with Vue

const prefix = prefixify('');

const CARD_DATA = 'cardData';
const USER = 'user';
const DETACHED = 'detached';
const SET_DETACHED = 'setDetached';
const URL_PARAMS = 'urlParams';

export const APP = {
	DETACHED: prefix(DETACHED),
	SET_DETACHED: prefix(SET_DETACHED),
	URL_PARAMS: prefix(URL_PARAMS)
};

export default new Vuex.Store({
	modules: assign({}, sharedModules, respondentModules, clinicianModules),

	state: {
		[CARD_DATA]: {},
		[USER]: {},
		[DETACHED]: false
	},

	getters: {
		...exact([CARD_DATA, DETACHED]),
		cardName: (state, getters) => get(getters.cardData, 'cardName', '🐻'),
		userId: (state) => get(state, 'user.userId'),
		userType: (state) => get(state, 'user.type'),
		userTimezone: (state) => get(state, 'user.timezoneName'),
		userLanguage: (state) => get(state, 'user.userLanguageCode'),
		respondent: (state) => get(state, 'respondent.data'),
		treatment: (state) => get(state, 'treatment.data'),
		[URL_PARAMS]: (state, getters) => get(getters.cardData, 'urlParams', {}),
		isMe: (state) => ({ id }) => +get(state, 'user.userId') === +id
	},

	mutations: {
		initUser: (state, { user, type }) => {
			state.user = assign({}, user.toJSON(), { type });
		},

		setCardData: (state, payload) => {
			state.cardData = payload;
		},

		[SET_DETACHED]: set(DETACHED)
	},

	actions: {
		initUser: ({ commit }, { user, type }) => user.then(() => {
			commit('initUser', { user, type });
		}, noop),

		keepalive: debounce(() => repository.keepalive(), 60000, { leading: true }),

		resetCardData: ({ commit, state }) => {
			state.respondent && commit('respondent/reset');
			state.treatment && commit('treatment/reset');
		},

		setCardData: ({ commit }, payload) => {
			commit('setCardData', payload);
		},

		[SET_DETACHED]: ({ commit }, detached) => {
			commit(SET_DETACHED, detached);
		}
	}
});
