import { Model, sync } from 'backbone';
import urls from './urls';
import { find } from 'lodash';

export default Model.extend({
	url () {
		return urls.isUnique.url(this.get('code'));
	},

	sync (method, model, options) {
		if (method === 'create') {
			options.type = 'GET';
		}

		// eslint-disable-next-line prefer-rest-params
		return sync(...arguments);
	},

	// Check is done by performing a search for a circle with a given code. Response is actually an
	// array that is empty or contains one element. So, circle code is available when response array
	// is empty
	// UPDATE 09.06.2020
	// Response returns Array with all elements, so additional check is required.
	parse (response) {
		const isTaken = find(response, { code: this.get('code') });
		return { available: !isTaken };
	}
});
