var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "circle-members" }, [
    _c("section", { staticClass: "circle-members__owner-list" }, [
      _c("h3", [_vm._v(_vm._s(_vm.t("Circle owner")))]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "circle-members__list-item" },
        [_c("avatar-nickname", { attrs: { user: _vm.owner } })],
        1
      )
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "circle-members__member-list" }, [
      _c("h3", [_vm._v(_vm._s(_vm.t("Circle members")))]),
      _vm._v(" "),
      _c("p", { staticClass: "circle-members__member-list-counter" }, [
        _vm._v("\n\t\t\t(" + _vm._s(_vm.resultsCount) + ")\n\t\t")
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "circle-members__list" },
        _vm._l(_vm.list, function(member) {
          return _c(
            "li",
            { key: member.id, staticClass: "circle-members__list-item" },
            [
              _c("avatar-nickname", { attrs: { user: member } }),
              _vm._v(" "),
              !_vm.isOwner(member)
                ? _c(
                    "button",
                    {
                      staticClass: "circle-members__member-delete",
                      on: {
                        click: function($event) {
                          return _vm.remove(member)
                        }
                      }
                    },
                    [_c("icon", { attrs: { name: "delete" } })],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.needMore
        ? _c(
            "button",
            {
              staticClass:
                "circle-members__member-list-action flat-button__button",
              on: { click: _vm.loadMore }
            },
            [_vm._v(_vm._s(_vm.t("More results")))]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }