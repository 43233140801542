import repository from 'repository';
import { assign, get, map } from 'lodash';

export default {
	namespaced: true,

	state: {
		data: [],
		model: [],
		searchParams: {}
	},

	getters: {
		treatments: (state) => state.data,
		results: (state) => state.model,
		isMore: (state) => state.data.length < +state.model.total
	},

	mutations: {
		setSearchParams: (state, filters) => {
			assign(state.searchParams, filters);
		},

		setFilter: (state, filters) => {
			assign(state.searchParams.filters, filters);
		},

		resetFilter: (state) => {
			state.searchParams.filters = {
				start: 0,
				limit: get(state.searchParams.filters, 'limit')
			};
		},

		increaseStart: (state) => {
			state.searchParams.filters.start += state.searchParams.filters.limit;
		},

		resetStart: (state) => {
			state.searchParams.filters.start = 0;
		},

		set: (state, { treatments }) => {

			state.data = treatments.toJSON();
			state.model = treatments;
		},

		push: (state, { treatments }) => {
			state.data.push(...treatments.toJSON());
			state.model.add(treatments.models);
		},

		setSortParam: (state, { key, ascending }) => {
			assign(state.searchParams, { sortBy: ascending ? `+${key}` : `-${key}` });
		}
	},

	actions: {
		initSearchParams: ({ commit, rootState }, payload) => {
			const {
				'filter[respondentGroupId]': respondentGroup = {},
				'filter[treatmentTypeId]': treatmentType = {},
				'filters': otherFilters = {},
				'search': find = {}
			} = rootState.filter.id === payload.id ?
				get(rootState.filter, 'keys') :
				{};

			assign(
				payload.filters,
				{
					'filter[respondentGroupId]': map(respondentGroup, (val, key) =>
						val ? key : undefined).join()
				},
				{
					'filter[treatmentTypeId]': map(treatmentType, (val, key) =>
						val ? key : undefined).join()
				},
				otherFilters,
				{ search: find.query }
			);

			commit('setSearchParams', payload);
		},

		setFilter: ({ commit }, params) => {
			commit('resetStart');
			commit('setFilter', params);
		},

		resetFilter: ({ commit }) => {
			commit('resetStart');
			commit('resetFilter');
		},

		setSortParam: ({ commit }, sortBy) => {
			commit('resetStart');
			commit('setSortParam', sortBy);
		},

		loadMore: ({ commit, dispatch }) => {
			commit('increaseStart');
			return dispatch('search', { push: true });
		},

		search: ({ state, commit }, { push } = {}) => {
			const treatments = repository.searchMyTreatments(state.searchParams);

			return treatments.then(() => {
				treatments.total = treatments.getResponseHeader('X-Total-Count');

				if (push) {
					commit('push', { treatments });

				} else {
					commit('set', { treatments });
				}
			});
		}
	}
};
