import ButtonView from './button-view';
import { defaults, isString } from 'lodash';

/**
 * Adds a standard button for the specified card instance.
 *
 * @function appbar#addButton
 * @author Waldemar
 *
 * @param {object} opts
 * @param {string} opts.sourceCard - Name of the card which button should be placed on.
 * @param {string} opts.title
 * @param {string} [opts.url]
 * @param {string} [icon]
 * @param {appbar.button.click-callback} [opts.click] - Callback for click.
 * @param {appbar.button.onRender-callback} [opts.onRender] - Callback called on render.
 * @param {bool} [opt.preventHide] - Should button be showed in 1 column layout.
 *
 * @returns {ButtonView}
 */

export default function (options) {
	let sourceCard;
	let opts = options;

	// @TODO(2015-10-15): remove when all calls are migrated to non-deprecated usage
	if (arguments.length === 2 && isString(opts)) {
		sourceCard = opts;
		// eslint-disable-next-line prefer-rest-params
		opts = arguments[1];

	} else {
		sourceCard = opts.sourceCard;
	}
	const title = opts.title || '';
	const buttonView = new ButtonView(defaults({
		url: opts.url,
		click: opts.click,
		css: opts.css,
		icon: opts.icon,
		title
	}, opts));

	this.add(sourceCard, buttonView, {
		preventHide: !!opts.preventHide,
		order: opts.order
	});

	return buttonView;
}
