import FunctionParameter from './function-parameter';
import { assign, constant, forEach } from 'lodash';

const FunctionExpression = function (definition) {
	const name = definition.name ? definition.name : 'questionResponseValue';
	const parameters = {};

	forEach(definition.parameter, (param) => {
		parameters[param.name] = new FunctionParameter(param, FunctionExpression);
	});

	assign(this, { parameters, definition, name }, {
		getName: () => name,
		getClass: constant('Function'),
		getParamsMap: () => parameters,

		getInvolvedQuestionCodesMap: (questionMap = {}, externals) => {
			forEach(parameters, (param) => {
				param.getInvolvedQuestionCodesMap(questionMap, externals);
			});

			return questionMap;
		}
	});
};

export default FunctionExpression;

