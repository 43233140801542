export default function (definition) {
	/* debug */
	this.__debug = {
		raw: definition,
		option: definition.option,
		question: definition.question
	};

	this.getType = function () {
		return 'Option';
	};

	this.getClass = function () {
		return 'Option';
	};

	this.getInvolvedQuestionCodesMap = function (questionMap) {
		questionMap[this.getQuestionCode()] = 'question';

		return questionMap;
	};

	this.getBoolean = function () {
		return (typeof this.getOptionCode() == 'string') && (this.getOptionCode().length > 0);
	};

	this.getNumber = function () {
		return NaN;
	};

	this.getString = function () {
		return (typeof this.getOptionCode() != 'string') ?
			'Undefined option reference' :
			this.getOptionCode();
	};

	this.getOptionCode = function () {
		// @TODO: when json converted will be fixed this should no longer check for vector
		// this also works without the condifions due to array.toString method

		return (typeof definition.option == 'object') ?
			((typeof definition.option[0] == 'object') ? definition.option[0].code : definition.option[0]) :
			definition.option;
	};

	this.getQuestionCode = function () {
		return (typeof definition.question == 'object') ?
			definition.question.code :
			definition.question;
	};

	this.getRawValue = function () {
		return definition.option;
	};

	this.toString = function () {
		return `{Option:${this.getString()}}`;
	};

}
