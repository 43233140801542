import t from 'translate';
import appBar from 'app-bar';
import store from 'store';
import cwalert from 'cwalert';
import confirmation from 'components/confirmation/confirmation';
import can from 'acl-can';
import { SYSTEM } from 'service/acl/checkpoints';

const card = {
	url: [
		'treatment/:treatmentId/connected-apps/:id',
		'connected-apps/:id'
	],
	name: 'connected-app-details',
	title: t('App store'),

	ctrl: ({ close }, { id }) => {
		if (can.delete(SYSTEM.SETTINGS.SETTINGS)) {
			appBar.addButton(card.name, {
				icon: 'unlink',
				title: t('Break the connection'),
				click: () => {
					confirmation({
						icon: 'unlink',
						warning: true,
						title: t('Deactivate App'),
						message: t('Are you sure you wish to deactivate this App?')
					}).then(() => {
						store.dispatch('apps/disconnectCurrent');
						close();
						cwalert.success(t('App disconnected'));
					});
				}
			});
		}

		return store.dispatch('apps/initDetails', id);
	}
};

export { card as appDetails };
