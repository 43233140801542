import { member } from './urls';
import SingleEntity from '../shared/single-entity';
import { sync } from 'backbone';

export default SingleEntity.extend({
	url: member.url,
	eventNamespace: 'circle-member',

	onSync (method, model, options) {
		if (method === 'update' || method === 'create') {
			options.type = 'PUT';
		}
		// eslint-disable-next-line prefer-rest-params
		return sync(...arguments);
	}
});
