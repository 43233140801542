import CardDialog from 'service/card-dialog/card-dialog';
import Vue from 'vue';
import store, { APP } from 'store';
import TreatmentSelection from './index';
import { TREATMENT } from 'store/treatments/rp-treatment';
import appbarManager from 'core/engine/appbar/appbar-manager';
import renderCurrent from 'core/engine/card/services/render-current';
import { get, includes } from 'lodash';
import afterTreatmentSelect from 'modules/treatments/components/treatment-selection/after-treatment-select.es6';

const cardsSkipped = [
	'assessment',
	'latest-assessment',
	'available-consents',
	'consent'
];

const autoAssessment = ({ cardName, cardParams }) => {
	const referral = get(cardParams, 'referral');

	return (
		cardName === 'start' &&
		referral !== 'switch-treatment'
	);
};

const selectionNeeded = ({ cardName, cardParams }) => (
	!autoAssessment({ cardName, cardParams }) &&
	store.getters['userType'] === 'respondent' &&
	!store.getters[TREATMENT.ID] &&
	!cardParams.treatmentId
);

const inDetachedMode = ({ detachable }) => store.getters[APP.DETACHED] && detachable;

const dialog = () => {
	const vm = new Vue({
		el: document.createElement('div'),
		store,
		...TreatmentSelection
	});

	const cardDialog = new CardDialog({
		preventFocusTrap: true,
		mode: 'mighty',
		content: vm.$el
	});

	const closeDialog = () => {
		cardDialog.close();
		appbarManager.toggle(true);
	};

	vm.$on('openConsents', () => {
		closeDialog();
		renderCurrent();
	});

	return { closeDialog };
};

export default ({ config, cardName, cardParams }) => new Promise((resolve, reject) => {
	if (
		selectionNeeded({ cardName, cardParams }) &&
		!inDetachedMode(config) &&
		!includes(cardsSkipped, cardName)
	) {
		store.dispatch(APP.SET_DETACHED, true);
		appbarManager.toggle(false);

		const { closeDialog } = dialog();

		const unsubscribe = store.subscribeAction({
			after: (action) => {
				if (action.type === TREATMENT.INIT) {
					unsubscribe();
					closeDialog();
					store.dispatch(APP.SET_DETACHED, false);

					if (afterTreatmentSelect({
						treatmentId: store.getters[TREATMENT.ID],
						referral: 'switch-treatment'
					})) {
						reject();
					} else {
						resolve();
					}
				}
			}
		});

	} else {
		resolve();
	}
});
