import SingleEntity from '../shared/single-entity';
import t from 'translate';
import urls from './urls';
import datetime from 'datetime';

export default SingleEntity.extend({
	idAttribute: 'inputId',
	urlRoot: urls.decision.url,
	eventNamespace: 'decision',

	getResponseText () {
		switch (this.get('value')) {
			case 'yes':
				return t('manual-inputs.answers.Yes');
			case 'no':
				return t('manual-inputs.answers.No');
			default:
				return '';
		}
	},

	getRespondentId () {
		return this.get('respondent_id') || this.get('respondentId');
	},

	getStartDate () {
		return this.get('issueDate');
	},

	setStartDate (date, timezone) {
		this.set('issueDate', datetime(date).toUtcFromTimezone(timezone).toObject());
	},

	setEndDate () {}
});

