import { cube } from './urls';
import { GET } from 'service/ajax/ajax';

export default {
	cube: ({ query = {}, ignoreAccessRules = false }) => {
		const queryString = encodeURI(JSON.stringify(query));
		return GET({
			url: `${cube}?ignoreAccessRules=${+ignoreAccessRules}&query=${queryString}`,
			separator: '&'
		});
	}
};
