import settings from 'system-settings';
import { clone, every, isArray, isString, isFunction, split } from 'lodash';

export default (ftrs) => {
	const features = isFunction(ftrs) ? ftrs() : ftrs;
	const list = isString(features) ? split(features, ' ') : clone(features);

	return isArray(list) ?
		every(list, (feature) => settings.getBoolean(feature)) :
		true;
};
