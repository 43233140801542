import accessRoles from '../system-settings/access-roles';
import adapters from '../adapters/adapters';
import adapterInstance from '../adapters/adapter-instance';
import adapterInstanceSetting from '../adapters/adapter-instance-setting';
import adapterInstanceCapability from '../adapters/adapter-instance-capability';
import adapterInstances from '../adapters/adapter-instances';
import assessment from '../assessments/assessment';
import assessmentFlags from '../assessments/assessment-flags';
import assessmentInstance from '../assessments/assessment-instance';
import assessmentInstances from '../assessments/assessment-instances';
import assessmentInstanceReports from '../assessments/assessment-instance-reports';
import assessments from '../assessments/assessments';
import assessmentValueChartSeries from '../assessments/assessment-value-chart-series';
import assessmentValues from '../assessments/assessment-values';
import cards from '../card-configurator/cards';
import clinician from '../clinicians/clinician';
import clinicianLock from '../clinicians/clinician-lock';
import clinicianAttributes from '../clinicians/clinician-attributes';
import clinicianGroups from '../clinicians/clinician-groups';
import clinicianRoles from '../clinicians/clinician-roles';
import clinicianSearchAvailable from '../clinicians/clinician-search-available';
import clinicianSearch from '../clinicians/clinician-search';
import consent from '../consents/consent';
import consentInstance from '../consents/consent-instance';
import consentInstances from '../consents/consent-instances';
import consents from '../consents/consents';
import contentPackage from '../content-packages/content-package';
import contentPackageInstance from '../content-packages/content-package-instance';
import contentPackageInstances from '../content-packages/content-package-instances';
import contentPackages from '../content-packages/content-packages';
import contexts from '../general/contexts';
import dataIntegration from '../data-integration/data-integration';
import decision from '../plans/decision';
import decisions from '../plans/decisions';
import deniedClinicians from '../treatments/denied-clinicians';
import exportTemplate from '../export-template/export-template';
import exportTemplateGui from '../export-template/export-template-gui';
import flag from '../flags/flag';
import flags from '../flags/flags';
import media from '../media/media';
import mediaSearch from '../media/media-search';
import notifications from '../general/notifications';
import pages from '../pages/pages';
import plan from '../plans/plan';
import planInstance from '../plans/plan-instance';
import planInstances from '../plans/plan-instances';
import plans from '../plans/plans';
import relationTypes from '../relation-types/relation-types';
import respondent from '../respondents/respondent';
import respondentClinicians from '../respondents/respondent-clinicians';
import respondentGroup from '../respondents/respondent-group';
import respondentGroups from '../respondents/respondent-groups';
import respondentRelations from '../respondents/respondent-relations';
import respondentSearch from '../respondents/respondent-search';
import respondentLock from '../respondents/respondent-lock';
import roles from '../treatments/roles';
import roleSetRoles from '../treatments/role-set-roles';
import roleSets from '../treatments/role-sets';
import systemSettings from '../system-settings/system-settings';
import systemEventTypes from '../system-log/system-event-types';
import systemExports from '../system-exports/system-exports';
import treatment from '../treatments/treatment';
import treatmentSearch from '../treatments/treatment-search';
import treatmentProgram from '../treatments/treatment-program';
import treatmentPrograms from '../treatments/treatment-programs';
import treatmentType from '../treatments/treatment-type';
import treatmentTypes from '../treatments/treatment-types';
import videoChat from '../video-chat/video-chat';

export default {
	accessRoles,
	adapters,
	adapterInstance,
	adapterInstanceSetting,
	adapterInstanceCapability,
	adapterInstances,
	assessment,
	assessmentFlags,
	assessmentInstance,
	assessmentInstances,
	assessmentInstanceReports,
	assessments,
	assessmentValueBarSeries: assessmentValueChartSeries(),
	assessmentValuePieSeries: assessmentValueChartSeries(),
	assessmentValuePlotSeries: assessmentValueChartSeries(),
	assessmentValues,
	cards,
	clinician,
	clinicianLock,
	clinicianAttributes,
	clinicianGroups,
	clinicianRoles,
	clinicianSearchAvailable,
	clinicianSearch,
	consent,
	consentInstance,
	consentInstances,
	consents,
	contentPackage,
	contentPackageInstance,
	contentPackageInstances,
	contentPackages,
	contexts,
	dataIntegration,
	decision,
	decisions,
	deniedClinicians,
	exportTemplate,
	exportTemplateGui,
	flag,
	flags,
	media,
	mediaSearch,
	notifications,
	pages,
	plan,
	planInstance,
	planInstances,
	plans,
	relationTypes,
	respondent,
	respondentClinicians,
	respondentGroup,
	respondentGroups,
	respondentRelations,
	respondentSearch,
	respondentLock,
	roles,
	roleSetRoles,
	roleSets,
	systemSettings,
	systemEventTypes,
	systemExports,
	treatment,
	treatmentSearch,
	treatmentProgram,
	treatmentPrograms,
	treatmentType,
	treatmentTypes,
	videoChat
};
