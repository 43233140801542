import { isNil } from 'lodash';
import parsed from './types/parsed';
import date from './types/date';
import respondent from './types/respondent';
import defaultItem from './types/default';

const components = {
	default: defaultItem,
	date,
	respondent,
	parsed
};

export default ({ tileConfig, itemConfig, rawContent }) => {
	let component = components[itemConfig.type] || components.default;

	if (isNil(rawContent) || rawContent === '') {
		component = components.default;
	}

	return component({ tileConfig, itemConfig, rawContent });
};
