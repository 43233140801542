import dashboardChart from './dashboard-chart';
import dashboardMultiTable from './dashboard-multi-table';
import dashboardValue from './dashboard-value';
import DashboardProgress from './dashboard-progress';
import dashboardTable from './dashboard-table';

export default {
	'dashboard-chart': dashboardChart,
	'dashboard-multi-table': dashboardMultiTable,
	'dashboard-progress': DashboardProgress,
	'dashboard-value': dashboardValue,
	'dashboard-table': dashboardTable
};
