var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      staticClass: "edit-object__select",
      attrs: { disabled: _vm.readonly },
      domProps: { value: _vm.objVal },
      on: { blur: _vm.onSelectValueChange }
    },
    _vm._l(_vm.values, function(value) {
      return _c("option", { key: value, domProps: { value: value } }, [
        _vm._v("\n\t\t" + _vm._s(value) + "\n\t")
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }