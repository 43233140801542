var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.display
    ? _c("span", { staticClass: "sidebar-counter" }, [
        _vm.loaded
          ? _c("span", [_vm._v(_vm._s(_vm.count))])
          : _c("span", {
              directives: [
                {
                  name: "loader-spinner",
                  rawName: "v-loader-spinner:small",
                  arg: "small"
                }
              ]
            })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }