import { assign } from 'lodash';

import SingleEntity from '../shared/single-entity';
import common from '../messages/message-common';
import { messageDraft } from './urls';

/**
 * RespondentMessageDraft entity
 *
 * @class RespondentMessageDraft
 * @extends SingleEntity
 */
export default SingleEntity.extend(assign(common, {
	urlRoot: messageDraft.url,
	eventNamespace: 'respondent-message-draft'
}));
