import cardUrl from 'cwcardurl';
import config from 'core/config';
import cwalert from 'cwalert';
import Modernizr from 'modernizr';
import screenfull from 'screenfull';
import SimpleWebRTC from 'simplewebrtc';
import t from 'translate';
import TileView from 'tile-view';
import {
	compact, debounce, delay, forEach, get, isUndefined, noop, replace, split, toLower
} from 'lodash';
import { mapGetters } from 'vuex';
import { clinicianName, respondentName } from 'service/display-name/display-name';
import { warn } from 'service/log/log';
import cardControls from 'core/engine/card/services/card-controls';
import $ from 'jquery';
import { View } from 'backbone.marionette';
import store, { APP } from 'store';

const VIDEO_FIX_DELAY = 500; // time needed to recalculate `video` element absence bug
let cachedWebRtc;
let unsubscribe = noop;
const marionetteDestroy = View.prototype.destroy;
const $fakeVideo = $('<div />').appendTo('body');
const CARD_NAME = 'video-chat';

export default TileView.extend({
	title: t('Live video stream'),
	acl: [{
		checkpoint: 'administrator.respondents.video-chat',
		op: 'READ'
	}],
	features: () => store.getters.userType === 'respondent' ?
		['RESPONDENT_VIDEO_CHAT'] :
		true,
	instant: true,

	cardData: () => ['treatmentId'],

	onBeforeRender () {
		// M I G H T Y unkillability (`fixBackgroundAudio` related, see somewhere below)
		this.cardContext().set('G O D L I K E', true);
		View.prototype.destroy = noop;
	},

	Vue: (tile) => ({
		mounted () {
			if (tile.treatmentId) {
				this.supported && this.initWebRTC();
			}

			this.fixBackgroundAudio();

			if (this.fullScreenSupport) {
				screenfull.on('change', () => {
					this.fullScreen = screenfull.isFullscreen;
				});
			}
		},

		data () {
			return {
				me: this.$store.state.user.userId,
				manualDisconnect: false,
				controlsVisible: false,
				userListVisible: false,
				connected: false,
				audioEnabled: true,
				videoEnabled: true,
				fullScreen: false,
				alreadyInRoom: false,
				hasAccess: true,
				ready: {
					call: false,
					handleConnection: false
				},
				silent: false,
				supported: Modernizr.webrtc,

				phrases: {
					establishing: t('Establishing connection\u2026'),
					waiting: t('Waiting for others to join\u2026'),
					alreadyInRoom: t('This chat is already opened in another tab'),
					noAccess: t('You have no access to this treatment'),
					// eslint-disable-next-line max-len
					notSupported: t('The video chat is unfortunately not supported by this web browser. We are sorry for the inconvenience.'),
					disconnected: t('Disconnected from the video chat')
				}
			};
		},

		computed: {
			...mapGetters('videoChat', ['peers']),
			peerLengthClassName: ({ peers }) => `connected-peers--${peers.length - 1}`,

			establishingConnection: (
				{ connected, manualDisconnect, hasAccess, alreadyInRoom }
			) => !connected && !manualDisconnect && hasAccess && !alreadyInRoom,

			onePeer: ({ connected, peers }) => connected && peers.length <= 1,

			isClinician: ({ $store }) => $store.state.user.type === 'clinician',
			isRespondent: ({ $store }) => $store.state.user.type === 'respondent',
			fullScreenSupport: () => screenfull.isEnabled,

			waiting: (
				{ alreadyInRoom, hasAccess, onePeer }
			) => onePeer && !alreadyInRoom && hasAccess,

			toggleControlsTitle: ({ controlsVisible }) => controlsVisible ?
				t('Hide controls') :
				t('Show controls'),

			toggleCallTitle: ({ connected }) => connected ? t('Leave call') : t('Join call'),

			toggleMicTitle: ({ audioEnabled }) => audioEnabled ? t('Mute') : t('Unmute'),

			toggleVideoTitle: ({ videoEnabled }) => videoEnabled ?
				t('Disable video') :
				t('Enable video'),

			toggleFullScreenTitle: ({ fullScreen }) => fullScreen ?
				t('Exit full screen') :
				t('Enter full screen'),

			toggleUserListTitle: ({ userListVisible }) => userListVisible ?
				t('Hide connected users list') :
				t('Show connected users list')
		},

		methods: {
			destroyCachedSession () {
				if (cachedWebRtc) {
					cachedWebRtc.stopLocalVideo();
					cachedWebRtc.leaveRoom();
				}
			},

			initWebRTC () {
				this.destroyCachedSession();
				const currentUrl = replace(window.location.href, window.location.hash, '');
				const root = compact(split(currentUrl, '/'));
				const baseUrl = root.slice(0, 2).join('//'); // -> 'https://tng.checkware.external'

				const appDir = config().appDir === '../' ?
					`/${root.slice(2, root.length).join('/')}` : // -> '/clinician-app'
					config().appDir; // -> '/v2/helium/dev'

				this.webrtc = cachedWebRtc = new SimpleWebRTC({
					url: baseUrl,
					socketio: {
						transports: ['websocket'],
						reconnect: false,
						path: appDir + config().webSocketsPath,
						query: {
							portal: this.$store.state.user.type
						}
					},
					localVideoEl: this.$refs.currentUserVideo,
					remoteVideosEl: this.$refs.calleeUserVideo,
					autoRequestMedia: true,
					media: {
						audio: true,
						video: {
							facingMode: 'user', // prefer the front camera
							// Lower frame-rates may be desirable in some cases, like
							// WebRTC transmissions with bandwidth restrictions
							frameRate: { ideal: 10, max: 15 }
						}
					},
					localVideo: {
						autoplay: true,
						mirror: false, // in IOS it also flips play/pause icon -> never flip it
						muted: true
					}
				});

				// called when user accepts/rejects camera/mic permissions
				this.webrtc.on('readyToCall', () => {
					this.ready.call = true;
					this.initCall();
				});

				this.webrtc.on('mute', (peer) => {
					this.toggleMute({ peer, mute: true });
				});

				this.webrtc.on('unmute', (peer) => {
					this.toggleMute({ peer, mute: false });
				});

				this.bindConnectionEvents({
					alreadyInRoom: this.onAlreadyInRoom,
					disconnect: this.onDisconnect,
					noAccessToTreatment: this.onNoAccessToTreatment,
					notAuthorized: this.onNotAuthorized,
					readyToHandleConnections: this.onReadyToHandleConnections,
					reconnecting: this.onReconnecting,
					reconnect: this.onReconnect,
					userJoined: this.onUserJoined,
					userLeft: this.onUserLeft,
					usersUpdate: this.onUsersUpdate
				});
			},

			bindConnectionEvents (events) {
				forEach(events, (callback, eventName) => {
					this.webrtc.connection.on(eventName, callback.bind(this));
				});
			},

			toggleMute ({ peer, mute }) {
				this.$store.dispatch('videoChat/updatePeer', { peer, mute });
			},

			onUsersUpdate (users) {
				this.$store.dispatch('videoChat/updatePeers', users);
			},

			onUserJoined (user) {
				if (user.type === 'CLINICIAN') {
					this.notice(t(`Clinician [{user}] has joined this room`, {
						user: clinicianName(user)
					}));
				} else {
					this.notice(t(`Respondent [{user}] has joined this room`, {
						user: respondentName(user)
					}));
				}

				this.fixMissingVideo(user);
			},

			// @see Defect #11448
			// Hack that detects unwanted absence of a video element and does a reconnect behind
			// the scenes to mount it
			fixMissingVideo: debounce(function (user) {
				if (+user.id !== +this.me) {
					delay(() => {
						if (!this.$refs.calleeUserVideo.querySelector('video')) {
							this.silent = true;
							this.stopCall();
							this.refreshMicAndVideo(VIDEO_FIX_DELAY);

							delay(() => {
								this.resumeCall();
								this.silent = false;
							}, VIDEO_FIX_DELAY);
						}
					}, VIDEO_FIX_DELAY);
				}
			}, 10 * VIDEO_FIX_DELAY, { leading: true, trailing: false }),

			// @see Defect #11395
			// Hack that allows to reconnect when connection is lost
			// (e.g. due to network problems), this can be reworked to not close the card
			fixReconnection () {
				this.disconnect();

				if (this.isVisible() && !this.manualDisconnect && !this.silent) {
					cardControls.closeCard();
					setTimeout(() => {
						cardUrl.openCard(CARD_NAME, { treatmentId: tile.treatmentId });
					}, 1000);

					this.notice(t('Connection lost'));
				}
			},

			// @see Defect #11475
			// Hack that allows to hear audio when leaving the card by mounting invisible video el
			fixBackgroundAudio () {
				unsubscribe();
				$fakeVideo.empty();
				unsubscribe = this.$store.subscribe((mutation, state) => {
					if (
						mutation.type === 'setCardData' &&
						get(state, 'cardData.cardName') !== CARD_NAME &&
						this.webrtc
					) {
						const $videoEl = $(this.webrtc.getRemoteVideoContainer()).hide();
						$fakeVideo.append($videoEl);
						View.prototype.destroy = marionetteDestroy;
					}
				});
			},

			onUserLeft (user) {
				if (user.type === 'CLINICIAN') {
					this.notice(t(`Clinician [{user}] has left this room`, {
						user: clinicianName(user)
					}));
				} else {
					this.notice(t(`Respondent [{user}] has left this room`, {
						user: respondentName(user)
					}));
				}
			},

			onReadyToHandleConnections () {
				this.ready.handleConnection = true;
				this.initCall();
			},

			onNotAuthorized () {
				warn('not authorized');
			},

			onAlreadyInRoom () {
				this.alreadyInRoom = true;
			},

			onNoAccessToTreatment () {
				this.hasAccess = false;
			},

			onDisconnect () {
				this.fixReconnection();
			},

			onReconnecting (attempt) {
				if (!this.isVisible()) {
					return;
				}

				if (attempt >= 5 || this.$store.getters[APP.DETACHED]) {
					this.disconnect();
					this.manualDisconnect = true;
					this.notice(t('Disconnected'));
					return;
				}
				this.notice(t('Reconnecting\u2026'));
			},

			onReconnect () {
				if (!this.isVisible()) {
					return;
				}
				this.notice(t('Reconnected'));
			},

			initCall: debounce(function () {
				if (!this.connected && this.ready.call && this.ready.handleConnection) {
					this.alreadyInRoom = false;
					this.webrtc.joinRoom(+tile.treatmentId, () => {
						this.connected = true;
					});
				}
			}, 500),

			toggleControls () {
				this.controlsVisible = !this.controlsVisible;
			},

			toggleMic () {
				if (this.audioEnabled) {
					this.audioEnabled = false;
					this.webrtc.mute();
				} else {
					this.audioEnabled = true;
					this.webrtc.unmute();
				}
			},

			refreshMedia (mediaType, wait = 200) {
				const toggle = {
					video: 'toggleVideo',
					mic: 'toggleMic'
				}[mediaType];

				for (let i = 1; i <= 2; i++) {
					delay(() => {
						this[toggle]();
					}, wait * i);
				}
			},

			refreshMicAndVideo (wait = 500) {
				this.refreshMedia('mic', wait);
				this.refreshMedia('video', wait);
			},

			toggleVideo () {
				if (this.videoEnabled) {
					this.disableVideo();
				} else {
					this.enableVideo();
				}
			},

			disableVideo () {
				this.videoEnabled = false;
				this.webrtc.pauseVideo();
			},

			enableVideo () {
				this.videoEnabled = true;
				this.webrtc.resumeVideo();
			},

			toggleFullScreen () {
				screenfull.isFullscreen ?
					screenfull.exit() :
					screenfull.request(this.$refs.videoChat);
			},

			toggleUserList () {
				this.userListVisible = !this.userListVisible;
			},

			toggleCall () {
				if (this.connected) {
					this.manualDisconnect = true;
					this.stopCall();

				} else {
					this.resumeCall();
					this.manualDisconnect = false;
				}

			},

			stopCall () {
				this.disconnect();
				this.webrtc.stopLocalVideo();
			},

			resumeCall () {
				this.initWebRTC();
				this.refreshMicAndVideo();
			},

			disconnect () {
				this.close();
				this.connected = false;
				this.$store.dispatch('videoChat/resetPeers');
			},

			close () {
				this.webrtc.leaveRoom();
				this.webrtc.disconnect();
			},

			buttonTitle (item) {
				return {
					toggleControls: this.toggleControlsTitle,
					toggleCall: this.toggleCallTitle,
					toggleMic: this.toggleMicTitle,
					toggleVideo: this.toggleVideoTitle,
					toggleFullScreen: this.toggleFullScreenTitle,
					toggleUserList: this.toggleUserListTitle
				}[item];
			},

			buttonClass (item) {
				return {
					toggleCall: this.connected ?
						'video-chat__action-button--leave' :
						'video-chat__action-button--join'
				}[item];
			},

			iconClass (item) {
				return {
					toggleFullScreen: this.fullScreen ?
						'icon--exit-full-screen' :
						'icon--enter-full-screen',
					toggleMic: this.audioEnabled ?
						'icon--mic' :
						'icon--mic-slashed',
					toggleVideo: this.videoEnabled ?
						'icon--cam' :
						'icon--cam-slashed'
				}[item];
			},

			userType (user) {
				return {
					clinician: 'icon--clinician',
					respondent: 'icon--respondent'
				}[isUndefined(user) ? this.$store.state.user.type : toLower(user.type)];
			},

			notice (...args) {
				if (this.isVisible() && !this.silent) {
					cwalert.notice(...args);
				}
			},

			isVisible () {
				return get(this.$store.state, 'cardData.cardName') === CARD_NAME;
			}
		},

		template: `
			<section
				class="video-chat"
				:class="{'video-chat--fullscreen': fullScreen}"
				ref="videoChat"
				v-if="supported"
			>
				<div
					class="video-chat__control-panel"
					:class="{'video-chat__control-panel--expanded': controlsVisible}"
				>
					<button
						class="video-chat__action-button video-chat__action-button--more"
						:class="{'video-chat__action-button--disabled': !hasAccess}"
						:title="buttonTitle('toggleControls')"
						@click="toggleControls"
						:disabled="!hasAccess"
					><i class="fa icon--more-actions video-chat__icon" /></button>
					<div class="video-chat__control-list" v-if="controlsVisible">
						<button
							class="video-chat__action-button"
							:class="[buttonClass('toggleCall')]"
							:title="buttonTitle('toggleCall')"
							@click="toggleCall"
						><i class="fa icon--phone video-chat__icon" /></button>
						<button
							class="video-chat__action-button"
							:title="buttonTitle('toggleMic')"
							:class="{'video-chat__action-button--disabled': !ready.call}"
							:disabled="!ready.call"
							@click="toggleMic"
						>
							<i
								class="fa video-chat__icon"
								:class="iconClass('toggleMic')"
							/>
						</button>
						<button
							class="video-chat__action-button"
							:title="buttonTitle('toggleVideo')"
							:class="{'video-chat__action-button--disabled': !ready.call}"
							:disabled="!ready.call"
							@click="toggleVideo"
						>
							<i
								class="fa video-chat__icon"
								:class="iconClass('toggleVideo')"
							/>
						</button>
						<button
							v-if="fullScreenSupport"
							class="video-chat__action-button"
							:title="buttonTitle('toggleFullScreen')"
							@click="toggleFullScreen"
						>
							<i
								class="fa video-chat__icon"
								:class="iconClass('toggleFullScreen')"
							/>
						</button>
						<button
							class="video-chat__action-button"
							:title="buttonTitle('toggleUserList')"
							@click="toggleUserList"
						><i class="fa icon--users video-chat__icon"></i></button>

						<ul class="video-chat__users-list" v-if="userListVisible">
							<li
								v-if="!peers.length"
								class="video-chat__users-list-item"
								v-translate
							>No data</li>
							<li v-for="peer in peers" class="video-chat__users-list-item">
								<i
									class="video-chat__user-icon fa"
									:class="userType(peer)"
								/>
								<span class="video-chat__user">
									{{peer.displayName}}
									<span
										v-if="peer.id == me"
										class="video-chat__user-badge"
									>(${t(`It's you`)})</span>
								</span>
								<img
									class="
										video-chat__user-icon-svg
										video-chat__user-icon-svg--cam-muted
									"
									src="images/video-chat/cam-slashed.svg"
									v-show="peer.id == me ? !videoEnabled : peer.videoMuted"
								/>
								<img
									class="video-chat__user-icon-svg"
									src="images/video-chat/cam.svg"
									v-show="peer.id == me ? videoEnabled : !peer.videoMuted"
								/>
								<img
									class="video-chat__user-icon-svg"
									src="images/video-chat/mic-slashed.svg"
									v-show="peer.id == me ? !audioEnabled : peer.audioMuted"
								/>
								<img
									class="video-chat__user-icon-svg"
									src="images/video-chat/mic.svg"
									v-show="peer.id == me ? audioEnabled : !peer.audioMuted"
								/>
							</li>
						</ul>
					</div>
				</div>

				<div
					ref="calleeUserVideo"
					class="video-chat__wrapper video-chat__wrapper--main"
					:class="peerLengthClassName">
					<p
						v-if="waiting"
						class="video-chat__loader"
					>
						<span v-loader-spinner class="video-chat__loader-icon" />
						<span class="video-chat__loader-label">{{phrases.waiting}}</span>
					</p>
					<p
						v-if="establishingConnection"
						class="video-chat__loader"
					>
						<span v-loader-spinner class="video-chat__loader-icon" />
						<span class="video-chat__loader-label">{{phrases.establishing}}</span>
					</p>
					<p
						v-if="alreadyInRoom"
						class="video-chat__loader-label"
					>{{phrases.alreadyInRoom}}</p>
					<p
						v-if="!hasAccess"
						class="video-chat__loader-label"
					>{{phrases.noAccess}}</p>
					<p
						v-if="manualDisconnect && !connected"
						class="video-chat__loader-label"
					>{{phrases.disconnected}}</p>
				</div>
				<div class="video-chat__wrapper video-chat__wrapper--small">
					<video class="video-chat__current-user-video" ref="currentUserVideo"></video>
					<span
						v-if="!audioEnabled"
						class="icon--mic-slashed video-chat__mute-icon"
						title="${t(`You're muted`)}"
					/>
				</div>
			</section>
			<p v-else>
				{{phrases.notSupported}}
			</p>
		`,

		beforeDestroy () {
			if (this.fullScreenSupport) {
				screenfull.off('change');
			}
		}
	})
});
