import repository from 'repository';
import { set } from '../__helpers/mutations';
import { exact } from '../__helpers/getters';
import prefixify from '../__helpers/prefixify';

const prefix = prefixify('systemEventTypes');

const DATA = 'data';
const SET = 'set';
const INIT = 'init';

export const SYSTEM_EVENT_TYPES = {
	DATA: prefix(DATA),
	INIT: prefix(INIT)
};

export default {
	namespaced: true,

	state: {
		[DATA]: []
	},

	getters: {
		...exact([DATA])
	},

	mutations: {
		[SET]: set()
	},

	actions: {
		[INIT]: async ({ commit }) => {
			const data = await repository.getSystemEventsTypes();
			commit(SET, data.type);
		}
	}
};
