import urlify from 'service/urlify/urlify';
import { param } from 'jquery';
import { assign, forEach, isNaN } from 'lodash';

const prefix = 'backend-commons/treatment';

const activitiesFilter = function ({ actors = [], events = [], limit = 10, start = 0 }) {
	const urlParams = { limit, start };

	actors.length && assign(urlParams, { 'filterIn[actor]': actors.join(',') });
	events.length && assign(urlParams, { 'filterIn[event]': events.join(',') });

	return decodeURIComponent(param(urlParams));
};

const dataSource = (dataSources) => {
	const params = [];

	// convert proper keynames to the ones that b💩ckend accepts
	const rename = (key) => {
		if (key === 'roleType') {
			return 'typeRole';
		}
		return key;
	};

	forEach(dataSources, (sources, index) => {
		// eslint-disable-next-line lodash/prefer-map
		forEach(sources, (value, key) => {
			params.push(`customCalculationColumn[${index}][${rename(key)}]=${value}`);
		});
	});

	return params.join('&');
};

export default {
	single: {
		url: `${prefix}/`
	},

	singleTreatment: (treatmentId = '') => `${prefix}/${treatmentId}`,

	list: {
		url: 'backend-commons/treatment/'
	},

	removeTreatment: {
		url: (treatmentId) => `${prefix}/${treatmentId}`
	},

	search: {
		url: (params, opts, dataSources) =>
			`${prefix}/?${urlify(params, opts)}&${dataSource(dataSources)}`
	},

	byRespondentId: {
		url: (respondent) => `${prefix}/?${urlify({ 'respondent.id': respondent })}`
	},

	byMainRespondentId: {
		url: (respondent) => `${prefix}/?${urlify({ respondent })}`
	},

	attribute: {
		url () {
			return `${prefix}/${this.get('treatment')}/attribute/${this.get('typeAttribute')}/`;
		}
	},

	listAttributes: {
		url: (treatmentId) => `${prefix}/${treatmentId}/attribute/`
	},

	listClinicians: {
		url: (treatmentId) => `${prefix}/${treatmentId}/clinician/`
	},

	singleClinician: {
		url: (treatmentId, clinicianId = '') =>
			`${prefix}/${treatmentId}/clinician/${clinicianId}`
	},

	singleRole: {
		url: (treatmentId, id) => {
			const suffix = !isNaN(id) ? `/${id}` : '/';
			return `${prefix}/${treatmentId}/role${suffix}`;
		}
	},

	listRoles: {
		url: (treatmentId) => `${prefix}/${treatmentId}/role/`
	},

	listWithRoles: {
		url: (treatmentId, dataSources) =>
			`backend-commons/type-role-has-role/${treatmentId}?include=stats&${
				dataSource(dataSources)
			}`
	},

	listWithoutRoles: {
		url: (treatmentId, dataSources) =>
			`backend-commons/type-role-has-role/${treatmentId}/not-assigned-stats?${
				dataSource(dataSources)
			}`
	},

	terminatePlans: {
		url: (treatmentId) => `${prefix}/${treatmentId}/assessment-plan/terminate/`
	},

	myList: {
		url: (params, dataSources, sortBy) =>
			`${prefix}/current-clinician-treatments?${
				decodeURIComponent(param(params))
			}&${dataSource(dataSources)}&sort=${sortBy}`
	},

	treatmentActivities: {
		url: (params) => `${prefix}/${params.treatmentId}/activity/?${activitiesFilter(params)}`
	},
	deniedCliniciansInTreatment: {
		url: (treatmentId) => `${prefix}/${treatmentId}/administrator-denial/`
	},
	deniedClinicianInTreatment: {
		url: ({ treatmentId, denialId }) =>
			`${prefix}/${treatmentId}/administrator-denial/${denialId}`
	},
	consentsInTreatment: {
		url: ({ treatmentId }) => `${prefix}/${treatmentId}/consent-instance/`
	},
	treatmentConsentTemplates: {
		url: ({ treatmentId, respondentId }) =>
			`${prefix}/${treatmentId}/respondent/${respondentId}/available-consent/`
	},

	treatmentPrograms: () =>
		`backend/treatment-programs`,

	treatmentProgram: (treatmentProgramId) =>
		`backend/treatment-programs/${treatmentProgramId}`,

	treatmentProgramModules: (treatmentProgramId) =>
		`backend/treatment-programs/${treatmentProgramId}/modules`,

	treatmentProgramModule: (treatmentProgramId, moduleId) =>
		`backend/treatment-programs/${treatmentProgramId}/modules/${moduleId}`
};
