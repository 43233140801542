import SingleEntity from '../shared/single-entity';
import urls from './urls';

export default SingleEntity.extend({
	urlRoot: urls.singleGroup.url,
	eventNamespace: 'respondent-group',
	idAttribute: 'respondentGroupId',
	defaults: {
		respondentGroupName: '',
		respondentGroupCategory: '',
		respondentGroupIsCommon: false
	}
});

