import {
	assign, filter, find, findIndex, forEach, includes, map, merge, reduce, split, transform
} from 'lodash';

export const updatePaths = (obj) => obj.children ?
	transform(obj.children, (result, child, index) => {
		child.path = obj.root ? `children.${index}` : `${obj.path}.children.${index}`;
		child.children = updatePaths(child);
		result.push(child);
	}, []) :
	null;

export const addChild = ({
	copyPath,
	from,
	parent,
	processed,
	update
}) => {
	const elementIndex = copyPath === 'root' ?
		parent.children.length - 1 :
		findIndex(parent.children, { path: copyPath });
	const finalIndex = elementIndex + 1;
	const toPaste = update({
		element: merge({}, processed, { checked: false }),
		from,
		noInheritance: true,
		origin: parent
	});
	parent.children.splice(finalIndex, 0, toPaste);
	parent.children = updatePaths(parent);
	return parent;
};

export const emptyNode = (parent, path) => ({ parent, path, element: {} });

export const mirrorList = ({ elements, path, mirrors = [] }) =>
	transform(elements, (result, element) => {
		if (element.mirror === path) {
			result.push(element.path);
		}

		if (element.children) {
			mirrorList({ elements: element.children, path, mirrors });
		}
	}, mirrors);

export const mirrorsLength = (element, path) =>
	reduce(element.children, (result, child) => {
		if (child.mirror && child.mirror === path) {
			return result + 1;
		}
		return result;
	}, 0);

export const node = ({ parent, path, element = {} }) => parent.children ?
	transform(parent.children, (result, child) => {
		if (child.path === path) {
			result.element = child;
			result.parent = parent;
			return false;
		}

		if (child.children) {
			node({ parent: child, path, element });
		}
		return result;
	}, element) :
	element;

export const mirrorPaths = ({ elements, list = [], root = false }) => {
	if (elements.children) {
		return transform(elements.children, (result, child) => {
			if (child.mirror) {
				result.push(child.path);
				// if only roots are required no need to touch children if already found
			} else if (root) {
				mirrorPaths({ elements: child, list, root: true });
			}

			if (!root) {
				mirrorPaths({ elements: child, list });
			}
		}, list);
	}
	return list;
};

export const targetPath = (path) => path === 'children' ? path : `${path}.children`;

export const insertElements = ({
	children,
	elementList,
	from,
	processed,
	update
}) => {
	const insert = (children) => transform(children, (result, child) => {
		if (find(elementList, { path: child.path })) {
			processed.path = `${child.path}.children.${child.children.length}`;
			const toPaste = update({
				element: merge({}, processed, { checked: false }),
				from,
				noInheritance: true,
				origin: child
			});
			toPaste.children = updatePaths(processed);

			if (child.mirror) {
				toPaste.mirror = `${child.mirror}.children.${child.children.length}`;
			}
			child.children.push(toPaste);
		} else if (child.children) {
			child.children = insert(child.children);
		}
		result.push(child);
	}, []);
	return insert(children);
};

export const parentPaths = (str, paths = []) => {
	const path = str.slice(0, str.lastIndexOf('.children'));

	if (path === 'children.') {
		return [];
	}
	paths.push(path);

	if (includes(path, '.children')) {
		return parentPaths(path, paths);
	}
	return paths;
};

export const removeLastPathElement = (path) => {
	const splitted = split(path, '.');
	return splitted.splice(0, split(path, '.').length - 1).join('.');
};

export const removeChildren = (children, selected) => reduce(children, (result, child) => {
	const list = filter(result, (single) => single !== child.path);

	if (child.children) {
		return removeChildren(child.children, list);
	}
	return list;
}, selected);

export const selectedChildren = (item, selected) => reduce(selected, (result, child) => {
	if (!result) {
		return false;
	}
	return child.substring(0, item.path.length) === item.path;
}, true);

export const setChildrenMirrors = (children) => map(children, (child) => {
	const newChild = assign({}, child, { mirror: child.path, warning: true });

	if (child.children) {
		newChild.children = setChildrenMirrors(child.children);
	}
	return newChild;
});

export const updateWarnings = (item, warnings) => {
	const newWarnings = [...warnings];
	const index = findIndex(newWarnings, (warningPath) => warningPath === item.path);

	if (item.warning && index === -1) {
		newWarnings.push(item.path);
	} else if (!item.warning && index !== -1) {
		newWarnings.splice(index, 1);
	}
	return newWarnings;
};

export const traverse = ({ parent, path = 'children.', mirror = false }) => {
	forEach(parent.children, (item, index) => {
		if (mirror && item.type === 'folder') {
			item.mirrorName = item.fields.name;
		}
		item.path = path + index;

		if (item.children) {
			traverse({ parent: item, path: `${item.path}.children.` });
		}
	});
	return parent;
};

export const updateChildrensMirrors = (children, parentPath) =>
	map(children, (child, index) => {
		const newChild = assign({}, child, { mirror: parentPath + index });

		if (child.children) {
			newChild.children = updateChildrensMirrors(child.children, newChild.mirror);
		}
		return newChild;
	});

export const updateList = ({ origin, update, parentContext }) => {
	const fields = origin.fields;
	const updateChildren = (children) => reduce(children, (result, child) => {
		const context = update.mirror({ origin, parentContext });
		child.fields = assign(child.fields, context);

		if (child.type !== 'folder')  {
			child.fields.name = fields.customName ? fields.name : update.name(child.fields);
		} else {
			child.children = updateChildren(child.children);
		}

		result.push(child);
		return result;
	}, []);

	if (origin.children) {
		origin.children = updateChildren(origin.children);
	}
	return origin;
};

export const updateMirrors = ({ changeIndex, parent, rows }) =>
	reduce(parent.children, (result, child, index) => {
		// if there is any mirror below the pasted row its value needs to be updated
		if (index >= changeIndex && child.mirror) {
			const splitted = split(child.mirror, '.');
			const splittedValue = parseInt(splitted[splitted.length - 1], 10);
			splitted[splitted.length - 1] = rows ? splittedValue - rows : splittedValue + 1;
			const updatedMirror = assign({}, child, { mirror: splitted.join('.') });

			if (child.children) {
				updatedMirror.children = updateChildrensMirrors(
					updatedMirror.children,
					`${splitted.join('.')}.children.`
				);
			}
			result.push(updatedMirror);
		} else {
			result.push(child);
		}
		return result;
	}, []);

export const validateFields = ({ elements, entry, types }) =>
	reduce(elements, (result, element) => {
		if (element.type !== 'folder' && !element.root) {
			const additionalField = find(types.additionalTypes, (type) =>
				type === element.type);
			const mandatoryFields = additionalField ?
				[...types.additional, ...types.mandatory] :
				types.mandatory;
			return reduce(mandatoryFields, (elementResult, field) =>
				element.fields[field] ? result : false, result);
		}

		if (element.children) {
			return validateFields({ elements: element.children, entry: result, types });
		}
		return result;
	}, entry);

export const warning = ({ item, state }) => {
	const compareNames = (element, name) => !!find(element.children, (child) => {
		if (child.children) {
			return compareNames(child, name);
		}
		return (child.fields.name === name && child.path !== item.path);
	});
	return compareNames(state, item.fields.name);
};
