import $ from 'jquery';

export default {
	single: {
		url: 'backend-commons/flag/'
	},
	list: {
		url: (params) => `backend-commons/flag/?${params ? $.param(params) : ''}`
	},
	byIsRaised: {
		url: 'backend-commons/flag/?isRaised=1'
	},
	byRespondentId: {
		url: (respondentId) => `backend-commons/flag/?respondent=${respondentId}&includeTestFlags=1`
	},
	byAssessmentInstanceId: {
		url: (assessmentInstanceId) => `backend-commons/flag/?includeTestFlags=1&assessmentInstance=${assessmentInstanceId}`
	},
	byNotifications: {
		url: 'backend-commons/flag/?notifications=1&newOnly=1'
	},
	reasonTemplates: {
		url: 'backend-commons/flag-unraise-reason/'
	},
	markAsSeen: {
		url: () => `backend-commons/assessmentinstance/rest/assessment_instance_flag/${this.getId()}`
	},
	byTreatmentId: {
		url: (treatmentId) => `backend-commons/flag/?treatment=${treatmentId}&includeTestFlags=1`
	}
};
