export const CONDITION = 'condition';
export const CONTINUE = 'continue';
export const CONTINUE_IF = 'continue-if';
export const END = 'end';
export const HIDE = 'hide';
export const NEW_PAGE = 'newPage';
export const PROGRESS = 'progress';
export const REDRAW = 'redraw';
export const SHOW = 'show';
export const STOP = 'stop';
export const TEXT_EXPRESSION = 'text-expression';
