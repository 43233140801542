/**
 * Get card context object of the current card.
 *
 * @function TileView.cardContext
 * @returns {Object} - Card context of the current card.
 * @deprecated
 * @example
 * export default TileView.extend({
 *     loaded: ({ tile }) => {
 *         tile.respondent = tile.cardContext().get('respondent');
 *     }
 * });
 */
export default function () {
	return this.__params.cardModel.get('context');
}
