import moment from 'moment';

const addLeadingZero = (number) => (`0${number}`).slice(-2);

export default function (tz1, tz2) {
	const diff = moment.tz(tz1).utcOffset() - moment.tz(tz2).utcOffset();

	if (diff === 0) {
		return '';
	}

	const h = addLeadingZero(Math.abs(diff) / 60);
	const m = addLeadingZero(Math.abs(diff) % 60);
	let difference = (diff < 0) ? '-' : '+';
	difference += `${h}:${m}`;

	return difference;
}
