import cardUrl from 'cwcardurl';
import canAccess from 'core/engine/card/services/can-access';
import { isObject, isString } from 'lodash';

/**
 * Adds a card link button for the specified card instance. Checks ACL for a card.
 *
 * @function appbar#addCardLink
 *
 * @param {object} opts
 * @param {string} opts.sourceCard - Name of the card which button should be placed on.
 * @param {object} opts.card - Target card.
 * @param {object} [opts.query] - Card query parameters.
 * @param {string} [opts.title] - Custom link title.
 * @param {string} [opts.icon] - Icon name.
 * @param {Function} [opts.click] - Callback for click.
 * @param {Function} [opts.onRender] - Callback called on render.
 *
 * @returns {appbar}
 */

export default function (options) {
	let sourceCard;
	let opts = options;

	// @TODO(2015-10-15): remove when all calls are migrated to non-deprecated usage
	if (arguments.length === 2 && isString(opts)) {
		sourceCard = opts;
		opts = arguments[1]; // eslint-disable-line prefer-rest-params

	} else {
		sourceCard = opts.sourceCard;
	}
	let targetCardName = '';
	const card = opts.card;

	if (isString(card)) {
		targetCardName = card;

	} else if (isObject(card)) {
		targetCardName = card.name;
	}

	if (!card || !canAccess(targetCardName)) {
		return this;
	}

	const title = opts.title || card.title || '';
	const query = opts.query || {};
	const url = cardUrl.buildUrl(targetCardName, query);

	this.addButton({
		sourceCard,
		title,
		url,
		click: opts.click,
		onRender: opts.onRender,
		icon: opts.icon
	});

	return this;
}
