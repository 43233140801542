import { assign, get, map, sortBy, omitBy, reduce, transform } from 'lodash';
import ACL from 'service/acl/acl';
import repository from 'repository';
import { applyTransform, mapEntity, parseStr, status } from './helpers';
import { set } from '../__helpers/mutations';
import datetime from 'service/datetime/datetime';
import actions from './actions';

export const agenda = {
	namespaced: true,
	modules: { actions }
};

// factory function returning new store module since there can be more than one
export const store = () => ({
	namespaced: true,
	state: {
		data: [],
		config: {},
		variables: {},
		dateRange: {
			from: '',
			to: ''
		},
		loading: false,
		timezone: 'UTC'
	},

	getters: {
		loading: (state) => state.loading,
		agendaData: (state) => omitBy(state.config.agendaData, ['disabled', true]),
		types: (state, getters) => reduce(
			getters.agendaData,
			(acc, val, key) => {
				const aclEntry = val.source.acl;
				const accessible = aclEntry ? ACL.checkAccess(aclEntry) : true;

				if (accessible) {
					acc.push({
						name: key,
						label: get(val, 'label', '')
					});
				}

				return acc;
			},
			[]
		),

		entities: (state, getters) => state.loading ?
			[] :
			transform(state.data, (result, entities, type) => {
				const agendaTypeData = getters.agendaData[type];

				if (!agendaTypeData) {
					return [];
				}

				const mapping = agendaTypeData.source.mapping;

				result.push(...map(entities, (entity) => assign(
					mapEntity({ entity, mapping }),
					{ itemType: type },
					{ typeLabel: get(agendaTypeData, 'label', '') }
				)));

				return result;
			}, []),

		items: (state, getters) => {
			const entities = map(getters.entities, (entity) => {
				const rules = getters.agendaData[entity.itemType].transform;
				const layout = getters.agendaData[entity.itemType].layout;
				const transformed = applyTransform({
					entity,
					rules,
					vars: state.variables,
					translate: getters.settings.translate,
					timezone: state.timezone
				});

				return assign(
					{},
					entity,
					transformed,
					{ status: status(transformed) },
					{ layout }
				);
			});
			return sortBy(entities, ['datetime', 'itemType', 'id']);
		},

		settings: (state) => get(state.config, 'agendaSettings'),

		sources: (state, getters) => transform(getters.agendaData, (sources, cfg, name) => {
			if (!ACL.checkAccess(cfg.source.acl)) {
				return sources;
			}

			sources[name] = parseStr(cfg.source.url, state.variables);

			const dateRange = (type) => state.dateRange[type] ?
				`filter[${cfg.source.dateRangeKey}][${type}]=${state.dateRange[type]}` :
				'';

			if (getters.settings.limitByRange && cfg.source.dateRangeKey) {
				sources[name] += `${dateRange('from')}&${dateRange('to')}`;
			}

			return sources;
		}, {}),

		addable: (state, getters) => transform(getters.agendaData, (addable, cfg, type) => {
			if (!cfg.add) {
				return true;
			}
			const args = transform(cfg.add.args, (result, arg, key) => {
				result[key] = parseStr(arg, state.variables);
			}, cfg.add.args);

			const hasAccess = ACL.checkAccess(cfg.add.acl);

			if (hasAccess) {
				addable.push({
					name: type,
					...assign({}, cfg.add, { args })
				});
			}

			return addable;
		}, [])
	},

	mutations: {
		resetData: (state) => {
			state.config.agendaData = {};
		},
		setData: set(),
		setConfig: set('config'),
		setVariables: set('variables'),
		setDateRange: set('dateRange'),
		setLoading: set('loading'),
		setTimezone: set('timezone')
	},

	actions: {
		init: ({ commit, dispatch, getters }, { config, variables, timezone }) => {
			commit('resetData');
			commit('setConfig', config);
			commit('setVariables', variables);
			commit('setTimezone', timezone);

			if (!getters.settings.limitByRange) {
				return dispatch('fetch');
			}
			return {};
		},

		fetch: ({ commit, getters }) => repository.agenda(getters.sources).then((data) => {
			commit('setData', data);
		}),

		fetchByDateRange: ({ state, commit, dispatch }, { from, to } = {}) => {
			if (from && to) {
				commit('setDateRange', {
					from: datetime(from).toUtcFromTimezone(state.timezone).utcString,
					to: datetime(to).toUtcFromTimezone(state.timezone).utcString
				});
			}

			commit('setLoading', true);
			return dispatch('fetch').then(() => {
				commit('setLoading', false);
			});
		},

		customRequest: (context, request) => repository.agendaAction(request)
	}
});
