import { when } from 'jquery';

export default ({ rootState, dispatch }, { actionName, resource, action }) =>
	resource.then((...args) => {
		const { respondentId, treatmentId } = action(...args);

		dispatch('respondent/setId', respondentId, { root: true });
		dispatch('treatment/setId', treatmentId, { root: true });

		dispatch('addCurrentCardActions', {
			actions: [['treatment/init', {}, { root: true }]],
			source: `store-action:${actionName}`,
			type: 'store-action'
		}, { root: true });

		const promises = rootState.currentCard.promises[`store-action:${actionName}`] || [];

		return when(...promises);
	});
