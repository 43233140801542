import { assign, get } from 'lodash';
import repository from 'repository';
import initWith from '../__helpers/init-with';

export default {
	namespaced: true,

	state: {
		data: null,
		model: null
	},

	getters: {
		contentPackageInstanceId: (state, getters, rootState) =>
			get(rootState.cardData, 'urlParams.contentPackageInstanceId')
	},

	mutations: {
		set: (state, { contentPackageInstance, model }) => {
			state.data = contentPackageInstance;
			state.model = model;
		},

		reset: (state) => {
			state.data = null;
			state.model = null;
		},

		update: (state, payload) => {
			state.data = assign({}, state.data, payload);
			state.model.set(payload);
		}
	},

	actions: {
		init: (context, { contentPackageInstanceId } = { contentPackageInstanceId: 0 }) => {
			const { commit, getters } = context;
			const model =
				repository.contentPackageInstance(
					contentPackageInstanceId ||
					getters.contentPackageInstanceId
				);

			return initWith(context, {
				actionName: 'contentPackageInstance/init',
				resource: model,
				action: () => {
					const contentPackageInstance = model.toJSON();
					commit('set', { model, contentPackageInstance });

					return {
						treatmentId: contentPackageInstance.treatment,
						respondentId: contentPackageInstance.respondent.id
					};
				}
			});
		},

		update: ({ commit }, payload) => {
			commit('update', payload);
		},

		destroy: ({ commit, state }) => state.model.destroy().then(() => {
			commit('reset');
		})
	}
}
