export default function (value) {
	this.getRawValue = () => value;

	this.getBoolean = () => value !== 0;

	this.getNumber = () => parseFloat(value);

	this.getString = () => {
		if (value === false || typeof value === 'undefined' || isNaN(value)) {
			return '';
		}

		return '' + (Math.round(parseFloat(value) * 1000) / 1000);
	};

	this.toString = function () {
		return `\{Number:${this.getString()}}`;
	};
}

