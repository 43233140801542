<template>
	<ul>
		<li
			v-for="(row, index) in objVal"
			:key="index"
			class="edit-object__row edit-object__row--edit array-row__field"
		>
			<p class="array-row__index">
				{{index}}
			</p>
			<input
				v-for="(rowItem, i) in row"
				:key="i"
				v-trim
				type="text"
				:value="rowItem"
				:readonly="readonly"
				@blur="onArrayArrayValueChange(index, i, $event)"
				v-on="inputListeners"
			/>
			<button
				v-if="!readonly"
				v-translate
				class="edit-object__button--add edit-object__button--add-item"
				@click="addArrayArrayItem(index)"
			>editObject.addItem
			</button>
			<button
				v-if="!readonly"
				v-translate
				class="edit-object__button--remove edit-object__button--remove-item"
				@click="removeArrayArrayItem(index)"
			>editObject.removeLastItem
			</button>
			<button
				v-if="!readonly"
				class="edit-object__button edit-object__button--remove"
				@click="removeArrayArrayRow(index)"
			/>
		</li>
		<li class="edit-object__row edit-object__row--add array-row__field">
			<input
				v-for="(newItem, i) in newArrayArrayRow"
				:key="i"
				v-trim
				type="text"
				:readonly="readonly"
				@blur="onNewArrayArrayRowChange(i, $event)"
				v-on="inputListeners"
			/>
			<button
				v-if="!readonly"
				v-translate
				class="edit-object__button--add edit-object__button--add-item"
				@click="addNewArrayArrayItem"
			>editObject.addItem
			</button>
			<button
				v-if="!readonly"
				v-translate
				class="edit-object__button--remove edit-object__button--remove-item"
				@click="removeNewArrayArrayItem"
			>editObject.removeLastItem
			</button>
			<button
				v-if="!readonly"
				class="edit-object__button--add"
				@click="addArrayArrayRow"
			/>
		</li>
	</ul>
</template>

<script>
import Vue from 'vue';
import { cloneDeep, trim } from 'lodash';
import inputMixin from './__input-mixin';

export default Vue.component('arrayArrayInput', {
	mixins: [inputMixin],
	props: {
		objVal: {
			type: Array,
			required: true
		}
	},
	data: () => ({
		newArrayArrayRow: ['']
	}),

	methods: {
		onArrayArrayValueChange (index, i, e) {
			this.objVal[index][i] = trim(e.target.value);
			this.onValueChange({ path: this.path, value: this.objVal });
		},

		addArrayArrayRow () {
			const value = cloneDeep(this.objVal);
			value.push(cloneDeep(this.newArrayArrayRow));
			this.onValueChange({ path: this.path, value });
		},

		removeArrayArrayRow (index) {
			const value = cloneDeep(this.objVal);
			value.splice(index, 1);
			this.onValueChange({ path: this.path, value });
		},

		addArrayArrayItem (index) {
			this.objVal[index].push('');
			this.onValueChange({ path: this.path, value: this.objVal });
		},

		removeArrayArrayItem (index) {
			this.objVal[index].pop();
			this.onValueChange({ path: this.path, value: this.objVal });
		},

		addNewArrayArrayItem () {
			this.newArrayArrayRow.push('');
		},

		removeNewArrayArrayItem () {
			this.newArrayArrayRow.pop();
		},

		onNewArrayArrayRowChange (i, e) {
			this.newArrayArrayRow[i] = trim(e.target.value);
		}
	}
});
</script>

