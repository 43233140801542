<template>
	<div>
		<section
			v-for="({exercises, label}) in groupedExercises"
			:key="label"
			class="information-package-elements__container"
		>
			<h3 v-if="label" class="information-package-elements__title">
				{{label}}
			</h3>
			<ul class="generic-list__list information-package-elements">
				<li
					v-for="exercise in exercises"
					:key="exercise.id"
					class="generic-list__item assessment-list__item"
				>
					<a
						:class="exerciseClassName(exercise)"
						:href="url(exercise)"
					>
						<icon name="exercises" :title="$t('Exercises')" />
						<div
							class="
								generic-list__action-title-container
								information-package-element__action-title-container
							"
						>
							<p
								class="
									generic-list__action-title
									information-package-element__action-title
								"
							>
								{{exercise.elementVersion.alias}}
							</p>
							<span
								v-if="exercise.state === STATE_COMPLETED"
								class="information-package-element__run-once-more"
							>
								{{$t("You can do it more than once")}}
							</span>
							<p class="information-package-element__state-label">
								{{stateLabel(exercise)}}
							</p>
						</div>
					</a>
				</li>
			</ul>
		</section>
	</div>
</template>

<script>
import systemSettings from 'system-settings';
import {
	RESPONDENT_CONTENT_PACKAGE_INSTANCES
} from 'store/content-packages/rp-content-package-instances';
import { CONTEXTS } from 'store/general/contexts';
import { TYPES } from 'store/general/types';
import { RESPONDENT_EXERCISES } from 'store/content-packages/rp-exercises';
import { TREATMENT } from 'store/treatments/rp-treatment';
import cardUrl from 'cwcardurl';
import Icon from 'components/icon';
import { filter, get, map, size } from 'lodash';
import { STATE_COMPLETED } from 'modules/respondent-information-package/constants';
import t from 'translate';

export default {
	title: t('Exercises'),
	actions: () => [
		[RESPONDENT_CONTENT_PACKAGE_INSTANCES.INIT],
		[RESPONDENT_EXERCISES.INIT],
		systemSettings.getBoolean('ASSESSMENT_TYPES') ? [TYPES.INIT] : [CONTEXTS.INIT]
	],
	features: ['ENABLE_RESPONDENT_EXERCISES'],
	components: { Icon },
	data: () => ({
		STATE_COMPLETED
	}),
	computed: {
		groupBy: () => systemSettings.getBoolean('ASSESSMENT_TYPES') ? 'type' : 'context',
		rpExercises: ({ $store }) => $store.getters[RESPONDENT_EXERCISES.DATA],
		groups: ({ $store }) => systemSettings.getBoolean('ASSESSMENT_TYPES') ?
			$store.getters[TYPES.DATA] :
			$store.getters[CONTEXTS.DATA],
		groupedExercises: ({ rpExercises, groupBy, groups }) => {
			const array = [];

			const exercisesWithoutGroup = filter(rpExercises, (exercise) => {
				const cpInstance = exercise.instance;
				return !cpInstance[groupBy];
			});

			array.push({
				label: '',
				exercises: exercisesWithoutGroup
			});

			const exercisesWithGroup = map(groups, (group) => {
				const id = +group.id || +group.assessmentInstanceContextId;
				const exercisesArr = filter(rpExercises, (exercise) => {
					const cpInstance = exercise.instance;
					return +get(cpInstance[groupBy], 'id') === id;
				});

				return {
					label: group.assessmentInstanceContextLabel || group.label,
					exercises: exercisesArr
				};
			});

			array.push(...exercisesWithGroup);

			return filter(array, (group) => size(group.exercises));
		}
	},
	methods: {
		exerciseClassName: (exercise) => `generic-list__action
				information-package-element__action
				information-package-element--${exercise.state}`,
		url (exercise) {
			return cardUrl.buildUrl('information-package-element', {
				informationPackage: exercise.instance.id,
				id: exercise.id,
				treatmentId: this.$store.getters[TREATMENT.ID]
			});
		},
		stateLabel (exercise) {
			return {
				NOT_STARTED: this.$t('Not started'),
				IN_PROGRESS: this.$t('In progress'),
				COMPLETED: this.$t('Completed')
			}[exercise.state];
		}
	}
};
</script>
