import Marionette from 'marionette';
import ItemView from './single';

export default Marionette.CollectionView.extend({
	tagName: 'ul',
	className: 'category-tag__list',
	childView: ItemView,
	initialize ({ iconClassName }) {
		this.iconClassName = iconClassName;
	},
	childViewOptions () {
		return {
			iconClassName: this.iconClassName
		}
	}
});
