<template>
	<a class="card-link" :href="url"><slot /></a>
</template>

<script>
import Vue from 'vue';
import cardUrl from 'core/engine/card/services/card-url';
import { constant } from 'lodash';

export default Vue.component('CardLink', {
	props: {
		card: {
			type: String,
			required: true
		},
		params: {
			type: Object,
			default: constant({})
		}
	},

	computed: {
		url: ({ card, params }) => cardUrl.buildUrl(card, params)
	}
});
</script>
