import findBestUrlPattern from 'core/engine/card/services/find-best-url-pattern';
import { concat, forEach, isArray, isObject, isUndefined } from 'lodash';

const cards = {};
const urls = {};

const renderUrl = (url, values) =>
	`#${
		url
			.replace('(', '')
			.replace(')', '')
			.replace(/:(\w+)/g, (match, key) => encodeURIComponent(
				!isUndefined(values[key]) ? values[key] : ''
			))}`;

export default {
	registerCard: (card) => {
		const names = card.alias ? concat([card.name], card.alias) : [card.name];

		forEach(names, (name) => {
			cards[name] = card;

			if (isObject(card.url)) {
				const items = [];

				forEach(card.url, (url) => {
					const params = [];
					url.replace(/:(\w+)/g, (match, key) => {
						params.push(key);
					});

					items.push({ url, params });
				});
				urls[name] = items;

			} else {
				urls[name] = card.url;
			}
		});
	},

	/**
	 * @function cardUrl.buildUrl
	 * @param {string} cardName - Card name.
	 * @param {Object} query    - URL parameters.
	 * @returns {string}        - URL string.
	 * @example
	 *
	 * cardUrl.buildUrl('respondent-status', { respondentId: 19 });
	 */
	buildUrl (cardName, query = {}) {
		const url = urls[cardName] ? urls[cardName] : cardName;
		const values = query;

		if (isArray(url)) {
			const bestMatch = findBestUrlPattern(values, url);
			return renderUrl(bestMatch.url, values);
		}

		return renderUrl(url, values);

	},

	go (url) {
		window.location.hash = url;
		return this;
	},

	openCard (cardName, query) {
		return this.go(this.buildUrl(cardName, query));
	}
};
