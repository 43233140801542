import {
	assign, cloneDeep, defer, filter, forEach, get, includes, invoke, isFunction, isNumber, map,
	some
} from 'lodash';
import $ from 'jquery';
import { Model, View } from 'backbone';
import appBar from 'app-bar';
import can from 'acl-can';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import icon from 'service/icon';
import systemSettings from 'system-settings';
import t from 'translate';
import AssessmentInstanceLastSeenPage from '../../entities/assessment-instance-last-seen-page';
import Pager from '../../components/navigation/pager';
import RoleSummary from './role-summary';
import store from 'store';
import tocView from '../../components/navigation/view';
import parse from '../../helpers/parse';
import glob, { initialGlob } from '../../core/glob';
import renderers from '../../rendering/renderers';
import cardControls from 'core/engine/card/services/card-controls';
import {
	CONDITION, CONTINUE,
	END,
	HIDE,
	NEW_PAGE,
	PROGRESS,
	REDRAW,
	SHOW,
	STOP,
	TEXT_EXPRESSION
} from '../../core/constants';
import attachPlugins from '../../plugins';

const navClassName = 'assessment-navigation';

const readonlyActiveElements = ['.assessment-upload__button--download'];

const props = (rt) => {
	const isProper = isFunction(rt.get);

	const attrs = isProper ?
		{
			id: rt.get('id'),
			readonly: rt.get('readonly'),
			visible: rt.get('visible'),
			mandatory: rt.get('mandatory'),
			voluntary: rt.get('voluntary')
		} :
		{
			id: rt.getElementId(),
			readonly: rt.isReadonly(),
			visible: rt.isVisible(),
			mandatory: rt.isMandatory(),
			voluntary: rt.isVoluntary()
		};
	return assign(attrs, {
		skippable: attrs.readonly || !attrs.visible || attrs.voluntary
	});
};

const findWrapper = (id) => $(`[data-id=${id}]`).length ? $(`[data-id=${id}]`) : $(`#${id}`);

export default View.extend({
	acl: [],
	questionsPerPage: 15,
	className: 'assessment-runtime',
	redrawCount: 0,
	REDRAW_THRESHOLD: 10,
	stopFlag: false,
	pages: {},
	elements: () => ({
		$header: $('<h1 class="assessment-title" />'),
		$tocToolbar: $('<div class="table-of-content" />').hide(),
		$container: $('<div class="assessment-runtime__container" />'),
		$bottomButtons: $('<div class="nav-buttons flat-button__buttons-container" />'),
		$nav: $(`<div class="${navClassName}__container" />`),
		$navLeft: $(`<span class="${navClassName}__list-item nav-left" />`),
		$navCenter: $(`<span class="${navClassName}__list-item nav-center" />`),
		$navRight: $(`<span class="${navClassName}__list-item nav-right" />`),
		$progress: $(`<div class="${navClassName}__progress-container" />`),
		$finishButton: $(`<button class="appbar-button" title="${t('Complete assessment')}" />`)
			.html(icon('arrow-right'))
			.hide(),
		$bottomFinishButton: $(`<button class="
			flat-button__button
			flat-button__button--success
		" />`).text(t('Submit')),
		$nextButton: $(`<button class="appbar-button" title="${t('Next page')}" disabled />`)
			.html(icon('arrow-right')),
		$bottomNextButton: $('<button class="flat-button__button flat-button__button--submit" />')
			.text(t('Next page')),
		$backButton: $(`<button class="appbar-button" title="${t('Previous page')}"/>`)
			.html(icon('arrow-left')),
		$bottomBackButton: $(`<button class="flat-button__button" />`).text(t('Previous page')),
		$copyrights: $('<div />').attr('role', 'contentinfo').addClass('copyrights')
	}),

	goingBackForbidden () {
		return this.restrictBackButton && (!this.allowSingleBackPage || this.singleBackPageUsed);
	},

	changeButtonState: ({ tile, button = '', state = '' }) => {
		const maybeDisabled = () =>
			button === 'back' &&
			state === 'enabled' &&
			tile.goingBackForbidden() ?
				'disabled' :
				state;

		const { bottom, $el } = {
			finish: () => ({
				$el: tile.$finishButton,
				bottom: tile.$bottomFinishButton
			}),
			next: () => ({
				$el: tile.$nextButton,
				bottom: tile.$bottomNextButton
			}),
			back: () => ({
				$el: tile.$backButton,
				bottom: tile.$bottomBackButton
			})
		}[button]();
		const stateName = maybeDisabled();

		const newState = {
			enabled: () => ({ hidden: false, disabled: false }),
			disabled: () => ({ hidden: false, disabled: true }),
			hidden: () => ({ hidden: true, disabled: true })
		}[stateName]();

		const applyState = ({ hidden, disabled }, $button) => {
			if (hidden) {
				$button.hide();

			} else {
				$button.show();
			}

			$button
				.prop('disabled', !!disabled)
				.toggleClass('disabled', disabled)
				.attr('aria-disabled', disabled ? 'true' : '');
		};

		applyState(newState, bottom);
		applyState(newState, $el);
	},

	isReadonlyMode () {
		return this.ass.isReadonlyMode() ||
			(
				store.getters.userType === 'clinician' &&
				!can.edit('administrator.respondents.assessmentcontent')
			);
	},

	// eslint-disable-next-line max-statements
	render () {
		this.renderers = {};
		assign(glob, cloneDeep(initialGlob));
		assign(this, this.elements());
		this.assessmentModel = this.cardContext().get('assessment-model');

		this.ass = this.assessmentModel.getAssessment(); // ( ＾◡＾)っ (‿|‿)
		this.assInstId = this.assessmentModel.get('assessmentInstanceId');
		this.$container.attr('data-instanceid', this.assInstId);

		this.$el
			.append(this.$header, this.$tocToolbar, this.$container)
			.addClass(this.assessmentModel.isResponsiveLayout() ? 'responsive' : 'non-responsive');

		this.setLoading();

		this.setLoaded();
		glob.renderers = renderers;
		this.handleEvents(this);

		this
			.renderNavigation()
			.mountRoleSummary()
			.renderContent();

		if (this.ass.isTOCAllowed()) {
			appBar.addButton(this.cardContext().get('cardName'), {
				css: 'appbar-button',
				title: t('Table of content'),
				icon: 'list',
				order: 0,
				click: () => {
					this.toc({ tile: this, fromButton: true });
				}
			});
		}
	},

	mountProgress () {
		const counterType = this.assessmentModel.get('completionProgressCounterType');

		if (counterType === 'PERCENTAGE' || counterType === null) {
			this.$navCenter.append(this.$progress);
		}
	},

	renderNavigation () {
		const StatusBar = View.extend({
			render () {
				this.$el.empty();
			}
		});
		const statusBar = new StatusBar();
		appBar.add(this.cardContext().get('cardName'), statusBar, { type: 'outer' });

		this.mountProgress();

		this.$bottomToolbar = statusBar.$el.append(
			this.$nav.append(
				this.$navLeft,
				this.$navCenter,
				this.$navRight
			)
		);
		this.$bottomButtons.appendTo(this.$el);

		const append = ({ $el, $parent, fn }) => {
			$el.appendTo($parent).on('click', () => {
				fn(this);
			});
		};

		forEach([
			{ $el: this.$backButton, $parent: this.$navLeft, fn: this.onBack },
			{ $el: this.$bottomBackButton, $parent: this.$bottomButtons, fn: this.onBack },
			{ $el: this.$finishButton, $parent: this.$navRight, fn: this.onFinish },
			{ $el: this.$bottomFinishButton, $parent: this.$bottomButtons, fn: this.onFinish },
			{ $el: this.$nextButton, $parent: this.$navRight, fn: this.onNext },
			{ $el: this.$bottomNextButton, $parent: this.$bottomButtons, fn: this.onNext }
		], append);

		this.hideButton('finish');

		return this;
	},

	handleShowHide () {
		// show or hide element based on passed event type
		// this is done by finding proper element in DOM tree (what a great pattern) and calling its
		// attached function or applying default show/hide
		const showHide = ({ targetClass, targetCode, type }) => {
			const $el = $((targetClass ? '.' : '#') + targetCode);
			const eventHandler = {
				show: 'onShow',
				hide: 'onHide'
			}[type];

			const clearHandler = {
				show: 'unclear',
				hide: 'clear'
			}[type];

			if (isFunction($el.data(eventHandler))) {
				$el.data(eventHandler)();

			} else {
				$el[type]();
			}

			invoke(this.renderers[targetCode], clearHandler);
		};

		forEach([SHOW, HIDE], (type) => {
			this.ass.on(type, (event) => {
				showHide(assign({}, event, { type }));
			});
		});
	},

	showDone ({ disabled } = {}) {
		this.stopFlag = !!disabled;

		if (!this.isReadonlyMode()) {
			this.showButton('finish');

		} else {
			this.hideButton('finish');
		}

		this.hideButton('next');
	},

	showContinue ({ disabled } = { disabled: false }) {
		this.stopFlag = !!disabled;
		this.showButton('next').hideButton('finish');
	},

	handleConditioning () {
		this.ass.on(STOP, (event) => {
			if (!includes(this.currentElementCodes(), event.targetCode)) {
				return; // the item related to the event is not on the screen so the event is
				// discarded
			}

			defer(() => {
				if (this.ass.isMore()) {
					this.showContinue({ disabled: true });

				} else {
					this.showDone({ disabled: true });
				}
			});
		});

		this.ass.on(CONDITION, () => {
			if (this.ass.isMore()) {
				this.showContinue();

			} else {
				this.showDone();
			}
		});

		this.ass.on(CONTINUE, () => {
			defer(() => {
				if (this.ass.isMore()) {
					if (this.ass.canGoNext()) {
						this.showContinue();

					} else {
						this.showContinue({ disabled: true });
					}

				} else {
					this.showDone();
				}
			});
		});
	},

	customEvents: (tile) => ({
		[REDRAW]: () => {
			tile.redraw();
		},

		[NEW_PAGE]: () => {
			tile.stopFlag = false;
			tile.hideButton('finish').showButton('next');
		},

		[PROGRESS]: (event) => {
			let progressText = '';

			if (event.total !== 0) {
				let progressNumber = Math.round(event.progress / event.total * 100);

				if (!isNumber(progressNumber)) {
					progressNumber = 0;
				}

				progressText = `${progressNumber}%`;
			}

			tile.$progress.html(progressText);
		},

		[END]: () => {

			if (!tile.isReadonlyMode()) {
				tile.showButton('finish');
			}

			tile.hideButton('next');
		},

		[TEXT_EXPRESSION]: (event) => {
			if (tile.renderers && tile.renderers[event.targetCode]) {
				if (isFunction(tile.renderers[event.targetCode].updateText)) {
					tile.renderers[event.targetCode].updateText();
				}
			}
		},
		'LiveResponse:recovery-start': () => {
			cwalert.error(t('assessment-runtime.server-is-not-responding'));
		},
		'LiveResponse:recovery-successful': () => {
			cwalert.success(t('assessment-runtime.server-is-back'));
		},
		'Question:limit': () => {
			cwalert.notice(t('assessment-runtime.checkbox.limitExceeded'));
		}
	}),

	handleEvents (tile) {
		forEach(tile.customEvents(tile), (eventHandler, eventName) => {
			tile.ass.on(eventName, eventHandler);
		});

		this.handleShowHide();
		this.handleConditioning();
	},

	hideButton (button) {
		this.changeButtonState({ button, state: 'hidden', tile: this });
		return this;
	},

	showButton (button) {
		this.changeButtonState({ button, state: 'enabled', tile: this });
		return this;
	},

	renderContent () {
		this.$el.attr({ 'aria-labelledby': 'assessment-name' });
		this.$header.html(this.assessmentModel.get('questionnaire').name);
		assign(this, {
			assessmentname: document.getElementById('assessment-name'),
			restrictBackButton: this.ass.isBackButtonRestricted(),
			allowSingleBackPage: this.ass.isBackButtonAllowedOnce(),
			pager: new Pager({
				elements: this.ass.getRuntimes(),
				itemsPerPage: this.questionsPerPage
			}),
			pageNumber: this.ass.isTOCAllowed() ? 'TOC' : 1,
			lastSeenPageModel: new AssessmentInstanceLastSeenPage({
				assessmentInstanceId: this.ass.getInstanceId()
			})
		});
		this.initialRender();
		this.handleCopyrights();
	},

	initialRender () {
		if (!this.isReadonlyMode() && this.ass.getPreviousSessionLastSeenPageElementCode()) {
			this
				.hideButton('finish')
				.showButton('next')
				.hideButton('back');

			this.jumpTo(this.ass.getPreviousSessionLastSeenPageElementCode());
			this.handleRoleSummary({
				showContent: true,
				once: true
			});

		} else if (this.ass.isTOCAllowed()) {
			this.toc({ tile: this });

		} else {
			this
				.hideButton('finish')
				.showButton('next')
				.hideButton('back');

			this.handleRoleSummary({ showContent: true });
			this.ass.goto();
		}
	},

	skipCheckAndConfirm () {
		return this.isReadonlyMode() || this.pageNumber === 'TOC' || +this.pageNumber <= 0;
	},

	popupsEnabled () {
		const instancePopups = this.assessmentModel.get('showUnansweredQuestionsPopup');
		const allAssessmentsPopups = systemSettings.getBoolean('ASSESSMENT_POPUPS');
		return allAssessmentsPopups ? instancePopups : allAssessmentsPopups;
	},

	confirmationState () {
		let unansweredCount = 0;
		let mandatoryCount = 0;

		forEach(this.currentPageQuestionsRuntimes, (rt) => {
			const { id, mandatory, skippable } = props(rt);
			const $wrapper = findWrapper(id);

			$wrapper.removeClass('assessment-question-unanswered mandatory');

			if (rt.isIgnored() || rt.isAnswered() || skippable) {
				// Either of these means we're ok
				return;
			}

			// eslint-disable-next-line lodash/prefer-lodash-method
			$wrapper.addClass('assessment-question-unanswered')
				.find('.questiontext, .ar-component__label')
				.addClass('non-answered-question-marker')
				.attr('title', `${t('*')} ${t('(unanswered)')}`);
			unansweredCount++;

			if (mandatory) {
				$wrapper.addClass('mandatory');
				mandatoryCount++;
			}
		});

		return {
			unansweredCount,
			mandatoryWarning: mandatoryCount > 0 || this.stopFlag,
			unansweredWarning: unansweredCount && this.popupsEnabled()
		};
	},

	checkAndConfirm (done) {
		if (this.skipCheckAndConfirm()) {
			done();
			return;
		}
		$('.questiontext, .ar-component').removeClass('non-answered-question-marker');
		const { unansweredCount, mandatoryWarning, unansweredWarning } = this.confirmationState();

		if (mandatoryWarning) {
			confirmation({
				preventCancel: true,
				confirm: t('Ok'),
				title: t('Mandatory questions'),
				message: t('assessment-runtime.all-mandatory-questions-must-be-answered'),
				icon: 'assessment-continue'
			}).done(() => {
				if (unansweredCount) {
					cwalert.notice(t('Unanswered questions have been marked with *'));
				}
			});

		} else if (unansweredWarning) {
			const message = t('assessment.ContinueWithUnanswered', { x: unansweredCount });
			confirmation({
				title: t('Continue with unanswered'),
				message,
				icon: 'assessment-continue'
			}).done(done).fail(() => {
				cwalert.notice(t('Unanswered questions have been marked with *'));
			});

		} else {
			done();
		}
	},

	returnToToc: ({ componentIds, components }) => some(
		componentIds,
		(componentId) => parse.bool(components[componentId].instance.object.returnToTOC)
	),

	returnToElement: ({ componentIds, components }) => {
		let element = false;
		forEach(
			componentIds,
			(componentId) => {
				element = get(components[componentId], 'instance.object.returnToElement');
				return !element;
			}
		);
		return element;
	},

	maybeGoToElement: (tile) => {
		const goToElement = tile.returnToElement({
			componentIds: tile.currentVisibleElementCodes(),
			components: tile.ass.componentById
		});

		if (goToElement) {
			tile.pageNumber = tile.pager.getPageNumberByElementId(goToElement);
			tile.ass.goto(goToElement);
		}

		return goToElement;
	},

	onNext: (tile) => {
		store.dispatch('keepalive');
		tile.naviButtonPressed = 'next';

		const increase = () => {
			if (tile.pageNumber < tile.pager.pagesCount()) {
				tile.pageNumber++;
			}
		};

		const render = () => {
			if (!tile.maybeGoToElement(tile)) {
				if (tile.pageNumber === 'TOC') {
					tile.pageNumber = 1;
					tile.ass.goto();

				} else if (tile.returnToToc({
					componentIds: tile.currentElementCodes(),
					components: tile.ass.componentById
				})) {
					tile.toc({ fromButton: true, tile });

				} else {
					increase();

					while (!tile.pager.getPageStartId(tile.pageNumber)) {
						increase();
					}

					tile.ass.goto(tile.pager.getPageStartId(tile.pageNumber));
					tile.singleBackPageUsed = false;
				}
			}

			// Scroll to top when continue is clicked
			tile.$header[0].scrollIntoView();
		};

		tile.checkAndConfirm(render);
		tile.handleRoleSummary();
	},

	onBack: (tile) => {
		store.dispatch('keepalive');
		tile.naviButtonPressed = 'back';
		const render = () => {
			tile.pageNumber--;

			while (!tile.pager.getPageStartId(tile.pageNumber)) {
				tile.pageNumber--;
			}
			tile.ass.goto(tile.pager.getPageStartId(tile.pageNumber));
			tile.showButton('next').hideButton('finish');
		};

		if (tile.allowSingleBackPage) {
			tile.singleBackPageUsed = true;
		}

		if (tile.pageNumber === 1 || tile.pageNumber === 'TOC') {
			tile.toc({ tile });

		} else {
			render();
		}

		tile.handleRoleSummary();
	},

	onFinish: (tile) => {
		const submitAssessment = () => {
			confirmation({
				icon: 'submit-assessment',
				title: t('Submit assessment'),
				message: t(
					'Do you want to submit your answers now?' + ' ' +
					'It will not be possible to change it afterwards'
				),
				confirm: t('Yes, submit')
			}).done(() => {
				tile.submit(tile);
			});
		};

		if (!tile.maybeGoToElement(tile)) {
			tile.checkAndConfirm(submitAssessment);
		}
	},

	submit: (tile) => {
		tile.startSaving();
		tile.setLoading();
		const success = () => {
			const assessmentInstance = tile.cardContext().get('assessmentInstance');

			const latestAssessmentParams = ({ treatmentId, referral }) => {
				const opts = {
					openCard: 'latest-assessment',
					cardQuery: {}
				};

				if (treatmentId) {
					opts.cardQuery.treatmentId = treatmentId;
				}

				if (referral) {
					opts.cardQuery.referral = referral;
				}

				return opts;
			};

			const afterSubmitRedirect = ({
				treatmentId,
				referral,
				iterateInTreatmentContext
			}) => {
				let opts = {};
				const iteratesInContext = !referral && treatmentId && iterateInTreatmentContext;

				if (referral || iteratesInContext) {
					opts = latestAssessmentParams({
						treatmentId,
						referral
					});
				}

				cardControls.closeCard(opts);
			};

			const handleTheSuccess = () => {
				cwalert.success(t('assessment.AssessmentDelievered'));
				tile.setLoaded();
				const cardContext = tile.cardContext();
				const openNextAssessment = cardContext.get('openNextAssessment');

				if (!openNextAssessment) {
					cardControls.closeCard();
				} else {
					afterSubmitRedirect({
						iterateInTreatmentContext: systemSettings.getBoolean('RESPONDENT_ITERATE_IN_TREATMENT_CONTEXT'),
						treatmentId: cardContext.get('treatmentId'),
						referral: cardContext.get('referral')
					});
				}
			};

			if (assessmentInstance && isFunction(assessmentInstance.fetch)) {
				assessmentInstance.fetch().done(handleTheSuccess);

			} else {
				handleTheSuccess();
			}
		};

		const error = () => {
			cwalert.error(t('assessment.assessment-delivery-error'));
			tile.setLoaded(); // hide tile loader, display error
		};

		tile.ass.submit({ success, error });
	},

	startSaving () {
		return !this.isReadonlyMode() && this.ass.startSaving();
	},

	jumpTo (elementId) {
		this.pageNumber = this.pager.getPageNumberByElementId(elementId);
		this.ass.goto(this.pager.getPageStartId(this.pageNumber));
		this.handleRoleSummary();
	},

	toc ({ fromButton, tile }) {
		tile.$tocToolbar.hide();
		tile.$progress.html('0%');
		tile.pageNumber = 'TOC';
		tile.pages = [];

		tile.handleRoleSummary({ showContent: !fromButton });
		tile.$el.focus();

		forEach({
			finish: 'hidden',
			next: 'enabled',
			back: 'hidden'
		}, (state, button) => {
			tile.changeButtonState({ button, state, tile });
		});

		tile.$container.html(tocView({
			entries: tile.ass.getTableOfContents(),
			jumpTo: tile.jumpTo.bind(tile)
		}));

		!tile.isReadonlyMode() && tile.lastSeenPageModel.save({ lastSeenPageElementCode: '' });
	},

	drawNotImplemented: () => $('<div />'),
	naviButtonPressed: 'none',

	elementCodes: (elements) => map(
		elements,
		(element) => invoke(element, 'get', ['id']) || element.getElementId()
	),
	currentElements: [],
	currentElementCodes () {
		return this.elementCodes(this.currentElements);
	},
	currentVisibleElements () {
		return filter(this.currentElements, (element) => invoke(element, 'isVisible'));
	},
	currentVisibleElementCodes () {
		return this.elementCodes(this.currentVisibleElements());
	},

	renderReadonlyOverlay () {
		// create multiple readonly overlay div's instead of one
		// this provides access to custom assessment attributes - see defect 4865
		forEach(this.$container.children(), (containterChild) => {
			forEach(containterChild.children, (assessmentElement) => {
				assessmentElement.classList.add('assessment--readonly');
				forEach(assessmentElement.children, (assessmentElementChild) => {
					forEach(readonlyActiveElements, (activeElement) => {
						if (
							// eslint-disable-next-line lodash/prefer-lodash-method
							$(assessmentElementChild).find(activeElement)
								.length > 0
						) {
							assessmentElementChild.classList.add('assessment__readonly-above');
						}
					});
				});
				const readonlyOverlay = document.createElement('div');
				readonlyOverlay.classList.add('assessment-runtime__readonly-overlay');
				assessmentElement.appendChild(readonlyOverlay);
			});
		});
	},

	redraw () {
		if (this.ass.isTOCAllowed()) {
			this.$tocToolbar.show();
		}

		this.$bottomToolbar.show();
		this.$container.html('');

		this.currentPageQuestionsRuntimes = [];
		this.currentElements = [];
		const sections = {};
		this.showButton('back');

		if (this.pageNumber === 1 && !this.ass.isTOCAllowed()) {
			this.hideButton('back');
		}

		let focused;

		if (this.ass.isTOCAllowed() ? (this.pageNumber === 'TOC') : (this.pageNumber === 1)) {
			this.$el.focus();
			focused = true;
		}

		let questionLimit = this.questionsPerPage;
		this.numberOfActualQuestionsOnPage = 0;
		let firstElement;
		let element = this.ass.getNextElement();

		while (element) {
			const isProper = element instanceof Model;

			const id = isProper ? element.get('id') : element.getElementId();
			const sectionId = isProper ? element.get('sectionId') : element.getSectionId();
			const type = isProper ? element.get('type') : element.getType();
			const elementClass = isProper ? element.get('class') : element.getClass();
			const getSection = isProper ?
				element.section.bind(element) :
				element.getSection.bind(element);

			this.currentElements.push(element);

			let elementBox;
			let renderer;

			if (type !== 'Section' && !element.isIgnored()) {
				this.numberOfActualQuestionsOnPage++;

				if (!firstElement) {
					firstElement = element;
				}
			}

			if (isFunction(glob.renderers[type])) {
				const isProperView = includes([
					'Slider',
					'Range',
					'NumericSlider',
					'NumericRange',
					'StringInput'
				], type);

				renderer = isProperView ?
					new glob.renderers[type]({
						model: element,
						assessment: this.ass
					}) :
					new glob.renderers[type](element, this.ass);
				this.renderers[id] = renderer;

				if (elementClass === 'Question') {
					this.currentPageQuestionsRuntimes.push(element);

					if (isProper) {
						element.trigger('element.show');

					} else {
						element.shown();
					}
				}

				elementBox = renderer instanceof View ?
					renderer.el :
					renderer.getElementBox();

				if (type === 'Section') {
					sections[id] = renderer;
				}

				attachPlugins({
					renderer,
					element,
					assessmentInstanceId: this.assInstId
				});
			} else {
				elementBox = this.drawNotImplemented(element);
			}

			if (sectionId) {
				sections[sectionId].addChild(elementBox, element, renderer);

			} else {
				this.$container.append(elementBox);
			}

			if (!focused && (type !== 'Section')) {
				$(elementBox).focus();
				focused = true;
			}

			if (this.ass.isMore() && (elementClass === 'Question') && (--questionLimit <= 0)) {
				// question limit reached. page break if allowed
				let pageBreakAllowed = true;

				for (let section = getSection(); section; section = section.getSection()) {
					if (section.isNoPageBreaks()) {
						pageBreakAllowed = false;
						break;
					}
				}

				if (pageBreakAllowed) {
					this.hideButton('finish').showButton('next');
					this.ass.markPageBreak();
					this.ass.updateProgress();
					break;
				}
			}

			element = this.ass.getNextElement();
		}

		if (firstElement && !this.isReadonlyMode() && !this.singleBackPageUsed) {
			this.lastSeenPageModel.save({
				lastSeenPageElementCode: isFunction(firstElement.get) ?
					firstElement.get('id') :
					firstElement.getElementId()
			});
		}

		if (this.isReadonlyMode()) {
			this.renderReadonlyOverlay();
		}

		if (this.ass.isMore() && (this.numberOfActualQuestionsOnPage === 0)) {
			this.redrawCount++;
			switch (this.naviButtonPressed) {
			case 'next': {
				if (this.redrawCount < this.REDRAW_THRESHOLD) {
					this.redrawCount = 0;
					this.onNext(this);

				} else {
					this.redrawCount = 0;
				}
				break;
			}

			case 'back': {
				if (this.redrawCount < this.REDRAW_THRESHOLD) {
					this.redrawCount = 0;
					this.onBack(this);

				} else {
					this.redrawCount = 0;
				}
				break;
			}
			}
		}
		this.naviButtonPressed = 'none';
	},

	mountRoleSummary () {
		const roleSummary = this.cardContext().get('roleSummary');

		if (roleSummary) {
			glob.roleSummaryView = new RoleSummary({
				model: new Model(roleSummary)
			});

			glob.roleSummaryView.render().$el.appendTo(this.$el);
			this.$bottomButtons.prepend(glob.roleSummaryView.ui.bottomButton[0]);
			this.$nav.prepend(glob.roleSummaryView.ui.appbarButton);

			this.listenTo(glob.roleSummaryView, 'showContent', () => {
				this.$container.addClass('assessment-runtime__container--overlayed');
				this.$nav.hide();
			});

			this.listenTo(glob.roleSummaryView, 'hideContent hide', () => {
				this.$container.removeClass('assessment-runtime__container--overlayed');
				this.$nav.show();
			});

			this.listenTo(glob.roleSummaryView, 'cancel', () => {
				cardControls.closeCard();
			});
		}

		return this;
	},

	shouldShowRoleSummary ({ once }) {
		const showWhenTOC = () => this.ass.isTOCAllowed() && this.pageNumber === 'TOC';
		const showOtherwise = () => !this.ass.isTOCAllowed() && this.pageNumber === 1;

		return showWhenTOC() || showOtherwise() || once;
	},

	/*
	 * Handle role summary show
	 * This function is called across runtime lifetime -- on init, on any page change (`.goto()`)
	 * etc.
	 *
	 * @param {boolean} showContent - should role summary show content
	 * @param {boolean} once - should role summary open only once
	 */
	handleRoleSummary ({ showContent, once } = {}) {
		if (!glob.roleSummaryView) {
			return;
		}

		if (this.shouldShowRoleSummary({ once })) {
			glob.roleSummaryView.show();

		} else {
			glob.roleSummaryView.hide();
		}

		if (showContent) {
			glob.roleSummaryView.showContent({
				once: once && !this.shouldShowRoleSummary({ once })
			});
		} else {
			glob.roleSummaryView.hideContent();
		}
	},

	handleCopyrights () {
		const text = this.ass.getCopyrightText();
		const logo = this.ass.getCopyrightLogo();
		this.$el.append(this.$copyrights);

		if (logo) {
			const mm = this.ass.mediaManager;
			const $img = $('<img />').attr({
				src: mm.getUrl(logo),
				alt: mm.getAlt(logo),
				width: mm.getImageWidth(logo),
				height: mm.getImageHeight(logo)
			});

			$('<p />').html($img).appendTo(this.$copyrights);
		}

		if (text) {
			$('<p />').html(text).appendTo(this.$copyrights);
		}
	}
});
