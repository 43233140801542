import CardView from 'core/engine/card/views/card-view';
import { Model } from 'backbone';

export default CardView.extend({
	className: 'card home-card',
	model: new Model({ title: 'Home', context: new Model() }),

	initialize ({ cardName = '' }) {
		this.model.set('cardName', cardName);
	}
});

