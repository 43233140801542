import repository from 'repository';
import initEntities from '../__helpers/init-entities';
import { assign, filter, get, map, size } from 'lodash';
import { add, remove, set, update } from '../__helpers/mutations';
import datetime from 'service/datetime/datetime';
import moment from 'moment';
import { status } from '../agenda/helpers';
import { RESPONDENT } from '../respondents/respondent';

const prefix = 'goals';
const ACTIVE = 'active';
const ACTIVE_COUNT = 'activeCount';
const ENTITIES = 'entities';
const ITEMS = 'items';
const SIZE = 'size';

const INIT = 'init';

export const GOALS = {
	ACTIVE: `${prefix}/${ACTIVE}`,
	ACTIVE_COUNT: `${prefix}/${ACTIVE_COUNT}`,
	ENTITIES: `${prefix}/${ENTITIES}`,
	ITEMS: `${prefix}/${ITEMS}`,
	SIZE: `${prefix}/${SIZE}`,

	INIT: `${prefix}/${INIT}`
};

export default {
	namespaced: true,

	state: {
		data: []
	},

	getters: {
		[ENTITIES]: (state) => map(state.data, (goal) => assign({}, goal, {
			completed: goal.isCompleted,
			datetime: goal.deadline,
			itemType: 'goal',
			open: !goal.isCompleted,
			overdue: !goal.isCompleted && datetime(goal.deadline).toMoment() < moment.now(),
			title: goal.name
		})),
		[ITEMS]: (state, getters) => map(getters[ENTITIES], (goal) => assign({}, goal, {
			status: status(goal)
		})),
		[ACTIVE]: (state) => filter(state.data, { isCompleted: false }),
		[ACTIVE_COUNT]: (state, getters) => size(getters[ACTIVE]),
		[SIZE]: (state) => size(state.data)
	},

	mutations: {
		set: set(),
		remove: remove(),
		add: add(),
		update: update()
	},

	actions: {
		initForTreatment: async ({ commit }, { treatmentId } = { treatmentId: 0 }) => {
			commit('set', await repository.treatmentGoals(treatmentId));
		},

		initForRespondent: async ({ commit }, { respondentId } = { respondentId: 0 }) => {
			commit('set', await repository.respondentGoals(respondentId));
		},

		[INIT]: async ({ commit, dispatch, rootGetters, rootState }) => {
			commit('set', []);
			await initEntities(GOALS.INIT)({ dispatch, rootGetters, rootState });
		},

		create: ({ commit, rootGetters }, goal) => repository.createGoal(assign(
			{},
			goal,
			{ respondent: get(goal, 'respondentId', rootGetters[RESPONDENT.RESPONDENT_ID]) }
		)).then((newGoal) => {
			commit('add', assign({}, goal, newGoal));
			return newGoal;
		}),

		remove: ({ commit }, goal) => {
			commit('remove', { item: goal });
			return repository.deleteGoal(goal);
		},

		toggleStatus: ({ commit }, goal) => {
			const toggledGoal = assign({}, goal, { isCompleted: !goal.isCompleted });
			commit('update', toggledGoal);

			return repository.updateGoal(toggledGoal);
		}
	}
};
