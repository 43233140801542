import prefixify from '../__helpers/prefixify';
import { set } from '../__helpers/mutations';
import { exact } from '../__helpers/getters';
import { get } from 'lodash';
import repository from 'repository';

const prefix = prefixify('respondentAssessmentInstance');

const DATA = 'data';
const TREATMENT_ID = 'treatmentId';
const SET = 'set';
const INIT = 'init';

export const ASSESSMENT_INSTANCE = {
	DATA: prefix(DATA),
	TREATMENT_ID: prefix(TREATMENT_ID),
	INIT: prefix(INIT)
};

export default {
	namespaced: true,

	state: {
		[DATA]: null
	},

	getters: {
		...exact([DATA]),
		[TREATMENT_ID]: (state) => get(state[DATA], 'treatment')
	},

	mutations: {
		[SET]: set()
	},

	actions: {
		[INIT]: async ({ commit }, id) => {
			commit(SET, await repository.assessmentInstance(id));
		}
	}
};
