import { updateName } from './update-name';
import { assign, isNil, map, transform } from 'lodash';

export const DEFAULT_ASSESSMENT_ORDER = 'first';
export const EMPTY_CONTEXT = '0';

const convertUndefined = (item) => item !== undefined ? item : null;

const setAssessmentContext = (context, contextLabel) =>
	context !== EMPTY_CONTEXT ? convertUndefined(contextLabel) : '';

export const updateMirrorContext = ({ origin, parentContext }) => {
	const label = origin.fields.assessmentContext ? origin.fields.contextLabel : parentContext;
	return assign({}, {
		assessmentContext: origin.fields.assessmentContext || null,
		contextLabel: label || null
	});
};

const assessmentContext = ({ element, origin }) =>
	element.fields.assessmentContext || origin.fields.assessmentContext || null;

export const updateNonMirrorContext = ({ element, origin, noInheritance = false }) => {
	const folderContext = element.type === 'folder' ?
		assessmentContext({ element, origin }) :
		null;
	return assign({}, {
		assessmentContext: noInheritance ? element.fields.assessmentContext : folderContext,
		contextLabel: element.fields.assessmentContext ?
			element.fields.contextLabel :
			origin.fields.contextLabel || null
	});
};

const updateFromParent = (element, fields) => {
	const setAssessmentOrder = (type) =>
		type === 'folder' ? null : DEFAULT_ASSESSMENT_ORDER;
	element.fields.codeTemplate = !element.fields.customCode ?
		fields.codeTemplate :
		element.fields.codeTemplate;
	// it can be an empty string but it excludes inheritance

	if (isNil(element.fields.assessmentContext)) {
		element.fields.contextLabel = setAssessmentContext(
			fields.assessmentContext,
			fields.contextLabel
		);
	} else {
		element.fields.contextLabel = convertUndefined(element.fields.contextLabel);
	}

	element.fields.assessmentOrder = fields.assessmentOrder ?
		fields.assessmentOrder :
		setAssessmentOrder(element.type);
};

export const updateContext = ({ element, origin, parentContext = null }) => element.mirror ?
	updateMirrorContext({ origin, parentContext }) :
	updateNonMirrorContext({ element, origin });

const updateElement = (element, fields) => {
	if (element.type !== 'folder' &&
		fields.codeTemplate !== element.fields.codeTemplate) {
		element.fields.customName = false;
	}
	updateFromParent(element, fields);

	if (element.type !== 'folder' && !element.fields.customName) {
		element.fields.name = updateName({ ...element.fields, type: element.type });
	}
};

export const updateFields = ({ element, item }) => {
	const assignFields = () => {
		const assessmentContext = setAssessmentContext(
			element.fields.assessmentContext,
			element.fields.contextLabel
		);

		const updatedFields = {
			...item.fields,
			...{
				codeTemplate: element.fields.codeTemplate,
				contextLabel: assessmentContext || null
			}
		};

		if (item.type === 'folder' || item.root) {
			updatedFields.parentCodeTemplate = element.fields.codeTemplate;
		}

		if (item.type !== 'folder') {
			updatedFields.name = updateName({ ...updatedFields, type: item.type });
		}

		return updatedFields;
	};

	const fields = assignFields();

	if (fields.assessmentOrder || item.type === 'folder') {
		fields.assessmentOrder = element.fields.assessmentOrder || fields.assessmentOrder;
	}
	return fields;
};

export const updateValues = ({ element, fields, origin, parentCode = null }) =>
	transform(origin, (result, child) => {
		if (child.type !== 'folder' && !child.root) {
			updateElement(child, fields);
		} else if (child.children) {
			updateFromParent(child, fields);
			child.children = updateValues({
				fields: child.fields,
				origin: child.children,
				element: []
			});
			child.fields.parentCodeTemplate = parentCode;
		}
		result.push(child);
	}, element);

export const updatePastedContext = ({ element, from, noInheritance, origin }) => {
	element.fields = assign({}, element.fields, updateNonMirrorContext({
		element: from,
		origin,
		noInheritance
	}));

	if (element.type !== 'folder') {
		element.fields.name = updateName(element.fields);
	} else if (element.children) {
		element.children = map(element.children, (child) => updatePastedContext({
			element: child,
			from,
			noInheritance: false,
			origin,
			updateName,
			updateNonMirrorContext
		}));
	}
	return element;
};
