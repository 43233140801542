import MultipleEntities from '../shared/multiple-entities';
import MediaCategory from './category';
import urls from './urls';

const MediaCategories = MultipleEntities.extend({
	model: MediaCategory,
	urls,
	eventNamespace: 'media-category',

	getAll () {
		return this.retrieve('categories');
	}
});

export default new MediaCategories();
