import { Application } from 'marionette';
import appContext from 'app-context';
import appLayout from './views/app-layout-view';
import loadExtensions from './extensions/load-extensions';
import cardLayout from './engine/card/views/card-layout';
import appbarLayout from './engine/appbar/views/appbar-layout';
import { forEach, get, isFunction, toArray } from 'lodash';
import tileManager from './engine/tile/tile-manager';
import cardBuilder from './engine/card/services/card-builder';
import store from 'store';
import appbarManager from './engine/appbar/appbar-manager';

loadExtensions();

const app = new Application({ region: '.app__container' });

appLayout.getRegion('appContent').show(cardLayout);
app.getRegion().show(appLayout);

app.on('start', (context, { type, modules }) => {
	appLayout
		.setLangAttr(store.getters['userLanguage'])
		.getRegion('appBar')
		.show(appbarLayout({ appType: type }));
	appbarManager.toggle(false);
	appContext.trigger('app.start');
	app.getRegion().$el.addClass(`app__container--${type} app__container--ready`);

	const cards = [];
	forEach(modules, (moduleDefinition) => {
		const definition = isFunction(moduleDefinition) ?
			moduleDefinition() :
			moduleDefinition;

		tileManager.registerTiles(get(definition, 'tiles', {}));
		cards.push(...toArray(get(definition, 'cards', [])));

		if (isFunction(get(definition, 'action'))) {
			definition.action();
		}
	});

	cardBuilder.cards(cards);
});

export default app;
