/* eslint-disable max-len */
const prefix = 'backend-commons/treatment';

export const message = {
	url () {
		return `${prefix}/${this.get('treatment')}/respondent/${this.getRespondentId()}/message/`;
	}
};

export const messages = {
	url: ({ treatment }) =>
		`${prefix}/${treatment}/message/`
};

export const messageDrafts = {
	url ({ treatment, respondent }) {
		return `backend-commons/treatment/${treatment}/respondent/${respondent}/message/draft/`;
	}
};

export const messageDraft = {
	url () {
		return `${prefix}/${this.get('treatment')}/respondent/${this.getRespondentId()}/message/draft/`;
	}
};

export const unreplied = {
	url: 'backend-commons/message/notification/'
};

export const messageAttachment = {
	url () {
		return `${prefix}/${this.get('treatmentId')}/respondent/${this.get('respondentId')}/message/draft/${this.get('messageId')}/attachment/`;
	}
};

export const messageAttachments = {
	url: ({ treatmentId, respondentId, messageId }) =>
		`${prefix}/${treatmentId}/respondent/${respondentId}/message/draft/${messageId}/attachment/`
};

export const messageDraftAttachment = {
	url () {
		return `${prefix}/${this.get('treatmentId')}/respondent/${this.get('respondentId')}/message/draft/${this.get('messageDraftId')}/attachment/`;
	}
};

export const messageDraftAttachments = {
	url: ({ treatmentId, respondentId, messageDraftId }) =>
		`${prefix}/${treatmentId}/respondent/${respondentId}/message/draft/${messageDraftId}/attachment/`
};

const single = (
	{ respondentId, treatmentId, messageId }
) => `${prefix}/${treatmentId}/respondent/${respondentId}/message/${messageId}`;

export default {
	message,
	messages,
	messageDraft,
	messageDrafts,
	unreplied,
	messageAttachment,
	messageAttachments,
	messageDraftAttachment,
	messageDraftAttachments,
	single
};
