import FormComponentView from '../form-component-view';
import { compile } from 'handlebars';
import { defer, get, throttle, noop, includes } from 'lodash';
import config from 'core/config';
import $ from 'jquery';
import t from 'translate';
import cwalert from 'cwalert';
import getFileType from 'service/get-file-type';
import Fallback from './fallback/fallback';
import executeIfFunction from 'execute-if-function';
import backendConfig from 'service/backend-config';
import fileTpl from './file-template';
import previewTpl from './preview-template';
import { CSRF_HEADER, csrfToken } from 'service/csrf';

const MAX_SIZE = 2000000;
const MAX_FILE_SIZE = get(backendConfig, 'attachment.max_file_size', MAX_SIZE);
const CONTAINER_CLASS = 'form-view__file-container';
const fileInput = FormComponentView.extend({
	template: compile(fileTpl),

	initialize () {
		this.model.set(
			'fileDescAlternative',
			t('or {pseudoLinkStart}choose a file{pseudoLinkEnd} to upload', {
				pseudoLinkStart: '<span class="form-view__file-pseudolink">',
				pseudoLinkEnd: '</span>'
			})
		);
		this.fileModel = this.model.get('fileModel');
		this.listenTo(this.fileModel, 'change', function () {
			this.model.set('value', this.fileModel.toJSON());
		});

		if (this.model.has('template')) {
			this.template = this.model.get('template');
		}

		this.ALLOWED_MIME_TYPES = get(
			backendConfig.getBackendConfigValue(`${this.model.get('constraints')}FileConstraints`),
			'mimeTypes'
		);
	},

	valueChanged: noop,

	onAfterRender () {
		this.previewTemplate = compile(previewTpl)(this.fileModel.toJSON());
		this.isNew = this.fileModel.isNew();

		this.$(`.${CONTAINER_CLASS}`).attr('tabindex', '0');
		this.$(`.${CONTAINER_CLASS}`).on('click keyup', (e) => {
			if (this.model.get('readonly')) {
				return;
			}

			if (
				(!$(e.target).hasClass(CONTAINER_CLASS) && !e.keyCode) ||
				(e.keyCode === 13)
			) {
				this.$(`.${CONTAINER_CLASS}`).trigger('click');
			}
		});

		defer(() => {
			import(
				/* webpackChunkName: "dropzone" */
				'dropzone'
			).then((module) => {
				const Dropzone = module.default;
				this.dropzone = new Dropzone(`.${CONTAINER_CLASS}`, this.getDropzoneConfig());
				!this.isNew && this.setUrl();

				// temporary solution, only one simple enough with no regression risk
				$('.dz-remove').attr('title', t('Remove'));
			});
		});
	},

	getDropzoneConfig () {
		const that = this;
		const url = this.isNew ?
			config().backendRootUrl['default'] + this.fileModel.url() :
			this.fileModel.url;

		if (this.isNew) {
			this.$(`.${CONTAINER_CLASS}`).addClass(`${CONTAINER_CLASS}--is-new`);
		}

		return {
			headers: {
				[CSRF_HEADER]: csrfToken(),
				'x-mobile-gui': 1,
				'x-mobile-url': window.location.href
			},
			withCredentials: true,
			addRemoveLinks: this.isNew,
			uploadMultiple: false,
			url,
			thumbnailWidth: 300,
			thumbnailHeight: 300,
			dictRemoveFile: '',
			dictFileTooBig: t('File is too big ({{filesize}}MiB) Max filesize {{maxFilesize}}MiB'),
			previewTemplate: this.previewTemplate,
			maxFilesize: get(
				backendConfig.getBackendConfigValue('attachment'),
				'max_file_size',
				MAX_SIZE
			) / 1000000,

			fallback () {
				that.$(`.${CONTAINER_CLASS}`)
					.addClass(`${CONTAINER_CLASS}--fallback`);

				const fallback = new Fallback({
					uploadUrl: this.options.url,
					fileModel: that.fileModel
				});

				fallback.render().$el.appendTo(this.element);

				that.listenTo(fallback.model, 'change', () => {
					that.fileModel.set(fallback.model.omit('type', 'isImage'));
				});
			},

			// eslint-disable-next-line max-statements
			init () {
				that.dropzone = this;

				this.on('complete', that.onComplete.bind(that));

				this.on('removedfile', throttle((file) => {
					that.onRemovedFile.call(that, file);
				}, 100, { trailing: false }));

				if (!that.isNew) {
					// Create the mock file and add it to dropzone
					const file = that.fileModel.toJSON();
					file.type = file.mimeType;
					this.emit('addedfile', file);
					this.files.push(file);

					if (getFileType(that.fileModel.get('mimeType')) === 'image') {
						this.emit('thumbnail', file, that.fileModel.getUrl());
					}

					that.fileModel.set('mimeType', file.type);
					that.handleSuccess(file);
					that.setUrl();
				}
			}
		};
	},

	onComplete (file) {
		if (file.status === 'error' || !includes(this.ALLOWED_MIME_TYPES, file.type)) {
			this.handleError(file);
		} else {
			this.fileModel.set('mimeType', file.type);
			this.handleSuccess(file);
			this.setUrl();

			executeIfFunction(this.model.get('onComplete'));
		}
	},

	onRemovedFile () {
		if (!this.isNew || !this.fileModel.get('url')) {
			return;
		}
		const name = this.fileModel.get('name');
		this.fileModel.destroy().done(() => {
			cwalert.success(t('Successfully removed file "{name}"', { name }));
		});
	},

	setUrl () {
		const href = this.fileModel.getUrl();

		this.$('.form-view__file-preview-link')
			.attr({ href })
			.on('click', (e) => {
				e.stopPropagation();
			});
	},

	handleError (file) {
		if (file.size > MAX_FILE_SIZE) {
			cwalert.error(t('File is too big ({filesize}MiB) Max filesize {maxFilesize}MiB',
				{
					filesize: Math.round(file.size / 10000) / 100,
					maxFilesize: Math.round(MAX_FILE_SIZE / 10000) / 100
				}));
		} else {
			cwalert.error(t('The file could not be uploaded'));
		}
	},

	handleSuccess (file) {
		if (file.xhr) {
			// allow only one file at a time
			if (this.dropzone.files.length > 1 && !this.model.get('multi')) {
				this.dropzone.emit('removedfile', this.dropzone.files.shift());
			}

			this.fileModel.set($.parseJSON(file.xhr.response));
			cwalert.success(t('Successfully added file "{name}"', {
				name: this.fileModel.get('name')
			}));
		}

		// add content type related icon
		const fileType = getFileType(this.fileModel.get('mimeType'));

		if (fileType !== 'image') {
			this.$('.form-view__file-image-container')
				.addClass(`form-view__file-image-container--${fileType}`);
		}
	}
});

export { fileInput as file };
