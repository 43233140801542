import { find, forEach, get, isArray, isUndefined, reduce } from 'lodash';
import config from 'core/engine/card/config/card-config';
import tileManager from 'tileManager';
import aclCheck from 'core/engine/tile/helpers/acl-check';
import featuresCheck from 'core/engine/tile/helpers/features-check';
import can from 'acl-can';
import store from 'store';

const arrayWrap = (acl) => isArray(acl) ? acl : [acl];

// let an empty card pass
const initialCount = (cfg) => get(cfg, 'tiles', []).length ? 0 : 1;

const cardAclCheck = (card = {}) => {
	const cardAcl = arrayWrap(card.acl);

	if (!isUndefined(cardAcl[0])) {
		const approved = reduce(cardAcl, (current, row) => {

			if (!can.read(row)) {
				return false;
			}
			return current;
		}, true);

		return approved;

	}

	return true;
};

export default (cardName) => {
	const cardCfg = config(cardName);
	let aclPassedCount = initialCount(cardCfg);
	let mandatoryFailed = false;
	const card = find(store.getters['engine/cards/registered'], { name: cardName });

	if (get(cardCfg, 'disabled') || !cardAclCheck(card)) {
		return false;
	}

	if (get(card, 'redirect') === true) {
		return true;
	}

	forEach(get(cardCfg, 'tiles'), (tile) => {
		const tileView = tileManager.getTileView(tile.tileName);

		const canPass = () => {
			const aclPass = aclCheck(get(tileView, 'acl', []));
			const featurePass = featuresCheck(get(tileView, 'features', []));

			return aclPass && featurePass;
		};

		if (canPass()) {
			aclPassedCount++;

		} else if (tile.mandatory) {
			mandatoryFailed = true;
			return false;
		}
	});

	return !mandatoryFailed && (aclPassedCount > 0);
};
