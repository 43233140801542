<template>
	<li :class="mainClassNames">
		<button
			v-if="hasActiveChildren"
			:class="toggleClassNames"
			@click="toggle(item.path)"
		>
			<icon
				:small="true"
				:class-name="iconClassName"
				type="fontawesome"
				:name="item.expanded ? 'minus-square' : 'plus-square'"
			/>
		</button>
		<input
			v-if="!item.root"
			:key="checkboxKey"
			class="tree-item__checkbox"
			type="checkbox"
			:checked="checked"
			:indeterminate.prop="isIndeterminate && !checked"
			@click="toggleEdit(item, { source: 'input' })"
		/>
		<icon
			:name="iconName()"
			:class-name="iconClasses()"
		/>
		<button
			:class="buttonClassNames"
			@click="onNameClick"
		>
			<span
				:class="elementClass"
			>
				{{itemName}}
				<span v-if="item.mirrorSuffix">
					{{suffixName}}
					(<icon
						name="mirror"
						:small="true"
						class-name="tree-item__mirror-icon"
					/>
					{{mirrorName}})
				</span>
			</span>
		</button>
		<tree-list
			v-if="hasActiveChildren"
			v-show="item.expanded"
			:root="item.root"
			:blueprints="blueprints"
			:checked-items="checkedItems"
			:children="item.children"
			:copy="copy"
			:copy-path="copyPath"
			:disabled="disabled"
			:dispatch="dispatch"
			:getters="getters"
			:icons="icons"
			:incomplete-paths="incompletePaths"
			:indeterminate="indeterminate"
			:mirror="!!item.mirror"
			:mirror-details="mirrorDetails"
			:mirrors="mirrors"
			:path="item.path"
			:parent-active="rowActive"
			:parent-expanded="item.expanded"
			:pass-button-refs="passButtonRefs"
			:position="position"
			:root-mirrors="rootMirrors"
			:selected="selected"
			:set-copy-path="setCopyPath"
			:toggle="toggle"
			:toggle-edit="toggleEdit"
			:name-path="namePath"
			:warning-paths="warningPaths"
			:on-add="onAdd"
			@passButtonRefs="passButtonRefs"
		/>
	</li>
</template>

<script>
import Vue from 'vue';
import cwalert from 'service/cwalert';
import sharedProps from '../shared/props';
import t from 'translate';
import { get, includes, size, split } from 'lodash';

// marked things that needs further investigation with `wtf`, good luck in the future

const ITEM_PROPS = {
	activeParent: {
		type: Boolean,
		default: false
	},
	item: {
		type: Object,
		required: true
	},
	parentRoot: {
		type: Boolean,
		default: false
	}
};

export default Vue.component('tree-item', {
	props: { ...sharedProps, ...ITEM_PROPS },

	data: () => ({
		active: false,
		timestamp: Date.now(),
		t
	}),

	computed: {
		buttonClassNames: ({ isActive }) => ({
			'tree-item__button': true,
			'tree-item__button--active': isActive
		}),

		checked: ({ checkedItems, item }) => includes(checkedItems, item.path),

		// checkbox needs to be rerendered if a child clicked while a parent is selected
		checkboxKey () {
			const selected = includes(this.selected, this.item.path);
			const checked = includes(this.checkedItems, this.item.path);
			return selected && checked ? Date.now() : this.timestamp;
		},

		elementClass ({ item }) {
			const highlighted = item.highlighted && item.position !== this.position;
			const highlightedActive = item.highlighted && item.position === this.position;
			const classes = {
				'tree-item__warning': this.checkWarning(),
				'tree-item--highlighted': highlighted,
				'tree-item--highlighted-active': highlightedActive,
				'tree-item__mirror': this.mirror
			};
			classes[this.pathClass(item.path)] = true;
			return classes;
		},

		toggleClassNames: ({ item }) => ({
			'tree-item__state': true,
			'tree-item__state-root': item.root
		}),

		hasActiveChildren: ({ item }) => item.mirror ? !!size(item.children) : !!item.children,

		iconClassName: ({ activeParent }) => activeParent ?
			'tree-item__icon-transparent' :
			'',

		isIndeterminate: ({ indeterminate, item }) => includes(indeterminate, item.path),

		itemMirrorDetails () {
			if (!this.item.mirror) {
				return {};
			}
			return this.mirrorDetails[this.item.path];
		},

		itemName () {
			return get(this.item, this.namePath);
		},

		mainClassNames: ({ item, parentRoot, rowActive }) => ({
			'tree-item': true,
			'tree-item__row--active': rowActive, // wtf, 'row--active'?
			'tree-item__row--expanded': rowActive && item.expanded,  // wtf, 'row--expanded'?
			'tree-item__root-child': parentRoot // wtf, 'root-child'?
		}),

		mirror () {
			return includes(this.mirrors, this.item.path);
		},

		mirrorName () {
			return this.itemMirrorDetails.mirrorSuffix ?
				`${this.itemMirrorDetails.name} .${this.itemMirrorDetails.mirrorSuffix}` :
				`${this.itemMirrorDetails.name}`;
		},

		rootMirror () {
			return includes(this.rootMirrors, this.item.path);
		},

		rowActive () {
			return (this.item.path === this.copyPath) ||
				(includes(this.copy, this.item.path) && !this.copyPath);
		},

		suffixName () {
			return this.item.mirrorSuffix  ? `.${this.itemMirrorDetails.suffix}` : '';
		},

		isActive: ({ getters, item }) => getters('activePath') === item.path
	},

	methods: {
		checkWarning () {
			const duplicatedName = includes(this.warningPaths, this.item.path);
			const incompleteName = includes(this.incompletePaths, this.item.path);
			return duplicatedName || incompleteName;
		},

		iconClasses () {
			return this.checkWarning() ? 'tree-item__icon tree-item__warning' : 'tree-item__icon';
		},

		iconName () {
			if (this.checkWarning()) {
				return this.icons['warning'];
			}
			return this.icons[this.item.type] || 'question-mark';
		},

		pathClass (name = '') {
			const splitted = split(name, '.');
			return splitted.length ? splitted.join('_') : name;
		},

		onNameClick () {
			if (!this.copy.length) {
				this.toggleEdit(this.item, { source: 'name' });
				this.dispatch('setActivePath', this.item.path);

			} else if (this.mirror && !this.rootMirror) {
				cwalert.error(t('No paste into a mirror'));

			} else {
				this.active = this.item.path !== this.copyPath;
				const path = this.active ? this.item.path : null;
				this.setCopyPath(path);
			}
		}
	}
});
</script>
