import MultipleEntities from '../shared/multiple-entities';
import AbuseReportStatus from './abuse-report-status';
import urls from './urls';

const CircleAbuseReportStatuses = new (MultipleEntities.extend({
	model: AbuseReportStatus,
	eventNamespace: 'circle-abuse-report-status',
	urls,

	list () {
		return this.retrieve('abuseReportStatus');
	}
}))();

export { CircleAbuseReportStatuses };
