<template>
	<ul>
		<li
			v-for="(value, index) in objVal"
			:key="index"
			class="edit-object__row edit-object__row--edit"
		>
			<p class="array-row__index">
				{{index}}
			</p>
			<input
				v-trim
				type="text"
				:value="value"
				:readonly="readonly"
				@blur="onArrayValueChange(index, $event)"
				v-on="inputListeners"
			/>
			<button
				v-if="!readonly"
				class="edit-object__button--remove"
				@click="removeArrayValue(index)"
			/>
		</li>
		<li class="edit-object__row edit-object__row--add">
			<input
				v-model="newArrayValue"
				v-trim
				type="text"
				:readonly="readonly"
				v-on="inputListeners"
			/>
			<button v-if="!readonly" class="edit-object__button--add" @click="addArrayValue" />
		</li>
	</ul>
</template>

<script>
import { cloneDeep, trim } from 'lodash';
import inputMixin from './__input-mixin';
import Vue from 'vue';

export default Vue.component('arrayInput', {
	mixins: [inputMixin],
	props: {
		objVal: {
			type: Array,
			required: true
		}
	},
	data: () => ({
		newArrayValue: ''
	}),

	methods: {
		onArrayValueChange (index, e) {
			this.objVal[index] = trim(e.target.value);
			this.onValueChange({ path: this.path, value: this.objVal });
		},

		addArrayValue () {
			const value = cloneDeep(this.objVal);
			value.push(this.newArrayValue);
			this.newArrayValue = '';
			this.onValueChange({ path: this.path, value });
		},

		removeArrayValue (index) {
			const value = cloneDeep(this.objVal);
			value.splice(index, 1);
			this.onValueChange({ path: this.path, value });
		}
	}
});
</script>
