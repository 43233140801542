<template>
	<table
		v-if="sortedItems.length"
		class="table-list table table--long-text"
	>
		<thead>
			<table-row>
				<table-heading
					v-for="column in columns"
					:key="column.name"
					@click.native="maybeSort(column)"
				>
					<slot
						name="header"
						:column="column"
						:content="column.label"
					>
						{{column.label}}
						<icon
							v-if="sortable"
							:name="sortIcon(column)"
						/>
					</slot>
				</table-heading>
			</table-row>
		</thead>

		<transition-group tag="tbody" name="fade">
			<table-row
				v-for="item in sortedItems"
				:key="item[itemKey]"
				:class="rowClassName(item)"
				class="table__row"
				tabindex="0"
				@keyup.enter.native="onRowClick(item)"
				@click.native="onRowClick(item)"
			>
				<table-cell
					v-for="column in columns"
					:key="`${item[columns[0].key]}:${column.key}`"
					:class="cellClassName(column)"
					class="table__cell"
				>
					<slot name="cell-label">
						<p class="table-cell-label">
							{{column.label}}
						</p>
					</slot>

					<slot
						name="cell"
						:item="item"
						:column="column"
						:content="getContent(column.key, { data: item }).value"
					>
						{{getContent(column.key, { data: item }).value}}
					</slot>
				</table-cell>
			</table-row>
		</transition-group>
	</table>
	<p v-else>
		{{$t('table.empty')}}
	</p>
</template>

<script>
import jsonQuery from 'json-query';
import { cloneDeep, constant, get, identity, kebabCase, reverse, sortBy, toLower } from 'lodash';
import TableCell from './table-cell';
import TableHeading from './table-heading';
import TableRow from './table-row';

const localState = {};

export default {
	components: { TableCell, TableHeading, TableRow },
	props: {
		items: {
			type: Array,
			default: constant([])
		},
		itemKey: {
			type: String,
			default: 'id'
		},
		columns: {
			type: Array,
			default: constant([])
		},
		sortable: {
			type: Boolean,
			default: false
		},
		itemValue: {
			type: Function,
			default: identity
		},
		tilePath: {
			type: String,
			required: true
		}
	},

	data () {
		return {
			sortColumn: get(localState, `${this.tilePath}.sortKey`, this.columns[0]),
			sortReverse: get(localState, `${this.tilePath}.sortReverse`, false)
		};
	},

	computed: {
		sortKey: ({ sortColumn }) => get(sortColumn, 'key'),

		sortedItems: ({ itemValue, items, sortable, sortColumn, sortKey, sortReverse }) => {
			const clone = cloneDeep(items);

			if (sortable) {
				const value = (item) => itemValue(item, sortColumn);

				const sorted = sortBy(items, (item) => toLower(
					value(
						jsonQuery(sortKey, { data: item }).value,
						sortKey
					)
				));

				return sortReverse ? reverse(sorted) : sorted;
			}

			return clone;
		}
	},

	watch: {
		sortColumn (sortKey) {
			localState[this.tilePath] = { ...localState[this.tilePath], sortKey };
		},

		sortReverse (sortReverse) {
			localState[this.tilePath] = { ...localState[this.tilePath], sortReverse };
		}
	},

	methods: {
		getContent: jsonQuery,

		maybeSort (column) {
			this.sortReverse = this.sortColumn.key === column.key ? !this.sortReverse : false;
			this.sortColumn = column;
		},

		sortIcon (column) {
			if (column.key === this.sortColumn.key) {
				return this.sortReverse ? 'sort-desc' : 'sort-asc';
			}
			return 'sort';
		},

		cellClassName: (column) => ({
			[`table__cell--optional`]: column.optional,
			[`table__cell--${kebabCase(column.key)}`]: true
		}),

		rowClassName: (item) => get(item, 'classNames', {}),

		onRowClick (item) {
			this.$emit('row-click', item);
		}
	}
};
</script>
