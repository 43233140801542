import repository from 'repository';
import prefixify from '../__helpers/prefixify';
import { exact } from '../__helpers/getters';
import { get, isNil, findIndex } from 'lodash';
import { updateHashMap } from '../__helpers/mutations';
import Vue from 'vue';
const prefix = prefixify('rawEntities');

const ADD_ENTITIES = 'addEntities';
const DATA = 'data';
const ID_KEYS = 'idKeys';
const FETCH = 'fetch';
const UPDATE_ONE = 'updateOne';
const SET_ENTITIES = 'setEntities';
const UPDATE_ENTITY = 'updateEntity';
const CLEAR_ENTITIES = 'clearEntities';
const TOTAL_COUNT = 'totalCount';
const SET_TOTAL_COUNT = 'setTotalCount';
const CLEAR_TOTAL_COUNT = 'clearTotalCount';
const SET_ID_KEY = 'setIdKey';
const SAVE_ID_KEY = 'saveIdKey';
const CLEAR = 'clear';

export const RAW_ENTITIES = {
	DATA: prefix(DATA),
	FETCH: prefix(FETCH),
	UPDATE_ONE: prefix(UPDATE_ONE),
	SAVE_ID_KEY: prefix(SAVE_ID_KEY),
	TOTAL_COUNT: prefix(TOTAL_COUNT),
	CLEAR: prefix(CLEAR)
};

export default {
	namespaced: true,

	state: {
		[DATA]: {},
		[TOTAL_COUNT]: {},
		[ID_KEYS]: {}
	},

	getters: {
		...exact(DATA),
		...exact(TOTAL_COUNT)
	},

	mutations: {
		[SET_ENTITIES]: updateHashMap(),
		[ADD_ENTITIES]: (state, { id, payload }) => {
			state[DATA][id].push(...payload);
		},
		[UPDATE_ENTITY]: (state, { id, entityIndex, entity }) => {
			Vue.set(state[DATA][id], entityIndex, entity);
		},
		[SET_TOTAL_COUNT]: updateHashMap(TOTAL_COUNT),
		[SET_ID_KEY]: (state, { id, idKey }) => {
			state[ID_KEYS][id] = idKey;
		},
		[CLEAR_ENTITIES]: (state, { id }) => {
			Vue.set(state[DATA], id, []);
		},
		[CLEAR_TOTAL_COUNT]: (state, { id }) => {
			Vue.set(state[TOTAL_COUNT], id, 0);
		}
	},

	actions: {
		[SAVE_ID_KEY]: ({ commit }, { id, idKey }) => {
			if (id && idKey) {
				commit(SET_ID_KEY, { id, idKey });
			}
		},
		[FETCH]: ({ commit }, { url, id, add = false }) => {
			const entities = repository.rawGET(url);
			return entities.then((response) => {
				const mutation = add ? ADD_ENTITIES : SET_ENTITIES;
				const rawTotalCount = entities.getResponseHeader('x-total-count');
				const data = response;
				const totalCount = isNil(rawTotalCount) ? rawTotalCount : +rawTotalCount;

				commit(SET_TOTAL_COUNT, { id, payload: totalCount });
				commit(mutation, { id, payload: data });
			});
		},
		[UPDATE_ONE]: ({ commit, state }, {
			url,
			id,
			entityId,
			method = 'PUT',
			payload: { key, value }
		}) => {
			const entityIndex = findIndex(
				state[DATA][id],
				[state[ID_KEYS][id], entityId]
			);

			const patchData = { [key]: value };

			const entityData = {
				...state[DATA][id][entityIndex],
				...patchData
			};

			let request;

			if (method === 'PATCH') {
				request = repository.rawPATCH(url, patchData);
			} else {
				request = repository.rawPUT(url, entityData);
			}

			return request.then((response) => {
				const savedEntity = get(response, 'data', entityData);

				commit(UPDATE_ENTITY, { id, entityIndex, entity: savedEntity });
			});
		},
		[CLEAR]: ({ commit }, { id }) => {
			commit(CLEAR_ENTITIES, { id });
			commit(CLEAR_TOTAL_COUNT, { id });
		}
	}
};
