import TileView from 'tile-view';

export default TileView.extend({
	title: 'Dev Test',
	instant: true,

	Vue: () => ({
		data: {
			test: 'Test'
		},
		template: `
		<section>
			{{test}}
		</section>`
	})
});
