import Modernizr from 'modernizr';
import parseUrl from 'parse-url';
import url from 'build-url';
import layoutAdjustment from '../layout-adjustment';
import appUrl from 'service/app-url/app-url';
import $ from 'jquery';
import { warn } from 'service/log/log';

const mediaTypes = {
	'mp3': 'audio/mpeg',
	'ogg': 'audio/ogg'
};

export default function (runtime) {
	const $box = $('<div class="assessment-container assessment-audiovideo__container" />');
	const $wrapper = $('<div class="assessment-audiovideo__player" />').appendTo($box);

	!runtime.isVisible() && $box.hide();
	layoutAdjustment(runtime, $box[0]);

	let mediaUrl = runtime.getMediaUrl();
	const fileExtension = mediaUrl.split('.').pop();

	if (/^https?:\/\/www.youtube/.test(mediaUrl)) {

		try {
			const parsedUrl = parseUrl(mediaUrl);

			if (parsedUrl.queryParams.v) {
				mediaUrl = '' + url('https://www.youtube.com/embed/' + parsedUrl.queryParams.v, {
						showinfo: 0,
						theme: 'light',
						rel: 0,
						modestbranding: 1,
						wmode: 'opaque'
					});
			}
		} catch (e) {
			warn(e);
		}

		$wrapper.addClass('assessment-audiovideo__player--youtube');

		if (!Modernizr.video.h264 || !Modernizr.video.ogg) {
			$wrapper.addClass('assessment-audiovideo__player--flash');
		}
		const $iframe = $('<iframe class="assessment-audiovideo__iframe" />')
			.attr({
				src: mediaUrl
					.replace(/^http:/, 'https:')
					.replace(/\/watch\?v=([\w-]+).*/, '/embed/$1'),
				frameborder: '0'
			});
		$wrapper.html($iframe);

	} else if (/\.gif$/.test(mediaUrl)) {
		$wrapper.addClass('assessment-audiovideo__player--gif');
		const $img = $('<img class="cwgif" />').attr({
			title: '',
			src: mediaUrl
		});
		$wrapper.append($img);

	} else if (runtime.getMediaType() === 'audio') {
		const type = mediaTypes[fileExtension] ? 'type="' + mediaTypes[fileExtension] + '"' : '';
		$wrapper.html(`
				<audio controls="controls">
					<source src="${appUrl(mediaUrl)}" ${type} />
					<object 
						type="application/x-shockwave-flash" 
						data="swf/player_mp3_mini.swf" 
						width="200" 
						height="20"
					>
    				    <param name="movie" value="swf/player_mp3_mini.swf" />
    				    <param name="bgcolor" value="#000000" />
    				    <param name="FlashVars" value="mp3=${appUrl(mediaUrl)}" />
						<p class="assessment-audiovideo__player--fallback">
							Audio element and Flash Player are not supported! 
							<a href="https://get.adobe.com/flashplayer/">Install flash player.</a>
						</p>
					</object>
				</audio>
			`);

	} else { // embedded video
		const type = mediaTypes[fileExtension] ? 'type="' + mediaTypes[fileExtension] + '"' : '';
		const flashVars = 'config={&quot;playerId&quot;:&quot;player&quot;,&quot;clip&quot;:' +
			'{&quot;url&quot;:&quot;\' + mediaUrl + \'&quot;}}"';
		$wrapper.addClass('assessment-audiovideo__player--universal');
		$wrapper.html(`
				<video controls="controls">
					<source src="${appUrl(mediaUrl)}" ${type} />
					<object 
						type="application/x-shockwave-flash" 
						data="swf/flowplayer-3.2.18.swf" 
					>
						<param name="movie" value="swf/flowplayer-3.2.18.swf" />
						<param name="allowFullScreen" value="true" />
						<param 
							name="flashVars" 
							value="${flashVars}" 
						/>
						<p class="assessment-audiovideo__player--fallback">
							Video element and Flash Player are not supported! 
							<a href="https://get.adobe.com/flashplayer/">Install flash player. </a>
						</p>
					</object>\
				</video>\
			`);
	}

	this.getElementBox = function () {
		return $box[0];
	};
}
