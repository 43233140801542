import repository from 'repository';

export default {
	namespaced: true,
	state: {
		id: null,
		roles: null
	},
	getters: {
		roles: (state) => state.roles
	},
	mutations: {
		eraseRoles: (state) => {
			state.roles = null;
		},
		setId: (state, id) => {
			state.id = id;
		},
		setRoles: (state, list) => {
			state.roles = list;
		}
	},
	actions: {
		addRoleToRoleSet: ({ dispatch, state }, data) => {
			data.roleSetId = state.id;
			const result = repository.addRoleSetRole(data);
			result.then(() => {
				dispatch('init', state.id);
			});
			return result;
		},
		addNewRoleToRoleSet: ({ dispatch }, data) => {
			const result = repository.addRole(data);
			return result.then((response) => {
				const data = { roleId: response.id };
				return dispatch('addRoleToRoleSet', data);
			});
		},
		deleteRoleFromRoleSet: ({ dispatch, state }, data) => {
			data.roleSetId = state.id;
			const result = repository.deleteRoleSetRole(data);
			result.then(() => {
				dispatch('init', state.id);
			});
			return result;
		},
		getSingleRoleSet: ({ commit, dispatch }, id) => {
			commit('eraseRoles');
			commit('setId', id);
			dispatch('init', id);
		},
		init: ({ commit }, id) => repository.roleSet(id).then((results) => {
			commit('setRoles', results);
		}),
		setRoleRequired: ({ state }, data) => {
			data.roleSetId = state.id;
			return repository.updateRoleSetRole(data);
		},
		updateRoleInRoleSet: ({ dispatch, state }, data) => {
			const result = repository.updateRole(data);
			result.then(() => {
				dispatch('init', state.id);
			});
			return result;
		}
	}
};
