import { param } from 'jquery';

export const storeApps = {
	url: 'backend-commons/app/'
};

export const activated = {
	url: 'backend-commons/active-app/'
};

export const activatedList = {
	url: (params = '') =>
		`backend-commons/active-app/?${decodeURIComponent(param(params))}`
};

export const connectedList = {
	url: (params = '') =>
		`backend-commons/active-app/connection/?${decodeURIComponent(param(params))}`
};

export const connected = {
	url: () => `backend-commons/active-app/connection/`
};

export default {
	storeApps,
	activated,
	activatedList,
	connected,
	connectedList
};
