/**
 * @class Repository
 */
export default {
	/**
	 * Get all pages
	 * @method getPages
	 * @return {Object} new {{#crossLink "{Pages"}}{{/crossLink}} Collection
	 * @example
	 *        Repository.getPages();
	 */
	getPages: function () {
		return this.Pages.getList();
	},

	/**
	 * Get all pages, including (soft) deleted
	 * @method getAllPages
	 * @return {Object} new {{#crossLink "Pages"}}{{/crossLink}} Collection
	 * @example
	 *        Repository.getAllPages();
	 */
	getAllPages: function () {
		return this.Pages.getAll();
	},

	getPageById: function (pageId) {
		return this.Pages.getById(pageId);
	},

	getPageByPermalink: function (alias) {
		return this.Pages.getByPermalink(alias);
	},

	getPageCategories: function () {
		return this.PageCategories.getAll();
	},

	getRandomPage: function (category) {
		return this.Pages.getRandom(category);
	},

	/**
	 * Search pages
	 * @param params
	 * @return {Object} new {{#crossLink "{Pages"}}{{/crossLink}} Collection
	 */
	searchPages: function (params) {
		return this.Pages.search(params);
	},

	/**
	 * Get pages with roles
	 * @param {Object} params            - Parameters.
	 * @param {number} params.treatment  - Treatment ID.
	 * @param {number} params.respondent - Respondent ID.
	 * @returns {Object} new {{#crossLink "{Pages"}}{{/crossLink}} Collection
	 * @example
	 *        repository.getTreatmentPagesWithRoles(params);
	 */

	getTreatmentPages (params) {
		return this.Pages.listWithRoles(params);
	}
};
