import { cwtagsNl2br } from '../../helpers/string';
import { assign, forEach, noop } from 'lodash';
import $ from 'jquery';
import { init } from '../shared/view';
import { questionTextId } from '../shared/helpers';

export default function (model, assessment) {
	const { $container, $questionText, $answerContainer } = init({
		className: 'assessment-combobox-container',
		model
	});

	const $select = $('<select />')
		.prop('disabled', model.isReadonly())
		.attr('aria-labelledby', questionTextId(model))
		.appendTo($answerContainer);

	// Used for keeping options removed by `hide` expressions and allow them to be selected in
	// main handler (present in tile)
	const $ghostSelect = $('<select />').hide().appendTo($answerContainer);

	const $emptyOption = $('<option />').appendTo($select);

	const highlightIfAnswered = () => {
		$container.toggleClass('highlightedRow', model.isAnswered());
	};
	highlightIfAnswered();

	const answers = model.getAnswers();

	forEach(answers, (answer) => {
		const $option = $('<option />')
			.addClass(answer.getTemplateId())
			.val(answer.getTemplateId())
			.html(cwtagsNl2br(answer.getLabel()))
			.attr('id', answer.getId());

		$option.data('onHide', () => {
			$ghostSelect.append($option);
			answer.select();
			answer.deselect();
			$option.prop('selected', false);
			$emptyOption.prop('selected', true);
		});

		$option.data('onShow', () => {
			$select.append($option);
			$option.prop('selected', false);
			$emptyOption.prop('selected', true);

			// Make sure changes are propagated to the awesome custom-everything framework
			answer.select();
			answer.deselect();
		});

		if (answer.isSelected()) {
			$option.prop('selected', true);
		}

		if (!answer.isVisible()) {
			// Model is initiated once, view is re-initiated on every page render.
			// "Real time" logic has to be done here, e.g. deselecting the answer when it's hidden
			answer.deselect();
			$ghostSelect.append($option);

		} else {
			$select.append($option);
		}
	});

	$select.on('change', (event) => {
		const code = event.target[event.target.selectedIndex].value;

		if (answers[code]) {
			answers[code].select();

		} else {
			model.resetAnswer();
		}

		highlightIfAnswered();
		assessment.startSaving();
	});

	assign(this, {
		updateText: () => {
			$questionText.html(cwtagsNl2br(model.getQuestionText()));
		},
		getElementBox: () => $container[0],
		clear: () => {
			$('option:selected', $container).prop('selected', false);
		},
		unclear: noop
	});
}

