import repository from 'repository';
import { map, sortBy } from 'lodash';
import { cleanClinicianRole } from '../__helpers/clean';

export default {
	namespaced: true,

	state: {
		all: []
	},

	getters: {
		all: (state) => sortBy(
			map(state.all, cleanClinicianRole),
			({ name = '' }) => name.toLocaleLowerCase()
		)
	},

	mutations: {
		setAll: (state, { roles }) => {
			state.all = roles;
		}
	},

	actions: {
		initAll: ({ commit }) => {
			const roles = repository.getActiveClinicianRoles();

			return roles.then(() => {
				commit('setAll', { roles: roles.toJSON() });
			});
		}
	}
};
