import repository from 'repository';
import { assign, get, unset } from 'lodash';
import { respondentName } from 'service/display-name/display-name';
import deniedClinicians from './denied-clinicians';
import attributes from './respondent-attributes';
import { TREATMENT } from 'store/treatments/rp-treatment';
import { CUBE } from 'store/cube/cube';
import { APP } from 'store';

const prefix = 'respondent/';

const DISPLAY_NAME = 'displayName';
const TIMEZONE = 'timezone';
const RESPONDENT_ID = 'respondentId';
const SUMMARY = 'summary';
const MESSAGES_COUNT = 'messagesCount';
const DATA = 'data';
const LIST = 'list';

const INIT_SUMMARY = 'initSummary';

export const RESPONDENT = {
	DISPLAY_NAME: `${prefix}${DISPLAY_NAME}`,
	TIMEZONE: `${prefix}${TIMEZONE}`,
	RESPONDENT_ID: `${prefix}${RESPONDENT_ID}`,
	SUMMARY: `${prefix}${SUMMARY}`,
	MESSAGES_COUNT: `${prefix}${MESSAGES_COUNT}`,
	DATA: `${prefix}${DATA}`,
	LIST: `${prefix}${LIST}`,
	INIT_SUMMARY: `${prefix}${INIT_SUMMARY}`
};

const CUBE_SUMMARY_ID = 'respondentSummary';

export default {
	namespaced: true,

	modules: {
		attributes,
		deniedClinicians
	},

	state: {
		data: null,
		model: null,
		currentRespondentId: undefined,
		list: []
	},

	getters: {
		[DISPLAY_NAME]: (state) => respondentName(state.data),

		// eslint-disable-next-line max-params
		[TIMEZONE]: (state, getters, rootState, rootGetters) =>
			rootGetters.userType === 'respondent' ?
				rootGetters.userTimezone :
				get(state.data, 'respondentTimezoneName', ''),

		// eslint-disable-next-line max-params
		[RESPONDENT_ID]: (state, getters, rootState, rootGetters) =>
			rootGetters.userType === 'respondent' ?
				rootGetters.userId :
				get(
					rootState.cardData,
					'urlParams.respondentId',
					get(
						state,
						'currentRespondentId',
						get(
							state.data,
							'respondentId',
							get(rootState, 'treatment.data.respondent.id')
						)
					)
				),
		// eslint-disable-next-line max-params
		[SUMMARY]: (state, getters, rootState, rootGetters) => {
			const measures = get(rootGetters[CUBE.RESULTS], `${CUBE_SUMMARY_ID}.result[0]`);
			const summary = {
				messages: {
					count: 0
				}
			};

			if (measures) {
				summary.messages.count = measures['Messages.count'];
			}

			return summary;
		},
		[MESSAGES_COUNT]: (state, getters) => get(getters[SUMMARY], 'messages.count', 0),
		[DATA]: (state, getters) => assign({}, state.data, { displayName: getters[DISPLAY_NAME] }),
		[LIST]: (state) => state.list
	},

	mutations: {
		set: (state, { respondent, model }) => {
			state.data = respondent;

			if (model) {
				state.model = model;
			}

		},
		setList: (state, list) => {
			state.list = list;
		},
		setId: (state, id) => {
			state.currentRespondentId = id;
		},

		reset: (state) => {
			unset(state, 'currentRespondentId');
			state.data = null;
			state.model = null;
		}
	},

	actions: {
		init: ({ commit, getters, rootGetters, state }, { respondentId } = { respondentId: 0 }) => {
			const currentId = () =>
				respondentId || getters[RESPONDENT_ID];

			if (rootGetters.userType === 'clinician' && currentId()) {
				const model = state.model || repository.respondent(currentId());

				return model.then(() => {
					commit('set', { model, respondent: model.toJSON() });
				});
			}
			return {};
		},
		initList: ({ commit }) => {
			const respondentList = repository.respondents();
			return respondentList.then((response) => {
				commit('setList', response);
			});
		},
		// used in Respondent Portal
		initCurrent: ({ commit }) => {
			const model = repository.getCurrentRespondent();

			return model.then(() => {
				commit('set', { model, respondent: model.toJSON() });
			});
		},

		// used in Respondent Portal
		saveCurrent: (context, respondent) => repository.saveCurrentRespondent(respondent),

		[INIT_SUMMARY]: async (
			{ getters, rootGetters, dispatch },
			{
				treatmentId = rootGetters[TREATMENT.ID]
			} = {}
		) => {
			let mainFilter = {
				member: 'Messages.treatmentId',
				operator: 'equals',
				values: [treatmentId]
			};

			if (!treatmentId) {
				const respondentId = rootGetters[APP.USER_TYPE] === 'respondent' ?
					rootGetters[APP.USER_ID] :
					getters[RESPONDENT_ID];

				mainFilter = {
					member: 'Messages.respondentId',
					operator: 'equals',
					values: [respondentId]
				};
			}

			await dispatch(CUBE.LOAD, {
				id: CUBE_SUMMARY_ID,
				query: {
					measures: [
						'Messages.count'
					],
					timezone: rootGetters[APP.USER_TIMEZONE],
					filters: [
						mainFilter,
						{
							member: 'Messages.treatmentEnabled',
							operator: 'equals',
							values: [true]
						}
					]
				}
			}, { root: true });
		},

		setId: ({ commit }, respondentId) => commit('setId', respondentId),

		resetId: ({ commit }) => {
			commit('setId', undefined);
		}
	}
};
