import repository from 'repository';
import { filter, size, sortBy, cloneDeep, isArray, forEach } from 'lodash';
import { set, update, remove, idx } from '../__helpers/mutations';
import compareParams from '../__helpers/compare-params';

const prefix = 'dataIntegration/codeTypes';

const SORTED = 'sorted';

export const CODE_TYPES = {
	SORTED: `${prefix}/${SORTED}`
};

export default {
	namespaced: true,

	state: {
		list: [],
		params: {},
		initialParams: null,
		total: 0
	},

	getters: {
		list: (state) => state.list,
		[SORTED]: (state, getters) => sortBy(getters.list, ['name']),
		params: (state) => state.params,
		total: (state) => state.total,
		count: (state, getters) => size(getters.list),
		enabled: (state, getters) => filter(getters.list, { enabled: true })
	},

	mutations: {
		setList: (state, list) => {
			if (isArray(list)) {
				state.list = list;
			} else {
				state.list.push(list);
			}
		},
		setParams: set('params'),
		setTotal: set('total'),
		setInitialParams: (state, params) => {
			state.initialParams = params;
		},
		update: update('list'),
		remove: remove('list')
	},

	actions: {
		delete: async ({ commit, state }, id) => {
			const index = idx(state.list, id);
			const result = await repository.deleteCodeType(id);
			commit('remove', { index });
			return result;
		},

		search: ({ commit, state }, searchParams = state.params) => {
			if (searchParams) {
				commit('setParams', searchParams);
			}

			if (!state.initialParams) {
				const initialParams = cloneDeep(searchParams);
				delete initialParams.start;
				commit('setInitialParams', initialParams);
			}
			const noFilters = compareParams(state, searchParams);

			const results = repository.searchCodeTypes(state.params);

			return results.then((rawResults, statusText, xhrObj) => {
				const total = xhrObj.getResponseHeader('X-Total-Count');
				commit('setTotal', total);

				if (statusText === 'success') {
					if (noFilters) {
						forEach(rawResults, (item) => {
							const index = idx(state.list, item.id);

							if (index > -1) {
								commit('update', item);
							} else {
								commit('setList', item);
							}
						});
					} else {
						commit('setList', rawResults);
					}
				}
				return [results, statusText, xhrObj];
			});
		},

		update: (context, data) => repository.updateCodeType(data),

		add: (context, data) => repository.addCodeType(data)
	}
};
