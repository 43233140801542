var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "ul",
    { staticClass: "simple-list" },
    [
      _vm._l(_vm.props.items, function(item, i) {
        return _c(
          "li",
          {
            key: i,
            staticClass: "simple-list__item",
            class: _vm.props.className(item),
            attrs: { title: _vm.props.getTitle(item) }
          },
          [
            _c("a", {
              staticClass: "simple-list__item-link",
              attrs: { href: _vm.props.getUrl(item) },
              domProps: { innerHTML: _vm._s(_vm.props.content(item)) }
            })
          ]
        )
      }),
      _vm._v(" "),
      !_vm.props.items.length
        ? _c("p", { domProps: { textContent: _vm._s(_vm.props.emptyContent) } })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }