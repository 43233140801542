import systemSettings from 'system-settings';
import can from 'acl-can';
import { mapSettings } from './helpers';

const allowances = [
	{ card: 'clinician-my-profile', flag: 'ENABLE_CLINICIAN_PERSONAL_SETTINGS_PAGE' },
	{ card: 'unanswered-messages',  flag: 'ENABLE_MESSAGING_SYSTEM' },
	{ card: 'search-plans',         flag: 'ENABLE_WORKFLOWS' }
];

export default [
	...mapSettings(allowances), {
		card: 'circles-startpage',
		condition: () => systemSettings.getBoolean('ENABLE_CLINICIAN_CIRCLES') &&
			systemSettings.getBoolean('ENABLE_CIRCLES')
	}, {
		card: 'clinician-dashboard',
		condition: () => can.read('administrator.administrators.dashboard')
	}, {
		card: 'clinic-dashboard',
		condition: () => can.read('administrator.administrators.clinic-dashboard')
	}
];
