var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ol",
    { class: _vm.mainClassName },
    [
      _c(
        "transition-group",
        { attrs: { name: _vm.transitionName, tag: "div" } },
        _vm._l(_vm.processedItems, function(item) {
          return _c(
            "li",
            { key: _vm.itemIndex(item), class: _vm.elementClassName("item") },
            [
              _c("agenda-item", {
                attrs: {
                  actions: item.actions,
                  collapsed: _vm.collapsed,
                  description: item.description,
                  "horizontal-labels": item.horizontalLabels,
                  icon: item.icon,
                  "item-type": item.itemType,
                  labels: item.labels,
                  layout: item.layout,
                  "on-click": item.onClick,
                  status: item.status,
                  "store-path": _vm.storePath,
                  subtitle: item.subtitle,
                  title: item.title,
                  "type-label": item.typeLabel
                }
              })
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }