var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "circles-infobox" }, [
    _c(
      "span",
      { staticClass: "circles-infobox__text" },
      [
        _vm._v(
          "\n\t\t" +
            _vm._s(_vm.t("You are identified in the circles as")) +
            "\n\t\t"
        ),
        _c("strong", { staticClass: "circles-infobox__nickname" }, [
          _vm._v(_vm._s(_vm.nickname))
        ]),
        _vm._v("\n\t\t" + _vm._s(_vm.t("You're using the avatar")) + "\n\t\t"),
        _c("avatar", {
          attrs: {
            name: _vm.avatarName,
            color: _vm.avatarColour,
            classes: "circles-infobox__avatar"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "circles-infobox__link-container" }, [
      _c(
        "a",
        {
          staticClass: "circles-infobox__link",
          attrs: { href: _vm.profileUrl }
        },
        [_vm._v(_vm._s(_vm.t("Modify your settings")))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }