import SingleEntity from '../shared/single-entity';
import { connected } from './urls';
import { sync } from 'backbone';

/**
 * Store Apps Entity
 * @class ConnectedApps
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	eventNamespace: 'connected-app',
	urlRoot: connected.url,
	onSync: function (method, model, options) {
		if (method === 'update' || method === 'create') {
			options.type = 'PUT';
		}

		return sync(...arguments);
	}
});
