<template functional>
	<form class="consent-content" @submit.prevent="props.onSubmit">
		<div class="consent-content__content">
			<slot name="content" />
		</div>
		<ul
			v-for="(option, i) in props.options"
			v-once
			:key="i"
			class="consent-content__options"
		>
			<li class="consent-content__option option">
				<p class="option__content">
					<slot name="optionContent" :content="option.content" />
				</p>
				<label>
					<slot name="checkbox" :option="option">
						<input
							type="checkbox"
							class="option__checkbox"
							:checked.prop="option.answer && option.answer.answer"
							@change="props.onOptionToggle({ option, index: i })"
						/>
					</slot>

					<span class="option__statement">
						"{{option.statement}}"
					</span>
				</label>
			</li>
		</ul>
		<div class="consent-option__button-container">
			<button
				v-if="props.submittable"
				v-translate
				type="submit"
				class="consent-option__button consent-option__button--submit"
			>consents.submit</button>
		</div>
	</form>
</template>

<script>
import { noop } from 'lodash';

export default {
	name: 'ConsentContent',

	props: {
		submittable: {
			type: Boolean,
			default: false
		},
		options: {
			type: Array,
			required: true
		},
		onSubmit: {
			type: Function,
			default: noop
		},
		onOptionToggle: {
			type: Function,
			default: noop
		}
	}
};
</script>
