import { Model } from 'backbone';
import { assign, defer, filter, forEach, get, isUndefined, noop, without } from 'lodash';
import EventManager from '../../../core/event-manager';
import executeIfFunction from 'execute-if-function';
import parse from '../../../helpers/parse';
import getOptions from '../../../backend-wrapper/questiontypes/get-options';
import textExpression from '../../../core/text-expression';

/*
 * Model shared with all types of sliders
 */
export default Model.extend({
	// standard interface for element models
	initialize (...args) {
		const [tpl, instance, guiAttributes, response, helpers] = args;
		assign(this, {
			tpl,
			instance,
			guiAttributes,
			response,
			mediaManager: helpers.mediaManager
		});

		this.setupEventSystem();
		defer(() => {
			this.em.trigger('change', { preventSave: true });
		});

		this.answers = {};

		let options;

		this.getOptions = function () {
			return options ? options : (options = getOptions(tpl));
		};

		this.init();

		this.onInitialize && this.onInitialize.call(this);
	},

	init () {
		const endValueLabelsOnly = () => {
			if (!this.tpl.valueLabels || this.tpl.valueLabels.length !== 2) {
				return false;
			}

			return filter(
				this.tpl.valueLabels,
				(valueLabel) =>	+valueLabel.value === +this.tpl.minValue ||
					+valueLabel.value === +this.tpl.maxValue
			).length === 2;
		};

		const endLabelsOnly = parse.bool(this.tpl.showEndLabels) &&
			!parse.bool(this.tpl.showScaleLabels);

		const showClear = !isUndefined(this.tpl.showClear) ? parse.bool(this.tpl.showClear) : true;

		this.set({
			alignment: this.instance.alignment(),
			answerVisible: parse.bool(this.tpl.displayValue),
			class: this.instance.getType(),
			code: this.instance.code,
			digitalPoints: parse.bool(this.tpl.showDigitalPoints),
			endLabelsOnly,
			endLabelsVisible: parse.bool(this.tpl.showEndLabels),
			endValueLabelsOnly: endValueLabelsOnly(),
			hairline: parse.bool(this.tpl.hairline),
			id: this.instance.elementId,
			ignoredOptionCode: parse.bool(this.tpl.ignoreAnswerOption),
			labelPosition: this.tpl.labelPosition,
			labelStep: +this.tpl.labelStep,
			labelsVisible: parse.bool(this.tpl.showScaleLabels),
			layoutData: this.instance.getLayoutData(),
			leftVal: executeIfFunction(this.defaultLeftVal, this),
			length: this.tpl.length,
			mandatory: this.instance.isMandatory(),
			voluntary: this.instance.isVoluntary(),
			max: +get(this.tpl, 'maxValue', 0.1),
			min: +get(this.tpl, 'minValue', 0),
			name: this.instance.name,
			orientation: get(this.tpl, 'direction', 'horizontal'),
			preferredLength: get(this.tpl, 'length', false),
			question: this.getQuestionText(),
			readonly: this.instance.isReadonly(),
			rightVal: executeIfFunction(this.defaultRightVal, this),
			sectionId: this.instance.sectionId,
			showClear,
			stop: this.guiAttributes.isStop,
			ticks: parse.bool(this.tpl.showTicks),
			tplOptions: this.tpl.getOptions(),
			type: this.tpl.getType(),
			val: this.defaultValue()(),
			valueLabels: get(this.tpl, 'valueLabels', []),
			valueLabelsCount: get(this.tpl, 'valueLabels', []).length,
			valuePosition: this.tpl.valuePosition,
			valueSetByInput: false,
			valueStep: +this.tpl.step,
			visible: this.isVisible()
		});
	},

	getElementId () {
		return this.get('id');
	},

	// shortcut for getting a config property
	cfg (prop) {
		return this.get('config')[prop];
	},

	// used for autonumbering, numbers are set after model init so it needs to be a function
	number () {
		return this.instance.number;
	},

	// get a parent section
	section () {
		return this.instance.sectionRuntime;
	},

	// wire up the data to The Incredi🅱🅱🅱🅱🅱le Assessment Runtime Framework™
	setupEventSystem () {
		this.em = new EventManager({ context: this });
		this.trigger = this.em.trigger;
		this.triggerChange = (val) => {
			this.em.trigger('change', { newValue: val });
		};
		this.on = this.em.on;

		this.listenTo(this, 'element.show', this.onElementShow);
	},

	onElementShow () {
		if (!this.response.initialized) {
			this.response.save.addResponse(this.getResponse());
			this.response.initialized = true;
		}
	},

	defaultValue (def = 'getDefaultValue') {
		return () => !isNaN(this.instance[def]()) ? this.instance[def]() : undefined;
	},

	defaultValues: noop,

	// make sure that values are numeric and in range
	sanitize (value) {
		const inRange = value >= this.get('min') && value <= this.get('max');

		return inRange ? parseFloat(value) : undefined;
	},

	setValue (newValue, opts = {}) {
		this.set('valueSetByInput', !opts.defaultValue);
		this.set('val', this.sanitize(newValue), opts);
		this.triggerChange(this.get('val'));
	},

	setValues (values, opts = {}) {
		this.set('valueSetByInput', !opts.defaultValue);
		this.set({
			leftVal: this.sanitize(values[0]),
			rightVal: this.sanitize(values[1])
		}, opts);

		if (this.isAnswered()) {
			this.triggerChange(this.get('leftVal'));
			this.triggerChange(this.get('rightVal'));
		}
	},

	value () {
		return this.get('val');
	},

	values () {
		return [this.get('leftVal'), this.get('rightVal')];
	},

	isAnswered () {
		return this.get('valueSetByInput') && this.values().length === this.cfg('valuesCount') &&
			without(this.values(), false, undefined, NaN).length === this.cfg('valuesCount');
	},

	isIgnored () {
		if (this.get('visible') === false) {
			return true;
		}

		for (let parent = this.instance.sectionRuntime; parent; parent = parent.getSection()) {
			if (!parent.isVisible()) {
				return true;
			}
		}

		return false;
	},

	isVisible () {
		return this.guiAttributes.isVisible();
	},

	setVisible () {
		this.set('visible', this.isVisible());
	},

	getQuestionText () {
		return textExpression({
			text: this.instance.getQuestionText(),
			guiAttributes: this.guiAttributes
		});
	},

	// a shared function to use by the following ones as they're almost identical
	getSelectedAnswersProps ({ propName }) {
		const fn = {
			label: 'getLabel',
			code: 'getCode'
		}[propName];
		const labels = [];

		forEach(this.answers, (answer, i) => {
			if (
				answer.isVisible() &&
				answer.isSelected() &&
				i !== this.get('ignoredOptionCode')
			) {
				labels.push(this.tpl.getOptions()[i][fn]());
				return false; // only 1 answer can be selected anyway.
			}

			return true;
		});

		return labels;
	},

	getSelectedAnswersLabels () {
		return this.getSelectedAnswersProps('label');
	},

	getSelectedAnswersCodes () {
		return this.getSelectedAnswersProps('code');
	},

	// called when assessment condition (i.e. hide) is met
	clear (params) {
		this.set('visible', false, { silent: true });
		this.onClear(params);
		return this;
	},

	// called when assessment condition (i.e. show) is met
	unclear (params) {
		this.set('visible', true);
		this.onUnclear(params);
		return this;
	},

	reset () {
		this.clear().unclear({ reset: true });
		return this;
	},

	onClear: noop,
	onUnClear: noop
});
