var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tree-controls__buttons" }, [
    _c(
      "button",
      {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip.top-center",
            value: _vm.t(_vm.buttons.save),
            expression: "t(buttons.save)",
            modifiers: { "top-center": true }
          }
        ],
        class: _vm.buttonClasses("save", false),
        on: {
          click: function($event) {
            return _vm.save(_vm.localItems)
          }
        }
      },
      [
        _c("icon", {
          attrs: { name: "save", "class-name": "tree-controls__icon" }
        })
      ],
      1
    ),
    _vm._v(" "),
    _vm.copy.length
      ? _c(
          "button",
          {
            directives: [
              {
                name: "shortkey",
                rawName: "v-shortkey",
                value: ["esc"],
                expression: "['esc']"
              },
              {
                name: "tooltip",
                rawName: "v-tooltip.top-center",
                value: _vm.t(_vm.buttons.copyCancel),
                expression: "t(buttons.copyCancel)",
                modifiers: { "top-center": true }
              }
            ],
            class: _vm.buttonClasses("copy", !_vm.enabledCopy),
            on: {
              click: function($event) {
                return _vm.performCopy(false)
              },
              shortkey: function($event) {
                return _vm.performCopy(false)
              }
            }
          },
          [
            _c("icon", {
              attrs: {
                name: "copy-cancel",
                "class-name": "tree-controls__icon"
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.copy.length
      ? _c(
          "button",
          {
            directives: [
              {
                name: "shortkey",
                rawName: "v-shortkey",
                value: { std: ["ctrl", "c"], mac: ["meta", "c"] },
                expression: "{ std: ['ctrl', 'c'], mac: ['meta', 'c'] }"
              },
              {
                name: "tooltip",
                rawName: "v-tooltip.top-center",
                value: _vm.t(_vm.buttons.copy),
                expression: "t(buttons.copy)",
                modifiers: { "top-center": true }
              }
            ],
            class: _vm.buttonClasses("copy", _vm.enabledCopy),
            attrs: { disabled: _vm.enabledCopy },
            on: {
              click: function($event) {
                return _vm.performCopy(true)
              },
              shortkey: function($event) {
                return _vm.performCopy(true)
              }
            }
          },
          [
            _c("icon", {
              attrs: { name: "copy", "class-name": "tree-controls__icon" }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "button",
      {
        directives: [
          {
            name: "shortkey",
            rawName: "v-shortkey",
            value: { std: ["ctrl", "v"], mac: ["meta", "v"] },
            expression: "{ std: ['ctrl', 'v'], mac: ['meta', 'v'] }"
          },
          {
            name: "tooltip",
            rawName: "v-tooltip.top-center",
            value: _vm.t(_vm.buttons.paste),
            expression: "t(buttons.paste)",
            modifiers: { "top-center": true }
          }
        ],
        class: _vm.pasteClasses("paste", _vm.enabledPaste),
        attrs: { disabled: _vm.enabledPaste },
        on: { click: _vm.performPaste, shortkey: _vm.performPaste }
      },
      [
        _c("icon", {
          attrs: { name: "paste", "class-name": "tree-controls__icon" }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        directives: [
          {
            name: "shortkey",
            rawName: "v-shortkey",
            value: { std: ["ctrl", "s"], mac: ["meta", "s"] },
            expression: "{ std: ['ctrl', 's'], mac: ['meta', 's'] }"
          },
          {
            name: "tooltip",
            rawName: "v-tooltip.top-center",
            value: _vm.t(_vm.buttons.download),
            expression: "t(buttons.download)",
            modifiers: { "top-center": true }
          }
        ],
        staticClass: "tree-controls__buttons-item",
        class: _vm.buttonClasses("download", !_vm.enableDownload),
        attrs: { disabled: !_vm.enableDownload },
        on: { click: _vm.download, shortkey: _vm.download }
      },
      [
        _c("icon", {
          attrs: { name: "download", "class-name": "tree-controls__icon" }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        directives: [
          {
            name: "shortkey",
            rawName: "v-shortkey",
            value: { std: ["ctrl", "d"], mac: ["meta", "d"] },
            expression: "{ std: ['ctrl', 'd'], mac: ['meta', 'd'] }"
          },
          {
            name: "tooltip",
            rawName: "v-tooltip.top-center",
            value: _vm.t(_vm.buttons.mirror),
            expression: "t(buttons.mirror)",
            modifiers: { "top-center": true }
          }
        ],
        class: _vm.buttonClasses("mirror", !_vm.enabledMirror),
        attrs: { disabled: !_vm.enabledMirror },
        on: { click: _vm.createMirror, shortkey: _vm.onCreateMirror }
      },
      [
        _c("icon", {
          attrs: { name: "mirror", "class-name": "tree-controls__icon" }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        directives: [
          {
            name: "shortkey",
            rawName: "v-shortkey",
            value: ["del"],
            expression: "['del']"
          },
          {
            name: "tooltip",
            rawName: "v-tooltip.top-center",
            value: _vm.t(_vm.buttons.delete),
            expression: "t(buttons.delete)",
            modifiers: { "top-center": true }
          }
        ],
        staticClass: "tree-controls__buttons-item tree-view__button-delete",
        class: _vm.buttonClasses("delete", !_vm.enabledDelete),
        attrs: { disabled: !_vm.enabledDelete },
        on: { click: _vm.deleteSelected, shortkey: _vm.deleteSelected }
      },
      [
        _c("icon", {
          attrs: { name: "delete", "class-name": "tree-controls__icon" }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }