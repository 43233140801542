export default function (definition) {
	if (typeof definition.name == 'object') {
		const href = definition.name;

		definition.name = href.code;
		definition.target = href.target;
	}

	/* debug */
	this.__debug = {
		raw: definition,
		option: definition.option,
		norm: definition.name
	};

	this.getType = function () {
		return 'Norm';
	};

	this.getClass = function () {
		return 'Reference';
	};

	this.getInvolvedQuestionCodesMap = function (questionMap) {
		return questionMap;
	};

	this.getBoolean = function () {
		return (typeof definition.name == 'string') && (definition.name.length > 0);
	};

	this.getNumber = function () {
		return NaN;
	};

	this.getString = function () {
		return (typeof definition.name != 'string') ?
			'Undefined norm reference' :
			definition.name;
	};

	this.toString = function () {
		return `{Norm:${this.getString()}}`;
	};

	this.getRawValue = function () {
		return definition.name;
	};

	this.getNormCode = function () {
		return definition.name;
	};

}
