/*
 * Maps into font-awesome classes
 * http://fortawesome.github.io/Font-Awesome/icons/
 * Please update jsdoc if anything is changed
 */

const FILE_TEXT_O = 'file-text-o';
const FILE_IMAGE_O = 'file-image-o';

export default {
	'system': 'cogs',
	'clinician': 'user-md',
	'attribute': 'tags',
	'respondent': 'user',
	'case': 'briefcase',
	'role': 'child',
	'clinician-group': 'medkit',
	'respondent-group': 'group',
	'group': 'group',
	'assessment': FILE_TEXT_O,
	'assessment-data': 'check-square-o',
	'assessment-status': 'question-circle',
	'context': 'tag',
	'cms': 'indent',
	'language': 'language',
	'remote-scoring': 'external-link',
	'access': 'key',
	'login': 'key',
	'template': 'edit',
	'report': 'folder-o',
	'information': 'info-circle',
	'overdue': 'clock-o',
	'flag': 'flag',
	'message': 'envelope',
	'plan': 'calendar',
	'relation': 'sitemap',
	'search': 'search',
	'attachment': FILE_TEXT_O,
	'decision': 'hand-o-up',
	'yes': 'thumbs-up',
	'no': 'thumbs-down',
	'pdf': 'file-pdf-o',
	'doc': 'file-word-o',
	'file': 'file-o',
	'png': FILE_IMAGE_O,
	'jpg': FILE_IMAGE_O,
	'jpeg': FILE_IMAGE_O,
	'gif': FILE_IMAGE_O,
	'menu': 'bars',
	'home': 'home',
	'export': 'download',
	'download': 'download',
	'female': 'female',
	'male': 'male',
	'lock': 'lock',
	'unlock': 'unlock',
	'open': 'folder-open',
	'remove': 'times',
	'delete': 'trash-o',
	'close': 'arrow-circle-o-left',
	'cancel': 'times',
	'context-menu': 'ellipsis-v',
	'edit': 'pencil',
	'play': 'play',
	'pause': 'pause',
	'terminate': 'stop',
	'save': 'save',
	'history': 'history',
	'list': 'list-ol',
	'add': 'plus',
	'clear-query': 'times',
	'backward': 'arrow-left',
	'forward': 'arrow-right',
	'check': 'check',
	'store': 'shopping-cart',
	'logout': 'power-off',
	'login-session': 'power-off',
	'warning': 'exclamation-triangle',
	'save-indication': 'cog fa-spin',
	'eraser': 'eraser',
	'treatment': 'medkit',
	'information-package': 'archive',
	'publish': 'arrow-circle-up',
	'unpublish': 'arrow-circle-down',
	'reason-templates': 'flag',
	'seen': 'check',
	'lowered': 'check',
	'copy': 'copy',
	'notsubmitted': 'minus',
	'assessment-type': 'th',
	'goals': 'tasks',
	'more': 'chevron-right',
	'angle': 'angle-right',
	'page': 'newspaper-o',
	'exercise': 'calendar-check-o',
	'complete': 'check-circle',
	'angle-up': 'angle-up',
	'angle-down': 'angle-down',
	'achieve': 'check-circle-o',
	'achieved': 'trophy',
	'not-achieved': 'circle-o',
	'media': 'picture-o',
	'external-link': 'external-link',
	'eye': 'eye',
	'excel': 'file-excel-o',
	'audio': 'file-audio-o',
	'word': 'file-word-o',
	'archive': 'file-archive-o',
	'text': FILE_TEXT_O,
	'code': 'file-code-o',
	'powerpoint': 'file-powerpoint-o',
	'video': 'file-video-o',
	'image': FILE_IMAGE_O,
	'assessment-continue': 'arrow-circle-o-right',
	'submit-assessment': 'send',
	'unlink': 'unlink',
	'link': 'link',
	'category': 'tags',
	'circles': 'users',
	'circle-edit': 'cog',
	'circle-join': 'sign-in',
	'circle-leave': 'times-circle-o',
	'circle-cancel': 'undo',
	'abuse-report': 'bullhorn',
	'user': 'user',
	'user-circle': 'user-circle',
	'post-remove': 'calendar-times-o',
	'cards': 'th-large',
	'activate': 'plug',
	'ban': 'ban',
	'unban': 'undo',
	'reveal': 'eye',
	'import': 'upload',
	'not_started': 'minus',
	'in_progress': 'hourglass-half',
	'completed': 'check',
	'consent-templates': 'street-view',
	'roles': 'cube',
	'roleset': 'cubes',
	'menu-items': 'align-justify',
	'minus-square': 'minus-square-o',
	'plus-square': 'plus-square-o'
};
