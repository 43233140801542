<template>
	<ol class="sidebar-items">
		<li
			v-for="item in items"
			:key="`${item.label}:${item.icon}`"
			class="sidebar-items__item"
		>
			<sidebar-item
				:item="item"
				:can-access="canAccess(item)"
				:type="type"
				:clickable="clickable"
			/>
		</li>
	</ol>
</template>

<script>
import SidebarItem from './sidebar-item';
import { constant } from 'lodash';

export default {
	components: { SidebarItem },
	props: {
		items: {
			type: Array,
			required: true
		},
		canAccess: {
			type: Function,
			default: constant(true)
		},
		type: {
			type: String,
			default: ''
		},
		clickable: {
			type: Boolean,
			default: true
		}
	}
};
</script>
