import Marionette from 'marionette';
import cardUrl from 'cwcardurl';

const respondentController = Marionette.Object.extend({
	home: function () {
		cardUrl.openCard('start');
	}
});

export default respondentController;

