import t from 'translate';
import can from 'acl-can';

const card = {
	name: 'clinic-dashboard',
	url: 'clinic-dashboard',
	title: t('Clinic Dashboard'),
	acl: 'administrator.administrators.clinic-dashboard',

	ctrl: ({ close, populate }) => {
		if (!can.read(card.acl)) {
			close();
			return;
		}

		populate();
	}
};

export { card as ClinicDashboard };
