import logoutHref from '../util/logout-href';
import t from 'translate';

const card = {
	name: 'logout',
	url: 'logout',
	title: t('Log out'),
	redirect: true,

	ctrl: () => {
		window.location = logoutHref({ usePreviousUrl: true });
	}
};

export { card as logout };
