<template>
	<modal-dialog
		:show="show"
		:on-close="onClose"
		:loading="loading"
	>
		<template #title>
			<span v-translate>{{title}}</span>
		</template>

		<template #content>
			<edit-activity
				:activity="activity"
				:is-new="!activity.goal"
				:goals="goals"
				:on-submit="onSubmit"
				:on-cancel="onClose"
			/>
		</template>
	</modal-dialog>
</template>

<script>
import DialogMixin from './__dialog-mixin';
import EditActivity from './edit-activity';

export default {
	name: 'ActivityDialog',
	components: { EditActivity },
	mixins: [DialogMixin],
	props: {
		activity: {
			type: Object,
			required: true
		},
		goals: {
			type: Array,
			default: () => []
		}
	}
};
</script>
