import repository from 'repository';
import exercises from './content-package-exercises';
import { filter, size } from 'lodash';
import { when } from 'jquery';
import initEntities from '../__helpers/init-entities';

export default {
	namespaced: true,

	state: {
		data: [],
		collection: {}
	},

	modules: { exercises },

	mutations: {
		set: (state, { contentPackages, collection }) => {
			state.data = contentPackages;
			state.collection = collection;
		},

		add: (state, contentPackageInstance) => {
			state.data.push(contentPackageInstance);
		}
	},

	getters: {
		active: (state) => filter(state.data, { state: 'ACTIVATE' }),
		totalCount: (state) => size(state.data),
		uncompletedCount: (state) => size(filter(state.data, { isCompleted: false }))
	},

	actions: {
		initForTreatment: ({ commit }, { treatmentId }) => {
			const collection = repository.treatmentContentPackageInstances(treatmentId);

			return when(collection).then(() => {
				commit('set', {
					contentPackages: collection.toJSON(),
					collection
				});
			});
		},

		initForRespondent: ({ commit }, { respondentId }) => {
			const collection = repository.respondentContentPackages(respondentId);

			return when(collection).then(() => {
				commit('set', {
					contentPackages: collection.toJSON(),
					collection
				});
			});
		},

		init: initEntities('contentPackageInstances/init', {
			acl: 'administrator.respondents.informationpackages'
		}),

		add: ({ commit, state }, contentPackageInstance) => {
			state.collection.add(contentPackageInstance);
			commit('add', contentPackageInstance);
		}
	}
};
