import t from 'translate';
import { isNaN, isNumber, isRegExp } from 'lodash';
import emailCheck from 'lib/email-check';

/*
 * Validators
 *
 * Validator should be function. Should return boolean, true when value is correct.
 * First parameter is current field value, second, third and others are arguments given in
 * field declaration.
 */
export default {
	/*
	 * Dict of invalid messages
	 */
	invalidMessages: {
		number: t('Value should be a number'),
		regexp: t('Value should pass regular expression test'),
		email: t('Please enter valid email address'),
		required: t('Field is required'),
		tel: t('Please enter a standard telephone number. Only numbers will be accepted.')
	},

	/*
	 * Test if value is number and is in range.
	 *
	 * @example
	 * { validator: "number", args: [0, 99999] }
	 *
	 * @example
	 * { validator: "number" }
	 *
	 * @param {type} value
	 * @param {type} min
	 * @param {type} max
	 * @returns {boolean}
	 */
	number (newVal, min, max) {
		const value = Number(newVal);
		let result = !isNaN(value) && isNumber(value);

		if (!result) {
			return false;
		}

		if (min) {
			result = value > min;
		}

		if (max) {
			result = value < max;
		}

		return result;
	},

	/*
	 * Test value using regexp pattern.
	 *
	 * @example
	 * { validator: "regexp", args: /^[0-9]$/ }
	 *
	 * @param {type} value
	 * @param {type} pattern
	 * @returns {boolean}
	 */
	regexp (value, pattern) {
		if (isRegExp(pattern)) {
			return pattern.test(value);

		}
		return pattern.test(value);

	},

	/*
	 * Test value is an email.
	 *
	 * @example
	 * { validator: "email" }
	 *
	 * @param {type} email
	 * @returns {boolean}
	 */
	email (value) {
		return emailCheck(value);
	},

	/*
	 * Test value is not empty.
	 *
	 * @param {type} value
	 * @returns {boolean}
	 */
	required (value) {
		return !!value;
	},

	/*
	 * Test value is phone number.
	 *
	 * @param {type} value
	 * @returns {boolean}
	 */
	tel (value) {
		return this.regexp(value, /^\d+$/);
	}
};
