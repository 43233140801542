<template>
	<div v-if="datetimePolyfill" :class="containerClassName">
		<label :class="labelClassName">
			<p :class="labelTextClassName">
				<span v-if="translate" v-translate>{{label}}</span>
				<span v-else>{{label}}</span>
				<button
					v-show="model"
					type="button"
					:class="[
						'datetime-input__clear-button',
						{ 'datetime-input__clear-button--disabled': disabled }
					]"
					@click="clearValue"
				>
					<i class="fa fa-trash" />
					{{titles.clearBtn}}
				</button>
			</p>
		</label>
		<datetime
			ref="datetime"
			v-model="model"
			:type="type"
			:input-id="datetimeId"
			:input-class="inputClassName"
			value-zone="local"
			:format="dateFormat"
			:disabled="disabled"
			:readonly="readonly"
			@input="updateValue()"
			@close="onClose"
		/>
	</div>
	<div v-else :class="containerClassName">
		<p :class="labelTextClassName">
			<span v-if="translate" v-translate>{{label}}</span>
			<span v-else>{{label}}</span>
		</p>
		<input
			ref="input"
			:type="inputType"
			:class="inputClassName"
			:value="value"
			:disabled="disabled"
			:readonly="readonly"
			@input="updateValue()"
			@blur="onClose"
		/>
	</div>
</template>

<script>
import Vue from 'vue';
import Modernizr from 'modernizr';
import t from 'translate';
import { className } from './_helpers';
import Datetime from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import { DateTime } from 'luxon';

Vue.use(Datetime);

const COMPONENT_NAME = 'datetime-input';

export default Vue.component(COMPONENT_NAME, {
	props: {
		value: {
			type: String,
			default: ''
		},
		classPrefix: {
			type: String,
			default: ''
		},
		invalid: {
			type: Boolean,
			default: false
		},
		label: {
			type: String,
			required: true
		},
		required: {
			type: Boolean,
			default: false
		},
		format: {
			type: String,
			default: t('date.formats.dateTimeLuxon')
		},
		type: {
			type: String,
			default: 'datetime'
		},
		translate: {
			type: Boolean,
			default: true
		},
		readonly: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			model: this.value,
			dateFormat: this.format,
			titles: {
				clearBtn: t('Clear')
			}
		};
	},

	computed: {
		inputType: ({ type }) => ({
			datetime: 'datetime-local',
			date: 'date'
		}[type]),
		datetimeId () {
			// datetime component needs unique id
			return this._uid.toString();
		},
		containerClassName () {
			const element = 'field-container';
			const classes = this.className(element);

			if (this.required) {
				classes.push(...this.className(`${element}--mandatory`));
			}
			return classes;
		},

		labelClassName () {
			return [
				...this.className('label-container'),
				this.invalid && 'invalid'
			];
		},

		labelTextClassName () {
			return this.className('label-text');
		},

		inputClassName () {
			return this.datetimePolyfill ?
				this.className('input').join(' ') :
				this.className('input');
		},

		datetimePolyfill () {
			const datetimeNeeded = this.type === 'datetime' &&
				!Modernizr.inputtypes['datetime-local'];
			const dateNeeded = this.type === 'date' && !Modernizr.inputtypes['date'];

			return (datetimeNeeded || dateNeeded) && !this.readonly;
		}
	},

	watch: {
		value (val) {
			this.model = val;
		}
	},

	methods: {
		updateValue () {
			// vue-datetime always uses ISO-8601 string
			// if type is date, strip time part to align with native html date input format
			if (this.type === 'date' && this.datetimePolyfill && this.model) {
				this.$emit('input', DateTime.fromISO(this.model).toFormat('yyyy-MM-dd'));

				return;
			}

			this.datetimePolyfill ?
				this.$emit('input', this.model) :
				this.$emit('input', this.$refs.input.value);
		},
		clearValue () {
			this.$emit('input', null);
		},
		className: className(COMPONENT_NAME),
		onClose () {
			this.$emit('on-close');
		}
	}
});
</script>
