var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _vm.title
        ? _c("h2", { staticClass: "chart-tile__title" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.title) + "\n\t")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("chart", {
        attrs: {
          type: _vm.config.chartType,
          method: _vm.method,
          params: _vm.params,
          options: _vm.options,
          "responsive-options": _vm.responsiveOptions,
          "threshold-line": _vm.config.thresholdLine,
          "threshold-range": _vm.config.thresholdRange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }