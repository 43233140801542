import moment from 'moment';
import t from 'translate';
import { map, reduce, split } from 'lodash';

const calculateTicks = (series, dateType = 'month') => {
	const dates = reduce(series, (memo, current) => {
		memo.push(...map(current, 'x'));
		return memo;
	}, []);

	const minTime = moment(reduce(
		dates, (min, date) => +date < +min ? date : min, dates[0]
	)).startOf(dateType).subtract(1, dateType);

	const maxTime = moment(reduce(
		dates, (max, date) => +date > +max ? date : max, dates[0]
	)).startOf(dateType).add(1, dateType);

	const timeDiff = maxTime.diff(minTime, dateType);

	const list = map(
		Array.from({ length: timeDiff }),
		(val, index) => moment(minTime).add(index, dateType)
	);

	return {
		minTime,
		maxTime,
		list
	};
};

const dateType = (type) => ({
	// ISO 8601
	year: {
		parse: (i) => moment(`${i}-01`),
		ticksFormat: t('date.formats.chartYear'),
		type: 'year',
		tooltipFormat: t('date.formats.chartYear')
	},
	yearMonth: {
		parse: (i) => moment(i),
		ticksFormat: t('date.formats.chartMonth'),
		type: 'month',
		tooltipFormat: t('date.formats.chartYearMonth')
	},
	yearWeek: {
		parse: (i) => moment(`${split(i, '-')[0]}-01`)
			// accepts only Number and gets week for current Moment's year
			.week(split(i, '-')[1])
			.startOf('week'),
		ticksFormat: t('date.formats.chartYearWeek'),
		type: 'week',
		tooltipFormat: t('date.formats.chartYearWeek')
	},
	weekDay: {
		parse: (i) => moment().weekday(i).startOf('day'),
		ticksFormat: t('date.formats.chartShortWeekDay'),
		type: 'day',
		tooltipFormat: t('date.formats.chartWeekDay')
	},
	date: {
		parse: (i) => moment(i),
		ticksFormat: t('date.formats.chartDate'),
		type: 'day',
		tooltipFormat: t('date.formats.chartDate')
	},
	instance: {
		parse: (i) => moment(i),
		ticksFormat: t('date.formats.chartMonthDay'),
		type: 'day',
		tooltipFormat: t('date.formats.chartInstance')
	}
}[type] || { parse: (i) => moment(i), ticksFormat: t('date.formats.chartYearMonth') });

export {
	calculateTicks,
	dateType
};
