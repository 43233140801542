<template>
	<p class="items-count">
		{{description}}
	</p>
</template>

<script>
export default {
	props: {
		current: {
			type: Number,
			required: true
		},
		total: {
			type: Number,
			required: true
		}
	},

	computed: {
		description: ({ $t, current, total }) => total ?
			$t('table.itemsCount', { current, total }) :
			$t('table.itemsCountEmpty')
	}
};
</script>
