import FormComponentView from '../form-component-view';
import { compile } from 'handlebars';
import { assign, isDate, isNaN, isObject, some } from 'lodash';
import datetime from 'datetime';
import moment from 'moment';
import t from 'translate';
import datetimeComponent from 'components/datetime';

const DATE_FORMAT = 'YYYY-MM-DD';

const date = FormComponentView.extend({
	template: compile(`
		<label class="form-view__label-container">
			{{#if label}}
				<p class="form-view__label-text">{{ label }}</p>
			{{/if}}
			<input
				type="date"
				class="form-view__input form-view__date"
				name="{{ uniqueName }}"
				value="{{ value }}"
				{{#if required}} required="required" {{/if}}
				{{#if readonly}} readonly="readonly" {{/if}}
				{{#if disabled}} disabled="disabled" {{/if}}
				{{#if hint}} aria-describedby="{{ name }}-hint"{{/if}}
				{{#if min }} min="{{ min }}" {{/if}}
				{{#if max }} max="{{ max }}" {{/if}}
				{{#if step }} step="{{ step }}" {{/if}} />
		</label>
		{{#if hint}}
			<div class="form-view__hint" id="{{ name }}-hint">{{ hint }}</div>
		{{/if}}
	`),
	events: {
		'blur @ui.input': 'inputChanged'
	},
	onAfterRender () {
		this.attachFallback();
		this.listenTo(this.model, 'change:value', this.serializeData);
		this.ui.input.on('change', (e) => {
			// if this is original form change event we skip it
			if (!!e.originalEvent && !!e.originalEvent.type && e.originalEvent.type === 'change') {
				e.preventDefault();
				return false;
			}
		});
	},
	attachFallback () {
		const prevent = this.model.get('readonly') || this.model.get('disabled');

		if (Modernizr.inputtypes['date'] || prevent) {
			return;
		}

		const vm = datetimeComponent({
			type: 'date',
			attrs: this.model.attributes,
			dateAttr: 'value',
			set: (newDate) => {
				this.model.set('value', newDate);
				this.ui.input.trigger('change');
			},
			min: this.model.get('min'),
			max: this.model.get('max'),
			format: t('date.formats.dateLuxon')
		});

		this.ui.input.hide().after(vm.$el);
	},
	inputChanged (...args) {
		FormComponentView.prototype.inputChanged.apply(this, args);
		const newDate = !!this.ui.input.val() && this.parseDate(this.ui.input.val());
		const isValid = newDate && !isNaN(newDate.getTime());

		if (!this.model.get('readonly') && isValid) {
			// pats: it's here because we have to trigger somehow form submit/save methods
			this.ui.input.trigger('change');
		}
	},
	serializeData () {
		// date might be in multiple formats
		const newDate = this.model.get('value') && this.parseDate(this.model.get('value'));

		// date should be in ISO-8601
		if (newDate) {
			const dateString = moment(newDate).format(DATE_FORMAT);
			this.model.attributes.value = dateString;
			this.ui.input.val && this.ui.input.val(dateString);
		}

		return assign({
			min: moment('1900-01-01').format(DATE_FORMAT),
			max: moment('2100-01-01').format(DATE_FORMAT)
		}, this.model.toJSON());
	},

	dateParses: [
		(value) => value,
		(value) => isObject(value) ? datetime(value).toDate() : false,
		(value) => moment(value).toDate()
	],

	parseDate (value) {
		let newDate;

		// eslint-disable-next-line lodash/collection-method-value
		some(this.dateParses, (processFn) => {
			newDate = processFn(value);

			return isDate(newDate) && !isNaN(newDate.getTime());
		});

		return newDate;
	}
});

export { date };
