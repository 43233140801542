<template>
	<div>
		<label>
			<input
				v-model="value"
				class="edit-object__radio"
				:name="uniqueInputName"
				type="radio"
				:disabled="readonly"
				:value="true"
			/> <span class="edit-object__value-description">true</span>
		</label>
		<label>
			<input
				v-model="value"
				class="edit-object__radio"
				:name="uniqueInputName"
				type="radio"
				:disabled="readonly"
				:value="false"
			/> <span class="edit-object__value-description">false</span>
		</label>
	</div>
</template>

<script>
import inputMixin from './__input-mixin';
import Vue from 'vue';
import { constant } from 'lodash';
import uniqueId from 'service/unique-id';

export default Vue.component('booleanInput', {
	mixins: [inputMixin],
	props: {
		objVal: {
			type: [String, Boolean], // Vue treats mere presence of a prop as `true`...
			default: constant('')    // ...so it should be empty string by default
		},
		objKey: {
			type: String,
			required: true
		}
	},
	computed: {
		value: {
			get () {
				return this.objVal;
			},
			set (value) {
				this.onValueChange({ path: this.path, value });
			}
		},

		uniqueInputName: ({ objKey }) => `${objKey}-${uniqueId()}`
	}
});
</script>
