import { QUESTION_TAG_NAME } from '../../../config';

export default `
<div
	id="{{id}}"
	data-id="{{id}}"
	class="
		ar-component
		slider
		slider--{{variant}}
		slider--unanswered
		slider__container
		slider__container--{{orientation}}
		slider--label-position-{{labelPosition}}
		slider--value-position-{{valuePosition}}
		slider--value-labels-count-{{valueLabelsCount}}
		{{#if alignment}}slider--align-{{alignment}}{{/if}}
		{{#if digitalPoints}}slider--digital-points{{/if}}
		{{#if readonly}}slider--readonly{{/if}}
		{{#if hairline}}slider--hairline{{/if}}
		{{#if ticks}}slider--ticks{{else}}slider--no-ticks{{/if}}
		{{#if endLabelsOnly}}slider--end-labels-only{{/if}}
		{{#if endValueLabelsOnly}}slider--end-value-labels-only{{/if}}
		{{#if visible}}ar-component--visible slider__container--visible
		{{else}}ar-component--hidden slider__container--hidden
		{{/if}}
		{{#if endLabelsVisible}}slider--end-labels-visible{{else}}slider--end-labels-hidden{{/if}}
		{{#if labelsVisible}}slider--labels-visible{{else}}slider--labels-hidden{{/if}}
">
	<${QUESTION_TAG_NAME}
		class="ar-component__label slider__label"
		id="{{qTextId}}"
	>
		<span class="ar-component__number slider__number"></span>
		<span class="ar-component__title slider__title">{{{title}}}</span>
	</${QUESTION_TAG_NAME}>
	{{#if answerVisible}}
	<p class="slider__answer">
		{{labels.answer}}: {{labels.none}}
	</p>
	{{/if}}
	<div class="
		ar-component__controls
		slider__controls
		{{#if showClear}}slider__controls--with-clear{{else}}slider__controls--without-clear{{/if}}"
	>
		{{#if showClear}}
			<button class="slider__reset" title="{{t 'Clear'}}">
				<i class="slider__reset-icon"></i>
			</button>
		{{/if}}
	</div>
</div>
`;
