import $, { when } from 'jquery';
import {
	assign, compact, defaults, every, filter, forEach, invokeMap, isFunction, isObject, isString,
	isUndefined, map, noop, omit
} from 'lodash';
import { Model, View } from 'backbone';
import * as components from '../components/components';
import cwalert from 'cwalert';
import t from 'translate';
import FieldsCollection from '../entities/fields-collection';
import ButtonsCollection from '../entities/buttons-collection';
import Loader from './loader';
import { warn } from 'service/log/log';
import spin from 'components/loaders/spin';

/**
 * Form View
 *
 * @class FormView
 * @author Waldemar
 * @author Patrycjusz
 * @param {Object} param
 * @param {Backbone.Model} param.model model
 * @param {Array} param.fields array of fields definition, for more details about how to describe
 *     fields see {@link FormComponentView}
 * @param {Array} param.buttons array of buttons definition
 * @param {Array} param.inputLabels
 * @param {Array} param.classNames array of additional CSS classes for form, form-view--{className}
 * @param {Array} param.fieldsets array of fieldsets
 * @param {Boolean} param.autocomplete turn on/off autocomplete
 * @param {Boolean} param.readonly as default will mark every field as readonly, useful for setting
 *     ACL
 * @param {Boolean} param.disabled as default will mark every field as disabled/enabled, useful for
 *     setting ACL
 * @param {Boolean} param.preventSave will prevent from sending model to the backend, useful if you
 *     create some dummy models, virtual forms etc. Can return promise.
 * @param {Function} param.onAfterSave triggered after submit event {@link param.listenTo} and form
 *     was validated, useful if you need to to something after sending model to the backend
 * @param {Array} param.listenTo event that will trigger form validation and submitting process,
 *     default "change"
 * @param {Array} param.keysNotSubmitted array of keys of model that are not sended to the backend
 */
export default View.extend({
	tagName: 'form',
	className: 'form-view',
	components,
	_dataDefaults: () => ({ model: new Model() }),
	/**
	 * Number of miliseconds passed on to debouncing 'save' function
	 * It is done on instantiated view
	 * @type {Number}
	 */
	SAVE_TIME_THRESHOLD: 1000,

	initialize (args) {
		const params = assign({ loader: this.model && this.model.isNew() }, args);
		this.data = new Model(params);

		if (!this.model) {
			this.model = new Model(params.item);
		}
		this._setDefaults(params);
		defaults(this, assign(this._dataDefaults(), this.data.toJSON()));
		this.backupModel = new Model(this.model.attributes);
		this.model.revert = () => {
			this.model.set(this.backupModel.attributes);
			this._setValid();
		};
		this._initInputLabels();
		assign(this, {
			fields: new FieldsCollection(compact(this.data.get('fields'))),
			buttons: new ButtonsCollection(compact(this.data.get('buttons')))
		});
	},

	/*
	 * @private
	 */
	_setDefaults (params) {
		this.fieldsets = params.fieldsets || [];
		this.fieldViews = {};
		this.buttonViews = {};
		this.keysNotSubmitted = params.keysNotSubmitted || [];
		this._setDefaultFieldset();
	},

	/*
	 * Allow to not provide explicit fieldset in config
	 * @private
	 * @return {Object} FormView
	 */
	_setDefaultFieldset () {
		if (this.fieldsets.length) {
			return this;
		}

		this.fieldsets = [{
			caption: this.data.get('caption'),
			name: this.data.get('name'),
			fields: this.data.get('fields') ?
				map(this.data.get('fields'), (field) => field.name || field.key) :
				[],
			buttons: this.data.get('buttons') ? map(this.data.get('buttons'), 'name') : []
		}];
		return this;
	},

	/*
	 * Initiate input labels
	 * @private
	 * @return {Object} FormView
	 */
	_initInputLabels () {
		!this.model.get('inputLabels') && this.model.set('inputLabels', {});
		this.keysNotSubmitted.push('inputLabels');
	},

	render () {
		this
			._setAutocomplete()
			._setClassNames()
			._renderFieldsets()
			.setEventListeners()
			.renderLoader()
			.renderSaveIndicator();

		return this;
	},

	/*
	 * @private
	 * @return {Object} FormView
	 */
	_setAutocomplete () {
		if (this.data.get('autocomplete') === false) {
			this.$el.attr('autocomplete', 'off');

			this.dirtyAutocompleteHack();
		}
		return this;
	},

	dirtyAutocompleteHack () {
		// autocomplete problem - we should prepare more generic/bulletprof solution, more
		// details: https://bugs.chromium.org/p/chromium/issues/detail?id=468153
		// http://stackoverflow.com/questions/12374442/chrome-browser-ignoring-autocomplete-off
		// http://stackoverflow.com/questions/15738259/disabling-chrome-autofill/29582380#29582380
		this.$el.prepend(`
			<input style="display: none;" />
			<input type="text" style="display: none;" />
			<input type="password" style="display: none;" />
		`);
	},

	/*
	 * @private
	 * @return {Object} FormView
	 */
	_setClassNames () {
		forEach(this.data.get('classNames'), (className) => {
			this.$el.addClass(`${this.className}--${className}`);
		});
		return this;
	},

	/*
	 * Render fieldsets and their children (fields & buttons)
	 * @private
	 * @return {Object} FormView
	 */
	_renderFieldsets () {
		forEach(this.fieldsets, (fieldset) => {
			const fields = compact(fieldset.fields);
			const buttons = compact(fieldset.buttons);

			assign(fieldset, {
				squeeze: !!this.data.get('squeeze'),
				formClass: this.className
			});

			const fieldsetView = new this.components.Fieldset({
				model: new Model(fieldset)
			}).render();

			const renderComponent = function (fn) {
				return function (componentName) {
					fn(componentName, { parent: fieldsetView.ui.fieldset });
				};
			};

			forEach(fields, renderComponent(this._renderField.bind(this)));
			forEach(buttons, renderComponent(this._renderButton.bind(this)));

			this.$el.append(fieldsetView.$el);
		});

		return this;
	},

	/*
	 * @private
	 * @param  {String} fieldName
	 * @param  {Object} params
	 * @return {Object} FormView
	 */
	_renderField (fieldName, params) {
		const fieldModel = this.fields.get(fieldName);
		!fieldModel && warn(`No such field : ${fieldName}`);

		const fieldType = fieldModel.get('type') || 'text';

		if (!this.components.hasOwnProperty(fieldType)) {
			return false;
		}

		const fieldKey = fieldModel.get('key');
		const fieldIs = (what) => (this.data.get(what) && isUndefined(fieldModel.get(what))) ||
			fieldModel.get(what);

		let disabled = fieldIs('disabled');
		const $parent = params.parent;

		const getVal = fieldModel.get('getVal');

		if (isFunction(getVal)) {
			fieldModel.set('value', getVal(fieldModel.get('value')), { silent: true });
		}

		fieldModel.set({
			disabled,
			formClass: this.className,
			label: fieldModel.get('label') ||
				(this.model.label && this.model.label[fieldKey]) ||
				'',
			readonly: fieldIs('readonly'),
			value: fieldModel.get('value') || this.model.get(fieldKey),
			values: this._executeIfFunction(fieldModel.get('values'))
		});

		if (!isUndefined(disabled)) {
			disabled = this._executeIfFunction(disabled);
		}

		if (isObject(disabled)) {
			fieldModel.set('disabled', disabled.condition(this.model));

			this.listenTo(this.model, disabled.event, () => {
				fieldModel.set('disabled', disabled.condition(this.model));
			});
		}

		const fieldView = this._renderComponent(this.components[fieldType], fieldModel);
		this._attachFieldListeners(fieldView);
		fieldView.$el.appendTo($parent);

		if (!fieldView.hidden) {
			this.fieldViews[fieldKey] = fieldView;
		}
		return this;
	},

	/*
	 * @private
	 */
	_executeIfFunction (fn) {
		if (isFunction(fn)) {
			return fn(this.model);
		}
		return fn;
	},

	/*
	 * @private
	 * @param  {Object} fieldView
	 */
	_attachFieldListeners (fieldView) {
		const fieldKey = fieldView.model.get('key');
		const getVal = fieldView.model.get('getVal');

		this.listenTo(fieldView, 'formChange', () => {
			this.trigger(`formChange:${fieldKey}`, fieldView);
		});

		this.listenTo(fieldView.model, 'change:value', (model, val) => {
			const data = {};
			const value = getVal ? getVal(val) : val;

			data[fieldKey] = value;
			this.model.set(data);

			// ugly, but usable for changing state of fields that depends on other fields
			// We should think about aproach for this, it might be better, much better, but
			// still should stand at KISS principle
			this.trigger(`change:${fieldKey}`, fieldView.model, value);
			this.trigger('change', fieldKey, fieldView.model, value);
		});
	},

	_renderButton (buttonName, { parent }) {
		const buttonModel = this.buttons.get(buttonName);
		const $parent = parent;
		buttonModel.set({ formClass: this.className });

		const buttonView = this._renderComponent(this.components.button, buttonModel);
		buttonView.$el.appendTo($parent);
		this.buttonViews[buttonName] = buttonView;
	},

	_renderComponent (Component, model) {
		return new Component({
			model,
			formModel: this.model,
			formView: this
		}).render();
	},

	validate () {
		invokeMap(this.fieldViews, 'validate');

		let validationResults = [];
		forEach(this.fieldViews, (view) => {
			validationResults = validationResults.concat(view.validate());
		});

		return validationResults;
	},

	/*
	 * @param {Object} opts
	 */
	submit (opts) {
		const blurrable = this.data.get('listenTo') === 'submit';
		const input = $('.form-view').find('input[type=text]'); // eslint-disable-line
		const submit = this.data.get('submit') || noop;
		const onAfterSave = this.data.get('onAfterSave') || noop;

		if (blurrable) {
			document.activeElement.blur();  // to remove keyboard on mobiles - solution from:
			// https://stackoverflow.com/questions/5937339/ipad-safari-make-keyboard-disappear
			input.prop('readonly', true);
		}

		const validationResults = this.validate();

		if (this.data.get('loader') || this.data.get('saveIndicator')) {
			this.setLoading();
		}

		when(...validationResults)
			.done(() => {
				if (this.data.get('submit') && this.model.isValid()) {
					submit(omit(this.model.toJSON(), this.keysNotSubmitted));

				} else if (this.data.get('preventSave') && this.model.isValid()) {
					onAfterSave.call(this, this.model);

				} else {
					this.save(opts);
				}
			})
			.fail(() => {
				this.setLoaded();
			})
			.always(() => {
				blurrable && input.prop('readonly', false);
			});
	},

	save (opts = {}) {
		const beforeSave = this.data.get('onBeforeSave') || this.data.get('beforeSave') || noop;

		if (this.model.isValid() && this._fieldsAreValid()) {
			this.sanitizeModel();

			// Callback beforeSave usable for updating model before saving
			// if there is callback beforeSave call it now
			beforeSave.call(this, opts);

			this.model.save()
				.done(this._onSaveSuccess.bind(this))
				.fail(this._onSaveError.bind(this));

		} else {
			this.setLoaded();
			this._focusOnFirstInvalidField();
		}
	},

	/*
	 * @private
	 */
	_onSaveSuccess (...args) {
		if (this.data.get('refreshModelOnSave')) {
			this.model = this.model.clone();
		}

		const onAfterSave = this.data.get('onAfterSave') || function () {
		};
		const successMessage = this.data.get('successMessage') || t('Success');
		const onAfterSaveResult = onAfterSave.apply(this, args);

		const onSaveSuccess = () => {
			// you can use property successMessage (as string or as callback) to set custom success
			// message
			if (successMessage !== false) {
				cwalert.success(
					isFunction(successMessage) ?
						successMessage.apply(this, args) :
						successMessage
				);
			}
			this._onSaveAlways();
		};

		if (isObject(onAfterSaveResult) && onAfterSaveResult.hasOwnProperty('then')) {
			onAfterSaveResult.then(onSaveSuccess);

		} else {
			onSaveSuccess();
		}
	},

	/*
	 * @private
	 */
	_onSaveError (...args) {
		// @TODO(2015-10-25): add option to capture error on controller side when we start to
		// handle errors
		const errorMsg = this.data.get('errorMessage') || t('Error');
		// you can use property errorMessage (as string or as callback) to set custom error message
		cwalert.error(isFunction(errorMsg) ? errorMsg.apply(this, args) : errorMsg);

		this._onSaveAlways();
	},

	/*
	 * @private
	 */
	_onSaveAlways () {
		this.unsanitizeModel().setLoaded();
	},

	/*
	 * @private
	 * @return {Boolean}
	 */
	_fieldsAreValid () {
		return every(this.fieldViews, (fieldView) => fieldView.isValid());
	},

	/*
	 * @private
	 * @return {Array}
	 */
	_getInvalidFields () {
		return filter(this.fieldViews, (fieldView) => !fieldView.isValid());
	},

	/*
	 * @private
	 */
	_focusOnFirstInvalidField () {
		const invalidFields = this._getInvalidFields();
		invalidFields.length && invalidFields[0].getInput().trigger('focus');
	},

	/*
	 * @private
	 */
	_setInvalid (model, error) {
		this.unsanitizeModel().setLoaded();

		if (isString(error.key)) {
			error.key = [error.key];
		}

		if (isString(error.msg)) {
			error.msg = [error.msg];
		}

		forEach(error.key, (key, index) => {
			this.fieldViews[key].setInvalid(error.msg[index]);
		});
	},

	_setValid () {
		invokeMap(this.fieldViews, 'setValid');
	},

	sanitizeModel () {
		this.keyCache = {};
		this.sanitized = true;
		forEach(this.keysNotSubmitted, (key) => {
			this.keyCache[key] = this.model.get(key);
			this.model.unset(key, {
				silent: true
			});
		});
	},

	unsanitizeModel () {
		this.sanitized && forEach(this.keysNotSubmitted, (key) => {
			this.model.set(key, this.keyCache[key], { silent: true });
			delete this.keyCache[key];
		});
		this.sanitized = false;
		return this;
	},

	setLoading () {
		this.$el.prepend(this.loader.$el);
		return this._toggleLoadingClass('add');
	},

	setLoaded () {
		this.loader.remove();
		return this._toggleLoadingClass('remove');
	},

	/*
	 * @private
	 * @param  {String} fn 'add' or 'remove'
	 * @return {Object} FormView
	 */
	_toggleLoadingClass (fn) {
		this.$el[`${fn}Class`](`${this.className}--loading`);
		return this;
	},

	setEventListeners () {
		const eventName = this.data.get('listenTo') || 'change';
		this.listenTo(this.model, 'invalid', this._setInvalid);

		if (eventName === 'change') {
			this.$el.on(eventName, () => {
				this.$el.trigger('submit');
			});
		}

		this.$el.on('submit', (e) => {
			e.preventDefault();
			this.submit();
		});

		return this;
	},

	close () {
		const removeView = function (view) {
			view.customRemove();
		};
		forEach(this.fieldViews, removeView);
		forEach(this.buttonViews, removeView);
		this.trigger('close');

		this.remove();
	},

	disableSubmit () {
		this._toggleSubmit(false);
	},

	enableSubmit () {
		this._toggleSubmit(true);
	},

	/*
	 * @private
	 */
	_toggleSubmit (toggle) {
		forEach(this.buttonViews, (view) => {
			const isSubmit = view.model.get('type') === 'submit';
			isSubmit && view.disable(!toggle);
		});
	},

	renderSaveIndicator () {
		if (!this.data.get('saveIndicator')) {
			return this;
		}
		const $saveIndicator =
			$(`<p class="form-view__save-indication">${t('Processing data…')}</p>`)
				.prepend(spin());

		this.$el
			.addClass(`${this.className}--with-save-indicator`)
			.prepend($saveIndicator);

		return this;
	},

	renderLoader () {
		this.loader = new Loader().render();
		return this;
	}
});

