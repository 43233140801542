import repository from 'repository';
import prefixify from '../__helpers/prefixify';

const prefix = prefixify('systemExports');

const EXPORT_LOGS = 'exportLogs';

export const SYSTEM_EXPORTS = {
	EXPORT_LOGS: prefix(EXPORT_LOGS)
};

export default {
	namespaced: true,

	state: {},

	getters: {},

	mutations: {},

	actions: {
		[EXPORT_LOGS]: async (context, data) => await repository.exportSystemLogs(data)
	}
};
