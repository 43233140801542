<template>
	<section>
		<agenda-view
			:name="name"
			:items="parsedItems"
			:addable="addable"
			:types="types"
			:tile-path="tilePath"
			:loading="loading"
			:disabled="!enabled"
			:refresh="refresh"
			:timezone="timezone"
			:settings="settings"
		>
			<template #disabled>
				<p
					v-translate
					class="incomplete-treatment"
				>treatment.labels.unavailable</p>
			</template>
			<template #empty>
				<p
					v-translate
					class="empty-treatment"
				>treatment.labels.empty</p>
			</template>
		</agenda-view>
		<component
			:is="popupType"
			v-bind="popupProps"
		/>
	</section>
</template>

<script>
import {
	assign, cloneDeep, defaults, find, get, includes, map, omit, split, transform
} from 'lodash';
import { store } from 'store/agenda';
import config from 'core/config';
import storeMixin from 'components/__mixins/store-mixin';
import 'components/agenda-view';
import confirmation from 'components/confirmation/confirmation';
import t from 'service/lang/translate';
import availablePopups from './available-popups';

// move to the separate components' set once custom components size reaches three
import ViewReport from './components/view-report';
import { RESPONDENT } from 'store/respondents/respondent';
import { TREATMENT } from 'store/treatments/treatment';

const getter = function (name) {
	return {
		[name] () {
			return this.getters(name);
		}
	};
};
const localGetters = (names) => transform(
	names,
	(result, name) => assign(result, getter(name)),
	{}
);

export default {
	title: '',
	instant: true,
	mixins: [storeMixin],
	data: ({ tile }) => ({
		config: cloneDeep(tile.config()),
		name: 'agenda-tile',
		tilePath: tile.path,
		storePath: `agenda/${tile.path}`,
		currentPopup: {}
	}),

	actions: ['respondent/init', 'treatment/init'],

	computed: {
		...localGetters(['settings', 'items', 'addable', 'loading', 'types']),

		parsedItems: ({ actionHandler, items }) => map(
			items,
			(item) => assign(item, {
				actions: map(item.actions, (actionItem) => assign(
					{}, actionItem, actionHandler(actionItem)
				))
			})
		),

		enabled: ({ treatment }) => {
			if (!treatment) {
				return true;
			}

			return get(treatment, 'complete') && get(treatment, 'enabled');
		},

		timezone: ({ $store }) => $store.getters['respondent/timezone'],
		treatment: ({ $store }) => $store.getters['treatment'],
		popupType: ({ currentPopup }) => get(currentPopup, 'component', ''),
		popupProps: ({ currentPopup }) => get(currentPopup, 'props', {})
	},

	async created () {
		this.setDataLoading();

		if (this.$store.getters[`${this.storePath}/settings`]) {
			this.config.agendaSettings = this.$store.getters[`${this.storePath}/settings`];

		} else {
			this.$store.registerModule(split(this.storePath, '/'), store());
		}

		await this.$store.dispatch(
			`${this.storePath}/init`,
			{
				timezone: this.timezone,
				config: this.config,
				variables: {
					root: config().backendRootUrl.default,
					respondentId: this.$store.getters[RESPONDENT.RESPONDENT_ID],
					treatmentId: this.$store.getters[TREATMENT.ID],
					now: new Date().toISOString()
				}
			}
		);

		this.setDataLoaded();

	},

	methods: {
		refresh ({ from, to } = {}) {
			if (!this.settings.limitByRange) {
				return;
			}

			this.dispatch('fetchByDateRange', { from, to });
		},

		actionHandler (actionItem) {
			const handlers = {
				component: () => ({
					component: {
						viewReport: ViewReport
					}[actionItem.component]
				}),

				confirm: () => {
					const { action } = this.actionHandler(omit(actionItem, ['confirm']));

					const confirmConfig = transform(
						actionItem.confirm,
						(result, val, key) => assign(result, {
							[key]: includes(['title', 'message', 'confirm', 'cancel'], val) ?
								t(val) :
								val
						}),
						{}
					);

					return {
						action: () => {
							confirmation(defaults(
								confirmConfig,
								{ message: t('Are you sure?') }
							)).done(action);
						}
					};
				},

				request: () => ({
					action: async () => {
						this.setDataLoading();
						await this.dispatch('customRequest', actionItem.request);
						this.refresh();
						this.setDataLoaded();
					}
				}),

				action: () => {
					const { name, payload = {} } = actionItem.action;

					return {
						action: async () => {
							this.setDataLoading();
							await this.$store.dispatch(`agenda/actions/${name}`, payload);
							this.refresh();
							this.setDataLoaded();
						}
					};
				},

				popup: () => {
					const { popup, entity } = actionItem;

					return {
						action: () => {
							const popupItem = availablePopups[popup];

							this.currentPopup = popupItem({
								entity,
								show: true,
								loading: false
							});
						}
					};
				}
			};

			const handler = find(handlers, (handlerFn, name) => actionItem[name]);
			return handler ? handler(actionItem) : {};
		}
	}
};
</script>
