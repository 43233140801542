<template>
	<button
		:class="className"
		:disabled="status === 'isOwner'"
		@click.stop="action"
	>{{phrase}}</button>
</template>

<script>
import { forEach, noop } from 'lodash';
import confirmation from 'components/confirmation/confirmation';
import t from 'service/lang/translate';
import cwalert from 'service/cwalert';
import store from 'store';

// "temporary" here -- that is, as long as search component doesn't return direct store data
import { toggleStatus } from 'store/circles/helpers';

export default {
	props: {
		circle: {
			type: Object,
			required: true
		}
	},

	computed: {
		status: ({ circle }) => {
			let status = '';
			forEach(circle.memberStatus, (value, statusName) => {
				if (value) {
					status = statusName;
					return false;
				}
			});
			return status;
		},

		className: ({ status }) => ({
			'circles-list__action-button': true,
			'circles-list__action-button--disabled': status === 'isOwner'
		}),

		phrase: ({ status }) => ({
			isOwner: t('Owner'),
			isNone: t('Ask to join'),
			isCandidate: t('Cancel request'),
			isMember: t('Leave circle')
		}[status])
	},

	methods: {
		action () {
			const methodName = {
				isNone: 'join',
				isCandidate: 'cancel',
				isMember: 'leave'
			}[this.status];

			return methodName ? this[methodName]() : noop;
		},
		join () {
			confirmation({
				title: t('Join circle'),
				message: t('Are you sure?'),
				icon: 'circle-join'
			}).then(() => {
				store.dispatch('circles/join', { circleId: this.circle.id }).then(() => {
					this.circle = toggleStatus({ circle: this.circle, status: 'join' });
					cwalert.success(t('Request has been sent'));
				});
			});
		},

		cancel () {
			confirmation({
				title: t('Cancel request'),
				message: t('Are you sure?'),
				icon: 'circle-cancel'
			}).then(() => {
				store.dispatch('circles/cancelRequest', {
					circleId: this.circle.id
				}).then(() => {
					this.circle = toggleStatus({ circle: this.circle, status: 'leave' });
					cwalert.success(t('Request has been canceled'));
				});
			});
		},

		leave () {
			confirmation({
				title: t('Leave circle'),
				message: t('Are you sure?'),
				icon: 'circle-leave',
				warning: true
			}).then(() => {
				store.dispatch('circles/leave', {
					userId: store.state.circles.profile.id,
					circleId: this.circle.id
				}).then(() => {
					this.circle = toggleStatus({ circle: this.circle, status: 'leave' });
					cwalert.success(t('Circle has been left'));
				});
			});
		}
	}
};
</script>
