var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ol",
    { class: _vm.listClasses },
    [
      _vm._l(_vm.children, function(item, index) {
        return _c("tree-item", {
          key: index,
          staticClass: "tree-item--nested",
          attrs: {
            "active-parent": _vm.parentActive,
            blueprints: _vm.blueprints,
            "checked-items": _vm.checkedItems,
            copy: _vm.copy,
            "copy-path": _vm.copyPath,
            disabled: _vm.disabled,
            dispatch: _vm.dispatch,
            getters: _vm.getters,
            icons: _vm.icons,
            "incomplete-paths": _vm.incompletePaths,
            indeterminate: _vm.indeterminate,
            item: item,
            "mirror-details": _vm.mirrorDetails,
            mirrors: _vm.mirrors,
            "parent-root": _vm.root,
            "pass-button-refs": _vm.passButtonRefs,
            position: _vm.position,
            "root-mirrors": _vm.rootMirrors,
            selected: _vm.selected,
            "set-copy-path": _vm.setCopyPath,
            toggle: _vm.toggle,
            "toggle-edit": _vm.toggleEdit,
            "name-path": _vm.namePath,
            "warning-paths": _vm.warningPaths,
            "on-add": _vm.onAdd
          },
          on: { passButtonRefs: _vm.passButtonRefs }
        })
      }),
      _vm._v(" "),
      !_vm.mirror
        ? _c(
            "li",
            { class: _vm.addClasses },
            [
              _c("tree-add", {
                attrs: {
                  blueprints: _vm.blueprints,
                  disabled: _vm.disabled,
                  icons: _vm.icons,
                  path: _vm.path,
                  "name-path": _vm.namePath,
                  "on-add": _vm.onAdd
                },
                on: { addButtonRefs: _vm.passButtonRefs }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }