import systemSettings from 'service/system-settings/system-settings';
import { map } from 'lodash';

const cond = (flag) => ({
	condition: () => systemSettings.getBoolean(flag)
});

const setting = ({ card, flag }) => ({ card, ...cond(flag) });

export const mapSettings = (allowances) => map(allowances, setting);
