import {
	dataKey,
	defaultVariantName
} from './sidebar-meta';

export default [
	{
		name: defaultVariantName,
		[dataKey]: [
			{
				label: 'My Profile',
				translate: true,
				icon: 'clinician',
				linkType: 'card',
				target: 'clinician-my-profile',
				openInNewWindow: false,
				size: 'bigger',
				style: 'profile',
				counter: '',
				acl: {}
			}, {
				label: 'Log out',
				translate: true,
				icon: 'logout',
				linkType: 'card',
				target: 'logout',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: {}
			}, {
				label: 'My assigned treatments',
				translate: true,
				icon: 'assigned-active-treatments',
				linkType: 'card',
				target: 'my-treatments',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: {}
			}, {
				label: 'Unanswered messages',
				translate: true,
				icon: 'message',
				linkType: 'card',
				target: 'unanswered-messages',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: 'unansweredMessages',
				acl: {}
			}, {
				label: 'Treatments',
				translate: true,
				icon: 'treatment',
				linkType: 'card',
				target: 'search-treatments',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: {}
			}, {
				label: 'Respondents',
				translate: true,
				icon: 'respondent',
				linkType: 'card',
				target: 'search-respondents',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: {}
			}, {
				label: 'Clinicians',
				translate: true,
				icon: 'clinician',
				linkType: 'card',
				target: 'search-clinicians',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: {}
			}, {
				label: 'Clinic Dashboard',
				translate: true,
				icon: 'assessment',
				linkType: 'card',
				target: 'clinic-dashboard',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: {}
			}, {
				label: 'Dashboard',
				translate: true,
				icon: 'dashboard',
				linkType: 'card',
				target: 'clinician-dashboard',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: {}
			}, {
				label: 'Content management',
				translate: true,
				icon: 'content-package',
				linkType: 'card',
				target: 'content-management',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: {
					checkpoint: 'system.settings.informationpackages',
					op: 'READ'
				}
			}, {
				label: 'System reports',
				translate: true,
				icon: 'reports',
				linkType: 'card',
				target: 'system-reports',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: {}
			}, {
				label: 'system-exports__card-title',
				translate: true,
				icon: 'export',
				linkType: 'card',
				target: 'system-exports',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: {}
			}, {
				label: 'Circles',
				translate: true,
				icon: 'circles',
				linkType: 'card',
				target: 'circles-startpage',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: {}
			}, {
				label: 'Plans',
				translate: true,
				icon: 'plan',
				linkType: 'card',
				target: 'search-plans',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: {}
			}, {
				label: 'System logs',
				translate: true,
				icon: 'log',
				linkType: 'card',
				target: 'system-log',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: {}
			}, {
				label: 'System settings',
				translate: true,
				icon: 'system',
				linkType: 'card',
				target: 'system-settings',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: {}
			}
		]
	}
];
