import { find, assign, reject } from 'lodash';
import CirclePost from 'repo/circles/post';
import AbuseReport from 'repo/circles/abuse-report';
import { push } from './helpers';
import moment from 'moment';

export default {
	namespaced: true,

	state: {
		current: [],
		collection: {},
		totalCount: 0,
		loading: {
			list: false,
			create: false,
			post: 0
		}
	},

	getters: {
		sorted: (state) => state.current.sort((post1, post2) =>
			moment(post2.createdAt) - moment(post1.createdAt)),
		// eslint-disable-next-line max-params
		profile: (state, getters, rootState, rootGetters) =>
			rootGetters['circles/profile/profileData']
	},

	mutations: {
		set: (state, { posts, collection }) => {
			state.current = posts;
			state.collection = collection;
			state.totalCount = +collection.total;
		},

		add: (state, { posts }) => {
			push({ from: posts, to: state.current });
		},

		setPost: (state, { post }) => {
			assign(find(state.current, { id: post.id }), post);
		},

		remove: (state, { post }) => {
			state.current = reject(state.current, (existingPost) => +existingPost.id === +post.id);
		},

		setLoading: (state, loading) => {
			state.loading = assign({}, state.loading, loading);
		},

		increaseTotalCount: (state) => {
			state.totalCount++;
		},

		decreaseTotalCount: (state) => {
			state.totalCount--;
		},

		resetTotalCount: (state) => {
			state.totalCount = 0;
		}
	},

	actions: {
		set: ({ commit }, payload) => {
			commit('set', payload);
		},

		init: ({ commit }, { collection }) => collection.then(() => {
			commit('resetTotalCount');
			commit('set', { posts: collection.toJSON(), collection });
		}),

		loadMore: ({ commit, state }) => {
			commit('setLoading', { list: true });
			const posts = state.collection.more();
			posts.then(() => {
				commit('add', { posts: posts.toJSON() });
				commit('setLoading', { list: false });
			});
		},

		loadAll: ({ commit, state }) => {
			commit('setLoading', { list: true });
			const posts = state.collection.all();
			posts.then(() => {
				commit('add', { posts: posts.toJSON() });
				commit('setLoading', { list: false });
			});
		},

		create: ({ commit, getters, rootState, state }, { content }) => {
			const post = {
				circle: rootState.circles.currentCircle.data,
				content,
				circleUser: getters.profile
			};
			commit('setLoading', { create: true });

			return new CirclePost(post).save().then((newPost) => {
				state.collection.add(newPost);
				commit('increaseTotalCount');
				commit('setLoading', { create: false });
				commit('add', { posts: [newPost] });
			});
		},

		save: ({ commit, state }, { post }) => {
			commit('setPost', { post });
			commit('setLoading', { post: post.id });

			return state.collection.get(post.id).set(post).save().then(() => {
				commit('setLoading', { post: 0 });
			});
		},

		setPost: ({ commit }, { post }) => {
			commit('setPost', { post });
		},

		remove: ({ commit, state }, { post, circleId }) => {
			commit('remove', { post, circleId });
			commit('decreaseTotalCount');

			return state.collection.get(post.id).destroy();
		},

		reportAbuse: ({ commit }, { post }) => {
			const report = new AbuseReport({
				postContent: post.content,
				circle: post.circle.id,
				circleUser: post.circleUser.id,
				circlePost: post.id
			});

			return report.save().then(() => commit(
				'setPost',
				{ post: assign(post, { reportAbusesCount: post.reportAbusesCount + 1 }) }
			));
		}
	}
};
