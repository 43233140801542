import $ from 'jquery';
import layoutAdjustment from '../../rendering/layout-adjustment';
import { cwtagsNl2br } from '../../helpers/string';
import { QUESTION_TAG_NAME } from '../../config';
import { questionTextId } from './helpers';

export const init = ({ className, model }) => {
	const $container = $(
		`<div
			data-id="${model.getElementId()}"
			id="${model.getElementId()}"
			class="assessment-container ${className}"
		/>`
	);

	if (!model.isVisible()) {
		$container.hide();
	}

	layoutAdjustment(model, $container[0]);

	const $innerContainer = $(`<${QUESTION_TAG_NAME} class="questiontext" />`).appendTo($container);

	if (model.getNumber()) {
		$(`<span class="question-numbering">${model.getNumber()}.</span>`)
			.appendTo($innerContainer);
	}

	const $questionText = $(`<span
			class="question"
			id="${questionTextId(model)}"
		>${cwtagsNl2br(model.getQuestionText())}</span>`).appendTo($innerContainer);

	const $answerContainer = $('<div class="questionanswers" />').appendTo($container);

	return { $container, $questionText, $answerContainer };
};
