import TileView from 'tile-view';
import acl from 'acl';
import canAccess from 'core/engine/card/services/can-access';
import cardUrl from 'cwcardurl';
import { find, assign } from 'lodash';
import referral from 'service/referral';
import { TREATMENT } from 'store/treatments/rp-treatment';

export default TileView.extend({
	acl: {},
	instant: true,
	init: ({ tile }) => {
		tile.cfg = tile.config() || {};

		const tileAcl = tile.cfg.ACL || tile.cfg.acl;

		const enabled = (aclConfig) => {
			if (!tile.cfg.cardName) {
				return false;
			}

			if (aclConfig && !acl.checkAccess(aclConfig)) {
				return false;
			}

			return canAccess(tile.cfg.cardName);
		};

		if (!enabled(tileAcl)) {
			tile.disable();
		}
	},

	Vue: ({ cfg }) => ({
		data: cfg,
		template: `
				<a v-once class="card-shortcut__link" :href="url">
					<span class="card-shortcut__content">{{linkTitle}}</span>
				</a>
			`,

		computed: {
			cardCfg () {
				return find(
					this.$store.getters['engine/cards/registered'],
					{ name: this.cardName }
				);
			},

			url () {
				let extraParams = {};

				if (this.$store.getters['userType'] === 'respondent') {
					extraParams = { treatmentId: this.$store.getters[TREATMENT.ID] };
				}

				return cardUrl.buildUrl(
					this.cardCfg.name,
					assign(
						{},
						this.cardParams,
						{ referral: referral() },
						this.$store.getters.urlParams,
						...extraParams
					)
				);
			},

			linkTitle () {
				return this.cardCfg.title();
			}
		}
	})
});
