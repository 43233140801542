import Vue from 'vue';

export const button = (COMPONENT_NAME) => Vue.component(COMPONENT_NAME, {
	props: {
		onClick: {
			type: Function,
			required: true
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		className: COMPONENT_NAME
	}),
	template: `
		<button :class="className" @click.prevent.stop="onClick($event)" :disabled="disabled" />
	`
});
