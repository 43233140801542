var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.mainClasses },
    [
      _c(
        _vm.tagName,
        { tag: "component", class: _vm.className, attrs: { href: _vm.url } },
        [
          _vm.imageUrl
            ? _c(
                "div",
                { staticClass: "mini-shortcut-tile__image-container" },
                [
                  _c("img", {
                    staticClass: "mini-shortcut-tile__image",
                    attrs: {
                      src: _vm.imageUrl,
                      alt: _vm.image.alt,
                      width: _vm.image.width,
                      height: _vm.image.height
                    }
                  })
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.contentExists
            ? _c(
                "div",
                { staticClass: "mini-shortcut-tile__content-container" },
                [
                  _vm.cfg.icon
                    ? _c("icon", { attrs: { name: _vm.cfg.icon } })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mini-shortcut-tile__title-container" },
                    [
                      _c("p", { staticClass: "mini-shortcut-tile__title" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" + _vm._s(_vm.tileTitle) + "\n\t\t\t\t"
                        )
                      ]),
                      _vm._v(" "),
                      _vm.subtitle
                        ? _c(
                            "p",
                            { staticClass: "mini-value-tile__subtitle" },
                            [_c("span", [_vm._v(_vm._s(_vm.tileSubtitle))])]
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _vm.shouldShow("count") || _vm.shouldShow("totalCount")
                    ? _c("p", { staticClass: "mini-shortcut-tile__value" }, [
                        _vm.shouldShow("count")
                          ? _c("span", [_vm._v(_vm._s(_vm.count))])
                          : _vm._e(),
                        _vm.shouldShow("totalCount")
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "mini-tile__total counter-tile__total"
                              },
                              [_vm._v("/" + _vm._s(_vm.totalCount))]
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "mini-shortcut-tile__icon",
                      attrs: { "aria-hidden": "true" }
                    },
                    [
                      _c("span", {
                        staticClass: "icon icon--angle-right icon-2x"
                      })
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }