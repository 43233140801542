import repository from 'repository';

import { cleanClinicianGroup } from '../__helpers/clean';
import { assign, filter, get, map, sortBy } from 'lodash';

export default {
	namespaced: true,

	state: {
		clinicianGroups: {
			data: null,
			collection: null
		},
		data: {},
		enterprises: [],
		id: 0,
		attributes: {
			data: null,
			collection: null
		},
		roles: [],
		roleSets: [],
		ruleSet: false
	},

	getters: {
		data: (state) => state.data,
		treatmentTypeId: (state) => get(state, 'data.id'),
		visibleAttributes: (state) => filter(state.attributes.data, { isVisible: true }),
		sortedRoles: (state) => sortBy(state.roles, 'name'),
		respondentRoles: (state, getters) => filter(getters.sortedRoles, { type: 'RESPONDENT' }),
		clinicianGroups: (state) => sortBy(
			map(state.clinicianGroups.data, cleanClinicianGroup),
			({ name = '' }) => name.toLocaleLowerCase()
		),
		clinicianRoles: (state, getters) => filter(getters.sortedRoles, { type: 'CLINICIAN' }),
		roleSets: (state) => state.roleSets,
		enabledEnterprises: (state) =>
			filter(state.enterprises, (enterprise) => !!enterprise.enabled),
		enterprises: (state, getters) => map(
			getters.enabledEnterprises,
			(enterprise) => ({
				label: enterprise.name,
				value: enterprise.id
			})
		),
		ruleSet: (state) => state.ruleSet
	},

	mutations: {
		set: (state, { treatmentType }) => {
			state.data = treatmentType;
		},

		setAttributes: (state, { attributes, collection }) => {
			state.attributes.data = attributes;

			if (collection) {
				state.attributes.collection = collection;
			}
		},

		setEnterprises: (state, list) => {
			state.enterprises = list;
		},

		setId: (state, id) => {
			state.id = id;
		},

		setClinicianGroups: (state, { clinicianGroups, collection }) => {
			state.clinicianGroups.data = clinicianGroups;
			state.clinicianGroups.collection = collection;
		},

		setRoles: (state, { roles }) => {
			state.roles = roles;
		},

		setRoleSets: (state, payload) => {
			state.roleSets = payload;
		},

		setRuleSet: (state, payload) => {
			state.ruleSet = payload;
		}
	},

	actions: {
		addAndRefreshClinicianGroup: ({ dispatch, state }, data) =>
			dispatch('addClinicianGroup', data).then(
				() => dispatch('getClinicianGroups', { treatmentTypeId: state.id })
			),

		addClinicianGroup: ({ state }, data) =>
			repository.addTreatmentTypeClinicianGroup(assign(data, { treatmentTypeId: state.id })),

		addRoleSet: ({ dispatch, state }, data) => {
			data.treatmentTypeId = state.id;
			const result = repository.addTreatmentTypeRoleSets(data);
			return result.then(() => dispatch('getRoleSets'));
		},

		deleteTreatmentType: (context, id) => repository.deleteTreatmentType(id),

		deleteAndRefreshClinicianGroup: ({ dispatch, state }, id) =>
			dispatch('deleteClinicianGroup', id).then(
				() => dispatch('getClinicianGroups', { treatmentTypeId: state.id })
			),

		deleteClinicianGroup: ({ state }, id) =>
			repository.deleteTreatmentTypeClinicianGroup({ id, treatmentTypeId: state.id }),

		deleteRoleSet: ({ dispatch, state }, id) => {
			const data = { id, treatmentTypeId: state.id };
			const result = repository.deleteTreatmentTypeRoleSets(data);
			return result.then(() => dispatch('getRoleSets'));
		},

		getEnterpriseList: ({ commit }, { limit }) => repository.searchEnterprisesForTreatmentType({
			start: 0,
			limit
		}).then((response) => {
			commit('setEnterprises', response);
		}),

		reset: ({ commit }) => commit('set', { treatmentType: {} }),

		init: ({ commit }, { treatmentTypeId }) => {
			const model = repository.getTreatmentTypeById(treatmentTypeId);

			return model.then(() => {
				commit('set', { treatmentType: model.toJSON() });
			});
		},

		initAttributes: ({ commit }, { treatmentTypeId }) => {
			const collection = repository.treatmentTypeAttributes(treatmentTypeId);

			return collection.then(() => {
				commit('setAttributes', { attributes: collection.toJSON(), collection });
			});
		},

		initAttributesOnly: (
			{ commit }, { treatmentTypeId }
		) => repository.rawTreatmentTypeAttributes(treatmentTypeId).then((attributes) => {
			commit('setAttributes', { attributes });
		}),

		getClinicianGroups: ({ commit }, { treatmentTypeId }) => {
			const collection = repository.getTreatmentTypeClinicianGroups(treatmentTypeId);

			return collection.then(() => {
				commit('setClinicianGroups', { clinicianGroups: collection.toJSON(), collection });
			});
		},

		getRoles: ({ commit, getters }, { treatmentTypeId } = { treatmentTypeId: 0 }) => {
			const id = treatmentTypeId || getters.treatmentTypeId;
			const collection = repository.getTreatmentTypeRoles(id);

			return collection.then(() => {
				commit('setRoles', { roles: collection.toJSON() });
			});
		},

		getRoleSets: ({ commit, state }, treatmentTypeId = null) => {
			if (treatmentTypeId) {
				commit('setId', treatmentTypeId);
			}
			repository.treatmentTypeRoleSets(state.id).then((response) => {
				commit('setRoleSets', response);
			});
		},

		deleteRoles: ({ commit }) => {
			commit('setRoles', { roles: [] });
		},

		add: (context, data) => repository.addTreatmentType(data),

		update: (context, data) => repository.updateTreatmentType(data),

		determineRuleSet: ({ commit }, treatmentTypeId) => {
			if (treatmentTypeId) {
				return repository.searchRuleSetsByTreatmentType(treatmentTypeId)
					.then((response) => {
						commit('setRuleSet', !!response.length);
					});
			}
			return commit('setRuleSet', false);
		}
	}
};
