import Media from './entities';
import MediaCategories from './categories';

/**
 * @class Repository
 */
export default {
	/**
	 * Get all media, including (soft) deleted
	 * @method allMedia
	 * @return {Object} new {{#crossLink "{Media"}}{{/crossLink}} Collection
	 * @example
	 * 		repository.allMedia();
	 */
	allMedia: () => Media.getAll(),

	/**
	 * @method mediaModel
	 * @param {String|Number} id
	 * @return {Object} new {{#crossLink "{MediaModel"}}{{/crossLink}} Collection
	 * @example
	 * 		repository.mediaModel(3);
	 */
	mediaModel: (id) => Media.getById(id),

	/**
	 * Search media
	 * @param params
	 * @return {Object} new {{#crossLink "{Media"}}{{/crossLink}} Collection
	 */
	searchMedia: (params) => Media.search(params),

	/**
	 * @method mediaCategories
	 * @example
	 * 		repository.mediaCategories();
	 */
	mediaCategories: () => MediaCategories.getAll()
};
