import systemSettings from 'system-settings';
import { HOME_RESPONDENT } from 'modules/home/constants';
import { LATEST_ASSESSMENT } from 'modules/assessment/constants';
import handleOpenOption from 'modules/respondent/utils/handle-open-option';
import cardUrl from 'core/engine/card/services/card-url';

export default ({ treatmentId, referral }) => {
	if (systemSettings.getBoolean('RESPONDENT_ITERATE_AFTER_TREATMENT_SELECT')) {
		cardUrl.openCard(LATEST_ASSESSMENT, { treatmentId, referral });
		return true;
	}

	if (handleOpenOption({ treatmentId })) {
		return true;
	}

	cardUrl.openCard(HOME_RESPONDENT);
	return false;
};
