<template>
	<user-link v-if="content" :respondent="content" />
	<span v-else>{{emptyChar}}</span>
</template>

<script>
import jsonQuery from 'json-query';
import { UserLink } from 'components/user-link/user-link';
import TableComponentMixin from './_mixin';
import { respondentName } from 'service/display-name/display-name';

export default {
	components: {
		UserLink
	},

	mixins: [
		TableComponentMixin
	],

	computed: {
		content: ({ column, item }) => jsonQuery(column.key, { data: item }).value
	},

	methods: {
		sortValue: ({ itemValue }) => respondentName(itemValue)
	}
};
</script>
