import { isEqual, every, get, isArray, isUndefined, toLower, toUpper } from 'lodash';
import { READ, ADD, EDIT, DELETE, SUPERVISOR, ADMINISTRATOR } from './access-levels';

const accessLevel = {
	[READ]: 2000,
	OPEN: 2000, // @TODO(2019-05-21): Replace usages
	[ADD]: 3000,
	CREATE: 3000, // @TODO(2019-05-21): Replace usages
	[EDIT]: 4000,
	UPDATE: 4000, // @TODO(2019-05-21): Replace usages
	[DELETE]: 5000,
	[SUPERVISOR]: 6000,
	[ADMINISTRATOR]: 7000
};

const lookup = (op, grantedAccessLevel) => {
	if (!accessLevel[toUpper(op)]) {
		return false;
	}
	const requiredAccessLevel = accessLevel[toUpper(op)];
	return grantedAccessLevel >= requiredAccessLevel;
};

const challenge = ({ op, aclEntry }) =>
	!isUndefined(aclEntry['access-level']) && lookup(op, parseInt(aclEntry['access-level'], 10));

let checkpoints = {};

const checkAccess = (action = {}) => {
	const op = get(action, 'op', action.operation);

	let aclEntry;

	if (isEqual(action, {})) {
		return true;

	} else if (action.checkpoint) {
		aclEntry = checkpoints[toLower(action.checkpoint)];

	} else if (action.aclEntry) {
		aclEntry = action.aclEntry;

	} else {
		return false;
	}

	if (!aclEntry) {
		return false;

	}
	return challenge({ op, aclEntry	});
};

export default {
	init: (acl = {}) => {
		checkpoints = acl;
	},

	checkAccess: (action = {}) => {
		if (isArray(action)) {
			return every(action, (entry) => checkAccess(entry));

		}
		return checkAccess(action);
	}
};
