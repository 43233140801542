import Backbone from 'backbone';
import acl from 'acl';
import appContext from 'app-context';
import { assign, clone, forEach, isFunction, noop } from 'lodash';

/**
 * Single Entity
 * Blueprint Backbone Model class used across Repository Service.
 * @class SingleEntity
 * @extends Backbone.Model
 */
export default Backbone.Model.extend({
	idAttribute: 'id',

	initialize (params) {
		this.params = params;
		this.attachEventListeners();
		this.onInitialize();
	},

	onInitialize: noop,

	onSync (...args) {
		return Backbone.sync(...args);
	},

	sync (...args) {
		const [method, model] = args;
		const eventName = `${this.eventNamespace}.__${method}`;

		if (this.eventNamespace) {
			if (method === 'create') {
				this.listenToOnce(this, 'sync', () => {
					appContext.trigger(eventName, model);
				});

			} else {
				appContext.trigger(eventName, model);
			}
		}

		return this.onSync(...args);
	},

	attachEventListeners () {
		forEach(this.appEvents, (handler, eventName) => {
			this.listenTo(appContext, eventName, handler);
		});

		forEach(this.events, (handler, eventName) => {
			this.listenTo(this, eventName, handler);
		});

		return this;
	},

	isListeningTo (eventName) {
		return !!this._events[eventName];
	},

	retrieve () {
		assign(this, this.fetch({ cache: false }));
		this.onRetrieve();

		this.then(() => {
			if (this.logEntry) {
				const logEntry = clone(this.logEntry);

				forEach(logEntry, (logParam, key) => {

					if (isFunction(logParam)) {
						logEntry[key] = logParam(this);
					}
				});

				appContext.trigger('log.info', logEntry);
			}
		});

		return this;
	},

	onRetrieve: noop,

	/**
	 * Get id of the entity.
	 *
	 * @function getId
	 * @returns {number} Id.
	 * @example
	 *        var assessmentInstances = repository.respondentAssessmentInstances(19);
	 *        var firstAssessmentId;
	 *        assessmentInstances.done(function () {
     * 			var firstAssessment = assessmentInstances.models[0];
     * 			firstAssessmentId = firstAssessment.getId();
     * 		});
	 */
	getId () {
		return +this.get(this.idAttribute);
	},

	/**
	 * Checks if the entity can be deleted, based on its checkpoint attribute.
	 *
	 * @function canBeDeleted
	 * @returns {boolean} Can entity be deleted.
	 * @example
	 *        var assessmentInstance = repository.getAssessmentInstanceById(115);
	 *        assessmentInstance.done(function () {
     * 			if (assessmentInstance.canBeDeleted()) {
     * 				// do something
     * 			}
     * 		});
	 */
	canBeDeleted () {
		if (this.checkpoint) {
			return acl.checkAccess({ op: 'DELETE', checkpoint: this.checkpoint });

		}
			return true;

	},

	getThisOrThat (key1, key2) {
		return this.get(key1) || this.get(key2);
	}
});
