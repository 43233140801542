<template>
	<figure class="circle-progress">
		<svg
			:height="size"
			:width="size"
		>
			<circle
				class="circle-progress__background"
				fill="transparent"
				:r="radius"
				:cx="size/2"
				:cy="size/2"
				:stroke="backgroundStroke"
				:stroke-width="strokeWidth"
			/>
			<circle
				class="circle-progress__value"
				fill="transparent"
				:r="radius"
				:cx="size/2"
				:cy="size/2"
				:stroke="stroke"
				:stroke-width="strokeWidth"
				:stroke-dasharray="strokeDashArray"
				:stroke-dashoffset="progress"
			/>
		</svg>
		<figcaption class="circle-progress__text">
			<slot>{{percent}}%</slot>
		</figcaption>
	</figure>
</template>

<script>
import Vue from 'vue';
import { delay } from 'lodash';

export default Vue.component('CircleProgress', {
	props: {
		animationDelay: {
			type: Number,
			default: 500
		},
		size: {
			type: Number,
			default: 120
		},
		backgroundStroke: {
			type: String,
			default: '#DFDFDF'
		},
		stroke: {
			type: String,
			default: '#00B9E4'
		},
		strokeWidth: {
			type: Number,
			default: 15
		},
		percent: {
			type: Number,
			default: 0
		}
	},

	data: () => ({
		value: 0
	}),

	computed: {
		radius: ({ size, strokeWidth }) => (size / 2) - strokeWidth,
		circumference: ({ radius }) => radius * 2 * Math.PI,
		strokeDashArray: ({ circumference }) => `${circumference} ${circumference}`,
		progress: ({ value, circumference }) => circumference - (value / 100 * circumference)
	},

	mounted () {
		delay(() => {
			this.value = this.percent;
		}, this.animationDelay);
	}
});
</script>
