import { delay, get } from 'lodash';
import { GET } from 'service/ajax/ajax';

export default {
	namespaced: true,
	state: {
		initialised: false
	},
	getters: {
		initialised: (state) => state.initialised
	},
	mutations: {
		setInitialised (state, bool) {
			state.initialised = bool;
		}
	},

	actions: {
		init: ({ rootState, commit }) => {
			const code = get(rootState.user, 'userLanguageCode', 'en');

			const promise = Promise.all([
				GET({ url: `lang/${code}.json`, prepend: false }),
				GET({ url: `lang/custom/${code}.json`, prepend: false }),
				GET({ url: `lang/localization/${code}.json`, prepend: false }),
				GET({ url: `lang/chart/${code}.json`, prepend: false })
			]);

			promise.then(() => {
				delay(() => {
					commit('setInitialised', true);
				}, 10);
			});

			return promise;
		}
	}
};
