<template>
	<plot-chart
		:params="params"
		:options="options"
		:threshold-line="config.thresholdLine"
		:threshold-range="config.thresholdRange"
	/>
</template>

<script>
import moment from 'moment';
import Chartist from 'Chartist';
import { assign, filter, get, isNull, isUndefined, map, reduce } from 'lodash';
import { calculateTicks, dateType } from './helpers';
import { mapGetters } from 'vuex';
import assessmentValueChart from '../../shared/assessment-value-chart';

export default {
	...assessmentValueChart(),
	actions: (tile) => [[
		'assessmentValuePlotSeries/getValues',
		assign({}, tile.config()),
		{},
		{ instant: true }
	]],

	data: ({ tile }) => ({
		config: tile.config()
	}),
	computed: {
		...mapGetters('assessmentValuePlotSeries', ['valuesByRef']),

		// depends on type of date in dataSources, defines which momentJS method (parser)
		// should be used and which format should be used to be displayed later
		// eg. yearWeek - handler: moment().isoWeekYear(), format: 'YYYY ww'
		date: ({ config }) => dateType(config.dataSources[0].seriesCategory),

		// mapping tile config and data from backend by reference name ('ref')
		// INPUT [{ref: 'val1', values: [{label: '2018-03', value: 2}]},
		// {ref: 'val2', values: [{label: '2018-03', value: 4}]}]
		// ['val2', 'val1']
		// OUTPUT [[{x: Moment, y: 4}], [{x: Moment, y: 2}]]
		// empty values (gaps) are merged at the end of series collection
		series: ({ config, date, valuesByRef }) => map(config.series, (ref) => {
			const gaps = [];
			const line = reduce(get(valuesByRef[ref], 'values'), (memo, item) => {
				(isNull(item.value) || item.value === config.emptyIfEquals) ?
					gaps.push({
						x: date.parse(item.label),
						y: null
					}, {
						x: date.parse(item.label),
						y: 0,
						meta: `${date.tooltipFormat},gap`
					}) :
					memo.push({
						x: date.parse(item.label),
						y: item.value,
						meta: date.tooltipFormat
					});

				return memo;
			}, []);

			line.push(...gaps);

			return line;
		}),
		// calculate min/max dates to provide this data to chart's options
		// while axisX's type is 'FixedScaleAxis' - it needs a precise ticks list
		ticks: ({ series, date }) => calculateTicks(series, date.type),

		options: ({ config, date, ticks }) => ({
			axisX: {
				type: Chartist.FixedScaleAxis,
				low: +ticks.minTime,
				high: +ticks.maxTime,
				ticks: ticks.list,
				labelInterpolationFnc: (value, index, collection) => {
					if (collection.length > 7) {
						const mod = Math.ceil(collection.length / 6);

						// eg. mod = 5, render labels: 0 (hidden), 5, 10, 15, 20
						return index % mod === 0 ?
							moment(value).format(date.ticksFormat) :
							null;
					}

					return moment(value).format(date.ticksFormat);
				}
			},
			low: config.min,
			high: config.max,
			height: config.chartHeight,
			horizontalLabels: config.horizontalLabels
		}),
		params: ({ config, series }) => ({
			dataColors: config.dataColors,
			legendNames: config.legendNames,
			series,
			thresholdLineColors: config.thresholdLineColors,
			thresholdRangeColors: config.thresholdRangeColors
		})
	},
	created () {
		!isUndefined(this.config.title) && this.tile.setTitle(this.config.title);
		// eslint-disable-next-line lodash/prop-shorthand
		const checkValues = filter(this.series, (line) => line.length);
		!checkValues.length && !this.config.showIfEmpty && this.tile.disable();
	}
};
</script>
