<template>
	<menu-toggle-button
		v-if="viable"
		:is-selected="isSelected"
		:items="variants"
		name="switch-variant"
		:title="$t('Change variant')"
		@firstItemClick="selectVariant"
		@itemClick="selectVariant"
	>
		<template #buttonContent>
			<icon name="copy" />
		</template>
		<template #firstItemContent>
			{{defaultVariantName}}
		</template>
		<template #itemContent="{ item }">
			<span v-if="card.translate" v-translate>{{item.name}}</span>
			<span v-else>{{item.name}}</span>
		</template>
	</menu-toggle-button>
</template>

<script>
import MenuToggleButton from './menu-toggle-button';
import Icon from 'components/icon';
import { filter, get, intersection, map } from 'lodash';
import { NONE } from 'store/engine/variant-criteria';
import t from 'service/lang/translate';

export default {
	components: { Icon, MenuToggleButton },

	computed: {
		viable: ({ $store, variants }) => (
			!$store.getters.autoVariant &&
			variants &&
			!!variants.length
		),

		card: ({ $store }) => $store.getters.cardDefinition,
		defaultVariantName: ({ card }) => get(card, 'variantName', t('Default')),
		variantIndex: ({ $store }) => $store.getters.variantIndex,
		// show only variants that meet criteria set in `.when`
		variants: ({ $store, card }) => filter(
			card.alternativeCards,
			(variant, index) => filter(variant.when, (criterionValue, criterionName) => {
				if (criterionName === NONE) {
					return true;
				}
				const getter = $store.getters.variantGetters[index][criterionName];
				const set = map($store.getters[getter.name], getter.transform);

				return intersection(set, criterionValue).length;
			}).length
		)
	},

	methods: {
		selectVariant ({ i }) {
			this.$store.dispatch('setVariantIndex', i);
		},

		isSelected ({ i }) {
			return i === this.variantIndex;
		}
	}
};
</script>
