import { assign, chain, find, filter, findIndex, split } from 'lodash';
import repository from 'repository';
import Model from 'repo/system-settings/entity';

// eslint-disable-next-line no-useless-escape
const regex = /respondent\-activities|clinician\-activities|system\-activities/;
const getLabel = (str) => split(str.systemSettingLabel, '.')[2];

export default {
	namespaced: true,

	state: {
		data: [],
		collection: {}
	},

	getters: {
		system: (state) => filter(state.data, { systemSettingCategory: 'SYSTEM ADMINISTRATOR' }),
		clinician: (state) => filter(state.data, { systemSettingCategory: 'ADMINISTRATOR' }),
		respondent: (state) => filter(state.data, { systemSettingCategory: 'RESPONDENT' }),
		assessment: (state) => filter(state.data, { systemSettingCategory: 'ASSESSMENT PORTAL' }),

		groupedActorActivities: (state) => (
			chain(state.data)
				.filter((item) => item.systemSettingCategory === 'TREATMENT ACTIVITY' &&
						item.systemSettingType === 'BOOL' &&
						regex.exec(item.systemSettingLabel)
				)
				.sortBy((item) => getLabel(item))
				.groupBy((item) => getLabel(item))
				.value()
		),

		groupedEventActivities: (state) => (
			chain(state.data)
				.filter((item) => item.systemSettingCategory === 'TREATMENT ACTIVITY' &&
					item.systemSettingType === 'BOOL' &&
					!regex.exec(item.systemSettingLabel)
				)
				.sortBy((item) => getLabel(item))
				.groupBy((item) => getLabel(item))
				.value()
		)
	},

	mutations: {
		set: (state, { data, collection }) => {
			state.data = data;
			state.collection = collection;
		},

		toggleSettingValue: (state, payload) => {
			const i = findIndex(state.data, payload);
			state.data[i].systemSettingValue = !state.data[i].systemSettingValue;
		},

		updateSetting: (state, setting) => {
			const systemSetting = find(state.data, { systemSettingId: setting.systemSettingId });
			assign(systemSetting, setting);
		}
	},

	actions: {
		init: ({ commit }) => {
			const collection = repository.getSystemSettings();

			return collection.then(() => {
				commit('set', { data: collection.toJSON(), collection });
			});
		},

		toggleValue: ({ commit, state }, payload) => {
			commit('toggleSettingValue', payload);

			const model = new Model(find(state.data, payload));

			return model.save();
		},

		update: ({ commit }, setting) => {
			commit('updateSetting', setting);
			const model = new Model(setting);
			return model.save();
		}
	}
};
