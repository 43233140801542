<template>
	<section class="dashboard-table__wrapper">
		<h2 v-if="title" class="chart-tile__title">
			{{title}}
		</h2>
		<form class="form-view">
			<select-input
				v-if="config.axisZ && axisZValues.length > 1"
				v-model="axisZ[0]"
				:options="axisZValues"
				:label="config.axisZ[0].label || ''"
				:translate="config.translate"
				:get-option-label="axisZLabel"
				:clearable="false"
			/>
		</form>

		<table class="dashboard-table">
			<thead>
				<tr>
					<th>{{maybeTranslate(config.headingLabel)}}</th>
					<th v-for="header in headers" :key="header">
						{{format({ axis: 'X', value: header })}}
					</th>
					<th
						v-for="additionalColumn in config.additionalColumns"
						:key="additionalColumn.label"
					>
						{{maybeTranslate(additionalColumn.label)}}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(row, i) in rows"
					:key="i"
				>
					<th scope="row">
						{{format({ axis: 'Y', value: rowHeaders[i] })}}
					</th>
					<td
						v-for="(cell, j) in row"
						:key="j"
						:class="cellClassName(j)"
					>{{cell}}</td>
				</tr>
				<tr
					v-for="(additionalRow, i) in additionalRows"
					:key="additionalRow.label"
				>
					<th scope="row">
						{{maybeTranslate(config.additionalRows[i].label)}}
					</th>
					<td
						v-for="(cell, j) in additionalRow"
						:key="j"
						class="dashboard-table__cell--additional"
					>{{cell}}</td>
				</tr>
			</tbody>
		</table>
	</section>
</template>

<script>
import actions from '../../shared/actions';
import { CUBE } from 'store/cube/cube';
import cubeId from '../../shared/cube-id';
import {
	clone, compact, filter, forEach, get, groupBy, includes, isFinite, keys, map, reduce,
	reject, sum
} from 'lodash';
import { EMPTY_CHAR } from 'lib/chars';
import { DateTime } from 'luxon';

const AXIS_Z_ALL = 'AXIS_Z_ALL';
const NULL_LABEL = '(None)';

export default {
	actions: (tile) => actions({
		title: tile.config().title,
		query: tile.config().cubeQuery,
		ignoreAccessRules: tile.config().ignoreAccessRules
	}),

	data: ({ tile }) => ({
		axisZ: [AXIS_Z_ALL],
		config: tile.config()
	}),

	acl: [],

	computed: {
		title: ({ maybeTranslate, config }) => maybeTranslate(config.title),
		showNone: ({ config }) => config.showNone !== false,
		results: ({ config, $store }) => $store.getters[CUBE.RESULTS][cubeId(config)].result,
		filteredResults: ({ axisZ, config, results }) => filter(results, (result) => {
			if (axisZ[0] && axisZ[0] !== AXIS_Z_ALL) {
				return `${result[config.axisZ[0].value]}` === `${axisZ[0]}`;
			}

			return true;
		}),
		groupedResults: ({ config, filteredResults, results }) => ({
			x: groupBy(filteredResults, config.axisX.value),
			y: groupBy(filteredResults, config.axisY.value),
			z: groupBy(results, get(config, 'axisZ[0].value', []))
		}),

		axisZValues: (
			{ clean, neatHeaders, groupedResults }
		) => [AXIS_Z_ALL, ...neatHeaders(clean(groupedResults.z))],

		allHeaders: ({ clean, groupedResults }) => clean(groupedResults.x),
		allRowHeaders: ({ clean, groupedResults }) => clean(groupedResults.y),

		headers: ({ allHeaders, config, groupedResults, neatHeaders }) => {
			if (config.hideEmptyCols) {
				const headers = [];

				forEach(allHeaders, (header) => {
					forEach(groupedResults.x, (values, colHeader) => {
						if (header === colHeader) {
							const flatValues = map(values, config.value);

							if (compact(flatValues).length) {
								headers.push(header);
							}
						}
					});
				});

				return neatHeaders(headers);
			}

			return neatHeaders(allHeaders);
		},
		rowHeaders: ({ allRowHeaders, config, groupedResults }) => {
			if (config.hideEmptyRows) {
				const rowHeaders = [];

				forEach(allRowHeaders, (header) => {
					forEach(groupedResults.y, (values, rowHeader) => {
						if (header === rowHeader) {
							const flatValues = map(values, config.value);

							if (compact(flatValues).length) {
								rowHeaders.push(header);
							}
						}
					});
				});

				return rowHeaders;
			}

			return allRowHeaders;
		},

		rows: (
			{ config, groupedResults, headers, itemValue, op, rowHeaders, showEmpty }
		) => {
			const rows = compact(map(
				groupedResults.y,
				(result, rowHeader) => {
					if (
						(config.hideEmptyRows && !includes(rowHeaders, rowHeader)) ||
						(!showEmpty && rowHeader === 'null')
					) {
						return false;
					}

					const row = map(
						headers,
						(header) => itemValue(
							filter(
								result,
								(item) => `${item[config.axisX.value]}` === `${header}`
							)
						)
					);

					const additionalColumns = map(
						config.additionalColumns,
						(column) => op(column.op, row)
					);

					return [...row, ...additionalColumns];
				}
			));

			return rows;
		},

		additionalRows: ({ config, headers, op, rows }) => map(
			config.additionalRows,
			(additionalRow) => map(
				[...headers, ...config.additionalColumns],
				(column, i) => op(additionalRow.op, map(rows, (row) => row[i]))
			)
		)
	},

	methods: {
		maybeTranslate (text = '') {
			return this.config.translate ? this.t(text) : text;
		},

		format ({ axis, value }) {
			const cfg = this.config[`axis${axis}`];

			if (cfg.type === 'date') {
				return DateTime.fromISO(value).toFormat(cfg.format);
			}
			return value === 'null' ? this.t(NULL_LABEL) : value;
		},

		cellClassName (index) {
			return {
				'dashboard-table__cell': true,
				'dashboard-table__cell--additional': !this.headers[index]
			};
		},

		itemValue (items) {
			const value = items.length && sum(map(items, this.config.value));

			return value || EMPTY_CHAR;
		},

		clean (values) {
			return this.showNone ? keys(values) : reject(keys(values), (hdr) => hdr === 'null');
		},

		neatHeaders (headers) {
			const toSort = clone(headers);
			toSort.sort((a, b) => {
				if (a === 'null' || a > b) {
					return 1;
				}

				if (a === b) {
					return 0;
				}

				return -1;
			});

			return toSort;
		},

		op (op, items) {
			return this[op](items);
		},

		SUM: (items) => reduce(items, (result, item) => {
			const itemValue = isFinite(item) ? item : 0;
			return result + itemValue;
		}, 0),

		MEAN (items) {
			return this.SUM(items) / items.length;
		},

		axisZLabel (opt) {
			if (opt === AXIS_Z_ALL) {
				return this.t('All');
			}

			if (opt === 'null') {
				return this.t(NULL_LABEL);
			}
			return opt;
		}
	}
};
</script>
