<template>
	<section>
		<agenda-view
			:name="name"
			:items="items"
			:addable="addable"
			:types="types"
			:tile-path="tilePath"
			:settings="settings"
			:refresh="refresh"
			:loading="loading"
			:timezone="timezone"
			:statuses="['completed', 'open', 'overdue']"
		/>

		<activity-dialog
			:show="addingActivity"
			:activity="newActivity"
			:on-close="closeActivityDialog"
			:loading="addActivityLoading"
			:on-submit="addActivity"
			:goals="goals"
		/>
	</section>
</template>

<script>
import t from 'service/lang/translate';
import { assign, cloneDeep, get, isEmpty, map, find } from 'lodash';
import { activitiesMapping } from '../../shared/agenda-config';
import agendaMixin from '../../shared/tile-mixin';
import ActivityDialog from '../../components/activity-dialog';
import cwalert from 'service/cwalert';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints.json';
import { RESPONDENT } from 'store/respondents/respondent';
import { ACTIVITIES } from 'store/goals/activities';
import { TREATMENT } from 'store/treatments/treatment';
import { GOALS } from 'store/goals/goals';

export default {
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.GOALS_ACTIVITIES,
		op: READ
	}],
	title: t('goals.activities'),
	actions: ({ $store }) => [
		[ACTIVITIES.INIT_FOR_GOAL, {
			goalId: get($store.getters.urlParams, 'goalId'),
			respondentId: $store.getters[RESPONDENT.RESPONDENT_ID]
		}],
		GOALS.INIT
	],
	components: { ActivityDialog },
	mixins: [agendaMixin],
	data ({ $store }) {
		const goalId = get($store.getters.urlParams, 'goalId');
		const respondentId = $store.getters[RESPONDENT.RESPONDENT_ID];

		return {
			goalId,
			name: 'activities-tile',
			addingActivity: false,
			addActivityLoading: false,
			newActivity: {},
			tilePath: [
				this.tile.path,
				`goal-${goalId}`,
				`respondent-${respondentId}`,
				`treatment-${$store.getters[TREATMENT.ID]}`
			].join('|'),
			config: cloneDeep(this.tile.config()),
			loading: false,
			types: [{ name: 'activity' }],
			addable: [{
				icon: 'activity',
				name: 'activity',
				action: (datetime) => {
					this.openActivityDialog({ datetime });
				},
				timeScope: this.tile.config().activityTimeScope
			}]
		};
	},

	computed: {
		activities: ({ $store, config, respondentId, toggleActivityStatus }) => map(
			$store.getters[ACTIVITIES.ITEMS_IN_GOAL],
			(activity) => assign(
				{},
				activity,
				activitiesMapping({ activity, config, respondentId, toggleActivityStatus })
			)
		),
		items: ({ activities }) => activities,
		settings: ({ config }) => config.agendaSettings,
		goals: ({ $store }) => $store.getters[GOALS.ITEMS]
	},

	methods: {
		async refresh () {
			this.loading = true;
			await this.$store.dispatch('activities/initForGoal', {
				goalId: this.goalId,
				respondentId: this.respondentId
			});
			this.loading = false;
		},

		openActivityDialog ({ datetime }) {
			const params = isEmpty(datetime) ?
				{} :
				{ startDate: datetime.toFormat(this.$t('date.formats.activity')) };

			const goal = find(this.goals, { id: this.goalId });

			this.newActivity = assign({}, this.emptyActivity, {
				goal,
				params
			});
			this.addingActivity = true;
		},

		closeActivityDialog () {
			this.addingActivity = false;
		},

		addActivity () {
			this.addActivityLoading = true;
			this.$store.dispatch(
				ACTIVITIES.CREATE,
				{
					activity: this.newActivity,
					inGoal: +this.newActivity.goal.id === +this.goalId
				}
			).then(() => {
				this.addActivityLoading = false;
				cwalert.success(this.$t('goals.editActivity.addSuccess'));
				this.closeActivityDialog();
			});
		}
	}
};
</script>
