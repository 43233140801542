<template>
	<vue-showdown
		v-if="contentParsed"
		:markdown="contentParsed"
	/>
	<span v-else>{{emptyChar}}</span>
</template>

<script>
import { mtParse } from '../helpers/parse';
import TableComponentMixin from './_mixin';
import { VueShowdown } from 'vue-showdown';

export default {
	components: { VueShowdown },
	mixins: [
		TableComponentMixin
	],

	computed: {
		contentParsed: ({ column, content, item, tileConfig }) =>
			mtParse({ column, content, tileTranslate: tileConfig.translate, item })
	},

	methods: {
		sortValue: ({ itemValue }) => itemValue
	}
};
</script>
