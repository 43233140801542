import repository from 'repository';
import { assign, filter, map } from 'lodash';
import { RESPONDENT } from '../respondents/respondent';
import prefixify from '../__helpers/prefixify';

const prefix = prefixify('treatments');

const DATA = 'data';
const SET = 'set';

// getters
const READY = 'ready';
const ENABLED = 'enabled';
const DISABLED = 'disabled';
const ALL = 'all';
const ENABLED_WITH_RESPONDENT = 'enabledWithRespondent';
const MAIN_RESPONDENT_READY = 'mainRespondentReady';

// actions
const INIT_ALL = 'initAll';
const INIT_FOR_RESPONDENT = 'initForRespondent';
const INIT_FOR_MAIN_RESPONDENT = 'initForMainRespondent';
const INIT = 'init';
const RESET = 'reset';

export const TREATMENTS = {
	// getters
	READY: prefix(READY),
	ENABLED: prefix(ENABLED),
	DISABLED: prefix(DISABLED),
	ALL: prefix(ALL),
	ENABLED_WITH_RESPONDENT: prefix(ENABLED_WITH_RESPONDENT),
	MAIN_RESPONDENT_READY: prefix(MAIN_RESPONDENT_READY),

	// actions
	INIT_ALL: prefix(INIT_ALL),
	INIT_FOR_RESPONDENT: prefix(INIT_FOR_RESPONDENT),
	INIT_FOR_MAIN_RESPONDENT: prefix(INIT_FOR_MAIN_RESPONDENT),
	INIT: prefix(INIT),
	RESET: prefix(RESET)
};

export default {
	namespaced: true,

	state: {
		[DATA]: [],
		collection: null
	},

	getters: {
		[READY]: (state) => filter(state[DATA], { enabled: true, complete: true }),
		[ENABLED]: (state) => filter(state[DATA], { enabled: true }),
		[DISABLED]: (state) => filter(state[DATA], { enabled: false }),
		[ALL]: (state) => state[DATA],
		[ENABLED_WITH_RESPONDENT]: (state, getters) => map(
			getters.enabled,
			(treatment) => assign(treatment, {
				name: `${treatment.name} - ${treatment.respondent.displayName}`
			})
		).sort((a, b) => a.name.localeCompare(b.name)),

		// eslint-disable-next-line max-params
		[MAIN_RESPONDENT_READY]: (state, getters, rootState, rootGetters) => filter(
			getters.ready,
			(treatment) => +treatment.respondent.id === +rootGetters[RESPONDENT.RESPONDENT_ID]
		)
	},

	mutations: {
		[SET]: (state, { treatments, collection }) => {
			state[DATA] = treatments;
			state.collection = collection;
		},

		[RESET]: (state) => {
			state[DATA] = [];
			state.collection = null;
		}
	},

	actions: {
		[INIT_ALL]: async ({ commit }, params) => {
			const treatments = await repository.treatments(params);
			commit(SET, { treatments });
		},

		[INIT_FOR_RESPONDENT]: (
			{ commit, rootGetters },
			{ respondentId } = { respondentId: 0 }
		) => {
			const collection = repository.respondentTreatments(
				respondentId || rootGetters[RESPONDENT.RESPONDENT_ID]
			);

			return collection.then(() => {
				commit(SET, { treatments: collection.toJSON(), collection });
			});
		},

		[INIT_FOR_MAIN_RESPONDENT]: (
			{ commit, rootGetters },
			{ respondentId } = { respondentId: 0 }
		) => {
			const collection = repository.mainRespondentTreatments(
				respondentId || rootGetters[RESPONDENT.RESPONDENT_ID]
			);

			return collection.then(() => {
				commit(SET, { treatments: collection.toJSON(), collection });
			});
		},

		[INIT]: ({ dispatch, rootGetters }, params) => rootGetters.userType === 'respondent' ?
			dispatch(INIT_ALL, params) :
			dispatch(INIT_FOR_RESPONDENT),

		[RESET]: ({ commit }) => commit(RESET)
	}
};
