var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "inline-edit__component",
      class: { "inline-edit__component--before-hide": _vm.animationOut }
    },
    [
      _c(
        "div",
        {
          ref: "label",
          staticClass: "inline-edit__label",
          class: { "inline-edit__label--hidden": _vm.inputVisible }
        },
        [
          _c(
            "button",
            {
              staticClass: "inline-edit__label-button",
              attrs: {
                "aria-label": _vm.titles.edit,
                "aria-pressed": _vm.inputVisible.toString(),
                tabindex: "0",
                disabled: !_vm.allowEdit
              },
              on: { click: _vm.showInput }
            },
            [
              _c("span", { staticClass: "inline-edit__label-text" }, [
                _vm._v(_vm._s(_vm.name))
              ])
            ]
          ),
          _vm._v(" "),
          _vm.allowDelete
            ? _c(
                "button",
                {
                  staticClass: "inline-edit__remove-button",
                  attrs: { title: _vm.titles.remove, tabindex: "0" },
                  on: { click: _vm.remove }
                },
                [_c("span", { staticClass: "icon--times" })]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "inline-edit__input",
          class: [
            { "inline-edit__input--visible": _vm.inputVisible },
            { "inline-edit__input--invalid": !_vm.valid }
          ],
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.save($event)
            }
          }
        },
        [
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "focus-when",
                  rawName: "v-focus-when",
                  value: _vm.inputVisible,
                  expression: "inputVisible"
                },
                {
                  name: "blur-when",
                  rawName: "v-blur-when",
                  value: !_vm.inputVisible,
                  expression: "!inputVisible"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name"
                }
              ],
              ref: "input",
              staticClass: "inline-edit__input-element",
              style: { width: _vm.inputWidth + "px" },
              attrs: {
                type: "text",
                maxlength: _vm.maxSize,
                "aria-invalid": !_vm.valid,
                tabindex: "0"
              },
              domProps: { value: _vm.name },
              on: {
                keydown: [
                  _vm.onKeydown,
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape"
                      ])
                    ) {
                      return null
                    }
                    return _vm.cancel($event)
                  },
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.save($event)
                  }
                ],
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.name = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("i", { staticClass: "inline-edit__input-edit icon--pencil" }),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _vm._m(2)
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "inline-edit__input-save",
        attrs: { type: "submit", title: _vm.titles.save, tabindex: "0" }
      },
      [_c("i", { staticClass: "icon--check" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "inline-edit__input-cancel",
        attrs: { title: _vm.titles.cancel, tabindex: "0" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.cancel($event)
          }
        }
      },
      [_c("i", { staticClass: "icon--times" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "inline-edit__input-description" }, [
      _vm._v(_vm._s(_vm.titles.inputDescription))
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }