import AssessmentInstance from '../assessment-instances/assessment-instance';
import urls from './urls';
import t from 'translate';
import datetime from 'datetime';
import Backbone from 'backbone';
import appContext from 'app-context';

/**
 * RespondentAssessmentInstance Entity
 * @class RespondentAssessmentInstance
 * @extends AssessmentInstance
 */
export default AssessmentInstance.extend({
	urlRoot: urls.single.url,
	eventNamespace: 'respondent-assessment-instance',
	sync: function () {
		return Backbone.sync.apply(this, arguments);
	},

	appEvents: {},

	isOverdue: function () {
		const endDate = datetime(this.get('assessmentInstanceEndDate'))
			.setTimezone(appContext.get('user').get('timezoneName'))
			.toMoment()
			.unix();
		const nowDate = parseInt(new Date().getTime() / 1000, 10);
		return nowDate > endDate;
	},

	toJSON: function () {
		const json = Backbone.Model.prototype.toJSON.call(this);
		json.isOverdue = this.isOverdue();
		const date = datetime(this.get('assessmentInstanceEndDate'))
			.setTimezone(appContext.get('user').get('timezoneName'))
			.toMoment();
		json.endDate = date.format(t('date.formats.longDateTime'));
		return json;
	}
});
