import SharedModel from './shared';
import { assign, forEach, find, map, values } from 'lodash';
import answerMixin from '../shared/answer';

export default SharedModel.extend({
	defaults: {
		config: {
			valuesCount: 2
		}
	},

	onInitialize () {
		assign(this, {
			selectedMinAnswer: null,
			selectedMaxAnswer: null,
			defaultMin: null,
			defaultMax: null
		});

		forEach(this.get('tplOptions'), (option) => {
			this.answers[option.getCode()] = this.answerFactory(option);
		});
	},

	defaultMinAnswerCode () {
		return this.instance.getDefaultAnswerOptionCode()[0];
	},
	defaultMaxAnswerCode () {
		return this.instance.getDefaultAnswerOptionCode()[1];
	},

	defaultValues () {
		return [this.defaultMinAnswerCode(), this.defaultMaxAnswerCode()];
	},

	// wrap available options with some methods etc.
	answerFactory (option) {
		const answer = {
			valueOf: () => values(this.answers).indexOf(answer),
			selectedMin: false,
			selectedMax: false,

			deselect: () => {
				if (answer.selectedMin) {
					answer.selectedMin = false;
					this.selectedMinAnswer = null;
				}

				if (answer.selectedMax) {
					answer.selectedMax = false;
					this.selectedMaxAnswer = null;
				}

				this.trigger('change', {
					answerCode: option.getCode()
				});

				return answer;
			},

			selectAsMax: ({ byInput } = { byInput: false }) => {
				this.set('valueSetByInput', byInput);

				if (this.selectedMaxAnswer === answer) {
					return this;
				}

				if (this.selectedMaxAnswer) {
					this.selectedMaxAnswer.deselect();
				}
				answer.selectedMax = true;
				this.selectedMaxAnswer = answer;
				this.isAnswered() && this.trigger('change', {
					answerCode: option.getCode(),
					newState: 'checked',
					side: 'max'
				});
				return answer;
			},

			selectAsMin: ({ byInput } = { byInput: false }) => {
				this.set('valueSetByInput', byInput);

				if (this.selectedMinAnswer === answer) {
					return answer;
				}

				if (this.selectedMinAnswer) {
					this.selectedMinAnswer.deselect();
				}

				answer.selectedMin = true;
				this.selectedMinAnswer = answer;
				this.isAnswered() && this.trigger('change', {
					answerCode: option.getCode(),
					newState: 'checked',
					side: 'min'
				});
				return answer;
			}
		};
		assign(answer, answerMixin({ answer, option }));

		if (option.getCode() === this.defaultMinAnswerCode()) {
			this.defaultMin = answer.selectAsMin({ byInput: false });
		}

		if (option.getCode() === this.defaultMaxAnswerCode()) {
			this.defaultMax = answer.selectAsMax({ byInput: false });
		}

		return answer;
	},
	getResponse ({ submit } = { submit: false }) {
		if (submit && !this.isAnswered()) {
			return;
		}

		const response = {
			questionCode: this.get('id'),
			timeStamp: new Date().getTime(),
			responseType: 'options',
			options: {}
		};
		let inRange = false;

		forEach(this.answers, (answer, i) => {
			if (answer.selectedMin) {
				inRange = true;
			}

			if (inRange) {
				const option = this.get('tplOptions')[i];

				response.options[option.getCode()] = this.instance.isReverseScore() ?
					option.getReverseValue() :
					option.getValue();
			}

			if (answer.selectedMax) {
				inRange = false;
			}
		});

		return response;
	},

	setResponse (response) {
		const answers = this.answers;
		const optVals = map(response.options, (opt, i) => i);
		const min = optVals.shift();
		const max = optVals.pop();
		answers[min].selectAsMin({ byInput: true });
		answers[max ? max : min].selectAsMax({ byInput: true });
		answers[min].selectAsMin({ byInput: true });
	},

	values () {
		const values = [undefined, undefined];
		forEach(this.answers, (answer, i) => {

			if (!answer.isVisible() || i === this.get('ignoredOptionCode')) {
				return;
			}

			if (answer.selectedMin) {
				values[0] = answer;
			}

			if (answer.selectedMax) {
				values[1] = answer;
			}
		});

		return values;
	},

	onClear () {
		const selectedMin = find(this.answers, { selectedMin: true });
		const selectedMax = find(this.answers, { selectedMax: true });
		selectedMin && selectedMin.deselect();
		selectedMax && selectedMax.deselect();
	},

	onUnclear ({ reset } = { reset: false }) {
		if (reset === true) {
			return;
		}
		this.defaultMin && this.defaultMin.selectAsMin({ byInput: false });
		this.defaultMax && this.defaultMax.selectAsMax({ byInput: false });
	}
});
