import SingleEntity from '../shared/single-entity';
import urls from './urls';

export default SingleEntity.extend({
	idAttribute: 'assessmentId',
	eventNamespace: 'assessment',
	urlRoot: urls.assessment.url,

	displayName () {
		return `${this.get('assessmentName')} (${this.get('assessmentCode')}) v${this.get('assessmentVersion')}`;
	},

	getLabel () {
		return this.displayName();
	},

	getPackageName () {
		return `${this.get('assessmentPackageName')} (v${this.get('assessmentPackageVersion')}.${this.get('assessmentPackageRevision')})`;
	},

	getAutospawnURL (autospawnIdentifier) {
		const identifier = autospawnIdentifier || this.get('assessmentAutospawnIdentifier');
		const autospawnUrl = this.getAutospawnPrefix() + identifier;
		const clinicianOnly = this.get('assessmentNoRespondent');
		return (identifier && !clinicianOnly) ? autospawnUrl : '';
	},

	getAutospawnPrefix () {
		return 'autospawn,';
	}
});
