import SingleEntity from '../shared/single-entity';
import urls from './urls';

export default SingleEntity.extend({
	urlRoot: urls.singleClinician.url,
	eventNamespace: 'respondent-clinician',
	idAttribute: 'relationId',

	displayName () {
		if (this.get('administratorFirstName') && this.get('administratorLastName')) {
			return `${this.get('administratorFirstName')} ${this.get('administratorLastName')}`;
		}
		return this.get('administratorUsername');
	}
});
