import Vue from 'vue';
import iconsArray from './icon-settings';
import iconsFa from 'components/icon/icon-mapping-fa';
import t from 'translate';
import { findIndex, forEach } from 'lodash';

export default Vue.component('icon', {
	props: {
		name: {
			type: String,
			required: true,
			validator: (value) => {
				let match = false;
				forEach(iconsArray, (obj) => {

					if (obj.source[value]) {
						match = true;
						return false;
					}
				});
				return match;
			}
		},
		type: {
			type: String,
			default: ''
		},
		additional: {
			type: [Boolean, String],
			default: false
		},
		small: {
			type: Boolean,
			default: false
		},
		className: {
			type: [Array, String],
			default: ''
		},
		title: {
			type: String,
			default: ''
		}
	},
	template: `
		<span :class="iconClasses" :title="iconTitle">
			<span v-if="additional" :class="additionalClasses" />
		</span>
	`,
	computed: {
		iconClasses () {
			const classes = ['icon', this.className];
			let match = null;

			if (this.type) {
				const index = findIndex(iconsArray, { name: this.type });
				const listObj = iconsArray[index];
				match = listObj && listObj.source[this.name] ? listObj : null;

			} else {
				forEach(iconsArray, (obj) => {
					if (obj.source[this.name]) {
						match = obj;
						return false;
					}
				});
			}

			if (match) {
				classes.push(match.action(match.source[this.name]));

				if (!this.small) {
					classes.push(match.small);
				}
			}
			return classes;
		},

		additionalClasses () {
			const classes = ['icon-extra'];

			if (this.type === 'fontawesome') {
				classes.push(...this.faClasses(this.additional, true));
			}
			return classes;
		},

		iconTitle () {
			return this.title.length ? t(this.title) : null;
		}
	},
	methods: {
		faClasses (name) {
			const classes = ['fa', `fa-${iconsFa[name]}`];

			if (!this.small) {
				classes.push('fa-2x');
			}
			return classes;
		}
	}
});
