import MultipleEntities from '../shared/multiple-entities';
import TreatmentTypeClinicianGroup from './clinician-group-entity';
import urls from './urls';

/**
 * TreatmentTypeClinicianGroups Entity
 * @class TreatmentTypes
 * @extends MultipleEntities
 */
const TreatmentTypeClinicianGroupsEntity = MultipleEntities.extend({
	model: TreatmentTypeClinicianGroup,
	urls,
	eventNamespace: 'treatment-type-clinician-group',
	idName: 'administratorGroupId',

	events: {
		'add sync': function () {
			// models stored on the server don't have `treatmentType`
			// attribute which is needed to deduct correct url for deletion
			this.each((model) => {
				model.set('treatmentType', +this.ownerId);
			});
		}
	},

	canAdd: function (model) {
		return +model.get('treatmentType') === +this.ownerId;
	},

	/**
	 * Get all TreatmentTypeClinicianGroups
	 * @method getByTreatmentType
	 * @return {Object} new TreatmentTypeClinicianGroups Collection
	 * @example
	 *        Repository.TreatmentTypeClinicianGroups.getByTreatmentType(2);
	 */
	getByTreatmentType: function (treatmentTypeId) {
		return this.retrieve('listClinicianGroups', treatmentTypeId);
	}

});

export default new TreatmentTypeClinicianGroupsEntity();
