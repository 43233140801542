<template>
	<checkbox :disabled="disabled" :checked="checked" @change="onChange" />
</template>

<script>
import Checkbox from 'components/form/checkbox-simple-input';
import conditionalValue from 'modules/general/shared/conditional-value';
import TableComponentMixin from './_mixin';

export default {
	components: {
		Checkbox
	},

	mixins: [
		TableComponentMixin
	],

	computed: {
		disabled: ({ column }) => column.disabled ? conditionalValue(column.disabled) : true,
		checked: ({ content }) => content
	},

	methods: {
		onChange (data) {
			this.$emit('change', data);
		},

		sortValue: ({ itemValue }) => itemValue
	}
};
</script>
