<template>
	<edit-goal
		:goal="goal"
		:categories="categories"
		:on-submit="onSubmit"
		:on-cancel="resetGoal"
		:treatments="treatments"
	>
		<respondent-search
			v-if="!respondentId"
			v-model="respondent"
		/>
	</edit-goal>
</template>

<script>
import t from 'service/lang/translate';
import { CLINICIAN } from 'service/acl/checkpoints';
import { ADD } from 'service/acl/access-levels';
import { assign, get, has, cloneDeep } from 'lodash';
import EditGoal from '../../components/edit-goal';
import cwalert from 'service/cwalert';
import redirectOnSave from 'modules/general/shared/redirect-on-save';
import { RESPONDENT } from 'store/respondents/respondent';
import { TREATMENT } from 'store/treatments/treatment';

export default {
	title: t('goals.addGoal.addTitle'),
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.GOALS_ACTIVITIES,
		op: ADD
	}],
	actions: ({ $store }) => {
		const actions = [['goalCategories/init']];
		const { treatmentId, respondentId } = $store.getters.urlParams;

		if (treatmentId) {
			actions.push(['treatment/init']);

		} else if (respondentId) {
			actions.push(['treatments/initForRespondent', { respondentId }]);
		}

		return actions;
	},
	components: { EditGoal },
	data () {
		return {
			defaultGoal: {
				respondent: this.$store.getters[RESPONDENT.RESPONDENT_ID],
				treatment: this.$store.getters[TREATMENT.ID]
			},
			goal: {},
			respondent: {},
			treatment: {},
			loading: false
		};
	},
	computed: {
		categories: ({ $store }) => $store.getters['goalCategories/items'],
		respondentId: ({ $store }) => $store.getters[RESPONDENT.RESPONDENT_ID],
		treatmentId: ({ $store }) => has($store.getters.urlParams, 'treatmentId'),
		treatments: ({ $store }) => $store.getters['treatments/ready'],
		respondentSelected: ({ respondent }) => get(respondent, 'respondentId')
	},

	watch: {
		respondent () {
			const respondentId = get(this.respondent, 'respondentId');
			this.treatment = null;
			this.$store.dispatch('treatments/initForRespondent', { respondentId });
		}
	},

	created () {
		this.resetGoal();
	},

	methods: {
		onSubmit () {
			this.setDataLoading();
			const goal = assign(this.goal, { respondentId: this.respondent.respondentId });

			this.$store.dispatch('goals/create', goal).then((res) => {
				cwalert.success(t('goals.editGoal.saveSuccess'));
				this.setDataLoaded();

				redirectOnSave({
					config: get(this.tile.config(), 'redirectOnSave', {}),
					fallback: ['goal', {
						respondentId: res.respondent,
						goalId: res.id
					}]
				});
			});
		},

		resetGoal () {
			this.goal = cloneDeep(this.defaultGoal);
		}
	}
};
</script>
