import Message from '../messages/message';
import { message } from './urls';

/**
 * RespondentMessage entity
 *
 * @class RespondentMessage
 * @extends SingleEntity
 */
export default Message.extend({
	urlRoot: message.url,
	eventNamespace: 'respondent-message'
});
