import MultipleEntities from '../shared/multiple-entities';
import ActivatedApp from './activated-app';
import urls from './urls';

/**
 * ActivatedApps Entity
 * @class ActivatedApps
 * @extends MultipleEntities
 */
const ActivatedApps = MultipleEntities.extend({
	model: ActivatedApp,
	urls,
	eventNamespace: 'activated-app',

	listStoreApps () {
		return this.retrieve('storeApps');
	},

	listActivated (params) {
		return this.retrieve('activatedList', params);
	}
});

export default new ActivatedApps();
