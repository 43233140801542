<template>
	<input
		class="edit-object__number"
		type="number"
		:value="objVal"
		:readonly="readonly"
		@blur="onNumberValueChange"
	/>
</template>
<script>
import inputMixin from './__input-mixin';
import Vue from 'vue';

export default Vue.component('numberInput', {
	mixins: [inputMixin],
	props: {
		objVal: {
			type: Number,
			required: true
		}
	},

	methods: {
		onNumberValueChange (e) {
			this.onValueChange({ path: this.path, value: +e.target.value });
		}
	}
});
</script>
