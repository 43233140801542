import t from 'translate';
import Vue from 'vue';

import './tree-controls';

import { debounce, forEach, noop, split, toLower } from 'lodash';
import scrollIntoView from 'scroll-into-view';

export default Vue.component('tree-search', {
	props: {
		changePosition: {
			type: Function,
			required: true
		},
		createMirror: {
			type: Function,
			required: true
		},
		copy: {
			type: Array,
			default: () => []
		},
		deleteSelected: {
			type: Function,
			required: true
		},
		downloadUrl: {
			type: String,
			default: ''
		},
		enabledCopy: {
			type: Boolean,
			default: false
		},
		enabledMirror: {
			type: Boolean,
			default: false
		},
		enabledPaste: {
			type: Boolean,
			default: false
		},
		localItems: {
			type: Object,
			required: true
		},
		matched: {
			type: Number,
			default: 0
		},
		modifiedNotSaved: {
			type: Boolean,
			default: false
		},
		onRemoveSelected: {
			type: Function,
			required: true
		},
		onSearchKeyUp: {
			type: Function,
			required: true
		},
		performCopy: {
			type: Function,
			required: true
		},
		performPaste: {
			type: Function,
			required: true
		},
		position: {
			type: Number,
			default: 0
		},
		saveAllowed: {
			type: Boolean,
			default: true
		},
		saveTemplate: {
			type: Function,
			default: noop
		},
		selected: {
			type: Array,
			default: () => []
		},
		selectedMirror: {
			type: Boolean,
			default: false
		},
		setModified: {
			type: Function,
			default: noop
		}
	},
	data: () => ({
		highlightedPath: null,
		openItems: [],
		phrase: '',
		titles: {
			search: t('Search')
		}
	}),
	methods: {
		onKeyUp: debounce(function () {
			this.removeSelected();

			if (this.phrase !== '') {
				this.onSearchKeyUp(toLower(this.phrase));
			}
		}, 500),
		addPosition () {
			if (this.position < this.matched) {
				const newPosition = this.position + 1;
				this.changePosition(newPosition);
				this.findElement(newPosition);
			}
		},
		findElement (position) {
			if (this.localItems.children) {
				this.highlightedPath = null;
				this.findElementInSingleRow(this.localItems.children, position);
			}

			if (this.highlightedPath) {
				const parent = this.$parent.$el;
				const highlightedEl = parent.querySelectorAll(`.${this.highlightedPath}`);

				if (highlightedEl) {
					scrollIntoView(highlightedEl[0]);
				}
			}
		},
		findElementInSingleRow (children, position) {
			forEach(children, (level) => {
				if (level.position === position) {
					const splitted = split(level.path, '.');
					this.highlightedPath = splitted.length > 0 ? splitted.join('_') : level.path;
					return false;
				} else if (level.children) {
					return this.findElementInSingleRow(level.children, position);
				}
			});
		},
		removeSelected (erasePhrase = null) {
			if (erasePhrase) {
				this.phrase = '';
			}
			window.scroll({ top: 0 });
			this.onRemoveSelected();
		},
		subtractPosition () {
			if (this.position > 1 && this.matched > 1) {
				const newPosition = this.position - 1;
				this.changePosition(newPosition);
				this.findElement(newPosition);
			}
		}
	},
	template: `
		<div class="tree-search__container">
			<tree-controls
				:copy="copy"
				:createMirror="createMirror"
				:deleteSelected="deleteSelected"
				:downloadUrl="downloadUrl"
				:enabledCopy="enabledCopy"
				:enabledMirror="enabledMirror"
				:enabledPaste="enabledPaste"
				:localItems="localItems"
				:modifiedNotSaved="modifiedNotSaved"
				:performCopy="performCopy"
				:performPaste="performPaste"
				:saveAllowed="saveAllowed"
				:saveTemplate="saveTemplate"
				:selected="selected"
				:selected-mirror="selectedMirror"
				:setModified="setModified"
			/>
			<section class="tree-search__search-container">
				<input type="text"
					v-model="phrase"
					@keyup="onKeyUp"
					class="tree-search__input"
					:placeholder="titles.search"
				/>
				<div class="tree-search__buttons-container">
					<span
						v-if="position > 0"
						class="tree-search__results"
					>{{ position }}/{{ matched }}</span>
					<button
						:class="{ 'tree-search__button-inactive': position >= matched }"
						class="tree-search__button tree-search__button-down"
						@click="addPosition"
					>
						<icon name="angle-down" className="tree-search__icon" />
					</button>
					<button
						:class="{ 'tree-search__button-inactive': position < 2 }"
						class="tree-search__button tree-search__button-up"
						@click="subtractPosition"
					>
						<icon name="angle-up" className="tree-search__icon" />
					</button>
					<button
						:class="{ 'tree-search__button-inactive': phrase === '' }"
						class="tree-search__button tree-search__button-times"
						@click="removeSelected(true)"
					>
						<icon name="times" className="tree-search__icon" />
					</button>
				</div>
			</section>
		</div>
	`
});
