import repository from 'repository';
import { RESPONDENT } from 'store/respondents/respondent';
import { TREATMENT } from 'store/treatments/rp-treatment';
import prefixify from '../__helpers/prefixify';
import { set } from '../__helpers/mutations';

const prefix = prefixify('respondentContentPackageInstances');

const ITEMS = 'items';
const SIZE = 'size';

const INIT = 'init';

const SET = 'set';

export const RESPONDENT_CONTENT_PACKAGE_INSTANCES = {
	ITEMS: prefix(ITEMS),
	SIZE: prefix(SIZE),

	INIT: prefix(INIT)
};

export default {
	namespaced: true,

	state: {
		data: []
	},

	getters: {
		[ITEMS]: (state) => state.data,
		[SIZE]: (state) => state.data.length
	},

	mutations: {
		[SET]: set()
	},

	actions: {
		[INIT]: async ({ commit, rootGetters }) => {
			const contentPackageInstances = await repository.getContentPackages({
				respondentId: rootGetters[RESPONDENT.RESPONDENT_ID],
				treatmentId: rootGetters[TREATMENT.ID]
			});

			commit(SET, contentPackageInstances);
		}
	}
};
