export default {
	excel: 'Spreadsheet',
	pdf: 'Pdf',
	audio: 'Audio',
	word: 'Word document',
	archive: 'Archive',
	text: 'Text',
	code: 'Code',
	powerpoint: '',
	video: 'Video',
	image: 'Image'
};
