import Backbone from 'backbone';
import { forEach, isArray, isBoolean, isFunction, isObject, isString, noop, some } from 'lodash';
import Marionette from 'marionette';
import $ from 'jquery';
import t from 'translate';
import Validation from './validation';
import slugify from 'slugify';

/**
 * Form field base class
 *
 * @class FormComponentView
 * @author Waldemar
 * @author Patrycjusz
 * @param {Object} param
 * @param {String|Function} param.emptyMessage
 * @param {String} param.type describe field type, i.e. text, datetime, select, search
 * @param {String} param.key key of form model field {@link FormView}
 * @param {String} param.label field label
 * @param {Boolean} param.mandatory mark as mandatory
 * @param {Boolean} param.required mark as required
 * @param {Boolean} param.readonly mark as readonly
 * @param {Boolean} param.disabled mark as disabled
 * @param {String} param.placeholder add placeholder in html markup
 * @param {String} param.hint add hint functionality, useful for rendering some additional
 *     information below field, for example some info about acceptable characters
 * @param {Function} param.customize use this for updating this field when related fields trigger
 *     some events for example show/hide, callback arguments: view, formView, formModel
 * @param {String} param.autocomplete
 * @param {String|Function|Array|Object} param.validators
 * @param {String|Function} param.invalidMessage error message
 */
export default Marionette.CompositeView.extend({
	tagName: 'div',
	ui: {
		textarea: 'textarea',
		input: 'input',
		select: 'select',
		hint: '.form-view__hint'
	},
	events: {
		'change @ui.textarea': 'textareaChanged',
		'change @ui.input': 'inputChanged',
		'change @ui.select': 'selectChanged',
		'keyup @ui.input': 'setDirty',
		'keyup @ui.textarea': 'setDirty'
	},
	modelEvents: {
		'change:disabled': 'disabledChanged',
		'change:readonly': 'readonlyChanged',
		'change:value': 'valueChanged',
		'change:hint': 'hintChanged'
	},
	collectionEvents: {
		request: 'setLoading',
		sync: 'setLoaded'
	},
	customClassName: undefined,

	model: new Backbone.Model(),

	onBeforeRender () {
		this.setUniqueName();
		this.parseFlags();

		!this.model.get('emptyMessage') && this.model.set('emptyMessage', t('No items'));
		this.baseClassName = `${this.model.get('formClass')}__field-container`;

		this.className = [
			this.baseClassName,
			` ${this.baseClassName}`,
			`--${this.model.get('type')}`,
			` ${this.baseClassName}`,
			`--${slugify(this.model.get('key') || '')}`
		].join('');

		if (this.customClassName) {
			this.className += ` ${this.customClassName}`;
		}
		this.toggleDisabledClass(!!this.model.get('disabled'));
		this.$invalidMsg = $('<p />').addClass('invalid-msg');
		let show = this.model.get('show');
		const formModel = this.options.formModel;
		const executeIfFunction = function (fn) {
			if (isFunction(fn)) {
				return fn(formModel);
			}
			return fn;
		};

		if (show) {
			show = executeIfFunction(show);
			const triggerEntity = show.listenTo || formModel;
			this.toggleHidden(!show.condition(triggerEntity));
			show.event && this.listenTo(triggerEntity, show.event, () => {
				const toggle = !show.condition(formModel);
				this.toggleHidden(toggle);
			});
		}

		this.listenTo(formModel, `change:${this.model.get('key')}`, this.formModelChanged);

		if (this.model.get('mandatory') && !this.model.has('required')) {
			this.model.set('required', true);
		}
		this.setAutocomplete();
	},

	setUniqueName () {
		const uniqueName = `${this.model.get('name')}__${(new Date()).valueOf()}`;
		this.model.set('uniqueName', uniqueName);
	},

	parseFlags () {
		const flags = this.model.get('flags');

		if (!isArray(flags)) {
			return;
		}

		forEach(flags, (flag) => {
			this.model.set(flag, true);
		});
	},

	setAutocomplete () {
		if (this.model.get('autocomplete') === false) {
			this.model.set('noAutocomplete');
		}
	},

	onRender () {
		const formModel = this.options.formModel;
		const fieldKey = this.model.get('key');

		if (this.model.get('mandatory')) {
			this.className += ` ${this.baseClassName}--mandatory`;
		}

		if (this.model.get('readonly')) {
			this.className += ` ${this.baseClassName}--readonly`;
		}

		this.$el.addClass(this.className);

		this.setPristine();
		this.listenTo(this, 'value.change ', (value, label) => {
			if (label && !this.hidden) {
				const inputLabels = formModel.get('inputLabels') || [];
				inputLabels[fieldKey] = label;
				formModel.set('inputLabels', inputLabels);
			}
			const hydratedValue = this.hydrateValue(value);

			// the change event has to be triggered also when the value doesn't differ from the
			// previous one
			this.model.set('value', this.sanitize(hydratedValue), { silent: true });
			this.model.trigger('change:value', this.model, this.model.get('value'));
		});

		if (this.collection && !this.hidden) {
			this.listenTo(this.collection, 'request', this.setLoading);
			this.listenTo(this.collection, 'sync', this.setLoaded);
		}

		isFunction(this.onAfterRender) && this.onAfterRender();

		if (isFunction(this.model.get('customize'))) {
			this.model.get('customize')(this, this.options.formView, this.options.formModel);
		}

		// this is ugly but
		// 1. this is here because binding with CompositeView.events doesn't work
		// 2. this prevent from bubbling default error window
		this.$('input, select, textarea').on('invalid', (e) => {
			this.setInvalid(e.currentTarget.validationMessage);
			return false;
		});

		this.setFocus();
	},

	sanitize: (value) => value,

	unsanitize: (value) => value,

	hydrateValue: (value) => value,

	setFocus () {
		this.model.get('focus') && setTimeout(() => {
			this.ui.input.trigger('focus');
		}, 300);

		return this;
	},

	toggleHidden (bool) {
		const fieldKey = this.model.get('key');
		this.hidden = bool;

		if (!bool) {
			this.options.formView.fieldViews[fieldKey] = this;
		}
		this.$el && this.toggleVisibility();
	},

	toggleVisibility () {
		const hiddenClassName = `${this.model.get('formClass')}__field-container--hidden`;
		this.$el.toggleClass(hiddenClassName, this.hidden);
	},

	setInitial () {
		this.model.set('isInvalid', false);
		this.$el.removeClass('valid invalid');
	},

	setValid () {
		this.$el.removeClass('invalid').addClass('valid');
		this.$invalidMsg.detach();
		this.model.set('isInvalid', false);
		return this;
	},

	setInvalid (msg) {
		this.model.set('isInvalid', true);
		this.$el.removeClass('valid').addClass('invalid');

		if (msg) {
			this.$el.append(this.$invalidMsg);
			this.$invalidMsg.html(msg);
		}
		return this;
	},

	isValid () {
		return !this.model.get('isInvalid');
	},

	formModelChanged (model, value) {
		this.model.set('value', value);
	},

	inputChanged () {
		this.determineDirty();
		this.setValid();
		this.trigger('value.change', this.ui.input.val());
		isFunction(this.model.get('onChange')) && this.model.get('onChange')({
			value: this.ui.input.val(),
			label: this.getLabel(),
			validation: this.validate()
		});
	},

	textareaChanged () {
		this.determineDirty();
		this.trigger('value.change', this.ui.textarea.val());
		isFunction(this.model.get('onChange')) && this.model.get('onChange')({
			value: this.ui.input.val(),
			label: this.getLabel(),
			validation: this.validate()
		});
	},

	selectChanged () {
		this.trigger('value.change', this.ui.select.val(), this.getLabel());
		isFunction(this.model.get('onChange')) && this.model.get('onChange')({
			value: this.ui.select.val(),
			label: this.getLabel(),
			validation: this.validate()
		});
	},

	disabledChanged (model, disabled) {
		if (this.ui.select instanceof $) {
			this.ui.input.prop('disabled', disabled);
			this.toggleDisabledClass(disabled);
		}
	},

	toggleDisabledClass (disabled) {
		this.$el.toggleClass(`${this.model.get('formClass')}__field-container--disabled`, disabled);
	},

	readonlyChanged (model, readonly) {
		this.$el.toggleClass(`${this.baseClassName}--readonly`, readonly);
		this.ui.input.prop('readonly', readonly);
	},

	valueChanged (model, val) {
		const value = this.unsanitize(val);

		if (!this.hidden) {
			this.setInitial();

			this.ui.input && this.ui.input.val(value);
			this.ui.select && this.ui.select.val(value);
		}

		if (!value) {
			this.ui.input && this.ui.input.val('');
			this.ui.select && this.model.get('description') && this.ui.select.val(null);
		}
	},

	setHint (val) {
		return this.model.set('hint', val);
	},

	hintChanged () {
		if (this.model.has('hint')) {
			this.ui.hint.html(this.model.get('hint'));

		} else {
			this.ui.hint.empty().addClass('form-view__hint--hide');
		}
	},

	determineDirty () {
		const dirty = !!this.model.get('value') && !!this.model.get('value').length;
		this.model.set('dirty', dirty);
	},

	setPristine () {
		this.model.set('dirty', false);
	},

	setDirty () {
		this.model.set('dirty', true);
	},

	isDirty () {
		return !!this.model.get('dirty');
	},

	getValue () {
		return this.model.get('value');
	},

	getLabel () {
		return this.model.get('value');
	},

	setLoading () {
		this.$el.addClass(`${this.baseClassName}--loading`);
		return this;
	},

	setLoaded () {
		this.$el.removeClass(`${this.baseClassName}--loading`);
		return this;
	},

	getInput () {
		let input;
		some(['input', 'textarea', 'select'], (uiElementName) => {
			input = this.ui[uiElementName];
			return !!this.ui[uiElementName].length;
		});

		return input;
	},

	/*
	 * Validate form field.
	 *
	 * Validators can be just one validator or array of validators.
	 * If any of validators will fail validation will set field as invalid.
	 * Invalid message can be customized using "invalidMessage" property.
	 * For more details see _validator function.
	 *
	 * Remember, if field is hidden validate will always return true.
	 *
	 * @see for example see _validator function below
	 * @returns Array {Promise}.
	 */
	validate () {
		this.setValid();
		let dff;

		// hidden field isn't validated
		if (this.hidden) {
			this.setInitial();
			dff = $.Deferred();
			dff.resolve();
			return [dff.promise()];
		}

		if (!this._validateNative()) {
			dff = $.Deferred();
			dff.reject();
			return [dff.promise()];
		}

		// if field is required and doesn't have value
		if (this.model.get('required') && !this.model.get('value')) {
			this.setInvalid(this._invalidMessage('required'));
			dff = $.Deferred();
			dff.reject();
			return [dff.promise()];
		}

		// if field is empty or doesn't have any validator probably we should skip it
		if (!this.model.has('validators') || !this.model.get('value')) {
			this.setInitial();
			dff = $.Deferred();
			dff.resolve();
			return [dff.promise()];
		}

		const validators = this.model.get('validators');
		const result = [];

		// if validator is array of validators
		if (isArray(validators)) {
			for (const i in validators) {
				if (validators.hasOwnProperty(i)) {
					result.push(this._validate(this.model.get('validators')[i]));
				}
			}

		} else {
			result.push(this._validate(validators));
		}

		return result;
	},
	/*
	 * Run native browser validation.
	 *
	 * @returns {boolean}
	 */
	_validateNative () {
		if (!!this.ui.input && this.ui.input.length && !!this.ui.input[0].willValidate) {
			return this.ui.input[0].checkValidity();
		}

		if (!!this.ui.textarea && this.ui.textarea.length && !!this.ui.textarea[0].willValidate) {
			return this.ui.textarea[0].checkValidity();
		}

		if (!!this.ui.select && this.ui.select.length && !!this.ui.select[0].willValidate) {
			return this.ui.select[0].checkValidity();
		}

		return true;
	},
	/*
	 * Run specific validator.
	 *
	 * Validator should return true if current field value is correct or false in otherwise.
	 * Validator can be an string, function or object:
	 * * if it is string validator will use validation from default library, for example: Email,
	 * number, regexp
	 * * if it is function validator will run this function, it should return boolean
	 * * if it is object it should contain key "validator" which is function or string described as
	 * above, and optionally:
	 *    * "invalidMessage" - string which is displayed when validator will return false
	 *    * "args" - additional arguments for "validator" function.
	 *
	 * @example just simple email validation
	 * { "validators": "email" }
	 *
	 * @example
	 * { "validators": ["email"] }
	 *
	 * @example test if value is nan
	 * { "validators": function(value) { return isNaN(value); } }
	 *
	 * @example test regexp
	 * { "validators": { "validator" : "regexp", "args" : /^[a-Z]{2,3}$/, "invalidMessage": "Error"
	 *     } }
	 *
	 * @example test if value is number and is between 0 and 9999
	 * { "validators": { "validator" : "number", "args" : [0, 9999] } }
	 *
	 * @member {FormComponentView}
	 * @param {type} validator
	 * @returns {Promise}
	 */
	_validate (validator) {
		let result;

		// validator can be simple function that should return boolean
		if (isFunction(validator)) {
			result = validator(this.model.get('value'));
			return this._resolveResult(result, validator);

			// validator can be also predefined in validation.js
		} else if (isString(validator) && !!Validation[validator]) {
			result = Validation[validator](this.model.get('value'));
			return this._resolveResult(result, validator);

			// validator can be also more complicated, validator can be custom function, with custom
			// "args" and custom "invalidMessage"
		} else if (isObject(validator) && !!validator.validator) {
			let validatorFn = noop;

			if (isFunction(validator.validator)) {
				validatorFn = validator.validator;

			} else if (isString(validator.validator) && !!Validation[validator.validator]) {
				validatorFn = Validation[validator.validator];

			}
			// args are concated with current value
			const args = [this.model.get('value')].concat(validator.args ? validator.args : []);
			result = validatorFn(...args);
			return this._resolveResult(result, validator);
		}
	},
	/*
	 * Resolve result.
	 *
	 * @param {type} result - Can be promise or boolean.
	 * @param {type} validator
	 * @return {Promise}
	 */
	_resolveResult (result, validator) {
		const dff = $.Deferred();

		if (isObject(result) && result.done && result.fail) {
			$.when(result).done(() => {
				this.setValid();
				dff.resolve();
			}).fail(() => {
				this.setInvalid(this._invalidMessage(validator));
				dff.reject();
			});

		} else if (isBoolean(result)) {
			if (!result) {
				this.setInvalid(this._invalidMessage(validator));
				dff.reject();
			} else {
				this.setValid();
				dff.resolve();
			}
		}

		return dff.promise();
	},

	_invalidMessage (validator) {
		let msg = '';

		if (validator.invalidMessage) {
			msg = validator.invalidMessage;

		} else if (isString(validator) && !!Validation.invalidMessages[validator]) {
			msg = Validation.invalidMessages[validator];

		} else {
			msg = this.model.get('invalidMessage') || t('Error');
		}
		return msg;
	},

	// override this if you need to e.g. clean some zombie events
	customRemove () {
		this.trigger('remove');
		this.remove();
	}
});
