<template>
	<div :class="classes.content">
		<h3 v-if="title" :class="classes.title">
			<icon
				:name="icon"
				:class-name="classes.icon"
			/><span :class="classes['title-text']">{{title}}</span>
		</h3>
		<slot name="afterTitle" />
		<h4
			v-if="!isCollapsed && subtitle"
			:class="classes.subtitle"
		>{{subtitle}}</h4>
		<p
			v-if="descriptionVisible"
			:class="classes.description"
		>{{description}}</p>
	</div>
</template>

<script>
import { optionalString, requiredBoolean, requiredObject } from './props';
import { AGENDA_ITEM_CONTENT } from './constants';

export default {
	name: AGENDA_ITEM_CONTENT,
	props: {
		title: optionalString,
		subtitle: optionalString,
		description: optionalString,
		icon: optionalString,
		classes: requiredObject,
		isCollapsed: requiredBoolean
	},

	computed: {
		descriptionVisible: ({ isCollapsed, description, title, subtitle }) =>
			(!isCollapsed && description) ||
			(!title && !subtitle && description)
	}
};
</script>
