import SingleEntity from '../shared/single-entity';
import urls from './urls';
import Backbone from 'backbone';

/**
 * TreatmentAttribute Entity
 * @class TreatmentAttribute
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	eventNamespace: 'treatment-attribute',
	urlRoot: urls.attribute.url,
	url: urls.attribute.url,
	onSync: function (method, model, options) {
		if (method === 'create') {
			options.type = 'PUT';
		}
		return Backbone.sync.apply(this, arguments);
	}
});

