import SingleEntity from '../shared/single-entity';
import urls from './urls';

/**
 * Page
 * @class Page
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	eventNamespace: 'page',
	url: urls.single.url
});
