import SingleEntity from '../shared/single-entity';
import urls from './urls';
import Backbone from 'backbone';

/**
 * TreatmentTypeClinicianGroup Entity
 * @class TreatmentTypeClinicianGroup
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	url: function () {
		return urls.singleClinicianGroup.url(this.get('treatmentType'), this.get('administratorGroupId'));
	},
	eventNamespace: 'treatment-type-clinician-group',
	idAttribute: 'administratorGroupId',

	onSync: function (method, model, options) {
		if (method === 'create') {
			options.type = 'PUT';
		}

		return Backbone.sync.apply(this, arguments);
	}
});

