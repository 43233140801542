import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import RemoteScoringAssessment from './remote-scoring-assessment';

const RemoteScoringAssessmentsEntity = MultipleEntities.extend({
	urls,
	model: RemoteScoringAssessment,
	idName: 'AssessmentId',
	eventNamespace: 'remote-scoring-assessment',

	getAll () {
		return this.retrieve('remoteScoringAssessments');
	}

});

export default new RemoteScoringAssessmentsEntity();
