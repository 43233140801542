import t from 'translate';
import repository from 'repository';
import { when } from 'jquery';
import store from 'store';
import { TREATMENT } from 'store/treatments/rp-treatment';

const card = {
	title: t('New message'),
	name: 'new-message',
	url: 'treatment/:treatmentId/messages/new',

	ctrl: ({ cardData }) => {
		const drafts = repository.messageDrafts({ treatment: store.getters[TREATMENT.ID] });
		const treatments = repository.treatments();
		cardData.set({ drafts, treatments, isNew: true });

		return when(drafts, treatments);
	}
};

export { card as NewMessage };
