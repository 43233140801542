import { View } from 'marionette';
import $ from 'jquery';
import Handlebars from 'handlebars';

const AppLayout = View.extend({
	template: Handlebars.compile(`
		<section class="app-appbar__container"></section>
		<section class="app-content__container" role="main"></section>
		<div class="alert__container"></div>
	`),
	regions: {
		appContent: '.app-content__container',
		appBar: '.app-appbar__container'
	},

	onRender () {
		$('body').on('click', this.trigger.bind(this, 'click'));
		$(document).on('keyup', this.trigger.bind(this, 'keyup'));
	},

	setLangAttr (lang) {
		document.documentElement.setAttribute('lang', lang);
		return this;
	}
});

export default new AppLayout();
