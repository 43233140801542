<template>
	<div :class="containerClassNames">
		<button
			tabindex="0"
			:class="filterClassNames"
			@click="toggle()"
		>
			{{label}}
		</button>
	</div>
</template>

<script>
import { constant } from 'lodash';

export default {
	props: {
		items: {
			type: Array,
			default: constant([])
		},
		label: {
			type: String,
			default: ''
		},
		value: {
			type: [Number, String],
			required: true
		}
	},

	computed: {
		toggled: ({ value }) => value === '1',

		model: {
			get: ({ value }) => value,
			set (newVal) {
				this.$emit('input', this.stringValue(newVal));
			}
		},

		containerClassNames: ({ classNames, toggled }) => ({
			'table-view__filter-checkbox': true,
			...classNames('container', toggled)
		}),

		filterClassNames: ({ classNames, toggled }) => classNames('toggle', toggled)
	},

	methods: {
		classNames (block, condition) {
			return {
				[`filter__${block}`]: true,
				[`filter__${block}--active`]: condition
			};
		},

		toggle () {
			this.model = !this.toggled;
		},

		stringValue (val) {
			return val ? '1' : '0';
		}
	}
};
</script>
