import { forEach, isArray, isFunction, isObject, isString, noop } from 'lodash';
import appContext from 'app-context';

/**
 * Card app events.
 *
 * @deprecated
 * @name cardAppEvents
 * @param  {object|array|string} events - Set of events to listen to on `appContext`.
 * @example
 *
 * const card = {
 *     appEvents: ['assessment-instance.delete']
 * };
 */
export default function (events) {
	const registerEvent = (eventName, eventAction) => {
		this.listenTo(appContext, eventName, eventAction);
	};
	const cardReload = () => {
		this.populate();
	};

	if (isString(events)) {
		registerEvent(events, cardReload);

	} else if (isArray(events)) {
		registerEvent(events.join(' '), cardReload);

	} else if (isObject(events)) {
		forEach(events, (eventAction, eventName) => {
			let fn = noop;

			if (isString(eventAction)) {
				fn = this[eventAction];

			} else if (isFunction(eventAction)) {
				fn = eventAction;
			}
			registerEvent(eventName, fn);
		});
	}
}
