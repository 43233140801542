import Attachment from './content/Attachment';
import AudioVideo from './content/AudioVideo';
import ImageElement from '../components/image/view';
import Section from './content/Section';
import Text from '../components/text-content/view';
import Checkbox from '../components/checkbox/view';
import Combobox from '../components/select/view';
import Country from '../components/country/view';
import DateInput from './questions/DateInput';
import ImageWidget from './questions/image-widget';
import NumericInput from '../components/numeric-input/view';
import Radiobutton from '../components/radio-button/view';
import StringInput from '../components/string-input/view';
import slider from '../components/slider/slider';

export default {
	Attachment,
	AudioVideo,
	ImageElement,
	Section,
	Text,
	Checkbox,
	Combobox,
	Country,
	DateInput,
	ImageWidget,
	NumericInput,
	NumericRange: slider.views.NumericRange,
	NumericSlider: slider.views.NumericSlider,
	Radiobutton,
	Range: slider.views.Range,
	Slider: slider.views.Slider,
	StringInput
};
