import textExpression from '../../../core/text-expression';

export default ({ answer, option }) => ({
	getCode: () => option.getCode(),
	getId: () => `${answer.getElementId()}:${option.getCode()}`,
	getTemplateId: () => option.getCode(),
	getType: () => option.getType(),
	getLabel: () => textExpression({
		text: option.getLabel(),
		guiAttributes: option.guiAttributes
	}),
	isVisible: () => option.guiAttributes.isVisible(),
	image: () => option.image(),
	getAlt: () => option.getDescription() || option.image()
});
