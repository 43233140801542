import { constant, noop } from 'lodash';

export const optionalArray = {
	type: Array,
	default: constant([])
};

export const optionalFunction = {
	type: Function,
	default: noop
};

export const optionalObject = {
	type: Object,
	default: constant({})
};

export const optionalString = {
	type: String,
	default: ''
};

export const requiredArray = {
	type: Array,
	required: true
};

export const requiredFunction = {
	type: Function,
	required: true
};

export const requiredObject = {
	type: Object,
	required: true
};

export const requiredString = {
	type: String,
	required: true
};

export const requiredBoolean = {
	type: Boolean,
	required: true
};
