import TileView from 'tile-view';
import repository from 'repository';
import moment from 'moment';
import DiaryEntriesList from '../../views/list';
import viewCollection from '../../shared/view-collection';
import { filter } from 'lodash';

export default TileView.extend({
	acl: [],
	features: ['RESPONDENT_DIARY'],

	init () {
		this.filter = this.cardContext().get('filter');
		this.entries = repository.getDiaryEntries();
	},

	appEvents: {
		'diary-entry.create diary-entry.update': 'onEntriesUpdate'
	},

	bindEvents () {
		const cardContext = this.cardContext();

		this.listenTo(cardContext, 'diary:filter-by-month', this.filterByMonth);
		this.listenTo(cardContext, 'diary:filter-by-year', this.filterByYear);
		this.listenTo(cardContext, 'diary:show-all', this.showAll);
	},

	onRender () {
		this.load(this.entries);
	},

	loaded () {
		this.collection = this.entries.sort();
		this.viewCollection = viewCollection(this.collection, { limit: false });

		this.listView = new DiaryEntriesList({
			collection: this.viewCollection
		});
		this.bindEvents();

		this.$el.append(this.listView.$el);
		this.listView.render();
	},

	filterByMonth (month, year) {
		this.viewCollection.reset(filter(this.collection.models, (model) => {
			const createdAt = moment(model.get('createdAt'), 'YYYY-MM-DD HH:mm:ss');
			return createdAt.month() === month && createdAt.year() === year;
		}));
	},

	filterByYear (year) {
		this.viewCollection.reset(filter(this.collection.models, (model) => {
			const createdAt = moment(model.get('createdAt'), 'YYYY-MM-DD HH:mm:ss');
			return createdAt.year() === year;
		}));
	},

	showAll () {
		this.viewCollection.reset(this.collection.models);
	},

	onEntriesUpdate () {
		if (this.filter.month && this.filter.year) {
			this.filterByMonth(this.filter.month, this.filter.year);

		} else if (this.filter.year) {
			this.filterByYear(this.filter.year);

		} else {
			this.showAll();
		}
	}
});

