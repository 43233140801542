import User from './user';
import { CircleMembers } from './members';
import { Circles } from './circles';
import { CirclePosts } from './posts';
import { CircleCandidates } from './candidates';
import { CircleAbuseReports } from './abuse-reports';
import CircleAbuseReport from './abuse-report';
import { CircleAbuseReportStatuses } from './abuse-report-statuses';
import UserBan from './user-ban';

export default {
	/**
	 * @function circle
	 * @param {string|number} id - Circle ID.
	 * @returns {Object}         - Circles Collecion.
	 * @example
	 *        repository.circle(701);
	 */
	circle: (id) => Circles.getById(id),

	/**
	 * @function circles
	 * @returns {Collection} - Circles Collecion.
	 * @example
	 *        repository.circles();
	 */
	circles: () => Circles.list(),

	/**
	 * @function circles
	 * @param {string|number} id - Circle ID.
	 * @returns {Collection}     - Circles Collecion.
	 * @example
	 *        repository.circlesByUser(1405);
	 */
	circlesByUser: (id) => Circles.listByUser(id),

	/**
	 * @function memberMe
	 * @param {string|number} id - Circle user ID.
	 * @returns {Object}         - Member model.
	 * @example
	 *        repository.memberMe();
	 */
	memberMe (id) {
		const user = new User({ id });
		return user.retrieve();
	},

	/**
	 * @function circleMembers
	 * @param {Object} params - Parameters.
	 * @returns {Object} - CircleMembers Collection.
	 * @example
	 *        repository.circleMembers({ circleId: 700 });
	 */
	circleMembers: ({ circleId, start, limit } =  { start: 0, limit: 10 }) =>
		CircleMembers.list({ circleId, start, limit }),

	/**
	 * @function searchCircles
	 * @param {Object} params - Search params.
	 * @returns {Object}      - Circles Collection.
	 * @example
	 *        repository.searchCircles({
     * 			query: 'example',
     * 			limit: 10,
     * 			start: 0
     * 		});
	 */
	searchCircles: (params) => Circles.search(params),

	/**
	 * @function searchAbuseReports
	 * @param {Object} params - Search params.
	 * @returns {Object}      - AbuseReports Collection.
	 * @example
	 *        repository.searchAbuseReports({
     * 			query: 'example',
     * 			limit: 10,
     * 			start: 0
     * 		});
	 */
	searchAbuseReports: (params) => CircleAbuseReports.search(params),

	/**
	 * @function circlePosts
	 * @param {Object} params - Parameters.
	 * @returns {Object}      - CirclePosts Collection.
	 * @example
	 *        repository.circlePosts(701);
	 */
	circlePosts: (params) => CirclePosts.list(params),

	/**
	 * @function circleCategories
	 * @returns {Object} - CircleCategories Collection.
	 * @example
	 *        repository.circleCategories();
	 */
	circleCategories () {
		return this.CircleCategories.getAll();
	},

	/**
	 * @function circleCandidates
	 * @param {Object} params          - Parameters.
	 * @param {number} params.circleId - Circle ID.
	 * @param {number} params.start    - Start.
	 * @param {number} params.limit    - Limit.
	 * @returns {Collection}           - CircleCandidates Collection.
	 * @example
	 *        repository.circleCandidates({ circleId: 700, start: 0, limit: 10 });
	 */
	circleCandidates: ({ circleId, start, limit } = { start: 0, limit: 10 }) =>
		CircleCandidates.list({ circleId, start, limit }),

	/**
	 * @function circleAbuseReports
	 * @param {Object} params          - Parameters.
	 * @param {number} params.start    - Start.
	 * @param {number} params.limit    - Limit.
	 * @returns {Collection}           - CircleAbuseReports Collection.
	 * @example
	 *        repository.circleAbuseReports({ start: 0, limit: 10 });
	 */
	circleAbuseReports: ({ start, limit } = { start: 0, limit: 10 }) =>
		CircleAbuseReports.list({ start, limit }),

	/**
	 * @function circleAbuseReportStatuses
	 * @returns {Collection} - CircleAbuseReportStatuses Collection.
	 * @example
	 *        repository.circleAbuseReportStatuses();
	 */
	circleAbuseReportStatuses: () => CircleAbuseReportStatuses.list(),

	/**
	 * @function banCircleUser
	 * @param {number} circleUserId - Circle ID.
	 * @returns {Object}            - Promise.
	 * @example
	 * 		repository.banCircleUser(8);
	 */
	banCircleUser: (circleUserId) => {
		const ban = new UserBan({ circleUserId });
		return ban.save();
	},

	/**
	 * @function unBanCircleUser
	 * @param {number} circleUserId - Circle ID.
	 * @returns {Object}            - Promise.
	 * @example
	 * 		repository.unBanCircleUser(8);
	 */
	unBanCircleUser: (circleUserId) => {
		const ban = new UserBan({ circleUserId });
		return ban.destroy();
	},

	/**
	 * @function deleteAbuseReportPost
	 * @param {number} id - Abuse Report id.
	 * @returns {Object}  - Promise.
	 * @example
	 * 		repository.deleteAbuseReportPost(10);
	 */
	deleteAbuseReportPost: (id) => {
		const report = new CircleAbuseReport({ id });
		return report.deletePost();
	},

	/**
	 * @function revealAbuseAuthorIdentity
	 * @param {number} id - Abuse Report id.
	 * @returns {Object}  - Promise.
	 * @example
	 * 		repository.revealAbuseAuthorIdentity(10);
	 */
	revealAbuseAuthorIdentity: (id) => {
		const report = new CircleAbuseReport({ id });
		return report.identifyUser();
	}
};
