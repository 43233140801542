import { agenda } from '../agenda';
import activities from '../goals/activities';
import activity from '../goals/activity';
import apps from '../apps/apps';
import circles from '../circles/circles';
import components from '../components';
import contexts from '../general/contexts';
import cube from '../cube/cube';
import currentCard from '../engine/current-card';
import engine from '../engine/engine';
import filter from 'components/filter/store';
import goal from '../goals/goal';
import goalCategories from '../goals/goal-categories';
import goals from '../goals/goals';
import languages from '../general/languages';
import messages from '../messages/messages';
import rawEntities from '../general/raw-entities';
import respondent from '../respondents/respondent';
import search from '../general/search';
import session from '../session/session';
import timezones from '../general/timezones';
import treatments from '../treatments/treatments';
import types from '../general/types';

export default {
	activities,
	activity,
	agenda,
	apps,
	circles,
	components,
	contexts,
	cube,
	currentCard,
	engine,
	filter,
	goal,
	goalCategories,
	goals,
	languages,
	messages,
	rawEntities,
	respondent,
	search,
	session,
	timezones,
	treatments,
	types
};
