<template>
	<select
		class="edit-object__select"
		:value="objVal"
		:disabled="readonly"
		@blur="onSelectValueChange"
	>
		<option v-for="value in values" :key="value" :value="value">
			{{value}}
		</option>
	</select>
</template>

<script>
import inputMixin from './__input-mixin';
import Vue from 'vue';

export default Vue.component('selectInput', {
	mixins: [inputMixin],
	props: {
		objVal: {
			type: [String, Boolean, Number],
			required: true
		},
		values: {
			type: Array,
			required: true
		}
	},
	methods: {
		onSelectValueChange (e) {
			this.onValueChange({ path: this.path, value: e.target.value });
		}
	}
});
</script>
