import repository from 'repository';
import { groupBy } from 'lodash';
import { set, update } from '../__helpers/mutations';
import prefixify from '../__helpers/prefixify';
import { RESPONDENT } from 'store/respondents/respondent';
import { TREATMENT } from 'store/treatments/rp-treatment';

const prefix = prefixify('respondentPages');

const DATA = 'data';
const SET = 'set';
const UPDATE = 'update';

const GROUPED_BY_TYPE = 'groupedByType';
const GROUPED_BY_CONTEXT = 'groupedByContext';
const SIZE = 'size';

const INIT = 'init';
const COMPLETE = 'complete';

export const RESPONDENT_PAGES = {
	SIZE: prefix(SIZE),
	GROUPED_BY_TYPE: prefix(GROUPED_BY_TYPE),
	GROUPED_BY_CONTEXT: prefix(GROUPED_BY_CONTEXT),

	INIT: prefix(INIT),
	COMPLETE: prefix(COMPLETE)
};

export default {
	namespaced: true,

	state: {
		[DATA]: []
	},

	getters: {
		[GROUPED_BY_TYPE]: (state) => groupBy(state[DATA], `instance.type.label`),
		[GROUPED_BY_CONTEXT]: (state) => groupBy(state[DATA], `instance.context.label`),
		[SIZE]: (state) => state[DATA].length
	},

	mutations: {
		[SET]: set(),
		[UPDATE]: update()
	},

	actions: {
		[INIT]: async ({ commit, rootGetters }) => {
			const pages = await repository.getAssignedPages({
				respondentId: rootGetters[RESPONDENT.RESPONDENT_ID],
				treatmentId: rootGetters[TREATMENT.ID]
			});
			commit(SET, pages);
		},
		[COMPLETE]: async (context, { contentPackageId, elementId }) => {
			await repository.elementMarkAsCompleted({ contentPackageId, elementId });
		}

	}
};
