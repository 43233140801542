import Modernizr from 'modernizr';
import $ from 'jquery';
import moment from 'moment';
import t from 'translate';
import systemSettings from 'system-settings';
import datetime from 'datetime';
import { isString, isObject, isUndefined, forEach } from 'lodash';

export default function () {
	(function ($) {
		$.fn.cwdatepicker = function (params = {}) {
			// native date support
			if (Modernizr.inputtypes.date) {
				const $input = this;
				const input = this[0];
				input.type = 'date';

				$input.off('blur').one('blur', () => {
					input.checkValidity();

					if (input.validity.rangeUnderflow) {
						input.value = input.min;

					} else if (input.validity.rangeOverflow) {
						input.value = input.max;
					}
				});

				const stringDate = (date) => moment(date).format('YYYY-MM-DD');

				const parseParams = ({ minDate, maxDate, onSelect }) =>{
					if (minDate) {
						input.min = stringDate(minDate);
					}
					if (maxDate) {
						input.max = stringDate(maxDate);
					}
					if (onSelect) {
						$input.on('change', () => {
							const date = datetime(input.value).toObject();
							onSelect(date);
						});
					}
				};

				if (isObject(params)) {

					/** Set some sensible default min/max values for the year.
					 * The native datetime-local inputs need this attribute **/
					params.maxDate = params.maxDate || moment('2100', 'YYYY').toDate();
					params.minDate = params.minDate || moment('1900', 'YYYY').toDate();

					parseParams(params);
				}

				if (isString(params)) {
					const fn = {
						getDate: () => {
							if (isString(input.value) && input.value.length > 0) {
								return moment(input.value).toDate();
							}
						},

						setDate: (newDate) => {
							input.value = newDate ? stringDate(newDate) : null;
						}
					}[params];

					return fn.call(this, arguments[1]);
				}

				// jQuery datepicker fallback
			} else {
				const $this = $(this);

				if (!isObject(params)) {
					const args = arguments;
					const instantMethods = ['getDate'];
					const returnFn = () => $this.datepicker.apply(this, args);

					if (instantMethods.indexOf(args[0]) > -1) {
						return returnFn();

					} else {
						setTimeout(returnFn, 200);
					}
				}

				return this.each(() =>{
					const settings = {
						dateFormat: t('date.formats.quizDateFormat'), //'dd.mm.yy',

						dayNamesShort: t('gui.calendar.dayShortNames').split('\n'),
						dayNamesMin: t('gui.calendar.dayShortNames').split('\n'),
						dayNames: t('gui.calendar.dayFullNames').split('\n'),

						monthNames: t('gui.calendar.monthFullNames').split('\n'),
						monthNamesShort: t('gui.calendar.monthShortNames').split('\n'),

						nextText: t('gui.calendar.NextMonth'),
						prevText: t('gui.calendar.PrevMonth'),

						closeText: t('gui.calendar.Close'),
						currentText: t('gui.respondent-calendar.Today'),

						firstDay: (systemSettings.getInteger('FIRST_DAY_OF_WEEK') + 1) % 7,

						showButtonPanel: true,
						constrainInput: true
					};

					$this.prop('readonly') && $this.prop('disabled', true);

					forEach(params, (value, key) => {
						if (!isUndefined(value)) {
							settings[key] = value;
						}
					});

					$this.datepicker(settings);
				});
			}
		};
	})($);
}
