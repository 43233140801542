import { assign, cloneDeep, debounce, findIndex, omit, pick } from 'lodash';
import t from 'translate';
import Vue from 'vue';
import $ from 'jquery';
import store from 'store';
import { TREATMENT } from 'store/treatments/rp-treatment';
import TileView from 'tile-view';
import formView from 'components/form-view/form-view';
import cwalert from 'cwalert';
import Message from 'repo/respondent-messages/message';
import MessageDraft from 'repo/respondent-messages/message-draft';
import MessageDraftAttachment from 'repo/respondent-messages/message-draft-attachment';
import attachmentsComponent from '../../shared/attachments-component';
import systemSettings from 'system-settings';

const getDraft = ({ drafts }) => {
	let draft;
	let draftPromise;

	if (drafts.size()) {
		draft = drafts.at(-1);
		draftPromise = draft.fetch();

	} else {
		draft = new MessageDraft({
			treatment: store.getters[TREATMENT.ID],
			senderType: 'RESPONDENT',
			respondent: +store.state.user.userId
		});
		draftPromise = draft.patchSave({ content: '' });
	}

	return { draft, draftPromise };
};

export default TileView.extend({
	title: t('New message'),
	acl: () => !store.state.user.userSettings['respondent.disable-communication'],
	SAVE_THRESHOLD: 500,
	features: ['ENABLE_MESSAGING_SYSTEM'],

	cardData: () => ['drafts'],

	tileData: ({ tile }) => getDraft(pick(tile, 'drafts')),

	attachmentFactory: (tile, opts) => new MessageDraftAttachment(assign({
		treatmentId: store.getters[TREATMENT.ID],
		messageDraftId: tile.draft.getId()
	}, opts)),

	loaded: ({ data, tile }) => {
		tile.enableAttachments = systemSettings.getBoolean('ENABLE_ATTACHMENTS');
		tile.attachments = data.draft.get('attachment') || [];
		const attachment = tile.attachmentFactory(tile);
		let draftXhr;

		tile.form = formView({
			name: 'new-message',
			model: data.draft,
			loader: true,
			listenTo: ['submit'],
			preventSave: true,

			onAfterSave: () => {
				const messageData = cloneDeep(omit(tile.draft.toJSON(), 'id'));
				const message = new Message(messageData);
				message.save({ attachment: tile.attachments }).then(() => {
					if (draftXhr && draftXhr.readyState !== 4) {
						draftXhr.onreadystatechange = null;
						draftXhr.abort();
					}
					delete tile.draft;
					tile.card().close({
						openCard: 'messages',
						cardQuery: {
							treatmentId: store.getters[TREATMENT.ID]
						}
					});
				});
			},

			fields: [{
				key: 'content',
				type: 'textarea',
				label: t('Message text'),
				hint: ' ',
				rows: 10,

				customize: (view) => {
					view.ui.textarea.on('keyup', () => {
						view.setHint(t('Saving draft'));
					});

					view.ui.textarea.on('keyup', debounce(() => {
						if (!tile.draft) {
							return;
						}
						const content = view.ui.textarea[0] ?
							view.ui.textarea[0].value :
							data.draft.get('content');
						data.draft.patchSave({ content }).then(() => {
							view.setHint(t('Draft saved'));
							setTimeout(() => {
								view.setHint('');
							}, 3000);
						});
					}, tile.SAVE_THRESHOLD));
				}
			}, tile.enableAttachments && {
				key: 'attachmentUpload',
				type: 'file',
				label: '',
				multi: true,
				template: () => `
					<div class="form-view__file-container">
						<p class="form-view__file-description-alternative"></p>
					</div>
				`,
				fileModel: attachment,
				constraints: 'message',

				onComplete: () => {
					tile.attachments.push(attachment.toJSON());
				},

				customize: (view) => {
					const attachmentsView = new Vue({
						el: $('<div />')[0],
						components: {
							'attachments-list': attachmentsComponent()
						},
						data: { attachments: tile.attachments },
						methods: {
							remove: (id) => {
								const attachmentToRemove = tile.attachmentFactory(tile, { id });
								const index = findIndex(tile.attachments, { id });
								tile.attachments.splice(index, 1);
								attachmentToRemove.destroy().then(() => {
									cwalert.success(t('Attachment removed'));
								});
							}
						},
						template: `
							<div class="message-attachments__container">
								<attachments-list
									:attachments="attachments"
									:remove="remove"
								/>
							</div>`
					});
					view.$el.prepend(attachmentsView.$el);
					view.$('.form-view__file-description-alternative')
						.html(t('Drag a file here or {spanStart}choose a file to attach{spanEnd}', {
							spanStart: '<span class="form-view__file-pseudolink">',
							spanEnd: '</span>'
						}));
				}
			}],
			buttons: [{
				caption: t('Send'),
				name: 'send',
				type: 'submit'
			}]
		});

		tile.$el.html(tile.form.$el);
	}
});
