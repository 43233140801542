import { cwtagsNl2br } from '../../helpers/string';
import layoutAdjustment from '../../rendering/layout-adjustment';
import $ from 'jquery';
import { assign } from 'lodash';
import Modernizr from 'modernizr';
import { QUESTION_TAG_NAME } from '../../config';
import { questionTextId } from '../shared/helpers';

export default function (runtime, assessment) {
	const $container = $(`
		<div
			class="assessment-container assessment-numeric-container"
			id="${runtime.getElementId()}"
		></div>`);
	const qTextId = questionTextId(runtime);

	const $questionContainer = $(
		`<${QUESTION_TAG_NAME} class="questiontext" />`
	).appendTo($container);

	if (!runtime.isVisible()) {
		$container.hide();
	}

	layoutAdjustment(runtime, $container[0]);

	if (runtime.getNumber()) {
		$(`
			<span class="question-numbering question-numbering-numericinput">
				${runtime.getNumber()}.
			</span>
		`).appendTo($questionContainer);
	}

	const $text = $(`
		<span id="${qTextId}" class="question">
			${cwtagsNl2br(runtime.getQuestionText())}
		</span>
	`).appendTo($questionContainer);

	const $answer = $('<div class="questionanswers" />').appendTo($container);
	const $input = $(`
			<input
				type="number"
				aria-labelledby="${qTextId}"
			/>`)
		.prop('disabled', !!runtime.isReadonly())
		.appendTo($answer);

	const setControlValue = (value) => {
		if (Modernizr.inputtypes.number) {
			$input.val(value);

		} else {
			$input.spinner('value', value);
		}
	};

	const initControl = () => {
		if (Modernizr.inputtypes.number) {
			$input.attr({
				min: runtime.getMinimalValue(),
				max: runtime.getMaximalValue(),
				step: runtime.getValueStep()
			});

		} else {

			$input.spinner({
				min: runtime.getMinimalValue(),
				max: runtime.getMaximalValue(),
				step: runtime.getValueStep(),
				disabled: runtime.isReadonly()
			});
		}

		setControlValue(isNaN(runtime.getValue()) ? '' : runtime.getValue());
	};

	const getControlValue = function () {
		return Modernizr.inputtypes.number ?
			$input.val().replace(',', '.') :
			$input.spinner('value');
	};

	initControl();

	const highlightIfAnswered = () => {

		if ($input.val()) {
			$container.addClass('highlightedRow');

		} else {
			$container.removeClass('highlightedRow');
		}
	};

	highlightIfAnswered();

	const clear = () => {
		setControlValue(null);
		runtime.clearValue();
	};

	const save = () => {
		try {
			const val = getControlValue();

			if (val === null) {
				clear();

			} else {
				runtime.setValue(val);
				setControlValue(runtime.getValue());
			}

		} catch (e) {
			setControlValue(runtime.getValue());
		}

		highlightIfAnswered();

		assessment.startSaving();
	};

	$input.on('change blur', save);

	$input.on('keydown', (e) => {
		// jquery numeric input fix for enter key changing url
		if ((e.keyCode || e.which || e.charCode) === 13) {
			e.preventDefault();
		}
	});

	assign(this, {
		clear: () => {
			setControlValue(null);
		},
		getElementBox: () => $container[0],
		updateText: () => {
			$text.html(cwtagsNl2br(runtime.getQuestionText()));
		}
	});
}

