var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { class: _vm.mainClassNames },
    [
      _vm.hasActiveChildren
        ? _c(
            "button",
            {
              class: _vm.toggleClassNames,
              on: {
                click: function($event) {
                  return _vm.toggle(_vm.item.path)
                }
              }
            },
            [
              _c("icon", {
                attrs: {
                  small: true,
                  "class-name": _vm.iconClassName,
                  type: "fontawesome",
                  name: _vm.item.expanded ? "minus-square" : "plus-square"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.item.root
        ? _c("input", {
            key: _vm.checkboxKey,
            staticClass: "tree-item__checkbox",
            attrs: { type: "checkbox" },
            domProps: {
              checked: _vm.checked,
              indeterminate: _vm.isIndeterminate && !_vm.checked
            },
            on: {
              click: function($event) {
                return _vm.toggleEdit(_vm.item, { source: "input" })
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("icon", {
        attrs: { name: _vm.iconName(), "class-name": _vm.iconClasses() }
      }),
      _vm._v(" "),
      _c(
        "button",
        { class: _vm.buttonClassNames, on: { click: _vm.onNameClick } },
        [
          _c("span", { class: _vm.elementClass }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.itemName) + "\n\t\t\t"),
            _vm.item.mirrorSuffix
              ? _c(
                  "span",
                  [
                    _vm._v(
                      "\n\t\t\t\t" + _vm._s(_vm.suffixName) + "\n\t\t\t\t("
                    ),
                    _c("icon", {
                      attrs: {
                        name: "mirror",
                        small: true,
                        "class-name": "tree-item__mirror-icon"
                      }
                    }),
                    _vm._v("\n\t\t\t\t" + _vm._s(_vm.mirrorName) + ")\n\t\t\t")
                  ],
                  1
                )
              : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      _vm.hasActiveChildren
        ? _c("tree-list", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.item.expanded,
                expression: "item.expanded"
              }
            ],
            attrs: {
              root: _vm.item.root,
              blueprints: _vm.blueprints,
              "checked-items": _vm.checkedItems,
              children: _vm.item.children,
              copy: _vm.copy,
              "copy-path": _vm.copyPath,
              disabled: _vm.disabled,
              dispatch: _vm.dispatch,
              getters: _vm.getters,
              icons: _vm.icons,
              "incomplete-paths": _vm.incompletePaths,
              indeterminate: _vm.indeterminate,
              mirror: !!_vm.item.mirror,
              "mirror-details": _vm.mirrorDetails,
              mirrors: _vm.mirrors,
              path: _vm.item.path,
              "parent-active": _vm.rowActive,
              "parent-expanded": _vm.item.expanded,
              "pass-button-refs": _vm.passButtonRefs,
              position: _vm.position,
              "root-mirrors": _vm.rootMirrors,
              selected: _vm.selected,
              "set-copy-path": _vm.setCopyPath,
              toggle: _vm.toggle,
              "toggle-edit": _vm.toggleEdit,
              "name-path": _vm.namePath,
              "warning-paths": _vm.warningPaths,
              "on-add": _vm.onAdd
            },
            on: { passButtonRefs: _vm.passButtonRefs }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }