import store from 'store';
import { TREATMENT } from 'store/treatments/rp-treatment';

export default async ({ cardParams }) => {
	if (
		store.getters['userType'] === 'respondent' &&
		cardParams.treatmentId
	) {
		await store.dispatch(TREATMENT.INIT, cardParams.treatmentId);
	}
};
