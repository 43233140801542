import repository from 'repository';
import { set } from '../__helpers/mutations';
import prefixify from '../__helpers/prefixify';

const prefix = prefixify('adapterInstanceCapability');

// getters
const DATA = 'data';

// actions
const FETCH = 'fetch';
const UPDATE = 'update';

export const ADAPTER_INSTANCE_CAPABILITY = {
	DATA: prefix(DATA),

	FETCH: prefix(FETCH),
	UPDATE: prefix(UPDATE)
};

export default {
	namespaced: true,

	state: {
		data: null
	},

	getters: {
		[DATA]: (state) => state.data
	},

	mutations: {
		set: set()
	},

	actions: {
		[FETCH]: ({ commit }, { capabilityId }) =>
			repository.fetchAdapterInstanceCapability({ capabilityId })
				.then((data) => {
					commit('set', data);
				}),

		[UPDATE]: (context, { capabilityId, data }) =>
			repository.updateAdapterInstanceCapability({ capabilityId, data })
	}
};
