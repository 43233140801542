'use strict';

import Backbone from 'backbone';
import Url from 'lib/url/url';

const AutospawnAssessment = Backbone.Model.extend({
	idAttribute: 'assessmentInstanceId',
	url: function () {
		var queryParams = {};
		if (this.get('identifier')) {
			queryParams.identifier = this.get('identifier');
		} else if (this.get('exercise')) {
			queryParams.exercise = this.get('exercise');
		}
		return new Url('backend-commons/assessmentinstance/rest/autospawn/', queryParams, {
			preprendBackendRootUrl: 'default'
		});
	}
});

export default AutospawnAssessment;
