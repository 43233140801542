import Vue from 'vue';
import spin from 'components/loaders/spin';
import t from 'translate';
import { directive as onClickOutside } from 'vue-on-click-outside';
import { isFunction, throttle, trim } from 'lodash';
import Hammer from 'hammerjs';
import { VTooltip } from 'v-tooltip';

export const loaderSpinner = {
	bind: (el, binding) => {
		let opt = {};

		if (binding.arg) {
			opt = { variant: binding.arg };
		}
		el.appendChild(spin(opt)[0]);
	}
};

Vue.directive('on-click-outside', onClickOutside);

Vue.directive('focus-when', {
	update: (el, params) => {
		!!params.value && el.focus();
	}
});

Vue.directive('blur-when', {
	update: (el, params) => {
		!!params.value && el.blur();
	}
});

Vue.directive('loader-spinner', loaderSpinner);

Vue.directive('translate', {
	inserted: (el) => {
		el.textContent = t(trim(el.textContent));
	}
});

Vue.directive('trim', {
	update: (el) => {
		if (!el.value) {
			return;
		}

		el.value = trim(el.value);
	}
});

Vue.directive('pinchout', {
	bind (el, binding) {
		if (isFunction(binding.value)) {
			const mc = new Hammer(el);
			mc.get('pinch').set({
				enable: true
			});
			mc.on('pinchout', throttle(binding.value, 300, { leading: false }));
		}
	}
});

Vue.directive('pinchin', {
	bind (el, binding) {
		if (isFunction(binding.value)) {
			const mc = new Hammer(el);
			mc.get('pinch').set({
				enable: true
			});
			mc.on('pinchin', throttle(binding.value, 300, { leading: false }));
		}
	}
});

Vue.directive('tooltip', VTooltip);
