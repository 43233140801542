import { error } from 'service/log/log';
import { isUndefined } from 'lodash';

const errorWhenEmpty = (prop, name) => {
	isUndefined(prop) && error(`'${name}' is not defined on the component/view`);
	return isUndefined(prop);
};

export default {
	methods: {
		getters (name) {
			errorWhenEmpty(this.storePath, 'storePath');
			return this.$store.getters[`${this.storePath}/${name}`];
		},
		dispatch (action, payload) {
			errorWhenEmpty(this.storePath, 'storePath');
			return this.$store.dispatch(`${this.storePath}/${action}`, payload);
		}
	}
};
