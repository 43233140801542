var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _vm.treatments.length
      ? _c(
          "ul",
          _vm._l(_vm.treatments, function(treatment) {
            return _c(
              "li",
              { key: treatment.id },
              [
                _c("progress-bar", {
                  attrs: {
                    "start-date": _vm.startDate(treatment),
                    "end-date": _vm.endDate(treatment),
                    title: _vm.title(treatment),
                    subtitle: _vm.subtitle(treatment),
                    "progress-value": _vm.progress(treatment),
                    href: _vm.cardLink(treatment)
                  }
                })
              ],
              1
            )
          }),
          0
        )
      : _c("p", [_vm._v("\n\t\t" + _vm._s(_vm.t("No treatments")) + "\n\t")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }