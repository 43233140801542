import urlify from 'service/urlify/urlify';
import { assign } from 'lodash';

export default {
	single: {
		url () {
			return `backend-commons/link/${this.getId() ? this.getId() + '?include=category' : ''}`;
		}
	},

	list: {
		url: 'backend-commons/link/?include=category'
	},

	singleCategory: {
		url: 'backend-commons/link-category/'
	},

	listAllCategories: {
		url: 'backend-commons/link-category/'
	},

	searchLinks: {
		url: (params) => `backend-commons/link/?include=category&${urlify(
			assign({ deleted: 0 }, params),
			{ omit: ['timestamp'] }
		)}`
	}
};
