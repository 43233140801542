import { forEach, isArray, isObject, isString, keys, noop } from 'lodash';
import FormComponentView from '../form-component-view';
import { compile } from 'handlebars';
import $ from 'jquery';

const radio = FormComponentView.extend({
	template: compile(`
		<fieldset>
			{{#if label}}
				<legend class="form-view__label-text">{{ label }}</legend>
			{{/if}}
			<div class="form-view__labels-group-container">
				{{#each values}}
					<div class="form-view__radio-row">
					<label class="form-view__label-container">
						<input
						type="radio"
						class="form-view__input {{ ../formClass }}__radio"
						name="{{ name }}"
						value="{{ fieldKey }}"
						{{#unless hideDefaultLabel}} aria-label="{{ name }}" {{/unless}}
						{{#if required}} required="required" {{/if}}
						{{#if checked}} checked="checked" {{/if}}
						{{#if disabled}} disabled="disabled" {{/if}}
						{{#if hint}} aria-describedby="{{ name }}-hint"{{/if}}/>
						<p class="form-view__label-text">{{{ fieldValue }}}</p>
					</label>
					</div>
				{{/each}}
			</div>
			{{#if hint}}
				<div class="form-view__hint" id="{{ name }}-hint">{{ hint }}</div>
			{{/if}}
		</fieldset>
	`),

	initialize () {
		const values = this.model.get('values');
		isArray(values) && this.normalizeValues();
	},

	normalizeValues () {
		const values = [];
		forEach(this.model.get('values'), (value) => {
			if (isString(value)) {
				values.push({
					name: this.model.get('name'),
					fieldKey: value,
					fieldValue: value,
					checked: value === this.model.get('value'),
					hideDefaultLabel: this.model.get('hideDefaultLabel')
				});

			} else if (isObject(value)) {
				const key = keys(value)[0];
				values.push({
					name: this.model.get('name'),
					fieldKey: key,
					fieldValue: value[key],
					checked: key === this.model.get('value'),
					hideDefaultLabel: this.model.get('hideDefaultLabel')
				});
			}
		});
		this.model.set('values', values);
	},

	valueChanged: noop,

	events: {
		'change input' (e) {
			const $input = $(e.target);
			this.trigger('value.change', $input.val());
			this.model.get('onChange') && this.model.get('onChange')();
		}
	}
});

export { radio };
