import { assign } from 'lodash';

// alphabetical order
import agenda from './agenda/methods';
import apps from './apps/methods';
import assessments from './assessments/methods';
import assessmentInstances from './assessment-instances/methods';
import cardConfig from './card-config/methods';
import circles from './circles/methods';
import consentInstances from './consents/rp-methods';
import cube from './cube/methods';
import currentRespondent from './respondents/current-respondent-methods';
import diaryEntries from './diary-entries/methods';
import goals from './goals/methods';
import languages from './languages/methods';
import links from './links/methods';
import media from './media/methods';
import pages from './pages/methods';
import respondentAssessmentInstances from './respondent-assessment-instances/methods';
import respondentContentPackages from './respondent-content-packages/methods';
import respondentMessages from './respondent-messages/methods';
import respondentReportInstances from './respondent-report-instances/methods';
import respondents from './respondents/methods';
import respondentTreatments from './respondent-treatments/methods';
import sidebar from './sidebar/methods';
import timezones from './timezones/methods';
import treatmentTypes from './treatment-types/methods';
import user from './user/methods';

export default assign(
	{},
	agenda,
	apps,
	assessments,
	assessmentInstances,
	cardConfig,
	circles,
	consentInstances,
	cube,
	currentRespondent,
	diaryEntries,
	goals,
	languages,
	links,
	media,
	pages,
	respondentAssessmentInstances,
	respondentContentPackages,
	respondentMessages,
	respondentReportInstances,
	respondents,
	respondentTreatments,
	sidebar,
	timezones,
	treatmentTypes,
	user
);
