<template>
	<button
		:class="classes"
		@click="action"
	>{{title}}</button>
</template>

<script>
import { noop } from 'lodash';
export default {
	props: {
		title: {
			type: String,
			required: true
		},

		action: {
			type: Function,
			default: noop
		},

		classes: {
			type: String,
			default: 'agenda-item__action'
		}
	}
};
</script>
