<template>
	<submit-button
		label="table.clear"
		:can-submit="canClear"
		:class-prefix="classPrefix"
		@click="$emit('click')"
	/>
</template>

<script>
export default {
	props: {
		canClear: {
			type: Boolean,
			default: true
		},
		classPrefix: {
			type: String,
			default: 'table-view'
		}
	}
};
</script>
