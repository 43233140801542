import MultipleEntities from '../shared/multiple-entities';
import Page from './entity';
import urls from './urls';
import $ from 'jquery';
import { assign } from 'lodash';

/**
 * Pages
 * @class Pages
 * @extends MultipleEntities
 */
const Pages = MultipleEntities.extend({
	model: Page,
	urls,
	eventNamespace: 'page',

	/**
	 * @method getList
	 * @return {Object} new {{#crossLink "Pages"}}{{/crossLink}} Collection
	 * @example
	 *        Repository.Pages.getList();
	 */
	getList: function () {
		return this.retrieve('list');
	},

	/**
	 * Get all pages, including (soft) deleted
	 * @method getAll
	 * @return {Object} new {{#crossLink "Pages"}}{{/crossLink}} Collection
	 * @example
	 *        Repository.Pages.getAll();
	 */
	getAll: function () {
		return this.retrieve('listAll');
	},

	/**
	 * Get specific page by permalink
	 * @method getByPermalink
	 * @param  {String} alias
	 * @return {Object} new {{#crossLink "Page"}}{{/crossLink}} Model
	 * @example
	 *        Repository.Pages.getByPermalink('clinician-home');
	 */
	getByPermalink: function (alias) {
		const page = assign(new this.model(), $.Deferred());
		page.url = urls.permalink.url(alias);
		page.fetch().then((response) => {
			if (page.has('id')) {
				page.set(response);
				page.resolve();
			} else {
				page.reject();
			}
		});

		return page;
	},

	/**
	 * Get random page from a given category
	 * @param category
	 * @return {Object} new {{#crossLink "Page"}}{{/crossLink}} Model
	 */
	getRandom: function (category) {
		return this.retrieve('random', category);
	},

	/**
	 * Search pages
	 * @param params
	 * @return {Object} new {{#crossLink "{Pages"}}{{/crossLink}} Collection
	 */
	search: function (params) {
		return this.retrieve('search', params);
	},

	listWithRoles: function (params) {
		return this.retrieve('listWithRoles', params);
	}
});

export default new Pages();
