<template>
	<h2 class="heading__title">
		{{title}}
	</h2>
</template>

<script>
export default {
	acl: [],
	instant: true,
	data: ({ tile }) => ({
		config: tile.config()
	}),
	computed: {
		title: ({ config, t }) => config.translate ? t(config.title) : config.title
	}
};
</script>
