import $ from 'jquery';
import title from '../attributes/title';

/**
 * Completely clear tile view and redraw the title.
 *
 * @function TileView.clear
 * @returns {Object} - Tile object.
 * @deprecated
 * @example
 * export default TileView.extend({
 *     loaded: ({ tile }) => {
 *         tile.clear();
 *     }
 * });
 */
export default function () {
	$(this.el).empty();

	if (this.title) {
		title.call(this, this.title);
	}
	return this;
}

