import appBar from 'app-bar';
import store from 'store';
import t from 'translate';
import { ACTIVITY } from 'store/goals/activity';
import { RESPONDENT } from 'store/respondents/respondent';
import { removeActivity, toggleActivityComplete } from '../shared/appbar-buttons';

const card = {
	name: 'edit-activity',
	url: 'treatment/:treatmentId/goal/:goalId/activity/:activityId',
	title: t('goals.editActivityTitle'),

	ctrl: ({ actions, close }, { activityId, goalId }) => actions([
		[ACTIVITY.INIT, {
			activityId,
			goalId,
			respondentId: store.getters[RESPONDENT.RESPONDENT_ID]
		}]
	]).then(() => {
		const mountButtons = () => {
			appBar.clear(card.name);

			appBar.addButton(toggleActivityComplete({ card, done: mountButtons }));
			appBar.addButton(removeActivity({ card, goalId, done: close }));
		};

		mountButtons();
	})
};

export { card as EditActivity };
