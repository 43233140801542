import cwalert from 'cwalert';
import initWith from '../__helpers/init-with';
import repository from 'repository';
import t from 'translate';

import { assign, get } from 'lodash';

export default {
	namespaced: true,

	state: {
		data: null,
		model: null
	},

	getters: {
		data: (state) => state.data,
		planInstanceId: (state, getters, rootState) =>
			get(rootState.cardData, 'urlParams.planInstanceId')
	},

	mutations: {
		set: (state, { planInstance, model }) => {
			state.data = planInstance;
			state.model = model;
		},

		reset: (state) => {
			state.data = null;
			state.model = null;
		},

		update: (state, payload) => {
			state.data = assign({}, state.data, payload);
			state.model.set(payload);
		}
	},

	actions: {
		init: (context, { planInstanceId } = { planInstance: 0 }) => {
			const { commit, getters } = context;
			const model = repository.planInstance(planInstanceId || getters.planInstanceId);

			return initWith(context, {
				actionName: 'planInstance/init',
				resource: model,
				action: () => {
					const planInstance = model.toJSON();
					commit('set', { model, planInstance });

					return {
						treatmentId: planInstance.treatment,
						respondentId: planInstance.respondent.id
					};
				}
			});
		},

		initPure: ({ commit, getters }, { planInstanceId } = { planInstance: 0 }) => {
			const model = repository.planInstance(planInstanceId || getters.planInstanceId);
			return model.then(() => {
				const planInstance = model.toJSON();
				commit('set', { model, planInstance });
			});
		},

		update: ({ commit }, payload) => {
			commit('update', payload);
		},

		save: ({ commit, state }, payload) => {
			state.model.save(payload.changes)
				.then(() => state.model.fetch())
				.then(() => {
					cwalert.success(t('assessment.properties.saveSuccessful'));
					const planInstance = state.model.toJSON();
					commit('set', { model: state.model, planInstance });
				});
		},

		destroy: ({ commit, state }) => state.model.destroy().then(() => {
			commit('reset');
		})
	}
};
