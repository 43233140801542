import repository from 'repository';
import { set } from '../__helpers/mutations';

export default {
	state: {
		roleMappingList: [],
		ruleSetId: 0
	},

	getters: {
		roleMappingList: (state) => state.roleMappingList
	},

	mutations: {
		setRoleMappingList: set('roleMappingList'),
		setRuleSetId: set('ruleSetId')
	},

	actions: {
		addRoleMapping: (context, data) => repository.addRoleMapping(data),

		addAndRefreshRoleMapping: ({ dispatch }, data) => dispatch('addRoleMapping', data)
			.then(() => dispatch('searchRoleMapping')),

		deleteRoleMapping: (context, id) => repository.deleteRoleMapping(id),

		deleteAndRefreshRoleMapping: ({ dispatch }, data) => dispatch('deleteRoleMapping', data)
			.then(() => dispatch('searchRoleMapping')),

		searchRoleMapping: ({ commit, state }, ruleSetId) => {
			if (ruleSetId) {
				commit('setRuleSetId', ruleSetId);
			}
			return repository.roleMapping(state.ruleSetId)
				.then((response) => commit('setRoleMappingList', response));
		},
		updateRoleMapping: (context, data) => repository.updateRoleMapping(data),

		updateAndRefreshRoleMapping: ({ dispatch }, data) => dispatch('updateRoleMapping', data)
			.then(() => dispatch('searchRoleMapping'))
	}
};
