export default function (definition) {
	if (typeof definition.dimension == 'object') {
		const href = definition.dimension;

		definition.dimension = href.code;
		definition.target = href.target;
	}

	/* debug */
	this.__debug = {
		raw: definition,
		dimension: definition.dimension
	};

	this.getType = function () {
		return 'Dimension';
	};

	this.getClass = function () {
		return 'Reference';
	};

	this.getInvolvedQuestionCodesMap = function (questionMap) {
		questionMap[definition.dimension] = 'dimension';

		return questionMap;
	};

	this.getBoolean = function () {
		return (typeof definition.dimension == 'string') && (definition.dimension.length > 0);
	};

	this.getNumber = function () {
		return NaN;
	};

	this.getString = function () {
		return (typeof definition.dimension != 'string') ?
			'Undefined dimension reference' :
			definition.dimension;
	};

	this.toString = function () {
		return `{Dimension:${this.getString()}}`;
	};

	this.getRawValue = function () {
		return definition.dimension;
	};

	this.getDimensionCode = function () {
		return definition.dimension;
	};

}
