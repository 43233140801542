import repository from 'repository';
import { reject, sortBy, toLower } from 'lodash';
import prefixify from '../__helpers/prefixify';
import { STATUS_UNPUBLISHED } from 'modules/content-package/constants';
const prefix = prefixify('contentPackages');

const DATA = 'data';
const NOT_UNPUBLISHED = 'notUnpublished';

const SET = 'set';

const INIT_AVAILABLE = 'initAvailable';

export const CONTENT_PACKAGES = {
	DATA: prefix(DATA),
	NOT_UNPUBLISHED: prefix(NOT_UNPUBLISHED),

	INIT_AVAILABLE: prefix(INIT_AVAILABLE)
};

export default {
	namespaced: true,

	state: {
		[DATA]: [],
		collection: {}
	},

	mutations: {
		[SET]: (state, { contentPackages, collection }) => {
			state[DATA] = contentPackages;
			state.collection = collection;
		}
	},

	getters: {
		[NOT_UNPUBLISHED]: (state) => {
			const unsorted = reject(state[DATA], {
				status: STATUS_UNPUBLISHED
			});
			return sortBy(unsorted, (contentPackage) => toLower(contentPackage.name));
		}
	},

	actions: {
		[INIT_AVAILABLE]: ({ commit }) => {
			const collection = repository.availableContentPackages();

			return collection.then(() => {
				commit(SET, { contentPackages: collection.toJSON(), collection });
			});
		}
	}
};
