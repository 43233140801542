import { assign, isDate, isNaN, isObject, some } from 'lodash';
import FormComponentView from '../form-component-view';
import datetimeService from 'service/datetime/datetime';
import moment from 'moment';
import datetimeComponent from 'components/datetime/index';
import { compile } from 'handlebars';
import t from 'translate';

const DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm';
const DATETIME_LOCAL = 'datetime-local';

const DatetimeInput = FormComponentView.extend({
	template: compile(`
        <label class="form-view__label-container">
            <p class="form-view__label-text">{{ label }}</p>
            <input
                type="datetime-local"
                class="form-view__input form-view__datetime"
                name="{{ uniqueName }}"
                value="{{ value }}"
                {{#if required}} required="required" {{/if}}
                {{#if readonly}} readonly="readonly" {{/if}}
                {{#if disabled}} disabled="disabled" {{/if}}
                {{#if hint}} aria-describedby="{{ name }}-hint"{{/if}}
                {{#if min }} min="{{ min }}" {{/if}}
                {{#if max }} max="{{ max }}" {{/if}}
                {{#if step }} step="{{ step }}" {{/if}} />
        </label>
        {{#if hint}}
            <div class="form-view__hint" id="{{ name }}-hint">{{ hint }}</div>
        {{/if}}
    `),
	events: {
		'blur @ui.input': 'inputChanged'
	},
	onAfterRender () {
		this.attachFallback();
		this.listenTo(this.model, 'change:value', this.serializeData);
		this.ui.input.on('change', (e) => {
			// if this is original form change event we skip it
			if (!!e.originalEvent && !!e.originalEvent.type && e.originalEvent.type === 'change') {
				e.preventDefault();
				return false;
			}
		});
	},
	attachFallback () {
		const prevent = this.model.get('readonly') || this.model.get('disabled');

		if (Modernizr.inputtypes[DATETIME_LOCAL] || prevent) {
			return;
		}

		const timezone = this.model.get('timezone');
		const dummy = { value: this.model.get('value') };
		const vm = datetimeComponent({
			type: 'datetime',
			attrs: dummy,
			timezone: this.model.get('timezone'),
			dateAttr: 'value',
			set: (date) => {
				// eslint-disable-next-line no-useless-escape
				const tzSuffix = /:00.000\+\d\d\:\d\d/;
				let dateString = date.replace(tzSuffix, '');

				if (timezone) {
					dateString = datetimeService(date.replace(tzSuffix, ''))
						.toUtcFromTimezone(timezone)
						.toMoment()
						.format(DATE_FORMAT);
				}
				this.model.set('value', dateString);
				this.ui.input.trigger('change');
			},
			min: this.model.get('min'),
			max: this.model.get('max'),
			format: t('date.formats.dateTimeLuxon')
		});

		this.ui.input.hide().after(vm.$el);
	},

	inputChanged (...args) {
		if (!Modernizr.inputtypes[DATETIME_LOCAL]) {
			return;
		}
		const date = !!this.ui.input.val() && this.parseDate(this.ui.input.val());
		const isValid = date && !isNaN(date.getTime());

		if (!this.model.get('readonly') && isValid) {
			FormComponentView.prototype.inputChanged.apply(this, args);
			// pats: it's here because we have to trigger somehow form submit/save methods
			this.ui.input.trigger('change');

		} else {
			this.validate();
			args[0].preventDefault();
			return;
		}
	},
	serializeData () {
		const timezone = this.model.get('timezone');
		const date = this.model.get('value') && this.parseDate(this.model.get('value'));

		// date should be in ISO-8601 , acceptable by datetime-local
		if (date) {
			let dateString = moment(date).format(DATE_FORMAT);

			if (timezone && Modernizr.inputtypes[DATETIME_LOCAL]) {
				dateString = datetimeService(date).setTimezone(timezone)
					.toMoment().format(DATE_FORMAT);
			}

			this.model.attributes.value = dateString;
			this.ui.input.val && this.ui.input.val(dateString);
		}

		return assign({
			min: moment('1900-01-01').format(DATE_FORMAT),
			max: moment('2100-01-01').format(DATE_FORMAT)
		}, this.model.toJSON());
	},

	hydrateValue (date) {
		const timezone = this.model.get('timezone');
		let dateString = moment(date).format(DATE_FORMAT);

		if (timezone && Modernizr.inputtypes[DATETIME_LOCAL]) {
			dateString = datetimeService(date).toUtcFromTimezone(timezone)
				.toMoment().format(DATE_FORMAT);
		}
		return dateString;
	},

	unsanitize (value) {
		if (value) {
			let date;
			some(this.dateParses, (processFn) => {
				date = processFn(value);
				return isDate(date) && !isNaN(date.getTime());
			});
			return date && (moment(date).format(DATE_FORMAT) || value);
		}
		return value;
	},

	dateParses: [
		(value) => value,
		(value) => isObject(value) ? datetimeService(value).toDate() : false,
		(value) => moment(value).toDate()
	],

	parseDate (value) {
		let date;

		some(this.dateParses, (processFn) => {
			date = processFn(value);
			return isDate(date) && !isNaN(date.getTime());
		});

		return date;
	}
});

export { DatetimeInput as datetime };
