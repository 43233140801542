<template>
	<th>
		<slot />
	</th>
</template>

<script>
export default {
	props: {

	}
};
</script>
