var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "generic-list__item information-package-element" },
    [
      _c(
        "div",
        {
          class: _vm.itemClassName,
          attrs: { tabindex: "0" },
          on: {
            click: _vm.onClick,
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.onClick($event)
            }
          }
        },
        [
          !_vm.item.isMedia
            ? _c("icon", {
                staticClass: "information-package-element__type-icon",
                attrs: { name: _vm.typeIcon, title: _vm.typeIcon }
              })
            : _c("icon", {
                staticClass: "information-package-element__type-icon",
                attrs: { name: _vm.item.fileType, title: _vm.item.typeLabel }
              }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "\n\t\t\t\tgeneric-list__action-title-container\n\t\t\t\tinformation-package-element__action-title-container\n\t\t\t"
            },
            [
              _vm.item.isMedia
                ? _c(
                    "span",
                    { staticClass: "information-package-element__type-label" },
                    [
                      _vm._v(
                        "\n\t\t\t\t" + _vm._s(_vm.item.typeLabel) + "\n\t\t\t"
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "generic-list__action-title information-package-element__action-title"
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.item.elementVersion.alias) +
                      "\n\t\t\t"
                  )
                ]
              ),
              _vm._v(" "),
              _vm.item.state === _vm.STATE_COMPLETED && _vm.item.isExercise
                ? _c(
                    "span",
                    {
                      staticClass: "information-package-element__run-once-more"
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.$t("You can do it more than once")) +
                          "\n\t\t\t"
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.item.isMedia
                ? _c("icon", {
                    staticClass: "information-package-element__open-external",
                    attrs: {
                      name: "external-link",
                      title: _vm.$t("Open in new window")
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "information-package-element__state-label" },
                [
                  _vm.iconsOnly
                    ? _c("icon", {
                        attrs: { name: _vm.stateIcon, title: _vm.stateIcon }
                      })
                    : [
                        _vm._v(
                          "\n\t\t\t\t\t" + _vm._s(_vm.stateLabel) + "\n\t\t\t\t"
                        )
                      ],
                  _vm._v(" "),
                  _vm.item.isExercise
                    ? [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s("(" + _vm.item.exercises.length + ")") +
                            "\n\t\t\t\t"
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }