import { assign, forEach, noop } from 'lodash';
import { View } from 'marionette';
import { compile } from 'handlebars';

export default View.extend({
	template: compile(`
		<div class="tile__wrapper">
			<div class="progress-bar progress-bar--tile" role="presentation">
				<div class="progress-bar__indicator"></div>
			</div>
			{{#if title}}<h2 class="tile__title">{{ title }}</h2>{{/if}}
			<div class="tile__content"></div>
			<div class="tile__modal"></div>
		</div>
	`),
	className: 'tile__container tile__column-or-container',
	tagName: 'section',
	regions: {
		tileContent: '.tile__content'
	},
	ui: {
		title: '.tile__title'
	},
	enabled: true,

	initialize () {
		this.tileView = this.model.get('tileView');
		this.containerConfig = assign({
			width: 4,
			height: 'auto'
		}, this.model.get('containerConfig'));
	},

	onBeforeRender () {
		if (this.model.get('disabled')) {
			this.disableTileViewRender();
		}
	},

	disableTileViewRender () {
		this.tileView._render = this.tileView.render;
		this.tileView._onRender = this.tileView.onRender;
		this.enabled = false;
		this.tileView.render = noop;
		this.tileView.onRender = noop;
	},

	enableTileViewRender () {
		this.enabled = true;

		if (this.tileView._render) {
			this.tileView.render = this.tileView._render;
		}

		if (this.tileView._onRender) {
			this.tileView.onRender = this.tileView._onRender;
		}
	},

	onRender () {
		this
			.setClasses()
			.parseConfig()
			.setTileContent();
	},

	parseConfig () {
		forEach(this.containerConfig, (value, key) => {
			if (this.containerConfigHandlers.hasOwnProperty(key)) {
				this.containerConfigHandlers[key].call(this, value);
			}
		});
		return this;
	},

	setClasses () {
		this.$el.addClass(this.model.get('tileName'));
		this.$el.toggleClass('disabled', !this.enabled);
		return this;
	},

	containerConfigHandlers: {
		width (width) {
			width && this.$el.addClass(`width-${width}`);
		},
		height (height) {
			height && this.$el.addClass(`height-${height}`);
		},
		boxless (boxless) {
			boxless && this.$el.addClass('boxless');
		},
		micro (micro) {
			micro && this.$el.addClass('micro');
		},
		minWidth (minWidth) {
			minWidth && this.$el.addClass(`min-width-${minWidth}`);
		},
		maxWidth (maxWidth) {
			maxWidth && this.$el.addClass(`max-width-${maxWidth}`);
		},
		disabled (disabled) {
			disabled && this.$el.addClass('disabled');
		},
		collapse (direction) {
			direction && this.$el.addClass(`collapse-${direction}`);
		},
		overflow (overflow) {
			overflow && this.$el.addClass(`overflow-${overflow}`);
		}
	},

	setTileContent () {
		this.getRegion('tileContent').show(this.model.get('tileView'));
		this.model.get('tileView').trigger('show');
		return this;
	},

	onDestroy () {
		this.tileView.trigger('remove').remove();
	},

	modelEvents: {
		'change:loading' (model, loading) {
			this.$el.toggleClass('tile__container--loading', loading);
		},
		'change:data-loading' (model, loading) {
			this.$el.toggleClass('tile__container--data-loading', loading);
		},
		'change:disabled' (model, disabled) {
			this.$el.toggleClass('disabled', disabled);
			this[`${{
				true: 'disable',
				false: 'enable'
			}[disabled]}TileViewRender`]();
		},
		'change:title' () {
			this.ui.title.html(this.model.get('title'));
		}
	}
});

