import { assign, debounce, get, isArray, pick } from 'lodash';

/**
 * Function that returns runQuery function which:
 * - sets search results on viewModel based on passed parameters
 * - fires relative cardContext events.
 *
 * @param {Object} opts        - Options.
 * @param {Object} opts.viewModel   - Backbone Model for keeping search data.
 * @param {Object} opts.cardContext - Context of a current card.
 * @param {Function} opts.searchFn  - Callback function for retrieving the data.
 * @param {number} opts.wait        - Time (ms) which has to pass since the last search is invoked.
 * @param {Array} opts.params       - Additional params passed to search query.
 * @example
 * 		tile.runQuery = createRunQuery({
			cardContext: tile.cardContext(),
			viewModel: tile.viewModel,
			params: tile.keys,
			searchFn: tile.searchFn.bind(tile)
		});
 */
export default (
	{ viewModel, cardContext, searchFn, wait = 100, params = [] }
) => debounce((query) => {
	const searchParameters = assign(pick(viewModel.toJSON(), ...params), {
		search: query || viewModel.get('search'),
		start: viewModel.get('start'),
		limit: viewModel.get('limit')
	});

	const resultsCollection = searchFn(searchParameters);

	cardContext.trigger('search.start');

	resultsCollection.then(function (...args) {
		const params = args.length === 1 && isArray(args[0]) ? args[0] : args;
		const xhrObj = params[2];

		cardContext.trigger('search.end');
		viewModel.setResults({
			resultsCollection,
			url: get(this, 'url'),
			xhrObj
		});
	});

	// url should reflect search change -- let card do this
	cardContext.set('search', viewModel.get('search') || '');

}, wait);
