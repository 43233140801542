import { GET, PUT } from 'service/ajax/ajax';
import urls from './urls';

export default {
	/**
	 * Get all available Treatments.
	 *
	 * @function treatments
	 * @param {object} params - Optional params.
	 * @returns {object} Promise.
	 * @example
	 * Repository.treatments();
	 */
	treatments: (params) => GET({
		url: urls.list(params)
	}),

	/**
	 * Get Treatment.
	 *
	 * @function getTreatment
	 * @param {number|string} treatmentId - Treatment ID.
	 * @returns {object} {{#crossLink "Treatment"}}{{/crossLink}}.
	 * @example
	 *        Repository.getTreatment(8);
	 */
	getTreatment (treatmentId) {
		return this.Treatments.getById(treatmentId);
	},

	/**
	 * Get new collection of Treatments which match search params.
	 *
	 * @function searchTreatments
	 * @param {object} params - Search params.
	 * @returns {object} New Treatments Collection.
	 * @example
	 *        Repository.searchTreatments({
	 * 			query: 'def',
	 * 			limit: 10,
	 * 			start: 0
	 * 		});
	 */
	searchTreatments (params) {
		return this.Treatments.search(params);
	},

	/**
	 *
	 * @param {number} treatmentId - Treatment ID.
	 * @returns {object} - Promise.
	 * @example
	 * 		repository.treatmentAttributeValues(1234593636);
	 */
	treatmentAttributeValues: (treatmentId) => GET({
		url: urls.attributes(treatmentId)
	}),

	/**
	 *
	 * @param {object} attr - Attribute.
	 * @returns {{object}} - Promise.
	 * @example
	 * 		repository.saveSingleTreatmentAttribute({ id: 5, value: 'elo' });
	 */
	saveSingleTreatmentAttribute: (attr) => PUT({
		url: urls.attribute({
			treatmentId: attr.treatment,
			attributeId: attr.id,
			treatmentTypeAttributeId: attr.typeAttribute
		}),
		data: attr
	}),

	defaultTreatmentAttributeValues: (treatmentTypeId) => GET({
		url: urls.defaultAttributeValues(treatmentTypeId)
	})
};
