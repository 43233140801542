import { assign, get } from 'lodash';
import i18next from 'i18next';
import store from 'store';

export default function () {
	const code = get(store.state.user, 'userLanguageCode', 'en');

	return store.dispatch('engine/i18n/init').then(
		([bluePrintResponse, customResponse, formatResponse, chartResponse]) => {
			const resources = {};
			const lang = assign(
				bluePrintResponse,
				customResponse,
				formatResponse,
				chartResponse
			);
			resources[code] = { translation: lang };

			return i18next.init({
				resStore: resources,
				lng: code,
				shortcutFunction: 'defaultValue',
				getAsync: false
			});
		}
	);
}
