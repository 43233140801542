import config from 'core/config';
import { get, startsWith } from 'lodash';

export default (url = '') => {
	if (!url) {
		return '';
	}

	const baseUrl = config().baseUrl;

	/**
	 * Matches the last part of url that is surrounded by slashes.
	 *
	 * @param {string} url - Input URL.
	 * @returns {string}   - Last directory name.
	 * @example
	 * 	lastDir('https://tng.checkware.external/v2/');
	 * 	-> '/v2'
	 */
	const lastDir = (url) => get(url.match(/.*(\/.+?\/)$/), '[1]', '');

	const baseUrlEnd = lastDir(baseUrl);

	if (/^http/.test(url)) {
		return url;
	}

	if (startsWith(url, baseUrlEnd)) {
		return baseUrl + url.replace(baseUrlEnd, '');
	}

	if (baseUrl[baseUrl.length - 1] === '/' && url[0] === '/') {
		return baseUrl + url.replace('/', '');
	}

	return baseUrl + url;
};
