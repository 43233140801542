var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "h3",
    { staticClass: "treatment-grid-header" },
    [
      _vm.icon ? _c("icon", { attrs: { name: _vm.icon } }) : _vm._e(),
      _vm._v(" "),
      _c("span", [_vm._t("default")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }