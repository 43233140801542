var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("agenda-view", {
        attrs: {
          name: _vm.name,
          items: _vm.items,
          addable: _vm.addable,
          types: _vm.types,
          "tile-path": _vm.tilePath,
          settings: _vm.settings,
          refresh: _vm.refresh,
          loading: _vm.loading,
          disabled: !_vm.enabled,
          timezone: _vm.timezone,
          statuses: ["completed", "open", "overdue"]
        },
        scopedSlots: _vm._u([
          {
            key: "disabled",
            fn: function() {
              return [
                _c("p", { staticClass: "incomplete-treatment" }, [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.$t("treatment.labels.unavailable")) +
                      "\n\t\t\t"
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("goal-dialog", {
        attrs: {
          show: _vm.addingGoal,
          goal: _vm.newGoal,
          "on-close": _vm.closeGoalDialog,
          loading: _vm.addGoalLoading,
          "on-submit": _vm.addGoal,
          categories: _vm.categories,
          treatments: _vm.treatments,
          title: "goals.editGoal.addTitle"
        }
      }),
      _vm._v(" "),
      _c("activity-dialog", {
        attrs: {
          show: _vm.addingActivity,
          activity: _vm.newActivity,
          "on-close": _vm.closeActivityDialog,
          loading: _vm.addActivityLoading,
          "on-submit": _vm.addActivity,
          goals: _vm.goals
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }