var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "modal" } },
    [
      _c("trap", { attrs: { disabled: !_vm.show } }, [
        _vm.show
          ? _c(
              "div",
              {
                staticClass: "modal-dialog",
                class: _vm.className,
                attrs: { role: "dialog", "aria-modal": "true" },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape"
                      ])
                    ) {
                      return null
                    }
                    return _vm.handleClose($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "modal-dialog__shadow" }),
                _vm._v(" "),
                _c("div", { staticClass: "modal-dialog__top-bar" }, [
                  _c(
                    "button",
                    {
                      staticClass: "modal-dialog__dismiss",
                      attrs: { tabindex: "-1", title: _vm.t("Close") },
                      on: { click: _vm.handleClose }
                    },
                    [_c("icon", { attrs: { name: "times" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "h2",
                    { staticClass: "modal-dialog__title" },
                    [_vm._t("title")],
                    2
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-dialog__window" }, [
                  _vm.loading
                    ? _c("div", {
                        directives: [
                          {
                            name: "loader-spinner",
                            rawName: "v-loader-spinner"
                          }
                        ],
                        staticClass: "modal-dialog__loader"
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "modal-dialog__content" },
                    [_vm._t("content")],
                    2
                  )
                ])
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }