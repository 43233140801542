import card from './card';
import cards from './cards';
import { clinicianSidebar, respondentSidebar } from './sidebar';
import i18n from './i18n';

export default {
	namespaced: true,
	modules: {
		card,
		cards,
		i18n,
		clinicianSidebar,
		respondentSidebar
	}
};
