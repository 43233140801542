import { messageAttachment } from './urls';
import MessageAttachment from '../messages/message-attachment';

/**
 * RespondentMessageAttachment entity
 *
 * @class RespondentMessageAttachment
 * @extends MessageAttachment
 */
const RespondentMessageAttachment = MessageAttachment.extend({
	urlRoot: messageAttachment.url,
	eventNamespace: 'respondent-message-attachment'
});

export default RespondentMessageAttachment;
