import Vue from 'vue';

Vue.component('custom-tag', {
	props: {
		name: {
			type: String,
			required: true
		}
	},

	render (createElement) {
		return createElement(this.name, this.$slots.default);
	}
});
