import { find, findIndex, get, reject } from 'lodash';
import repository from 'repository';
import prefixify from '../__helpers/prefixify';
import { set } from '../__helpers/mutations';
import { exact } from '../__helpers/getters';
import {
	PROGRAM,
	MODULE,
	SECTION
} from 'modules/treatment-programs/constants';

const prefix = prefixify('treatmentProgram');

const DATA = 'data';
const MODULES = 'modules';
const ACTIVE_ITEM = 'activeItem';
const ACTIVE_ITEM_DATA = 'activeItemData';
const SET = 'set';
const SET_MODULES = 'setModules';
const SET_SECTIONS = 'setSections';

const INIT = 'init';
const UPDATE = 'update';
const SET_ACTIVE_ITEM = 'setActiveItem';
const UPDATE_MODULES = 'updateModules';
const ADD_MODULE = 'addModule';
const UPDATE_MODULE = 'updateModule';
const DELETE_MODULE = 'deleteModule';
const UPDATE_SECTIONS = 'updateSections';
const ADD_SECTION = 'addSection';
const DELETE_SECTION = 'deleteSection';

export const TREATMENT_PROGRAM = {
	DATA: prefix(DATA),
	ACTIVE_ITEM: prefix(ACTIVE_ITEM),
	ACTIVE_ITEM_DATA: prefix(ACTIVE_ITEM_DATA),

	INIT: prefix(INIT),
	UPDATE: prefix(UPDATE),
	SET_ACTIVE_ITEM: prefix(SET_ACTIVE_ITEM),
	UPDATE_MODULES: prefix(UPDATE_MODULES),
	ADD_MODULE: prefix(ADD_MODULE),
	UPDATE_MODULE: prefix(UPDATE_MODULE),
	DELETE_MODULE: prefix(DELETE_MODULE),
	UPDATE_SECTIONS: prefix(UPDATE_SECTIONS),
	ADD_SECTION: prefix(ADD_SECTION),
	DELETE_SECTION: prefix(DELETE_SECTION)
};

export default {
	namespaced: true,

	state: {
		[DATA]: null,
		[ACTIVE_ITEM]: {
			type: null,
			id: null
		}
	},

	getters: {
		...exact([DATA, ACTIVE_ITEM]),

		[MODULES]: (state, getters) => get(getters[DATA], 'modules', []),

		[ACTIVE_ITEM_DATA]: (state, getters) => {
			const programData = getters[DATA];
			const modules = get(programData, 'modules', []);
			const { type, id } = getters[ACTIVE_ITEM];

			return ({
				[PROGRAM]: () => programData,
				[MODULE]: () => find(modules, ['id', id]),
				[SECTION]: () => {
					const foundModule = find(
						modules,
						(module) => find(get(module, 'sections', []), ['id', id])
					);

					return find(get(foundModule, 'sections', []), ['id', id]);
				}
			})[type]();
		}
	},

	mutations: {
		[SET]: set(),
		[SET_ACTIVE_ITEM]: set(ACTIVE_ITEM),
		[SET_MODULES]: (state, modules) => {
			state.data.modules = modules;
		},
		[SET_SECTIONS]: (state, { moduleId, sections }) => {
			const moduleIndex = findIndex(
				get(state.data, 'modules', []),
				['id', moduleId]
			);

			state.data.modules[moduleIndex].sections = sections;
		}
	},

	actions: {
		[INIT]: async ({ commit, rootGetters }, {
			treatmentProgramId = rootGetters['urlParams'].treatmentProgramId
		} = {}) => {
			const program = await repository.treatmentProgram(treatmentProgramId);

			commit(SET, program);
		},

		[UPDATE]: async ({ commit }, treatmentProgram) => {
			const program = await repository.updateTreatmentProgram(
				treatmentProgram.id,
				treatmentProgram
			);

			commit(SET, program);
		},

		[SET_ACTIVE_ITEM]: ({ commit }, item) => commit(SET_ACTIVE_ITEM, item),

		[UPDATE_MODULES]: async ({ commit, getters, rootGetters }, {
			treatmentProgramId = rootGetters['urlParams'].treatmentProgramId,
			modules
		} = {}) => {
			const program = await repository.updateTreatmentProgram(
				treatmentProgramId,
				{
					...getters[DATA],
					modules
				}
			);

			commit(SET_MODULES, program.modules);
		},

		[ADD_MODULE]: async ({ getters, rootGetters, dispatch }, {
			treatmentProgramId = rootGetters['urlParams'].treatmentProgramId,
			module
		} = {}) => {
			await dispatch(UPDATE_MODULES, {
				treatmentProgramId,
				modules: [
					...getters[MODULES],
					module
				]
			});
		},

		[UPDATE_MODULE]: async ({ dispatch, getters, rootGetters }, {
			treatmentProgramId = rootGetters['urlParams'].treatmentProgramId,
			moduleId,
			module
		}) => {
			const modules = getters[MODULES];
			const moduleIndex = findIndex(modules, ['id', moduleId]);

			modules[moduleIndex] = module;

			await dispatch(UPDATE_MODULES, {
				treatmentProgramId,
				modules
			});
		},

		[DELETE_MODULE]: async ({ dispatch, getters, rootGetters }, {
			treatmentProgramId = rootGetters['urlParams'].treatmentProgramId,
			moduleId
		}) => {
			await dispatch(UPDATE_MODULES, {
				treatmentProgramId,
				modules: reject(getters[MODULES], ['id', moduleId])
			});
		},

		[UPDATE_SECTIONS]: async ({ commit, getters, rootGetters }, {
			treatmentProgramId = rootGetters['urlParams'].treatmentProgramId,
			moduleId,
			sections
		}) => {
			const module = find(getters[MODULES], ['id', moduleId]);

			const moduleResult = await repository.updateTreatmentProgramModule(
				{
					treatmentProgramId,
					moduleId,
					module: {
						...module,
						sections
					}
				}
			);

			commit(SET_SECTIONS, {
				moduleId,
				sections: moduleResult.sections
			});
		},

		[ADD_SECTION]: async ({ dispatch, getters, rootGetters }, {
			treatmentProgramId = rootGetters['urlParams'].treatmentProgramId,
			moduleId,
			section
		}) => {
			const sections = get(find(getters[MODULES], { id: moduleId }), 'sections', []);

			await dispatch(UPDATE_SECTIONS, {
				treatmentProgramId,
				moduleId,
				sections: [
					...sections,
					section
				]
			});
		},

		[DELETE_SECTION]: async ({ dispatch, getters, rootGetters }, {
			treatmentProgramId = rootGetters['urlParams'].treatmentProgramId,
			moduleId,
			sectionId
		}) => {
			const sections = get(find(getters[MODULES], { id: moduleId }), 'sections', []);

			await dispatch(UPDATE_SECTIONS, {
				treatmentProgramId,
				moduleId,
				sections: reject(sections, ['id', sectionId])
			});
		}
	}

};
