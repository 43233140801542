import { View } from 'marionette';
import { compile } from 'handlebars';

export default View.extend({
	template: compile(`
		<button class="appbar-button role-summary__back" title="{{t "Previous page"}}">
			{{{icon "arrow-left"}}}
		</button>
		
		<button class="role-summary__back flat-button__button">
			{{t "Previous page"}}
		</button>
		
		<section class="role-summary__content">
			<h1 class="assessment-title">{{assessmentName}}</h1>
		
			<p class="role-summary__description">
				{{t "This assessment is part of a treatment where you have a specified role"}}
			</p>
		
			{{#if mainRespondentDisplayName}}
				<p class="role-summary__label">{{t "Related respondent"}}</p>
				<p class="role-summary__value">{{mainRespondentDisplayName}}</p>
			{{/if}}
		
			<p class="role-summary__label">{{t "Respondent role in treatment"}}</p>
			<p class="role-summary__value">{{mainRespondentRoleName}}</p>
		
			<p class="role-summary__label">{{t "Treatment"}}</p>
			<p class="role-summary__value">{{treatmentName}}</p>
		
			<p class="role-summary__label">{{t "Your role in treatment"}}</p>
			<p class="role-summary__value">{{roleName}}</p>
		
			<div class="flat-button__buttons-container">
				<button class="
					role-summary__continue
					flat-button__button
					flat-button__button--submit
				">
					{{t "Continue"}}
				</button>
			</div>
		</section>
	`),
	ui: {
		appbarButton: '.role-summary__back.appbar-button',
		bottomButton: '.role-summary__back.flat-button__button',
		content: '.role-summary__content',
		continue: '.role-summary__continue'
	},

	className: 'role-summary__container',

	events: {
		'click @ui.continue': 'hideContent'
	},

	onRender () {
		this.hide();

		this.ui.appbarButton.on('click', this.showContent.bind(this));
		this.ui.bottomButton.on('click', this.showContent.bind(this));
	},

	$elements () {
		return this.$el.add(this.ui.appbarButton).add(this.ui.bottomButton);
	},

	show () {
		!this.visible && this.$elements().show();
		this.visible = true;
		this.trigger('show');
		return this;
	},

	hide () {
		this.visible !== false && this.$elements().hide();
		this.visible = false;
		this.trigger('hide');
		return this;
	},

	showContent ({ once } = {}) {
		this.once = once;

		if (this.visible) {
			this.ui.appbarButton.hide();
			this.ui.bottomButton.hide();
			this.ui.content.show();
			this.trigger('showContent');
		}
		return this;
	},

	hideContent () {
		if (this.visible) {
			!this.once && this.ui.appbarButton.show();
			!this.once && this.ui.bottomButton.show();
			this.ui.content.hide();
			this.once = false;
			this.trigger('hideContent');
		}
		return this;
	}
});
