/* global define */
import $ from 'jquery';

var ButtonHelper = function (node) {
	this.disable = function () {
		$(node).addClass('disabled');
	};

	this.enable = function () {
		$(node).removeClass('disabled');
	};
};

export default ButtonHelper;
