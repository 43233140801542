export const BAR = 'bar';
export const PLOT = 'plot';
export const PIE = 'pie';
export const DONUT = 'donut';
export const HORIZONTAL_BAR = 'horizontalBar';
export const STACKED_BAR = 'stackedBar';

export const BAR_METHOD = 'Bar';
export const PIE_METHOD = 'Pie';
export const LINE_METHOD = 'Line';

