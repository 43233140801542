import repository from 'repository';
import Attribute from 'repo/treatments/attribute-entity';
import { clone, map, filter } from 'lodash';
import { when } from 'jquery';

export default {
	namespaced: true,

	state: {
		data: [],
		collection: {},
		attributes: {
			data: []
		}
	},

	getters: {
		sortedByName: (state) => {
			const types = clone(state.data);
			types.length && types.sort((a, b) => a.name.localeCompare(b.name));

			return types;
		},

		attributes: (state) => state.attributes.data
	},

	mutations: {
		set: (state, { treatmentTypes, collection }) => {
			state.data = treatmentTypes;
			state.collection = collection;
		},

		setAttributes: (state, { attributes }) => {
			state.attributes.data = attributes;
		},

		resetAttributes: (state) => {
			state.attributes.data = [];
		}
	},

	actions: {
		initForCurrentClinician: ({ commit }) => {
			const collection = repository.treatmentTypesForCurrentClinician();

			return collection.then(() => {
				commit('set', { treatmentTypes: collection.toJSON(), collection });
			});
		},

		initAll: ({ commit }) => {
			const collection = repository.treatmentTypes();
			return collection.then(() => {
				commit('set', { treatmentTypes: collection.toJSON(), collection });
			});
		},

		initAttributes: ({ commit }, { treatmentTypeId }) => {
			const collection = repository.treatmentTypeAttributes(treatmentTypeId);

			return collection.then(() => {
				commit('setAttributes', { attributes: collection.toJSON() });
			});

		},

		saveAttributes: ({ state }, { treatmentId, values }) => {
			const editableAttributes = filter(state.attributes.data, ['isReadonly', false]);

			return when(...map(editableAttributes, (attribute) => {
				const attributeModel = new Attribute({
					treatment: treatmentId,
					typeAttribute: attribute.id,
					value: values[attribute.id]
				});
				return attributeModel.save();
			}));
		},

		resetAttributes: ({ commit }) => {
			commit('resetAttributes');
		}
	}
};
