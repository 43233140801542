import { assign, get, pick } from 'lodash';
import clinicianConfig from 'config/clinician-config';
import respondentConfig from 'config/respondent-config';
import planeditorConfig from 'config/planeditor-config';
import assessmentConfig from 'config/assessment-config';
import atomicConfig from 'config/atomic-config';

let type = 'clinician';

export default (newType) => {
	if (newType) {
		type = newType;
	}

	const appConfig = {
		assessment: assessmentConfig,
		atomic: atomicConfig,
		clinician: clinicianConfig,
		planeditor: planeditorConfig,
		respondent: respondentConfig
	}[type];

	return assign({
		appVersion: get(window, 'DEVCONF.appVersion', ''),
		webSocketsPath: get(window, 'DEVCONF.webSocketsPath', '/socket.io'),
		appDir: get(window, 'DEVCONF.appDir', '../')
	}, pick(
		appConfig.config(),
		'appUrl', 'authUrl', 'baseUrl', 'cardsDefinitionRoot', 'defaultCardAfterLogin',
		'backendRootUrl'
	));
};
