<script>
import jsonQuery from 'json-query';
import { parse } from '../helpers/parse';
import { EMPTY_CHAR } from 'lib/chars';

export default {
	props: {
		column: {
			type: Object,
			required: true
		},
		item: {
			type: Object,
			required: true
		},

		tileConfig: {
			type: Object,
			required: true
		}
	},

	computed: {
		isEmpty: ({ column, content }) => column.parse ? !parse({ column, content }) : !content,
		emptyChar: () => EMPTY_CHAR,
		content: ({ column, item }) => jsonQuery(column.key, { data: item }).value
	}
};
</script>
