import t from 'translate';
import store from 'store';
import { TREATMENT } from 'store/treatments/rp-treatment';
import cardUrl from 'cwcardurl';

const card = {
	title: t('Home'),
	name: 'home-respondent',
	url: [
		'home',
		'treatment/:treatmentId/home'
	],

	ctrl: (context, { treatmentId }) => {
		if (treatmentId) {
			return store.dispatch(TREATMENT.INIT, treatmentId);
		}

		return cardUrl.openCard('home-respondent', {
			treatmentId: store.getters[TREATMENT.ID]
		});
	}
};

export default card;
