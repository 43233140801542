import cardConfig from 'core/engine/card/config/card-config';
import { assign, defer, forEach, get, isFunction, isUndefined, pick, size } from 'lodash';
import { Collection, Model } from 'backbone';
import extensions from 'core/engine/tile/extensions/extensions';
import TileContainerCollectionView from 'core/engine/tile/views/TileContainerCollectionView';
import { error } from 'service/log/log';
import getTiles from 'core/engine/tile/get-tiles';
import store from 'store';
import TileView from './views/tile-view';
import appContext from 'app-context';
import { TREATMENT } from 'store/treatments/rp-treatment';

const provideTiles = ({ card = {}, data = {}, variantIndex = 0 }) => {
	let cardTiles = [];
	const alternativeCards = card.alternativeCards;

	const variantData = { ...data };

	if (store.getters.userType === 'respondent') {
		const treatmentTypeId = get(store.getters[TREATMENT.DATA], 'treatmentType.id');
		assign(variantData, { treatmentTypeId });
	}

	if (size(variantData)) {
		cardTiles = getTiles({
			defaultTiles: card.tiles,
			alternativeCards,
			variantData
		});

	} else {
		cardTiles = variantIndex > -1 ?
			get(alternativeCards, `[${variantIndex}]`, {}).tiles :
			card.tiles;
	}

	return cardTiles;
};

const TileManager = function () {
	const tiles = {};
	const tileViews = {};

	const tileWrapper = ({ tilesList, tileName }) => {
		const tile = tilesList[tileName];

		if (tile.extend) {
			return tile;
		}
		const componentName = /tiles\/(.*)\/index.vue/.exec(tile.__file)[1];

		return TileView.extend({
			...pick(tile, ['acl', 'actions', 'instant', 'title', 'features']),
			vueComponent: () => ({ [componentName]: tile })
		});
	};

	const initTileView = ({ params, controllerContext, tileName, tileModel }) => {
		const Tile = tileWrapper(
			{ tilesList: tiles, tileName }
		).extend(extensions.functions).extend({ __params: params });
		const tileView = new Tile(params);
		tileView.__params.controllerContext = controllerContext;

		forEach(extensions.validators, (fn) => {
			fn.call(tileView, tileName);
		});

		// load extension attributes
		forEach(extensions.attributes, (fn, name) => {
			!isUndefined(tileView[name]) && fn.call(tileView, tileView[name], tileView);
		});

		if (!tileModel.get('preventInit') && isFunction(tileView.init)) {
			tileView.init({ tile: tileView });

		} else if (tileModel.get('preventInit')) {
			tileModel.set('disabled', true);
		}

		return tileView;

	};

	this.registerTile = function (tileName, TileClass) {
		if (tiles[tileName]) {
			error(`Tile ${tileName} is already registered.`);
			return;
		}

		tiles[tileName] = TileClass;

		if (TileClass.extend) {
			tileViews[tileName] = new TileClass({ skipInit: true });
			extensions.validators.template.call(tileViews[tileName], tileName);
		}
	};

	this.getTileView = (name) => tileViews[name] || tiles[name];

	this.registerTiles = function (tilesList) {
		forEach(tilesList, (tileClass, tileName) => {
			this.registerTile(tileName, tileClass);
		});
	};

	this.populateCard = (
		cardView,
		{ controllerContext, data = {}, variantIndex = store.getters.variantIndex }
	) => {
		appContext.trigger('card.populate');
		const tileContainers = new Collection();
		const cardTiles = provideTiles({
			card: cardConfig(cardView.model.get('cardName')),
			data,
			variantIndex
		});

		forEach(cardTiles, (tile, tileIndex) => {
			defer((tileModel) => {
				const tileName = tileModel.get('tileName');

				if (!tiles[tileName]) {
					error(`Tile ${tileName} not registered.`);
					return;
				}

				const params = {
					cardView,
					cardModel: cardView.model,
					tileConfig: tileModel.get('tileConfig'),
					tileContainerModel: tileModel,
					path: `card-${cardView.model.get('cardName')}|tile-${tileIndex}`
				};

				const tileView = initTileView({ params, controllerContext, tileName, tileModel });

				tileModel.set('tileView', tileView);
				tileContainers.add(tileModel);

			}, new Model(tile));
		});

		cardView.renderTiles(new TileContainerCollectionView({ collection: tileContainers }));
	};
};

export default new TileManager();

