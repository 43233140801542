import repository from 'repository';
import { assign } from 'lodash';

export default {
	namespaced: true,

	actions: {
		// Asessment instance
		lockAssessment: (self, { id }) => new Promise((success) => {
			repository.lockAssessmentInstance({ id, success });
		}),

		unlockAssessment: (self, { id }) => repository.unlockAssessmentInstance(id),

		eraseAssessmentResponses: (
			self, { id }
		) => repository.eraseAssessmentInstanceResponses(id),

		deleteAssessment: (self, { id }) => repository.deleteAssessmentInstance(id),

		// Content package
		activateContentPackage: (self, data) => repository.activateContentPackageInstance(data),
		deactivateContentPackage: (self, data) => repository.deactivateContentPackageInstance(data),
		completeContentPackage: (self, { id }) => repository.completeContentPackageInstance(id),
		deleteContentPackage: (self, { id }) => repository.deleteContentPackageInstance(id),

		// Decision
		decideNo: ({ dispatch }, { id }) => dispatch(
			'decision/initPure',
			id,
			{ root: true }
		).then(() => dispatch('decision/update', { value: 'no' }, { root: true })),

		decideYes: ({ dispatch }, { id }) => dispatch(
			'decision/initPure',
			id,
			{ root: true }
		).then(() => dispatch('decision/update', { value: 'yes' }, { root: true })),

		// Message
		answerMessage: ({ rootGetters }, { id }) => repository.toggleMessageAnswer({
			respondentId: rootGetters['respondent/respondentId'],
			treatmentId: rootGetters['treatment/treatmentId'],
			messageId: id,
			answered: true
		}),

		unanswerMessage: ({ rootGetters }, { id }) => repository.toggleMessageAnswer({
			respondentId: rootGetters['respondent/respondentId'],
			treatmentId: rootGetters['treatment/treatmentId'],
			messageId: id,
			answered: false
		}),

		// Plan
		pausePlan: (self, { id }) => repository.pausePlanInstance(id),
		resumePlan: (self, { id }) => repository.resumePlanInstance(id),
		terminatePlan: (self, { id }) => repository.terminatePlanInstance(id),
		deletePlan: (self, { id }) => repository.deletePlanInstance(id),

		// Goal
		completeGoal: (self, goal) => repository.updateGoal(
			assign({}, goal, { isCompleted: true })
		),
		openGoal: (self, goal) => repository.updateGoal(assign({}, goal, { isCompleted: false }))
	}
};
