import repository from 'repository';
import { set } from '../__helpers/mutations';
import { updateValues } from 'modules/export-template/helpers/update';
import {
	assign, clone, find, forEach, merge, cloneDeepWith, isObject, isEmpty, transform
} from 'lodash';
import t from 'translate';
import { EXPORT_TEMPLATE } from './export-template';

const setWarning = ({ element, assessments, treatmentTypes }) => {
	const assessmentWarning = element.fields.assessment &&
		!find(assessments, { assessmentId: element.fields.assessment });
	const treatmentTypeWarning = element.fields.treatmentType &&
		!find(treatmentTypes, { id: element.fields.treatmentType });

	if (assessmentWarning || treatmentTypeWarning) {
		element.unavailable = true;
	}
	return element.unavailable;
};

const checkAvailability = ({ children, assessments, treatmentTypes }) =>
	transform(children, (result, element) => {
		if (element.type === 'folder' && element.children.length) {
			element.children = checkAvailability({
				children: element.children,
				assessments,
				treatmentTypes
			});
		} else {
			element.unavailable = setWarning({ element, assessments, treatmentTypes });
		}
		result.push(element);
	}, []);

export default {
	namespaced: true,

	state: {
		consentOptions: [],
		data: {},
		locked: false,
		lockInterval: null,
		selected: [],
		templateData: null,
		templateId: 0,
		userId: null
	},

	getters: {
		data: (state) => state.data,
		fields: (state) => state.fields,
		locked: (state) => {
			const occupied = state.data.occupiedById;
			// occupied may be null or an integer - if not null needs to be compared with userId
			return occupied && +occupied !== +state.userId;
		},
		templateConsents: (state) => state.consentOptions,
		templateData: (state) => state.templateData
	},

	mutations: {
		set: set(),

		clearInterval: (state) => {
			if (state.lockInterval) {
				clearInterval(state.lockInterval);
			}
		},

		saveItem: (state, { item, fields }) => {
			assign(item.fields, fields);

			if (!item.children) {
				assign(item.fields, { customName: fields.customName });
			} else {
				item.children = updateValues({
					element: [],
					fields,
					origin: item.children,
					parentCode: item.fields.codeTemplate
				});
			}
			// when a single input field is updated getter is not being triggered
			item.fields = clone(item.fields);
			item.unavailable = false;
		},

		setConsentOptions: (state, payload) => {
			state.consentOptions = payload;
		},

		setInterval: (state, intervalHandle) => {
			state.lockInterval = intervalHandle;
		},

		setTemplateData: (state, data) => {
			state.templateData = data;
		},

		setTemplateId: (state, templateId) => {
			state.templateId = templateId;
		},

		setUserId: (state, userId) => {
			state.userId = userId;
		}
	},

	actions: {
		init: ({ commit, getters, state, rootGetters }, { templateId, userId }) => {
			const setData = (treeData) => {
				treeData.children = checkAvailability({
					children: treeData.children,
					assessments: rootGetters['exportTemplate/sortedAssessments'],
					treatmentTypes: rootGetters[EXPORT_TEMPLATE.TREATMENTS]
				});
				commit('set', treeData);
				commit('setTemplateId', templateId);
				commit('setUserId', userId);

				if (!getters.locked) {
					window.localStorage.setItem('exportTemplate',  JSON.stringify({
						templateId: state.templateId,
						userId: +state.userId,
						content: state.data
					}));
				}
			};

			return repository.exportTemplate(templateId).then(({ content }) => {
				if (isEmpty(content)) {
					setData({
						type: 'exportTemplate',
						root: true,
						path: 'root',
						fields: {
							name: t('Data export template'),
							codeTemplate: '#ItemID#_#Assessment#_#Context#'
						},
						children: []
					});

					return;
				}

				setData(JSON.parse(content));
			});
		},

		lockTemplate: ({ commit }, templateId) => {
			const intervalHandle = setInterval(() => {
				repository.lockExportTemplate(templateId);
			}, 360000);
			commit('setInterval', intervalHandle);
			return repository.lockExportTemplate(templateId);
		},

		saveItem: ({ commit }, payload) => {
			commit('saveItem', payload);
		},

		unlockTemplate: ({ commit }, templateId) => {
			commit('clearInterval');
			window.localStorage.removeItem('exportTemplate');
			return repository.unlockExportTemplate(templateId);
		},

		saveTemplate: ({ state }, exportTemplate) => {
			const cleanItem = (value) => {
				if (isObject(value)) {
					// @TODO consider these to stay on each item as 1 obj for easy removing
					// eg. itemState: { checked: true, expanded: false, ... }
					// by keeping that as 1 obj, it'll be just -> delete value.itemState
					// NOTICE: cloneDeepWith customizer only works by mutating value directly
					forEach(['checked', 'highlighted', 'indeterminate', 'expanded'], (prop) => {
						delete value[prop];
					});
				}
			};

			return repository.saveExportTemplate({
				templateId: state.templateId,
				data: {
					content: JSON.stringify(cloneDeepWith(merge({}, exportTemplate), cleanItem))
				}
			});
		},

		initSingle: ({ commit }, templateId) => repository.singleExportTemplate(templateId)
			.then((result) => {
				commit('setTemplateData', result);
			}),

		getConsents: ({ commit }, templateId) => repository.exportTemplateConsents(templateId)
			.then((response) => {
				commit('setConsentOptions', response);
			}),

		updateSingle: (context, { data, templateId }) =>
			repository.updateExportTemplateData({ data, templateId }),

		deleteQuestion: (context, id) => repository.deleteExportTemplateConsentOption(id),

		saveConsent: ({ dispatch }, { id, optionId }) =>
			repository.addExportTemplateConsentOption({ id, optionId }).then(() => {
				dispatch('getConsents', id);
			})
	}
};
