export default {
	language: {
		url: 'backend-commons/administrator/rest/language/'
	},
	allLanguages: {
		url: 'backend-commons/administrator/rest/language/query?all=1'
	},
	enabledLanguages: {
		url: 'backend-commons/administrator/rest/language/query?all=0'
	}
};
