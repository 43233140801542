import Vue from 'vue';
import StatusButton from './status-button';

const statusButton = (circle) => new Vue({
	el: document.createElement('div'),
	components: { StatusButton },
	data: { circle },
	template: `<status-button :circle="circle" />`
});

export { statusButton };
