import repository from 'repository';

export default {
	namespaced: true,

	state: {
		current: [],
		collection: {}
	},

	mutations: {
		set: (state, { collection }) => {
			state.current = collection.toJSON();
			state.collection = collection;
		}
	},

	actions: {
		init: ({ commit }) => {
			const collection = repository.circleCategories();
			return collection.then(() => {
				commit('set', { collection });
			});
		}
	}
}
