import repository from 'repository';
import { set } from '../__helpers/mutations';
import { map } from 'lodash';

export default {
	namespaced: true,
	state: {
		data: []
	},

	getters: {
		names: (state) => map(state.data, 'administratorRoleAccessName')
	},

	mutations: {
		set: set('data')
	},

	actions: {
		init: ({ commit }) => {
			const checkpoints = repository.getCheckpoints();

			return checkpoints.then(() => {
				commit('set', checkpoints.toJSON());
			});
		}
	}
};
