var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ol",
    { class: _vm.mainClassName },
    _vm._l(_vm.nonEmptyGroups, function(group) {
      return _c(
        "li",
        { key: group.index, class: _vm.elementClassName("group") },
        [
          _c("agenda-group-title", {
            attrs: {
              scope: _vm.scope,
              datetime: group.datetime,
              "store-path": _vm.storePath
            }
          }),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: _vm.transitionName } },
            [
              _c("agenda-group", {
                attrs: {
                  addable: _vm.addable,
                  items: group.items,
                  datetime: group.datetime,
                  scope: _vm.scope,
                  collapsed: _vm.collapsed,
                  direction: _vm.direction,
                  "store-path": _vm.storePath
                }
              })
            ],
            1
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }