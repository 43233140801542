<template>
	<ol :class="mainClassName">
		<li
			v-for="group in nonEmptyGroups"
			:key="group.index"
			:class="elementClassName('group')"
		>
			<agenda-group-title
				:scope="scope"
				:datetime="group.datetime"
				:store-path="storePath"
			/>
			<transition :name="transitionName">
				<agenda-group
					:addable="addable"
					:items="group.items"
					:datetime="group.datetime"
					:scope="scope"
					:collapsed="collapsed"
					:direction="direction"
					:store-path="storePath"
				/>
			</transition>
		</li>
	</ol>
</template>

<script>
import Vue from 'vue';
import { requiredArray, requiredString } from './props';
import agendaMixin from './agenda-mixin';
import './agenda-group';
import { filter } from 'lodash';
import { AGENDA_GROUPS } from './constants';

export default Vue.component(AGENDA_GROUPS, {
	mixins: [agendaMixin],
	props: {
		groups: requiredArray,
		addable: requiredArray,
		compressed: {
			type: [Boolean, String],
			default: false
		},
		collapsed: {
			type: [Boolean, String],
			default: false
		},
		direction: requiredString,
		scope: requiredString,
		storePath: requiredString
	},

	computed: {
		mainClassName: ({ $options }) => [$options.name],
		transitionName: ({ direction }) => `fade-${direction}`,
		nonEmptyGroups: ({ compressed, groups }) => filter(
			groups,
			(group) => !compressed || group.items.length
		)
	}
});
</script>
