import {
	Assessments, AssessmentFlags, AssessmentQuestions, AssessmentClinicianGroups,
	RemoteScoringAssessments, RemoteScoringProperties, Contexts, AssessmentTypes,
	AssessmentCalculations
} from './definitions';
import urls from './urls';
import { GET } from 'service/ajax/ajax';

/**
 * @class Repository
 */
export default {
	/**
	 * Get all Assessments.
	 *
	 * @function getAssessments
	 * @returns {Object} Assessments Collection.
	 * @example
	 *        Repository.getAssessments();
	 */
	getAssessments: () => Assessments.getAll(),

	/**
	 * Get available Assessments for a single template.
	 *
	 * @function getAvailableAssessments
	 * @param {number|string} templateId - Id of a required template.
	 * @returns {Object} Assessments Collection.
	 * @example
	 *        Repository.getAvailableAssessments();
	 */
	getAvailableAssessments: (templateId) => GET({
		url: urls.availableAssessments(templateId),
		cachedKey: 'assessment'
	}),

	/**
	 * Get all Assessments that are enabled.
	 *
	 * @function getEnabledAssessments
	 * @param {Object} params - Parameters.
	 * @returns {Object} Assessments Collection.
	 * @example
	 *        Repository.getEnabledAssessments();
	 */
	getEnabledAssessments: (params) => Assessments.getEnabled(params),

	/**
	 * Get Assessments that are able to be autospawned.
	 *
	 * @function getAutospawnAssessments
	 * @returns {Object} Assessments Collection.
	 * @example
	 *        Repository.getAutospawnAssessments();
	 */
	getAutospawnAssessments: () => Assessments.getAutospawn(),

	/**
	 * Get Assessment by id.
	 *
	 * @function getAssessmentById
	 * @param {number|string} assessmentId - Assessment ID.
	 * @returns {Object} Assessment Model.
	 * @example
	 *        Repository.getAssessmentById(7);
	 */
	getAssessmentById: (assessmentId) => Assessments.getById(assessmentId),

	/**
	 * Get all AssessmentFlags.
	 *
	 * @function getAllAssessmentFlags
	 * @returns {Object} - Promise.
	 * @example
	 *        repository.allAssessmentFlags();
	 */
	allAssessmentFlags: () => GET({ url: urls.flags }),

	/**
	 * Get AssessmentQuestions of a given Assessment.
	 *
	 * @function getAssessmentQuestions
	 * @param {number|string} assessmentId - Assessment ID.
	 * @returns {Object} AssessmentQuestions Collection.
	 * @example
	 *        Repository.getAssessmentQuestions(1);
	 */
	getAssessmentQuestions: (assessmentId) => AssessmentQuestions.getByAssessment(assessmentId),

	/**
	 * Search for Assessments.
	 *
	 * @function searchAssessments
	 * @param {string} query - Search query.
	 * @returns {Object} Assessments Collection.
	 * @example
	 *        Repository.searchAssessments('CW');
	 */
	searchAssessments: (query) => Assessments.search(query),

	/**
	 * Get all AssessmentClinicianGroups for the given Assessment.
	 *
	 * @function getAssessmentClinicianGroups
	 * @param {number|string} assessmentId - Assessment ID.
	 * @returns {Object} AssessmentClinicianGroups Collection.
	 * @example
	 *        Repository.getAssessmentClinicianGroups(2);
	 */
	getAssessmentClinicianGroups: (assessmentId) =>
		AssessmentClinicianGroups.getForAssessment(assessmentId),

	/**
	 * Get all RemoteScoringAssessments.
	 *
	 * @function getRemoteScoringAssessments
	 * @returns {Object} RemoteScoringAssessments Collection.
	 * @example
	 *        Repository.getRemoteScoringAssessments();
	 */
	getRemoteScoringAssessments: () => RemoteScoringAssessments.getAll(),

	/**
	 * Get all RemoteScoringProperties.
	 *
	 * @function getRemoteScoringProperties
	 * @param {number|string} assessmentId - Assessment ID.
	 * @returns {Object} RemoteScoringProperties Collection.
	 * @example
	 *        Repository.getRemoteScoringProperties();
	 */
	getRemoteScoringProperties: (assessmentId) =>
		RemoteScoringProperties.getForAssessment(assessmentId),

	/**
	 * Get all Contexts.
	 *
	 * @function getContexts
	 * @returns {Object} Contexts Collection.
	 * @example
	 *        Repository.getContexts();
	 */
	getContexts: () => Contexts.getAll(),

	/**
	 * Get all AssessmentTypes.
	 *
	 * @function getAssessmentTypes
	 * @returns {Object} AssessmentTypes Collection.
	 * @example
	 *        Repository.getAssessmentTypes();
	 */
	getAssessmentTypes: () => AssessmentTypes.getAll(),

	/**
	 * Get calculations of an assessment.
	 *
	 * @function assessmentFlags
	 * @param {number|string} assessmentId - Assessment ID.
	 * @returns {Object} AssessmentFlags collection.
	 * @example
	 * 		repository.AssessmentFlags(5);
	 */
	assessmentFlags: (assessmentId) => AssessmentFlags.getByAssessment(assessmentId),

	/**
	 * Get questions of an assessment.
	 *
	 * @function assessmentQuestions
	 * @param {number|string} assessmentId - Assessment ID.
	 * @returns {Object} AssessmentQuestions collection.
	 * @example
	 * 		repository.AssessmentQuestions(5);
	 */
	assessmentQuestions: (assessmentId) => AssessmentQuestions.getByAssessment(assessmentId),

	/**
	 * Get calculations of an assessment.
	 *
	 * @function assessmentCalculations
	 * @param {number|string} assessmentId - Assessment ID.
	 * @returns {Object} AssessmentCalculations collection.
	 * @example
	 * 		repository.AssessmentCalculations(5);
	 */
	assessmentCalculations: (assessmentId) => AssessmentCalculations.getByAssessment(assessmentId)
};
