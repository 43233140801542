import $ from 'jquery';
import { debounce, forEach, isFunction, size } from 'lodash';
import { cwtagsNl2br } from '../../../helpers/string';
import { warn } from 'service/log/log';

const MATRIX_COLUMN_QUESTION_WIDTH = '30'; // %
const TABLE_HEIGHT = 350; // magic number

const init = ({ firstQuestion, $header, view }) => {
	$('<th class="thheader matrix__header-cell" />')
		.attr('width', `${MATRIX_COLUMN_QUESTION_WIDTH}%`)
		.appendTo($header);

	const answers = firstQuestion.getAnswers();
	const count = size(answers);
	const matrixColumnWidth = (100 - MATRIX_COLUMN_QUESTION_WIDTH) / count;
	const longAnswers = [];

	const markLongAnswers = (i, bool) => {
		const lastIteration = (i === count - 1);
		bool && longAnswers.push(i);

		if (lastIteration && longAnswers.length) {
			const isLongAnswer = function (answer) {
				const isFirst = (answer === 0);
				const isMiddle = (longAnswers.length % 2 === 1) &&
					(answer === Math.floor(count / 2));
				const isLast = (answer === count - 1);

				return !(isFirst || isMiddle || isLast);
			};

			/* Determine if matrix structure requires adding 'long answers' class.
			 * In cases where most of answers are numbers and only first, middle or last
			 * answer is long, there's no need for adding the class.
			 */
			const itIsReallyNeeded = () => {
				let addClassFlag = false;

				forEach(longAnswers, (answer) => {
					if (isLongAnswer(answer)) {
						addClassFlag = true;
					}
				});
				return addClassFlag;
			};

			itIsReallyNeeded() && view.$table.addClass('matrix__table--long-answers');
		}
	};

	const setHeight = function ($cell, $text) {
		if ($text.height() > $cell.height()) {
			$cell.height(1.5 * $text.height());
		}
	};

	let i = 0;
	forEach(answers, (answer) => {
		const $th = $(`<th
			class="${answer.getTemplateId()} questionthheader  matrix__answer-cell" />
		`).attr('width', `${matrixColumnWidth}%`).appendTo($header);

		const $answer = $('<span class="matrix__answer-text" />').appendTo($th);

		if (!answer.isVisible()) {
			$th.hide();
		}

		if (answer.getType() === 'TextOption') {
			$answer.html(cwtagsNl2br(answer.getLabel()));

		} else {
			warn(`not implemented option type: ${answer.getType()}`);
		}

		const determineLongAnswers = () => {
			setTimeout(() => {
				setHeight($th, $answer);
				const criteria = () => {
					const threshold = 10;
					return $answer.width() - $th.width() > threshold;
				};

				if (i >= size(answers)) {
					i = 0;
				}
				i++;
				markLongAnswers(i, criteria());
			}, 200);
		};

		determineLongAnswers();

		$(window).on('resize', debounce(determineLongAnswers, 100));
	});
};

// "Sticky" table headers, applied when table is taller than arbitrary `TABLE_HEIGHT` px
const stickyHeader = (view) => {
	setTimeout(() => {
		// Cell heights are also calculated with a delay, make sure the sticky css is added
		// after everything
		view.$table && view.$table.height() > TABLE_HEIGHT && view.$table.floatThead({
			floatContainerClass: 'sticky-css',
			top: $('.app-appbar__container').height()
		});
	}, 500);

	let lastPos = null;

	$(window).on('resize', debounce(() => {
		if (lastPos !== window.scrollY) {
			lastPos = window.scrollY;
			setTimeout(() => {
				if (view.$table) {
					const reinit = view.$table.floatThead('destroy');
					isFunction(reinit) && reinit();
				}
			}, 100);
		}
	}, 100));
};

export default ({ view, $container, runtime, getClass }) => {
	view.$table = $('<table class="cwmatrix matrix__table" />').appendTo($container);
	const $thead = $('<thead class="matrix__thead" />').appendTo(view.$table);
	const $header = $('<tr class="matrix__header-row" />').appendTo($thead);
	view.$tBody = $('<tbody class="matrix__tbody" />').appendTo(view.$table);

	let firstQuestion;

	for (
		let i = 0;
		firstQuestion = runtime.getChild(i); // eslint-disable-line no-cond-assign
		i++
	) {
		if (getClass(firstQuestion) === 'Question') {
			break;
		}
	}

	firstQuestion && init({ firstQuestion, $header, view });
	stickyHeader(view);
};
