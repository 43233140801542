import systemSettings from 'system-settings';
import { mapSettings } from './helpers';

const allowances = [
	{ card: 'respondent-my-profile', flag: 'ENABLE_RESPONDENT_PERSONAL_SETTINGS_PAGE' },
	{ card: 'information-packages',  flag: 'ENABLE_RESPONDENT_INFORMATION_PACKAGES' },
	{ card: 'diary',                 flag: 'RESPONDENT_DIARY' },
	{ card: 'messages',              flag: 'ENABLE_MESSAGING_SYSTEM' },
	{ card: 'reports-list',          flag: 'ENABLE_RESPONDENT_REPORTS_PAGE' },
	{ card: 'goals',                 flag: 'ENABLE_RESPONDENT_GOALS' },
	{ card: 'exercises',             flag: 'ENABLE_RESPONDENT_EXERCISES' },
	{ card: 'media',                 flag: 'ENABLE_RESPONDENT_MEDIA' },
	{ card: 'pages',                 flag: 'ENABLE_RESPONDENT_PAGES' },
	{ card: 'links',                 flag: 'ENABLE_RESPONDENT_LINKS' },
	{ card: 'video-chat',            flag: 'RESPONDENT_VIDEO_CHAT' }
];

export default [
	...mapSettings(allowances),
	{
		card: 'circles-startpage',
		condition: () => systemSettings.getBoolean('ENABLE_RESPONDENT_CIRCLES') &&
			systemSettings.getBoolean('ENABLE_CIRCLES')
	}
];
