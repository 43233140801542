import repository from 'repository';
import { map, sortBy } from 'lodash';
import { cleanAssessment } from '../__helpers/clean';

export default {
	namespaced: true,

	state: {
		data: [],
		collection: {}
	},

	getters: {
		enabled: (state, getters) => map(getters.sorted, cleanAssessment),
		sorted: (state) => sortBy(
			state.data,
			(assessment) => assessment.assessmentName.toLowerCase()
		)
	},

	mutations: {
		set: (state, { assessments, collection }) => {
			state.data = assessments;
			state.collection = collection;
		}
	},

	actions: {
		getEnabled: ({ commit }, params = {}) => {
			const collection = repository.getEnabledAssessments(params);

			return collection.then(() => {
				commit('set', { assessments: collection.toJSON(), collection });
			});
		}
	}
};
