import { cwtagsNl2br } from '../../helpers/string';
import layoutAdjustment from '../layout-adjustment';
import $ from 'jquery';
import { noop, throttle } from 'lodash';
import t from 'translate';
import { QUESTION_TAG_NAME } from '../../config';
import { questionTextId } from '../../components/shared/helpers';

export default function (runtime, assessment) {

	const box = document.createElement('div');
	box.id = runtime.getElementId();
	box.className = 'assessment-container assessment-date-container';

	if (!runtime.isVisible()) {
		$(box).hide();
	}

	layoutAdjustment(runtime, box);

	const questionBox = document.createElement(QUESTION_TAG_NAME);
	questionBox.className = 'questiontext';
	box.appendChild(questionBox);

	if (runtime.getNumber()) {
		const questionNumber = document.createElement('span');
		questionNumber.className = 'question-numbering question-numbering-dateinput';
		questionNumber.innerHTML = `${runtime.getNumber()}.`;
		questionBox.appendChild(questionNumber);
	}

	const qTextId = questionTextId(runtime);
	const questionText = document.createElement('span');
	questionText.id = qTextId;
	questionText.className = 'question';
	questionText.innerHTML = cwtagsNl2br(runtime.getQuestionText());
	questionBox.appendChild(questionText);

	this.updateText = function () {
		questionText.innerHTML = cwtagsNl2br(runtime.getQuestionText());
	};

	const answersBox = document.createElement('form');
	answersBox.className = 'questionanswers';
	box.appendChild(answersBox);

	const input = document.createElement('input');
	answersBox.appendChild(input);

	if (runtime.isReadonly()) {
		input.setAttribute('disabled', 'disabled');
	}

	input.setAttribute('aria-labelledby', qTextId);

	const refreshDateText = function (updatePickerDate) {
		return throttle(updatePickerDate, 700, { leading: false });
	};

	let prev; // eslint-disable-line no-unused-vars
	let _datepickeractive = false;
	let updatePickerDate = noop;
	let clearDate = noop;
	let updateDate = noop;

	if (runtime.isDate() && runtime.isTime()) {
		/* date & time picker */
		$(input).cwdatetimepicker({
			dateFormat: t('date.formats.quizDateFormat'), // dd.mm.yy
			timeFormat: t('date.formats.quizTimeFormat'), // HH:mm
			minDate: runtime.getMinimalDate(),
			maxDate: runtime.getMaximalDate(),
			yearRange: runtime.getYearRange(),
			changeYear: true,
			changeMonth: true,
			onChangeMonthYear (year, month, inst) {
				/** TODO: investigate why isn't this done automatically?
				 * At this point, the internal $(input).datepicker('getDate')
				 * represents a different date than the one set in $(input).val();
				 **/
				inst.currentYear = inst.selectedYear;
				inst.currentMonth = inst.selectedMonth;

			},
			onSelect () {
				updateDate();
			},
			onClose () {
				updateDate();
				_datepickeractive = false;
			},
			beforeShow () {
				_datepickeractive = true;
			}
		});

		$(input).on('blur', () => {
			if (!_datepickeractive) {
				updateDate();
			}
		});

		$(input).cwdatetimepicker('setDate', runtime.getDate());

		updatePickerDate = function () {
			$(input).cwdatetimepicker('setDate', runtime.getDate());
		};

		clearDate = function () {
			$(input).cwdatetimepicker('setDate', null);
			prev = input.value;
			runtime.clearDate();
			assessment.startSaving();
		};

		updateDate = function () {
			runtime.setDate($(input).cwdatetimepicker('getDate'));
			assessment.startSaving();
			prev = input.value;
			refreshDateText(updatePickerDate);
		};

	} else if (runtime.isDate()) {
		/* date only picker */
		$(input).cwdatepicker({
			dateFormat: t('date.formats.quizDateFormat'), // dd.mm.yy,
			minDate: runtime.getMinimalDate(),
			maxDate: runtime.getMaximalDate(),
			yearRange: runtime.getYearRange(),
			changeYear: true,
			changeMonth: true,
			onSelect () {
				updateDate();
			},
			onClose () {
				_datepickeractive = false;
			},
			beforeShow () {
				_datepickeractive = true;
			}
		});

		$(input).on('blur', () => {
			if (!_datepickeractive) {
				updateDate();
			}
		});

		$(input).on('selenium-force', function () {
			$(input).cwdatepicker('setDate', $(this).val());
			updateDate();
		});

		updatePickerDate = function () {
			$(input).cwdatepicker('setDate', runtime.getDate());
		};

		updatePickerDate();

		clearDate = function () {
			$(input).cwdatepicker('setDate', null);
			prev = input.value;
			runtime.clearDate();
			assessment.startSaving();
		};
		updateDate = function () {
			runtime.setDate($(input).cwdatepicker('getDate'));
			assessment.startSaving();
			prev = input.value;
			refreshDateText(updatePickerDate);
		};

	} else if (runtime.isTime()) {
		/* time only picker */
		$(input).cwtimepicker({
			timeFormat: t('date.formats.quizTimeFormat'), // HH:mm,
			onSelect (dateText) {
				updateDate(dateText);
			},
			onClose () {
				_datepickeractive = false;
			},
			beforeShow () {
				_datepickeractive = true;
			}
		});

		$(input).on('blur', () => {
			if (!_datepickeractive) {
				updateDate(input.value);
			}
		});

		$(input).on('selenium-force', function () {
			updateDate($(this).val());
		});

		$(input).cwtimepicker('setDate', runtime.getDate());

		updatePickerDate = function () {
			$(input).cwtimepicker('setDate', runtime.getDate());
		};

		clearDate = function () {
			$(input).cwtimepicker('setDate', null);
			prev = input.value;
			runtime.clearDate();
			assessment.startSaving();
		};

		updateDate = function (dateText) {
			let date = null;
			let time;

			if (dateText) {
				const timeArr = dateText.split(':');
				time = {
					hour: timeArr[0],
					minute: timeArr[1]
				};
				date = new Date(0, 0, 0, time.hour, time.minute);
			}
			runtime.setDate(date);
			assessment.startSaving();
			prev = input.value;
			refreshDateText(updatePickerDate);
		};
	}

	this.getElementBox = function () {
		return box;
	};

	this.clear = function () {
		clearDate();
	};
}

