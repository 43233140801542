import { cwtagsNl2br } from '../../helpers/string';
import layoutAdjustment from '../layout-adjustment';
import $ from 'jquery';
import { delay, forEach } from 'lodash';
import t from 'translate';
import { QUESTION_TAG_NAME } from '../../config';

export default function (runtime, assessment) {
	const $container = $(`<div
		id="${runtime.getElementId()}"
		data-id="${runtime.getElementId()}"
		class="assessment-container assessment-imagewidget-container"
	/>`);

	if (!runtime.isVisible()) {
		$container.hide();
	}

	layoutAdjustment(runtime, $container[0]);
	const $innerContainer = $(`<${QUESTION_TAG_NAME} class="questiontext" />`).appendTo($container);

	if (runtime.getNumber()) {
		$(`<span class="question-numbering">${runtime.getNumber()}.</span>`)
			.appendTo($innerContainer);
	}

	const $questionText = $(`<span
			class="question"
			data-id="${runtime.getElementId()}-questiontext"
		>${cwtagsNl2br(runtime.getQuestionText())}</span>`).appendTo($innerContainer);

	this.updateText = function () {
		$questionText.html(cwtagsNl2br(runtime.getQuestionText()));
	};

	const $answerContainer = $('<div class="questionanswers" />').appendTo($container);
	const $imgContainer = $('<div class="image-box" />').appendTo($answerContainer);

	const alt = (runtime.isMultiple()) ?
		t('This is a special type of image, where you can select one or more points.') :
		t('This is a special type of image, where you can select a specific point.');

	const $img = $(`<img
		class="assessment-image assessment"
		src="${runtime.getImageUrl()}"
		alt="${alt}"
	/>`).appendTo($imgContainer);

	const $clearContainer = $('<div />').appendTo($container);
	const $clearSelection = $(`<a
		tabindex="0"
		role="button"
	>${t('assessment-runtime.image-widget.clear-selection')}</a>`).appendTo($clearContainer);

	const rx = (x) => x / $imgContainer.width();
	const ry = (y) => y / $imgContainer.height();

	const addXmark = (x, y, clickId) => {
		if (!runtime.isMultiple()) {
			$('span', $imgContainer).remove();
		}

		const $markContainer = $('<span />').css({
			position: 'absolute',
			left: `${rx(x) * 100}%`,
			top: `${ry(y) * 100}%`
		}).appendTo($imgContainer);

		const $mark = $('<span class="ui-icon ui-icon-closethick xmark" />')
			.css('margin', '-24px 0 0 -8px')
			.data('clickId', clickId)
			.appendTo($markContainer);

		$mark.on('click', () => {
			runtime.removeClick($mark.data('clickId'));
			$mark.remove();
		});
	};

	const mountAnswers = ($image) => {
		$('span.xmark', $container).remove();

		const h = $image.height();
		const w = $image.width();

		if (!(h && w)) { // the image might not be rendered yet...
			delay(mountAnswers, 100, $image); // ...so good luck next time
			return;
		}

		forEach(runtime.getScaledClicksCoords({
			width: w,
			height: h
		}), (click) => {
			addXmark(click.x, click.y, click.id);
		});

		$clearSelection.on('click', () => {
			$('span.xmark', $container).remove();
			runtime.clearClicks();
		});
	};

	$img.on('load', () => {
		mountAnswers($img);
	});

	$img.on('click', (e) => {
		const h = $img.height();
		const w = $img.width();
		const scale = runtime.scale({
			width: w,
			height: h
		});

		const offX = (e.offsetX || e.originalEvent.layerX);
		const offY = (e.offsetY || e.originalEvent.layerY);
		const clickId = runtime.addClick({
			x: offX / scale.x,
			y: offY / scale.y
		});

		if (clickId) {
			addXmark(offX, offY, clickId);
		}

		assessment.startSaving();
	});

	$(window).on('resize', () => {
		mountAnswers($img);
	});

	if (assessment.isReadonlyMode()) {
		$clearSelection.hide();
	}

	this.getElementBox = () => $container[0];

	this.clear = () => {
		$('select', $container).prop('selected', false);
	};
}
