import t from 'translate';
import store from 'store';
import { TREATMENT } from 'store/treatments/rp-treatment';
import { RESPONDENT } from 'store/respondents/respondent';

// Return "real" value when in respondent portal, otherwise go with fallback
const ifRespondent = (value, fallback) => store.state.user.type === 'respondent' ?
	value() :
	fallback();

const watchTreatment = {
	watch: () => ifRespondent(
		() => ({ getter: [TREATMENT.ID] }),
		() => ({})
	)
};

export default {
	clinician: {
		unansweredMessages: {
			label: () => t('Unanswered messages'),
			count: () => ({ getter: 'notifications/messages' }),
			before: () => store.dispatch('notifications/init', { type: 'messages' })
		}
	},

	respondent: {
		assessments: {
			label: () => t('Assessments'),
			count: () => ifRespondent(
				() => ({ getter: 'respondentAssessmentInstances/size' }),
				() => ({ value: 'XX' })
			),
			before: () => ifRespondent(
				() => store.dispatch('respondentAssessmentInstances/init'),
				() => ({})
			),
			...watchTreatment
		},
		contentPackages: {
			label: () => t('Content packages'),
			count: () => ifRespondent(
				() => ({ getter: 'respondentContentPackageInstances/size' }),
				() => ({ value: 'XX' })
			),
			before: () => ifRespondent(
				() => store.dispatch('respondentContentPackageInstances/init'),
				() => ({})
			),
			...watchTreatment
		},
		messages: {
			label: () => t('Messages'),
			count: () => ifRespondent(
				() => ({ getter: RESPONDENT.MESSAGES_COUNT }),
				() => ({ value: 'XX' })
			),
			before: () => ifRespondent(
				() => store.dispatch(RESPONDENT.INIT_SUMMARY),
				() => ({})
			),
			...watchTreatment
		},
		reports: {
			label: () => t('All reports'),
			count: () => ifRespondent(
				() => ({ getter: 'reports/allCount' }),
				() => ({ value: 'XX' })
			),
			before: () => ifRespondent(
				() => store.dispatch('reports/initAllForCurrentRespondent'),
				() => ({})
			),
			...watchTreatment
		},

		__hack__reportsList: {
			label: () => t('Reports from reports list tile'),
			count: () => ifRespondent(
				() => ({ getter: 'reports/__hack__reportsList' }),
				() => ({ value: 'XX' })
			),
			before: () => ifRespondent(
				() => store.dispatch('reports/__hack__initFromReportsList'),
				() => ({})
			),
			...watchTreatment
		}
	}
};
