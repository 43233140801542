import SingleEntity from '../shared/single-entity';
import urls from './urls';

export default SingleEntity.extend({
	eventNamespace: 'assessment-type',
	urlRoot: urls.type.url,

	getLabel () {
		return this.get('label');
	}
});
