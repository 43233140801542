<template>
	<div :class="containerClassName">
		<button
			v-translate
			:class="buttonClassName"
			:disabled="!canCancel"
			type="button"
			@click="onClick"
		>{{label}}</button>
	</div>
</template>

<script>
import Vue from 'vue';
import { className } from './_helpers';
import { noop } from 'lodash';

const COMPONENT_NAME = 'plain-button';

export default Vue.component(COMPONENT_NAME, {
	props: {
		classPrefix: {
			type: String,
			default: ''
		},
		onClick: {
			type: Function,
			default: noop
		},
		label: {
			type: String,
			default: 'Cancel'
		},
		canCancel: {
			type: Boolean,
			default: true
		}
	},

	computed: {
		containerClassName () {
			return [
				...this.className('field-container'),
				...this.className('field-container--button')
			];
		},

		buttonClassName () {
			return [
				...this.className('button'),
				...this.className('button--button')
			];
		}
	},

	methods: {
		className: className(COMPONENT_NAME)
	}
});
</script>
