var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "h3",
      { staticClass: "circle-post__title" },
      [_c("avatar-nickname", { attrs: { user: _vm.post.circleUser } })],
      1
    ),
    _vm._v(" "),
    _vm.loading
      ? _c("div", {
          directives: [{ name: "loader-spinner", rawName: "v-loader-spinner" }],
          staticClass: "circle-post__loader"
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "time",
      {
        staticClass: "circle-post__datetime",
        attrs: { datetime: _vm.post.createdAt, title: _vm.datetime }
      },
      [_vm._v(_vm._s(_vm.fromNow))]
    ),
    _vm._v(" "),
    _vm.post.reportAbusesCount
      ? _c(
          "p",
          { staticClass: "circle-post__reported" },
          [
            _c("icon", { attrs: { name: "ban" } }),
            _vm._v(_vm._s(_vm.t("Post reported")) + "\n\t")
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "circle-post__menu" }, [
      _c(
        "button",
        {
          staticClass: "circle-post__menu-trigger",
          attrs: { title: _vm.t("Post options") },
          on: { click: _vm.menuToggle }
        },
        [_c("icon", { attrs: { name: "more-actions" } })],
        1
      ),
      _vm._v(" "),
      _vm.menuToggled
        ? _c("ul", { staticClass: "circle-post__menu-items" }, [
            _vm.canEdit
              ? _c("li", { staticClass: "circle-post__menu-item" }, [
                  _c(
                    "button",
                    {
                      staticClass: "circle-post__menu-action",
                      on: { click: _vm.edit }
                    },
                    [
                      _c("icon", { attrs: { name: "pencil", small: true } }),
                      _vm._v(" "),
                      _c("span", { staticClass: "circle-post__menu-content" }, [
                        _vm._v(_vm._s(_vm.t("Edit post")))
                      ])
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.canEdit
              ? _c("li", { staticClass: "circle-post__menu-item" }, [
                  _c(
                    "button",
                    {
                      staticClass: "circle-post__menu-action",
                      on: { click: _vm.remove }
                    },
                    [
                      _c("icon", { attrs: { name: "delete", small: true } }),
                      _vm._v(" "),
                      _c("span", { staticClass: "circle-post__menu-content" }, [
                        _vm._v(_vm._s(_vm.t("Delete post")))
                      ])
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("li", { staticClass: "circle-post__menu-item" }, [
              _c(
                "button",
                {
                  staticClass: "circle-post__menu-action",
                  on: { click: _vm.reportAbuse }
                },
                [
                  _c("icon", { attrs: { name: "ban", small: true } }),
                  _vm._v(" "),
                  _c("span", { staticClass: "circle-post__menu-content" }, [
                    _vm._v(_vm._s(_vm.t("Report abuse")))
                  ])
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "circle-post__content-container" }, [
      _c("p", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.beingEdited,
            expression: "!beingEdited"
          }
        ],
        staticClass: "circle-post__content-view",
        domProps: { innerHTML: _vm._s(_vm.escapedContent) }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.beingEdited,
              expression: "beingEdited"
            }
          ],
          staticClass: "circle-post__content-edit-container"
        },
        [
          _c("div", { staticClass: "form-view__field-container" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.post.content,
                  expression: "post.content"
                }
              ],
              ref: "textarea",
              staticClass:
                "form-view__input form-view__textarea circle-post__content-edit",
              domProps: { value: _vm.post.content },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.post, "content", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-view__field-container" }, [
            _c(
              "button",
              {
                staticClass: "form-view__button form-view__button--submit",
                on: { click: _vm.save }
              },
              [_vm._v(_vm._s(_vm.t("Save")))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "form-view__button",
                on: { click: _vm.cancelEditing }
              },
              [_vm._v(_vm._s(_vm.t("Cancel")))]
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }