import { GET } from 'service/ajax/ajax';
import urls from './urls';

export default {
	/**
	 * Get all AssessmentInstances.
	 *
     * @param {object} data -- Parameters.
	 * @returns {object} Promise.
	 * @example
	 *        Repository.getAssessmentInstances();
	 */
	getAssessmentInstances: (data) => GET({
		url: urls.assessmentInstances.url(data)
	}),

	/**
	 * Get all sorted AssessmentInstances.
     *
	 * @param {object} data -- Sorting and limiting parameters.
	 * @returns {object} New {{#crossLink "AssessmentInstances"}}{{/crossLink}} Collection.
	 * @example
	 *        Repository.getSortedAssessmentInstances({ name: 'ASC', limit: 1 });
	 */
	getSortedAssessmentInstances (data) {
		return this.AssessmentInstances.getSorted(data);
	},

	/**
	 * @param {number} id -         Id.
	 * @returns {Promise<object>} - Assessment instance.
	 * @example
	 * 		repository.assessmentInstance(2137);
	 */
	assessmentInstance: (id) => GET({
		url: urls.assessmentInstance(id)
	})
};
