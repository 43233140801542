/**
 * Remove `loading` state from the tile.
 *
 * @function TileView.setLoaded
 * @returns {Object} - Tile object.
 * @example
 * export default TileView.extend({
 *     loaded: ({ tile }) => {
 *         tile.setLoading();
 *         tile.setLoaded();
 *     }
 * });
 */
export default function () {
	this.__params.tileContainerModel.set('loading', false);
	return this;
}
