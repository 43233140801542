import { DECISIONS } from '../../../../store/plans/decisions';
import { RESPONDENT_CONSENT_INSTANCES } from 'store/consents/rp-consent-instances';
import { RESPONDENT } from 'store/respondents/respondent';

const NOTIFICATIONS_INIT = 'notifications/init';

export default {
	allRespondentReports: {
		getter: {
			count: 'reports/allCount'
		},
		actions: () => [['reports/initAllForCurrentRespondent']]
	},

	assessments: {
		getter: {
			count: 'notifications/assessments'
		},
		actions: () => [[NOTIFICATIONS_INIT, { type: 'assessment' }, {}, { instant: true }]]
	},

	assessmentsNotSubmited: {
		getter: {
			count: 'assessmentInstances/notSubmittedCount',
			total: 'assessmentInstances/size'
		},
		actions: () => [['assessmentInstances/init']]
	},

	availableConsents: {
		getter: {
			count: RESPONDENT_CONSENT_INSTANCES.NOT_SUBMITTED_COUNT,
			total: RESPONDENT_CONSENT_INSTANCES.ALL_COUNT
		},
		actions: () => [[RESPONDENT_CONSENT_INSTANCES.INIT]]
	},

	consents: {
		getter: {
			count: 'consentInstances/notSubmittedCount',
			total: 'consentInstances/count'
		},
		actions: () => [['consentInstances/init']]
	},

	contentPackages: {
		getter: {
			count: 'contentPackageInstances/uncompletedCount',
			total: 'contentPackageInstances/totalCount'
		},
		actions: () => [['contentPackageInstances/init']]
	},

	decisions: {
		getter: {
			count: 'notifications/decisions'
		},
		actions: () => [[NOTIFICATIONS_INIT, { type: 'inputs' }, {}, { instant: true }]]
	},

	flags: {
		getter: {
			count: 'notifications/flags'
		},
		actions: () => [[NOTIFICATIONS_INIT, { type: 'flags' }, { instant: true }]]
	},

	message: {
		getter: {
			count: 'notifications/messages'
		},
		actions: () => [[NOTIFICATIONS_INIT, { type: 'messages' }, {}, { instant: true }]]
	},

	newlyModifiedConsents: {
		getter: {
			count: 'consentInstances/newlyModifiedCount'
		},
		actions: () => [['consentInstances/newlyModifiedConsentsCount']]
	},

	overdueAssessments: {
		getter: {
			count: 'notifications/overdueAssessments'
		},
		actions: () => [[NOTIFICATIONS_INIT, { type: 'overdue' }, {}, { instant: true }]]
	},

	plans: {
		getter: {
			count: 'planInstances/size'
		},
		actions: () => [['planInstances/init']]
	},

	raisedFlags: {
		getter: {
			count: 'notifications/raisedFlags'
		},
		actions: () => [[NOTIFICATIONS_INIT, { type: 'flagsRaised' }, {}, { instant: true }]]
	},

	reportsListFromTileConfig: {
		getter: {
			count: 'reports/__hack__reportsList'
		},
		actions: () => [['reports/__hack__initFromReportsList']]
	},

	respondentAssessments: {
		getter: {
			count: 'respondentAssessmentInstances/size'
		},
		actions: () => [['respondentAssessmentInstances/init']]
	},

	respondentExercises: {
		getter: {
			count: 'respondentExercises/size'
		},
		actions: () => [['respondentExercises/init']]
	},

	respondentGoals: {
		getter: {
			count: 'goals/activeCount'
		},
		actions: () => [['goals/init']]
	},

	respondentLinks: {
		getter: {
			count: 'respondentLinks/size'
		},
		actions: () => [['respondentLinks/init']]
	},

	respondentMedia: {
		getter: {
			count: 'respondentMedia/size'
		},
		actions: () => [['respondentMedia/init']]
	},

	respondentMessages: {
		getter: {
			count: RESPONDENT.MESSAGES_COUNT
		},
		actions: () => [[RESPONDENT.INIT_SUMMARY]]
	},

	respondentPages: {
		getter: {
			count: 'respondentPages/size'
		},
		actions: () => [['respondentPages/init']]
	},

	treatmentDecisions: {
		getter: {
			count: DECISIONS.UNANSWERED_COUNT,
			total: DECISIONS.SIZE
		},
		actions: () => [['decisions/init']]
	},

	treatmentFlags: {
		getter: {
			count: 'flags/raisedCount',
			total: 'flags/size'
		},
		actions: () => [['flags/init']]
	},

	treatmentMsg: {
		getter: {
			count: 'messages/respondentUnansweredCount',
			total: 'messages/size'
		},
		actions: () => [['treatment/getMessages']]
	},

	unseenAssessments: {
		getter: {
			count: 'notifications/unseenAssessments'
		},
		actions: () => [[NOTIFICATIONS_INIT, { type: 'assessmentsUnseen' }, {}, {
			instant: true
		}]]
	},

	unseenFlags: {
		getter: {
			count: 'notifications/unseenFlags'
		},
		actions: () => [[NOTIFICATIONS_INIT, { type: 'flagsUnseen' }, {}, { instant: true }]]
	}
};
