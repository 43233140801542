import Backbone from 'backbone';
import appContext from 'app-context';
import datetime from 'datetime';
import cache from 'service/cache/cache';
import $ from 'jquery';
import {
	assign,
	forEach,
	isArray,
	isFunction,
	isObject,
	isString,
	isUndefined,
	toLower
} from 'lodash';

/**
 * Multiple Entities
 * Blueprint Backbone Collection class used across Repository service.
 *
 * @class MultipleEntities
 * @augments Backbone.Model
 */
export default Backbone.Collection.extend({
	idName: 'id',

	initialize () {
		this
			.attachEntitiesEventListeners()
			.attachEventListeners()
			.attachComparator();
	},

	entitiesEvents: {
		':refresh' () {
			this.url && assign(this, this.fetch());
		},
		'.__create' (entity) {
			if (!entity) {
				return;
			}

			this._canAdd(entity) && this.set(entity, { remove: false });

			appContext.trigger(`${this.eventNamespace}.create`, entity);
			entity.trigger('create');
		},
		'.__delete' (entity) {
			if (!entity) {
				return;
			}
			this.remove(entity);

			appContext.trigger(`${this.eventNamespace}.delete`, entity);
			entity.trigger('delete');
		},
		'.__update' (entity) {
			if (entity && isFunction(entity.getId) && this.get(entity.getId())) {
				this.add(entity, {
					merge: true
				});

				appContext.trigger(`${this.eventNamespace}.update`, entity);
				entity.trigger('update');
			}
		}
	},

	_canAdd (model) {
		if (isUndefined(this.ownerId)) {
			return true;

		}

		if (!isFunction(this.canAdd)) {
			return false;
		}
		return this.canAdd(model);

	},

	attachEntitiesEventListeners () {
		this.eventNamespace && forEach(this.entitiesEvents, (handler, eventName) => {
			this.listenTo(appContext, this.eventNamespace + eventName, handler);
		});
		return this;
	},

	attachEventListeners () {
		forEach(this.appEvents, (handler, eventName) => {
			this.listenTo(appContext, eventName, handler);
		});

		forEach(this.events, (hand, eventName) => {
			let handler = hand;

			if (isString(handler)) {
				handler = this[handler];
			}
			this.listenTo(this, eventName, handler);
		});

		return this;
	},

	attachComparator () {
		// skip if order isn't specified
		if (!this.order) {
			return this;
		}

		this.comparator = (item) => {
			const key = isArray(this.order) ? this.order[0] : this.order;
			let value;

			if (isFunction(key)) {
				value = key(item);

			} else if (isFunction(item[key])) {
				value = item[key];

			} else {
				value = item.get(key);
			}

			// backend returns date in specific format (CWDATE) which is unusable when sorting
			value = isObject(value) ? datetime(value).toMoment().unix() : value;

			// prevent bad order of strings with different lowercase characters
			value = isString(value) ? toLower(value) : value;

			// cast as int if variable is integer
			value = parseInt(value, 10) || value;

			return value;
		};

		return this;
	},

	/*
	 * Retrieve a collection.
	 *
	 * @protected
	 * @param  {string} name
	 * @param  {Object|string|number} ownerId
	 * @return {Object} Collection.
	 */
	retrieve (name, ownerId, opts = {}) {
		let entityUrl = this.urls[name].url;
		let entity;

		if (isFunction(entityUrl)) {
			// eslint-disable-next-line prefer-rest-params
			const args = [].slice.call(arguments);
			args.shift();
			entityUrl = entityUrl(...args);
		}

		if (!this[entityUrl]) {
			entity = this.clone();
			entity.url = entityUrl;
			this[entityUrl] = entity;

			assign(entity, entity.fetch(assign({ reset: true }, opts)));

		} else {
			entity = this[entityUrl];

			if (cache[entity.eventNamespace] === true) {
				const dfd = $.Deferred();
				assign(entity, dfd);
				dfd.resolve();

			} else {
				assign(entity, entity.fetch(assign({ reset: true }, opts)));
			}
		}

		entity.ownerId = ownerId;

		return entity;
	},

	/*
	 * Get Entity by id, it requires 'idName' property to be set
	 * Optional use modelCfg param to set default model attributes.
	 *
	 * @method getById
	 * @param {number|string} entityId
	 * @param {Object} modelCfg
	 * @return {Object} {{#crossLink "SingleEntity"}}{{/crossLink}}
	 * @example
	 *        Repository.AssessmentInstances.getById(7);
	 *        Repository.Respondents.getById(19);
	 */
	getById (entityId, modelCfg = {}) {
		modelCfg[this.idName] = entityId;
		const entity = new this.model(modelCfg);
		return entity.retrieve();
	}
});
