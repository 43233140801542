<template>
	<section v-once class="progress">
		<header class="progress__title-wrapper">
			<h2 v-if="title" class="progress__title">
				<a v-if="href" :href="href">{{title}}</a>
				<template v-else>
					{{title}}
				</template>
			</h2>
			<h3 v-if="subtitle" class="progress__subtitle">
				{{subtitle}}
			</h3>
		</header>
		<div class="progress__container">
			<p
				v-if="startDate"
				class="progress__label progress__label--start"
				:aria-label="t('Start date')"
			>
				{{startDate}}
			</p>
			<p
				v-if="endDate"
				class="progress__label progress__label--end"
				:aria-label="t('End date')"
			>
				{{endDate}}
			</p>
			<progress
				class="progress__indicator"
				max="100"
				:value="value"
				:aria-label="progressLabel"
			>
				<div class="progress__fallback-container">
					<span class="progress__fallback-indicator" :style="fallbackWidth" />
				</div>
			</progress>
		</div>
	</section>
</template>

<script>
import Vue from 'vue';
import t from 'translate';

const optionalString = {
	type: String,
	default: ''
};

export default Vue.component('progress-bar', {
	props: {
		progressValue: {
			type: [String, Number],
			required: true
		},
		title: optionalString,
		subtitle: optionalString,
		href: optionalString,
		startDate: optionalString,
		endDate: optionalString
	},

	computed: {
		t,
		value: ({ progressValue }) => progressValue > 100 ? 100 : progressValue,

		progressLabel: ({ value }) => `${t('Progress')}: ${value}%`,

		fallbackWidth: ({ value }) => ({ width: `${value}%` })
	}
});
</script>
