import Option from './types/Option';
import Norm from './types/Norm';
import Literal from './types/Literal';
import Expression from './expression';
import Dimension from './types/Dimension';
import TheFunction from './function';
import Calc from './types/Calc';
import Question from './types/Question';
import Section from './types/Section';
import Flag from './types/Flag';
import Language from './types/language';
import { isFunction } from 'lodash';

const addElement = (Entity) => ({ element, elements }) => {
	const Constructor = isFunction(Entity) ? Entity : Entity['default'];
	elements.push(new Constructor(element));
};

export const definitions = {
	expressions: {
		list: [
			undefined, 'or', 'and', 'not', 'equal', 'less', 'lessOrEqual', 'greater',
			'greaterOrEqual', 'plus', 'minus', 'notEqual', 'multiply', 'divide', 'max', 'min'
		],
		handle: addElement(Expression)
	},
	literals: {
		list: [
			'questionnaire:StringBasicType', 'questionnaire:BooleanBasicType',
			'questionnaire:NumberBasicType', 'questionnaire:DateBasicType'
		],
		handle: addElement(Literal)
	},
	functions: {
		list: ['questionnaire:Function'],
		handle: addElement(TheFunction)
	},
	options: {
		list: ['questionnaire:OptionType'],
		handle: addElement(Option)
	},
	questions: {
		list: ['questionnaire:QuestionType'],
		handle: addElement(Question)
	},
	sections: {
		list: ['questionnaire:SectionType'],
		handle: addElement(Section)
	},
	dimensions: {
		list: ['questionnaire:DimensionType'],
		handle: addElement(Dimension)
	},
	calculations: {
		list: ['questionnaire:CalculationType'],
		handle: addElement(Calc)
	},
	norms: {
		list: ['questionnaire:NormType'],
		handle: addElement(Norm)
	},
	flags: {
		list: ['questionnaire:FlagType'],
		handle: addElement(Flag)
	},
	languages: {
		list: ['questionnaire:LanguageType'],
		handle: addElement(Language)
	}
};
