import SingleEntity from '../shared/single-entity';
import { abuseReportStatus } from './urls';

export default SingleEntity.extend({
	urlRoot: abuseReportStatus.url,
	eventNamespace: 'circle-abuse-report-status',
	defaults: {
		isDefault: false
	}
});
