import { find, includes, reduce, replace, split } from 'lodash';
import { EMPTY_CONTEXT } from './update';

const contextIncluded = [
	'assessmentAttribute',
	'assessmentCalculation',
	'assessmentFlag',
	'assessmentQuestion'
];

const context = {
	name: 'Context',
	label: 'contextLabel'
};

const types = [{
	name: 'Assessment',
	label: 'columnLabel'
}, {
	name: 'ItemID',
	label: 'identifierLabel'
}];

const exportTypes = () => [...types, context];

const INVALID_CHARS = [' ', '#', '_', '!', '%', '&', ',', '\\(', '\\)', '\\\\', '\\*', '\\+', '-',
	'=', ':', ';', '<', '>', '\\?', '\\^', '`', '{', '\\|', '}', '~', '\\[', '\\]', '\\/'];

const splitOutInvalidChars = (value) => {
	const regex = new RegExp(INVALID_CHARS.join('|'), 'g');
	const prefix = value[0] === '@' ? '@' : '';
	const suffix = value.substr(value.length - 1) === '.' ? '.' : '';
	const replaced = replace(value, regex, '');
	return `${prefix}${replaced}${suffix}`;
};

export const updateName = (data) => {
	const elementContextExcluded = data.assessmentContext === EMPTY_CONTEXT;
	const noContextInheritance = !data.assessmentContext && data.parentContextExcluded;
	const excludeContext = elementContextExcluded || noContextInheritance;
	const includeContext = includes(contextIncluded, data.type) && !excludeContext;

	const splitted = split(data.codeTemplate, '#');
	return reduce(splitted, (result, element) => {
		const elementType = find(exportTypes(), { name: element });

		if (!elementType) {
			return result + element;
		} else if (data[elementType.label]) {
			if (element === context.name && !includeContext) {
				return result;
			}
			return result + splitOutInvalidChars(data[elementType.label]);
		}
		return result;
	}, '');
};
