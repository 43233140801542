import FormComponentView from '../form-component-view';
import { compile } from 'handlebars';
import { isFunction } from 'lodash';

const button = FormComponentView.extend({
	template: compile(`
		<button
			class="form-view__button form-view__button--{{ type }}"
			type="{{ type }}"
			name="{{ uniqueName }}"
		  {{#if disabled}} disabled="disabled" {{/if}}>
			{{ caption }}
		</button>
	`),
	customClassName: 'form-view__field-container--button',
	events: {
		'click button': 'onClick'
	},

	onClick (e) {
		if (this.model.get('disabled')) {
			return;
		}
		const action = this.model.get('action');
		const role = this.model.get('role');
		this.roles.hasOwnProperty(role) && this.roles[role].call(this);
		isFunction(action) && action(e);
		this.$('button').trigger('blur'); // prevent button from being focused after click

		// if this is submit button we should trigger event - becouse FormView should handle
		// validation in proper way
		if (this.model.get('type') === 'submit') {
			e.preventDefault() && e.stopPropagation();
			this.$el.trigger('submit');
		}
	},

	roles: {
		cancel () {
			this.options.formModel.revert();
			// to let animations finish, e.g. when closing card
			setTimeout(this.options.formView.close.bind(this), 300);
		},
		clear () {
			this.options.formModel.clear();
		},
		reset () {
			this.options.formModel.revert();
		}
	},

	disable (disabled) {
		this.model.set('disabled', disabled !== false);
	},

	disabledChanged (model, disabled) {
		this.$('button').prop('disabled', disabled);
		this.toggleDisabledClass(disabled);
	}
});

export { button };
