import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import UploadedFile from './uploaded-file';

const UploadedFiles = MultipleEntities.extend({
	urls,
	model: UploadedFile,
	idName: 'uploadFileId',

	getByAssessmentInstanceId (assessmentInstanceId) {
		return this.retrieve('uploadedFiles', assessmentInstanceId);
	},

	getTotalFileSize (formatted) {
		let filesize = 0;
		this.each((uploadedFile) => {
			filesize += uploadedFile.getFileSize();
		});

		if (formatted) {
			return `${Math.ceil(filesize / 1024)}kB`;
		}
		return filesize;
	}
});

export default new UploadedFiles();
