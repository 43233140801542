import { add, remove, set, update } from '../__helpers/mutations';
import repository from 'repository';
import { assign, map } from 'lodash';
import initEntities from '../__helpers/init-entities';
import { status } from '../agenda/helpers';
import datetime from 'service/datetime/datetime';
import moment from 'moment';
import { RESPONDENT } from '../respondents/respondent';

const prefix = 'activities';
const ENTITIES = 'entities';
const GOAL_ENTITIES = 'goalsEntities';
const ITEMS = 'items';
const ITEMS_IN_GOAL = 'itemsInGoal';

const CREATE = 'create';
const INIT = 'init';
const INIT_FOR_GOAL = 'initForGoal';

export const ACTIVITIES = {
	ENTITIES: `${prefix}/${ENTITIES}`,
	GOAL_ENTITIES: `${prefix}/${GOAL_ENTITIES}`,
	ITEMS: `${prefix}/${ITEMS}`,
	ITEMS_IN_GOAL: `${prefix}/${ITEMS_IN_GOAL}`,
	CREATE: `${prefix}/${CREATE}`,
	INIT: `${prefix}/${INIT}`,
	INIT_FOR_GOAL: `${prefix}/${INIT_FOR_GOAL}`
};

const entities = (state, field) => map(state[field], (activity) => assign({}, activity, {
	completed: activity.isCompleted,
	datetime: activity.deadline || activity.startDate || moment().format('YYYY-MM-DD'),
	overdue: datetime(activity.deadline).toMoment() < moment.now(),
	itemType: 'activity',
	open: !activity.isCompleted,
	title: activity.name
}));

const items = (list) => map(list, (activity) => assign({}, activity, {
	status: status(activity)
}));

export default {
	namespaced: true,

	state: {
		data: [],
		inGoal: []
	},

	getters: {
		[ENTITIES]: (state) => entities(state, 'data'),
		[GOAL_ENTITIES]: (state) => entities(state, 'inGoal'),
		[ITEMS]: (state, getters) => items(getters[ENTITIES]),
		[ITEMS_IN_GOAL]: (state, getters) => items(getters[GOAL_ENTITIES])
	},

	mutations: {
		add: add(),
		addInGoal: add('inGoal'),
		remove: remove(),
		removeInGoal: remove('inGoal'),
		set: set(),
		setInGoal: set('inGoal'),
		update: update(),
		updateInGoal: update('inGoal')
	},

	actions: {
		[INIT_FOR_GOAL]: async ({ commit }, { respondentId, goalId }) => {
			commit('set', []);
			commit('setInGoal', await repository.goalActivities({ respondentId, goalId }));
		},

		initForTreatment: async ({ commit }, { treatmentId }) => {
			commit('set', await repository.treatmentActivities(treatmentId));
		},

		initForRespondent: async ({ commit }, { respondentId }) => {
			commit('set', await repository.respondentActivities(respondentId));
		},

		[INIT]: async ({ commit, dispatch, rootGetters, rootState }) => {
			commit('set', []);
			await initEntities(ACTIVITIES.INIT)({ dispatch, rootGetters, rootState });
		},

		[CREATE]: (
			{ commit, rootGetters }, { activity, inGoal = false }
		) => repository.createActivity(assign(
			{},
			activity,
			{ goal: activity.goal.id },
			{ respondent: rootGetters[RESPONDENT.RESPONDENT_ID] }
		)).then((newActivity) => {
			const processedActivity = assign({}, activity, newActivity);
			commit('add', processedActivity);

			inGoal && commit('addInGoal', processedActivity);
		}),

		remove: ({ commit }, { activity, inGoal = false }) => {
			commit('remove', { item: activity });
			inGoal && commit('removeInGoal', { item: activity });
			return repository.deleteActivity(activity);
		},

		removeFromStore: ({ commit }, activity) => {
			commit('remove', { item: activity });
		},

		toggleStatus: ({ commit }, { activity, inGoal = false }) => {
			const toggledActivity = assign({}, activity, { isCompleted: !activity.isCompleted });
			commit('update', toggledActivity);
			inGoal && commit('updateInGoal', toggledActivity);

			return repository.updateActivity(toggledActivity);
		}
	}
};
