import { forEach, isArray } from 'lodash';
import { View } from 'marionette';
import cardLayout from './card-layout';
import cardConfig from 'core/engine/card/config/card-config';
import { compile } from 'handlebars';

export default View.extend({
	template: compile(`
		<h1 class="card__title">{{ title }}</h1>
		<div class="tiles-container"></div>
	`),
	initialize (opt) {
		this.model.set('cardName', opt.cardName);
		this.model.set('cardUrl', opt.cardUrl);
	},

	ui: {
		title: '.card__title'
	},

	regions: {
		tilesContainer: '.tiles-container'
	},

	renderTiles (tilesCollectionView) {
		if (this.getRegion('tilesContainer')) {
			this.getRegion('tilesContainer').show(tilesCollectionView, {
				forceShow: true
			});
		}
	},

	loading (start) {
		const fn = {
			true: 'addClass',
			false: 'removeClass'
		}[start];

		cardLayout.$el[fn]('card-loading');
	},

	onRender () {
		const config = cardConfig(this.model.get('cardName'));
		const showTitle = config.showTitle;
		const layout = config.layout;
		const greedyTiles = config.greedyTiles;

		showTitle && this.ui.title.addClass('show');
		layout && this.$el.addClass(`custom-layout custom-layout--${layout}`);
		greedyTiles && this.greedyTiles(greedyTiles);
	},

	greedyTiles (greedyCfg) {
		if (greedyCfg === true) {
			this.$el.addClass('greedy-tiles--all-layouts');

		} else if (isArray(greedyCfg)) {
			forEach(greedyCfg, (layoutType) => {
				this.$el.addClass(`greedy-tiles--${layoutType}-columns-layout`);
			});
		}
	},

	kill () {
		this.loading(false);
		this.model.get('context')
			.trigger('card.close')
			.off('card:close');

		// those abominations do not belong in the realm of modern development fortunately
		if (!this.model.get('context').get('G O D L I K E')) {
			this.getRegion('tilesContainer').destroy();
		}
		this.destroy();
	}
});

