import MultipleEntities from '../shared/multiple-entities';
import CircleCategory from './category';
import urls from './urls';

const CircleCategories = new (MultipleEntities.extend({
	model: CircleCategory,
	urls,
	eventNamespace: 'circle-category',

	getAll () {
		return this.retrieve('categories');
	}
}))();

export { CircleCategories };
