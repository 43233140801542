import { assign, filter, find, map, noop, lowerCase, pick } from 'lodash';
import t from 'translate';
import FormComponentView from '../form-component-view';
import Vue from 'vue';
import template from './template';

const multiselect = FormComponentView.extend({
	template: noop,
	items: [],

	initialize () {
		const values = this.model.get('collection') ?
			this.model.get('collection').toJSON() :
			this.model.get('values');

		assign(this, {
			items: map(values, (item) =>
				assign({}, item, {
					value: !!find(this.model.get('value'), { id: item.id })
				})
			),
			...pick(this.model.toJSON(), ['label', 'header', 'description', 'title'])
		});
	},

	onAfterRender () {
		const setValue = (value) => {
			this.model.set({ value });
		};

		this.vue = new Vue({
			el: document.createElement('div'),
			template,

			data: {
				items: this.items,
				name: '',
				cachedValue: {},
				isVisible: false,
				label: this.label || {},
				toggled: false,
				selectCategoryLabel: this.header || t('Select {item} category', {
					item: this.title
				}),
				saveLabel: t('Save'),
				cancelLabel: t('Cancel'),
				description: this.description
			},
			methods: {
				save () {
					this.toggleItemList();
				},

				cancel () {
					this.items = this.cachedValue;
					this.toggleItemList();
				},

				toggleItem: (item) => {
					item.value = !item.value;
					item.toggled = true;
				},

				removeItem (item) {
					item.value = false;
					item.toggled = false;
					setValue(this.selectedIds);
				},

				toggleItemList (action) {
					this.isVisible = !this.isVisible;
					setValue(this.selectedIds);

					if (action === 'show') {
						this.cachedValue = JSON.parse(JSON.stringify(this.items));
					}
				},

				onChange: noop
			},

			computed: {
				selectedItems () {
					return filter(this.items, { value: true });
				},

				selectedIds () {
					return map(this.selectedItems, (item) => ({ id: item.id }));
				},

				filteredItems () {
					return filter(this.items, (item) =>
						lowerCase(item.name).indexOf(lowerCase(this.name)) > -1
					);
				}
			}
		});

		this.$el.html(this.vue.$el);
	},

	customRemove () {
		this.vue.$destroy();
		this.remove();
	}
});

export { multiselect };
