<template>
	<input
		class="edit-object__text"
		type="text"
		v-trim
		:value="objVal"
		:readonly="readonly"
		@blur="onTextValueChange"
		v-on="inputListeners"
	/>
</template>

<script>
import inputMixin from './__input-mixin';
import Vue from 'vue';
import { trim } from 'lodash';

export default Vue.component('stringInput', {
	mixins: [inputMixin],
	props: {
		objVal: {
			type: String,
			required: true
		}
	},

	methods: {
		onTextValueChange (e) {
			this.onValueChange({ path: this.path, value: trim(e.target.value) });
		}
	}
});
</script>
