import SingleEntity from '../shared/single-entity';
import TreatmentTypeAttributes from './attribute-entities';
import TreatmentTypeClinicianGroups from './clinician-group-entities';
import TreatmentTypeRoles from './role-entities';
import urls from './urls';

/**
 * TreatmentType Entity
 * @class TreatmentType
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	urlRoot: urls.single.url,
	eventNamespace: 'treatment-type',
	defaults: { isDefault: false },

	/**
	 * @method getAttributes
	 * @return {Object} {{#crossLink "TreatmentTypeAttributes"}}{{/crossLink}}
	 */
	getAttributes: function () {
		return TreatmentTypeAttributes.getByTreatmentType(this.getId());
	},

	/**
	 * @method getClinicianGroups
	 * @return {Object} {{#crossLink "TreatmentTypeClinicianGroups"}}{{/crossLink}}
	 */
	getClinicianGroups: function () {
		return TreatmentTypeClinicianGroups.getByTreatmentType(this.getId());
	},

	/**
	 * @method getRoles
	 * @return {Object} {{#crossLink "TreatmentTypeRoles"}}{{/crossLink}}
	 */
	getRoles: function () {
		return TreatmentTypeRoles.getByTreatmentType(this.getId());
	}
});

