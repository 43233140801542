import { assign, flow, isArray, map, values } from 'lodash';
import store from 'store';

const operations = {
	addTimezone: (query) => assign({}, query, { timezone: store.getters['userTimezone'] })
};

const extend = flow(values(operations));

export default (query) => isArray(query) ? map(query, extend) : extend(query);
