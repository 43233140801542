import { messageDraftAttachment } from './urls';
import SingleEntity from '../shared/single-entity';
import fileUrl from 'service/file-url/file-url';

export default SingleEntity.extend({
	urlRoot: messageDraftAttachment.url,
	eventNamespace: 'respondent-message-draft-attachment',
	getUrl () {
		return fileUrl(this.get('url'));
	}
});
