import MultipleEntities from '../shared/multiple-entities';
import GoalCategory from './category';
import urls from './urls';

/**
 * GoalCategories
 * @class GoalCategories
 * @extends MultipleEntities
 */
const GoalCategories = MultipleEntities.extend({
	model: GoalCategory,
	urls,
	eventNamespace: 'goal-category',

	/**
	 * Get all goal categories
	 * @method getAll
	 * @return {Object} new {{#crossLink "GoalCategories"}}{{/crossLink}} Collection
	 * @example
	 *        Repository.GoalCategories.getAll();
	 */
	getAll: function () {
		return this.retrieve('listAllCategories');
	}
});

export default new GoalCategories();
