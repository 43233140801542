import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import AssessmentInstance from './assessment-instance';

const AssessmentInstancesEntity = MultipleEntities.extend({
	urls,
	model: AssessmentInstance,
	idName: 'assessmentInstanceId',
	eventNamespace: 'assessment-instance',

	events: {
		add (assessmentInstance) {
			// fix consistency issues with dates' fields
			if (!assessmentInstance.has('startDate') && !assessmentInstance.has('endDate')) {
				assessmentInstance.set({
					startDate: assessmentInstance.get('assessmentInstanceStartDate'),
					endDate: assessmentInstance.get('assessmentInstanceEndDate')
				});
			}
		}
	},

	appEvents: {
		'plan-instance.delete' (planInstance) {
			if (!planInstance) {
				return;
			}
			const relatedAssessmentInstances = this.where({ planInstanceId: planInstance.getId() });
			relatedAssessmentInstances.length && this.remove(relatedAssessmentInstances);
		}
	},

	canAdd (model) {
		return +model.get('respondentId') === +this.ownerId;
	},

	getAll () {
		return this.retrieve('allAssessmentInstances');
	},

	getExpired (params) {
		return this.retrieve('expired', params);
	},

	getByRespondentId (respondentId) {
		return this.retrieve('byRespondentId', respondentId);
	},

	getMy () {
		const collection = this.retrieve('myList');
		collection.parse = (response) => response.assessmentInstance;
		return collection;
	},

	getUnseen (params) {
		return this.retrieve('unseenAssessmentInstances', params);
	},

	getByTreatmentId (treatmentId) {
		const assessmentInstances = this.retrieve('treatmentAssessmentInstances', treatmentId);
		assessmentInstances.canAdd = function (model) {
			return +model.get('treatment') === +this.ownerId;
		};
		return assessmentInstances;
	},

	getOwnedByCurrentClinician (params = {}) {
		const collection = this.retrieve('ownedByCurrentClinician', params);

		// order by: not completed first, then descending by lastModified
		collection.comparator = function (a, b) {
			const compareCompleted = function (a, b) {
				return (+a.isCompleted()) - (b.isCompleted());
			};

			const compareLastChanged = function (a, b) {
				a.lastModifiedDate = a.getLastModifiedDate() || { unixtime: 0 };
				b.lastModifiedDate = b.getLastModifiedDate() || { unixtime: 0 };
				return b.lastModifiedDate.unixtime - a.lastModifiedDate.unixtime; // reverse order
			};

			return compareCompleted(a, b) || compareLastChanged(a, b);
		};

		return collection;
	}
});

export default new AssessmentInstancesEntity();
