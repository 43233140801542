import repository from 'repository';
import prefixify from '../__helpers/prefixify';

const prefix = prefixify('treatmentPrograms');

const CREATE = 'create';

export const TREATMENT_PROGRAMS = {
	CREATE: prefix(CREATE)
};

export default {
	namespaced: true,

	actions: {
		[CREATE]: async (ctx, data) =>
			await repository.createTreatmentProgram(data)
	}

};
