import repository from 'repository';
import prefixify from '../__helpers/prefixify';
import { set } from '../__helpers/mutations';
import { exact } from '../__helpers/getters';

const prefix = prefixify('respondentContentPackage');

const DATA = 'data';
const INIT = 'init';

const SET = 'set';

export const RESPONDENT_CONTENT_PACKAGE = {
	DATA: prefix(DATA),

	INIT: prefix(INIT)
};

export default {
	namespaced: true,

	state: {
		[DATA]: {}
	},

	getters: {
		...exact([DATA])
	},

	mutations: {
		[SET]: set()
	},

	actions: {
		[INIT]: async ({ commit }, { contentPackageId }) => {
			const contentPackage = await repository.contentPackage(contentPackageId);
			commit(SET, contentPackage);
		}
	}
};
