import MultipleEntities from '../shared/multiple-entities';
import LinkCategory from './category';
import urls from './urls';

/**
 * LinkCategories
 * @class LinkCategories
 * @extends MultipleEntities
 */
const LinkCategories = MultipleEntities.extend({
	model: LinkCategory,
	urls,
	eventNamespace: 'link-category',

	/**
	 * Get all link categories
	 * @method getAll
	 * @return {Object} new {{#crossLink "LinkCategories"}}{{/crossLink}} Collection
	 * @example
	 *        Repository.LinkCategories.getAll();
	 */
	getAll: function () {
		return this.retrieve('listAllCategories');
	}
});

export default new LinkCategories();
