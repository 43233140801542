import { cloneDeep, findIndex, isArray } from 'lodash';

export const idx = (arr, id) => findIndex(arr, { id });

export const set = (dest = 'data') => (state, payload) => {
	state[dest] = payload;
};

export const reset = (dest = 'data') => (state) => {
	state[dest] = isArray(state[dest]) ? [] : {};
};

export const add = (dest = 'data') => (state, item) => {
	isArray(item) ?
		state[dest].push(...item) :
		state[dest].push(item);
};

export const update = (dest = 'data') => (state, item) => {
	const cloned = cloneDeep(state[dest]);
	cloned[idx(cloned, item.id)] = item;
	state[dest] = cloned;
};

export const updateHashMap = (dest = 'data') => (state, { id, payload }) => {
	state[dest] = {
		...state[dest],
		[id]: payload
	};
};

export const remove = (dest = 'data') => (state, { item, index }) => {
	const i = item ? idx(state[dest], item.id) : index;
	state[dest].splice(i, 1);
};
