import Handlebars from 'handlebars';
import { forEach, replace } from 'lodash';
import $ from 'jquery';
import cardurl from 'cwcardurl';
import t from 'translate';
import icon from 'service/icon';
import moment from 'moment';
import acl from 'acl';
import executeIfFunction from 'execute-if-function';

const helpers = {
	cardUrl: cardurl.buildUrl,
	t: (messages) => executeIfFunction(t(messages)),
	icon: (iconName, title, small, className) => {
		const options = {
			title: title ? t(title) : '',
			small: !!small,
			className
		};

		const $icon = $('<div />').html(icon(iconName, options));
		return $icon.html();
	},

	nl2br: (string) => {
		const str = Handlebars.Utils.escapeExpression(string);
		const nl2br = replace(str, /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br />' + '$2');
		return new Handlebars.SafeString(nl2br);
	},

	dateFormat: (date, format, useTranslations) =>
		moment(date).format(useTranslations ? t(format) : format),

	ifCan: (operation, checkpoint, options) => {
		if (acl.checkAccess({ op: operation, checkpoint })) {
			return options.fn(this);
		}
	}
};

export default () => {
	forEach(helpers, (helperFn, helperName) => {
		Handlebars.registerHelper(helperName, helperFn);
	});
};
