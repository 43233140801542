import Modernizr from 'modernizr';
import $ from 'jquery';
import { filter, hasIn, isEmpty, keys, map, pick } from 'lodash';

export default () => {
	Modernizr.addTest('isiOS', () => /(iPad|iPhone|iPod)/.test(navigator.userAgent));

	Modernizr.addTest('fontFaceWithPseudoElements', () => !$('html').is('.lt-ie9'));

	// IE8-9 doesn't allow for JavaScript upload control
	Modernizr.addTest('standardFileUpload', () => !$('html').is('.lt-ie10'));

	Modernizr.addTest('webRTC', () => {
		const prefixes = ['', ...Modernizr._domPrefixes];
		const rtc = keys(pick(window,
			map(prefixes, (prefix) => `${prefix}RTCPeerConnection`)));

		if (!rtc.length) {
			return false;
		}

		return !isEmpty(filter(rtc, (prop) => {
			const PeerConnectionConstructor = window[prop];

			if (PeerConnectionConstructor) {
				const peerConnection = new PeerConnectionConstructor(null);
				return hasIn(peerConnection, 'createDataChannel');
			}

			return false;
		}));
	});

	Modernizr.addTest('mutationobserver', () =>
		!!window.MutationObserver || !!window.WebKitMutationObserver);
};
