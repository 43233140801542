var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "form",
    {
      staticClass: "consent-content",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.props.onSubmit($event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "consent-content__content" },
        [_vm._t("content")],
        2
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "consent-option__button-container" }, [
        _vm.props.submittable
          ? _c(
              "button",
              {
                directives: [{ name: "translate", rawName: "v-translate" }],
                staticClass:
                  "consent-option__button consent-option__button--submit",
                attrs: { type: "submit" }
              },
              [_vm._v("consents.submit")]
            )
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function(_h, _vm) {
    var _c = _vm._c
    return _vm._l(_vm.props.options, function(option, i) {
      return _c("ul", { key: i, staticClass: "consent-content__options" }, [
        _c("li", { staticClass: "consent-content__option option" }, [
          _c(
            "p",
            { staticClass: "option__content" },
            [_vm._t("optionContent", null, { content: option.content })],
            2
          ),
          _vm._v(" "),
          _c(
            "label",
            [
              _vm._t(
                "checkbox",
                [
                  _c("input", {
                    staticClass: "option__checkbox",
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: option.answer && option.answer.answer
                    },
                    on: {
                      change: function($event) {
                        return _vm.props.onOptionToggle({
                          option: option,
                          index: i
                        })
                      }
                    }
                  })
                ],
                { option: option }
              ),
              _vm._v(" "),
              _c("span", { staticClass: "option__statement" }, [
                _vm._v(
                  '\n\t\t\t\t\t"' + _vm._s(option.statement) + '"\n\t\t\t\t'
                )
              ])
            ],
            2
          )
        ])
      ])
    })
  }
]
render._withStripped = true

export { render, staticRenderFns }