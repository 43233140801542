import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import Context from './context';

const ContextsEntity = MultipleEntities.extend({
	urls,
	model: Context,
	idName: 'assessmentInstanceContextId',
	eventNamespace: 'assessment-context',

	getAll () {
		return this.retrieve('allContexts');
	},

	getLabelForId (id) {
		return this.get(id) ? this.get(id).getLabel() : '';
	}
});

export default new ContextsEntity();
