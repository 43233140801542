import { clone, find, forEach, get, includes, isArray, isFunction, keys, replace } from 'lodash';
import { Deferred } from 'jquery';
import repository from 'repository';
import { TREATMENT_TYPE_ID } from './variant-criteria';
import acl from 'service/acl/acl';
import { CLINICIAN } from 'service/acl/checkpoints.json';
import { READ } from 'service/acl/access-levels';

const variantsHandlers = {
	[TREATMENT_TYPE_ID]: {
		action: acl.checkAccess({
			op: READ,
			checkpoint: CLINICIAN.RESPONDENTS.TREATMENTS
		}) && 'treatmentTypes/initForCurrentClinician',
		getterName: 'treatmentTypes/sortedByName',
		getterTransform: (treatmentType) => treatmentType.id
	}
};

export default {
	state: {
		actions: [],
		promises: {},
		dfd: {},
		counts: {},
		searchQueries: {},
		variantIndex: null,
		autoVariant: false
	},

	getters: {
		actionsCount: (state) => {
			const count = {};

			forEach(state.actions, (actionsDefinition) => {
				const actionName = isArray(actionsDefinition.action) ?
					actionsDefinition.action[0] :
					actionsDefinition.action;

				if (!count[actionName]) {
					count[actionName] = 1;

				} else {
					count[actionName]++;
				}
			});

			return count;
		},

		searchQuery: (state, getters, rootState) =>
			get(state.searchQueries, `[${rootState.cardData.cardId}]`),

		// eslint-disable-next-line max-params
		cardDefinition: (state, getters, rootState, rootGetters) => {
			const name = get(rootState, 'cardData.cardId');
			const card = find(rootGetters['engine/cards/current'], { 'card-name': name });
			return card || {};
		},

		variantIndex: (state, getters) => {
			if (state.variantIndex !== null) {
				return state.variantIndex;
			}

			const name = getters.cardDefinition.name;
			const variants = JSON.parse(window.localStorage.getItem('cardVariants'));
			const savedIndex = get(variants, `card:${name}`, null);
			const variantExists = get(getters.cardDefinition, `alternativeCards[${savedIndex}]`);

			return savedIndex !== null && (variantExists || state.autoVariant) ? +savedIndex : -1;
		},

		variantActions: (state, getters) => {
			const variants = getters.cardDefinition.alternativeCards;
			const actions = [];

			if (variants) {
				forEach(variants, (variant) => {
					forEach(keys(variant.when), (criterion) => {
						const action = get(variantsHandlers[criterion], 'action');

						if (action && !includes(actions, action)) {
							actions.push(action);
						}
					});
				});
			}

			return actions;
		},

		variantGetters: (state, getters) => {
			const variants = getters.cardDefinition.alternativeCards;
			const variantGetters = [];

			if (variants) {
				forEach(variants, (variant) => {
					forEach(keys(variant.when), (criterion) => {
						const getter = {
							[criterion]: {
								name: get(variantsHandlers[criterion], 'getterName'),
								transform: get(variantsHandlers[criterion], 'getterTransform')
							}
						};

						getter && variantGetters.push(getter);
					});
				});
			}

			return variantGetters;
		},

		autoVariant: (state) => state.autoVariant
	},

	mutations: {
		addActionsAndPromises: (state, { source, actions, type, fireDispatch }) => {
			forEach(actions, (action) => {
				const promise = fireDispatch(action);

				if (!state.promises[source]) {
					state.promises[source] = [];
				}
				state.promises[source].push(promise);
				state.actions.push({ source, action, type });
			});
		},

		addAction: (state, { source, action, type }) => {
			state.actions.push({ source, action, type });
		},

		setDfd: (state, { actionName, reset }) => {
			if (!state.dfd[actionName] || reset) {
				state.dfd[actionName] = Deferred();
			}
		},

		addToCounts: (state, actionName) => {
			if (!state.counts[actionName]) {
				state.counts[actionName] = 1;

			} else {
				state.counts[actionName]++;
			}
		},

		resetCounts: (state, actionName) => {
			state.counts[actionName] = 0;
		},

		clear: (state) => {
			state.actions = [];
			state.promises = {};
			state.counts = {};
			state.dfd = {};
			state.variantIndex = null;
		},

		setSearchQuery: (state, { cardName, query }) => {
			state.searchQueries[cardName] = query;
		},

		setVariantIndex: (state, variantIndex) => {
			state.variantIndex = variantIndex;
		},

		setAutoVariant: (state, bool) => {
			state.autoVariant = bool;
		}
	},

	actions: {
		clearCurrentCard: ({ commit }) => {
			commit('clear');
		},

		addCurrentCardActions: (
			{ commit, dispatch, getters, state },
			{ source, type, actions = [] }
		) => {
			const actionsToAdd = isFunction(actions) ? actions() : clone(actions);

			const wrappedDispatch = (...args) => {
				const instant = get(args[3], 'instant');
				const now = (new Date()).valueOf();
				// make sure that instant action promises are unique
				const actionName = instant ? `${args[0]}:${now}` : args[0];

				commit('setDfd', { actionName });

				const makeDispatch = () => {
					dispatch(...args).then(() => {
						if (!state.dfd[actionName]) {
							return;
						}

						state.dfd[actionName].resolve();
						commit('setDfd', { actionName, reset: true });
					});
				};

				if (instant) {
					commit('addToCounts', replace(actionName, `:${now}`, ''));
					makeDispatch();

				} else {
					setTimeout(() => {
						commit('addToCounts', actionName);

						if (state.counts[actionName] === getters.actionsCount[actionName]) {
							makeDispatch();
						}
					}, 100);
				}

				return state.dfd[actionName];
			};

			const fireDispatch = (action) => {
				if (isArray(action)) {
					return wrappedDispatch(...action);
				}
				return wrappedDispatch(action);
			};

			if (!actions.length) {
				commit('addAction', { action: '', source, type });

			} else {
				commit(
					'addActionsAndPromises',
					{ actions: actionsToAdd, fireDispatch, source, type }
				);
			}
		},

		setSearchQuery: ({ commit, rootState }, { cardName, query }) => {
			commit('setSearchQuery', { cardName: cardName || rootState.cardData.cardId, query });
		},

		setVariantIndex: ({ commit, getters }, variantIndex) => {
			const name = getters.cardDefinition.name;
			const cardVariants = JSON.parse(window.localStorage.getItem('cardVariants'));
			cardVariants[`card:${name}`] = variantIndex;

			commit('setVariantIndex', variantIndex);
			window.localStorage.setItem('cardVariants', JSON.stringify(cardVariants));

			return repository.saveCardVariants({ cardVariants });
		},

		setAutoVariant: ({ commit }, bool) => {
			commit('setAutoVariant', bool);
		}
	}
};
