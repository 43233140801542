<template>
	<ul>
		<li
			v-for="(row, index) in objVal"
			:key="index"
			class="edit-object__row edit-object__row--edit"
		>
			<p class="array-row__index">
				{{index}}
			</p>
			<input
				v-for="i in [0,1]"
				:key="i"
				type="number"
				:value="row[i]"
				:readonly="readonly"
				:min="matrixMin[i]"
				:max="matrixMax[i]"
				@blur="onMatrixValueChange(index, i, $event)"
			/>
			<button
				v-if="!readonly"
				class="edit-object__button--remove"
				@click="removeMatrixRow(index)"
			/>
		</li>
		<li class="edit-object__row edit-object__row--add">
			<input
				v-model="newMatrixRow[0]"
				type="number"
				:readonly="readonly"
			/>
			<input v-model="newMatrixRow[1]" type="number" :readonly="readonly" />
			<button v-if="!readonly" class="edit-object__button--add" @click="addMatrixRow" />
		</li>
	</ul>
</template>

<script>
import { cloneDeep, get, map } from 'lodash';
import inputMixin from './__input-mixin';
import Vue from 'vue';

export default Vue.component('matrixInput', {
	mixins: [inputMixin],
	props: {
		config: {
			type: Object,
			default: () => ({})
		},
		objVal: {
			type: Array,
			required: true
		}
	},
	data: () => ({
		newMatrixRow: [0, 0]
	}),
	computed: {
		matrixMin () {
			return get(this.config, 'matrixMin', [0, 0]);
		},

		matrixMax () {
			return get(this.config, 'matrixMax', [Infinity, Infinity]);
		}
	},
	methods: {
		onMatrixValueChange (index, i, e) {
			this.objVal[index][i] = +e.target.value;
			this.onValueChange({ path: this.path, value: this.objVal });
		},

		addMatrixRow () {
			const value = cloneDeep(this.objVal);
			value.push(map(this.newMatrixRow, (val) => +val));
			this.onValueChange({ path: this.path, value });
		},

		removeMatrixRow (index) {
			const value = cloneDeep(this.objVal);
			value.splice(index, 1);
			this.onValueChange({ path: this.path, value });
		}
	}
});
</script>
