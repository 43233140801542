var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-view__container" },
    [
      _vm.showFilters
        ? _c("table-filters", {
            attrs: {
              config: _vm.filtersConfig,
              "current-count": _vm.items.length,
              "total-count": _vm.totalCount,
              "tile-path": _vm.tilePath
            },
            on: { change: _vm.onFiltersChange }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.groupedItems, function(group) {
        return _c(
          "div",
          { key: _vm.groupLabel(group), staticClass: "table-view__body" },
          [
            _vm.dataLoading
              ? _c("loader-overlay", { attrs: { label: "" } })
              : _vm._e(),
            _vm._v(" "),
            _vm.groupLabel(group)
              ? _c("h3", { staticClass: "table-view__group-label" }, [
                  _vm._v("\n\t\t\t" + _vm._s(_vm.groupLabel(group)) + "\n\t\t")
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("table-list", {
              staticClass: "table-view",
              attrs: {
                items: group,
                columns: _vm.columns,
                sortable: _vm.tileConfig.sortable,
                "item-value": _vm.itemValue,
                "item-key": _vm.tileConfig.idName,
                "tile-path": _vm.tilePath
              },
              on: { "row-click": _vm.onRowClick },
              scopedSlots: _vm._u(
                [
                  {
                    key: "cell",
                    fn: function(ref) {
                      var column = ref.column
                      var content = ref.content
                      var item = ref.item
                      return [
                        _c(_vm.component(column), {
                          tag: "component",
                          attrs: {
                            column: column,
                            item: item,
                            "tile-config": _vm.tileConfig
                          },
                          on: {
                            change: function($event) {
                              return _vm.onChange($event, {
                                column: column,
                                content: content,
                                item: item
                              })
                            }
                          }
                        })
                      ]
                    }
                  }
                ],
                null,
                true
              )
            }),
            _vm._v(" "),
            _vm.items.length < _vm.totalCount
              ? _c("more-results", { on: { more: _vm.moreResults } })
              : _vm._e()
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }