import MultipleEntities from '../shared/multiple-entities';
import PageCategory from './category';
import urls from './urls';

/**
 * PageCategories
 * @class PageCategories
 * @extends MultipleEntities
 */
const PageCategories = MultipleEntities.extend({
	model: PageCategory,
	urls,
	eventNamespace: 'page-category',

	/**
	 * Get all pages categories
	 * @method getAll
	 * @return {Object} new {{#crossLink "PagesCategories"}}{{/crossLink}} Collection
	 * @example
	 *        Repository.PageCategories.getAll();
	 */
	getAll: function () {
		return this.retrieve('listAllCategories');
	}
});

export default new PageCategories();
