var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { class: _vm.mainClassName },
    [
      _c("agenda-items", {
        attrs: {
          collapsed: _vm.collapsed,
          items: _vm.items,
          direction: _vm.direction,
          "store-path": _vm.storePath
        }
      }),
      _vm._v(" "),
      _c("agenda-add-item", {
        attrs: { datetime: _vm.datetime, scope: _vm.scope, items: _vm.addable }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }