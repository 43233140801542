import informationPackagesCounter from './information-packages-counter/tile';
import informationPackagesList from './information-packages-list';
import informationPackageElementsList from './information-package-elements-list';
import exercise from './exercise';
import exercisesList from './exercises-list';
import pagesList from './pages-list';
import mediaList from './media-list';
import linksList from './links-list';

export default {
	'information-packages-counter': informationPackagesCounter,
	'information-packages-list': informationPackagesList,
	'information-package-elements-list': informationPackageElementsList,
	exercise,
	'exercises-list': exercisesList,
	'pages-list': pagesList,
	'media-list': mediaList,
	'links-list': linksList
};
