var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.cpElement.exercises.length
    ? _c("div", [
        _vm.isFinished
          ? _c(
              "a",
              {
                staticClass:
                  "exercise__run flat-button__button flat-button__button--submit",
                attrs: { href: _vm.mainUrl }
              },
              [_vm._v("\n\t\t" + _vm._s(_vm.$t("Repeat exercise")) + "\n\t")]
            )
          : _c(
              "a",
              {
                staticClass:
                  "exercise__run flat-button__button flat-button__button--submit",
                attrs: { href: _vm.mainUrl }
              },
              [_vm._v("\n\t\t" + _vm._s(_vm.$t("Finish exercise")) + "\n\t")]
            ),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "generic-list__list exercise__list" },
          _vm._l(_vm.cpElement.exercises, function(exercise) {
            return _c(
              "li",
              {
                key: exercise.id,
                class: [
                  "generic-list__item",
                  "exercise__list-element",
                  "exercise__list-element--" + exercise.status
                ]
              },
              [
                _c(
                  "a",
                  {
                    staticClass:
                      "generic-list__action exercise__list-element-action",
                    attrs: { href: _vm.exerciseUrl(exercise) }
                  },
                  [
                    _c("icon", {
                      staticClass: "exercise__icon",
                      attrs: { name: "exercise", title: _vm.$t("Exercise") }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "\n\t\t\t\t\t\tgeneric-list__action-title-container\n\t\t\t\t\t\texercise__action-title-container\n\t\t\t\t\t"
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "generic-list__action-title exercise__creation-date"
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.createdAt(exercise)) +
                                "\n\t\t\t\t\t"
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "exercise__status" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.statusLabel(exercise)) +
                              "\n\t\t\t\t\t"
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          }),
          0
        )
      ])
    : _c("div", [
        _c(
          "a",
          {
            staticClass:
              "exercise__run flat-button__button flat-button__button--submit",
            attrs: { href: _vm.mainUrl }
          },
          [_vm._v("\n\t\t" + _vm._s(_vm.$t("Start exercise")) + "\n\t")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "exercise__empty-list-notify" }, [
          _vm._v(
            "\n\t\t" +
              _vm._s(_vm.$t("You haven’t done this exercise yet.")) +
              "\n\t"
          )
        ])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }