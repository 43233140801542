import { fixedPips, numericPips } from './pips';

const fixedOptions = ({
	connect, endLabelsVisible, getUrl, labelsVisible, orderedAnswers, start
}) => {
	const showPips = labelsVisible || endLabelsVisible;

	const options = {
		range: {
			min: 0,
			max: orderedAnswers.length - 1
		},
		connect,
		start,
		step: 1,

		format: {
			to: (value) => orderedAnswers[value],
			from: (value) => orderedAnswers.indexOf(value)
		},
		pips: showPips && fixedPips({ endLabelsVisible,	getUrl,	labelsVisible,	orderedAnswers })
	};

	return options;
};

const numericOptions = ({ connect, displayValues, getUrl, ...model }) => {
	const {
		digitalPoints,
		endLabelsVisible,
		labelsVisible,
		max,
		min,
		start,
		valueLabels,
		valueStep
	} = model;
	const noLabels = !(labelsVisible || endLabelsVisible);

	const options = {
		range: { min, max },
		step: valueStep,
		connect,
		start
	};

	if (!noLabels || valueLabels.length || digitalPoints) {
		options.pips = numericPips({
			displayValues,
			getUrl,
			...model
		});
	}

	return options;
};

export { fixedOptions, numericOptions };
