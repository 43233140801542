import Backbone from 'backbone';

export default Backbone.Collection.extend({
	model: Backbone.Model.extend({
		initialize () {
			if (!this.get('name')) {
				this.set('name', this.get('key'));
			}
		},
		idAttribute: 'name'
	})
});
