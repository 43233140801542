var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "edit-object__container" },
    [
      _vm._l(_vm.objVal, function(propVal, propKey, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass: "edit-object__row edit-object__row--edit"
          },
          [
            _c(
              "input",
              _vm._g(
                {
                  directives: [{ name: "trim", rawName: "v-trim" }],
                  attrs: { type: "text", readonly: _vm.readonly },
                  domProps: { value: propKey },
                  on: {
                    blur: function($event) {
                      return _vm.onObjKeyChange(propKey, $event)
                    }
                  }
                },
                _vm.inputListeners
              )
            ),
            _vm._v(" "),
            _c("edit-object", {
              attrs: {
                "obj-key": propKey,
                "obj-val": propVal,
                "on-change": _vm.onObjValueObjectChange,
                readonly: _vm.readonly,
                "initial-path": _vm.path,
                "allow-toggle": _vm.isObject(propVal),
                label: ""
              }
            }),
            _vm._v(" "),
            !_vm.readonly
              ? _c("button", {
                  staticClass: "edit-object__button--remove",
                  on: {
                    click: function($event) {
                      return _vm.removeObjectRow(propKey)
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("li", { staticClass: "edit-object__row edit-object__row--add" }, [
        _c("div", [
          _c(
            "input",
            _vm._g(
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newKey,
                    expression: "newKey"
                  },
                  { name: "trim", rawName: "v-trim" }
                ],
                attrs: { type: "text", readonly: _vm.readonly },
                domProps: { value: _vm.newKey },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.newKey = $event.target.value
                  }
                }
              },
              _vm.inputListeners
            )
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "input",
            _vm._g(
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newVal,
                    expression: "newVal"
                  }
                ],
                attrs: { type: "text", readonly: _vm.readonly },
                domProps: { value: _vm.newVal },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.newVal = $event.target.value
                  }
                }
              },
              _vm.inputListeners
            )
          )
        ]),
        _vm._v(" "),
        !_vm.readonly
          ? _c("button", {
              staticClass: "edit-object__button--add",
              on: { click: _vm.addObjectRow }
            })
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }