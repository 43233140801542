var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isEmpty
    ? _c("icon", {
        attrs: {
          small: "",
          title: _vm.column.label,
          name: _vm.name,
          color: _vm.color
        }
      })
    : _c("span", [_vm._v(_vm._s(_vm.emptyChar))])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }