import codes from '../../modules/circles/avatars/codes';
import repository from 'repository';
import { assign, omit } from 'lodash';

export default {
	namespaced: true,
	state: {
		avatarCodes: codes(),
		avatarColour: '#000000',
		avatarName: 'avatar-1',
		nickname: '',
		model: {},
		loading: false,
		ready: false
	},

	getters: {
		profileData: (state) => omit(state, 'loading', 'model', 'avatarCodes', 'ready')
	},

	mutations: {
		set: (state, payload) => {
			assign(state, payload);
		},
		setModel: (state, model) => {
			state.model = model;
		},
		setReady: (state) => {
			state.ready = true;
		}
	},

	actions: {
		init: ({ commit, state, rootState }) => {
			if (state.ready) {
				return;
			}
			const profile = repository.memberMe(rootState.user.circleUser.id);
			return profile.then(() => {
				commit('setModel', profile);
				commit('set', profile.toJSON());
				commit('setReady');
			});
		},

		set: ({ commit }, { profile, model }) => {
			if (model) {
				commit('setModel', model);
			}
			commit('set', profile);
		},
		save: ({ dispatch, commit, state }, { profile }) => {
			dispatch('set', { profile });
			commit('set', { loading: true });
			state.model.set(profile).save().then(() => {
				commit('set', { loading: false });
			});
		}
	}
};
