import repository from 'repository';
import { map, sortBy } from 'lodash';
import { cleanFlag } from '../__helpers/clean';

export default {
	namespaced: true,

	state: {
		data: []
	},

	getters: {
		all: (state) => sortBy(
			state.data,
			(flag) => flag.name.toLowerCase()
		)
	},

	mutations: {
		set: (state, flags) => {
			state.data = map(flags, cleanFlag);
		}
	},

	actions: {
		init: ({ commit }) => repository.allAssessmentFlags().then((flags) => {
			commit('set', flags);
		})
	}
};
