import Modernizr from 'modernizr';
import layoutAdjustment from '../layout-adjustment';
import $ from 'jquery';
import UploadedFiles from '../../entities/assessment-instance-uploaded-files-by-assessment';
import UploadedFile from '../../entities/assessment-instance-uploaded-file';
import Backbone from 'backbone';
import Table from 'components/table/table';
import cwalert from 'cwalert';
import t from 'translate';
import uniqueId from 'service/unique-id';
import appContext from 'app-context';
import confirmation from 'components/confirmation/confirmation';
import { QUESTION_TAG_NAME } from '../../config';

const buttonClasses = {
	disabled: 'assessment-upload__button--disabled'
};

const Attachment = function (runtime, assessment) {
	// Modernizr.standardfileupload;

	const $el = function (tagName, className) {
		return $('<' + tagName + ' />').addClass(className);
	};

	const $div = function (className) {
		return $el('div', className);
	};
	const $span = function (className) {
		return $el('span', className);
	};

	const urlRoot = Backbone.config().backendRootUrl['default'] || '';
	const $box = $div('assessment-container assessment-attachment-container').attr('id', runtime.getElementId());

	const box = $box[0];
	const assessmentInstanceId = assessment.getInstanceId();
	const assessmentQuestionCode = runtime.getCode();
	const $questionText = $(`<${QUESTION_TAG_NAME} class="questiontext" />`).appendTo($box);
	$span('question').html(runtime.getText()).appendTo($questionText);

	const $fileuploadInput = $('<input type="file" />')
		.addClass('theupload')
		.attr({
			'name': 'files[]',
			'data-url': urlRoot + new UploadedFile().url(),
			'multiple': (runtime.getMaxFiles() > 1)
		});
	const $fileuploadInputBox = $div('file-upload__button-box').html($fileuploadInput);
	const $cover = $div('file-upload__button-cover');
	Modernizr.standardfileupload && $cover.appendTo($fileuploadInputBox);
	const $uploadButton = $('<button />')
		.addClass('assessment-upload__button assessment-upload__button--upload')
		.html(t('Upload a file'))
		.appendTo($cover)
		.on('click', function () {
			$('input[type=file]', $fileuploadInputBox).trigger('click');
		});

	if (assessment.isReadonlyMode()) {
		$uploadButton
			.prop('disabled', true)
			.addClass(buttonClasses.disabled);
	}

	this.getElementBox = function () {
		return box;
	};

	if (!runtime.isVisible()) {
		$box.hide();
	}

	layoutAdjustment(runtime, box);

	/*** COLLECTION ***/
	const assessmentInstanceUploadedFiles = new UploadedFiles({
		assessmentInstanceId,
		assessmentInstanceCode: assessmentQuestionCode
	});

	assessmentInstanceUploadedFiles.fetch();

	/*** VIEWS ***/
	const $fileuploadContainer = $div('questionanswers');

	//Disabled - enable upon collection sync if applicable
	$fileuploadInput.prop('disabled', true);

	const $fileuploadInfo = $div('fileupload fileupload-information').append([
		$('<p />').html(
			t('assessment.upload.allowedfiletypes', {
				filetypes: '<i>' + runtime.getFileExtensions().join(', ') + '</i>'
			})
		),
		$('<p />')
			.addClass('upload-limit-info')
			.html(t('assessment.upload.maxfiles', { num: runtime.getMaxFiles() }))
	]);

	const $fileuploadProgressbar = $div('fileupload fileupload-progressbar').attr({
		'role': 'progressbar',
		'aria-valuemin': 0,
		'aria-valuenow': 0,
		'aria-valuemax': 100
	});

	const $fileuploadProgressbarContainer = $div('fileupload fileupload-progressbar-container')
		.append($fileuploadProgressbar)
		.hide();

	const $fileuploadStatus = $span('fileupload fileupload-status').hide();

	const fileuploadFilelist = new (Backbone.View.extend({
		tagName: 'div',
		className: 'fileupload fileupload-filelist',
		initialize () {
			this.table = new Table({
				parent: this.el,
				css: 'upload-list assessment-upload__table',

				itemsType (params) {
					params.get = function (item, key) {
						return item.get(key);
					};
					params.set = function (item, key, value) {
						return item.set(key, value);
					};
				},

				items: assessmentInstanceUploadedFiles.models,
				emptyListMessage: t('assessment.upload.noattachments'),

				columns: [{
					label: t('assessment.upload.file.filename'),
					key: 'uploadFileName'
				}, {
					label: t('assessment.upload.file.filesize'),
					render () {
						return this.item.getFileSize(true);
					}
				}, {
					label: t('assessment.upload.file.filedate'),
					render () {
						return this.item.getFileDate(true) || t('assessment.upload.file.uploading', 'Uploading..');
					}
				}, {
					label: t('assessment.upload.file.download'),
					render () {
						$(this.td).addClass('action');
						const attachmentUpload = this.item;
						const temporary = attachmentUpload.get('temp');
						const $buttonDownload = $('<a />')
							.html(t('general-list.Download'))
							.addClass('assessment-upload__button assessment-upload__button--download')
							.attr({
								'data-file-id': attachmentUpload.getId(),
								'data-file-download': '',
								'href': Backbone.config().backendRootUrl['default'] + attachmentUpload.getDownloadURL()
							});

						temporary && $buttonDownload.addClass(buttonClasses.disabled).prop('disabled', true);

						return $buttonDownload[0];
					}
				}, {
					label: t('assessment.upload.file.delete'),
					skip: assessment.isReadonlyMode(),
					render () {
						$(this.td).addClass('action');
						const attachmentUpload = this.item;
						const temporary = attachmentUpload.get('temp');

						const $buttonDelete = $('<button />')
							.html(t('general-list.Delete'))
							.addClass('assessment-upload__button assessment-upload__button--delete')
							.attr('data-file-id', this.item.getId())
							.attr('data-file-delete', '')
							.on('click', function () {
								confirmation({
									icon: 'delete',
									warning: true,
									title: t('Delete attachment'),
									message: t('assessment.upload.delete.confirm')
								}).done(function () {

									$(this).prop('disabled', true).addClass(buttonClasses.disabled);

									attachmentUpload.destroy({
										success (model) {
											cwalert.success(
												t('assessment.upload.delete.success', {
														filename: model.getFileName()
													}
												)
											);
											appContext.trigger('assessment-instance.updateById', assessmentInstanceId);
										},
										error (model) {
											const errmsg = t('assessment.upload.delete.error', {
													filename: model.getFileName()
												}
											);
											cwalert.error(errmsg);
											$fileuploadStatus.text();

											$(this).prop('disabled', false).removeClass(buttonClasses.disabled);
										}
									});
								}.bind(this));
							});

						temporary && $buttonDelete.addClass(buttonClasses.disabled).prop('disabled', true);

						return $buttonDelete[0];
					}
				}]
			});
		},
		render () {
			this.table.refresh();
		}
	}));

	const $fileuploadFilesize = $span('fileupload fileupload-totalfilesize');

	assessmentInstanceUploadedFiles.on('sync add remove change', function () {
		fileuploadFilelist.render();

		if (assessmentInstanceUploadedFiles.length >= runtime.getMaxFiles()) {
			$('.upload-limit-info', $fileuploadContainer).addClass('highlight');
			$('input.theupload', $fileuploadContainer).prop('disabled', true);
			$uploadButton.addClass(buttonClasses.disabled);

		} else {
			$('.upload-limit-info', $fileuploadContainer).removeClass('highlight');
			$('input.theupload', $fileuploadContainer).prop('disabled', false);
			$uploadButton.toggleClass(buttonClasses.disabled, assessment.isReadonlyMode());
		}
		$fileuploadFilesize.text(t('Storage used {used} out of {total}', {
			used: assessmentInstanceUploadedFiles.getTotalFileSize(true),
			total: runtime.getMaxFileSize() + 'kB'
		}));
	});

	$fileuploadInput.on('change', function () {
		const val = ($(this).val()) ? $(this).val() : ('assessment.upload.nofileselected');

		$(this).attr('placeholder', val);
	});

	/*** UPLOADER COMPONENT ***/
	$fileuploadInput.fileupload({
		dataType: 'json',
		add (e, data) {
			const filename = data.files[0].name;
			const filesize = data.files[0].size;

			//This little oneliner extracts the extension safely:
			const ext = filename.substr((~-filename.lastIndexOf('.') >>> 0) + 2);

			if (!runtime.isFileTypeAllowed(ext)) {
				cwalert.warning(t('assessment.upload.unsupportedFileType', {
					files: runtime.getFileExtensions().join(', ')
				}));
				return false;
			}

			if (assessmentInstanceUploadedFiles.models.length >= runtime.getMaxFiles()) {
				cwalert.warning(t('assessment.upload.fileLimitReached', {
					num: runtime.getMaxFiles()
				}));
				return false;
			}

			if (assessmentInstanceUploadedFiles.getTotalFileSize() + filesize >=
				runtime.getMaxFileSize() * 1024) {
				cwalert.warning(t('assessment.upload.filesizeLimitReached', {
					num: runtime.getMaxFileSize()
				}));
				return false;
			}

			const tempId = uniqueId();
			// Create temporary file object
			const fileInstance = new UploadedFile({
				uploadFileName: filename,
				uploadSize: filesize,
				uploadFileId: tempId,
				temp: tempId
			});

			assessmentInstanceUploadedFiles.add(fileInstance);

			data._fileRef = tempId;

			data.formData = {
				assessmentInstanceId,
				assessmentQuestionCode
			};

			data.submit();
		},

		start () {
			$fileuploadStatus.show();
			$fileuploadProgressbarContainer.show();
		},

		progressall (e, data) {
			const progress = parseInt(data.loaded / data.total * 100, 10);
			$fileuploadStatus.text(t('assessment.upload.complete', { percent: progress }));
			$fileuploadProgressbar.css('width', progress + '%');
			$fileuploadProgressbar.attr('aria-valuenow', progress);

			if (data.loaded == data.total) {
				cwalert.success(t('assessment.upload.completed'));
				$fileuploadStatus.text('');
				$fileuploadProgressbarContainer.animate({
					opacity: 0
				});
				appContext.trigger('assessment-instance.updateById', assessmentInstanceId);
			}
		},

		done (e, data) {
			const tempId = data._fileRef;

			if (data.result) {
				const fileId = data.result.data.uploadFileId;
				assessmentInstanceUploadedFiles.get(tempId).set('uploadFileId', fileId).unset('temp', {
					silent: true
				}).fetch();
			} else {
				assessmentInstanceUploadedFiles.remove(tempId);
				const errmsg = t('assessment.upload.error');
				cwalert.error(errmsg);
				$fileuploadStatus.text(errmsg);
			}
		},

		fail (e, data) {
			/** @TODO: Add meaningful error message from server **/
			const tempId = data._fileRef;
			assessmentInstanceUploadedFiles.remove(tempId);
			const errmsg = t('assessment.upload.error');
			cwalert.error(errmsg);
			$fileuploadStatus.text(errmsg);
		}
	});

	/*** CREATE FINAL VIEW ***/
	$fileuploadContainer.append([
		$fileuploadInfo,
		$fileuploadInputBox,
		$fileuploadStatus,
		$fileuploadProgressbarContainer,
		fileuploadFilelist.$el,
		$fileuploadFilesize
	]);

	$box.append($fileuploadContainer);
};

export default Attachment;
