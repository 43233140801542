import appUrl from 'service/app-url/app-url';

export default ({ runtime, $container }) => {
	const bg = runtime.getBackground();

	$container.css({
		'background-image': `url(${appUrl(bg.url)})`,
		'background-position': bg.position,
		'background-repeat': {
			repeatX: 'repeat-x',
			repeatY: 'repeat-y',
			noRepeat: 'no-repeat'
		}[bg.repeat]
	});
};
