import t from 'translate';
import { Collection } from 'backbone';
import { assign, clone, concat, forEach, isEmpty, map, some } from 'lodash';

export default {
	namespaced: true,
	state: {
		id: '',
		results: new Collection(),
		values: {},
		keys: {},
		filterControls: false,
		focusedFilter: null,
		isFilterToggled: {},
		isSomethingSelected: false,
		validity: {}
	},

	getters: {
		resultsCount: (state) => {
			if (!state.results.length) {
				return t('No records');
			}

			return state.results.total ?
				t('{current} of {total} records displayed', {
					current: state.results.length,
					total: state.results.total
				}) :
				t('{current} records displayed', {
					current: state.results.length
				});
		},

		anyFilterToggled: (state) => !isEmpty(state.isFilterToggled),

		focusedFilter: (state) => state.focusedFilter
	},

	mutations: {
		setFocusedFilter: (state, type) => {
			state.focusedFilter = type;
		},

		setId: (state, id) => {
			state.id = id;
		},
		setResults: (state, payload) => {
			state.results = payload.results;
		},
		setValues: (state, payload) => {
			state.values = assign({}, state.values, payload);
		},

		setKeys: (state, payload) => {
			state.keys = assign({}, state.keys, payload);
		},

		setValidity: (state, payload) => {
			state.validity = assign({}, state.validity, payload);
		},

		setSomethingSelected: (state) => {
			const invalid = map(state.validity, (fieldName) => ({ invalid: !fieldName.valid }));
			state.isSomethingSelected = some(
				concat(state.values, invalid),
				(field) => field && some(field, (value) => !!value)
			);
		},

		clearValues: (state) => {
			state.values = {};
		},

		clearValidity: (state) => {
			state.validity = {};
		},

		cacheValues: (state) => {
			state.cachedValues = clone(state.values);
		},

		restoreValues: (state) => {
			state.values = clone(state.cachedValues);
		},

		toggleControls: (state, payload) => {
			state.filterControls = payload.filterControls;

			if (payload.filterControls === false) {
				forEach(state.isFilterToggled, (val, key) => {
					state.isFilterToggled[key] = false;
				});
			}
		},

		toggleFilter: (state, payload) => {
			const change = { [payload.label]: payload.toggle };

			state.isFilterToggled = assign({}, state.isFilterToggled, change);
		}
	},

	actions: {
		setId: ({ commit }, id) => {
			commit('setId', id);
		},

		setResults: ({ commit }, payload) => {
			commit('setResults', payload);
		},

		setKeys: ({ commit }, payload) => {
			commit('setKeys', payload);
		},

		setValues: ({ commit }, payload) => {
			commit('setValues', payload);
			commit('setSomethingSelected');
		},

		clearValues: ({ commit }) => {
			commit('clearValues');
			commit('clearValidity');
			commit('cacheValues');
			commit('setSomethingSelected');
		},

		cacheValues: ({ commit }) => {
			commit('cacheValues');
		},

		restoreValues: ({ commit }) => {
			commit('clearValidity');
			commit('restoreValues');
		},

		showControls: ({ commit }) => {
			commit('cacheValues');
			commit('toggleControls', { filterControls: true });
		},

		hideControls: ({ commit }) => {
			commit('toggleControls', { filterControls: false });
		},

		toggleFilter: ({ commit }, payload) => {
			commit('toggleFilter', payload);
			commit('setFocusedFilter', payload.label);
		},

		setValidity: ({ commit }, payload) => {
			commit('setValidity', payload);
			commit('setSomethingSelected');
		}
	}
};
