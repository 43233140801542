<template>
	<section
		class="sidebar-container"
		:class="activeClass"
		@click="handleClick"
		@keyup.prevent.enter.space="handleClick"
		@blur.capture="onBlur"
	>
		<sidebar-items :items="items" :can-access="canAccess" :type="type" />
		<section class="sidebar-logo__container">
			<h3 class="sidebar-logo__image-container">
				<img class="sidebar-logo__image" src="images/checkware.png" alt="CheckWare" />
			</h3>
			<p v-if="i18nReady" class="sidebar-logo__version-text">
				<span v-translate>Version</span> {{appVersion}}
			</p>
		</section>
	</section>
</template>

<script>
import SidebarItems from './sidebar-items';
import config from 'core/config';
import { constant, defer, noop } from 'lodash';

export default {
	components: { SidebarItems },

	props: {
		items: {
			type: Array,
			required: true
		},
		show: {
			type: Boolean,
			default: false
		},
		onClick: {
			type: Function,
			default: noop
		},
		canAccess: {
			type: Function,
			default: constant(true)
		},
		type: {
			type: String,
			default: ''
		}
	},

	computed: {
		i18nReady: ({ $store }) => $store.getters['engine/i18n/initialised'],
		appVersion: () => config().appVersion,
		activeClass: ({ show }) => show ? 'active' : ''
	},

	methods: {
		handleClick () {
			this.onClick();
		},
		onBlur () {
			const isChild = (parent, child) => {
				let node = child.parentNode;

				while (node !== null) {
					if (node === parent) {
						return true;
					}

					node = node.parentNode;
				}

				return false;
			};

			defer(() => {
				if (!isChild(this.$el.parentElement, document.activeElement)) {
					this.$emit('focusout');
				}
			});
		}
	}
};
</script>
