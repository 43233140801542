var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("simple-list", {
    attrs: {
      items: _vm.items,
      "class-name": _vm.className,
      "get-title": _vm.title,
      "get-url": _vm.getUrl,
      content: _vm.content
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }