var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.checkedValue,
        expression: "checkedValue"
      }
    ],
    attrs: { disabled: _vm.disabled, type: "checkbox" },
    domProps: {
      checked: Array.isArray(_vm.checkedValue)
        ? _vm._i(_vm.checkedValue, null) > -1
        : _vm.checkedValue
    },
    on: {
      change: function($event) {
        var $$a = _vm.checkedValue,
          $$el = $event.target,
          $$c = $$el.checked ? true : false
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v)
          if ($$el.checked) {
            $$i < 0 && (_vm.checkedValue = $$a.concat([$$v]))
          } else {
            $$i > -1 &&
              (_vm.checkedValue = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
          }
        } else {
          _vm.checkedValue = $$c
        }
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }