import SingleEntity from '../shared/single-entity';
import urls from './urls';

/**
 * PageCategory
 * @class PageCategory
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	eventNamespace: 'page-category',
	urlRoot: urls.singleCategory.url
});
