var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.addableItems.length
    ? _c(
        "section",
        { class: _vm.mainClassName },
        [
          _c(
            "button",
            {
              class: _vm.elementClassName("action"),
              attrs: { "aria-expanded": _vm.menuToggled },
              on: { click: _vm.toggleMenu }
            },
            [
              _c("icon", {
                class: _vm.elementClassName("action-icon"),
                attrs: { name: "plus" }
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  class: _vm.elementClassName("action-text")
                },
                [_vm._v("Add")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.menuToggled
              ? _c(
                  "ul",
                  {
                    directives: [
                      {
                        name: "on-click-outside",
                        rawName: "v-on-click-outside",
                        value: _vm.hideMenu,
                        expression: "hideMenu"
                      }
                    ],
                    class: _vm.elementClassName("items")
                  },
                  _vm._l(_vm.addableItems, function(item, i) {
                    return _c(
                      "li",
                      { key: i, class: _vm.elementClassName("item") },
                      [
                        _c(
                          "button",
                          {
                            class: _vm.elementClassName("item-action"),
                            on: {
                              click: function($event) {
                                return _vm.addItem(item)
                              }
                            }
                          },
                          [
                            _c("icon", {
                              class: _vm.elementClassName("item-icon"),
                              attrs: { name: item.icon }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                directives: [
                                  { name: "translate", rawName: "v-translate" }
                                ],
                                class: _vm.elementClassName("item-text")
                              },
                              [_vm._v(_vm._s(_vm.title(item)))]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  0
                )
              : _vm._e()
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }