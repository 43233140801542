var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "edit-goal",
    {
      attrs: {
        goal: _vm.goal,
        categories: _vm.categories,
        "on-submit": _vm.onSubmit,
        "on-cancel": _vm.resetGoal,
        treatments: _vm.treatments
      }
    },
    [
      !_vm.respondentId
        ? _c("respondent-search", {
            model: {
              value: _vm.respondent,
              callback: function($$v) {
                _vm.respondent = $$v
              },
              expression: "respondent"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }