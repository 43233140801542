/**
 * Unique Id generator
 * @author Marcin
 * @requires uid
 */
var uniqueId = function () {
	return (Math.random() + '').substr(3);
};

export default uniqueId;
