<template>
	<section :class="mainClassName">
		<h2 :class="elementClassName('title')">
			{{title}}
		</h2>
		<span v-if="loading" v-loader-spinner :class="elementClassName('loader')" />
		<nav :class="elementClassName('controls')">
			<slot v-if="disabled" name="disabled" />
			<h2 :class="elementClassName('controls-header')">
				<button
					:class="elementClassName('controls-prev')"
					:title="$t('Previous')"
					@click="changeRange(ACTIONS.GO_PREV)"
				>
					<icon
						:class="elementClassName('icon-prev')"
						:small="true"
						name="angle-left"
					/>
				</button>
				<button
					:class="elementClassName('controls-text')"
					@click="changeRange(ACTIONS.GO_UP)"
				>{{current}}</button>
				<button
					:class="elementClassName('controls-next')"
					:title="$t('Next')"
					@click="changeRange(ACTIONS.GO_NEXT)"
				>
					<icon
						:class="elementClassName('icon-next')"
						:small="true"
						name="angle-right"
					/>
				</button>
				<button
					:class="toggleFiltersClassName"
					@click="toggleFilters"
				>
					<icon name="filter" />
					<span :class="elementClassName('toggle-filters-text')">{{$t('Filter')}}</span>
				</button>

				<button
					:class="elementClassName('controls-refresh')"
					@click="onRefresh"
				>{{refreshLabel}}</button>
			</h2>
			<form :class="controlsInputsClassName" @submit.prevent>
				<div :class="elementClassName('controls-wrapper')">
					<agenda-filter
						v-if="settings.showTypeSelect !== false"
						:filter-items="filter.types"
						:on-change="onTypeFilterChange"
						i18n-prefix="agenda.filters.type"
						:initial-checked="initialTypes"
					/>
					<agenda-filter
						v-if="settings.showStatusSelect !== false"
						:filter-items="filter.statuses"
						:on-change="onStatusFilterChange"
						i18n-prefix="agenda.filters.status"
						:initial-checked="initialStatuses"
					/>
					<agenda-filter
						v-if="settings.showViewSelect !== false"
						:close-filter="true"
						:filter-items="filter.scopes"
						:on-change="onScopeFilterChange"
						:multi="false"
						:initial-checked="[filteringItem(scope, 'scope')]"
					/>
				</div>
				<div :class="elementClassName('controls-wrapper')">
					<div :class="elementClassName('controls-expand-collapse-container')">
						<button
							v-if="settings.showExpandAllButton !== false"
							:class="elementClassName('controls-expand')"
							@click.stop.prevent="onExpand"
						>{{expandLabel}}</button>
						<button
							v-if="settings.showCollapseAllButton !== false"
							:class="elementClassName('controls-collapse')"
							@click.stop.prevent="onCollapse"
						>{{collapseLabel}}</button>
					</div>
					<div
						v-if="settings.showCompressedModeToggle !== false"
						:class="elementClassName('compress')"
					>
						<input
							:id="cid"
							v-model="compressed"
							type="checkbox"
						/>
						<label
							:for="cid"
							role="button"
							tabindex="0"
							:aria-label="$t('agenda.labels.compress')"
							:aria-pressed="compressed"
							@keyup.enter="compressed = !compressed"
						>
							<span
								:class="elementClassName('compress-title')"
							>{{$t('agenda.labels.compress')}}</span>
						</label>
					</div>
				</div>
			</form>
			<agenda-add-item
				v-if="!disabled"
				:items="addableConfig"
			/>
		</nav>

		<agenda-groups
			v-if="!disabled"
			:groups="groups"
			:addable="addableConfig"
			:scope="scope"
			:collapsed="collapsed"
			:compressed="compressed"
			:direction="direction"
			:store-path="storePath"
		/>
		<slot v-else name="empty" />
		<transition name="fade">
			<slot v-if="!visibleItems.length && !loading && compressed && !disabled" name="noItems">
				<p :class="elementClassName('no-items')">
					{{$t('agenda.labels.noItems')}}
				</p>
			</slot>
		</transition>

		<nav :class="elementClassName('controls--secondary')">
			<h2 :class="elementClassName('controls-title')">
				<button
					:class="elementClassName('controls-prev')"
					@click="changeRange(ACTIONS.GO_PREV)"
				>
					<icon :small="true" name="backward" /> {{prevName}}
				</button>
				<button
					:class="elementClassName('controls-next')"
					@click="changeRange(ACTIONS.GO_NEXT)"
				>
					{{nextName}} <icon :small="true" name="forward" />
				</button>
			</h2>
		</nav>
	</section>
</template>

<script>
import Vue from 'vue';
import './agenda-groups';
import './agenda-filter';
import {
	assign, flow, get, isEmpty, isString, map, random, reduce, reject, split, toUpper
} from 'lodash';
import { ACTIONS as A, GETTERS as G, store } from 'store/components/agenda-view';
import cardUrl from 'cwcardurl';
import {
	optionalArray, optionalFunction, requiredArray, requiredObject, requiredString
}  from './props';
import agendaMixin from './agenda-mixin';
import storeMixin from 'components/__mixins/store-mixin';
import { FORMATS } from './helpers';
import { AGENDA_VIEW, SUFFIXES } from './constants';
import referral from 'service/referral';

const randomSuffix = () => SUFFIXES[random(0, SUFFIXES.length - 1)];

export default Vue.component(AGENDA_VIEW, {
	mixins: [agendaMixin, storeMixin],
	props: {
		addable: optionalArray,
		disabled: {
			type: Boolean,
			default: false
		},
		settings: requiredObject,
		items: requiredArray,
		loading: {
			type: Boolean,
			default: false
		},
		name: requiredString,
		refresh: optionalFunction,
		statuses: {
			type: Array,
			default: () => ['completed', 'not-completed', 'emergency', 'inactive', 'stateless']
		},
		tilePath: requiredString,
		types: requiredArray,
		timezone: requiredString
	},

	data: ({ $t }) => ({
		ACTIONS: A,
		scopes: ['year', 'month', 'week', 'day'],
		cid: `cid-${new Date().valueOf()}-${randomSuffix()}`,
		direction: '',
		expandLabel: $t('agenda.actions.expand'),
		collapseLabel: $t('agenda.actions.collapse'),
		filtersVisible: false
	}),

	computed: {
		title: ({ maybeTranslate, settings }) => maybeTranslate(get(settings, 'title', 'Agenda')),
		scope: ({ getters }) => getters(G.SCOPE),
		initialTypes: ({ filteringItem, getters }) => map(
			getters(G.TYPES),
			(type) => filteringItem(type, 'type')
		),
		initialStatuses: ({ filteringItem, getters }) => map(
			getters(G.STATUSES),
			(status) => filteringItem(status, 'status')
		),
		compressed: {
			get ({ getters }) {
				return getters(G.COMPRESSED);
			},

			set (newValue) {
				this.dispatch(A.SET_COMPRESSED, newValue);
			}
		},
		collapsed: ({ getters }) =>  getters(G.COLLAPSED),
		format: ({ scope }) => FORMATS[scope](),
		current: ({ getters, format }) => getters(G.CURRENT).toFormat(format),
		prevName: ({ getters, format }) => getters(G.PREV).toFormat(format),
		nextName: ({ getters, format }) => getters(G.NEXT).toFormat(format),

		mainClassName: ({ $options, compressed, collapsed, loading, settings }) => ({
			[$options.name]: true,
			[`${$options.name}--compressed`]: compressed,
			[`${$options.name}--collapsed`]: collapsed,
			[`${$options.name}--loading`]: loading,
			[`${$options.name}--reversed`]: settings.reverseTimeline === true
		}),

		controlsInputsClassName: ({ elementClassName, filtersVisible }) => [
			elementClassName('controls-inputs'),
			elementClassName('controls-inputs', filtersVisible ? 'visible' : 'hidden')
		],

		toggleFiltersClassName: ({ elementClassName, filtersVisible }) => [
			elementClassName('toggle-filters'),
			elementClassName('toggle-filters', filtersVisible ? 'active' : 'dormant')
		],

		addableConfig: ({ addable }) => map(
			addable,
			(entry) => assign({}, entry, {
				action: entry.action || function (datetime) {
					const params = assign({}, {
						referral: referral()
					}, entry.args);

					if (!isEmpty(datetime)) {
						assign(params, { datetime });
					}

					cardUrl.openCard(entry.card, params);
				}
			})
		),

		groups: ({ getters }) => getters(G.GROUPS),

		filter: ({ filteringItem, statuses, types, scopes, scope }) => ({
			statuses: map(statuses, (status) => filteringItem(status, 'status')),
			types: map(types, (type) => filteringItem(type, 'type')),
			scopes: flow([
				(scopeItems) => reject(scopeItems, (scopeItem) => scopeItem === scope),
				(scopeItems) => map(scopeItems, (scopeItem) =>
					filteringItem(scopeItem, 'scope', false))
			])(scopes)
		}),

		refreshLabel: ({ $t }) => toUpper($t('agenda.actions.refresh')),

		visibleItems: ({ groups }) => reduce(
			groups,
			(visibleItems, group) => [...visibleItems, ...group.items],
			[]
		)
	},

	watch: {
		items () {
			this.dispatch(A.SET_ITEMS, { items: this.items });
		}
	},

	created () {
		this.storePath = `components/${this.$options.name}/${this.tilePath}`;

		if (!this.$store.getters[`${this.storePath}/${G.GROUPS}`]) {
			this.$store.registerModule(split(this.storePath, '/'), store());
			this.$store.dispatch(
				`${this.storePath}/${A.INIT}`,
				{
					items: this.items,
					initialScope: get(this.settings, 'initialScope', 'month'),
					initialCompressed: !!this.settings.initialCompressed,
					initialCollapsed: !!this.settings.initialCollapsed,
					reverseTimeline: !!this.settings.reverseTimeline,
					timezone: this.timezone
				}
			);
		}

		this.callOnRangeChange();
	},

	methods: {
		filteringItem (item, type, checked = true) {
			const checkParam = checked ? { checked: true } : {};

			if (isString(item)) {
				return {
					...checkParam,
					label: this.$t(`agenda.${type}.${item}`),
					value: item
				};
			}

			return {
				...checkParam,
				label: item.label ? this.$t(item.label) : this.$t(`agenda.${type}.${item.name}`),
				value: item.name
			};
		},

		changeRange (newDirection) {
			this.direction = newDirection;
			setTimeout(() => {
				this.dispatch(newDirection);
				this.callOnRangeChange();
			}, 0);
		},

		onTypeFilterChange (selectedTypes) {
			this.dispatch(A.FILTER_BY_TYPE, selectedTypes);
		},

		onStatusFilterChange (selectedStatuses) {
			this.dispatch(A.FILTER_BY_STATUS, selectedStatuses);
		},

		onScopeFilterChange (selectedScope) {
			this.dispatch(A.SET_SCOPE, selectedScope);
			this.callOnRangeChange();
		},

		setRange (opts) {
			return ({
				current: this.getters(G.CURRENT).toISO(opts),
				from: this.getters(G.START).toISO(opts),
				to: this.getters(G.END).toISO(opts),
				next: this.getters(G.NEXT).toISO(opts),
				prev: this.getters(G.PREV).toISO(opts)
			});
		},

		callOnRangeChange () {
			return this.refresh(this.setRange({ includeOffset: false }));
		},

		onRefresh () {
			this.direction = '';
			return this.refresh(this.setRange({ includeOffset: true }));
		},

		onExpand () {
			this.dispatch(A.SET_COLLAPSED, false);
		},

		onCollapse () {
			this.dispatch(A.SET_COLLAPSED, true);
		},

		maybeTranslate (phrase) {
			return this.settings.translate ? this.$t(phrase) : phrase;
		},

		toggleFilters () {
			this.filtersVisible = !this.filtersVisible;
		}
	}
});
</script>
