<template>
	<ul :class="classes.actions">
		<li
			v-for="(action, index) in actions"
			:key="`${index}:${action.title}`"
			:class="classes.actionContainer"
		>
			<component
				:is="action.component"
				v-if="action.component"
				:action="action"
			/>
			<agenda-item-action
				v-else
				:action="action.action"
				:title="action.title"
				:classes="classes.action"
			/>
		</li>
	</ul>
</template>

<script>
import { requiredArray, requiredObject } from './props';
import { AGENDA_ITEM_ACTIONS } from './constants';
import AgendaItemAction from './agenda-item-action';

export default {
	name: AGENDA_ITEM_ACTIONS,
	components: { AgendaItemAction },
	props: {
		actions: requiredArray,
		classes: requiredObject
	}
};
</script>
