import $ from 'jquery';
import { defer } from 'lodash';
import FormComponentView from '../form-component-view';
import { compile } from 'handlebars';

const textarea = FormComponentView.extend({
	template: compile(`
		<label class="form-view__label-container">
			{{#if label}}
				<p class="form-view__label-text">{{ label }}</p>
			{{/if}}
			<textarea
				class="
					form-view__input
					form-view__textarea
					{{#if autoresize}}form-view__textarea--autoresize{{/if}}
				"
				name="{{ uniqueName }}"
				rows="{{ rows }}"
				{{#if required}} required="required" {{/if}}
				{{#if readonly}} readonly="readonly" {{/if}}
				{{#if disabled}} disabled="disabled" {{/if}}
				{{#if placeholder}} placeholder="{{ placeholder }}" {{/if}}
				{{#if hint}} aria-describedby="{{ name }}-hint"{{/if}}
				{{#if min}} minlength="{{ min }}" {{/if}}
				{{#if max}} maxlength="{{ max }}" {{/if}}
				>{{ value }}</textarea>
		</label>
		{{#if hint}}
		  <div class="form-view__hint" id="{{ name }}-hint" role="status">{{{ hint }}}</div>
		{{/if}}
	`),
	onAfterRender () {
		this.listenTo(this.model, 'change:rows', this.render);

		defer(() => {
			this.model.get('autoresize') && $(this.ui.textarea).autoResize();
		});
	}
});

export { textarea };

