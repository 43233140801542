import repository from 'repository';
import { clone, deburr, findIndex, get, map, reject, sortBy, toLower } from 'lodash';
import { cleanRespondentGroup } from '../__helpers/clean';

// @TODO(2018-11-26): Shouldn't be needed, remove when terrifying deadlines allow
const dirty = (group) => ({
	respondentGroupId: get(group, 'id', group.respondentGroupId),
	respondentGroupName: get(group, 'name', group.respondentGroupName),
	respondentGroupCategory: get(group, 'category', group.respondentGroupCategory),
	respondentGroupIsCommon: get(group, 'common', group.respondentGroupIsCommon)
});

const cleanAndSorted = (groups) => sortBy(
	map(groups, cleanRespondentGroup),
	(group) => toLower(deburr(group.name))
);

export default {
	namespaced: true,

	state: {
		data: [],
		collection: {},
		all: [],
		currentRespondent: []
	},

	getters: {
		all: (state) => map(state.all, cleanRespondentGroup),
		forCurrentClinician: (state) => cleanAndSorted(state.data),

		currentRespondent: (state) => state.currentRespondent
	},

	mutations: {
		set: (state, { respondentGroups, collection }) => {
			state.data = respondentGroups;
			state.collection = collection;
		},

		setAll: (state, { groups }) => {
			state.all = groups;
		},

		setCurrentRespondent: (state, payload) => {
			state.currentRespondent = payload;
		},

		removeFromAll: (state, respondentGroupId) => {
			const cloned = clone(state.all);
			state.all = reject(cloned, { respondentGroupId });
		},

		editInAll: (state, group) => {
			const cloned = clone(state.all);
			const index = findIndex(cloned, { respondentGroupId: group.id });
			cloned[index] = dirty(group);
			state.all = cloned;
		}
	},

	actions: {
		initForCurrentClinician: ({ commit }) => {
			const collection = repository.respondentGroupsForCurrentClinician();

			return collection.then(() => {
				commit('set', { respondentGroups: collection.toJSON(), collection });
			});
		},

		initAll: ({ commit }) => {
			const groups = repository.getRespondentGroups();

			return groups.then(() => {
				commit('setAll', { groups: groups.toJSON() });
			});
		},

		initForCurrentRespondent: ({ commit }, respondentId) => {
			const collection = repository.getRespondentInRespondentGroups(respondentId);
			collection.then(() => {
				commit('setCurrentRespondent', collection.toJSON());
			});
		},

		add: ({ dispatch }, group) => repository.createRespondentGroup(dirty(group))
			.then(() => {
				dispatch('initAll');
			}),

		remove: ({ commit }, groupId) => repository.deleteRespondentGroup(groupId).then(() => {
			commit('removeFromAll', groupId);
		}),

		edit: ({ commit }, group) => {
			commit('editInAll', group);
			return repository.updateRespondentGroup(group.id, dirty(group));
		}
	}
};
