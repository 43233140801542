import MultipleEntities from '../shared/multiple-entities';
import Link from './entity';
import urls from './urls';

/**
 * Links
 * @class Links
 * @extends MultipleEntities
 */
const Links = MultipleEntities.extend({
	model: Link,
	urls,
	eventNamespace: 'link',

	/**
	 * @method getList
	 * @return {Object} new {{#crossLink "Links"}}{{/crossLink}} Collection
	 * @example
	 *        Repository.Links.getList();
	 */
	getList: function () {
		return this.retrieve('list');
	},

	/**
	 * Get new collection of Links which match search query
	 * @method search
	 * @param {Object} params Search parameters
	 * @return {Object} new Collection
	 * @example
	 *        Repository.Links.search('example');
	 */
	search: function (params) {
		// force fetch hack
		params.timestamp = new Date().valueOf();
		return this.retrieve('searchLinks', params);
	}
});

export default new Links();
