<template>
	<card-link :card="card" :params="params">
		{{contentParsed}}
	</card-link>
</template>

<script>
import { reduce, get } from 'lodash';
import CardLink from 'components/card-link';
import TableComponentMixin from './_mixin';

export default {
	components: {
		CardLink
	},

	mixins: [
		TableComponentMixin
	],

	computed: {
		contentParsed: ({ content, column }) => content[column.value],
		card: ({ column }) => column.card,
		params: ({ column, content }) => reduce(
			column.params,
			(acc, val, key) => {
				acc[key] = get(content, val, '');
				return acc;
			},
			{}
		)
	},

	methods: {
		sortValue: ({ itemValue, column }) => itemValue[column.value]
	}
};
</script>
