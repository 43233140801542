<template>
	<section>
		<ul v-if="treatments.length">
			<li v-for="treatment in treatments" :key="treatment.id">
				<progress-bar
					:start-date="startDate(treatment)"
					:end-date="endDate(treatment)"
					:title="title(treatment)"
					:subtitle="subtitle(treatment)"
					:progress-value="progress(treatment)"
					:href="cardLink(treatment)"
				/>
			</li>
		</ul>
		<p v-else>
			{{t('No treatments')}}
		</p>
	</section>
</template>

<script>
import { CLINICIAN } from 'service/acl/checkpoints.json';
import { READ } from 'service/acl/access-levels';
import datetime from 'datetime';
import { filter, get, includes, isArray } from 'lodash';
import cardUrl from 'cwcardurl';

export default {
	title: '',
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.TREATMENTS,
		op: READ
	}],

	actions: ({ $store }) => {
		const action = {
			clinician: 'treatments/initForRespondent',
			respondent: ['treatments/initAll', { includeDisabled: true }]
		}[$store.getters.userType];

		return [action];
	},

	data: ({ tile }) => ({
		cfg: tile.config()
	}),

	computed: {
		canOpen: ({ $store }) => $store.getters.userType === 'clinician',

		treatments: ({ $store, cfg }) => {
			if ($store.getters.treatment) {
				return [$store.getters.treatment];
			}

			const showable = (treatment) => treatment.enabled &&
				(treatment.endAt || cfg.showWithoutEndDate);

			return filter($store.state.treatments.data, (treatment) => {
				let properType = true;

				if (isArray(cfg.treatmentTypes) && cfg.treatmentTypes.length) {
					properType = includes(cfg.treatmentTypes, +treatment.treatmentType.id);
				}

				return properType && showable(treatment);
			});
		}
	},

	methods: {
		getDate (date) {
			const dateObj = datetime(date);
			const timezone = this.$store.getters.respondent ?
				this.$store.getters.respondent.respondentTimezoneName :
				this.$store.state.user.timezoneName;

			dateObj.setTimezone(timezone);
			return dateObj.toMoment();
		},

		start (treatment) {
			return this.getDate(treatment.startAt);
		},

		end (treatment) {
			return treatment.endAt ?
				this.getDate(treatment.endAt) :
				this.start(treatment).clone().add(this.cfg.defaultDurationDays, 'd');
		},

		startDate (treatment) {
			return this.start(treatment).format(this.t('date.formats.dateTime'));
		},

		endDate (treatment) {
			return this.end(treatment).format(this.t('date.formats.dateTime'));
		},

		title: (treatment) => get(treatment, 'treatmentType.name'),

		subtitle (treatment) {
			return this.canOpen ? treatment.customStatus : '';
		},

		progress (treatment) {
			if (!treatment.startAt) {
				return 0;
			}

			const now = datetime(new Date()).toMoment();
			const range = this.end(treatment) - this.start(treatment);
			const timeSpent = now - this.start(treatment);

			return (timeSpent / range) * 100;
		},

		cardLink (treatment) {
			return this.canOpen ?
				cardUrl.buildUrl('treatment', {
					respondentId: this.respondentId,
					treatmentId: treatment.id
				}) :
				'';
		}
	}
};
</script>
