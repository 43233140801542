import MultipleEntities from '../shared/multiple-entities';
import Decision from './decision';
import urls from './urls';

const DecisionsEntity = MultipleEntities.extend({
	urls,
	model: Decision,
	idName: 'inputId',
	eventNamespace: 'decision',

	getAll () {
		return this.retrieve('allDecisions');
	},

	getByRespondentId (respondentId) {
		return this.retrieve('byRespondentId', respondentId);
	},

	getForCurrentClinician () {
		return this.retrieve('forCurrentClinician');
	},

	getUnanswered () {
		return this.filter((model) => !model.get('responseDate'));
	},

	getByTreatmentId (treatmentId) {
		const decisions = this.retrieve('byTreatmentId', treatmentId);
		decisions.canAdd = function (model) {
			return +model.get('treatment') === +this.ownerId;
		};
		return decisions;
	}

});

export default new DecisionsEntity();
