import $ from 'jquery';
import iconsFa from 'components/icon/icon-mapping-fa';
import iconsArray from 'components/icon/icon-settings';
import { warn } from 'service/log/log';
import { findIndex, forEach, some } from 'lodash';

function getIcon (element, name, small, type = null) {
	let match = null;

	if (type) {
		const index = findIndex(iconsArray, { name: this.type });
		const listObj = iconsArray[index];
		match = listObj && listObj.source[name] ? listObj : null;
	} else {
		forEach(iconsArray, (obj) => {
			if (obj.source[name]) {
				match = obj;
				return false;
			}
		});
	}

	if (match) {
		element.addClass(match.action(match.source[name]));

		if (!small) {
			element.addClass(match.small);
		}
	} else {
		warn(`Could not find icon: ${name}`);
	}
	return element;
}

/*
 * Applies font-awesome (or other styles) to an element
 * @function icon
 * @param {string} icon the icon name
 * @param {Object|String}  options or set title attribute if undefined
 * @param {Object} options.element element the element to attach icon to, or null / an optional
 *     caption for a standalone icon
 * @param {Boolean} options.small make the icon small
 * @param {String}  options.title Title attribute (mandatory)
 * @returns {Object} DOMnode
 */
export default function (name, options = {}) {
	let icon = name;

	const { className, element, modifier, small, title, type } = options;

	const $element = element ? $(element) : $('<span />');
	let special = false;
	const specials = {};

	forEach(iconsFa, (className, iconName) => {
		specials[`${iconName}-`] = className;
	});

	some(specials, (iconName, specialName) => {
		// eslint-disable-next-line no-useless-escape
		const found = new RegExp(`^${specialName}\-`).exec(icon);

		if (found) {
			special = specialName;
			icon = icon.substring(specialName.length + 1);
		}
		return found;
	});

	title && $element.attr('title', title);

	$element.addClass(`icon`);

	getIcon($element, icon, small, type);

	className && $element.addClass(className);

	if (special) {
		const additional = specials[special];

		$element.append([
			$('<span />').addClass(`icon icon-extra icon-${special} fa fa-${additional}`)
		]);
	}

	if (modifier) {
		$element.addClass(`icon--${modifier}`);
	}

	return $element.get(0);
}
