import { assign } from 'lodash';
import repository from 'repository';
import initWith from '../__helpers/init-with';

export default {
	namespaced: true,

	state: {
		data: null,
		model: null
	},

	mutations: {
		set: (state, { decision, model }) => {
			state.data = decision;
			state.model = model;
		},

		reset: (state) => {
			state.data = null;
			state.model = null;
		},

		update: (state, payload) => {
			state.data = assign({}, state.data, payload);
			state.model.set(payload);
		}
	},

	actions: {
		init: (context, { decisionId } = { decisionId: 0 }) => {
			const { commit, getters } = context;
			const model = repository.decision(decisionId || getters.decisionId);

			return initWith(context, {
				actionName: 'decision/init',
				resource: model,
				action: () => {
					const decision = model.toJSON();
					commit('set', { model, decision });

					return {
						treatmentId: decision.treatment,
						respondentId: decision.respondentId
					};
				}
			});
		},

		initPure: ({ commit, getters }, id) => {
			const model = repository.decision(id || getters.decisionId);

			return model.then(() => {
				const decision = model.toJSON();
				commit('set', { model, decision });
			});
		},

		// b💩ckend doesn't return anything on update, need to fetch the entity after save
		update: ({ commit, state }, payload) => state.model.save(payload)
			.then(() => state.model.fetch())
			.then(() => {
				commit('update', state.model.toJSON());
			}),

		destroy: ({ commit, state }) => state.model.destroy().then(() => {
			commit('reset');
		})
	}
};
