import SharedView from './shared';
import { compact, map, without } from 'lodash';
import { numericOptions } from '../shared/options';

export default SharedView.extend({
	type: 'NumericRange',

	startValues () {
		if (!compact(this.model.values()).length) {
			return [this.model.get('min'), this.model.get('max')];
		}
		return this.model.values();
	},

	options () {
		return numericOptions({
			connect: true,
			displayValues: this.displayValues(),
			getUrl: this.model.mediaManager.getUrl,
			start: this.startValues(),
			...this.model.attributes
		});
	},

	changeValue ({ decrease } = { decrease: false }) {
		return (index) => {
			const values = this.slider.noUiSlider.get();
			const value = +values[index];

			values[index] = decrease ? value - this.opts.step : value + this.opts.step;
			this.slider.noUiSlider.set(values);
		};
	},

	onRender () {
		this.slider.noUiSlider.on('update', (values, handle) => {
			const answeredValues = this.model.values();
			answeredValues[handle] = values[handle];
			this.setAriaValue({ values: answeredValues.map(parseFloat) });
		});

		this.slider.noUiSlider.on('set', (values, handle) => {
			const unsetIndex = this.model.values().indexOf(undefined);
			const complete = unsetIndex === -1;

			if (!this.model.isAnswered() && without(this.model.values(), undefined).length) {

				if (unsetIndex !== handle) {
					const newValues = [];

					if ((handle === 0 && this.model.values()[handle] > values[handle]) ||
						(handle === 1 && this.model.values()[handle] < values[handle])) {
						newValues[handle] = values[handle];
						newValues[unsetIndex] = this.model.values()[handle];

					} else {
						newValues[handle] = this.model.values()[handle];
						newValues[unsetIndex] = values[handle];
					}

					this.model.setValues(map(newValues, function (val) {
						return parseFloat(val);
					}), { silent: true });
					this.slider.noUiSlider.set(newValues);
					return;
				}
			}

			this.getHandle(handle).addClass('answered');
			const answeredValues = this.model.values();
			answeredValues[handle] = values[handle];

			this.model.setValues(map(answeredValues, parseFloat), { silent: !complete });
			this.handleAnswered();
		});

		this.model.get('answerVisible') && this.renderLabel(this.startValues());
	},

	renderLabel (values) {
		const defaultValueOnStart =
			!this.model.get('valueSetByInput') &&
			!!compact(this.model.defaultValues()).length;

		const selectedMin = parseFloat(values[0]);
		const selectedMax = parseFloat(values[1]);
		const isAnswered = (this.model.isAnswered() || defaultValueOnStart) &&
			!isNaN(selectedMin) &&
			!isNaN(selectedMax);

		this.$answer.html(this.answer(isAnswered ?
			`${selectedMin} -  ${selectedMax}` :
			this.labels.none
		));
		this.$container.toggleClass('slider--unanswered', !isAnswered);
	}
});
