import { set, add, update, reset } from '../__helpers/mutations';
import { assign, find, findIndex, get, map, sortBy } from 'lodash';
import repository from 'repository';
import { cleanClinicianGroup } from '../__helpers/clean';

const CONSENT_ID = 'urlParams.consentId';

const mutation = (type, name) => {
	const clean = {
		clinicianGroups: cleanClinicianGroup
	}[name];
	const idx = (arr, id) => findIndex(arr, { id });

	return {
		set: (state, items) => {
			state[name] = map(items, clean);
		},
		add: add(name),
		update: (state, item) => {
			state.clinicianGroups[name] = assign(state[name], item);
		},
		remove: (state, id) => {
			const index = findIndex(state[name], { id });
			state[name].splice(index, 1);
		},
		updateConsent: (state, item) => {
			state[name][idx(state[name], item.id)] = item;
		}
	}[type];
};

export default {
	namespaced: true,
	state: {
		data: {}, // consent
		options: [],
		option: {},
		treatmentTypes: [],
		clinicianGroups: [],
		version: {}
	},

	getters: {
		consent: (state) => state.data,
		treatmentTypes: (state) => map(state.treatmentTypes),
		options: (state) => sortBy(state.options, ['order', 'id']),
		option: (state) => state.option,
		clinicianGroups: (state) => map(state.clinicianGroups),
		versions: (state) => sortBy(state.version, (version) => -1 * version.version)
	},

	mutations: {
		setConsentClinicianGroups: mutation('set', 'clinicianGroups'),
		addConsentClinicianGroup: mutation('add', 'clinicianGroups'),
		updateConsentClinicianGroup: mutation('update', 'clinicianGroups'),
		removeConsentClinicianGroup: mutation('remove', 'clinicianGroups'),

		setConsentTreatmentTypes: set('treatmentTypes'),
		addConsentTreatmentType: add('treatmentTypes'),
		updateConsentTreatmentType: update('treatmentTypes'),
		removeConsentTreatmentType: mutation('remove', 'treatmentTypes'),

		setConsentOptions: set('options'),
		setConsentOption: set('option'),
		setNewConsentOption: set('option'),
		addConsentOption: add('options'),
		removeConsentOption: mutation ('remove', 'options'),
		updateConsentOption: mutation('updateConsent', 'option'),

		setConsentVersion: mutation('set', 'version'),

		set: set(),
		reset: reset()	},
	actions: {
		initConsent: ({ commit }, consentId) => {
			const singleConsent = repository.getConsent({
				consentId
			});
			return singleConsent.then((consent) => {
				commit('set', consent);
			});
		},

		addConsent: ({ commit }, {
			name,
			content = '',
			submittableByAdministrator,
			requireAllResponsible,
			combinedAnswers
		}) => {
			const consent = repository.addConsent({
				name,
				content,
				submittableByAdministrator,
				requireAllResponsible,
				combinedAnswers
			});
			return consent.then((response) => {
				commit('set', response);
				return response;
			});
		},

		publishConsent: ({ commit }, consentId) => {
			const consent = repository.publishConsent({
				consentId
			});
			return consent.then((response) => {
				commit('set', response);
				return response;
			});
		},

		activateConsent: ({ commit }, consentId) => {
			const consent = repository.activateConsent({
				consentId
			});
			return consent.then((response) => {
				commit('set', response);
				return response;
			});
		},

		removeConsent: (context, consentId) => repository.removeConsent({ consentId }),

		copyConsent: ({ commit }, { consentId, name, version }) => {
			const consent = repository.copyConsent({
				consentId,
				name,
				version
			});
			return consent.then((response) => {
				commit('set', response);
				return consent;
			});
		},

		setConsentVersion: ({ commit }, consentId) => {
			const version = repository.getConsentVersions({
				consentId
			});
			return version.then((data) => {
				commit('setConsentVersion', data);
				return data;
			});
		},

		consentVersion: (
			{ commit, getters },
			{ consentId, versionNumber }
		) => repository.consentVersion({
			consentId,
			versionNumber: versionNumber || find(getters.versions, {
				version: `${getters.consent.lastVersionNumber}`
			}).id
		}).then((consent) => {
			commit('set', assign({}, getters.consent, consent));
		}),

		restoreConsent: ({ commit },  { consentId, versionNumber }) => {
			const version = repository.restoreConsent({
				consentId,
				versionNumber
			});
			return version.then((consent) => {
				commit('set', consent);
				return consent;
			});
		},

		updateConsent: ({ commit, rootState }, data) => {
			const consentId = data.id || get(rootState.cardData, CONSENT_ID);
			const consent = repository.updateConsent({
				consentId,
				...data
			});
			return consent.then((updatedData) => {
				commit('set', updatedData);
			});
		},

		initConsentOptions: ({ commit, rootState }, consentId) => {
			const allConsentOptions = repository.consentOptions({
				consentId: consentId || get(rootState.cardData, CONSENT_ID)
			});
			return allConsentOptions.then((options) => {
				commit('setConsentOptions', options);
			});
		},

		initConsentOption: ({ commit, dispatch, rootState }) => {
			const consentId = get(rootState.cardData, CONSENT_ID);
			const optionId = get(rootState.cardData, 'urlParams.optionId');
			const singleOption = repository.consentOption({
				consentId,
				optionId
			});
			return singleOption.then((option) => {
				commit('setConsentOption', option);
				return dispatch('consents/add', option, { root: true });
			});
		},

		initNewConsentOption: ({ commit }) => commit('setNewConsentOption', {}),

		addConsentOption: ({ commit }, {
			name,
			content,
			statement,
			order,
			consentId
		}) => {
			const option = repository.addConsentOption({
				name,
				content,
				statement,
				order,
				consentId
			});
			return option.then((response) => {
				commit('addConsentOption', response);
				return response;
			});
		},

		removeConsentOption: ({ commit, state, rootState }, optionId) => {
			const consentId = state.data.id || get(rootState.cardData, CONSENT_ID);

			return repository.removeConsentOption({
				consentId,
				optionId
			}).then(() => {
				commit('removeConsentOption', optionId);
			});
		},

		updateConsentOption: ({ commit, rootState }, {
			name,
			content,
			statement,
			order
		}) => {
			const consentId = get(rootState.cardData, CONSENT_ID);
			const optionId = get(rootState.cardData, 'urlParams.optionId');

			const consentOption = repository.updateConsentOption({
				consentId,
				optionId,
				name,
				content,
				statement,
				order
			});
			return consentOption.then((data) => {
				commit('updateConsentOption', data);
			});
		},

		setConsentClinicianGroups: ({ commit, rootState }) => {
			const consentId = get(rootState.cardData, CONSENT_ID);
			const clinicianGroups = repository.consentClinicianGroups({
				consentId
			});
			return clinicianGroups.then((payload) => {
				commit('setConsentClinicianGroups', payload);
			});
		},

		addConsentClinicianGroup: ({ commit, state }, group) => {
			const consentId = state.data.id;
			const administratorGroupId = group.id;
			commit('addConsentClinicianGroup', group);

			return repository.addConsentClinicianGroup({
				consentId,
				administratorGroupId
			}).then((newGroup) => {
				commit('updateConsentClinicianGroup', newGroup);
			});
		},

		removeConsentClinicianGroup: ({ commit, state }, id) => {
			const consentId = state.data.id;

			return repository.removeConsentClinicianGroup({
				consentId,
				administratorGroupId: id
			}).then(() => {
				commit('removeConsentClinicianGroup', id);
			});
		},

		setConsentTreatmentTypes: ({ commit, rootState }) => {
			const consentId = get(rootState.cardData, CONSENT_ID);
			const treatmentTypes = repository.consentTreatmentTypes({
				consentId
			});
			return treatmentTypes.then((payload) => {
				commit('setConsentTreatmentTypes', payload);
			});
		},

		addConsentTreatmentType: ({ commit, state }, item) => {
			const consentId = state.data.id;
			const treatmentTypeId = item.id;

			return repository.addConsentTreatmentTypes({
				consentId,
				treatmentTypeId
			}).then((data) => {
				commit('addConsentTreatmentType', data);
			});
		},

		removeConsentTreatmentType: ({ commit, state }, id) => {
			const consentId = state.data.id;

			return repository.removeConsentTreatmentTypes({
				consentId,
				treatmentTypeId: id
			}).then(() => {
				commit('removeConsentTreatmentType', id);
			});
		}
	}
};
