import t from 'translate';
import appBar from 'app-bar';
import store from 'store';
import banRedirect from '../helpers/ban-redirect';
import treatmentContextParam from 'modules/treatments/util/treatment-context-param';

const card = {
	title: t('Circles'),
	url: [
		'treatment/:treatmentId/circles',
		'circles'
	],
	name: 'circles-startpage',
	acl: 'administrator.circles.circles',

	ctrl: ({ close }) => {
		if (banRedirect(close)) {
			return;
		}

		if (store.getters.userType === 'clinician') {
			appBar.addCardLink({
				sourceCard: card.name,
				title: t('Circle abuse reports'),
				icon: 'circle-abuse-report',
				card: 'circle-abuse-reports'
			});
		}

		appBar.addCardLink({
			sourceCard: card.name,
			title: t('Create a circle'),
			icon: 'add',
			card: 'circle-create',
			query: treatmentContextParam()
		});

		return store.dispatch('circles/initMy');
	}
};

export { card as circles };
