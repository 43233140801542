import Vue from 'vue';
import { className } from './_helpers';
import { map, noop } from 'lodash';

const COMPONENT_NAME = 'checkbox-input';

export default Vue.component(COMPONENT_NAME, {
	props: {
		value: {
			type: Boolean
		},
		classPrefix: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		inputClass: {
			type: String,
			default: null
		},
		label: {
			type: String,
			required: true
		},
		omitDefaultClass: {
			type: Boolean,
			default: false
		},
		onChange: {
			type: Function,
			default: noop
		},
		textClass: {
			type: String,
			default: null
		},
		required: {
			type: Boolean,
			default: false
		},
		translate: {
			type: Boolean,
			default: true
		},
		readonly: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		containerClassName () {
			return this.className('field-container');
		},

		labelClassName () {
			return [
				...this.className('label-container'),
				...this.className('label-container--checkbox'),
				this.invalid && 'invalid'
			];
		},

		labelTextClassName () {
			const classes = this.classNames(['label-text'], this.textClass);

			if (this.required) {
				classes.push(this.className('label--required'));
			}
			return classes;
		},

		inputClassName () {
			return this.classNames(['input'], this.inputClass);
		},

		textAdditional () {
			if (this.classPrefix && this.textClass) {
				return `${this.classPrefix}__${this.textClass}`;
			}
			return '';
		},

		uniqueClass () {
			return this.omitDefaultClass && this.classPrefix && this.inputClass;
		}
	},

	methods: {
		classNames (classArray, additional) {
			if (this.uniqueClass) {
				return `${this.classPrefix}__${this.inputClass}`;
			}

			if (this.classPrefix && additional) {
				classArray.push(additional);
			}
			return map(classArray, (el) => this.className(el));
		},
		updateValue () {
			this.onChange(this.$refs.input.checked);
			this.$emit('input', this.$refs.input.checked);
		},

		onClick (event) {
			if (this.readonly) {
				event.preventDefault();
			}
		},

		className: className(COMPONENT_NAME)
	},

	template: `
		<div :class="containerClassName">
			<label :class="labelClassName">
				<input
					@change="updateValue()"
					@click="onClick"
					:checked="!!value"
					:class="inputClassName"
					:disabled="disabled"
					type="checkbox"
					ref="input"
				/>
				<span v-if="translate" :class="labelTextClassName" v-translate>{{ label }}</span>
				<span v-else :class="labelTextClassName">{{ label }}</span>
			</label>
		</div>
	`
});
