import repository from 'repository';
import { set } from '../__helpers/mutations';
import prefixify from '../__helpers/prefixify';

const prefix = prefixify('adapterInstances');

// getters
const DATA = 'data';

// actions
const FETCH = 'fetch';
const CREATE = 'create';

export const ADAPTER_INSTANCES = {
	DATA: prefix(DATA),

	FETCH: prefix(FETCH),
	CREATE: prefix(CREATE)
};

export default {
	namespaced: true,

	state: {
		data: null
	},

	getters: {
		[DATA]: (state) => state.data
	},

	mutations: {
		set: set()
	},

	actions: {
		[FETCH]: ({ commit }) =>
			repository.fetchAdapterInstances()
				.then((data) => {
					commit('set', data);
				}),

		[CREATE]: (context, data) =>
			repository.createAdapterInstance(data)
	}
};
