import { forEach } from 'lodash';
import { warning } from 'service/log/log';
import $ from 'jquery';

/**
 * DOM manipulation helper.
 *
 * @example    var a = dom.createElement({tag: 'a'});
 *
 * @deprecated
 * @class dom
 * @author Marcin
 */
const dom = new function () {

	/*
	 * @method dom#createElement
	 * @author Marcin
	 * @param {Object} params
	 *        @param {Object} params.attrs - DOM attributes map
	 *        @param {Object} params.props DOM properties map
	 *        @param {Object} params.bind - DOM event callbacks map
	 *        @param {Object|string} params.css css properties map or className (a class name can
	 *     also be added using DOM Attributes 'class').
	 *        @param {string} params.id - DOMElement id
	 *        @param {string} params.innerHTML inner HTML
	 *        @param {string|Array} params.content innerHTML or a list of contents cosisting of
	 *     strings and DOMElements. All the strings html reserved characters will be replaced with
	 *     entities.
	 *        @param {DOMElement} params.parent a parent element to which the created element will
	 *     be appended
	 * @returns {DOMNode}
	 */
	this.createElement = function (params, vars) {
		(typeof params === 'string') && (params = {
			tag: params
		});
		(typeof params === 'object') || (params = {});
		(typeof vars === 'object') || (vars = {});

		if (typeof params.tag === 'undefined') {
			warning('At least tag: name must be specified');
		}

		const element = document.createElement(params.tag);
		forEach(vars, (value, key) => {
			element[key] = value;
		});

		return dom.alterElement(element, params);
	};

	/*
	 * Checks if given item is a DOMNode.
  *
	 * @author Marcin
	 * @method dom#isHTMLElement
	 * @param {any} obj
	 * @returns {boolean}
	 */
	this.isHTMLElement = function (obj) {
		try {
			// Using W3 DOM2 (works for FF, Opera and Chrom)
			return obj instanceof HTMLElement;

		} catch (e) {
			// Browsers not supporting W3 DOM2 don't have HTMLElement and
			// an exception is thrown and we end up here. Testing some
			// properties that all elements have. (works on IE7)
			return (typeof obj === 'object') &&
				(obj.nodeType === 1) &&
				(typeof obj.style === 'object') &&
				(typeof obj.ownerDocument === 'object');
		}
	};

	/*
	 * Marksup an element wit data-* attributes.
  *
	 * @author Marcin
	 * @method dom#markup
	 * @param {DOMNode} element
	 * @param {Object} markup
	 * @returns {DOMNode}
	 */
	this.markup = function (element, markup) {
		return dom.alterElement(element, {
			data: markup
		});
	};

	/*
	 * Alters an element.
	 *
	 * @see dom#createElement
	 * @method dom#alterElement
	 * @param {DOMNode} element
	 * @param {Object} params - {@link dom#createElement}.
	 */
	this.alterElement = function (element, params) {
		(typeof params === 'object') || (params = {});

		if (typeof element === 'string') {
			element = document.getElementById(element.replace(/^#/, ''));
		}

		if (typeof params.attrs === 'object') {
			forEach(params.attrs, (value, name) => {
				if (value !== undefined) {
					element.setAttribute(name, value);
				}
			});
		}

		if (typeof params.props === 'object') {
			forEach(params.props, (value, name) => {
				element[name] = value;
			});
		}

		if (typeof params.bind === 'object') {
			forEach(params.bind, (callback, eventName) => {
				$(element).bind(eventName, callback);
			});
		}

		if (typeof params.css === 'object') {
			forEach(params.css, (value, key) => {
				element.style[key] = value;
			});

		} else if (typeof params.css === 'string') {
			element.className = params.css;
		}

		if (params.id) {
			element.id = params.id;
		}

		const isHTMLElement = function (obj) {
			try {
				// Using W3 DOM2 (works for FF, Opera and Chrom)
				return obj instanceof HTMLElement;

			} catch (e) {
				// Browsers not supporting W3 DOM2 don't have HTMLElement and
				// an exception is thrown and we end up here. Testing some
				// properties that all elements have. (works on IE7)
				return (typeof obj === 'object') &&
					(obj.nodeType === 1) &&
					(typeof obj.style === 'object') &&
					(typeof obj.ownerDocument === 'object');
			}
		};

		const appendContent = function (items) {
			forEach(items, (contentElement) => {
				if (isHTMLElement(contentElement)) {
					element.appendChild(contentElement);

				} else if (contentElement instanceof Array) {
					appendContent(contentElement);

				} else {
					const lines = (`${contentElement}`).replace(/\n{3,}/g, '\n\n\n').split(/\n/);
					forEach(lines, (line, i) => {
						if (i > 0) {
							dom.createElement({
								tag: 'br',
								parent: element
							});
						}
						element.appendChild(document.createTextNode(line));
					});
				}
			});
		};

		if (params.innerHTML !== undefined) {
			element.innerHTML = `${params.innerHTML}`;

		}

		if (params.content instanceof Array) {
			appendContent(params.content);

		} else if (params.content !== undefined) {
			element.innerHTML = params.content;
		}

		if (params.before) {
			const parent = (
				(params.parent === 'string') ?
					document.getElementById(params.parent) :
					params.parent
			) || params.before.parentNode;
			const before = (params.before === 'string') ?
				document.getElementById(params.before) :
				params.before;
			parent.insertBefore(element, before);

		} else if (params.parent) {
			((typeof params.parent === 'string') ?
				document.getElementById(params.parent) :
				params.parent).appendChild(element);
		}

		if (typeof params.action === 'function') {
			$(element).click(function (event) {
				for (let b = event.target; b !== this; b = b.parentNode) {
					if (b.tagName === 'A') {
						return;
					}
				}

				params.action.call(element, event);
			});
		}

		if (params.role) {
			element.setAttribute('role', params.role);
		}

		if (typeof params.aria === 'object') {
			forEach(params.aria, (value, key) => {
				element.setAttribute(`aria-${key}`, value);
			});
		}

		if (typeof params.data === 'object') {
			forEach(params.data, (value, key) => {
				element.setAttribute(`data-${key}`, value);
			});
		}

		if (typeof params.markup === 'object') {
			forEach(params.markup, (value, key) => {
				element.setAttribute(`data-${key}`, value);
			});
		}

		return element;
	};

	/*
	 * Detaches an element from the document DOM tree.
	 *
	 * @author Marcin
	 * @param {DOMNode|string} element
	 * @method dom#detach
	 */
	this.detach = function (element) {
		if (typeof element === 'string') {
			element = document.getElementById(element.replace(/^#/, ''));
		}

		if (element && element.parentNode) {
			element.parentNode.removeChild(element);
		}
	};
};

export default dom;
