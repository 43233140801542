import QueryParams from 'lib/url/query-params';

const url = function (url, params, opts = {}) {

	const querySeparator = (opts.querySeparator === undefined) ? '?' : opts.querySeparator;

	return url + querySeparator + new QueryParams(params);
};

export default url;
