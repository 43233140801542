import repository from 'repository';
import { set } from '../__helpers/mutations';
import prefixify from '../__helpers/prefixify';

const prefix = prefixify('adapters');

// getters
const DATA = 'data';

// actions
const FETCH = 'fetch';

export const ADAPTERS = {
	DATA: prefix(DATA),

	FETCH: prefix(FETCH)
};

export default {
	namespaced: true,

	state: {
		data: null
	},

	getters: {
		[DATA]: (state) => state.data
	},

	mutations: {
		set: set()
	},

	actions: {
		[FETCH]: ({ commit }) =>
			repository.fetchAdapters()
				.then((data) => {
					commit('set', data);
				})
	}
};
