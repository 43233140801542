import cubeId from './cube-id';
import extendQuery from './extend-query';
import { CUBE } from 'store/cube/cube';

export default (config) => [[
	CUBE.LOAD,
	{
		id: cubeId(config),
		query: extendQuery(config.query),
		ignoreAccessRules: config.ignoreAccessRules
	},
	{},
	{ instant: true }
]];
