import settings from 'system-settings';

export default () => ({
	respondentFirstName: '',
	respondentLastName: '',
	respondentUsername: null,
	respondentPassword: null,
	respondentPhone: null,
	respondentEmail: null,
	respondentGender: '2',
	respondentBirthdate: null,
	respondentAddress: '',
	respondentCommunicationDisabled: false,
	respondentAccountTest: false,
	respondentAccountEnabled: true,
	respondentLanguageId: settings.getInteger('DEFAULT_RESPONDENT_LANGUAGE'),
	respondentTimezoneName: settings.getString('DEFAULT_RESPONDENT_TIMEZONE')
});
