import { DELETE, GET, POST, PUT } from 'service/ajax/ajax';
import urls from './urls';

export default {
	/**
	 * Get new collection of Respondents which match search params.
  	 *
	 * @function searchRespondents
	 * @param {object} params - Search params.
	 * @returns {object} - New Respondents Collection.
	 * @example
	 *        repository.searchRespondents({
     * 			query: 'jof',
     * 			limit: 10,
     * 			start: 0
     * 		});
	 */
	searchRespondents (params) {
		return this.Respondents.search(params);
	},

	/**
	 * Get respondent by id.
  	 *
	 * @function getRespondent
	 * @param {number|string} respondentId - Respondent ID.
	 * @returns {object} Respondent model.
	 * @example
	 *        repository.getRespondent(19);
	 * @deprecated
	 */
	getRespondent (respondentId) {
		return this.Respondents.getById(respondentId);
	},

	/**
	 * Get respondent by id.
  	 *
	 * @function getRespondent
	 * @param {number|string} respondentId - Respondent ID.
	 * @returns {object} - Respondent model.
	 * @example
	 *        repository.getRespondent(19);
	 */
	respondent (respondentId) {
		return this.Respondents.getById(respondentId);
	},

	/**
	 * Get respondent by name.
  	 *
	 * @function getRespondentByName
	 * @param  {string} respondentName - Respondent name.
	 * @returns {object} - Respondent model.
	 * @example
	 *        repository.getRespondentByName('respondent2');
	 */
	getRespondentByUsername (respondentName) {
		return this.Respondents.getByUsername(respondentName);
	},

	/**
	 * Get clinicians assigned to a respondent.
  	 *
	 * @function getRespondentCliniciansByRespondentId
	 * @param {number|string} respondentId - Respondent ID.
	 * @returns {object} - Respondent Clinicians collection.
	 * @example
	 *        repository.getRespondentCliniciansByRespondentId(19);
	 */
	getRespondentCliniciansByRespondentId (respondentId) {
		return this.RespondentClinicians.getByRespondentId(respondentId);
	},

	/**
	 * Get all RespondentAttributeDefinitions.
  	 *
	 * @function getRespondentAttributeDefinitions
	 * @returns {object} New RespondentAttributeDefinitions Collection.
	 * @example
	 *        repository.getRespondentAttributeDefinitions();
	 */
	getRespondentAttributeDefinitions () {
		return this.RespondentAttributeDefinitions.getAll();
	},

	/**
	 * Get all RespondentAttributeValues for a Respondent.
  	 *
	 * @function getRespondentAttributeValues
	 * @param {number|string} respondentId - Respondent ID.
	 * @returns {object} New RespondentAttributeValues Collection.
	 * @example
	 *        repository.getRespondentAttributeValues(19);
	 */
	getRespondentAttributeValues (respondentId) {
		return this.RespondentAttributeValues.getAll(respondentId);
	},

	/**
	 * Get all Respondent Groups.
  	 *
	 * @function getRespondentGroups
	 * @returns {object} {{#crossLink "RespondentGroups"}}{{/crossLink}}.
	 * @example
	 *        repository.getRespondentGroups();
	 */
	getRespondentGroups () {
		return this.RespondentGroups.getAll();
	},

	/**
	 * Retrieve respondent group with clinician groups attached.
	 *
	 * @param {number|string} respondentGroupId - Respondent Group ID.
	 * @returns {object} - Promise.
	 * @example
	 * 		repository.respondentGroupWithClinicianGroups(47);
	 */
	respondentGroupWithClinicianGroups: (respondentGroupId) => GET({
		url: urls.groupWithClinicianGroups.url(respondentGroupId),
		separator: '&'
	}),

	/**
	 * Delete Respondent Group.
	 *
	 * @function deleteRespondentGroup
	 * @param {number|string} respondentGroupId - Respondent Group ID.
	 * @returns {object} - Promise.
	 * @example
	 * 		repository.deleteRespondentGroup(47);
	 */
	deleteRespondentGroup: (respondentGroupId) => DELETE({
		url: urls.singleGroup.url + respondentGroupId
	}),

	/**
	 * Update Respondent Group.
	 *
	 * @function updateRespondentGroup
	 * @param {number|string }respondentGroupId - Respondent Group ID.
	 * @param {object} data - Group data to save.
	 * @returns {object} - Promise.
	 * @example
	 * 		repository.updateRespondentGroup(47, {
	 * 			respodentGroupId: 47,
	 * 			respondentGroupName: 'group 1',
	 * 			respondentGroupCategory: 'category 1',
	 * 			respondentGroupIsCommon: false
	 * 		})
	 */
	updateRespondentGroup: (respondentGroupId, data) => PUT({
		url: urls.singleGroup.url + respondentGroupId,
		data
	}),

	createRespondentGroup: (data) => POST({
		url: urls.singleGroup.url,
		data
	}),

	/**
	 * Get Respondent Groups by current Clinician.
  	 *
	 * @function respondentGroupsForCurrentClinician
	 * @returns {object} {{#crossLink "RespondentGroups"}}{{/crossLink}}.
	 * @example
	 *        repository.respondentGroupsForCurrentClinician();
	 */
	respondentGroupsForCurrentClinician () {
		return this.RespondentGroups.getByCurrentClinician();
	},

	/**
	 * Get all RespondentInRespondentGroups for the given respondentId.
  	 *
	 * @function getRespondentInRespondentGroups
	 * @param {number|string} respondentId - Respondent ID.
	 * @returns {object} New RespondentInRespondentGroups Collection.
	 * @example
	 *        repository.getRespondentInRespondentGroups(3);
	 */
	getRespondentInRespondentGroups (respondentId) {
		return this.RespondentInRespondentGroups.getForRespondent(respondentId);
	},

	/**
	 * Get all Denied Clinicians for the given respondentId.
	 *
	 * @function getDeniedClinicians
	 * @param {object} params - Parameters.
	 * @param {number|string} params.respondentId - Respondent ID.
	 * @returns {object} 						  - Promise.
	 * @example
	 *        repository.getDeniedClinicians({
                  respondentId: 5
              });
	 */
	respondentDenied: ({ respondentId }) => GET({ url: urls.deniedClinicians.url(respondentId) }),

	/**
	 * Add Clinician to denied list for the given respondentId.
	 *
	 * @function addDeniedClinician
	 * @param {object} params - Parameters.
	 * @param {number|string} params.respondentId - Respondent ID.
	 * @param {number|string} params.clinicianId - Clinician ID.
	 * @returns {object} 							- Promise.
	 * @example
	 *        repository.addDeniedClinician({
                respondentId: 314,
                clinicianId: 5
              });
	 */
	addRespondentDenied: ({ respondentId, clinicianId }) => POST({
		url: urls.deniedClinician.url({ clinicianId, respondentId })
	}),

	/**
	 * Remove Clinicians from denied list for the given respondentId.
	 *
	 * @function delDeniedClinician
	 * @param {object} params - Parameters.
	 * @param {number|string} params.respondentId - Respondent ID.
	 * @param {number|string} params.clinicianId - Clinician ID.
	 * @returns {object} 							- Promise.
	 * @example
	 *        repository.delDeniedClinician({
	             respondentId: 1337,
	             clinicianId: 5
              });
	 */
	removeRespondentDenied: ({ respondentId, clinicianId }) => DELETE ({
		url: urls.deniedClinician.url({ clinicianId, respondentId })
	}),

	/**
	 * Add a single relation connected to a specified respondent.
	 *
	 * @function addRespondentRelation
	 * @param {object} data - Parameters.
	 * @param {object} data.relation - Relation ID.
 	 * @param {object} data.respondent1 - Respondent ID.
	 * @param {object} data.respondent2 - Respondent ID.
	 * @returns {object} 							- Promise.
	 * @example
	 *       repository.addRespondentRelation({
	 * 			relation: { id: 1 },
	 *          respondent1: { id: 10 },
	 * 			respondent2: { id: 1337 }
     *      });
	 */
	addRespondentRelation: (data) => POST({ url: urls.addRelation(), data }),

	/**
	 * Get relations by respondentId.
  	 *
	 * @function searchRespondentRelations
	 * @param {number|string} respondentId - Respondent ID.
	 * @returns {object} Relationld.
	 * @example
	 *        repository.searchRespondentRelations(19);
	 * @deprecated
	 */
	searchRespondentRelations: (respondentId) =>
		GET({ url: urls.respondentRelations.url(respondentId) }),

	/**
	 * Remove a single relation connected to a specified respondent.
	 *
	 * @function removeRespondentRelation
	 * @param {object} params - Parameters.
	 * @param {number|string} params.relationId - Relation ID.
 	 * @param {number|string} params.respondentId - Respondent ID.
	 * @returns {object} 							- Promise.
	 * @example
	 *       repository.removeRespondentRelation({
	 * 			relationId: 1,
	 *          respondentId: 1337
     *      });
	 */
	removeRespondentRelation: ({ relationTypeId, respondent1Id, respondent2Id }) => DELETE ({
		url: urls.deleteRelation.url({ relationTypeId, respondent1Id, respondent2Id })
	}),

	/**
	 * Get list of all consents for respondent.
	 *
	 * @function respondentConsents
	 * @param {object} params - Parameters.
	 * @param {number|string} params.respondentId - Respondent ID.
	 * @returns {object}                          - Promise.
	 * @example
	 *        repository.respondentConsents({
	 *            respondentId: 5
	 *        });
	 */
	respondentConsents: ({ respondentId }) => GET({
		url: urls.respondentConsents.url({ respondentId })
	}),

	/**
	 * Get list of all respondents.
	 *
	 * @function treatmentConsents
	 * @returns {object}   - Promise.
	 * @example
	 *        repository.respondents();
	 */
	respondents: () => GET({ url: urls.respondents.url() }),

	/**
	 * Update respondent data.
	 *
	 * @function updateRespondent
	 * @param {number} respondentId - Respondent ID.
	 * @param {object} respondent - Respondent data.
	 * @returns {object} - Promise.
	 * @example
	 *        repository.updateRespondent(respondentId, respondent);
	 */
	updateRespondent: (respondentId, respondent) => PUT({
		url: `${urls.respondent}${respondentId}`,
		data: respondent
	}),

	/**
	 * Get current lockout status for respondent.
	 *
	 * @function respondentLockStatus
	 * @param {number|string} respondentId - Respondent ID.
	 * @returns {object} - Promise.
	 * @example
	 *        repository.respondentLockStatus(respondentId);
	 */
	respondentLockStatus: (respondentId) => GET({ url: urls.lockStatus(respondentId) }),

	/**
	 * Release lock for respondent.
	 *
	 * @function respondentLockStatus
	 * @param {number|string} respondentId - Respondent ID.
	 * @returns {object} - Promise.
	 * @example
	 *        repository.unlockRespondent(respondentId);
	 */
	unlockRespondent: (respondentId) => DELETE({ url: urls.unlock(respondentId) })
};
