<template>
	<div class="treatment-grid-item">
		<button
			class="treatment-grid-item__button"
			tabindex="0"
			@click="click"
		>
			<div class="treatment-grid-item__body treatment-grid-item__body--with-image">
				<div
					class="treatment-grid-item__image"
					:style="{
						'background-image': `url(${image})`
					}"
				>
					<icon v-if="!image" name="image" />
				</div>
				<div class="treatment-grid-item__content">
					<slot />
				</div>
			</div>
		</button>
	</div>
</template>

<script>
export default {
	props: {
		image: {
			type: String,
			default: null
		}
	},

	methods: {
		click () {
			this.$emit('click');
		}
	}
};
</script>
