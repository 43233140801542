import SingleEntity from '../shared/single-entity';
import {
	abuseReportUpdate, abuseReportCreate, abuseReportDeletePost, abuseReportIdentifyUser
} from './urls';
import { sync } from 'backbone';

export default SingleEntity.extend({
	url: abuseReportUpdate.url,
	eventNamespace: 'circle-abuse-report',

	onSync (method) {
		if (method === 'create') {
			this._url = this.url;
			this.url = abuseReportCreate.url;

		} else if (this._url) {
			this.url = this._url;
		}

		// eslint-disable-next-line prefer-rest-params
		return sync(...arguments);
	},

	deletePost () {
		const post = new SingleEntity({ id: this.getId() });
		post.url = abuseReportDeletePost.url(this.getId());
		return post.destroy();
	},

	identifyUser () {
		const user = new SingleEntity({ id: this.getId() });
		user.url = abuseReportIdentifyUser.url(this.getId());
		user.retrieve();
		return user;
	}
});
