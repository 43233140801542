<template functional>
	<div class="warning-overlay">
		<slot />
	</div>
</template>

<script>
import Vue from 'vue';

export default Vue.component('warning-overlay', {});
</script>
