import treeView from './tree-view';
import { agendaView } from './agenda-view';

export default {
	namespaced: true,
	modules: {
		'tree-view': treeView,
		'agenda-view': agendaView
	}
};
