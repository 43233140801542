var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "progress" }, [
      _c("header", { staticClass: "progress__title-wrapper" }, [
        _vm.title
          ? _c(
              "h2",
              { staticClass: "progress__title" },
              [
                _vm.href
                  ? _c("a", { attrs: { href: _vm.href } }, [
                      _vm._v(_vm._s(_vm.title))
                    ])
                  : [_vm._v("\n\t\t\t\t" + _vm._s(_vm.title) + "\n\t\t\t")]
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.subtitle
          ? _c("h3", { staticClass: "progress__subtitle" }, [
              _vm._v("\n\t\t\t" + _vm._s(_vm.subtitle) + "\n\t\t")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "progress__container" }, [
        _vm.startDate
          ? _c(
              "p",
              {
                staticClass: "progress__label progress__label--start",
                attrs: { "aria-label": _vm.t("Start date") }
              },
              [_vm._v("\n\t\t\t" + _vm._s(_vm.startDate) + "\n\t\t")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.endDate
          ? _c(
              "p",
              {
                staticClass: "progress__label progress__label--end",
                attrs: { "aria-label": _vm.t("End date") }
              },
              [_vm._v("\n\t\t\t" + _vm._s(_vm.endDate) + "\n\t\t")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "progress",
          {
            staticClass: "progress__indicator",
            attrs: { max: "100", "aria-label": _vm.progressLabel },
            domProps: { value: _vm.value }
          },
          [
            _c("div", { staticClass: "progress__fallback-container" }, [
              _c("span", {
                staticClass: "progress__fallback-indicator",
                style: _vm.fallbackWidth
              })
            ])
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }