import { param } from 'jquery';

export default {
	allAssessments: {
		url: 'backend-commons/assessment/rest/assessment/all'
	},

	availableAssessments: (templateId) =>
		`backend-commons/dataexport/template/${templateId}/assessment/available/`,

	enabledAssessments: {
		url: (params = {}) =>
			`backend-commons/assessment/rest/assessment/all/enabled?${param(params)}`
	},

	autospawnAssessments: {
		url: 'backend-commons/assessment/rest/assessment/all?filter=autospawn-only'
	},

	assessment: {
		url: 'backend-commons/assessment/rest/assessment/'
	},

	calculationsOfAssessment: {
		url: (assessmentId) =>
			`backend-commons/assessment/rest/calculation/assessment/${assessmentId}`
	},

	flags: 'backend-commons/assessment/rest/assessment/all/flag',

	flagsOfAssessment: {
		url: (assessmentId) => `backend-commons/assessment/rest/assessment/flag/${assessmentId}`
	},

	questionsOfAssessment: {
		url: (assessmentId) => `backend-commons/assessment/rest/question/assessment/${assessmentId}`
	},
	question: {
		url: 'backend-commons/assessment/rest/assessment/'
	},

	clinicianGroup: {
		url: 'backend-commons/assessment/rest/administrator_group/'
	},

	clinicianGroups: {
		url: (assessmentId) =>
			`backend-commons/assessment/rest/administrator_group/assessment/${assessmentId}`
	},

	remoteScoringAssessment: {
		url: 'backend-commons/assessment/rest/scoring/'
	},

	remoteScoringAssessments: {
		url: 'backend-commons/assessment/rest/scoring/all'
	},

	remoteScoringProperty: {
		url: 'backend-commons/assessment/rest/scoring/params/'
	},

	remoteScoringProperties: {
		url: (assessmentId) => `backend-commons/assessment/rest/scoring/params/${assessmentId}`
	},

	allContexts: {
		url: 'backend-commons/assessmentinstance/rest/assessment_instance_context/all'
	},
	context: {
		url: 'backend-commons/assessmentinstance/rest/assessment_instance_context/'
	},

	types: {
		url: 'backend-commons/assessment-instance-type/'
	},
	type: {
		url: 'backend-commons/assessment-instance-type/'
	}
};
