import { compact, includes, isArray, isNaN, isNull, isString, isUndefined, map } from 'lodash';
import { removeAccents as accentsRemove } from '../../lib/string';

const KEYWORDS = ['query', 'search', 'limit', 'start', 'assessmentInstance'];
const CAST_MAP = {
	'true': 1,
	'false': 0,
	'null': null,
	'': null
};

const castToNumber = (val) => isNaN(parseInt(val, 10)) ? CAST_MAP[val] : parseInt(val, 10);
const isDefined = (val) => !isNull(val) && !isUndefined(val);
const isValidNumber = (val) => isDefined(castToNumber(val));
const isValidString = (val) => isString(val) && val.length;

const isValidFilter = (val) => isDefined(val) && (isValidNumber(val) || isValidString(val));
const filterValue = (val) => {

	if (isArray(val)) {
		return val.toString();
	}

	if (isValidNumber(val)) {
		return castToNumber(val);
	}

	return val;
};

/**
 * Convert object to URL parameters.
 *
 * @param { Object } params            - Parameters to convert.
 * @param { Object } opts              - Additional options.
 * @param { Array } opts.omit          - Parameters to omit.
 * @param { Array } opts.filterEq      - Parameters that should be prepended with `filterEq`.
 * @param { Array } opts.removeAccents - Parameters that should have accents removed ('Ą' -> 'A').
 * @returns {string}                   - Proper URL params.
 *
 * @example
 * 	const searchClinicians = {
 *		url: (params) => `backend-commons/administrator/?${urlify(params, { omit: ['treatment'] })}`
 *	}
 */
export default (
	params,
	{ omit, filterEq, removeAccents = [] } = { removeAccents: [] }
) => {

	const parsedParams = map(params, (value, key) => {
		let val = includes(removeAccents, key) ? accentsRemove(value) : value;

		if (includes(KEYWORDS.concat(omit), key)) {
			val = !isUndefined(val) ? val : '';
			return `${key}=${encodeURIComponent(val)}`;

		} else if (isValidFilter(val)) {
			const paramName = includes(filterEq, key) ? 'filterEq' : 'filter';

			return `${paramName}[${key}]=${encodeURIComponent(filterValue(val))}`;
		}
	});

	return compact(parsedParams).join('&');
};
