import aclcheck from '../../helpers/acl-check';

/**
 * ACL definition for the tile. If check fails, tile won't initialize. Apart form array, there can
 * be a function passed.
 *
 * @name TileView.acl
 * @param {*} checkpoints - Valid ACL checkpoints array.
 * @example
 * export default TileView.extend({
 *     acl: [{
 *         checkpoint: 'administrator.respondents.treatments',
 *         op: 'READ'
 *     }]
 * });
 *
 * @example
 * import can from 'acl-can';
 *
 * export default TileView.extend({
 *     acl: () => {
 *          const checkpoints = [{
 *               checkpoint: 'administrator.respondents.respondents',
 *               op: 'CREATE'
 *          }];
 *
 *          if (systemSettings.getBoolean('RESPONDENT_GROUPS_MANDATORY')) {
 *               checkpoints.push({
 *                    checkpoint: 'administrator.respondents.groups',
 *                    op: 'READ'
 *               });
 *          }
 *     return checkpoints;
 * });
 */
export default function (checkpoints) {
	if (this.__params.tileContainerModel.get('preventInit') !== true) {
		this.__params.tileContainerModel.set('preventInit', !aclcheck(checkpoints));
	}
}
