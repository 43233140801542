import BaseComponent from '../base-component';

export default BaseComponent.extend({
	template: `
		<div
			class="filter__container filter__container--checkbox"
			:class="containerClassNames"
		>
			<button
				tabindex="0"
				type="button"
				class="filter__action"
				@click="setValue(!viewValue.value)"
				:class="{
					'filter__action--active': viewValue.value,
					'filter__action--dormant': !viewValue.value
				}"
				:ref="selfName"
			>
				<div class="filter__remove">
					<button class="filter__remove-action" />
				</div>
				{{ label }}
			</button>
		</div>
	`,
	methods: {
		setValue (checked) {
			this.setValues({ value: checked });
			this.onChange(checked);
		}
	},

	computed: {
		containerClassNames () {
			return {
				'filter__container--active': this.anySelected,
				'filter__container--dormant': !this.anySelected
			};
		}
	}
});
