<template>
	<treatment-grid>
		<treatment-selection-item
			v-for="treatment in treatments"
			:key="treatment.id"
			:treatment="treatment"
			@click="$emit('click', treatment)"
		/>
	</treatment-grid>
</template>

<script>
import TreatmentGrid from 'modules/treatments/components/treatment-grid';
import TreatmentSelectionItem from './treatment-selection-item';

export default {
	components: {
		TreatmentGrid,
		TreatmentSelectionItem
	},

	props: {
		treatments: {
			type: Array,
			required: true
		}
	}
};
</script>
