import RespondentController from './controllers/controller';
import RespondentRouter from './controllers/router';
import appTitle from 'service/app-title';
import t from 'translate';
import * as cards from './cards/cards';
import myProfileTile from 'modules/profile/tiles/my-profile/tile';

export default {
	action: () => {
		appTitle.setAppName(t('Respondent Portal'));
		return new RespondentRouter({
			controller: new RespondentController()
		});
	},
	tiles: { 'my-profile': myProfileTile },
	cards
};
