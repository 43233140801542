var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("h2", { class: _vm.mainClassName }, [
    _vm.medium
      ? _c(
          "button",
          {
            class: _vm.buttonClassName("medium"),
            on: {
              click: function($event) {
                return _vm.changeScope({ newScope: _vm.medium.newScope })
              }
            }
          },
          [_vm._v(_vm._s(_vm.medium.text))]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.small
      ? _c(
          "button",
          {
            class: _vm.buttonClassName("small"),
            on: {
              click: function($event) {
                return _vm.changeScope({ newScope: _vm.small.newScope })
              }
            }
          },
          [_vm._v(_vm._s(_vm.small.text))]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.large
      ? _c(
          "button",
          {
            class: _vm.buttonClassName("large"),
            on: {
              click: function($event) {
                return _vm.changeScope({ newScope: _vm.large.newScope })
              }
            }
          },
          [_vm._v(_vm._s(_vm.large.text))]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }