<template>
	<div class="treatment-grid-label">
		<strong>{{label}}:</strong>
		<slot />
	</div>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			required: true
		}
	}
};
</script>
