<template>
	<div v-if="canAccess" :class="mainClassName">
		<a
			:href="href"
			:target="target"
			:class="className('link')"
			@click="handleClick"
		>
			<span :class="className('icon-container')">
				<icon v-if="item.icon" :name="item.icon" :class-name="className('icon')" />
			</span>
			<span :class="className('label')">{{text}}</span>
			<sidebar-counter :counter="item.counter" :type="type" />
		</a>
	</div>
</template>
<script>
import cardUrl from 'cwcardurl';
import t from 'translate';
import store from 'store';
import SidebarCounter from './sidebar-counter';
import { TREATMENT } from 'store/treatments/rp-treatment';

const COMPONENT_NAME = 'sidebar-item';

export default {
	components: { SidebarCounter },
	store,
	props: {
		item: {
			type: Object,
			required: true
		},
		canAccess: {
			type: Boolean,
			default: true
		},
		type: {
			type: String,
			default: ''
		},
		clickable: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		isRespondent: ({ $store }) => $store.getters['userType'] === 'respondent',
		target: ({ item }) => item.openInNewWindow ? '_blank' : '_self',
		mainClassName: ({ item }) => [
			COMPONENT_NAME,
			`${COMPONENT_NAME}--${item.size}`,
			`${COMPONENT_NAME}--${item.style}`,
			`${COMPONENT_NAME}--link-${item.linkType}`
		],
		href: ({ item, isRespondent, $store }) => {
			const params = isRespondent ? { treatmentId: $store.getters[TREATMENT.ID] } : {};

			return item.linkType === 'card' ?
				cardUrl.buildUrl(item.target, params) :
				item.target;
		},

		text: ({ $store, item }) => {
			if (item.style === 'profile') {
				return $store.state.user.displayName;
			}

			if (item.translate) {
				return t(item.label);
			}

			return item.label;
		}
	},
	methods: {
		className: (element = '') => `${COMPONENT_NAME}__${element}`,

		handleClick (e) {
			if (!this.clickable || !this.item.linkType) {
				e.preventDefault();
				e.stopPropagation();
			}
		}
	}
};
</script>
