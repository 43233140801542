import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import Question from './question';

const AssessmentQuestions = MultipleEntities.extend({
	urls,
	model: Question,
	idName: 'AssessmentQuestionId',
	eventNamespace: 'assessment-question',

	getByAssessment (assessmentId) {
		return this.retrieve('questionsOfAssessment', assessmentId);
	}
});

export default new AssessmentQuestions();
