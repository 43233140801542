import AttachmentsButton from 'components/attachments-button/attachments-button';
import appBar from 'app-bar';
import store from 'store';

const card = {
	name: 'assessment-portal-assessment',
	url: ['', 'home'],
	title: 'Assessment',

	ctrl: ({ cardData, populate }) => {
		const id = store.state.user.assessmentInstanceId;

		import(
			/* webpackChunkName: "ap-ar-instance" */
			`../entities/assessment-runtime-instance`
		).then((module) => {
			const AssessmentContentModel = module.default;
			const assessmentContent = new AssessmentContentModel({ assessmentInstanceId: id });

			return assessmentContent.fetch().then(() => {
				cardData.set({
					'assessment-model': assessmentContent,
					'assessmentInstanceId': id,
					'roleSummary': assessmentContent.get('roleInfo')
				});

				if (assessmentContent.get('instructionFiles').length) {
					const attachmentsButton = new AttachmentsButton({ model: assessmentContent });
					appBar.add(card.name, attachmentsButton, { order: 1 });
				}

				populate();
			});
		});
	}
};

export default card;
