import { replace } from 'lodash';

const CW_TAGS = (closing = '') => ({
	cwbold: `<${closing}b>`,
	cwitalics: `<${closing}i>`,
	cwunderline: `<${closing}u>`,
	cwstrikethrough: `<${closing}strike>`,
	cwquote: `<${closing}q>`
});

const cwtags = (string) => replace(
	string,
	/<(\/?)([a-z]+)>/g,
	(match, closing, tagName) => CW_TAGS(closing)[tagName] || `&lt;${closing}${tagName}&gt;`
);

const nl2br = (string) => replace(string, /\r?\n/g, '<br />');

const cwtagsNl2br = (string) => nl2br(cwtags(string));

const classNameFactory = (baseClassName, modifier) => {
	if (!baseClassName.length) {
		return '';
	}
	return {
		list: `${baseClassName}__list ${baseClassName}__list--${modifier}`,
		listItem: `${baseClassName}__list-item ${baseClassName}__list-item--${modifier}`
	};
};

const list = (type) => ({ string, baseClassName } = { string: '', baseClassName: '' }) => {
	const tagName = {
		ordered: 'ol',
		unordered: 'ul'
	}[type];
	const classNameOf = classNameFactory(baseClassName, type);
	const items = replace(
		replace(string, /\r?\n/g, '\n'),
		/^.*$/mg,
		(match) => `<li class="${classNameOf.listItem}">${cwtagsNl2br(match)}</li>`
	);

	return `<${tagName} class="${classNameOf.list}">${items}</${tagName}>`;
};

const unorderedList = list('unordered');
const orderedList = list('ordered');

export { CW_TAGS, cwtags, cwtagsNl2br, nl2br, orderedList, unorderedList };
