/* eslint-disable lodash/prefer-lodash-method */

import {
	assign, debounce, forEach, includes, isArray, isFunction, isObject, isUndefined, map, noop, get
} from 'lodash';
import $ from 'jquery';
import { Collection } from 'backbone';
import RowRenderer from 'components/table/row-renderer';
import t from 'translate';
import executeIfFunction from 'execute-if-function';
import tableSorter from './tablesorter';
import store from 'store';

/*
 * Pseudogeneric table component
 * @param {Object} params
 * @param {Function} params.click called on a row click.
 * @param {function|Array} params.columns.
 * @param {String} params.emptyListMessage String to display when the collection is empty
 * @param {String} params.emptyListTemplate String to display template when the collection is empty
 * @param {Backbone.Collection} params.collection
 * @param {Object} params.parent
 * @param {Object} params.actions
 * @param {Function} params.actions.delete delete a row.
 * @param {Function} params.store.items
 * @param {String} params.store.mutation
 * @param {Function} params.numberSorter
 * @param {Function} params.textSorter
 */

export default function (params = {}) {

	if (params.collection instanceof Collection) {
		params.items = params.collection.models;
		params.set = (model, key, value) => {
			model.set(key, value);
		};
		params.get = (model, key) => model.get(key);

	} else if (params.store) {
		params.items = params.store.items();
		const mutation = params.store.mutation;
		const mutations = isArray(mutation) ? mutation : [mutation];
		store.subscribe((mutation) => {

			if (includes(mutations, mutation.type)) {
				this.setItems(params.store.items());
			}
		});
	}

	// 'private' props and methods
	let lastItems = [];
	let columns = isFunction(params.columns) ? params.columns.call(this) : params.columns;
	columns = isObject(columns) ? columns : [];

	const html = {
		container: () => `
			<div class="cwtable__container">
				<table class="cwtable ${params.className || params.css || ''}">
					<thead>
						<tr>
							${html.columnHeaders}
							${html.actionsHeader}
						</tr>
					</thead>
					<tbody></tbody>
				</table>
				<div class="cwtable__see-more-container">
					<button class="flat-button__button cwtable__see-more-button">
						${t('More results')}
					</button>
				</div>
				<p class="no-items-notice">${params.emptyListMessage || t('No Items')}</p>
			</div>`,

		columnHeaders: map(columns, (col) => {
			if (!col || executeIfFunction(col.skip)) {
				return '';
			}
			const sorterData = col.sorter ? `data-sorter="${col.sorter}"` : '';
			const label = executeIfFunction(col.label);

			return `<th class="${col.className || col.css}" ${sorterData}>${label}</th>`;
		}).join(''),

		actionsHeader: params.actions ? '<th class="actions"></th>' : ''
	};

	// construct DOM
	const $container = $(html.container());
	const $table = $container.find('table');
	const $tbody = $table.find('tbody');
	const $emptyListMsg = $container.find('.no-items-notice');
	const $seeMoreContainer = $container.find('.cwtable__see-more-container');
	const $seeMoreButton = $seeMoreContainer.find('.cwtable__see-more-button');

	// public props and methods
	assign(this, {
		$container,
		clear: () => {
			$tbody.empty();
		},

		setItems: debounce((items) => {
			lastItems = items;
			let count = 0;
			const $trs = [];
			let rowsClickable = isFunction(params.click);

			if (!isUndefined(params.rowsClickable)) {
				if (isFunction(params.rowsClickable)) {
					params.rowsClickable = params.rowsClickable(this);
				}
				rowsClickable = params.rowsClickable;
			}

			const showHideTable = (count) => {
				if (count === 0) {
					$table.hide();
					$emptyListMsg.show();

				} else {
					$table.show();
					$emptyListMsg.hide();
				}
			};

			const addItem = (itemToAdd) => {
				const $tr = $('<tr />');
				const item = isFunction(params.item) ? params.item(itemToAdd) : itemToAdd;

				const rowRenderer = new RowRenderer({
					columns,
					item,
					get: (item, key) => isFunction(params.get) ?
						params.get.call(this, item, key) :
						item[key],
					tr: $tr[0],
					actions: params.actions,
					deleteFilter: params.deleteFilter,
					noClick: params.noClick || noop
				});

				if (rowsClickable) {
					$tr.addClass('clickable-row').attr('tabindex', '0');
					$tr.on('click', (e) => {
						if ($tr.hasClass('no-click') || $(e.target).is('a')) {
							return;
						}
						params.click(item);
					});

					$tr.on('keydown', function (e) {
						const code = e.which;
						const $target = $(e.target);

						// 13 = Return/Enter, 32 = Space
						if ($target.is($tr) && (code === 13 || code === 32)) {
							$(this).trigger('click');
						}
					});
				}

				if (isFunction(params.rowload)) {
					params.rowload(item, rowRenderer);

				} else {
					rowRenderer.render();
				}

				return $tr;
			};

			forEach(items, (item) => {
				$trs.push(addItem(item));
				count++;
			});

			showHideTable(count);
			$tbody.html($trs).trigger('update');
		}, get(params, 'refreshInterval', 500), { leading: true }),

		refresh: () => {
			this.setItems(lastItems);
		},

		showMoreButton: () => {
			$seeMoreContainer.show();
		},

		hideMoreButton: () => {
			$seeMoreContainer.hide();
		},

		setDataLoading: () => {
			$container.addClass('cwtable__container--loading');
		},

		setDataLoaded: () => {
			$container.removeClass('cwtable__container--loading');
		}
	});

	this.setItems(isObject(params.items) ? params.items : []);

	isFunction(params.init) && params.init.call(this);

	if (params.collection instanceof Collection && params.autoRefresh !== false) {
		const sortAndSet = (collection) => {
			if (params.collection.comparator) {
				params.collection.sort();
			}
			this.setItems(collection.models);
		};

		params.collection.listenTo(params.collection, 'reset', (collection) => {
			sortAndSet(collection);
		});

		params.collection.listenTo(params.collection, 'add remove change', () => {
			sortAndSet(params.collection);
		});
	}

	isFunction(params.more) || this.hideMoreButton();

	$seeMoreButton.on('click', () => {
		params.more.call(this, params.collection);
	});

	if (params.sortable) {
		tableSorter({
			$table,
			$tbody,
			sortOrder: params.sortOrder,
			numberSorter: params.numberSorter,
			textSorter: params.textSorter,
			extractText: params.extractText,
			preventAriaLive: params.preventAriaLive
		});
	}

	$container.appendTo(params.parent);
}
