import appUrl from 'service/app-url/app-url';
import { forEach, isFunction } from 'lodash';
import $ from 'jquery';
import { View } from 'marionette';
import icon from 'service/icon';
import iconMapping from 'components/icon/icon-mapping-default';
import t from 'translate';
import appContext from 'app-context';

const classPrefix = 'appbar-attachments';

export default View.extend({
	template: () => `
		<button class="appbar-button appbar-attachments__button"></button>
		<ul class="appbar-attachments__list"></ul>
	`,
	tagName: 'div',
	className: `${classPrefix}__container`,
	ui: {
		attachmentList: `.${classPrefix}__list`,
		attachmentButton: `.${classPrefix}__button`
	},
	events: {
		'click @ui.attachmentButton': 'toggleList'
	},

	onRender () {
		const $title = $('<span />').addClass('appbar-button__title').html(t('Attachments'));

		this.$links = [];
		this.ui.attachmentButton.append($title, icon('attachment', { title: t('Attachments') }));
		this.renderList();

		$('html, body').on('click', (e) => {
			const $target = $(e.target);
			const firedByButton = $target.is(`.${classPrefix}__button`) || !!$target.parents(`.${classPrefix}__list`).length;

			!firedByButton && this.hideList();
		});
	},

	toggleList (e, toggle) {
		e.stopPropagation(); // don't hide context menu on 1 column layout
		const tabindex = +this.$links[0].attr('tabindex');
		const newTabindex = (tabindex * -1) - 1; // 0 becomes -1, -1 becomes 0

		forEach(this.$links, ($link) => {
			$link.attr('tabindex', newTabindex);
		});

		this.ui.attachmentList.toggleClass('active', toggle);
		newTabindex === 0 && this.$links[0].trigger('focus');
	},

	hideList () {
		if (isFunction(this.ui.attachmentList.removeClass)) {
			this.ui.attachmentList.removeClass('active');
		}
	},

	renderList () {
		const mediaUrlsByFileName = {};

		forEach(this.model.get('mediaReferences'), (file, key) => {

			if (file.filename && file.url) { // assessments
				// 'attachment' part needed for logs
				const properUrl = file.url.replace('/rest/media/', '/rest/media/attachment/');

				mediaUrlsByFileName[file.filename] = properUrl;
			} else { // reports
				mediaUrlsByFileName[key] = file;
			}

		});

		forEach(this.model.get('instructionFiles'), (filename) => {
			const content = filename.replace(/^(.*)\..*$/, (all, noext) => noext);
			const extension = filename.split('.').pop();
			const iconName = iconMapping.hasOwnProperty(`file-${extension}`) ? `file-${extension}` : 'file';
			const $content = $('<span />').addClass(`${classPrefix}__action-text`).html(content);
			const $icon = $(icon(iconName)).addClass(`${classPrefix}__action-icon`);
			let href = appUrl(mediaUrlsByFileName[filename]);

			if (this.options.respondentId) {
				href += `?respondentId=${this.options.respondentId}`;

			} else if (appContext.isRespondent()) {
				href += `?respondentId=${appContext.getUserId()}`;
			}

			const $a = $('<a />')
				.addClass(`${classPrefix}__action`)
				.attr({
					href,
					tabindex: '-1'
				})
				.append($icon, $content);

			const $li = $('<li />')
				.addClass(`${classPrefix}__list-item`)
				.append($a);

			this.$links.push($a);
			this.ui.attachmentList.append($li);
		});
	}
});

