var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.sortedItems.length
    ? _c(
        "table",
        { staticClass: "table-list table table--long-text" },
        [
          _c(
            "thead",
            [
              _c(
                "table-row",
                _vm._l(_vm.columns, function(column) {
                  return _c(
                    "table-heading",
                    {
                      key: column.name,
                      nativeOn: {
                        click: function($event) {
                          return _vm.maybeSort(column)
                        }
                      }
                    },
                    [
                      _vm._t(
                        "header",
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(column.label) +
                              "\n\t\t\t\t\t"
                          ),
                          _vm.sortable
                            ? _c("icon", {
                                attrs: { name: _vm.sortIcon(column) }
                              })
                            : _vm._e()
                        ],
                        { column: column, content: column.label }
                      )
                    ],
                    2
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition-group",
            { attrs: { tag: "tbody", name: "fade" } },
            _vm._l(_vm.sortedItems, function(item) {
              return _c(
                "table-row",
                {
                  key: item[_vm.itemKey],
                  staticClass: "table__row",
                  class: _vm.rowClassName(item),
                  attrs: { tabindex: "0" },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.onRowClick(item)
                    },
                    click: function($event) {
                      return _vm.onRowClick(item)
                    }
                  }
                },
                _vm._l(_vm.columns, function(column) {
                  return _c(
                    "table-cell",
                    {
                      key: item[_vm.columns[0].key] + ":" + column.key,
                      staticClass: "table__cell",
                      class: _vm.cellClassName(column)
                    },
                    [
                      _vm._t("cell-label", [
                        _c("p", { staticClass: "table-cell-label" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(column.label) +
                              "\n\t\t\t\t\t"
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _vm._t(
                        "cell",
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(
                                _vm.getContent(column.key, { data: item }).value
                              ) +
                              "\n\t\t\t\t"
                          )
                        ],
                        {
                          item: item,
                          column: column,
                          content: _vm.getContent(column.key, { data: item })
                            .value
                        }
                      )
                    ],
                    2
                  )
                }),
                1
              )
            }),
            1
          )
        ],
        1
      )
    : _c("p", [_vm._v("\n\t" + _vm._s(_vm.$t("table.empty")) + "\n")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }