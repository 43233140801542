import Modernizr from 'modernizr';
import $ from 'jquery';
import moment from 'moment';
import t from 'translate';
import systemSettings from 'system-settings';
import { isString, isObject, isFunction, forEach } from 'lodash';

export default function () {
	(function ($) {
		$.fn.cwdatetimepicker = function (params = {}) {
			// native datetime-local support
			if (Modernizr.inputtypes['datetime-local']) {
				const $input = this;
				const input = this[0];
				input.type = 'datetime-local';

				$input.off('blur').one('blur', () => {
					input.checkValidity();

					if (input.validity.rangeUnderflow) {
						input.value = input.min;

					} else if (input.validity.rangeOverflow) {
						input.value = input.max;
					}
				});
				const stringDate = (date) => moment(date).format('YYYY-MM-DD[T]HH:mm:ss');

				const parseParams = ({ minDate, maxDate, onSelect }) => {
					if (minDate) {
						input.min = stringDate(minDate);
					}

					if (maxDate) {
						input.max = stringDate(maxDate);
					}

					if (onSelect) {
						$input.on('change', onSelect);
					}
				};

				if (isObject(params)) {

					/** Set some sensible default min/max values for the year.
					 * The native datetime-local inputs need this attribute **/
					params.maxDate = params.maxDate || moment('2100', 'YYYY').toDate();
					params.minDate = params.minDate || moment('1900', 'YYYY').toDate();

					parseParams(params);
				}

				if (isString(params)) {
					const fn = {
						getDate: () => {
							if (isString(input.value) && input.value.length > 0) {
								return moment(input.value).toDate();
							}
						},

						setDate: (newDate, callback) => {
							if (newDate) {
								let date = stringDate(newDate);

								if (isFunction(callback)) {
									date = callback(date);
								}
								input.value = date;

							} else {
								input.value = null;
							}
						},

						option: (optionName, value) => {
							parseParams({ [optionName]: value });
						}
					}[params];
					// eslint-disable-next-line prefer-rest-params
					const args = [].slice.call(arguments, 1);

					return fn(...args);
				}

				// jQuery datetimepicker fallback
			} else {
				const $this = $(this);

				if (!isObject(params)) {
					// eslint-disable-next-line prefer-rest-params
					return $this.datetimepicker.apply(this, [].slice.call(arguments, 0));
				}

				return this.each(() => {
					const settings = {
						dateFormat: 'dd.mm.yy',

						dayNamesShort: t('gui.calendar.dayShortNames').split('\n'),
						dayNamesMin: t('gui.calendar.dayShortNames').split('\n'),
						dayNames: t('gui.calendar.dayFullNames').split('\n'),

						monthNames: t('gui.calendar.monthFullNames').split('\n'),
						monthNamesShort: t('gui.calendar.monthShortNames').split('\n'),

						nextText: t('gui.calendar.NextMonth'),
						prevText: t('gui.calendar.PrevMonth'),

						closeText: t('gui.calendar.Close'),
						currentText: t('gui.respondent-calendar.Today'),

						timeOnlyTitle: t('gui.calendar.time.ChooseTime'),
						timeText: t('gui.calendar.time.Time'),
						hourText: t('gui.calendar.time.Hour'),
						minuteText: t('gui.calendar.time.Minute'),

						firstDay: (systemSettings.getInteger('FIRST_DAY_OF_WEEK') + 1) % 7,
						showButtonPanel: true,
						constrainInput: true
					};

					$this.prop('readonly') && $this.prop('disabled', true);

					forEach(params, (value, key) => {
						settings[key] = value;
					});
					$this.datetimepicker(settings);
				});
			}
		};
	})($);
}
