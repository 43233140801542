<template>
	<div :class="wrapperClassName">
		<div
			role="link"
			tabindex="0"
			:class="mainClassName"
			@click="onClick"
			@keyup.prevent.enter.space="onClick"
		>
			<span v-if="statusVisible" :class="elementClassName('status')" />

			<transition name="appear">
				<span
					v-if="!isCollapsed"
					:class="elementClassName('item-type-label')"
				>{{itemTypeLabel}}</span>
			</transition>

			<div :class="elementClassName('content-container')">
				<agenda-item-content
					:title="title"
					:subtitle="subtitle"
					:description="description"
					:icon="icon"
					:classes="classNames.content"
					:is-collapsed="isCollapsed"
				>
					<template #afterTitle>
						<agenda-item-labels
							v-if="!isCollapsed && horizontalLabels.length"
							:labels="horizontalLabels"
						/>
					</template>

				</agenda-item-content>
				<agenda-item-labels
					v-if="!isCollapsed && labels.length"
					:labels="labels"
				/>
			</div>
		</div>
		<agenda-item-actions
			v-if="actionsVisible"
			:actions="actions"
			:classes="classNames.actions"
		/>
		<button
			:class="elementClassName('collapse-action')"
			:aria-label="toggleCollapsedLabel"
			@click.stop="toggleCollapsed"
		>
			<span :class="elementClassName('collapse-chevron')" />
		</button>
	</div>
</template>
<script>
import Vue from 'vue';
import t from 'translate';
import {
	optionalArray,
	optionalObject,
	optionalString,
	requiredFunction,
	requiredString
} from './props';
import agendaMixin from './agenda-mixin';
import AgendaItemActions from './agenda-item-actions';
import AgendaItemContent from './agenda-item-content';
import AgendaItemLabels from './agenda-item-labels';
import { AGENDA_ITEM } from './constants';
import { assign, transform } from 'lodash';
import { ACTIONS as A } from 'store/components/agenda-view';

export default Vue.component(AGENDA_ITEM, {
	components: { AgendaItemActions, AgendaItemContent, AgendaItemLabels },
	mixins: [agendaMixin],
	props: {
		actions: optionalArray,
		collapsed: {
			type: [Boolean, String],
			default: false
		},
		description: optionalString,
		horizontalLabels: optionalArray,
		icon: {
			type: String,
			default: 'file-blank'
		},
		itemType: optionalString,
		typeLabel: optionalString,
		labels: optionalArray,
		layout: optionalObject,
		onClick: requiredFunction,
		status: optionalString,
		subtitle: optionalString,
		title: requiredString,
		storePath: requiredString

	},
	data: ({ collapsed }) => ({
		isCollapsed: collapsed
	}),

	computed: {
		toggleCollapsedLabel: ({ $t, isCollapsed }) => isCollapsed ?
			$t('agenda.actions.expand-single') :
			$t('agenda.actions.collapse-single'),
		mainClassName: ({ $options, itemType, status }) => ({
			[$options.name]: true,
			[`${$options.name}--${itemType}`]: true,
			[`${$options.name}--${status}`]: true
		}),
		wrapperClassName: (
			{ actionsVisible, elementClassName, isCollapsed, layout, statusVisible }
		) => ({
			[elementClassName('wrapper')]: true,
			[elementClassName('wrapper--with-actions')]: actionsVisible,
			[elementClassName('wrapper--collapsed')]: isCollapsed,
			[elementClassName('wrapper--status-after')]: layout.statusAfter,
			[elementClassName('wrapper--status-hidden')]: !statusVisible,
			[elementClassName('wrapper--narrow')]: layout.narrow,
			[elementClassName('wrapper--right-align')]: layout.rightAlign,
			[elementClassName('wrapper--labels-before-information')]: layout.labelsBeforeInformation
		}),
		classNames: ({ elementClassName }) => ({
			actions: {
				actions: elementClassName('actions'),
				action: elementClassName('action'),
				actionContainer: elementClassName('action-container')
			},
			content: transform(
				['content', 'title', 'icon', 'title-text', 'subtitle', 'description'],
				(result, name) => assign(result, { [name]: elementClassName(name) }),
				{}
			)
		}),
		itemTypeLabel: ({ itemType, typeLabel }) => typeLabel ?
			t(typeLabel) :
			t(`agenda.type.${itemType}`),
		statusVisible: ({ layout }) => layout.showStatus !== false,
		actionsVisible: ({ actions, isCollapsed }) => !!actions.length && !isCollapsed
	},

	mounted () {
		this.unsubscribe = this.$store.subscribeAction((action) => {
			if (action.type === `${this.storePath}/${A.SET_COLLAPSED}`) {
				this.isCollapsed = action.payload;
			}
		});
	},

	destroyed () {
		this.unsubscribe();
	},

	methods: {
		toggleCollapsed () {
			this.isCollapsed = !this.isCollapsed;
		},
		// somehow `elementClassName` gotten from the mixin has got the wrong `this` context
		elementClassName (suffix) {
			return `${this.$options.name}__${suffix}`;
		}
	}
});
</script>
