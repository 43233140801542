import t from 'translate';

const card = {
	title: t('My personal diary'),
	name: 'diary',
	url: 'treatment/:treatmentId/diary',

	ctrl: ({ cardData, populate }) => {
		cardData.set('filter', {
			month: null,
			year: null
		});

		populate();
	}
};

export default card;
