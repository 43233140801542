import {
	dataKey,
	defaultVariantName
} from './sidebar-meta';

export default [
	{
		name: defaultVariantName,
		[dataKey]: [
			{
				label: 'My Profile',
				translate: true,
				icon: 'respondent',
				linkType: 'card',
				target: 'respondent-my-profile',
				openInNewWindow: false,
				size: 'bigger',
				style: 'profile',
				counter: '',
				acl: []
			}, {
				label: 'Dashboard',
				translate: true,
				icon: 'reports',
				linkType: 'card',
				target: 'respondent-dashboard',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: []
			}, {
				label: 'Messages',
				translate: true,
				icon: 'message',
				linkType: 'card',
				target: 'messages',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: 'messages',
				acl: []
			}, {
				label: 'Assessments',
				translate: true,
				icon: 'assessment',
				linkType: 'card',
				target: 'assessment-list',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: 'assessments',
				acl: []
			}, {
				label: 'Reports',
				translate: true,
				icon: 'report',
				linkType: 'card',
				target: 'reports-list',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '__hack__reportsList',
				acl: []
			}, {
				label: 'My personal diary',
				translate: true,
				icon: 'file-blank',
				linkType: 'card',
				target: 'diary',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: []
			}, {
				label: 'goals.goals',
				translate: true,
				icon: 'goal',
				linkType: 'card',
				target: 'goals',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: []
			}, {
				label: 'Content packages',
				translate: true,
				icon: 'content-package',
				linkType: 'card',
				target: 'information-packages',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: 'contentPackages',
				acl: []
			}, {
				label: 'Exercises',
				translate: true,
				icon: 'activity',
				linkType: 'card',
				target: 'exercises',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: []
			}, {
				label: 'Media and documents',
				translate: true,
				icon: 'media',
				linkType: 'card',
				target: 'media',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: []
			}, {
				label: 'Pages',
				translate: true,
				icon: 'page',
				linkType: 'card',
				target: 'pages',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: []
			}, {
				label: 'Circles',
				translate: true,
				icon: 'circles',
				linkType: 'card',
				target: 'circles-startpage',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: []
			}, {
				label: 'Connected apps',
				translate: true,
				icon: 'check-circle',
				linkType: 'card',
				target: 'connected-apps',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: []
			}, {
				label: 'Log out',
				translate: true,
				icon: 'logout',
				linkType: 'card',
				target: 'logout',
				openInNewWindow: false,
				size: 'default',
				style: 'default',
				counter: '',
				acl: []
			}
		]
	}
];
