import { GIVEN, NOT_GIVEN, NOT_SUBMITTED, PARTIALLY_GIVEN, labels } from './statuses';
import t from 'translate';

export const description = (item) => ({
	[NOT_SUBMITTED]: t('consents.description.notSubmitted'),
	[GIVEN]: t('consents.description.given'),
	[PARTIALLY_GIVEN]: t('consents.description.partiallyGiven'),
	[NOT_GIVEN]: t('consents.description.notGiven')
})[item.status];

export const title = (item) => labels()[item.status];
