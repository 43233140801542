export const AGENDA_ADD_ITEM = 'agenda-add-item';
export const AGENDA_FILTER = 'agenda-filter';
export const AGENDA_GROUP = 'agenda-group';
export const AGENDA_GROUP_TITLE = 'agenda-group-title';
export const AGENDA_GROUPS = 'agenda-groups';
export const AGENDA_ITEM = 'agenda-item';
export const AGENDA_ITEM_ACTIONS = 'agenda-item-actions';
export const AGENDA_ITEM_CONTENT = 'agenda-item-content';
export const AGENDA_ITEM_LABELS = 'agenda-labels';
export const AGENDA_ITEMS = 'agenda-items';
export const AGENDA_VIEW = 'agenda-view';

export const SUFFIXES = ['🐻', '🧸', '🐨', '🐼'];
