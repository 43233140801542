import t from 'translate';
import store from 'store';
import cardUrl from 'cwcardurl';
import treatmentContextParam from 'modules/treatments/util/treatment-context-param';

const card = {
	url: [
		'treatment/:treatmentId/circles/banned',
		'circles/banned'
	],
	name: 'circles-banned',
	title: t('Circles banned information'),

	ctrl: ({ populate, close }) => {
		if (!store.state.user.circleUser.isBanned) {
			close({ go: cardUrl.buildUrl('circles-startpage', treatmentContextParam()) });
			return;
		}
		populate();
	}
};

export { card as circlesBanned };
