import Vue from 'vue';
import Datetime from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import t from 'translate';
import datetime from 'datetime';

Vue.use(Datetime);

/* Wrapper for vue-datetime component.
 * Instead of actual date, an object (`attrs`) + date attribute key name (`dateAttr`) are accepted
 * so that external changes (such as adjusting start date & end date) can be watched.
 */
export default ({ type, attrs, set, max, min, format, dateAttr, timezone }) => new Vue({
	el: document.createElement('div'),
	template: `
		<datetime
			v-model="date"
			type="${type}"
			:max-datetime="max"
			:min-datetime="min"
			:phrases="phrases"
			:format="format"
			zone="local"
			value-zone="local"
			input-class="form-view__input"
		/>
	`,
	data: {
		attrs,
		max,
		min,
		format,
		timezone,
		phrases: {
			ok: t('Ok'),
			cancel: t('Cancel')
		}
	},

	computed: {
		dateVal () {
			return this.attrs[dateAttr] || '';
		},
		date: {
			get () {
				// eslint-disable-next-line no-useless-escape
				const tzSuffix = /:00.000\+\d\d\:\d\d/;
				const utcSuffix = ':00.000Z';

				const date = this.dateVal.replace(tzSuffix, '').replace(utcSuffix, '');

				if (this.timezone) {
					return datetime(date)
						.setTimezone(this.timezone)
						.toMoment()
						.format('YYYY-MM-DD[T]HH:mm');
				}
				return date;
			},
			set
		}
	}
});
