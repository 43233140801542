import { defaultsDeep } from 'lodash';
import { TREATMENT } from 'store/treatments/rp-treatment';

export default {
	data: () => ({
		emptyGoal: {
			name: '',
			category: [],
			deadline: '',
			description: ''
		},

		emptyActivity: {
			name: '',
			description: '',
			startDate: '',
			deadline: '',
			goal: null
		}
	}),
	computed: {
		timezone: ({ $store }) => $store.getters['respondent/timezone'],
		respondentId: ({ $store }) => $store.getters['respondent/respondentId'],
		treatmentId: ({ $store }) => (
			$store.getters['treatment/treatmentId'] ||
			$store.getters[TREATMENT.ID]
		),
		settings: ({ config }) => defaultsDeep(config.agendaSettings, {
			initialCompressed: false,
			initialScope: 'month',
			translate: false,
			limitByRange: false
		})
	},
	methods: {
		toggleGoalStatus (goal) {
			this.$store.dispatch('goals/toggleStatus', goal);
		},

		toggleActivityStatus (activity) {
			this.$store.dispatch('activities/toggleStatus', { activity, inGoal: !!this.goalId });
		}
	}
};
