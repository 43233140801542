<template>
	<menu-toggle-button
		v-if="viable"
		:is-selected="isSelected"
		:items="treatments"
		:is-loading="isLoading"
		:allow-toggle="treatments.length > 1"
		name="switch-treatment"
		class="toggle-treatment"
		:title="$t('treatmentSelection.treatmentToggleTitle')"
		@toggleClick="toggleMenuOrClearTreatment"
		@firstItemClick="clearTreatment"
		@itemClick="selectTreatment"
	>
		<template #buttonContent>
			<icon name="treatment-type" />
		</template>
		<template #firstItemContent>
			<icon name="treatment-back" />
			<span>{{$t('treatmentSelection.backToTreatmentSelection')}}</span>
		</template>
		<template #itemContent="{ item }">
			{{title(item)}}
		</template>
	</menu-toggle-button>
</template>

<script>
import MenuToggleButton from './menu-toggle-button';
import { find, get } from 'lodash';
import Icon from 'components/icon';
import cardControls from '../../card/services/card-controls';
import { TREATMENT } from 'store/treatments/rp-treatment';
import { TREATMENTS } from 'store/treatments/treatments';
import { APP } from 'store';
import { RESPONDENT_CONSENT_INSTANCES as CONSENTS } from 'store/consents/rp-consent-instances';

export default {
	components: { Icon, MenuToggleButton },

	data: () => ({
		loading: false
	}),

	computed: {
		treatments: ({ $store }) => $store.getters[TREATMENTS.ENABLED],
		disabledTreatments: ({ $store }) => $store.getters[TREATMENTS.DISABLED],
		consentsFromRelations: ({ $store }) => $store.getters[CONSENTS.FROM_RELATIONS],
		consents: ({ $store }) => $store.getters[CONSENTS.ITEMS],

		// set of intuitive rules whether it makes sense to show the button
		shouldShow: ({ treatments, disabledTreatments, consents, consentsFromRelations }) => (
			treatments.length > 1 ||
			(disabledTreatments.length && consents.length) ||
			consentsFromRelations.length
		),

		viable: ({ $store, shouldShow }) => (
			$store.getters.userType === 'respondent' &&
			!$store.getters[APP.DETACHED] &&
			shouldShow
		)
	},

	async created () {
		if (this.$store.getters.userType === 'respondent') {
			await this.$store.dispatch(CONSENTS.INIT_ALL);
		}
	},

	methods: {
		toggleMenuOrClearTreatment () {
			if (this.treatments.length === 1) {
				this.clearTreatment();
			}
		},

		redirect () {
			cardControls.closeCard({
				openCard: 'start',
				cardQuery: { referral: 'switch-treatment' },
				manualOverride: true
			});
		},

		clearTreatment () {
			this.$store.dispatch(TREATMENT.CLEAR);
			this.redirect();
		},

		async selectTreatment ({ item, hideMenu }) {
			if (item.id === this.$store.getters[TREATMENT.ID]) {
				hideMenu();

			} else {
				this.setLoading(item.id);
				await this.$store.dispatch(TREATMENT.SET, item.id);
				this.setLoaded();
				hideMenu();
				this.redirect();
			}
		},

		setLoading (id) {
			this.loading = id;
		},

		setLoaded () {
			this.loading = false;
		},

		isSelected ({ item }) {
			return (this.$store.getters[TREATMENT.ID] &&
				get(item, 'id') === this.$store.getters[TREATMENT.ID]);
		},

		isLoading ({ item }) {
			return item.id === this.loading;
		},

		title (item) {
			const startCard = find(
				this.$store.getters['engine/cards/current'],
				{ 'card-name': 'start' }
			);
			const titleProp = get(startCard, '__temp__titleProperty') || 'treatmentType.name';
			return get(item, titleProp, '');
		}
	}
};
</script>
