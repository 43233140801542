import repository from 'repository';
import { set } from '../__helpers/mutations';
import prefixify from '../__helpers/prefixify';

const prefix = prefixify('adapterInstance');

// getters
const DATA = 'data';

// actions
const FETCH = 'fetch';
const DELETE = 'delete';

export const ADAPTER_INSTANCE = {
	DATA: prefix(DATA),

	FETCH: prefix(FETCH),
	DELETE: prefix(DELETE)
};

export default {
	namespaced: true,

	state: {
		data: null
	},

	getters: {
		[DATA]: (state) => state.data
	},

	mutations: {
		set: set()
	},

	actions: {
		[FETCH]: ({ commit }) =>
			repository.fetchAdapterInstance()
				.then((data) => {
					commit('set', data);
				}),

		[DELETE]: (context, { adapterInstanceId }) =>
			repository.deleteAdapterInstance(adapterInstanceId)
	}
};
