<template>
	<div class="circle-members">
		<section class="circle-members__owner-list">
			<h3>{{t('Circle owner')}}</h3>
			<div class="circle-members__list-item">
				<avatar-nickname :user="owner" />
			</div>
		</section>

		<section class="circle-members__member-list">
			<h3>{{t('Circle members')}}</h3>
			<p class="circle-members__member-list-counter">
				({{resultsCount}})
			</p>
			<ul class="circle-members__list">
				<li
					v-for="member in list"
					:key="member.id"
					class="circle-members__list-item"
				>
					<avatar-nickname :user="member"	/>
					<button
						v-if="!isOwner(member)"
						class="circle-members__member-delete"
						@click="remove(member)"
					><icon name="delete" /></button>
				</li>
			</ul>
			<button
				v-if="needMore"
				class="circle-members__member-list-action flat-button__button"
				@click="loadMore"
			>{{t('More results')}}</button>
		</section>
	</div>
</template>

<script>
import t from 'translate';
import { mapState, mapGetters, mapActions } from 'vuex';
import cwalert from 'cwalert';
import confirmation from 'components/confirmation/confirmation';
import avatarNickname from '../../components/avatar-nickname';
import enableCirclesCheck from '../../helpers/enable-circles-check';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints';

export default {
	title: t('Circle members'),
	acl: [{
		op: READ,
		checkpoint: CLINICIAN.CIRCLES.CIRCLES
	}],
	instant: true,
	features: enableCirclesCheck,
	components: { ...avatarNickname },
	computed: {
		...mapState('circles/members', ['current', 'totalCount']),
		...mapGetters('circles/members', ['list']),
		...mapGetters('circles', ['owner']),

		needMore: ({ current, totalCount }) => totalCount > current.length,

		resultsCount: ({ current, totalCount }) => t('{current} of {total} records displayed', {
			current: current.length,
			total: totalCount
		})
	},

	methods: {
		...mapActions('circles/members', ['loadMore', 'loadAll']),

		remove (member) {
			confirmation({
				icon: 'delete',
				warning: true,
				title: t('Remove member'),
				message: t('Are you sure you want to remove this member?')
			})
				.then(() => this.$store.dispatch('circles/members/remove', { member }))
				.then(() => {
					cwalert.success(t('Member has been removed'));
				});
		},

		isOwner (member) {
			return member.id === this.owner.id;
		}
	}
};
</script>
