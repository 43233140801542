import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import Assessment from './assessment';
import { Deferred } from 'jquery';
import { assign } from 'lodash';
import { GET } from 'service/ajax/ajax';

const AssessmentsEntity = MultipleEntities.extend({
	urls,
	model: Assessment,
	idName: 'assessmentId',
	eventNamespace: 'assessment',

	getAll () {
		return this.retrieve('allAssessments');
	},

	getEnabled (params = {}) {
		return this.retrieve('enabledAssessments', params);
	},

	getAutospawn () {
		return this.retrieve('autospawnAssessments');
	},

	getExportAvailable (templateId) {
		const available = this.getAvailable(templateId);
		const dfd = Deferred();
		const clone = assign(this.clone(), dfd);

		available.then((result) => {
			clone.add(result);
			clone.resolve();
		});

		return clone;
	},

	search (query) {
		const dfd = Deferred();
		const clone = assign(this.clone(), dfd);

		const assessments = GET({
			url: urls.enabledAssessments.url({
				currentAdministrator: 1,
				...query
			}),
			separator: '&'
		});

		assessments.then((data, status, response) => {
			clone.add(data);
			clone.resolve();

			if (query.search !== '') {
				clone.total = +response.getResponseHeader('X-Total-Count');
			}
		});
		return clone;
	}
});

export default new AssessmentsEntity();
