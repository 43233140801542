import repository from 'repository';
import { Deferred } from 'jquery';
import tilesModule from './tiles';
import {
	assign, cloneDeep, compact, concat, defaults, difference, filter, find, findIndex, flow,
	forEach, get, includes, isArray, isNumber, map, pick, reduce, reject, set, some, sortBy,
	toLower, join
} from 'lodash';
import { capitalize } from 'lib/string';
import Vue from 'vue';
import { NONE } from 'store/engine/variant-criteria';
import { set as provideSet } from '../__helpers/mutations';

const updateState = ({ state, path, value }) => {
	state.custom[state.selected] = compact(state.custom[state.selected]);
	return cloneDeep(set(state.custom, path, value));
};

const PORTALS = ['assessment', 'atomic', 'respondent', 'clinician'];
const SET_TILE_PROP = 'tiles/setTileProp';
const CURRENT_TILE = 'tiles/currentTile';

export default {
	namespaced: true,
	modules: { tiles: tilesModule },
	state: {
		availableCards: [],
		availableCardOptions: [],
		availableTiles: [],
		blueprint: {
			assessment: [],
			atomic: [],
			clinician: [],
			respondent: []
		},
		custom: {
			assessment: [],
			atomic: [],
			clinician: [],
			respondent: []
		},
		features: [],
		selected: 'clinician',
		tempSelected: 'clinician',
		customOnly: false,
		filter: '',
		currentCardName: '',
		variantIndex: null,
		selectedFeatures: []
	},

	getters: {
		features: (state) => map(state.features, 'name').sort((a, b) => a.localeCompare(b)),

		/*
		 * FROM: [
		 *         { name: 'A', cards: ['x', y'] },
		 *         { name: 'B', cards: ['w', 'y'] }
		 *       ]
		 * TO:   [
		 *         { card: 'x', features: ['A'] },
		 *         { card: 'w', features: ['B'] },
		 *         { card: 'y', features: ['A', 'B'] }
		 *       ]
		 */
		featuresByCard: (state) => reduce(state.features, (features, feature) => {
			const cardFound = find(
				features,
				(existingFeature) => includes(feature.cards, existingFeature.card)
			);

			if (cardFound) {
				cardFound.features.push(feature.name);

			} else {
				features.push(...map(
					feature.cards,
					(card) => ({ card, features: [feature.name] })
				));
			}
			return features;
		}, []),

		selectedFeatures: (state) => state.selectedFeatures,

		current: (state, getters) => {
			const data = state.customOnly ?
				state.custom[state.selected] :
				state.blueprint[state.selected];

			return flow([
				(cards) => filter(cards, (card) => includes(
					toLower(card['card-name']),
					toLower(state.filter)
				)),
				(cards) => map(cards, (card) => {
					const custom = state.customOnly ?
						card :
						find(state.custom[state.selected], { 'card-name': card['card-name'] });

					const additionalProps = {
						name: card['card-name'],
						customized: state.customOnly || !!custom,
						variants: get(custom || card, 'alternativeCards', []).length,
						features: get(
							find(getters.featuresByCard, { card: card['card-name'] }),
							'features',
							[]
						)
					};
					return assign({}, custom || card, additionalProps);
				}),
				(cards) => getters.selectedFeatures.length ?
					filter(
						cards,
						(card) => some(
							card.features,
							(feature) => includes(getters.selectedFeatures, feature)
						)
					) :
					cards,
				(cards)	=> sortBy(cards, 'card-name')
			])(data);
		},

		currentCardIndex: (state) => findIndex(
			state.custom[state.selected],
			{ 'card-name': state.currentCardName }
		),

		currentCard: (state, getters) => defaults(
			find(getters.current, { 'card-name': state.currentCardName }),
			{ alternativeCards: [] }
		),

		selectedVariant: (state, getters) => get(
			getters.currentCard,
			`alternativeCards[${state.variantIndex}]`,
			{}
		),

		availableTiles: flow([
			(state) => filter(
				state.availableTiles,
				(tile) => includes(tile.portal, state.selected)
			),
			(tiles) => sortBy(tiles, 'name')
		]),

		currentCardDefinition: (state, getters) => find(state.availableCards, {
			name: get(getters, 'currentCard', {})['card-name']
		}) || {},

		currentCardOptions: (state, getters) => filter(
			state.availableCardOptions,
			(option) => includes(getters.currentCardDefinition.options, option.name)
		),

		allCustomCards: (state) => [
			...state.custom.assessment,
			...state.custom.atomic,
			...state.custom.clinician,
			...state.custom.respondent
		],

		mismatchedCards: (state) => {
			const availableNames = map(state.availableCards, 'name');
			const mismatched = {};
			const filterFn = (card) => !includes(availableNames, get(card, 'card-name'));

			forEach(['clinician', 'respondent', 'assessment', 'atomic'], (type) => {
				mismatched[type] = filter(state.custom[type], filterFn);
			});
			return mismatched;
		},

		wrongPortalCards: (state) => {
			const wrongPortal = {};
			const filterFn = (type) => (card) => {
				const found = find(state.availableCards, { name: get(card, 'card-name') });
				return found && !includes(found.portal, type);
			};

			forEach(['clinician', 'respondent', 'assessment', 'atomic'], (type) => {
				wrongPortal[type] = filter(state.custom[type], filterFn(type));
			});

			return wrongPortal;
		},

		emptyCards: (state, getters) => reject(getters.allCustomCards, 'card-name'),

		registeredTiles: (state, getters) => {
			const registeredTiles = [];
			const hashMap = {};

			forEach(compact(getters.allCustomCards), (card) => {
				const tiles = isArray(card.tiles) ? [...card.tiles] : [];
				const variantTiles = map(card.alternativeCards, 'tiles');

				forEach(concat(tiles, ...variantTiles), (tile) => {
					if (tile && !hashMap[tile.tileName]) {
						if (!tile.tileName) {
							tile.tileName = `[EMPTY_NAME]_[${card['card-name']}]_${Date.now()}`;
						}
						hashMap[tile.tileName] = tile;
						registeredTiles.push(tile);
					}
				});
			});
			return registeredTiles;
		},

		mismatchedTiles: flow([
			(state, getters) => ({
				registered: map(getters.registeredTiles, 'tileName'),
				available: map(state.availableTiles, 'name')
			}),
			({ registered, available }) => difference(registered, available)
		]),

		currentTileName: flow([
			(state, getters) => getters[CURRENT_TILE],
			({ tileName, customTitle }) => join([
				tileName && `${tileName}`,
				customTitle && ` - ${customTitle}`
			])
		])
	},

	mutations: {
		setBlueprint: (state, { type, data }) => {
			state.blueprint[type] = data;
		},

		setCustom: (state, { type, data }) => {
			state.custom[type] = data;
		},

		setAvailable: (state, { availableCards, availableCardOptions, availableTiles }) => {
			assign(state, { availableCards, availableCardOptions, availableTiles });
		},

		setFeatures: provideSet('features'),
		setSelected: provideSet('selected'),
		setFilter: provideSet('filter'),
		setCustomOnly: provideSet('customOnly'),
		setCurrentCardName: provideSet('currentCardName'),
		setVariantIndex: provideSet('variantIndex'),
		setSelectedFeatures: provideSet('selectedFeatures'),

		ensureCustomCardExists: (state, { card }) => {
			if (get(card, 'customized')) {
				return;
			}
			state.custom[state.selected].push(cloneDeep(card));
		},

		setCardProp: (state, { index, key, value }) => {
			let path = `${state.selected}[${index}]`;

			if (key === 'tiles' && isNumber(state.variantIndex)) {
				path += `alternativeCards[${state.variantIndex}].tiles`;

			} else {
				path += `.${key}`;
			}

			state.custom = updateState({ state, path, value });
		},

		setTileProps: (state, { path, tile }) => {
			set(state.custom[state.selected], path, tile);
		},

		setVariantCriterion: (state, { criterion, index }) => {
			const path = `${state.selected}[${index}].alternativeCards[${state.variantIndex}].when`;
			state.custom = updateState({ state, path, value: criterion });
		},

		setVariantName: (state, { index, name }) => {
			const path = state.variantIndex !== null ?
				`${state.selected}[${index}].alternativeCards[${state.variantIndex}].name` :
				`${state.selected}[${index}].variantName`
			;
			state.custom = updateState({ state, path, value: name });
		},

		removeVariant: (state, { index }) => {
			const cloned = cloneDeep(state.custom);
			cloned[state.selected][index].alternativeCards.splice(state.variantIndex, 1);
			state.variantIndex = null;

			state.custom = cloned;
		},

		resetCurrentCard: (state, { card }) => {
			const cards = reject(
				get(state.custom, `${state.selected}`),
				{ 'card-name': card.name }
			);
			state.variantIndex = null;
			Vue.set(state, 'custom', updateState({ state, path: state.selected, value: cards }));
		},

		cloneVariant: (state, { name, card, index, criterion = NONE }) => {
			const path = `${state.selected}[${index}]`;
			const when = { [criterion]: [] };

			const variantToClone = isNumber(state.variantIndex) ?
				cloneDeep(card.alternativeCards[state.variantIndex]) :
				assign(pick(card, 'tiles'), { when });
			assign(variantToClone, { name });

			card.alternativeCards.push(cloneDeep(variantToClone));
			state.custom = updateState({ state, path, value: card });
			state.variantIndex = card.alternativeCards.length - 1;
		},

		removeCard: (state, { card, portal }) => {
			const compare = (customCard) => JSON.stringify(customCard) === JSON.stringify(card);
			const setTempSelected = (newSelected) => {
				state.tempSelected = state.selected;
				state.selected = newSelected;
			};
			const removeCard = ({ type }) => {
				const index = findIndex(state.custom[type], compare);

				if (index > -1) {
					state.custom[type].splice(index, 1);
					setTempSelected(type);
					return false;
				}
				return true;
			};

			if (portal) {
				removeCard({ type: portal });
				return;
			}

			forEach(PORTALS, (type) => removeCard({ type }));
		},

		renameCard: (state, { oldName, newName, portal }) => {
			const index = findIndex(state.custom[portal], { 'card-name': oldName });
			state.custom[portal][index]['card-name'] = newName;
		},

		replaceTile: (state, { tileName, newTile }) => {
			const replaceTile = (tiles) => map(
				tiles,
				(tile) => tile.tileName === tileName ?
					assign(tile, {
						tileName: newTile.tileName,
						tileConfig: assign(tile.tileConfig, newTile.tileConfig),
						containerConfig: assign(tile.tileConfig, newTile.containerConfig)
					}) :
					tile
			);

			const removeTile = (tiles) => reject(tiles, { tileName });
			const changeTile = newTile ? replaceTile : removeTile;

			forEach(PORTALS, (type) => {
				const cards = cloneDeep(state.custom[type]);
				forEach(cards, (card) => {

					if (card.alternativeCards) {
						forEach(card.alternativeCards, (variant) => {
							variant.tiles = changeTile(variant.tiles);
						});
					}

					card.tiles = changeTile(card.tiles);
				});

				state.custom[type] = cards;
			});
		},

		handleTempSelected: (state, bool) => {
			if (bool && state.tempSelected !== state.selected) {
				state.selected = state.tempSelected;
			}
		},

		reset: (state) => {
			state.custom[state.selected] = [];
		}
	},

	actions: {
		init: ({ commit, getters, dispatch }, { type } = { type: 'clinician' }) => {
			if (getters.current.length) {
				return Deferred().resolve();
			}
			dispatch('setType', type);
			const blueprint = {
				assessment: repository.cardConfig({ type: 'blueprintAssessment' }),
				atomic: repository.cardConfig({ type: 'blueprintAtomic' }),
				clinician: repository.cardConfig({ type: 'blueprintClinician' }),
				respondent: repository.cardConfig({ type: 'blueprintRespondent' })
			};
			const custom = {
				assessment: repository.cardConfig({ type: 'customAssessment' }),
				atomic: repository.cardConfig({ type: 'customAtomic' }),
				clinician: repository.cardConfig({ type: 'customClinician' }),
				respondent: repository.cardConfig({ type: 'customRespondent' })
			};

			const availableCards = repository.cardConfig({ type: 'availableCards' });
			const availableCardOptions = repository.cardConfig({ type: 'availableCardOptions' });
			const availableTiles = repository.cardConfig({ type: 'availableTiles' });
			const features = repository.cardConfig({ type: 'features' });

			return Promise.all([
				blueprint.assessment, blueprint.atomic, blueprint.clinician, blueprint.respondent,
				custom.assessment, custom.atomic, custom.clinician, custom.respondent,
				availableCards, availableCardOptions, availableTiles,
				features
			]).then(() => {
				commit('setBlueprint', { type: 'assessment', data: blueprint.assessment.data });
				commit('setBlueprint', { type: 'atomic', data: blueprint.atomic.data });
				commit('setBlueprint', { type: 'clinician', data: blueprint.clinician.data });
				commit('setBlueprint', { type: 'respondent', data: blueprint.respondent.data });
				commit('setCustom', { type: 'assessment', data: custom.assessment.data });
				commit('setCustom', { type: 'atomic', data: custom.atomic.data });
				commit('setCustom', { type: 'clinician', data: custom.clinician.data });
				commit('setCustom', { type: 'respondent', data: custom.respondent.data });
				commit('setAvailable', {
					availableCards: availableCards.data,
					availableCardOptions: availableCardOptions.data,
					availableTiles: availableTiles.data
				});
				commit('setFeatures', features.data);
			});
		},

		setType: ({ commit }, type) => commit('setSelected', type),
		setCustomOnly: ({ commit }, bool) => commit('setCustomOnly', bool),
		setFilter: ({ commit }, filterName) => commit('setFilter', filterName),
		setSelectedFeatures: ({ commit }, features) => commit('setSelectedFeatures', features),

		setCurrentCard: ({ state, commit, getters, dispatch }, cardName) => {
			if (state.currentCardName !== cardName) {
				commit('setCurrentCardName', cardName);
				commit('setVariantIndex', null);
			}

			cardName.length && commit('ensureCustomCardExists', { card: getters.currentCard });
			dispatch('setTiles');
		},

		setTiles: ({ commit, getters, state }) => {
			const card = get(getters, 'currentCard', {});
			const tiles = map(
				get(card, `alternativeCards[${state.variantIndex}].tiles`, card.tiles),
				(tile) => defaults(tile, { containerConfig: {} })
			);

			commit('tiles/setCurrentList', tiles);
		},

		save: (
			{ state, rootState, commit, getters },
			{ depth = 'card', portal, alteringCard, logType, logMessage }
		) => {
			const type = portal ?
				`custom${capitalize(portal)}` :
				`custom${capitalize(state.selected)}`;

			return repository.cardConfig({ type }).then((data) => {
				let savePath = [
					`[${getters.currentCardIndex}]`,
					isNumber(state.variantIndex) ? `alternativeCards[${state.variantIndex}]` : ''
				].join('');
				let workingCopy = cloneDeep(data);

				workingCopy[getters.currentCardIndex] = workingCopy[getters.currentCardIndex] ||
					getters.currentCard;

				const route = {
					all: () => {
						workingCopy = [...state.custom[state.selected]];
					},

					card: () => {
						savePath += '.tiles';
						commit('setCardProp', {
							key: 'tiles',
							value: state.tiles.currentList,
							index: getters.currentCardIndex
						});
						set(workingCopy, savePath, state.tiles.currentList);
					},

					tile: () => {
						savePath += `.tiles[${state.tiles.currentTileIndex}]`;
						commit('setTileProps', {
							path: savePath,
							tile: getters[CURRENT_TILE]
						});
						set(workingCopy, savePath, getters[CURRENT_TILE]);
					}
				}[depth];
				route();

				if (rootState.engine.cards.type === state.selected) {
					commit('engine/cards/setCustom', workingCopy, { root: true });
				}
				const promise = repository.saveCardConfig({
					type,
					data: workingCopy,
					logs: { logType, logMessage }
				});
				commit('handleTempSelected', alteringCard);

				return promise;
			});
		},

		saveAll: ({ dispatch }) => Promise.all(map(
			PORTALS, (portal) => dispatch('save', {
				depth: 'all',
				portal,
				logType: 'CARD_CONFIG_SAVED',
				logMessage: 'Card config saved'
			})
		)),

		setEnabled: ({ state, commit, dispatch, getters }, bool) => {
			commit('setCardProp', {
				key: 'disabled',
				value: !bool,
				index: getters.currentCardIndex
			});
			return dispatch('save', {
				depth: 'all',
				logType: 'CARD_ENABLED_CHANGED',
				logMessage: `Card (${state.currentCardName}) property [Enabled] set to ${!bool}`
			});
		},

		setGreedy: ({ state, commit, dispatch, getters }, bool) => {
			commit('setCardProp', {
				key: 'greedyTiles',
				value: bool ? [2] : false,
				index: getters.currentCardIndex
			});
			return dispatch('save', {
				depth: 'all',
				logType: 'CARD_GREEDY_CHANGED',
				logMessage: `Card (${state.currentCardName}) property [Greedy] changed`
			});
		},

		setTranslate: ({ state, commit, dispatch, getters }, bool) => {
			commit('setCardProp', {
				key: 'translate',
				value: bool,
				index: getters.currentCardIndex
			});
			return dispatch('save', {
				depth: 'all',
				logType: 'CARD_TRANSLATE_CHANGED',
				logMessage: `Card (${state.currentCardName}) property [Translate] changed`
			});
		},

		setVariant: ({ commit, dispatch }, index) => {
			commit('setVariantIndex', isFinite(+index) ? +index : null);
			dispatch('setTiles');
		},

		setVariantName: ({ commit, dispatch, getters }, { name }) => {
			commit('setVariantName', { name, index: getters.currentCardIndex });
			return dispatch('save', {
				depth: 'all',
				logType: 'VARIANT_NAME_CHANGED',
				logMessage: `Card (${getters.currentCard.name}) variant changed`
			});
		},

		removeCurrentVariant: ({ commit, dispatch, getters }) => {
			commit('removeVariant', { index: getters.currentCardIndex });
			return dispatch('save', {
				depth: 'all',
				logType: 'CARD_VARIANT_DELETED',
				logMessage: `Card (${getters.currentCard.name}) variant deleted`
			});
		},

		setCriterion: ({ commit, dispatch, getters }, criterion) => {
			commit('setVariantCriterion', { criterion, index: getters.currentCardIndex });
			return dispatch('save', {
				depth: 'all',
				logType: 'VARIANT_CRITERION_CHANGED',
				logMessage: `Card (${getters.currentCard.name}) variant criterion changed`
			});
		},

		setCustomProp: ({ commit, dispatch, getters }, { key, value }) => {
			commit('setCardProp', { key, value, index: getters.currentCardIndex });
			return dispatch('save', {
				depth: 'all',
				logType: 'CARD_CUSTOM_PROP_CHANGED',
				logMessage: `Card (${getters.currentCard.name}) custom property changed`
			});
		},

		resetCurrentCard: ({ commit, dispatch, getters }) => {
			commit('resetCurrentCard', { card: getters.currentCard });
			commit('ensureCustomCardExists', { card: getters.currentCard });
			dispatch('setTiles');
			return dispatch('save', {
				depth: 'all',
				logType: 'CARD_RESET',
				logMessage: `Card (${getters.currentCard.name}) reset`
			});
		},

		cloneVariant: ({ commit, dispatch, getters }, { name, criterion }) => {
			commit('cloneVariant', {
				name,
				criterion,
				card: getters.currentCard,
				index: getters.currentCardIndex
			});
			return dispatch('save', {
				depth: 'all',
				logType: 'CARD_VARIANT_CLONED',
				logMessage: `Card (${getters.currentCard.name}) variant cloned into variant (${
					name
				})`
			});
		},

		removeTile: ({ commit, state, getters, dispatch }, idx) => {
			const index = Number.isFinite(idx) ? idx : +state.tiles.currentTileIndex;
			const tileName = getters.currentTileName ||
				get(state.tiles.currentList[index], 'tileName');
			commit('tiles/removeTile', index);
			return dispatch('save', {
				logType: 'TILE_DELETED',
				logMessage: `Tile (${tileName}) deleted on card (${
					state.currentCardName
				})`
			});
		},

		moveTileUp: ({ state, commit, dispatch }, tileIndex) => {
			const tileName = get(state.tiles.currentList[tileIndex], 'tileName');
			commit('tiles/moveTile', { tileIndex, amount: -1 });
			return dispatch('save', {
				logType: 'TILE_POSITION_MOVED_UP',
				logMessage: `Tile (${tileName}) position moved up on card (${
					state.currentCardName
				})`
			});
		},

		moveTileDown: ({ state, commit, dispatch }, tileIndex) => {
			const tileName = get(state.tiles.currentList[tileIndex], 'tileName');
			commit('tiles/moveTile', { tileIndex, amount: 1 });
			return dispatch('save', {
				logType: 'TILE_POSITION_MOVED_DOWN',
				logMessage: `Tile (${tileName}) position moved down on card (${
					state.currentCardName
				})`
			});
		},

		setCurrentTile: ({ commit }, { tileName, tileIndex }) => {
			commit('tiles/setCurrentTile', { tileName, tileIndex });
		},

		setTileConfig: ({ state, getters, commit, dispatch }, { config, create }) => {
			const depth = create ? 'card' : 'tile';
			commit('tiles/setTileConfig', { config, create });
			return dispatch('save', {
				depth,
				logType: create ? 'CARD_CONFIG_CHANGED' : 'TILE_CONFIG_CHANGED',
				logMessage: create ?
					`Card (${state.currentCardName}) config changed` :
					`Tile (${getters.currentTileName}) config changed on card (${
						state.currentCardName
					})`
			});
		},

		setTileContainerProp: ({ state, getters, commit, dispatch }, { path, value }) => {
			commit(SET_TILE_PROP, { path, value, containerConfig: true });
			return dispatch('save', {
				depth: 'card',
				logType: 'TILE_PROP_CHANGED',
				logMessage:
					`Tile (${getters.currentTileName}) container property changed on card (${
						state.currentCardName
					})`
			});
		},

		createTile: ({ state, commit, getters, dispatch }, tileName) => {
			const title = find(getters.availableTiles, { name: tileName }).title || tileName;
			commit('tiles/createTile', { tileName, title });
			return dispatch('save', {
				depth: 'card',
				logType: 'TILE_ADDED',
				logMessage: `Tile (${title}) added on card (${
					state.currentCardName
				})`
			});
		},

		removeTileConfigKey: ({ state, getters, commit, dispatch }, { key }) => {
			commit(SET_TILE_PROP, { path: key, tileConfig: true, remove: true });
			return dispatch('save', {
				depth: 'tile',
				logType: 'TILE_CONFIG_KEY_DELETED',
				logMessage: `Tile (${getters.currentTileName}) config key deleted on card (${
					state.currentCardName
				})`
			});
		},

		setTileConfigKey: ({ state, getters, commit, dispatch }, { oldKey, newKey }) => {
			commit('tiles/renameTileKey', { oldKey, newKey, tileConfig: true });
			return dispatch('save', {
				depth: 'tile',
				logType: 'TILE_CONFIG_KEY_CHANGED',
				logMessage: `Tile (${getters.currentTileName}) config key changed on card (${
					state.currentCardName
				})`
			});
		},

		setTileConfigValue: ({ state, getters, commit, dispatch }, { path, value }) => {
			commit(SET_TILE_PROP, { path, value, tileConfig: true });
			return dispatch('save', {
				depth: 'tile',
				logType: 'TILE_CONFIG_VALUE_CHANGED',
				logMessage: `Tile (${getters.currentTileName}) config value changed on card (${
					state.currentCardName
				})`
			});
		},

		setTileCustomTitle: ({ state, getters, commit, dispatch }, customTitle) => {
			commit(SET_TILE_PROP, { path: 'customTitle', value: customTitle });
			return dispatch('save', {
				depth: 'tile',
				logType: 'TILE_CUSTOM_TITLE_CHANGED',
				logMessage: `Tile (${getters.currentTileName}) custom title changed on card (${
					state.currentCardName
				})`
			});
		},

		renameCard: ({ state, commit, dispatch }, { oldName, newName, portal }) => {
			commit('renameCard', { oldName, newName, portal });
			return dispatch('save', {
				depth: 'all',
				alteringCard: true,
				logType: 'CARD_NAME_CHANGED',
				logMessage: `Card (${state.currentCardName}) name changed`
			});
		},

		removeCard: ({ state, commit, dispatch }, { card, portal }) => {
			commit('removeCard', { card, portal });
			return dispatch('save', {
				depth: 'all',
				alteringCard: true,
				logType: 'CARD_DELETED',
				logMessage: `Card (${state.currentCardName}) deleted`
			});
		},

		replaceTile: ({ commit, dispatch }, { tileName, newTile }) => {
			commit('replaceTile', { tileName, newTile });
			return dispatch('saveAll');
		},

		reset: ({ commit, dispatch }) => {
			commit('reset');
			return dispatch('save', {
				depth: 'all',
				logType: 'CARD_CONFIG_RESET',
				logMessage: `All cards have been reset`
			});
		}
	}
};
