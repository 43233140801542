var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "edit-object__rows" },
    [
      _vm._l(_vm.objVal, function(row, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass: "array-row edit-object__row edit-object__row--edit"
          },
          [
            _c("p", { staticClass: "array-row__index" }, [
              _vm._v("\n\t\t\t" + _vm._s(index) + "\n\t\t")
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "edit-object__fields" },
              [
                _vm._l(row, function(rowVal, rowKey) {
                  return _c(
                    "li",
                    { key: rowKey, staticClass: "array-row__field" },
                    [
                      _c(
                        "input",
                        _vm._g(
                          {
                            directives: [{ name: "trim", rawName: "v-trim" }],
                            staticClass: "array-row__text",
                            attrs: { type: "text", readonly: _vm.readonly },
                            domProps: { value: rowKey },
                            on: {
                              blur: function($event) {
                                return _vm.onRowKeyChange(index, rowKey, $event)
                              }
                            }
                          },
                          _vm.inputListeners
                        )
                      ),
                      _vm._v(" "),
                      _vm.rowValType(rowVal) === "string" ||
                      _vm.rowValType(rowVal) === "number"
                        ? _c(
                            "input",
                            _vm._g(
                              {
                                directives: [
                                  { name: "trim", rawName: "v-trim" }
                                ],
                                staticClass: "array-row__text",
                                attrs: { type: "text", readonly: _vm.readonly },
                                domProps: { value: rowVal },
                                on: {
                                  blur: function($event) {
                                    return _vm.onRowValChange(
                                      index,
                                      rowKey,
                                      $event
                                    )
                                  }
                                }
                              },
                              _vm.inputListeners
                            )
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.rowValType(rowVal) === "boolean"
                        ? _c("input", {
                            staticClass: "array-row__array",
                            attrs: { type: "checkbox", readonly: _vm.readonly },
                            domProps: { value: rowVal, checked: rowVal },
                            on: {
                              change: function($event) {
                                return _vm.onRowValChange(index, rowKey, $event)
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.rowValType(rowVal) === "array"
                        ? _c("array-input", {
                            staticClass: "array-row__array",
                            attrs: {
                              "obj-val": rowVal,
                              readonly: _vm.readonly,
                              "on-value-change": _vm.onRowArrayChange(
                                index,
                                rowKey
                              ),
                              path: _vm.path
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.readonly
                        ? _c(
                            "button",
                            {
                              directives: [
                                { name: "translate", rawName: "v-translate" }
                              ],
                              staticClass:
                                "edit-object__button--remove edit-object__button--remove-item",
                              on: {
                                click: function($event) {
                                  return _vm.removeRowValue(index, rowKey)
                                }
                              }
                            },
                            [_vm._v("editObject.removeItem")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "array-row__field array-row__field--add" },
                  [
                    _c("label", [
                      _c(
                        "p",
                        {
                          directives: [
                            { name: "translate", rawName: "v-translate" }
                          ],
                          staticClass: "add-item-description"
                        },
                        [_vm._v("editObject.newStringVal")]
                      ),
                      _vm._v(" "),
                      _c(
                        "input",
                        _vm._g(
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.newTextRowKey[index],
                                expression: "newTextRowKey[index]"
                              },
                              { name: "trim", rawName: "v-trim" }
                            ],
                            staticClass: "array-row__text",
                            attrs: { type: "text", readonly: _vm.readonly },
                            domProps: { value: _vm.newTextRowKey[index] },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.newTextRowKey,
                                  index,
                                  $event.target.value
                                )
                              }
                            }
                          },
                          _vm.inputListeners
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "input",
                      _vm._g(
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newTextRowValue[index],
                              expression: "newTextRowValue[index]"
                            },
                            { name: "trim", rawName: "v-trim" }
                          ],
                          staticClass: "array-row__text",
                          attrs: { type: "text", readonly: _vm.readonly },
                          domProps: { value: _vm.newTextRowValue[index] },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newTextRowValue,
                                index,
                                $event.target.value
                              )
                            }
                          }
                        },
                        _vm.inputListeners
                      )
                    ),
                    _vm._v(" "),
                    !_vm.readonly
                      ? _c(
                          "button",
                          {
                            directives: [
                              { name: "translate", rawName: "v-translate" }
                            ],
                            staticClass:
                              "edit-object__button--add edit-object__button--add-item",
                            on: {
                              click: function($event) {
                                return _vm.addRowValue(
                                  index,
                                  "newTextRowKey",
                                  "newTextRowValue"
                                )
                              }
                            }
                          },
                          [_vm._v("editObject.addItem")]
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "array-row__field array-row__field--add" },
                  [
                    _c("label", [
                      _c(
                        "p",
                        {
                          directives: [
                            { name: "translate", rawName: "v-translate" }
                          ],
                          staticClass: "add-item-description"
                        },
                        [_vm._v("editObject.newBooleanVal")]
                      ),
                      _vm._v(" "),
                      _c(
                        "input",
                        _vm._g(
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.newBooleanRowKey[index],
                                expression: "newBooleanRowKey[index]"
                              },
                              { name: "trim", rawName: "v-trim" }
                            ],
                            staticClass: "array-row__text",
                            attrs: { type: "text", readonly: _vm.readonly },
                            domProps: { value: _vm.newBooleanRowKey[index] },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.newBooleanRowKey,
                                  index,
                                  $event.target.value
                                )
                              }
                            }
                          },
                          _vm.inputListeners
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newBooleanRowValue[index],
                          expression: "newBooleanRowValue[index]"
                        }
                      ],
                      staticClass: "array-row__checkbox",
                      attrs: { type: "checkbox", readonly: _vm.readonly },
                      domProps: {
                        checked: Array.isArray(_vm.newBooleanRowValue[index])
                          ? _vm._i(_vm.newBooleanRowValue[index], null) > -1
                          : _vm.newBooleanRowValue[index]
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.newBooleanRowValue[index],
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.newBooleanRowValue,
                                  index,
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.newBooleanRowValue,
                                  index,
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.newBooleanRowValue, index, $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    !_vm.readonly
                      ? _c(
                          "button",
                          {
                            directives: [
                              { name: "translate", rawName: "v-translate" }
                            ],
                            staticClass:
                              "edit-object__button--add edit-object__button--add-item",
                            on: {
                              click: function($event) {
                                return _vm.addRowValue(
                                  index,
                                  "newBooleanRowKey",
                                  "newBooleanRowValue"
                                )
                              }
                            }
                          },
                          [_vm._v("editObject.addTheItem")]
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "array-row__field array-row__field--add" },
                  [
                    _c("label", [
                      _c(
                        "p",
                        {
                          directives: [
                            { name: "translate", rawName: "v-translate" }
                          ],
                          staticClass: "add-item-description"
                        },
                        [_vm._v("editObject.newArrayVal")]
                      ),
                      _vm._v(" "),
                      _c(
                        "input",
                        _vm._g(
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.newArrayRowKey[index],
                                expression: "newArrayRowKey[index]"
                              },
                              { name: "trim", rawName: "v-trim" }
                            ],
                            staticClass: "array-row__text",
                            attrs: { type: "text", readonly: _vm.readonly },
                            domProps: { value: _vm.newArrayRowKey[index] },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.newArrayRowKey,
                                  index,
                                  $event.target.value
                                )
                              }
                            }
                          },
                          _vm.inputListeners
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("array-input", {
                      staticClass: "array-row__array",
                      attrs: {
                        "obj-val": _vm.newArrayRowValue[index],
                        readonly: _vm.readonly,
                        "on-value-change": _vm.onNewArrayRowValueChange(index),
                        path: _vm.path
                      }
                    }),
                    _vm._v(" "),
                    !_vm.readonly
                      ? _c(
                          "button",
                          {
                            directives: [
                              { name: "translate", rawName: "v-translate" }
                            ],
                            staticClass:
                              "edit-object__button--add edit-object__button--add-item",
                            on: {
                              click: function($event) {
                                return _vm.addRowValue(
                                  index,
                                  "newArrayRowKey",
                                  "newArrayRowValue"
                                )
                              }
                            }
                          },
                          [_vm._v("editObject.addTheItem")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              2
            ),
            _vm._v(" "),
            !_vm.readonly
              ? _c("button", {
                  staticClass: "edit-object__button--remove",
                  on: {
                    click: function($event) {
                      return _vm.removeArrayValue(index)
                    }
                  }
                })
              : _vm._e()
          ]
        )
      }),
      _vm._v(" "),
      _c("li", { staticClass: "edit-object__row edit-object__row--add" }, [
        _c("label", [
          _c(
            "p",
            {
              directives: [{ name: "translate", rawName: "v-translate" }],
              staticClass: "add-item-description"
            },
            [_vm._v("editObject.newObjectVal")]
          ),
          _vm._v(" "),
          _c(
            "input",
            _vm._g(
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newKey,
                    expression: "newKey"
                  },
                  { name: "trim", rawName: "v-trim" }
                ],
                attrs: { type: "text", readonly: _vm.readonly },
                domProps: { value: _vm.newKey },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.newKey = $event.target.value
                  }
                }
              },
              _vm.inputListeners
            )
          )
        ]),
        _vm._v(" "),
        _c(
          "input",
          _vm._g(
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newValue,
                  expression: "newValue"
                },
                { name: "trim", rawName: "v-trim" }
              ],
              attrs: { type: "text", readonly: _vm.readonly },
              domProps: { value: _vm.newValue },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.newValue = $event.target.value
                }
              }
            },
            _vm.inputListeners
          )
        ),
        _vm._v(" "),
        !_vm.readonly
          ? _c("button", {
              staticClass: "edit-object__button--add",
              on: { click: _vm.addObjectArrayValue }
            })
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }