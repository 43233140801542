import { PUT } from 'service/ajax/ajax';
import urls from './urls';

export default {
	/**
	 * Get a CurrentRespondent.
	 *
	 * @function getCurrentRespondent
	 * @returns {object} - Current Respondent.
	 * @example
	 *        Repository.getCurrentRespondent();
	 */
	getCurrentRespondent () {
		return this.CurrentRespondent.retrieve();
	},

	saveCurrentRespondent: (respondent) => PUT({
		data: respondent,
		url: urls.currentRespondent.url()
	})
};
