import { Model } from 'backbone';

const AppContext = Model.extend({
	isClinician () {
		return this.get('type') === 'clinician';
	},
	isRespondent () {
		return this.get('type') === 'respondent';
	},
	getUserId () {
		return this.get('user').get('userId');
	}
});

export default new AppContext();
