var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-value-tile" }, [
    _c("div", { staticClass: "dashboard-value-tile__container" }, [
      _c("h2", { staticClass: "dashboard-value-tile__title" }, [
        _vm._v("\n\t\t\t" + _vm._s(_vm.title) + "\n\t\t")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "dashboard-value-tile__count" }, [
        _vm._v("\n\t\t\t" + _vm._s(_vm.value) + "\n\t\t")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }