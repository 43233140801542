import t from 'translate';

export default {
	respondentFirstName: t('First name'),
	respondentLastName: t('Last name'),
	respondentUsername: t('Username'),
	respondentLanguageId: t('Language'),
	respondentPassword: t('Password'),
	respondentPhone: t('Phone'),
	respondentEmail: t('Email'),
	respondentGender: t('Gender'),
	respondentBirthdate: t('Birthdate'),
	respondentAddress: t('Address'),
	respondentTimezoneName: t('Timezone'),
	respondentCommunicationDisabled: t('Disable communcation'),
	respondentAccountEnabled: t('Account enabled'),
	respondentTestAccount: t('Test account')
};
