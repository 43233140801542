import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import Circle from './circle';

const Circles = new (MultipleEntities.extend({
	model: Circle,
	urls,
	eventNamespace: 'circle',

	list () {
		return this.retrieve('circle');
	},

	listByUser (circleUserId) {
		return this.retrieve('circlesByUser', circleUserId);
	},

	search (params) {
		return this.retrieve('search', params);
	},

	checkCode (code) {
		return this.retrieve('isUnique', code);
	}
}))();

export { Circles };
