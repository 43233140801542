import { find } from 'lodash';
import { LATEST_ASSESSMENT } from 'modules/assessment/constants';
import cardUrl from 'core/engine/card/services/card-url';
import store from 'store';

export default ({ treatmentId }) => {
	const startCard = find(store.getters['engine/cards/current'], { 'card-name': 'start' });
	const open = startCard.open;

	if (treatmentId && open && open !== LATEST_ASSESSMENT) {
		cardUrl.openCard(open, { treatmentId });
		return true;
	}

	return false;
};
