<template>
	<div class="activity">
		<div
			v-if="isCompleted"
			class="activity-achieved-label"
		>
			{{$t('Activity completed')}}
			<icon name="achieved" class-name="activity-achieved-icon" />
		</div>
		<edit-activity
			:activity="activity"
			:goals="goals"
			:is-new="false"
			:on-submit="onSubmit"
			:on-cancel="onCancel"
		/>
	</div>
</template>

<script>
import { cloneDeep, get, find } from 'lodash';
import EditActivity from '../../components/edit-activity';
import cwalert from 'service/cwalert';
import t from 'translate';
import { EDIT } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints.json';
import { RESPONDENT } from 'store/respondents/respondent';
import { ACTIVITY } from 'store/goals/activity';
import { GOALS } from 'store/goals/goals';

export default {
	title: t('goals.editActivity.editTitle'),
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.GOALS_ACTIVITIES,
		op: EDIT
	}],
	actions: ({ $store }) => [
		[ACTIVITY.INIT, {
			activityId: get($store.getters.urlParams, 'activityId'),
			goalId: get($store.getters.urlParams, 'goalId'),
			respondentId: $store.getters[RESPONDENT.RESPONDENT_ID]
		}],
		GOALS.INIT,
		'respondent/init'
	],
	components: { EditActivity },
	data: ({ $store }) => {
		const goals = $store.getters[GOALS.ITEMS];
		const activity = $store.getters[ACTIVITY.ITEM];
		const activityData = {
			...activity,
			goal: find(goals, { id: activity.goal })
		};

		return {
			cachedActivity: cloneDeep(activityData),
			activity: cloneDeep(activityData)
		};
	},
	computed: {
		isCompleted: ({ $store }) => $store.getters[ACTIVITY.IS_COMPLETED],
		goals: ({ $store }) => $store.getters[GOALS.ITEMS]
	},
	methods: {
		async onSubmit () {
			this.setDataLoading();
			await this.$store.dispatch(ACTIVITY.UPDATE, {
				...this.activity,
				isCompleted: this.isCompleted,
				goal: this.activity.goal.id
			});
			cwalert.success(this.$t('goals.editActivity.saveSuccess'));
			this.setDataLoaded();
		},

		onCancel () {
			this.activity = cloneDeep(this.cachedActivity);
		}
	}
};
</script>
