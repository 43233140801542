import { isString, reduce, replace } from 'lodash';

/**
 * Applies variables' values to the string.
 *
 * @param {string} str  - String that should be processed.
 * @param {object} vars - Variables that should be applied.
 * @returns {string}    - Processed string.
 * @example
 *        parseStr('The treatment ID is ${treatmentId}', { treatmentId: 5 });
 *        -> 'The treatment ID is 5'
 */
export const parseStr = (str = '', vars = {}) => reduce(
	vars,
	(result, val, key) => isString(result) ? replace(result, `\${${key}}`, val) : result,
	str
);
