var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("agenda-view", {
        attrs: {
          name: _vm.name,
          items: _vm.parsedItems,
          addable: _vm.addable,
          types: _vm.types,
          "tile-path": _vm.tilePath,
          loading: _vm.loading,
          disabled: !_vm.enabled,
          refresh: _vm.refresh,
          timezone: _vm.timezone,
          settings: _vm.settings
        },
        scopedSlots: _vm._u([
          {
            key: "disabled",
            fn: function() {
              return [
                _c(
                  "p",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }],
                    staticClass: "incomplete-treatment"
                  },
                  [_vm._v("treatment.labels.unavailable")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "empty",
            fn: function() {
              return [
                _c(
                  "p",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }],
                    staticClass: "empty-treatment"
                  },
                  [_vm._v("treatment.labels.empty")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c(
        _vm.popupType,
        _vm._b({ tag: "component" }, "component", _vm.popupProps, false)
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }