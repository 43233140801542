var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isNew && !_vm.goalsList.length
    ? _c(
        "p",
        [
          _c(
            "span",
            { directives: [{ name: "translate", rawName: "v-translate" }] },
            [_vm._v("goals.editActivity.noGoals")]
          ),
          _vm._v(" "),
          _c(
            "button-group",
            [_c("plain-button", { attrs: { "on-click": _vm.onCancel } })],
            1
          )
        ],
        1
      )
    : _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c("text-input", {
            attrs: {
              required: true,
              mandatory: true,
              invalid: _vm.$v.activity.name.$error,
              label: "goals.editActivity.name"
            },
            on: {
              input: function($event) {
                return _vm.$v.activity.name.$touch()
              }
            },
            model: {
              value: _vm.activity.name,
              callback: function($$v) {
                _vm.$set(_vm.activity, "name", $$v)
              },
              expression: "activity.name"
            }
          }),
          _vm._v(" "),
          _vm.goalsList.length
            ? _c("select-input", {
                attrs: {
                  options: _vm.goals,
                  required: true,
                  mandatory: true,
                  invalid: _vm.$v.activity.goal.$error,
                  label: "goals.editActivity.chooseGoal",
                  "item-label": "name"
                },
                on: {
                  input: function($event) {
                    return _vm.$v.activity.goal.$touch()
                  }
                },
                model: {
                  value: _vm.activity.goal,
                  callback: function($$v) {
                    _vm.$set(_vm.activity, "goal", $$v)
                  },
                  expression: "activity.goal"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("datetime-input", {
            attrs: { label: "goals.editActivity.startDate" },
            model: {
              value: _vm.startDate,
              callback: function($$v) {
                _vm.startDate = $$v
              },
              expression: "startDate"
            }
          }),
          _vm._v(" "),
          _c("datetime-input", {
            attrs: { label: "goals.editActivity.endDate" },
            model: {
              value: _vm.deadline,
              callback: function($$v) {
                _vm.deadline = $$v
              },
              expression: "deadline"
            }
          }),
          _vm._v(" "),
          _c("text-input", {
            attrs: { label: "goals.editActivity.description", multiline: true },
            model: {
              value: _vm.activity.description,
              callback: function($$v) {
                _vm.$set(_vm.activity, "description", $$v)
              },
              expression: "activity.description"
            }
          }),
          _vm._v(" "),
          _c(
            "button-group",
            [
              _c("submit-button", {
                attrs: {
                  "can-submit": !_vm.$v.activity.$invalid,
                  label: "goals.editActivity.submit"
                }
              }),
              _vm._v(" "),
              _c("plain-button", { attrs: { "on-click": _vm.onCancel } })
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }