import repository from 'repository';
import { Collection } from 'backbone';
import { add, set } from '../__helpers/mutations';
import { get, assign, map } from 'lodash';

export default {
	namespaced: true,
	state: {
		consents: [],
		data: [],
		totalConsents: '',
		searchParams: {
			filters: {
				start: 0,
				limit: 10
			}
		}
	},
	getters: {
		data: (state) => state.data,
		collection: (state) => {
			const collection = new Collection(state.data);
			collection.total = state.totalConsents;

			return collection;
		},
		allConsents: (state) => state.consents,
		isMore: (state) => state.data.length < +state.totalConsents,
		totalConsents: (state) => state.totalConsents
	},
	mutations: {
		add: add(),
		set: (state, consents) => {
			state.data = consents;
		},
		push: (state, consents) => {
			state.data.push(...consents);
		},
		clearData: (state) => {
			state.data = {};
		},
		setList: (state, list) => {
			state.consents = list;
		},
		setSearchParams: (state, filters) => {
			assign(state.searchParams, filters);
		},
		setFilter: (state, filters) => {
			assign(state.searchParams.filters, filters);
		},
		resetFilter: (state) => {
			state.searchParams.filters = {
				start: 0,
				limit: get(state.searchParams.filters, 'limit')
			};
		},
		increaseStart: (state) => {
			state.searchParams.filters.start += state.searchParams.filters.limit;
		},
		resetStart: (state) => {
			state.searchParams.filters.start = 0;
		},
		setTotalConsents: set('totalConsents')

	},
	actions: {
		init: ({ commit }) => {
			const consentList = repository.consentsList();
			return consentList.then((response) => {
				commit('setList', response);
			});
		},
		initPublished: ({ commit }) => {
			const consentList = repository.searchConsent({
				filters: { 'filter[status]': 'PUBLISHED,UNPUBLISHED_CHANGES' }
			});
			return consentList.then((response) => {
				commit('setList', response);
			});
		},
		initSearchParams: ({ commit, rootState }, payload) => {
			const {
				'Treatment type': treatmentType = {},
				'Status': status = {},
				'Find': find = {}
			} = get(rootState.filter, 'values');

			assign(
				payload.filters,
				{
					'filter[treatmentType.id]': map(treatmentType, (val, key) =>
						val ? key : undefined).join()
				}, {
					'filter[status]': map(status, (val, key) =>
						val ? key : undefined).join()
				},
				{ search: find }
			);
			commit('setSearchParams', payload);
		},
		loadMore: ({ commit, dispatch }) => {
			commit('increaseStart');
			return dispatch('search', { push: true });
		},
		search: ({ state, commit }, { push } = {}) => {
			const promise = repository.searchConsent({
				...state.searchParams
			});
			return promise.then((data) => {
				commit(push ? 'push' : 'set', map(data, (record) =>
					assign({}, record, {
						lastVersionNumber: record.lastVersionNumber || '—'
					})));
				commit('setTotalConsents', promise.getResponseHeader('X-Total-Count'));
			});
		},
		setFilter: ({ commit }, params) => {
			commit('resetStart');
			commit('setFilter', params);
		},
		resetFilter: ({ commit }) => {
			commit('resetStart');
			commit('resetFilter');
		},
		add: ({ commit }, payload) => {
			commit('add', payload);
		}
	}
};
