var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.contentPackages.length
      ? _c(
          "p",
          { staticClass: "information-package__list-uncompleted-label" },
          [_vm._v("\n\t\t" + _vm._s(_vm.$t("Uncompleted")) + "\n\t")]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "information-packages__list" },
      _vm._l(_vm.contentPackages, function(contentPackage) {
        return _c(
          "li",
          {
            key: contentPackage.id,
            staticClass: "information-package__list-item"
          },
          [
            _c(
              "a",
              {
                staticClass: "generic-list__action information-package__action",
                attrs: { href: _vm.url(contentPackage) }
              },
              [
                _c("icon", { attrs: { name: "content-package" } }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "\n\t\t\t\t\t\tgeneric-list__action-title-container\n\t\t\t\t\t\tinformation-package__action-title-container\n\t\t\t\t\t"
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "generic-list__action-title information-package__action-title"
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(contentPackage.name) +
                            "\n\t\t\t\t\t"
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "information-package__items-counter",
                        attrs: { title: _vm.$t("Information package elements") }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "information-package__items-counter--enlarge"
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.uncompleted(contentPackage)) +
                                "\n\t\t\t\t\t\t"
                            )
                          ]
                        ),
                        _vm._v(
                          " / " +
                            _vm._s(contentPackage.elementsTotal) +
                            "\n\t\t\t\t\t"
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            )
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }