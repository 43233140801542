import confirmation from 'components/confirmation/confirmation';
import t from 'translate';
import cardUrl from 'cwcardurl';
import systemSettings from 'system-settings';
import datetime from 'datetime';
import moment from 'moment';
import store from 'store';
import { get } from 'lodash';

const retrieve = {
	setting: (settingName) => +systemSettings.getInteger(settingName),

	userType: () => get(store.state, 'user.type'),

	expirationDays: () => ({
		respondent: retrieve.setting('RESPONDENT_PASSWORD_EXPIRE_DAYS'),
		clinician: retrieve.setting('CLINICIAN_PASSWORD_EXPIRE_DAYS')
	}[retrieve.userType()]),

	lastUpdateMoment: () => datetime(get(store.state, 'user.passwordUpdatedAt')).toMoment(),

	expirationMoment: () => retrieve.lastUpdateMoment() +
		(retrieve.expirationDays() * 24 * 60 * 60 * 1000),

	expireTime: (now) => {
		const timeRemaining = retrieve.expirationMoment() - now;
		const hoursRemaining = parseInt(timeRemaining / (1000 * 60 * 60), 10);
		const daysRemaining = parseInt(hoursRemaining / 24, 10);

		if (daysRemaining > 1) {
			return t('{daysRemaining} days', { daysRemaining });
		}

		if (daysRemaining === 1) {
			return t('one day');
		}

		if (hoursRemaining > 1) {
			return t('{hoursRemaining} hours', { hoursRemaining	});
		}

		if (hoursRemaining === 1) {
			return t('one hour');
		}

		return t('less than an hour');
	}
};

const isPasswordExpiring = (now) => {
	if (retrieve.expirationDays() === 0) {
		return false;
	}

	const warnDays = {
		respondent: retrieve.setting('RESPONDENT_PASSWORD_EXPIRE_DAYS_WARNING'),
		clinician: retrieve.setting('CLINICIAN_PASSWORD_EXPIRE_DAYS_WARNING')
	}[retrieve.userType()];

	const timeSinceLastPasswordUpdate = now - retrieve.lastUpdateMoment(); // miliseconds
	const timeThresholdForWarning = (retrieve.expirationDays() -
		warnDays) * 24 * 60 * 60 * 1000; // miliseconds

	return timeSinceLastPasswordUpdate - timeThresholdForWarning > 0;
};

export default () => {
	const now = datetime(moment())
		.toUtcFromTimezone(get(store.state, 'user.timezoneName'))
		.toMoment();

	isPasswordExpiring(now) && confirmation({
		title: t('Change password'),
		message: t(`For security reasons, all CheckWare account passwords have to be changed every {daysInterval} days Your current password will expire in {expireTime}`, {
			daysInterval: retrieve.expirationDays(),
			expireTime: retrieve.expireTime(now)
		}),
		confirm: t('Change it now'),
		cancel: t('Remind me later'),
		warning: true,
		icon: 'access'
	}).then(() => {
		cardUrl.openCard('my-profile');
	});
};
