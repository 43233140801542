<template>
	<li class="generic-list__item information-package-element">
		<div
			:class="itemClassName"
			tabindex="0"
			@click="onClick"
			@keyup.enter="onClick"
		>
			<icon
				v-if="!item.isMedia"
				:name="typeIcon"
				:title="typeIcon"
				class="information-package-element__type-icon"
			/>
			<icon
				v-else
				:name="item.fileType"
				:title="item.typeLabel"
				class="information-package-element__type-icon"
			/>
			<div
				class="
					generic-list__action-title-container
					information-package-element__action-title-container
				"
			>
				<span v-if="item.isMedia" class="information-package-element__type-label">
					{{item.typeLabel}}
				</span>
				<p class="generic-list__action-title information-package-element__action-title">
					{{item.elementVersion.alias}}
				</p>
				<span
					v-if="item.state === STATE_COMPLETED && item.isExercise"
					class="information-package-element__run-once-more"
				>
					{{$t('You can do it more than once')}}
				</span>
				<icon
					v-if="item.isMedia"
					name="external-link"
					:title="$t('Open in new window')"
					class="information-package-element__open-external"
				/>
				<p class="information-package-element__state-label">
					<icon v-if="iconsOnly" :name="stateIcon" :title="stateIcon" />
					<template v-else>
						{{stateLabel}}
					</template>
					<template v-if="item.isExercise">
						{{`(${item.exercises.length})`}}
					</template>
				</p>
			</div>
		</div>
	</li>
</template>

<script>
import Icon from 'components/icon';
import { STATE_COMPLETED } from 'modules/respondent-information-package/constants';
import fileUrl from 'service/file-url/file-url';
import { RESPONDENT_MEDIA } from 'store/media/rp-media';
import { RESPONDENT_LINKS } from 'store/links/rp-links';
import { TREATMENT } from 'store/treatments/rp-treatment';
import cardUrl from 'cwcardurl';
import cardControls from 'core/engine/card/services/card-controls';
import { toLower } from 'lodash';

export default {
	components: { Icon },
	props: {
		item: {
			type: Object,
			required: true
		},
		iconsOnly: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		STATE_COMPLETED
	}),
	computed: {
		itemClassName: ({ item }) => `generic-list__action
			information-package-element__action
			information-package-element--${item.state}`,
		stateLabel: ({ item, $t }) => ({
			NOT_STARTED: $t('Not started'),
			IN_PROGRESS: $t('In progress'),
			COMPLETED: $t('Completed')
		}[item.state]),
		stateIcon: ({ item }) => toLower(item.state),
		typeIcon: ({ item }) => toLower(item.type)
	},
	methods: {
		onClick () {
			if (this.item.isLink) {
				this.handleLink();
				return;
			} else if (this.item.isMedia) {
				this.handleMedia();
				return;
			}
			cardControls.closeCard({
				go: cardUrl.buildUrl('information-package-element', {
					informationPackage: this.item.instance.id,
					id: this.item.id,
					treatmentId: this.$store.getters[TREATMENT.ID]
				})
			});
		},
		async handleLink () {
			const link = this.item.link;
			// mark as complete
			await this.$store.dispatch(
				RESPONDENT_LINKS.COMPLETE,
				{
					contentPackageId: this.item.instance.id,
					elementId: this.item.id,
					transform: false
				}
			);

			// improve after decision from defect 12191
			if (link.openInNewWindow) {
				window.open(link.url, '_blank');
			} else {
				window.location = link.url;
			}
		},
		async handleMedia () {
			// mark as complete
			await this.$store.dispatch(
				RESPONDENT_MEDIA.COMPLETE,
				{
					contentPackageId: this.item.instance.id,
					elementId: this.item.id,
					transform: false
				}
			);

			// improve after decision from defect 12191
			window.open(fileUrl(this.item.uploadedMedia.file.url), '_blank');
		}
	}
};
</script>
