import SingleEntity from '../shared/single-entity';
import urls from './urls';
import { isFunction } from 'lodash';

// Disclaimer: the name is clearly ugly. It's on purpose. Backend API and
// the whole architecture around these 'custom attributes' is really bad.
// The name is a little remainder of that.

export default SingleEntity.extend({
	urlRoot: urls.singleAttributeValue.url,
	eventNamespace: 'respondent-attribute-value',
	idAttribute: 'respondentAttributeId',

	initialize () {
		this.listenTo(this, 'change', this.normalize);
	},

	normalize () {
		const meta = this.get('fkRespondentAttributeRespondentAttributeMeta');

		if (meta) {
			const metaType = meta.respondentAttributeMetaType;
			const attributeValue = this.get('respondentAttributeValue');
			const actions = {
				CHECKBOX: (value) => [true, '1'].indexOf(value) > -1
			};

			isFunction(actions[metaType]) && this.set({
				respondentAttributeValue: actions[metaType](attributeValue)
			}, { silent: true });
		}
	}
});
