import { assign, forEach, isArray, isString, isUndefined } from 'lodash';

const urlParam = /(:[A-Za-z]+)/g;
const getCardparams = ({ url, args }) => {
	const params = {};
	const matches = url.match(urlParam);

	matches && forEach(matches.map((match) => {
		const found = /:(\w+)/g.exec(match);
		return found && found[1]; // extracts :value
	}), (urlParamName, i) => {

		if (urlParamName && args[i]) {
			params[urlParamName] = args[i];
		}
	});
	return params;
};

export default ({ url, action, args }) => {
	const cardParams = {};

	if (isString(url)) {
		return getCardparams({ url, args });
	}

	if (isArray(url)) {

		if (!isUndefined(action)) {
			return getCardparams({ url: url[action], args });
		}

		forEach(url, (cardUrlEntry) => {
			assign(cardParams, getCardparams({ url: cardUrlEntry, args }));
		});

		return cardParams;
	}
};

