import repository from 'repository';

export default {
	namespaced: true,

	state: {
		data: []
	},

	getters: {
		data: (state) => state.data
	},

	mutations: {
		set: (state, payload) => {
			state.data = payload;
		}
	},

	actions: {
		init: ({ commit }, respondentId) => {
			const collection = repository.getRespondentCliniciansByRespondentId(respondentId);
			collection.then(() => {
				commit('set', collection.toJSON());
			});
		}
	}
};
