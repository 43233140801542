import datetime from 'service/datetime/datetime';
import { size, truncate } from 'lodash';
import { parseGet } from './parse-get';

/**
 * Perform computations.
 *
 * @param {object} params        - Parameters.
 * @param {object} params.entity - Source entity.
 * @param {string} params.op     - Operation.
 * @param {Array} params.args    - Operation arguments (order is important).
 * @param {object} params.vars   - Variables that should be applied.
 * @returns {*}                  - Computation result.
 * @example
 *        compute({
 * 			entity: { start: '2019-01-01T21:37', end: '2019-02-01T18:36' }},
 *            op: 'BETWEEN|DATE',
 *            args: ['start', '${now}', 'end'],
 *            vars: { now: '2019-01-15T13:37' }
 *        });
 *        -> true
 */
export const compute = ({ entity = {}, op = '', args = [], vars = {}, timezone }) => {
	const datetimeFactory = (arg) => datetime(parseGet({ entity, vars, key: arg }))
		.setTimezone(timezone)
		.toMoment();
	const parse = (arg) => parseGet({ entity, vars, key: arg });

	return {
		'!=': () => parse(args[0]) !== parse(args[1]),
		'=': () => parse(args[0]) === parse(args[1]),
		'TRIM': () => truncate(parse(args[0]), { length: args[1] }),
		'<|DATE': () => datetimeFactory(args[0]) < datetimeFactory(args[1]),
		'SIZE': () => size(parse(args[0])),

		'BETWEEN|DATE': () =>
			datetimeFactory(args[0]) < datetimeFactory(args[1]) &&
			datetimeFactory(args[1]) < datetimeFactory(args[2])
	}[op];
};
