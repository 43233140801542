import { defer, delay, forEach } from 'lodash';
import $ from 'jquery';
import { View } from 'marionette';
import { Model } from 'backbone';
import { compile } from 'handlebars';
import tpl from './appbar-template';
import cardControls from '../../card/services/card-controls';
import t from 'translate';
import appContext from 'app-context';
import SwitchVariant from './switch-variant';
import SwitchTreatment from './switch-treatment';
import Vue from 'vue';
import store, { APP } from 'store';
import sidebarConfig from '../../sidebar/sidebar-config';
import logoutHref from 'modules/profile/util/logout-href';
import SidebarMenu from 'core/engine/sidebar/components/sidebar-menu';
import { TREATMENT } from 'store/treatments/rp-treatment';

const AppbarLayout = View.extend({
	template: compile(tpl),
	className: 'appbar-container',
	model: new Model({}),

	initialize ({ appType }) {
		this.appType = appType;
		this.model.set({
			isAssessment: appType === 'assessment',
			isAtomic: appType === 'atomic',
			logoutUrl: logoutHref(),
			isAssessmentOrAtomic: appType === 'assessment' || appType === 'atomic',
			showHomeButton: true,
			showLogoutButton: (
				appType === 'assessment' ||
				appType === 'atomic' ||
				store.getters[APP.DETACHED]
			)
		});

		this.sidebarCfg = sidebarConfig[appType];
	},

	regions: {
		contextContainer: '.appbar-context__container',
		outerContainer: '.appbar-outer__container'
	},

	ui: {
		home: '.home',
		closeCard: '.close-card',
		contextToggle: '.appbar-context__toggle .appbar-button',
		contextContainer: '.appbar-context__container'
	},

	events: {
		'click @ui.closeCard': 'closeCardClick',
		'click @ui.contextToggle': 'contextToggleClick',
		'click @ui.home': 'homeClick'
	},

	modelEvents: {
		change () {
			this.render();
			this.onRender();
		}
	},

	sidebarButton: ({ config, type }) => Vue.extend({
		components: { SidebarMenu },
		computed: {
			items: () => store.getters[`${config.storeModule}/menuItems`]
		},
		beforeCreate () {
			store.dispatch(`${config.storeModule}/init`);
		},
		methods: {
			canAccessFn: (item) => config.canAccess(item)
		},
		template: `<sidebar-menu :items="items" :can-access="canAccessFn" type="${type}" />`
	}),

	onRender () {
		this.handleDetached();
		this.onContextItemsChange(0);
		this.setContextEvents();
		this.on('appbar-button.click', () => {
			this.closeContext();
		});

		if (!this.model.get('isAssessment') && !this.model.get('isAtomic')) {
			this.mountSidebar();
		}

		this.listenTo(appContext, 'card.render', () => {
			this.closeContext();

			if (cardControls.canClose()) {
				this.ui.closeCard.removeClass('disabled');
				$(this.ui.closeCard).attr('tabindex', 0);

			} else {
				this.ui.closeCard.addClass('disabled');
				$(this.ui.closeCard).attr('tabindex', -1);
			}
		});

		this
			.assignLabels()
			.mountSwitchVariant()
			.mountSwitchTreatment();
	},

	mountSidebar () {
		const mount = () => {
			delay(() => {
				const sidebarMounted = $('.sidebar-container').length;

				if (!sidebarMounted) {
					// eslint-disable-next-line lodash/prefer-lodash-method
					const $list = this.$el.find('.appbar-system-buttons__list');

					const Vm = this.sidebarButton({ config: this.sidebarCfg, type: this.appType });
					const sidebarButton = new Vm({
						el: document.createElement('div')
					});
					$list.prepend(sidebarButton.$el);
				}
			}, 10);
		};

		// wait for the treatment init before initialising sidebar in respondent app
		if (store.getters.userType === 'respondent') {
			const unsubscribe = store.subscribeAction({
				after: (action) => {
					if (action.type === TREATMENT.INIT) {
						mount();
						unsubscribe();
					}
				}
			});

		} else {
			mount();
		}
	},

	mountSwitchVariant () {
		defer(() => {
			this.vm = new Vue({
				el: $('.switch-variant-mount')[0],
				store,
				components: { SwitchVariant },
				template: '<switch-variant />'
			});
		});

		return this;
	},

	mountSwitchTreatment () {
		defer(() => {
			this.vm = new Vue({
				el: $('.switch-treatment-mount')[0],
				store,
				components: { SwitchTreatment },
				template: '<switch-treatment />'
			});
		});

		return this;
	},

	assignLabels () {
		const labels = {
			home: t('Home'),
			closeCard: t('card.close')
		};
		forEach(['home', 'closeCard'], (uiName) => {
			this.ui[uiName].attr('title', labels[uiName]);
		});
		return this;
	},

	closeCardClick () {
		this.ui.closeCard.trigger('blur');
		this.toggleContainersVisibility(true);
		cardControls.closeCard();
	},

	setContextEvents () {
		$('html, body').on('click', (e) => {
			const $target = $(e.target);
			const firedByContextToggle = !!$target.parents('.appbar-context__toggle').length;
			!firedByContextToggle && this.closeContext();
		});
	},

	contextToggleClick () {
		this.getRegion('contextContainer').$el.toggleClass('active');
	},

	homeClick () {
		this.ui.home.trigger('blur');
	},

	closeContext () {
		if (this.getRegion('contextContainer').$el) {
			this.getRegion('contextContainer').$el.removeClass('active');
		}
	},

	setContextCollection (collection) {
		this.onContextItemsChange(collection.where({ preventHide: false }).length);
	},

	onContextItemsChange (quantity) {
		this.el.className = `${this.className} context-items-count-${quantity}`;
	},

	hide () {
		this.toggleContainersVisibility(false);
	},

	show () {
		this.toggleContainersVisibility(true);
	},

	toggleContainersVisibility (bool) {
		forEach(
			[this.getRegion('contextContainer'), this.getRegion('outerContainer')],
			(container) => {
				container.$el && container.$el.toggleClass('hidden', !bool);
			}
		);
	},

	handleDetached () {
		store.subscribeAction({
			after: (action) => {
				if (action.type === APP.SET_DETACHED) {
					const detached = store.getters[APP.DETACHED];
					this.model.set({
						showLogoutButton: detached,
						showHomeButton: !detached
					});
				}
			}
		});
	}
});
let appbarLayout;

export default ({ appType } = {}) => {
	if (!appbarLayout) {
		appbarLayout = new AppbarLayout({ appType });
	}
	return appbarLayout;
};
