import { assign, constant, forEach, get, isUndefined, noop, toInteger } from 'lodash';
import store from 'store';
import t from 'translate';
import TileView from 'tile-view';
import can from 'acl-can';
import messagesComponent from './messages-component';

export default TileView.extend({
	title: t('Messages'),
	acl: [],
	features: ['ENABLE_MESSAGING_SYSTEM'],

	cardData: () => ['messages', 'respondent'],

	adjustments: noop,

	provideMarkAnswered: ({ data }, message) =>
		data.messages.findWhere({ id: message.id }).markAnswered(message.answered),

	sender: constant('CLINICIAN'),

	markAsRead: ({ data, tile }) => {
		const markAsRead = get(tile.config, 'markAsRead');
		const shouldMark = !isUndefined(markAsRead) ? markAsRead : true;
		const isRespondent = store.getters['userType'] === 'respondent';

		if (shouldMark && data.messages) {
			forEach(data.messages.where({
				read: false,
				senderType: tile.sender()
			}), (message) => {
				const messageRespondentId = message.get('respondent').respondentId;
				const toCurrentRespondent = toInteger(messageRespondentId) === toInteger(store.getters['userId']);
				if (
					!isRespondent ||
					(isRespondent && toCurrentRespondent)
				) {
					message.markAsRead();
				}
			});
		}
	},

	loaded ({ data, tile }) {
		assign(data, { messages: store.state.messages.collection });
		const msgList = messagesComponent({
			canMarkAnswered: can.edit('administrator.respondents.communication'),
			provideMarkAnswered: tile.provideMarkAnswered ?
				(message) => tile.provideMarkAnswered({ data }, message) :
				false
		});

		tile.$el.append(msgList.$el);
		tile.markAsRead({ data, tile });
		tile.adjustments(tile);
	}
});
