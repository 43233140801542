import { SESSION } from 'store/session/session';
import { delay } from 'lodash';
import logoutHref from 'modules/profile/util/logout-href';
import store from 'store';

const idleTime = async () => {
	await store.dispatch(SESSION.INIT);

	if (store.getters[SESSION.EXPIRED]) {
		window.location = logoutHref();

	} else {
		delay(idleTime, store.getters[SESSION.REMAINING] * 1000);
	}
};

export default idleTime;
