<template>
	<select-input
		v-model="respondent"
		:no-drop="noDrop"
		:empty-search-dropdown="false"
		:exceeded="exceeded"
		:async="true"
		:loading="loading"
		:options="list"
		:get-option-label="respondentLabel"
		label="Respondent"
		item-label="respondentId"
		:placeholder="t('Type respondent name')"
		:required="required"
		@input="$v.respondent.$touch()"
		@search="onInput"
	/>
</template>

<script>
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import { respondentName } from 'service/display-name/display-name';
import t from 'service/lang/translate';
import { cloneDeep, debounce } from 'lodash';

export default Vue.component('RespondentSearch', {
	props: {
		value: {
			type: [Number, String, Object, Array],
			default: ''
		},

		searchLimit: {
			type: Number,
			default: 10
		},

		required: {
			type: Boolean,
			default: true
		}
	},

	data: () => ({
		loading: false
	}),

	computed: {
		...mapGetters({
			exceeded: 'respondentSearch/exceeded',
			limit: 'respondentSearch/limit',
			respondents: 'respondentSearch/list',
			total: 'respondentSearch/total'
		}),

		noDrop: ({ $v, loading }) => !$v.respondent.$dirty || loading,

		respondent: {
			get: ({ value }) => cloneDeep(value),
			set (newVal) {
				this.$emit('input', newVal);
			}
		},

		list: ({ exceeded, exceededLabel, respondents, total }) => {
			const list = respondents;

			if (exceeded) {
				list.unshift({
					disabled: true,
					respondentId: 0,
					respondentUsername: exceededLabel(total - respondents.length)
				});
			}
			return list;
		}
	},

	created () {
		this.$store.dispatch('respondentSearch/setLimit', this.searchLimit);
	},

	methods: {
		t,
		...mapActions('respondentSearch', ['deleteList', 'provideResults', 'setSearchList']),

		respondentLabel: (respondent) => respondentName(respondent),

		exceededLabel: (amount) =>
			t('{count} results omitted, try narrowing down search criteria', {
				count: amount
			}),

		onInput (search) {
			if (search.length) {
				this.$v.respondent.$touch();
				this.loading = true;
				this.search(search);
			} else {
				this.$v.respondent.$reset();
				this.deleteList();
			}
		},

		search: debounce(function (search) {

			this.provideResults({
				limit: this.limit,
				respondentAccountEnabled: true,
				search,
				start: 0
			}).then(() => {
				this.loading = false;
			});
		}, 200)
	},

	validations: () => ({
		respondent: { required }
	})
});
</script>
