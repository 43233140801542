import repository from 'repository';
import prefixify from '../__helpers/prefixify';
import sharedSearch, {
	EXCEEDED,
	LIMIT,
	LIST,
	TOTAL,
	DELETE_LIST,
	SET_LIMIT,
	SET_LIST,
	SET_TOTAL,
	PROVIDE_RESULTS,
	SET_SEARCH_LIST
} from '../shared/search';

const prefix = prefixify('clinicianSearchAvailable');

export const CLINICIAN_SEARCH_AVAILABLE = {
	EXCEEDED: prefix(EXCEEDED),
	LIMIT: prefix(LIMIT),
	LIST: prefix(LIST),
	TOTAL: prefix(TOTAL),
	DELETE_LIST: prefix(DELETE_LIST),
	SET_LIMIT: prefix(SET_LIMIT),
	SET_LIST: prefix(SET_LIST),
	SET_TOTAL: prefix(SET_TOTAL),
	PROVIDE_RESULTS: prefix(PROVIDE_RESULTS),
	SET_SEARCH_LIST: prefix(SET_SEARCH_LIST)
};

export default sharedSearch(repository.searchAvailableClinicians);
