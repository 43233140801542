var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mini-chart-tile" },
    [
      _c(
        "span",
        { staticClass: "mini-chart-tile__title", attrs: { title: _vm.title } },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _vm._v(" "),
      _c("plot-chart", { attrs: { params: _vm.params, options: _vm.options } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }