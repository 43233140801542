import FormComponentView from '../form-component-view';
import { compile } from 'handlebars';

const password = FormComponentView.extend({
	template: compile(`
		<label class="form-view__label-container">
			<p class="form-view__label-text">{{ label }}</p>
			<input
				type="password"
				class="form-view__input form-view__password"
				name="{{ uniqueName }}"
				autocomplete="nope" {{! tricky one, see more details https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion }}
				{{#if value}} value="{{ value }}" {{/if}}
				{{#if required}} required="required" {{/if}}
				{{#if readonly}} readonly="readonly" {{/if}}
				{{#if disabled}} disabled="disabled" {{/if}}
				{{#if hint}} aria-describedby="{{ name }}-hint"{{/if}} />
		</label>
		{{#if hint}}
			<div class="form-view__hint" id="{{ name }}-hint">{{ hint }}</div>
		{{/if}}
	`)
});

export { password };
