<template>
	<section v-on-click-outside="hide" :class="mainClassName">

		<div
			:class="elementClassName('toggle-action')"
			role="button"
			tabindex="0"
			:aria-label="description"
			:aria-expanded="toggled"
			@click="toggle"
			@keyup.enter="toggle"
		>
			{{description}}
			<icon
				:small="true"
				:class="elementClassName('toggle-indicator')"
				name="angle-down"
			/>
		</div>

		<transition name="fade">
			<ul v-if="toggled" :class="elementClassName('items')">
				<li
					v-for="(filterItem, i) in filterItems"
					:key="i"
					:class="elementClassName('item')"
				>
					<div
						:class="elementClassName('action')"
						role="button"
						tabindex="0"
						:aria-label="filterItem.label"
						:aria-pressed="filterItem.checked"
						@click="toggleItem(filterItem)"
						@keyup.enter="toggleItem(filterItem)"
					>
						<icon
							v-if="multi"
							:name="checkboxIcon(filterItem)"
							:class="elementClassName('action-icon')"
						/>
						<span
							:class="elementClassName('action-text')"
						>
							{{filterItem.label}}
						</span>
					</div>
				</li>
			</ul>
		</transition>
	</section>
</template>

<script>
import Vue from 'vue';
import { cloneDeep, find, findIndex, map } from 'lodash';
import t from 'translate';
import agendaMixin from './agenda-mixin';
import { optionalArray, requiredArray, requiredFunction, optionalString } from './props';
import { AGENDA_FILTER } from './constants';

export default Vue.component(AGENDA_FILTER, {
	mixins: [agendaMixin],
	props: {
		closeFilter: {
			type: Boolean,
			default: false
		},
		filterItems: requiredArray,
		initialChecked: optionalArray,
		i18nPrefix: optionalString,
		multi: {
			type: Boolean,
			default: true
		},
		onChange: requiredFunction
	},
	data: ({ initialChecked }) => ({
		checked: [...initialChecked],
		toggled: false
	}),

	computed: {
		mainClassName: ({ $options, toggled, multi }) => ({
			[$options.name]: true,
			[`${$options.name}--toggled`]: toggled,
			[`${$options.name}--single`]: !multi,
			[`${$options.name}--multi`]: multi
		}),
		selected: ({ checked, filterItems }) => checked.length ? checked : filterItems,
		description: ({ selected, filterItems, checked, i18nPrefix }) => {
			const count = selected.length;

			if (count === filterItems.length) {
				return t(`${i18nPrefix}.all`);
			}

			return count === 1 ?
				checked[0].label :
				t(`${i18nPrefix}.multiple`, { count });
		}
	},
	watch: {
		initialChecked (newVal) {
			this.checked = [...newVal];
		}
	},
	methods: {
		toggleItem (item) {
			item.checked = !item.checked;

			if (this.multi) {
				this.toggleMulti(item);

			} else {
				this.toggleSingle(item);
			}
		},

		toggleSingle (item) {
			this.checked = [item];
			this.onChange(item.value);

			if (this.closeFilter) {
				this.hide();
			}
		},

		toggleMulti (item) {
			const checked = cloneDeep(this.selected);
			const idx = findIndex(checked, { value: item.value });

			if (idx > -1) {
				checked.splice(idx, 1);

			} else {
				checked.push(item);
			}

			this.checked = checked;
			this.onChange(map(this.selected, 'value'));
		},

		toggle () {
			this.toggled = !this.toggled;
		},
		hide () {
			this.toggled = false;
		},
		checkboxIcon (item) {
			return find(this.selected, { value: item.value }) ? 'checkbox-checked' : 'checkbox';
		}
	}
});

</script>
