import repository from 'repository';
import { filter } from 'lodash';
import initEntities from '../__helpers/init-entities';

export default {
	namespaced: true,

	state: {
		data: null,
		model: null
	},

	getters: {
		list: (state) => state.data,
		active: (state) => filter(state.data, (element) => element.instance.state === 'ACTIVATE')
	},

	mutations: {
		set: (state, { model }) => {
			state.data = model.toJSON();
			state.model = model;
		}
	},

	actions: {
		initForTreatment:
			({ commit, rootGetters }, { respondentId, treatmentId, allRespondents = false }) => {
				const params = allRespondents ?
					{ treatment: treatmentId } :
					{
						respondent: respondentId || rootGetters['respondent/respondentId'],
						treatment: treatmentId
					};

				const model = repository.contentPackageInstanceExercises(params);

				return model.then(() => {
					commit('set', { model });
				});
			},

		initForRespondent: ({ commit }, { respondentId }) => {
			const model = repository.contentPackageInstanceExercises({
				respondent: respondentId
			});

			return model.then(() => {
				commit('set', { model });
			});
		},

		init: initEntities('contentPackageInstances/exercises/init', {
			allRespondents: true
		})
	}
};
