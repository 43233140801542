import { assign, constant, get } from 'lodash';

const mapping = {
	danish: 'da',
	english: 'en',
	estonian: 'ee',
	german: 'de',
	icelandic: 'is',
	norwegian: 'nb',
	nynorsk: 'nn',
	polish: 'pl',
	spanish: 'es',
	swedish: 'sv'
};

export default function (definition) {
	assign(this, {
		getRawValue: () => definition,
		getClass: constant('Literal'),
		getInvolvedQuestionCodesMap: (questionMap) => questionMap,
		getString: () => definition.language,
		getLanguageCode: () => get(mapping, definition.language, 'en')
	});
}

