export default function (definition) {
	if (typeof definition.section == 'object') {
		const href = definition.section;

		definition.section = href.code;
		definition.target = href.target;
	}

	/* debug */
	this.__debug = {
		raw: definition,
		section: definition.section
	};

	this.getType = function () {
		return 'Section';
	};

	this.getClass = function () {
		return 'Reference';
	};

	this.getInvolvedQuestionCodesMap = function (questionMap) {
		questionMap[definition.section] = 'section';

		return questionMap;
	};

	this.getBoolean = function () {
		return (typeof this.getSectionCode() == 'string') && (this.getSectionCode().length > 0);
	};

	this.getNumber = function () {
		return NaN;
	};

	this.getString = function () {
		return (typeof this.getSectionCode() != 'string') ?
			'Undefined question reference' :
			this.getSectionCode();
	};

	this.getSectionCode = function () {
		return definition.section;
	};

	this.getRawValue = function () {
		definition.section;
	};

	this.toString = function () {
		return `{Section:${this.getString()}}`;
	};

}
