<template>
	<p v-if="isNew && !goalsList.length">
		<span v-translate>goals.editActivity.noGoals</span>
		<button-group>
			<plain-button :on-click="onCancel" />
		</button-group>
	</p>
	<form v-else @submit.prevent="onSubmit">
		<text-input
			v-model="activity.name"
			:required="true"
			:mandatory="true"
			:invalid="$v.activity.name.$error"
			label="goals.editActivity.name"
			@input="$v.activity.name.$touch()"
		/>
		<select-input
			v-if="goalsList.length"
			v-model="activity.goal"
			:options="goals"
			:required="true"
			:mandatory="true"
			:invalid="$v.activity.goal.$error"
			label="goals.editActivity.chooseGoal"
			item-label="name"
			@input="$v.activity.goal.$touch()"
		/>
		<datetime-input
			v-model="startDate"
			label="goals.editActivity.startDate"
		/>
		<datetime-input
			v-model="deadline"
			label="goals.editActivity.endDate"
		/>
		<text-input
			v-model="activity.description"
			label="goals.editActivity.description"
			:multiline="true"
		/>
		<button-group>
			<submit-button
				:can-submit="!$v.activity.$invalid"
				label="goals.editActivity.submit"
			/>
			<plain-button :on-click="onCancel" />
		</button-group>
	</form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { fromUTC, toUTC } from '../shared/date-utils';

export default {
	name: 'EditActivity',
	props: {
		activity: {
			type: Object,
			required: true
		},
		goals: {
			type: Array,
			required: true
		},
		onCancel: {
			type: Function,
			required: true
		},
		onSubmit: {
			type: Function,
			required: true
		},
		isNew: {
			type: Boolean,
			required: true
		}
	},

	data () {
		return {
			goalsList: [...this.goals]
		};
	},

	computed: {
		startDate: {
			get: ({ activity }) => activity.startDate ? fromUTC(activity.startDate) : '',
			set (newVal) {
				this.activity.startDate = toUTC(newVal);
			}
		},
		deadline: {
			get: ({ activity }) => activity.deadline ? fromUTC(activity.deadline) : '',
			set (newVal) {
				this.activity.deadline = toUTC(newVal);
			}
		}
	},

	validations: {
		activity: {
			name: { required },
			goal: { required }
		}
	}
};
</script>
