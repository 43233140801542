var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "treatment-grid",
    _vm._l(_vm.treatments, function(treatment) {
      return _c("treatment-selection-item", {
        key: treatment.id,
        attrs: { treatment: treatment },
        on: {
          click: function($event) {
            return _vm.$emit("click", treatment)
          }
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }