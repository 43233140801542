var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("plot-chart", {
    attrs: {
      params: _vm.params,
      options: _vm.options,
      "threshold-line": _vm.config.thresholdLine,
      "threshold-range": _vm.config.thresholdRange
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }