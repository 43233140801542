import repository from 'repository';
import { map } from 'lodash';
import { cleanClinicianGroup } from '../__helpers/clean';

export default {
	namespaced: true,

	state: {
		all: []
	},

	getters: {
		all: (state) => map(state.all, cleanClinicianGroup)
	},

	mutations: {
		setAll: (state, { groups }) => {
			state.all = groups;
		}
	},

	actions: {
		initAll: ({ commit }) => {
			const groups = repository.getClinicianGroups();
			return groups.then(() => {
				commit('setAll', { groups: groups.toJSON() });
			});
		}
	}
};
