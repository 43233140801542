import ReportContent from './content';

/**
 * @class Repository
 */
export default {
	/**
	 * Get all Reports.
	 *
	 * @function getReports
	 * @param {object} opts - Options.
	 * @returns {object}    - Reports Collection.
	 * @example
	 *        Repository.getReports();
	 */
	getReports ({ sortDirection, group, groupByLatest, treatmentId } = {}) {
		const params = {};

		if (sortDirection) {
			params.orderByDirection = sortDirection;
		}

		if (group) {
			params.groupBy = group;
		}

		if (groupByLatest) {
			params.groupByLatest = +groupByLatest;
		}

		if (treatmentId) {
			params.treatmentId = treatmentId;
		}

		return this.Reports.getAll(params);
	},

	/**
	 * Get a new sorted collection of all getByData.
	 *
	 * @function getReportsByData
	 * @param {object} data - Parameters.
	 * @returns {object}    - Reports Collection.
	 * @example
	 *        Repository.Reports.getReportsByData({ assessmentId: 32 });
	 */
	getReportsByData (data) {
		return this.Reports.getByData(data);
	},

	assessmentInstanceReportInstances (assessmentInstanceId) {
		return this.ReportInstances.getByAssessmentInstanceId(assessmentInstanceId);
	},

	/**
	 * Get ReportContent.
	 *
	 * @param {object} params - Parameters.
	 * @returns {object}      - ReportContent Model.
	 * @example
	 *        Repository.getReportContent({
     * 			assessmentInstanceId: 23,
     * 			reportId: 8
     * 		});
	 */
	getReportContent (params) {
		const reportContent = new ReportContent(params);
		return reportContent.retrieve();
	}
};
