<template>
	<ul class="edit-object__container">
		<li
			v-for="(propVal, propKey, index) in objVal"
			:key="index"
			class="edit-object__row edit-object__row--edit"
		>
			<input
				v-trim
				type="text"
				:value="propKey"
				:readonly="readonly"
				@blur="onObjKeyChange(propKey, $event)"
				v-on="inputListeners"
			/>

			<edit-object
				:obj-key="propKey"
				:obj-val="propVal"
				:on-change="onObjValueObjectChange"
				:readonly="readonly"
				:initial-path="path"
				:allow-toggle="isObject(propVal)"
				label=""
			/>

			<button
				v-if="!readonly"
				class="edit-object__button--remove"
				@click="removeObjectRow(propKey)"
			/>
		</li>
		<li class="edit-object__row edit-object__row--add">
			<div>
				<input
					v-model="newKey"
					v-trim
					type="text"
					:readonly="readonly"
					v-on="inputListeners"
				/>
			</div>
			<div>
				<input
					v-model="newVal"
					type="text"
					:readonly="readonly"
					v-on="inputListeners"
				/>
			</div>
			<button v-if="!readonly" class="edit-object__button--add" @click="addObjectRow" />
		</li>
	</ul>
</template>

<script>
import { assign, isObject, trim } from 'lodash';
import inputMixin from './__input-mixin';
import Vue from 'vue';

export default Vue.component('objectInput', {
	mixins: [inputMixin],
	props: {
		objVal: {
			type: Object,
			required: true
		},
		onObjectKeyChange: {
			type: Function,
			required: true
		}
	},

	data: () => ({
		newVal: '',
		newKey: ''
	}),
	methods: {
		onObjKeyChange (key, e) {
			this.onObjectKeyChange(key, trim(e.target.value));
		},
		addObjectRow () {
			const value = assign({}, this.objVal, { [this.newKey]: this.newVal });
			this.onValueChange({ path: this.path, value });

			this.newKey = '';
			this.newVal = false;
		},
		removeObjectRow (key) {
			const value = assign({}, this.objVal);
			delete value[key];
			this.onValueChange({ path: this.path, value });
		},
		isObject,
		onObjValueObjectChange ({ path, value }) {
			this.onValueChange({ path, value });
		}
	}
});
</script>
