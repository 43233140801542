import t from 'translate';
import cardUrl from 'cwcardurl';
import store from 'store';
import {
	RESPONDENT_CONTENT_PACKAGE_ELEMENT
} from 'store/content-packages/rp-content-package-element';
import { TREATMENT } from 'store/treatments/rp-treatment';

const card = {
	title: t('Run exercise'),
	name: 'exercise-run',
	url: 'treatment/:treatmentId/exercise/:informationPackage/:id/run',
	controller (treatmentId, informationPackage, id) {
		const element = store.dispatch(RESPONDENT_CONTENT_PACKAGE_ELEMENT.INIT, {
			contentPackageId: informationPackage,
			elementId: id
		});

		element.then(() => {
			const contentPackageElement = store.getters[RESPONDENT_CONTENT_PACKAGE_ELEMENT.DATA];
			const redirectTo = cardUrl.buildUrl('assessment', {
				exercise: contentPackageElement.id,
				treatmentId: store.getters[TREATMENT.ID]
			});
			this.cardContext().trigger('card:close', {
				go: redirectTo
			});
		});
	}
};

export { card as exerciseRun };
