import repository from 'repository';
import { filter } from 'lodash';

export default {
	namespaced: true,

	state: {
		data: [],
		collection: {}
	},

	getters: {
		active: (state) => filter(state.data, { enabled: true, allowManual: true }),
		enabled: (state) => filter(state.data, { enabled: true })
	},

	mutations: {
		set: (state, { plans, collection }) => {
			state.data = plans;
			state.collection = collection;
		}
	},

	actions: {
		initForRespondent: ({ commit, rootGetters }, { respondentId } = { respondentId: 0 }) => {
			const collection = repository.plansForRespondent(
				respondentId || rootGetters['respondent/respondentId']
			);

			return collection.then(() => {
				commit('set', { plans: collection.toJSON(), collection });
			});
		},

		initForCurrentClinician: ({ commit }) => {
			const collection = repository.currentClinicianPlans();

			return collection.then(() => {
				commit('set', { plans: collection.toJSON(), collection });
			});
		}
	}
};
