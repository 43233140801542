import { set } from '../__helpers/mutations';
import { assign, find, map, pick, sortBy } from 'lodash';
import repository from 'repository';

export default {
	namespaced: true,

	state: {
		data: {}
	},

	getters: {
		item: (state) => assign({}, state.data, pick(
			state.data.consentVersion,
			['name', 'content']
		)),
		// eslint-disable-next-line max-params
		options: (state, getters, rootState, rootGetters) => map(
			sortBy(getters.item.option, 'order'),
			(opt) => assign({}, opt, {
				answer: find(opt.answers, ['respondent.id', rootGetters.userId])
			})
		)
	},

	mutations: {
		set: set()
	},

	actions: {
		init: async ({ commit }, { consentInstanceId }) => {
			const [consentInstance, options] = await Promise.all([
				repository.consentInstance(consentInstanceId),
				repository.consentInstanceOptions(consentInstanceId)
			]);

			commit('set', assign({}, consentInstance, { option: options }));
		},

		submit: ({ commit, getters, rootGetters }, options) => {
			commit('set', assign(getters.item, {
				answers: map(options, (option) => ({
					id: option.id,
					answers: [{
						answer: !!option.answers[0].answer,
						respondent: { id: rootGetters.userId }
					}]
				}))
			}));
			return repository.submitConsentInstance(getters.item);
		}
	}
};
