<template>
	<div>
		<section
			v-for="(pages, groupName) in groupedPages"
			:key="groupName"
			class="information-package-elements__container"
		>
			<h3 v-if="groupName !== 'undefined'" class="information-package-elements__title">
				{{groupName}}
			</h3>
			<ul>
				<li
					v-for="page in pages"
					:key="page.id"
					class="generic-list__item assessment-list__item"
				>
					<a :class="linkClassName(page)" :href="url(page)">
						<span class="icon icon--page" />
						<div
							class="
								generic-list__action-title-container
								information-package-element__action-title-container
							"
						>
							<p
								class="
									generic-list__action-title
									information-package-element__action-title
								"
							>{{page.elementVersion.alias}}</p>
							<p class="information-package-element__state-label">
								{{stateLabel(page)}}
							</p>
						</div>
					</a>
				</li>
			</ul>
		</section>
	</div>
</template>
<script>
import systemSettings from 'system-settings';
import cardUrl from 'cwcardurl';
import { TREATMENT } from 'store/treatments/rp-treatment';
import { RESPONDENT_PAGES } from 'store/pages/rp-pages';
import t from 'translate';

export default {
	features: ['ENABLE_RESPONDENT_PAGES'],
	title: t('Pages'),
	actions: () => [RESPONDENT_PAGES.INIT],

	computed: {
		groupedPages: ({ $store }) => systemSettings.getBoolean('ASSESSMENT_TYPES') ?
			$store.getters[RESPONDENT_PAGES.GROUPED_BY_TYPE] :
			$store.getters[RESPONDENT_PAGES.GROUPED_BY_CONTEXT]
	},

	methods: {
		linkClassName: (page) => `generic-list__action
				information-package-element__action
				information-package-element--${page.state}`,

		url (page) {
			return cardUrl.buildUrl('information-package-element', {
				informationPackage: page.instance.id,
				id: page.id,
				treatmentId: this.$store.getters[TREATMENT.ID]
			});
		},

		stateLabel (page) {
			return {
				NOT_STARTED: this.$t('Not started'),
				IN_PROGRESS: this.$t('In progress'),
				COMPLETED: this.$t('Completed')
			}[page.state];
		}
	}
};
</script>
