var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.difference
    ? _c("section", { staticClass: "warning timezone-warning" }, [
        _c("p", [
          _c("span", [_vm._v(_vm._s(_vm.$t("The timezone of")))]),
          _vm._v(" "),
          _c("strong", [_vm._v(" " + _vm._s(_vm.displayName) + " ")]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.$t("is displayed")))])
        ]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.timezoneText))])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }