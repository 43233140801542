var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classes.labels }, [
    _c(
      "ul",
      { class: _vm.classes.list },
      _vm._l(_vm.labels, function(label, index) {
        return _c(
          "li",
          { key: index, class: _vm.classes.item },
          [
            _c("icon", {
              attrs: { name: label.icon, "class-name": _vm.classes.icon }
            }),
            _vm._v(" "),
            _c(
              "span",
              { class: _vm.classes.text, attrs: { title: _vm.title(label) } },
              [
                label.title ? [_vm._v(_vm._s(label.title) + ": ")] : _vm._e(),
                _vm._v(_vm._s(label.value) + "\n\t\t\t")
              ],
              2
            )
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }