import t from 'translate';
import TileView from 'tile-view';
import Table from 'components/table/table';
import store from 'store';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import cardUrl from 'cwcardurl';
import { get } from 'lodash';
import can from 'acl-can';
import { SYSTEM } from 'service/acl/checkpoints';
import treatmentContextParam from 'modules/treatments/util/treatment-context-param';

export default TileView.extend({
	title: t('Connected Apps and devices'),
	instant: true,

	loaded: ({ tile }) => {
		const actions = {};

		if (can.delete(SYSTEM.SETTINGS.SETTINGS)) {
			actions.delete = (connection, { cancel }) => {
				confirmation({
					icon: 'unlink',
					warning: true,
					title: t('Remove App connection'),
					message: t('Remove App connection for "{connection}"?', {
						connection: connection.get('activeApp').name
					})
				}).then(() => {
					store.dispatch('apps/disconnect', { id: connection.getId() });
					cwalert.success(t('Disconnected'));
				}, () => cancel());
			};
		}

		tile.table = new Table({
			parent: tile.el,
			collection: get(store.state, 'apps.connectedCollection'),
			click: (item) => {
				cardUrl.openCard('connected-app-details', {
					id: item.getId(),
					...treatmentContextParam()
				});
			},
			columns: [{
				key: 'name',
				label: t('App'),
				render: (name, { item }) => item.get('activeApp').name
			}, store.getters.userType === 'clinician' && {
				key: 'treatmentType',
				label: t('Treatment'),
				render: (name, { item }) => item.get('treatment').name
			}],

			actions
		});
	}
});
