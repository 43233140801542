var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    staticClass: "edit-object__number",
    attrs: { type: "number", readonly: _vm.readonly },
    domProps: { value: _vm.objVal },
    on: { blur: _vm.onNumberValueChange }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }