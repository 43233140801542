import store from 'store';
import cardUrl from 'cwcardurl';

export default (close) => {
	if (store.state.user.circleUser.isBanned) {
		close({ go: cardUrl.buildUrl('circles-banned') });
	}

	return store.state.user.circleUser.isBanned;
};
