import urlify from 'service/urlify/urlify';
import QueryParams from 'lib/url/query-params';

const BASE_URL = 'backend-commons/';
// const MOCK_URL = 'http://127.0.0.1:10010/';

export const circle = {
	url: `${BASE_URL}circle/`
};

export const circlesByUser = {
	url: (circleUserId) => `${BASE_URL}circle/?byUser=${circleUserId}`
};

export const search = {
	url: (params) => {
		const modifiedParams = {};

		if (params['categories.id']) {
			modifiedParams['categoryIds[]'] = params['categories.id'];
		}

		if (params['exactMatch']) {
			modifiedParams['exactCodeMatch'] = params['exactMatch'];
		}

		return `${BASE_URL}circle/?start=${params.start}&limit=${params.limit}&search=${
			params.search
		}&${new QueryParams(modifiedParams)}`;
	}
};

export const isUnique = {
	url: (code) => `${BASE_URL}circle/?filterEq[code]=${code}`
};

export const post = {
	url () {
		const id = this.getId() || '';
		const circleId = this.get('circle').id || this.get('circle');
		const circleUserId = this.get('circleUser').id || this.get('circleUser');

		return `${BASE_URL}circle/${circleId}/member/${circleUserId}/post/${id}`;
	}
};

export const posts = {
	url: ({ circleId, start = 0, limit = '' }) =>
		`${BASE_URL}circle/${circleId}/post/?start=${start}&limit=${limit}`
};

export const member = {
	url () {
		const { circle } = this.attributes;
		const id = this.getId() || '';
		return `${BASE_URL}circle/${circle}/member/${id}`;
	}
};

export const members = {
	url: ({ circleId, start = 0, limit = '' }) =>
		`${BASE_URL}circle/${circleId}/member/?start=${start}&limit=${limit}`
};

export const category = {
	url: `${BASE_URL}circle-category/`
};

export const categories = {
	url: `${BASE_URL}circle-category/`
};

export const candidate = {
	url () {
		const { circle } = this.attributes;
		const id = this.getId() || '';
		return `${BASE_URL}circle/${circle}/candidate/${id}`;
	}
};

export const candidates = {
	url: ({ circleId, start = 0, limit = '' }) =>
		`${BASE_URL}circle/${circleId}/candidate/?start=${start}&limit=${limit}`
};

export const user = {
	url: `${BASE_URL}circle/user/`
};

export const abuseReportCreate = {
	url () {
		const { circle, circleUser, circlePost } = this.attributes;

		return `${BASE_URL}circle/${circle}/member/${circleUser}/post/${circlePost}/report-abuse/`;
	}
};

export const abuseReportUpdate = {
	url () {
		return `${BASE_URL}circle/report-abuse/${this.getId()}`;
	}
};

export const abuseReports = {
	url: ({ start = 0, limit = '' }) =>
		`${BASE_URL}circle/report-abuse/?start=${start}&limit=${limit}`
};

export const searchAbuseReports = {
	url: (params) => `${BASE_URL}circle/report-abuse/?${urlify(params)}`
};

export const abuseReportDeletePost = {
	url: (circleReportAbuseId) => `${BASE_URL}circle/report-abuse/${circleReportAbuseId}/post/`
};

export const abuseReportIdentifyUser = {
	url: (circleReportAbuseId) =>
		`${BASE_URL}circle/report-abuse/${circleReportAbuseId}/user-identify`
};

export const abuseReportStatus = {
	url: `${BASE_URL}circle/report-abuse/status/`
};

export const userBan = {
	url () {
		const { circleUserId } = this.attributes;
		return `${BASE_URL}circle/user/${circleUserId}/ban`;
	}
};

export default {
	abuseReportCreate,
	abuseReportDeletePost,
	abuseReportIdentifyUser,
	abuseReports,
	abuseReportStatus,
	abuseReportUpdate,
	candidate,
	candidates,
	categories,
	category,
	circle,
	circlesByUser,
	isUnique,
	member,
	members,
	post,
	posts,
	search,
	searchAbuseReports,
	user
};
