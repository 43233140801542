import FormComponentView from '../form-component-view';
import { compile } from 'handlebars';
import t from 'translate';
import { isFunction } from 'lodash';

const checkbox = FormComponentView.extend({
	template: compile(`
		<div class="form-view__label-container">
			<p class="form-view__label-text" id="{{ name }}-label">{{ label }}</p>
			<label class="form-view__label">
				<input
					type="checkbox"
					class="form-view__input form-view__checkbox"
					name="{{ uniqueName }}"
					aria-labelledby="{{ name }}-label"
					{{#if readonly}} readonly="readonly"{{/if}}
					{{#if required}} required="required" {{/if}}
					{{#if checked}} checked="checked" {{/if}}
					{{#if disabled}} disabled="disabled" {{/if}}
					{{#if hint}} aria-describedby="{{ name }}-hint"{{/if}} />
				<span class="form-view__value-description">{{ valueDescription }}</span>
			</label>
		</div>
		{{#if hint}}
			<div class="form-view__hint" id="{{ name }}-hint">{{ hint }}</div>
		{{/if}}
	`),
	customClassName: 'form-view__field-container--collapsible',
	valueDescriptions: [t('Yes'), t('No')],
	valueDescriptionsClassName: 'form-view__value-description',
	ANIMATION_TIME: 200, // has to be the same in css

	initialize () {
		// use !! instead of === true because backend doesn't return boolean for checkbox attributes
		this.model.set('checked', !!this.model.get('value'));
		this.model.set('disabled', this.model.get('disabled') || this.model.get('readonly'));

		if (this.model.has('valueDescriptions')) {
			this.valueDescriptions = this.model.get('valueDescriptions');
		}
	},

	events: {
		'change @ui.input' () {
			const value = this.ui.input.prop('checked');
			this.trigger('value.change', value);
			isFunction(this.model.get('onChange')) && this.model.get('onChange')({
				value: this.ui.input.prop('checked'),
				label: this.getLabel()
			});
		},
		'click .form-view__label-container .form-view__value-description' () {
			this.ui.input.trigger('change');
		}
	},

	onAfterRender () {
		this.ui.valueDescription = this.$('.form-view__value-description');
		this.setValueDescription(!!this.model.get('value'));
	},

	setValueDescription (bool) {
		const className = (mod) => `${this.valueDescriptionsClassName}--${mod}`;
		const modifier = `turning-${bool ? 'on' : 'off'}`;
		const $el = this.ui.valueDescription;

		$el.addClass(className(modifier));
		setTimeout(() => {
			$el.removeClass(className(modifier));
		}, this.ANIMATION_TIME);

		$el.text(this.valueDescriptions[0]);
	},

	setChecked (value) {
		this.ui.input.prop('checked', !!value);
		return this;
	}
});

export { checkbox };
