import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import AssessmentType from './type';
import systemSettings from 'system-settings';

const AssessmentTypes = MultipleEntities.extend({
	urls,
	model: AssessmentType,
	eventNamespace: 'assessment-type',
	order: 'order',

	getAll () {
		return systemSettings.getBoolean('ASSESSMENT_TYPES') ? this.retrieve('types') : null;
	}
});

export default new AssessmentTypes();
