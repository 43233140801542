import { param } from 'jquery';

const prefix = 'backend-commons/report/rest/report';

export default {
	allReports: {
		url: `${prefix}/assessment/all`
	},
	reportsByData: {
		url: (data) => `${prefix}/assessment/all?${param(data)}`
	},
	report: {
		url: `${prefix}/assessment/`
	},

	reportsByAssessmentId: {
		url: (id) => `${prefix}/assessment/assessmentinstance/${id}`
	},
	reportContent: {
		url () {
			const {
				assessmentCode,
				assessmentInstanceId,
				reportCode,
				reportId,
				respondentId,
				treatmentId
			} = this.toJSON();

			if (assessmentCode && reportCode) {
				const params = `assessmentCode=${assessmentCode}&reportCode=${reportCode}`;

				if (treatmentId) {
					return `${prefix}/generate/treatment/${treatmentId}?${params}`;

				} else if (respondentId) {
					return `${prefix}/generate/respondent/${respondentId}?${params}`;
				}

				return `${prefix}/generate/?${params}`;
			}

			return `${prefix}/generate/${assessmentInstanceId}/${reportId}`;
		}
	}
};
