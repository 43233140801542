<template>
	<div :class="containerClassName">
		<label :class="labelClassName">
			<template v-if="showLabel">
				<icon v-if="icon" :name="icon" :class-name="iconClass" />
				<p v-if="translate" v-translate :class="labelTextClassName">{{label}}</p>
				<p v-else :class="labelTextClassName">{{label}}</p>
			</template>
			<div v-if="async && loading" v-loader-spinner:small class="select-input__loader" />
			<input
				v-if="readonly"
				:value="value"
				class="text-input__input"
				:disabled="disabled"
				readonly
				:placeholder="placeholder"
			/>
			<v-select
				v-else
				ref="vSelect"
				:clearable="clearable"
				:disabled="disabled"
				:filterable="!async"
				:get-option-label="getOptionLabel"
				:label="itemLabel"
				:loading="loading"
				:multiple="multiple"
				:no-drop="!dropdownVisible"
				:options="options"
				:placeholder="placeholder"
				:searchable="searchable"
				:value="value"
				:[maybereduce]="reduce"
				@input="updateValue"
				@search="onSearch"
			>
				<template #no-options>{{$t('selectInput.noResults')}}</template>
			</v-select>
		</label>
	</div>
</template>

<script>
import Vue from 'vue';
import { className } from './_helpers';
import vSelect from 'vue-select';
import { isFunction, forEach } from 'lodash';

const COMPONENT_NAME = 'select-input';

export default Vue.component(COMPONENT_NAME, {
	components: { vSelect },
	props: {
		value: {
			type: [Boolean, Number, String, Object, Array],
			default: ''
		},
		itemLabel: {
			type: String,
			default: 'label'
		},
		async: {
			type: Boolean,
			default: false
		},
		classPrefix: {
			type: String,
			default: ''
		},
		containerClass: {
			type: String,
			default: ''
		},
		exceeded: {
			type: Boolean,
			default: false
		},
		icon: {
			type: String,
			default: ''
		},
		label: {
			type: String,
			required: true
		},
		mandatory: {
			type: Boolean,
			default: false
		},
		options: {
			type: Array,
			required: true
		},
		placeholder: {
			type: String,
			default: ''
		},
		required: {
			type: Boolean,
			default: false
		},
		searchable: {
			type: Boolean,
			default: true
		},
		noDrop: {
			type: Boolean,
			default: false
		},
		emptySearchDropdown: {
			type: Boolean,
			default: true
		},
		showLabel: {
			type: Boolean,
			default: true
		},
		getOptionLabel: {
			type: vSelect.props.getOptionLabel.type,
			default: vSelect.props.getOptionLabel.default
		},
		reduce: {
			type: [vSelect.props.reduce.type, Boolean],
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		},
		clearable: {
			type: Boolean,
			default: true
		},
		invalid: {
			type: Boolean,
			default: false
		},
		multiple: {
			type: Boolean,
			default: false
		},
		translate: {
			type: Boolean,
			default: true
		},
		readonly: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({
		additionalClasses: [{
			value: 'exceeded',
			className: 'exceeded'
		}, {
			value: 'required',
			className: 'mandatory'
		}, {
			value: 'multiple',
			className: 'multiple'
		}],
		inputVal: ''
	}),

	computed: {
		containerClassName () {
			const element = 'field-container';
			const classes = this.className(element);

			if (this.containerClass) {
				classes.push(...this.className(this.containerClass));
			}
			forEach(this.additionalClasses, (single) => {
				if (this[single.value]) {
					classes.push(...this.className(`${element}--${single.className}`));
				}
			});
			return classes;
		},

		dropdownVisible: ({ emptySearchDropdown, noDrop, inputVal, value }) => {
			const isVisible = emptySearchDropdown ? true : !!inputVal.length || value;
			return noDrop ? false : isVisible;
		},

		iconClass: ({ className, icon }) => icon && className(`icon-${icon}`),
		labelClassName: ({ className, invalid }) => [
			...className('label-container'),
			invalid && 'invalid'
		],
		labelTextClassName () {
			const classes = [this.className('label-text')];

			if (this.mandatory) {
				classes.push(this.className('label--mandatory'));
			}

			if (this.classPrefix) {
				classes.push(`${this.classPrefix}__label-text`);
			}
			return classes;
		},
		inputClassName: ({ className }) => className('input'),
		maybereduce: ({ reduce }) => isFunction(reduce) ? 'reduce' : 'fakeReduce' // ¯\_(ツ)_/¯
	},

	mounted () {
		if (this.$refs.vSelect) {
			// remove default v-select aria-label
			const vSelectNode = this.$refs.vSelect.$el;
			vSelectNode.querySelector('.vs__dropdown-toggle').removeAttribute('aria-label');
		}
	},

	methods: {
		updateValue (val) {
			// when clearing a value it is going to be null
			if (!val || !val.disabled) {
				this.$emit('input', val);
			}
		},
		className: className(COMPONENT_NAME),
		onSearch (val) {
			this.inputVal = val;
			this.$emit('search', val);
		}
	}
});
</script>
