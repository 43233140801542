import { assign, cloneDeep } from 'lodash';
import mediaReferences from './media-references';
import attachments from './attachments';
import uploadedFiles from './uploaded-files';
import assessmentInstances from './assessment-instances';
import { DELETE, GET, POST, PUT } from 'service/ajax/ajax';
import urls from './urls';
import glob, { initialGlob } from 'modules/assessment-runtime/core/glob';

const assessmentUrl = ({ assessmentInstanceId, urlName }) =>
	urls[urlName].url(assessmentInstanceId);

/**
 * @class Repository
 */
export default {
	/**
	 * Get all AssessmentInstances.
	 *
	 * @function getAssessmentInstances
	 * @returns {object} - New AssessmentInstances Collection.
	 * @example
	 *        repository.getAssessmentInstances();
	 */
	getAssessmentInstances: () => assessmentInstances.getAll(),

	/**
	 * Get a new collection of all AssessmentInstances assigned to a Respondent.
	 *
	 * @function respondentAssessmentInstances
	 * @param  {number|string} respondentId - Id of respondent.
	 * @returns {object}                    - New AssessmentInstances Collection.
	 * @example
	 *        repository.respondentAssessmentInstances(19);
	 */
	respondentAssessmentInstances: (respondentId) =>
		assessmentInstances.getByRespondentId(respondentId),

	/**
	 * Get a new collection of all AssessmentInstances assigned to a Clinician.
	 *
	 * @function getMyAssessmentInstances
	 * @returns {object} - New AssessmentInstances Collection.
	 * @example
	 *        repository.getAssessmentInstancesByCurrentClinician();
	 */
	getMyAssessmentInstances: () => assessmentInstances.getMy(),

	/**
	 * Get AssessmentInstance.
	 *
	 * @function assessmentInstance
	 * @param {number|string} assessmentInstanceId - AssessmentInstance ID.
	 * @returns {object}                           - AssessmentInstance.
	 * @example
	 *        repository.assessmentInstance(7);
	 */
	assessmentInstance: (assessmentInstanceId) => assessmentInstances.getById(assessmentInstanceId),

	/**
	 * Get media references for assessment instance.
	 *
	 * @function getMediaReferences
	 * @param {number|string} assessmentInstanceId - AssessmentInstance ID.
	 * @returns {object}                           - MediaReferences.
	 * @example
	 *        repository.getMediaReferencesByAssessmentInstanceId(7);
	 */
	getMediaReferences: (assessmentInstanceId) =>
		mediaReferences.getByAssessmentInstanceId(assessmentInstanceId),

	/**
	 * Get attachments for assessment instance.
	 *
	 * @function assessmentInstanceAttachments
	 * @param {number|string} assessmentInstanceId - AssessmentInstance ID.
	 * @returns {object}                           - Attachments.
	 * @example
	 *        repository.assessmentInstanceAttachments(7);
	 */
	assessmentInstanceAttachments: (assessmentInstanceId) =>
		attachments.getByAssessmentInstanceId(assessmentInstanceId),

	/**
	 * Get files for assessment instance.
	 *
	 * @function assessmentInstanceFiles
	 * @param  {number|string} assessmentInstanceId - AssessmentInstance ID.
	 * @returns {object}                            - UploadedFiles.
	 * @example
	 *        repository.assessmentInstanceFiles(7);
	 */
	assessmentInstanceFiles: (assessmentInstanceId) =>
		uploadedFiles.getByAssessmentInstanceId(assessmentInstanceId),

	/**
	 * Get a new collection of expired AssessmentInstances.
     *
	 * @function getExpiredAssessmentInstances
	 * @param {object} params - Set of parameters.
	 * @returns {object}      - New AssessmentInstances Collection.
	 * @example
	 *        repository.getExpiredAssessmentInstances({ start: 0, limit: 10 });
	 */
	getExpiredAssessmentInstances: (params) => assessmentInstances.getExpired(params),

	/**
	 * Get a new collection of all unseen AssessmentInstances.
	 *
	 * @function getUnseenAssessments
	 * @param {object} params - Set of parameters.
	 * @returns {object} New AssessmentInstances Collection.
	 * @example
	 *        repository.getUnseenAssessments({ start: 0, limit: 10 }));
	 */
	getUnseenAssessments: (params) => assessmentInstances.getUnseen(params),

	/**
	 * Get AssessmentInstances by treatment id.
	 *
	 * @function treatmentAssessmentInstances
	 * @param {number|string} treatmentId - Treatment ID.
	 * @returns {object}                  - AssessmentInstance.
	 * @example
	 *        repository.treatmentAssessmentInstances(1);
	 */
	treatmentAssessmentInstances: (treatmentId) =>
		assessmentInstances.getByTreatmentId(treatmentId),

	/**
	 * Generate assessment portal code.
	 *
	 * @function createAssessmentPortalCode
	 * @param {number} assessmentInstanceId - AssessmentInstance ID.
	 * @example
	 *        repository.createAssessmentPortalCode(45);
	 */
	createAssessmentPortalCode: (assessmentInstanceId) => POST({
		url: assessmentUrl({ assessmentInstanceId, urlName: 'assessmentPortalCode' })
	}),

	/**
	 * Remove assessment portal code.
	 *
	 * @function deleteAssessmentPortalCode
	 * @param {number} assessmentInstanceId - AssessmentInstance ID.
	 * @example
	 *        repository.deleteAssessmentPortalCode(45);
	 */
	deleteAssessmentPortalCode: (assessmentInstanceId) => DELETE({
		url: assessmentUrl({ assessmentInstanceId, urlName: 'assessmentPortalCode' })
	}),

	/**
	 * Send assessment portal code.
	 *
	 * @function sendAssessmentPortalCode
	 * @param {object} params - Parameters.
	 * @param {number} params.assessmentInstanceId - AssessmentInstance ID.
	 * @param {object} params.data - Data to be attached.
	 * @example
	 * 		repository.deleteAssessmentPortalCode({
	 *            assessmentInstanceId: 45,
	 *            data: {
	 *                useSms: true,
	 *                useEmail: true,
	 *                phoneNumber: store.getters.respondent.respondentPhone,
	 *                email: store.getters.respondent.respondentEmail
	 *            }
	 *        });
	 */
	sendAssessmentPortalCode: ({ assessmentInstanceId, data }) => POST({
		data,
		url: assessmentUrl({ assessmentInstanceId, urlName: 'sendAssessmentPortalCode' })
	}),

	/**
	 * Retrieve assessment values based on passed data.
	 *
	 * @function assessmentValues
	 * @param {Array} data - Data parameters.
	 * @returns {object}   - XHR response.
	 * @example
	 * 		repository.assessmentValues([{
	 * 			ref: 'aa',
	 * 			occurence: 'average',
	 * 			assessmentCode: 'SCORE-15',
	 * 			questionCode: 'Q17-RATEc',
	 * 			scope: 'treatment',
	 * 			treatmentId: 2,
	 * 			include: 'assessmentInstance'
	 *		}]);
	 */
	assessmentValues: (data) => PUT({ data, url: urls.assessmentValues.url }),

	/**
	 * Create Assessment Instance.
	 *
	 * @function createAssessmentInstance
	 * @param {object} data - Data object.
	 * @returns {object}    - XHR response.
	 * @example
	 * 		repository.createAssessmentInstance({
	 * 		 	assessmentCode: 'ORS',
	 * 		 	treatmentId: 7,
	 * 		 	treatmentTypeRoleId: 2,
	 * 		 	deadlineOffset: '2 hour'
	 * 		});
	 */
	createAssessmentInstance: (data) => POST({ data, url: urls.create.url }),

	/**
	 * Lock Assessment Instance.
	 *
	 * @function lockAssessmentInstance
	 * @param {object} args - Arguments.
	 * @param {number} args.id - Assessment instance id.
	 * @param {Function} args.success - Success callback.
	 * @example
	 * 		repository.lockAssessmentInstance({
	 * 			id: 5,
	 * 			success: () => {
	 * 				cwalert.success(t('Assessment is now closed'));
	 * 			}
	 * 		})
	 */
	lockAssessmentInstance: ({ id, success }) => {
		assign(glob, cloneDeep(initialGlob));

		import(
			/* webpackChunkName: "assesmentruntimeinstance" */
			/* webpackMode: "lazy" */
			`modules/assessment-runtime/entities/assessment-runtime-instance`
		).then((module) => {
			const AssessmentRuntime = module.default;

			const assessmentRuntime = new AssessmentRuntime({ assessmentInstanceId: id });

			// in order to lock assessment instance, it has to be
			// submitted first
			assessmentRuntime.fetch().then(() => {
				assessmentRuntime.getAssessment().submit({ success }, { close: true });
			});
		});
	},

	/**
	 * Unlock the assessment instance.
	 *
	 * @function unlockAssessmentInstance
	 * @param {number} id - Assessment instance ID.
	 * @returns {object}  - Promise.
	 * @example
	 * 		repository.unlockAssessmentInstance(2137);
	 */
	unlockAssessmentInstance: (id) => PUT({
		url: urls.single(id),
		data: {
			delivered: false,
			assessmentInstanceStatus: {
				assessmentInstanceClosed: false,
				hasStarted: true,
				isSubmitted: false
			}
		}
	}),

	/**
	 * Erase responses from the assessment instance.
	 *
	 * @param {number} id - Assessment instance ID.
	 * @returns {object}  - Promise.
	 * @example
	 * 		repository.eraseAssessmentInstanceResponses(2137);
	 */
	eraseAssessmentInstanceResponses: (id) => GET({	url: urls.eraseResponses.url(id) }),

	/**
	 * @param {number} id - Assessment instance ID.
	 * @returns {object}  - Promise.
	 * @example
	 * 		repository.deleteAssessmentInstance(2137);
	 */
	deleteAssessmentInstance: (id) => DELETE({ url: urls.single2(id) }),

	/**
	 * Get access token for IBDoc CalApp login.
	 *
	 * @function ibdocToken
	 * @param {number} assessmentInstanceId - Assessment instance ID.
	 * @returns {object} - Promise.
	 * @example
	 * 		repository.ibdocToken(assessmentInstanceId);
	 */
	ibdocToken: (assessmentInstanceId) => GET({ url: urls.ibdocToken(assessmentInstanceId) })
};
