import SingleEntity from '../shared/single-entity';
import urls from './urls';
import $ from 'jquery';
import Backbone from 'backbone';
import appContext from 'app-context';

/**
 * Flag Entity
 * @class Flag
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	eventNamespace: 'flag',
	urlRoot: urls.single.url,

	assessmentInstanceId: function () {
		return this.get('assessmentInstance').assessmentInstanceId;
	},

	respondentId: function () {
		return this.get('respondent').respondentId;
	},

	lower: function () {
		this.set('isRaised', false);

		return $.ajax({
			url: Backbone.config().backendRootUrl['default'] + 'backend-commons/flag/' + this.getId() + '/unraise/',
			type: 'PUT',
			contentType: 'application/json',
			data: JSON.stringify({
				flag: this.getId(),
				administrator: appContext.get('user').get('userId'),
				comment: this.get('unraiseComment'),
				reason: this.get('unraiseReason')
			})
		});
	},

	markAsSeen: function () {
		this.set('isSeen', true);

		return $.ajax({
			url: Backbone.config().backendRootUrl['default'] + 'backend-commons/flag/' + this.getId() + '/mark-as-seen/' + appContext.get('user').get('userId'),
			type: 'PUT'
		});
	}
});
