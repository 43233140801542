import clinicianConfig from './clinician-config';
import respondentConfig from './respondent-config';
import planeditorConfig from './planeditor-config';
import assessmentConfig from './assessment-config';
import atomicConfig from './atomic-config';
import Backbone from 'backbone';
import { isFunction, replace } from 'lodash';

export default ({ type }) => {
	const appConfig = {
		assessment: assessmentConfig,
		atomic: atomicConfig,
		clinician: clinicianConfig,
		planeditor: planeditorConfig,
		respondent: respondentConfig
	}[type];

	const backboneSync = Backbone.sync;
	// do not append backendUrlRoot to some urls
	const skip = (url) => {
		const urlRegex = /^\/|\.\./; // string starting with slash or two dots
		return !/^https?:/i.test(url) && (!urlRegex.test(url));
	};

	Backbone.sync = function (method, model) {
		if (model.url) {
			if (isFunction(model.url)) {
				model.fUrl = model.url;
			}

			let url = `${(isFunction(model.fUrl)) ? model.fUrl() : model.url}`;
			const backendUrlRoot = appConfig.config().backendRootUrl;

			if (skip(url)) {
				let target = 'default';
				url = replace(url, /^@(\w+)\//, (match, dst) => {
					target = dst;
					return '';
				});

				model.url = backendUrlRoot[target] + url;
			}
		}

		return backboneSync.apply(this, arguments); // eslint-disable-line prefer-rest-params
	};

	Backbone.getBackendRoot = function () {
		return appConfig.config().backendRootUrl;
	};

	Backbone.config = function () {
		return appConfig.config();
	};
};
