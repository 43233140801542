import { View } from 'marionette';

export default View.extend({
	template: () => `<div class="appbar-elements__content"></div>`,
	tagName: 'li',
	className: 'appbar-elements__item',

	modelEvents: {
		'change:preventHide': 'setPreventHide'
	},

	regions: {
		content: '.appbar-elements__content'
	},

	initialize (opt) {
		this.contentView = opt.model.get('view');
	},

	renderContentView () {
		this.getRegion('content').show(this.contentView);
	},

	onRender () {
		this.setPreventHide();
		this.renderContentView();
	},

	onShow () {
		this.contentView.trigger('appbarItem.show');
	},

	// if 'prevent-hide' class is present on an item, it will be visible in all layous
	setPreventHide () {
		this.$el.toggleClass('prevent-hide', this.model.get('preventHide'));
	}
});
