<template functional>
	<ul class="simple-list">
		<li
			v-for="(item, i) in props.items"
			:key="i"
			class="simple-list__item"
			:class="props.className(item)"
			:title="props.getTitle(item)"
		>
			<a
				:href="props.getUrl(item)"
				class="simple-list__item-link"
				v-html="props.content(item)"
			/>
		</li>
		<p v-if="!props.items.length" v-text="props.emptyContent" />
	</ul>
</template>

<script>
import { noop } from 'lodash';
import t from 'translate';

export default {
	name: 'SimpleList',
	props: {
		items: {
			type: Array,
			required: true
		},
		className: {
			type: Function,
			default: noop
		},
		getTitle: {
			type: Function,
			default: noop
		},
		getUrl: {
			type: Function,
			default: noop
		},
		content: {
			type: Function,
			default: noop
		},
		emptyContent: {
			type: String,
			default: t('No items')
		}
	}
};
</script>
