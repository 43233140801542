var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { class: _vm.mainClassName },
    [
      _c("h2", { class: _vm.elementClassName("title") }, [
        _vm._v("\n\t\t" + _vm._s(_vm.title) + "\n\t")
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("span", {
            directives: [
              { name: "loader-spinner", rawName: "v-loader-spinner" }
            ],
            class: _vm.elementClassName("loader")
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "nav",
        { class: _vm.elementClassName("controls") },
        [
          _vm.disabled ? _vm._t("disabled") : _vm._e(),
          _vm._v(" "),
          _c("h2", { class: _vm.elementClassName("controls-header") }, [
            _c(
              "button",
              {
                class: _vm.elementClassName("controls-prev"),
                attrs: { title: _vm.$t("Previous") },
                on: {
                  click: function($event) {
                    return _vm.changeRange(_vm.ACTIONS.GO_PREV)
                  }
                }
              },
              [
                _c("icon", {
                  class: _vm.elementClassName("icon-prev"),
                  attrs: { small: true, name: "angle-left" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                class: _vm.elementClassName("controls-text"),
                on: {
                  click: function($event) {
                    return _vm.changeRange(_vm.ACTIONS.GO_UP)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.current))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                class: _vm.elementClassName("controls-next"),
                attrs: { title: _vm.$t("Next") },
                on: {
                  click: function($event) {
                    return _vm.changeRange(_vm.ACTIONS.GO_NEXT)
                  }
                }
              },
              [
                _c("icon", {
                  class: _vm.elementClassName("icon-next"),
                  attrs: { small: true, name: "angle-right" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                class: _vm.toggleFiltersClassName,
                on: { click: _vm.toggleFilters }
              },
              [
                _c("icon", { attrs: { name: "filter" } }),
                _vm._v(" "),
                _c(
                  "span",
                  { class: _vm.elementClassName("toggle-filters-text") },
                  [_vm._v(_vm._s(_vm.$t("Filter")))]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                class: _vm.elementClassName("controls-refresh"),
                on: { click: _vm.onRefresh }
              },
              [_vm._v(_vm._s(_vm.refreshLabel))]
            )
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              class: _vm.controlsInputsClassName,
              on: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "div",
                { class: _vm.elementClassName("controls-wrapper") },
                [
                  _vm.settings.showTypeSelect !== false
                    ? _c("agenda-filter", {
                        attrs: {
                          "filter-items": _vm.filter.types,
                          "on-change": _vm.onTypeFilterChange,
                          "i18n-prefix": "agenda.filters.type",
                          "initial-checked": _vm.initialTypes
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.settings.showStatusSelect !== false
                    ? _c("agenda-filter", {
                        attrs: {
                          "filter-items": _vm.filter.statuses,
                          "on-change": _vm.onStatusFilterChange,
                          "i18n-prefix": "agenda.filters.status",
                          "initial-checked": _vm.initialStatuses
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.settings.showViewSelect !== false
                    ? _c("agenda-filter", {
                        attrs: {
                          "close-filter": true,
                          "filter-items": _vm.filter.scopes,
                          "on-change": _vm.onScopeFilterChange,
                          multi: false,
                          "initial-checked": [
                            _vm.filteringItem(_vm.scope, "scope")
                          ]
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { class: _vm.elementClassName("controls-wrapper") }, [
                _c(
                  "div",
                  {
                    class: _vm.elementClassName(
                      "controls-expand-collapse-container"
                    )
                  },
                  [
                    _vm.settings.showExpandAllButton !== false
                      ? _c(
                          "button",
                          {
                            class: _vm.elementClassName("controls-expand"),
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.onExpand($event)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.expandLabel))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.settings.showCollapseAllButton !== false
                      ? _c(
                          "button",
                          {
                            class: _vm.elementClassName("controls-collapse"),
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.onCollapse($event)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.collapseLabel))]
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _vm.settings.showCompressedModeToggle !== false
                  ? _c("div", { class: _vm.elementClassName("compress") }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.compressed,
                            expression: "compressed"
                          }
                        ],
                        attrs: { id: _vm.cid, type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.compressed)
                            ? _vm._i(_vm.compressed, null) > -1
                            : _vm.compressed
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.compressed,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.compressed = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.compressed = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.compressed = $$c
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          attrs: {
                            for: _vm.cid,
                            role: "button",
                            tabindex: "0",
                            "aria-label": _vm.$t("agenda.labels.compress"),
                            "aria-pressed": _vm.compressed
                          },
                          on: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              _vm.compressed = !_vm.compressed
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            { class: _vm.elementClassName("compress-title") },
                            [_vm._v(_vm._s(_vm.$t("agenda.labels.compress")))]
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ])
            ]
          ),
          _vm._v(" "),
          !_vm.disabled
            ? _c("agenda-add-item", { attrs: { items: _vm.addableConfig } })
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      !_vm.disabled
        ? _c("agenda-groups", {
            attrs: {
              groups: _vm.groups,
              addable: _vm.addableConfig,
              scope: _vm.scope,
              collapsed: _vm.collapsed,
              compressed: _vm.compressed,
              direction: _vm.direction,
              "store-path": _vm.storePath
            }
          })
        : _vm._t("empty"),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          !_vm.visibleItems.length &&
          !_vm.loading &&
          _vm.compressed &&
          !_vm.disabled
            ? _vm._t("noItems", [
                _c("p", { class: _vm.elementClassName("no-items") }, [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.$t("agenda.labels.noItems")) +
                      "\n\t\t\t"
                  )
                ])
              ])
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("nav", { class: _vm.elementClassName("controls--secondary") }, [
        _c("h2", { class: _vm.elementClassName("controls-title") }, [
          _c(
            "button",
            {
              class: _vm.elementClassName("controls-prev"),
              on: {
                click: function($event) {
                  return _vm.changeRange(_vm.ACTIONS.GO_PREV)
                }
              }
            },
            [
              _c("icon", { attrs: { small: true, name: "backward" } }),
              _vm._v(" " + _vm._s(_vm.prevName) + "\n\t\t\t")
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              class: _vm.elementClassName("controls-next"),
              on: {
                click: function($event) {
                  return _vm.changeRange(_vm.ACTIONS.GO_NEXT)
                }
              }
            },
            [
              _vm._v("\n\t\t\t\t" + _vm._s(_vm.nextName) + " "),
              _c("icon", { attrs: { small: true, name: "forward" } })
            ],
            1
          )
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }