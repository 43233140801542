import t from 'translate';
import repository from 'repository';
import SearchTileView from 'core/engine/tile/views/search-tile-view';
import cardurl from 'cwcardurl';
import store from 'store';
import enableCirclesCheck from '../../helpers/enable-circles-check';
import { assign, compact, forEach, map } from 'lodash';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints.json';
import listFields from '../../shared/list-fields';
import treatmentContextParam from 'modules/treatments/util/treatment-context-param';

export default SearchTileView.extend({
	title: t('Join an existing circle'),
	acl: [{
		op: READ,
		checkpoint: CLINICIAN.CIRCLES.CIRCLES
	}],
	features: enableCirclesCheck,

	searchFn (...args) {
		const collection = repository.searchCircles(...args);
		collection.then(() => {
			store.dispatch('circles/add', { collection, circles: collection.toJSON() });
		});
		return collection;
	},

	fields: ({ tile }) => {
		const ids = (value) => compact(
			map(value, (item, key) => item && key)
		);

		return [{
			key: 'categories.id',
			type: 'single-select',
			label: t('Category'),

			values: map(store.state.circles.categories.current, (category) => assign({}, category, {
				label: category.name
			})),

			onChange: (value) => {
				tile.viewModel.set('categories.id', ids(value).toString());
				tile.runQuery();
			}
		}, {
			key: 'exactMatch',
			type: 'checkbox',
			label: t('Exact circle code match'),

			onChange: (value) => {
				tile.viewModel.set('exactMatch', value);

				if (!tile.viewModel.get('search')) {
					tile.viewModel.set('search', '');
				}
				tile.runQuery();
			}
		}];
	},

	filterCfg: ({ tile }) => ({
		searchFields: [{
			key: 'searchFilter',
			type: 'search',
			label: t('Find'),
			onChange: (search) => {
				tile.viewModel.set({ search });
				tile.runQuery();
			}
		}]
	}),

	onClear: ({ tile }) => {
		tile.viewModel.set('exactMatch', false);
	},

	tableCfg: ({ tile }) => {
		tile.buttons = [];

		return {
			click: (item) => {
				cardurl.openCard('circle', {
					circleId: item.id,
					...treatmentContextParam()
				});
			},
			columns: listFields(tile)
		};
	},

	onRemove: ({ tile }) => {
		forEach(tile.buttons, (button) => {
			button.$destroy();
		});
	}
});
