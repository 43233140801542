var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("edit-goal", {
    attrs: {
      goal: _vm.goal,
      categories: _vm.categories,
      "on-submit": _vm.onSubmit,
      "on-cancel": _vm.onCancel,
      treatments: []
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }