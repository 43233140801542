<template>
	<transition name="modal">
		<trap :disabled="!show">
			<div
				v-if="show"
				role="dialog"
				aria-modal="true"
				class="modal-dialog"
				:class="className"
				@keyup.esc="handleClose"
			>
				<div class="modal-dialog__shadow" />
				<div class="modal-dialog__top-bar">
					<button
						tabindex="-1"
						class="modal-dialog__dismiss"
						:title="t('Close')"
						@click="handleClose"
					>
						<icon name="times" />
					</button>
					<h2 class="modal-dialog__title">
						<slot name="title" />
					</h2>
				</div>
				<div class="modal-dialog__window">
					<div v-if="loading" v-loader-spinner class="modal-dialog__loader" />
					<div class="modal-dialog__content">
						<slot name="content" />
					</div>
				</div>
			</div>
		</trap>
	</transition>
</template>

<script>
import Vue from 'vue';
import trap from 'vue-focus-lock';
import focused from 'service/focused-elements';
import t from 'translate';
import { invoke } from 'lodash';

export default Vue.component('ModalDialog', {
	components: { trap },
	props: {
		show: {
			type: Boolean,
			default: false
		},
		onClose: {
			type: Function,
			required: true
		},
		loading: {
			type: Boolean,
			default: false
		},
		className: {
			type: String,
			default: ''
		},
		customFocus: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		focused: null
	}),
	created () {
		this.focused = focused.last();
	},
	mounted () {
		// #justie11things
		invoke(document, 'activeElement.blur');
	},
	methods: {
		handleClose () {
			this.onClose();

			if (!this.customFocus) {
				invoke(this.focused, 'focus');
			}
		},
		t
	}
});
</script>
