import SingleEntity from '../shared/single-entity';
import urls from './urls';

/**
 * TreatmentTypeAttribute Entity
 * @class TreatmentTypeAttribute
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	url: function () {
		return urls.singleAttribute.url(this.get('treatmentType'), this.getId());
	},
	eventNamespace: 'treatment-type-attribute',
	defaults: {
		type: 'CHECKBOX',
		isRequired: false,
		isVisible: true,
		isReadonly: false,
		order: '0',
		parameters: null
	}
});
