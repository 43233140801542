import repository from 'repository';
import prefixify from '../__helpers/prefixify';
import { set } from '../__helpers/mutations';
import { RESPONDENT } from 'store/respondents/respondent';
import { exact } from '../__helpers/getters';

const prefix = prefixify('respondentContentPackageElements');

const DATA = 'data';
const INIT = 'init';

const SET = 'set';

export const RESPONDENT_CONTENT_PACKAGE_ELEMENTS = {
	DATA: prefix(DATA),

	INIT: prefix(INIT)
};

export default {
	namespaced: true,

	state: {
		[DATA]: {}
	},

	getters: {
		...exact([DATA])
	},

	mutations: {
		[SET]: set()
	},

	actions: {
		[INIT]: async ({ commit, rootGetters }, { contentPackageId }) => {
			const contentPackageElements = await repository.getContentPackageElements({
				cpInstanceId: contentPackageId,
				respondentId: rootGetters[RESPONDENT.RESPONDENT_ID]
			});
			commit(SET, contentPackageElements);
		}
	}
};
