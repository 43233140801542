var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.containerClasses,
      on: {
        keyup: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.removeEditItem($event)
        }
      }
    },
    [
      _c("tree-search", {
        attrs: {
          "change-position": _vm.changePosition,
          copy: _vm.copy,
          "create-mirror": _vm.createMirror,
          "delete-selected": _vm.deleteSelected,
          "download-url": _vm.downloadUrl,
          "enabled-copy": _vm.enabledCopy,
          "enabled-mirror": _vm.enabledMirror,
          "enabled-paste": _vm.enabledPaste,
          "local-items": _vm.localItems,
          matched: _vm.matched,
          "modified-not-saved": _vm.modifiedNotSaved,
          "on-remove-selected": _vm.onRemoveSelected,
          "on-search-key-up": _vm.onSearchKeyUp,
          "perform-copy": _vm.performCopy,
          "perform-paste": _vm.performPaste,
          position: _vm.position,
          "save-allowed": _vm.saveAllowed,
          selected: _vm.selected,
          "selected-mirror": _vm.selectedNestedMirror,
          "save-template": _vm.saveTemplate,
          "set-modified": _vm.setModified
        }
      }),
      _vm._v(" "),
      _c(
        "ol",
        { staticClass: "tree-view__list" },
        [
          _c("tree-item", {
            attrs: {
              blueprints: _vm.blueprints,
              "checked-items": _vm.checkedItems,
              copy: _vm.copy,
              "copy-path": _vm.copyPath,
              dispatch: _vm.dispatch,
              getters: _vm.getters,
              icons: _vm.icons,
              "incomplete-paths": _vm.incompletePaths,
              indeterminate: _vm.indeterminate,
              item: _vm.localItems,
              "mirror-details": _vm.mirrorDetails,
              mirrors: _vm.mirrors,
              "pass-button-refs": _vm.passButtonRefs,
              position: _vm.position,
              "root-mirrors": _vm.rootMirrors,
              selected: _vm.selected,
              "set-copy-path": _vm.setCopyPath,
              toggle: _vm.toggle,
              "toggle-edit": _vm.onItemClick,
              "warning-paths": _vm.invalidItems,
              "on-add": _vm.onItemAdd,
              "name-path": "fields.name"
            },
            on: { passButtonRefs: _vm.passButtonRefs, dispatch: _vm.dispatch }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }