import Backbone from 'backbone';
import getFileType from 'service/get-file-type';

const FallbackViewModel = Backbone.Model.extend({
	determineType (fileObject) {
		const fileObj = fileObject || this.toJSON();

		const fileType = getFileType(fileObj.mimeType);
		const isImage = fileType === 'image';
		this.set({
			type: isImage ? '' : fileType,
			isImage
		}, { silent: true });
		this.set(fileObj);
	}
});

export default new FallbackViewModel();

