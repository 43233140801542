import MultipleEntities from '../shared/multiple-entities';
import ConnectedApp from './connected-app';
import urls from './urls';

/**
 * ConnectedApps Entity
 * @class ConnectedApps
 * @extends MultipleEntities
 */
const ConnectedApps = MultipleEntities.extend({
	model: ConnectedApp,
	urls,
	eventNamespace: 'connected-app',

	listConnected (params) {
		return this.retrieve('connectedList', params);
	}
});

export default new ConnectedApps();
