import {
	cloneDeep,
	findIndex,
	filter,
	forEach,
	find,
	flow,
	get,
	includes,
	isEmpty,
	map
} from 'lodash';
import Vue from 'vue';
import repository from 'repository';
import { set } from '../__helpers/mutations';
import clinicianSidebarData from './clinician-sidebar-data';
import respondentSidebarData from './respondent-sidebar-data';
import { dataKey, defaultVariantName } from './sidebar-meta';
import { TREATMENT } from 'store/treatments/rp-treatment';

const DEFAULT_ITEM = {
	translate: true,
	icon: '',
	linkType: 'card',
	target: '',
	openInNewWindow: false,
	size: 'default',
	style: 'default',
	counter: '',
	acl: {}
};

const store = ({ data, repo, portal }) => ({
	namespaced: true,

	state: {
		availableCards: [],
		blueprint: data,
		custom: [],
		index: 0
	},

	getters: {
		cards: (state) => filter(
			state.availableCards,
			(card) => includes(card.portal, portal)
		),
		cardNames: flow([
			(state, getters) => map(getters.cards, 'name'),
			(cardNames) => cardNames.sort()
		]),
		config: (state) => state.custom,
		index: (state) => state.index,
		currentVariant: (state) =>
			get(state.custom, state.index, {}),
		currentMenuItems: (state, getters) => get(getters.currentVariant, dataKey, []),
		menuItems: (state, getters) => get(getters.computedVariant, dataKey, []),

		defaultVariant: (state) => find(state.custom, ['name', defaultVariantName]),
		// eslint-disable-next-line max-params
		computedVariant: (state, getters, rootState, rootGetters) => {
			const meetsCriterion = (criterionKey, criterionValue) => {
				if (criterionKey === 'treatmentTypeId') {
					return includes(
						criterionValue,
						get(rootGetters[TREATMENT.DATA], 'treatmentType.id')
					);
				}

				return true;
			};

			const findVariant = (variants) => {
				let computedVariant = getters.defaultVariant;

				const variantsWithCriteria = filter(
					variants,
					(v) => !isEmpty(get(v, 'when', {}))
				);

				forEach(variantsWithCriteria, (variant) => {
					let meetsCriteria = true;

					forEach(variant.when, (value, key) => {
						if (!meetsCriterion(key, value)) {
							meetsCriteria = false;
						}
					});

					if (meetsCriteria) {
						computedVariant = variant;
					}
				});

				return computedVariant;
			};

			return findVariant(state.custom);
		},

		currentCount: (state, getters) => getters.currentMenuItems.length
	},

	mutations: {
		set: set('custom'),

		add: (state, item) => {
			state.custom[state.index][dataKey] = [
				...state.custom[state.index][dataKey],
				item
			];
		},

		update: (state, item) => {
			const data = state.custom[state.index][dataKey];
			const idx = findIndex(data, { id: item.id });

			state.custom[state.index][dataKey][idx] = item;
		},

		remove: (state, { index }) => {
			state.custom[state.index][dataKey].splice(index, 1);
		},

		setCards: set('availableCards'),

		reset: (state) => {
			state.custom = cloneDeep(state.blueprint);
		},

		move: (state, { index, amount = 1 }) => {
			const items = state.custom[state.index][dataKey];

			items.splice(index + amount, 0, items.splice(index, 1)[0]);
		},

		cloneVariant: (state, variantName) => {
			const origin = cloneDeep(state.custom[state.index]);

			state.custom.push({
				...origin,
				name: variantName
			});
		},

		removeVariant: (state, variantName) => {
			const index = findIndex(
				state.custom,
				['name', variantName]
			);

			state.custom.splice(index, 1);
		},

		setVariant: (state, variantName) => {
			const index = findIndex(
				state.custom,
				['name', variantName]
			);

			state.index = index;
		},

		updateVariantName: (state, { index, variantName }) => {
			state.custom[index].name = variantName;
		},

		updateCriteria: (state, { index, criteria }) => {
			Vue.set(state.custom[index], 'when', criteria);
		}
	},

	actions: {
		init: async ({ commit, state }, {
			variant = defaultVariantName
		} = {}) => {
			const sidebarConfig = await repo.init();

			if (!get(sidebarConfig, `${state.index}.${dataKey}`, []).length) {
				commit('set', cloneDeep(state.blueprint));
				commit('setVariant', variant);
				return;
			}

			commit('set', sidebarConfig);
			commit('setVariant', variant);
		},

		initCards: ({ commit }) => repository.cardConfig({
			type: 'availableCards'
		}).then((cards) => {
			commit('setCards', cards);
		}),

		add: ({ commit, dispatch }, item) => {
			commit('add', { ...DEFAULT_ITEM, ...item });
			return dispatch('save');
		},

		update: ({ commit, dispatch }, item) => {
			commit('update', item);
			return dispatch('save');
		},

		remove: ({ commit, dispatch }, index) => {
			commit('remove', { index });
			return dispatch('save');
		},

		reset: ({ commit, dispatch }) => {
			commit('reset');
			return dispatch('save');
		},

		save: async ({ state }) => await repo.save(state.custom),

		moveItemUp: async ({ commit, dispatch }, index) => {
			commit('move', { index, amount: -1 });
			return await dispatch('save');
		},

		moveItemDown: async ({ commit, dispatch }, index) => {
			commit('move', { index, amount: 1 });
			return await dispatch('save');
		},

		cloneVariant: async ({ commit, dispatch }, variantName) => {
			commit('cloneVariant', variantName);
			return await dispatch('save');
		},

		removeVariant: async ({ commit, dispatch }, variantName) => {
			commit('removeVariant', variantName);
			return await dispatch('save');
		},

		setVariant: async ({ commit, dispatch }, variantName) => {
			commit('setVariant', variantName);
			return await dispatch('save');
		},

		changeVariantName: async (
			{ commit, getters, dispatch },
			{
				index = getters.index,
				variantName
			}
		) => {
			commit('updateVariantName', {
				index,
				variantName
			});
			return await dispatch('save');
		},

		updateCriteria: async (
			{ commit, getters, dispatch },
			{
				index = getters.index,
				criteria
			}
		) => {
			commit('updateCriteria', {
				index,
				criteria
			});
			return await dispatch('save');
		}
	}
});

export const clinicianSidebar = store({
	portal: 'clinician',
	data: clinicianSidebarData,
	repo: {
		init: repository.clinicianSidebar,
		save: repository.saveClinicianSidebar
	}
});
export const respondentSidebar = store({
	portal: 'respondent',
	data: respondentSidebarData,
	repo: {
		init: repository.respondentSidebar,
		save: repository.saveRespondentSidebar
	}
});
