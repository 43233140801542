var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("filter-" + _vm.config.type, {
    tag: "component",
    attrs: {
      "filter-id": _vm.filterId,
      loading: _vm.loading,
      items: _vm.items,
      label: _vm.config.label,
      type: _vm.config.type
    },
    model: {
      value: _vm.model,
      callback: function($$v) {
        _vm.model = $$v
      },
      expression: "model"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }