/**
 * Returns the tile wrapper DOMnode.
 *
 * @function TileView.box
 * @returns {Object} - DOMnode.
 * @deprecated
 * @example
 * import $ from 'jquery';
 *
 * export default TileView.extend({
 *     loaded: ({ tile }) => {
 *         $(tile.box()).addClass('tile-container');
 *     }
 * });
 */
export default function () {
	const parentNode = this.el.parentNode;
	return parentNode ? parentNode.parentNode : false;
}
