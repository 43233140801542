import SingleEntity from '../shared/single-entity';
import urls from './urls';

/**
 * LinkCategory
 * @class LinkCategory
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	eventNamespace: 'link-category',
	urlRoot: urls.singleCategory.url
});
