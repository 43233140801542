import acl from 'acl';
import { every, isArray, isFunction } from 'lodash';
import store from 'store';

const check = function (_checkpoints)  {
	// There is no ACL system outside clinician portal
	if (store.state.user.type !== 'clinician') {
		return true;
	}

	let access = true;
	let checkpoints = [];

	if (isFunction(_checkpoints)) {
		const result = _checkpoints();

		if (result instanceof Array) {
			checkpoints = result;

		} else {
			return result;
		}

	} else {
		checkpoints = _checkpoints;
	}

	if (isArray(checkpoints)) {

		every(checkpoints, (checkpoint) => {

			if (!acl.checkAccess(checkpoint)) {
				access = false;
				return false;
			}

			return true;
		}, this);

	} else if (typeof checkpoints === 'object') {
		switch (checkpoints.op) {

		case 'or':
			var ok = false;

			every(checkpoints.checkpoints, (checkpoint) => {
				if (acl.checkAccess(checkpoint)) {
					ok = true;
					return false;
				}
				return true;
			});

			if (!ok) {
				access = false;
				return false;
			}

			return true;
		}
	}

	return access;
};

export default check;

