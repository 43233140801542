import t from 'translate';

export const NOT_SUBMITTED = 'NOT_SUBMITTED';
export const GIVEN = 'GIVEN';
export const PARTIALLY_GIVEN = 'PARTIALLY_GIVEN';
export const NOT_GIVEN = 'NOT_GIVEN';

export const labels = () => ({
	[NOT_SUBMITTED]: t('consents.statuses.NOT_SUBMITTED'),
	[GIVEN]: t('consents.statuses.GIVEN'),
	[PARTIALLY_GIVEN]: t('consents.statuses.PARTIALLY_GIVEN'),
	[NOT_GIVEN]: t('consents.statuses.NOT_GIVEN')
});
