import t from 'translate';
import TileView from 'tile-view';
import repository from 'repository';
import DiaryEntryList from '../../views/list';
import DiaryEntryAdd from '../../views/add';
import viewCollection from '../../shared/view-collection';
import cardUrl from 'cwcardurl';
import treatmentContextParam from 'modules/treatments/util/treatment-context-param';

export default TileView.extend({
	title: t('My personal diary'),
	features: ['RESPONDENT_DIARY'],

	tileData: () => ({ entries: repository.getDiaryEntries() }),

	loaded: ({ tile }) => {
		tile.renderAddView(tile);
		tile.renderListView(tile);
	},

	renderAddView: (tile) => {
		tile.addView = new DiaryEntryAdd();
		tile.$el.append(tile.addView.render().$el);
	},

	renderListView: (tile) => {
		tile.listView = new DiaryEntryList({
			collection: viewCollection(tile.entries, { limit: tile.config().itemsCount })
		});

		tile.$el.append(tile.listView.render().$el);

		// link to diary card
		tile.$el.append(
			`<a href="${
				cardUrl.buildUrl('diary', treatmentContextParam())
			}" class="diary-brief__see-all">
				${t('See all items')} &raquo;
			</a>`
		);
	},

	onRemove: ({ tile }) => {
		tile.addView && tile.addView.remove();
		tile.listView && tile.listView.remove();
	}
});
