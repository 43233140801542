import { forEach } from 'lodash';

const parseUrl = function (url) {
	let parsed = {};
	(`${url}`).replace(/^\s*(((?:[^:\s]*:\/\/)?[^\/\s]*)(\/[^?\s]*)?)(\?(\S*))?\s*$/, function () {
		parsed = {
			hostUrl: arguments[2],
			path: arguments[3],
			query: arguments[5]
		};
	});

	if (!parsed.path) {
		parsed.path = '/';
	}

	if (parsed.query) {
		const queryParams = {};
		forEach(parsed.query.split('&'), (param) => {
			const p = param.split('=');
			queryParams[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
		});

		parsed.queryParams = queryParams;
	}

	return parsed;
};

export default parseUrl;
