import t from 'translate';
import { constant, isUndefined, reduce, get, template } from 'lodash';

export const mt = ({ tileTranslate, text, opts }) => {
	if (!text) {
		return null;
	}

	const { override } = opts;
	const should = !isUndefined(override) ? override : tileTranslate;

	return should ? t(text) : text;
};

const functions = {
	BETWEEN: (value, args) => value > args[0] && value <= args[1],

	GREATER_THAN: (value, args) => value > args[0]
};

const parseFunction = ({ $function, content }) => {
	const fn = functions[$function.name] || constant(false);
	return fn(content, $function.args) && $function.result;
};

export const parse = ({ column, content, item, field = 'parse' }) => column[field] ?
	reduce(column[field], (acc, parseDefinition) => {
		const result = parseDefinition[`${content}`];

		if (!isUndefined(result)) {
			return template(result)(item);

		} else if (parseDefinition.$function) {
			return parseFunction({ $function: parseDefinition.$function, content });

		} else if (parseDefinition.$default) {
			return template(parseDefinition.$default)(item);
		}

		return acc;
	}, '') :
	get(content, column.value, content);

export const mtParse = ({ column, content, tileTranslate, item }) => {
	// If `parse` rules are defined, try to translate provided parse options.
	if (column.parse) {
		return mt({
			tileTranslate,
			text: parse({ column, content, item }),
			opts: !isUndefined(column.translate) ? { override: !!column.translate } : {}
		});
	}

	// Don't translate content if no parse rules are defined
	return parse({ column, content });
};

export const isEmpty = ({ column, content }) =>
	column.parse ? !parse({ column, content }) : !content;
