import repository from 'repository';
import { when } from 'jquery';

export default {
	namespaced: true,

	state: {
		roles: {
			data: null,
			model: null
		},
		noRoles: {
			data: null,
			model: null
		}
	},

	getters: {
		roles: (state) => state.roles.model,
		noRoles: (state) => state.noRoles.model
	},

	mutations: {
		set: (state, { roles, noRoles }) => {
			state.roles.data = roles.toJSON();
			state.roles.model = roles;
			state.noRoles.data = noRoles.toJSON();
			state.noRoles.model = noRoles;
		}
	},

	actions: {
		init: ({ commit }, { treatmentId }) => {
			const roles = repository.getTreatmentAssignedRoles(treatmentId);
			const noRoles = repository.getTreatmentUnassignedRoles(treatmentId);

			return when(roles, noRoles).then(() => {
				commit('set', { roles, noRoles });
			});
		}
	}
}
