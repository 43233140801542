import { Deferred } from 'jquery';
import { assign, defer, filter, findIndex, forEach, get, keyBy, omit } from 'lodash';
import repository from 'repository';

export default {
	namespaced: true,

	state: {
		actionsCount: 0,
		requests: [],
		values: [],
		dfd: Deferred()
	},

	getters: {
		requestCount: (state) => state.requests.length,
		requests: (state) => filter(state.requests, 'ref'),
		valuesByRef: (state) => keyBy(state.values, 'ref')
	},

	mutations: {
		addAction: (state) => {
			state.actionsCount++;
		},

		removeAction: (state) => {
			state.actionsCount--;
		},

		addRequest: (state, request) => {
			state.requests.push(omit(request, ['link', 'reportCode']));
		},

		setValue: (state, { ref, payload }) => {
			const index = findIndex(state.requests, { ref });
			state.values.push(assign(state.requests[index], payload));
		},

		reset: (state) => {
			state.requests = [];
			state.dfd = Deferred();
			state.actionsCount = 0;
		}
	},

	actions: {
		getValues: ({ getters, rootGetters, state, commit }, config) => {
			const scope = (dataSource) => ({
				treatment: {
					treatmentId: rootGetters['treatment/treatmentId']
				},
				respondent: {
					respondentId: rootGetters['respondent/respondentId']
				},
				role: {
					role:
						dataSource.roleId ||
						get(rootGetters['treatment/mainRespondentRole'], 'treatmentTypeRole.id'),
					treatmentId: rootGetters['treatment/treatmentId']
				},
				treatmentType: {
					treatmentTypeId:
						dataSource.treatmentTypeId ||
						get(rootGetters.treatment, 'treatmentType.id')
				}
			})[dataSource.scope];

			const include = (source) => ({
				include: config.link === 'report' && source.reportCode ?
					'assessmentInstance,assessmentInstance.report' :
					'assessmentInstance'
			});

			const request = (source) => assign({}, source, scope(source), include(source), {
				'assessmentInstance.reportCode': source.reportCode
			});

			forEach(config.dataSources, (dataSource) => {
				commit('addRequest', request(dataSource));
			});
			commit('addAction');

			defer(() => {
				commit('removeAction');

				if (state.actionsCount === 0) {
					repository.assessmentValues(getters.requests).then((responses) => {
						forEach(responses, (response) => {
							commit('setValue', {
								ref: response.ref,
								payload: response
							});
						});

						state.dfd.resolve();
						commit('reset');
					});
				}
			});

			return state.dfd;
		}
	}
};
