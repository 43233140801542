import SingleEntity from '../shared/single-entity';
import urls from './urls';

/**
 * Language Entity
 * @class Language
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	idAttribute: 'languageId',
	eventNamespace: 'language',
	urlRoot: urls.language.url
});

