import { assign, forEach, isUndefined } from 'lodash';
import { warn } from 'service/log/log';
import mapping from 'service/system-settings/mapping';
import { Events } from 'backbone';

const SystemSettings = function () {
	const getSetting = (key) => {
		const settingName = mapping[key];

		if (isUndefined(settingName)) {
			warn(`Wrong system setting flag passed: '${key}'`);
			return;
		}
		const settingValue = this.SETTINGS[settingName];

		if (isUndefined(settingValue)) {
			warn(`Wrong system setting value with correct flag passed: '${key}' which resolved to '${settingValue}'`);
		}

		return settingValue;
	};

	assign(this, {
		SETTINGS: {},

		reload: (settings) => {
			forEach(settings, (setting) => {
				this.SETTINGS[setting.systemSettingLabel] = setting.systemSettingValue;
			});
			this.trigger('reload');
		},

		getBoolean: (key) => {
			const value = getSetting(key);
			return (value === true) || (value === 'true') || (value === 1);
		},

		getString: (key) => getSetting(key) ? String(getSetting(key)) : getSetting(key),
		getInteger: (key) => +getSetting(key),

		setValue: (key, value) => {
			this.SETTINGS[mapping[key]] = value;
		}
	}, Events);
};

export default new SystemSettings();
