import $ from 'jquery';
import t from 'translate';
import moment from 'moment';
import { DateTime } from 'luxon';
import { cond, constant, isNumber, replace, some, split, toLower, stubTrue } from 'lodash';
import { EMPTY_CHAR } from 'lib/chars';

const DATETIME_FORMAT = 'date.formats.dateTime';

const dateFormats = [
	DATETIME_FORMAT,
	'date.formats.activity',
	'date.formats.dateLongMonth',
	'date.formats.dateOnly',
	'date.formats.dateTimeLuxon',
	'date.formats.dateTimeSeconds',
	'date.formats.longDate',
	'date.formats.longDateTime',
	'date.formats.quizDateFormat'
];

const stringIsDate = (str) => some(
	dateFormats,
	(format) => DateTime.fromFormat(str, t(format)).isValid
);

// eslint-disable-next-line max-statements
export default ({
	$table,
	$tbody,
	sortOrder = [[0, 0]],
	numberSorter,
	textSorter,
	extractText,
	preventAriaLive = false
}) => {
	$.tablesorter.language = {
		sortAsc: t('Ascending sort applied, '),
		sortDesc: t('Descending sort applied, '),
		sortNone: t('No sort applied, '),
		sortDisabled: t('sorting is disabled'),
		nextAsc: t('activate to apply an ascending sort'),
		nextDesc: t('activate to apply a descending sort'),
		nextNone: t('activate to remove the sort')
	};

	const timestampOrNumber = (num) => {
		// Value of dates in 🐛nternet Expl😠rer can be treated differently i.e. be string...
		const _num = moment(num, t(DATETIME_FORMAT));
		return moment(_num).isValid() ? moment(_num).valueOf() : num;
	};

	const obtainDir = ({ direction }) => direction ? 1 : -1;

	const defaultNumberSorter = (a, b, direction) => {
		const dir = obtainDir({ direction });

		if (isNumber(a) && isNumber(b)) { // standard (default) scenario
			return (a - b) * dir;
		}

		const x = timestampOrNumber(a);
		const y = timestampOrNumber(b);

		if (!isNumber(x)) {
			return -1 * dir;

		} else if (!isNumber(y)) {
			return dir;
		}
		return (x - y) * dir;
	};

	const dateOrText = (txt) => {
		const _txt = moment(txt, t(DATETIME_FORMAT));
		return stringIsDate(txt) ? moment(_txt).valueOf() : txt;
	};

	const defaultTextSorter = (a, b) => {
		const x = dateOrText(a);
		const y = dateOrText(b);

		const determine = cond([
			[(x, y) => !isNumber(x) && isNumber(y), constant(-1)],
			[(x, y) => isNumber(x) || x > y, constant(1)],
			[(x, y) => x < y, constant(-1)],
			[stubTrue, constant(0)]
		]);

		return determine(x, y);
	};

	// this seems to disable auto-detection of dates and parsing them to wrong timestamps
	$.tablesorter.addParser({
		id: 'date',
		is: stringIsDate,
		format: (s) => s,
		parsed: false,
		type: 'numeric'
	});

	$table.tablesorter({
		dateFormat: toLower(
			split(t('date.formats.dateOnly', '.')).join('')
		),
		sortList: sortOrder,
		headerTemplate: '{content}{icon}',
		cssIcon: 'icon',
		cssIconAsc: 'icon--sort-asc',
		cssIconDesc: 'icon--sort-desc',
		cssIconNone: 'icon--sort',
		textExtraction: (node) => {
			const $el = $(node);
			// eslint-disable-next-line lodash/prefer-lodash-method
			const $checkbox = $el.find('input:checkbox');
			// eslint-disable-next-line lodash/prefer-lodash-method
			const $urlNode = $el.find('a');

			// Detect if dom node is url, then sort by innerHTML text instead of url link
			if ($urlNode.length) {
				return $urlNode[0].textContent;
			}

			// eslint-disable-next-line lodash/prefer-lodash-method
			const cellLabel = $el.find('.table-cell-label').text();

			// If checkbox exist - sort by its state.
			// Otherwise sort by node's innerText or innerHTML (this enables sorting by icons too)
			const innerTxt = node.innerText.length ? node.innerText : node.innerHTML;
			const content = extractText ?
				extractText({ content: innerTxt, $el }) :
				replace(innerTxt, EMPTY_CHAR, '');

			return $checkbox.length ?
				$checkbox.prop('checked') :
				replace(content, cellLabel, '');
		},
		numberSorter: numberSorter || defaultNumberSorter,
		textSorter: textSorter || defaultTextSorter
	});

	// tablesorter doesn't allow changing default role in a civilized way,
	// and default 'grid' role breaks a11y, see defect 12381
	$table.attr('role', 'table');

	if (preventAriaLive) {
		$tbody.attr('aria-live', 'off');
	}
};
