import { assign, cloneDeep, filter, get, mapKeys, reject, set, unset } from 'lodash';
import Vue from 'vue';

const propPath = ({ index, containerConfig, tileConfig }) => {
	const tileCfgPath = tileConfig ? 'tileConfig' : '';
	const path = containerConfig ? 'containerConfig' : tileCfgPath;
	return `[${index}]${path}`;
};

export default {
	namespaced: true,

	state: {
		currentList: [],
		currentTileIndex: -1
	},

	getters: {
		list: (state) => state.currentList,
		currentTile: (state) => state.currentList[state.currentTileIndex] || { containerConfig: {} }
	},

	mutations: {
		setCurrentList: (state, tiles) => {
			state.currentList = tiles;
		},

		setCurrentTile: (state, { tileName, tileIndex }) => {
			assign(state, {
				currentTileName: tileName,
				currentTileIndex: tileIndex
			});
		},

		createTile: (state, { tileName, title }) => {
			const similar = filter(state.currentList, { tileName }).length;
			const customTitle = similar ? `${title} #${similar + 1}` : title;

			state.currentList.push({ tileName, customTitle });
		},

		moveTile: (state, { tileIndex, amount = 1 }) => {
			const tiles = state.currentList;

			tiles.splice(tileIndex + amount, 0, tiles.splice(tileIndex, 1)[0]);
		},

		setTileConfig: (state, { config, create }) => {
			if (create) {
				state.currentList.push(config);

			} else {
				Vue.set(state.currentList, state.currentTileIndex, config);
			}
		},

		removeTile: (state, index) => {
			state.currentList = reject(state.currentList, (tile, i) => i === index);
		},

		setTileProp: (state, {
			path, value, containerConfig = false, tileConfig = false, remove = false
		}) => {
			const rootPath = propPath({
				index: state.currentTileIndex, containerConfig, tileConfig
			});
			const tiles = cloneDeep(state.currentList);

			if (remove) {
				unset(tiles, `${rootPath}.${path}`);

			} else {
				set(tiles, `${rootPath}.${path}`, value);
			}
			state.currentList = tiles;
		},

		renameTileKey: (state, { oldKey, newKey, containerConfig = false, tileConfig = false }) => {
			const path = propPath({ index: state.currentTileIndex, containerConfig, tileConfig });
			const tiles = cloneDeep(state.currentList);

			const props = mapKeys(
				get(tiles, path),
				(val, key) => key === oldKey ? newKey : key
			);

			set(tiles, path, props);
			state.currentList = tiles;
		}
	},

	actions: {}
};
