import t from 'translate';
import Vue from 'vue';
import TileView from 'tile-view';
import store from 'store';
import { mapState } from 'vuex';
import avatarNickname from '../../components/avatar-nickname';
import enableCirclesCheck from '../../helpers/enable-circles-check';

export default TileView.extend({
	title: t('My circle identity'),
	acl: [{
		op: 'read',
		checkpoint: 'administrator.circles.circles'
	}],
	features: enableCirclesCheck,
	instant: true,

	loaded: ({ tile }) => {
		const titles = {
			nickname: t('Circle nickname'),
			avatar: t('Avatar colour'),
			avatarSymbol: t('Avatar symbol')
		};

		tile.vue = new Vue({
			el: document.createElement('div'),
			store,
			components: { ...avatarNickname },
			data: { titles },
			computed: mapState('circles', ['profile']),
			methods: {
				saveColor (e) {
					this.$store.dispatch('circles/profile/save', {
						profile: {
							avatarColour: e.target.value
						}
					});
				},
				saveNickname (e) {
					this.$store.dispatch('circles/profile/save', {
						profile: {
							nickname: e.target.value
						}
					});
				},
				saveAvatar (avatar) {
					this.$store.dispatch('circles/profile/save', {
						profile: {
							avatarName: avatar.name
						}
					});
				}
			},

			template: `
				<section class="circles-profile">
					<div
						class="form-view__loader circles-profile__loader"
						v-if="profile.loading"
						v-loader-spinner
					></div>
					<avatar-nickname :user="profile" />
					<div class="form-view__field-container form-view__field-container--mandatory">
						<label class="form-view__label-container">
							<p class="form-view__label-text">{{ titles.nickname }}</p>
							<input
								type="text"
								class="form-view__input"
								:value="profile.nickname"
								@blur="saveNickname"
							/>
						</label>
					</div>

					<div class="form-view__field-container form-view__field-container--mandatory">
						<label class="form-view__label-container">
							<p class="form-view__label-text">{{ titles.avatar }}</p>
							<div class="form-view__input">
								<input
									class="circles-profile-edit__colour-input"
									type="color"
									:value="profile.avatarColour"
									@input="saveColor"
								/>
							</div>
						</label>
					</div>

					<div class="form-view__field-container form-view__field-container--mandatory">
						<p class="form-view__label-text">{{ titles.avatarSymbol }}</p>
						<ul>
							<li v-for="avatar in profile.avatarCodes"
								class="circles-profile-edit__item">
								<button
									class="circles-profile-edit__avatar"
									:class="{ 'circles-profile-edit__avatar--selected':
										avatar.name === profile.avatarName }"
									:title="avatar.name"
									@click="saveAvatar(avatar)"
								>
									<i class="avatar" :class="avatar.name"></i>
								</button>
							</li>
						</ul>
					</div>
				</section>

			`
		});
		tile.$el.html(tile.vue.$el);
	}
});
