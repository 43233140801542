import {
	assign, concat, forEach, isArray, isBoolean, isUndefined, map, noop, pick, trim
} from 'lodash';
import $ from 'jquery';

import TileView from 'tile-view';
import filter from 'components/filter/filter';
import CWtable from 'components/table/table';
import t from 'translate';
import store from 'store';
import cardUrl from 'cwcardurl';
import { router } from 'core/engine/card/services/card-builder';

import viewModel from 'service/search/view-model';
import createRunQuery from 'service/search/create-run-query';
import treatmentContextParam from 'modules/treatments/util/treatment-context-param';

export default TileView.extend({
	cardEvents: {
		'search.start' () {
			this.table && this.table.setDataLoading();
		},
		'search.end' () {
			this.table && this.table.setDataLoaded();
			this.setDataLoaded();
		}
	},
	instant: true,

	onInitialize: ({ tile }) => {
		tile.viewModel = viewModel();
	},

	results: {},
	searchFn: noop,
	tableCfg: noop,
	filterCfg: noop,
	fields: noop,
	onClear: noop,

	filterDefaults: (tile) => ({
		results: tile.viewModel.get('results'),

		onClear: () => {
			const fields = tile.keys.map((key) => ({ [key]: null }));

			tile.viewModel.set(assign({ search: '' }, ...fields));
			tile.onClear({ tile });
			tile.runQuery();
		},

		onApply: () => {
			tile.runQuery();
		},

		onRestore: () => {
			const fields = tile.fields({ tile }) || [];
			const { searchFields } = assign(
				{},
				tile.filterDefaults(tile),
				tile.filterCfg({ tile })
			);

			forEach(concat(fields, searchFields), (field) => {
				const fieldLabel = field.label;
				const fieldKey = field.key || 'search';
				const values = store.state.filter.values;
				const fieldValue = field.key ?
					map(values[fieldLabel], (val, key) => {
						if (val === true) {
							return key;

						} else if (!isBoolean(val)) {
							return val;
						}
					}) :
					tile.search;

				tile.viewModel.set(fieldKey, fieldValue.toString());
			});
		},

		searchFields: [{
			type: 'search',
			label: t('Find'),
			startValue: tile.search,
			onChange: (search) => {
				tile.viewModel.set({ search });
				tile.runQuery();
			}
		}]
	}),

	tableDefaults: (tile) => ({
		parent: tile.$tableContainer[0],
		collection: tile.viewModel.get('results'),
		emptyListMessage: t('No results')
	}),

	init: ({ tile }) => {
		const cardData = tile.cardContext().toJSON();
		const searchQuery = window.unescape(store.getters['searchQuery'] || cardData.search || '');

		tile.cardContext().set({
			target: cardData.target || 'respondent-status',
			search: trim(searchQuery)
		});

		router.navigate(cardUrl.buildUrl(cardData.cardName, {
			search: window.escape(trim(searchQuery)),
			...treatmentContextParam()
		}));

		// reflect query change in url (silently)
		const listen = () => {
			tile.cardContext().listenToOnce(
				tile.cardContext(),
				'change:search',
				(cardContext, searchQuery) => {
					store.dispatch('setSearchQuery', {
						query: window.unescape(searchQuery) || ' '
					});

					router.navigate(cardUrl.buildUrl(cardData.cardName, {
						search: window.escape(trim(searchQuery)),
						...treatmentContextParam()
					}));
					listen();
				}
			);
		};

		listen();

		assign(tile, pick(tile.cardContext().toJSON(), 'hideResults', 'limit', 'search'));

		tile
			._setKeys(tile)
			._setupViewModel(tile)
			._bindEvents(tile)
			._setData(tile)
			._setRunQuery(tile);
	},

	_setKeys: (tile) => {
		const fields = isArray(tile.fields({ tile })) ? tile.fields({ tile }) : [];
		const searchFields = assign(
			{},
			tile.filterDefaults(tile),
			tile.filterCfg({ tile })
		).searchFields;

		return assign(tile, {
			keys: fields.map((field) => field.key).concat(searchFields.map((field) => field.key))
		});
	},

	_setupViewModel: (tile) => {
		// searchField keys in form of { searchField: null, anotherSearchField: null }
		const additionalParams = map(tile.keys, (key) => ({ [key]: null }));
		const viewModelFields = assign({
			search: tile.search || '',
			limit: tile.limit
		}, ...additionalParams);

		tile.viewModel.set(viewModelFields);

		return assign(tile, { results: tile.viewModel.get('results') });
	},

	_bindEvents: (tile) => {
		forEach(tile.cardEvents, (fn, event) => {
			tile.listenTo(tile.cardContext(), event, fn);
		});

		// events in form of 'change:search change:searchField change:anotherSearchField'
		const events = tile.keys.reduce((memo, key) => `${memo} change:${key}`, 'change:search');

		// reset start when search criteria changes
		tile.listenTo(tile.viewModel, events, () => {
			tile.viewModel.set('start', 0);
		});

		tile.listenTo(tile.viewModel, 'change:results', () => {
			tile.renderResults(tile);
		});

		return tile;
	},

	_setData: (tile) => {
		// searchField keys in form of { searchField: this.cardContext().get('searchField') } }
		const searchFieldsData = tile.keys.map((key) => {
			if (isUndefined(key) || !isUndefined(tile[key])) {
				return;
			}
			const param = { [key]: tile.cardContext().get(key) };
			return param;
		});

		return assign(tile, ...searchFieldsData);
	},

	_setRunQuery: (tile) => {
		tile.runQuery = createRunQuery({
			cardContext: tile.cardContext(),
			viewModel: tile.viewModel,
			params: tile.keys,
			searchFn: tile.searchFn.bind(tile)
		});
	},

	loaded: ({ tile }) => {

		if (!tile.table) {
			tile.setDataLoading();
		}

		if (tile.hideResults) {
			tile.listenToOnce(tile.viewModel, 'change:results', () => {
				tile.hideResults = false;
				tile.enable();
				tile.init({ tile });
			});
			tile.setDataLoaded();
		}

		tile.hideResults || tile.runQuery();
		tile.listenTo(tile.viewModel, 'change:start', () => {
			tile.runQuery();
		});

		tile.filter = filter(assign(
			{ id: tile.title() },
			tile.filterDefaults(tile),
			{ filters: tile.fields({ tile }) },
			tile.filterCfg({ tile })
		));
		tile.$el.append(tile.filter.$el);
	},

	renderResults: (tile) => {
		tile.$tableContainer = tile.$('.search-results-list__container');

		if (!tile.$tableContainer.length) {
			tile.$tableContainer = $('<section />')
				.addClass('search-results-list__container')
				.appendTo(tile.$el);
		}
		tile.$tableContainer.empty();

		const tableCfg = assign(tile.tableDefaults(tile), tile.tableCfg({ tile }));

		// THERE CAN BE ONLY ONE
		if (tableCfg.store) {
			delete tableCfg.collection;
		}

		if (tile.viewModel.has('limit')) {
			tableCfg.more = () => {
				tile.viewModel.set(
					'start',
					tile.viewModel.get('start') + tile.viewModel.get('limit')
				);
			};
		}

		tile.table = new CWtable(tableCfg);

		if (tile.viewModel.get('results').size() >= tile.viewModel.get('results').total) {
			tile.table.hideMoreButton();
		} else {
			tile.table.showMoreButton();
		}
	}
});
