<template>
	<section>
		<h3 class="circle-post__title">
			<avatar-nickname :user="post.circleUser" />
		</h3>
		<div
			v-if="loading"
			v-loader-spinner
			class="circle-post__loader"
		/>
		<time
			:datetime="post.createdAt"
			:title="datetime"
			class="circle-post__datetime"
		>{{fromNow}}</time>

		<p
			v-if="post.reportAbusesCount"
			class="circle-post__reported"
		>
			<icon name="ban" />{{t('Post reported')}}
		</p>

		<div class="circle-post__menu">
			<button
				:title="t('Post options')"
				class="circle-post__menu-trigger"
				@click="menuToggle"
			><icon name="more-actions" /></button>

			<ul v-if="menuToggled" class="circle-post__menu-items">
				<li v-if="canEdit" class="circle-post__menu-item">
					<button
						class="circle-post__menu-action"
						@click="edit"
					>
						<icon name="pencil" :small="true" />
						<span class="circle-post__menu-content">{{t('Edit post')}}</span>
					</button>
				</li>
				<li v-if="canEdit" class="circle-post__menu-item">
					<button
						class="circle-post__menu-action"
						@click="remove"
					>
						<icon name="delete" :small="true" />
						<span class="circle-post__menu-content">{{t('Delete post')}}</span>
					</button>
				</li>
				<li class="circle-post__menu-item">
					<button
						class="circle-post__menu-action"
						@click="reportAbuse"
					>
						<icon name="ban" :small="true" />
						<span class="circle-post__menu-content">{{t('Report abuse')}}</span>
					</button>
				</li>
			</ul>
		</div>
		<div class="circle-post__content-container">
			<p
				v-show="!beingEdited"
				class="circle-post__content-view"
				v-html="escapedContent"
			/>
			<div v-show="beingEdited" class="circle-post__content-edit-container">
				<div class="form-view__field-container">
					<textarea
						ref="textarea"
						v-model="post.content"
						class="form-view__input form-view__textarea circle-post__content-edit"
					/>
				</div>
				<div class="form-view__field-container">
					<button
						class="form-view__button form-view__button--submit"
						@click="save"
					>{{t('Save')}}</button>
					<button
						class="form-view__button"
						@click="cancelEditing"
					>{{t('Cancel')}}</button>
				</div>

			</div>
		</div>
	</section>
</template>

<script>
import autosize from 'autosize';
import datetime from 'datetime';
import t from 'translate';
import store from 'store';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import avatarNickname from '../../components/avatar-nickname';
import escape from '../../helpers/escape-content';
import { assign, isBoolean } from 'lodash';

export default {
	store,
	components: { ...avatarNickname },
	props: {
		circleId: {
			type: Number,
			required: true
		},
		post: {
			type: Object,
			required: true
		},
		postMenuVisible: {
			type: Object,
			required: true
		},
		profile: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		beingEdited: false,
		contentCache: ''
	}),

	computed: {
		loading: ({ $store, post }) =>
			$store.state.circles.currentCircle.posts.loading.post === post.id,

		menuToggled: ({ post, postMenuVisible }) => postMenuVisible[post.id],

		datetime: ({ $store, post }) => datetime(post.createdAt)
			.setTimezone($store.getters.userTimezone)
			.toMoment()
			.format(t('date.formats.dateTime')),

		fromNow: ({ $store, post }) => datetime(post.createdAt)
			.useTimezone($store.state.user.timezoneName).setTimezone($store.state.user.timezoneName)
			.toMoment().fromNow(),

		escapedContent: ({ post }) => escape(post.content),

		canEdit: ({ post, profile }) => +profile.id === +post.circleUser.id
	},

	mounted () {
		autosize(this.$refs.textarea);
	},

	methods: {
		t,
		menuToggle (bool) {
			const visible = isBoolean(bool) ? bool : !this.postMenuVisible[this.post.id];
			this.$emit('toggle-menu', visible);
		},

		hideMenu () {
			this.menuToggle(false);
		},

		edit () {
			this.contentCache = this.post.content;
			this.hideMenu();
			this.beingEdited = true;
		},

		cancelEditing () {
			this.$store.dispatch('circles/currentCircle/posts/setPost', {
				post: assign({}, this.post, { content: this.contentCache })
			});
			this.beingEdited = false;
		},

		remove () {
			confirmation({
				icon: 'delete',
				warning: true,
				title: t('Delete post'),
				message: t('Are you sure you want to delete this post?')
			}).then(() => this.$store.dispatch('circles/currentCircle/posts/remove', {
				post: this.post,
				circleId: this.circleId
			})).then(() => {
				cwalert.success(t('Post has been deleted'));
			});
			this.hideMenu();
		},

		reportAbuse () {
			confirmation({
				icon: 'abuse-report',
				warning: true,
				title: t('Report abuse'),
				message: t('Are you sure you want to report abuse on this post?')
			}).then(() => this.$store.dispatch('circles/currentCircle/posts/reportAbuse', {
				post: this.post,
				reportedBy: this.$store.getters['circles/profile/profileData']
			})).then(() => {
				cwalert.success(t('Abuse has been reported'));
			});
			this.hideMenu();
		},

		save () {
			this.$store.dispatch('circles/currentCircle/posts/save', {
				post: this.post
			});
			this.beingEdited = false;
		}
	}
};
</script>
