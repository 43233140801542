import { forEach, inRange, isArray, isUndefined } from 'lodash';

// value height proportion formula:
// height - chart range
// ?????? - value range
const getValueHeight = ({ height, range, value }) => (height / range) * value;

const projectY = ({ chartRect, bounds, thresholdValue }, val) =>
	chartRect.y1 - getValueHeight({
		height: chartRect.height(),
		range: bounds.range,
		value: isUndefined(val) ? (thresholdValue - bounds.min) : (val - bounds.min)
	});

const renderThresholdLine = ({ chartRect, bounds, options, svg }, thresholdLineColors = []) => {
	const { value: thresholdValue, class: className } = options.thresholdLine;
	const renderThreshold = (thresholdValue, thresholdColor) => {
		const thresholdLine = projectY({ chartRect, bounds, thresholdValue });

		svg.elem('line', {
			x1: chartRect.x1,
			y1: thresholdLine,
			x2: chartRect.x2,
			y2: thresholdLine,
			style: `stroke: ${thresholdColor}`
		}, className);
	};

	if (isArray(thresholdValue)) {
		forEach(thresholdValue, (value, i) => {
			if (inRange(value, bounds.min)) {
				return;
			}

			renderThreshold(value, thresholdLineColors[i]);
		});
	} else {
		if (inRange(thresholdValue, bounds.min)) {
			return;
		}

		renderThreshold(thresholdValue, thresholdLineColors[0]);
	}
};

const renderThresholdRange = ({ chartRect, bounds, options, svg }, thresholdRangeColors = []) => {
	const { value: thresholdValue, class: className } = options.thresholdRange;
	const [min, max] = thresholdValue;

	if (inRange(max, bounds.min)) {
		return;
	}

	const posY = projectY({ chartRect, bounds, thresholdValue }, max);
	const posY2 = projectY({ chartRect, bounds, thresholdValue }, min);

	const height = Math.abs(posY - posY2) - (min < bounds.min ?
		getValueHeight({
			height: chartRect.height(),
			range: bounds.range,
			value: bounds.min - min
		}) : 0);

	svg.elem('rect', {
		x: chartRect.x1,
		y: posY,
		width: chartRect.x2 - chartRect.x1,
		height,
		style: `fill: ${thresholdRangeColors[0]}`
	}, className);
};

export {
	renderThresholdLine,
	renderThresholdRange
};
