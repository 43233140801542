<template>
	<time :datetime="isoString">
		{{content}}
	</time>
</template>

<script>
import datetime from 'service/datetime/datetime';
import t from 'service/lang/translate';
import Vue from 'vue';

export default Vue.component('TimeContent', {
	props: {
		time: {
			type: [Object, String, Array],
			default: ''
		},
		timezone: {
			type: String,
			default: 'UTC'
		},
		format: {
			type: String,
			default: t('date.formats.dateTime')
		}
	},

	computed: {
		wrapper: ({ time }) => datetime(time),
		isoString: ({ wrapper }) => wrapper.useTimezone('UTC').toMoment().toISOString(),

		content: (
			{ format, timezone, wrapper }
		) => wrapper.setTimezone(timezone).toMoment().format(format)
	}
});
</script>
