import { assign, forEach, isArray, isObject, isString, reduce, template } from 'lodash';
import store from 'store';
import config from 'core/config';
import { SEARCH } from 'store/general/search';
import { RAW_ENTITIES } from 'store/general/raw-entities';

const filterParams = (filters) => isArray(filters) ?
	reduce(
		filters,
		(acc, filter) => assign(acc, { [filter.name]: '' }),
		{}
	) :
	filters;

export const searchParams = ({ id, filters }) => (store.getters[SEARCH.DATA][id] || {
	start: 0,
	limit: 10,
	search: '',
	...filterParams(filters)
});

/*
	Transform array (of objects) to string.
	In: `{ foo: [{elo: 1, bar: 2}, { elo: 3, siema: 4}] }`
	Out: foo[0][elo]=1&foo[0][bar]=2&foo[1][elo]=3&foo[1][siema]=4

	This is currently used for a specific "current clinician treatments" feed only and has some
	fallbacks, "just in case".
 */
export const parseUrlParams = (urlParams) => {
	const parsed = {};

	forEach(urlParams, (params, name) => {
		if (!isArray(params)) {
			return params;
		}

		parsed[name] = reduce(params, (acc, param, i) => {
			if (isObject(param)) {
				acc.push(reduce(
					param,
					(memo, val, key) => {
						memo.push(`${name}[${i}][${key}]=${val}`);
						return memo;
					},
					[]
				).join('&'));

			} else {
				acc.push(`${name}[${i}]=${param}`);
			}

			return acc;
		}, []).join('&');

		return true;
	});

	return parsed;
};

export const saveIdKeyAction = ({ id, idKey }) => [RAW_ENTITIES.SAVE_ID_KEY, { id, idKey }];

export const fetchAction = ({ dataUrl, id, add = false, override = {}, filters = [] }) => [
	RAW_ENTITIES.FETCH, {
		id,
		url: template(dataUrl)(assign(
			store.getters.urlParams,
			{
				root: config().backendRootUrl.default,
				userId: store.getters.userId
			},
			searchParams({ id, filters }),
			override
		)),
		add
	}
];

export const updateAction = ({ dataUrl, method = 'PUT', id, entityId, payload, override = {} }) => [
	RAW_ENTITIES.UPDATE_ONE, {
		id,
		entityId,
		url: template(dataUrl)(assign(
			store.getters.urlParams,
			{
				root: config().backendRootUrl.default,
				userId: store.getters.userId,
				entityId
			},
			override
		)),
		method,
		payload
	}
];

export const clearAction = ({ id }) => [
	RAW_ENTITIES.CLEAR, { id }
];

const parseStr = ({ str, item }) => template(str)(item);

const parseObj = ({ obj, item }) => reduce(obj, (acc, str, key) => assign(
	{ ...acc },
	{ [key]: parseStr({ str, item }) }
), {});

export const enrichItem = ({ item, additionalData }) => reduce(
	additionalData,
	(acc, definition, key) => {
		let transformed;

		if (isString(definition)) {
			transformed = parseStr({ str: definition, item });
		}

		if (isObject(definition)) {
			transformed = parseObj({ obj: definition, item });
		}

		return assign(
			{ ...acc },
			{ [key]: transformed }
		);
	},
	item
);
