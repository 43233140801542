import t from 'service/lang/translate';

/**
 * @param {string} phrase     - Phrase that may be translated.
 * @param {boolean} translate - Should the phrase be translated.
 * @returns {string}          - Maybe translated phrase.
 * @example
 *        transcribe('agenda.label.assessment', true);
 *        -> 'Assessment'
 */
export const transcribe = (phrase, translate) => translate ? t(phrase) : phrase;
