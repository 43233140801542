import assessmentList from './assessment-list/tile';
import miniAssessmentValue from './mini-assessment-value/index';
import barChart from './assessment-value-bar-chart/index';
import miniPlotChart from './mini-assessment-value-plot-chart/index';
import pieChart from './assessment-value-pie-chart/index';
import plotChart from './assessment-value-plot-chart/index';

export default  {
	'assessment-list': assessmentList,
	'mini-assessment-value': miniAssessmentValue,
	'assessment-value-bar-chart': barChart,
	'assessment-value-pie-chart': pieChart,
	'assessment-value-plot-chart': plotChart,
	'mini-assessment-value-plot-chart': miniPlotChart
};
