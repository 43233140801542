import appLayout from 'core/views/app-layout-view';

export default function (bool) {
	const $el = appLayout.getRegion('appBar').$el;
	const fn = {
		true: 'addClass',
		false: 'removeClass'
	}[bool];
	$el[fn]('loading');

	return this;
}
