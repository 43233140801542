import MultipleEntities from '../shared/multiple-entities';
import Clinician from './clinician';
import urls from './urls';
import { assign } from 'lodash';

const RespondentCliniciansEntity = MultipleEntities.extend({
	model: Clinician,
	urls,
	eventNamespace: 'respondent-clinician',
	idName: 'relationId',

	canAdd (model) {
		return +model.get('respondentId') === +this.ownerId;
	},

	// object returned by backend has got a nested `administrator` property
	// which should not be nested ;)
	parse: (clinicians) => clinicians.map((clinician) => {
		assign(clinician, clinician.administrator);
		delete clinician.administrator;
		return clinician;
	}),

	getByRespondentId (respondentid) {
		return this.retrieve('listClinicians', respondentid);
	}

});

export default new RespondentCliniciansEntity();
