<template>
	<h2 :class="mainClassName">
		<button
			v-if="medium"
			:class="buttonClassName('medium')"
			@click="changeScope({ newScope: medium.newScope })"
		>{{medium.text}}</button>
		<button
			v-if="small"
			:class="buttonClassName('small')"
			@click="changeScope({ newScope: small.newScope })"
		>{{small.text}}</button>
		<button
			v-if="large"
			:class="buttonClassName('large')"
			@click="changeScope({ newScope: large.newScope })"
		>{{large.text}}</button>
	</h2>
</template>

<script>
import Vue from 'vue';
import { concat, get } from 'lodash';
import { requiredObject, requiredString } from './props';
import agendaMixin from './agenda-mixin';
import storeMixin from 'components/__mixins/store-mixin';
import { FORMATS } from './helpers';
import t from 'service/lang/translate';
import { AGENDA_GROUP_TITLE } from './constants';
import { ACTIONS as A } from 'store/components/agenda-view';

export default Vue.component(AGENDA_GROUP_TITLE, {
	mixins: [agendaMixin, storeMixin],
	props: {
		scope: requiredString,
		datetime: requiredObject,
		storePath: requiredString
	},
	computed: {
		large: ({ datetime, scope }) => ({
			year: {
				text: datetime.toFormat('MMM'),
				newScope: 'month'
			},
			month: null,
			week: null,
			day: null
		}[scope]),

		medium: ({ datetime, scope }) => ({
			year: null,
			month: datetime.weekday === 1 || datetime.day === 1 ?
				{
					text: `${t('agenda.unit.week')} ${datetime.weekNumber}`,
					newScope: 'week'
				} :
				null,
			week: null,
			day: null
		}[scope]),

		small: ({ datetime, scope }) => ({
			year: null,
			month: {
				text: datetime.toFormat(FORMATS.shortDayName()),
				newScope: 'day'
			},
			week: {
				text: datetime.toFormat(FORMATS.shortDayName()),
				newScope: 'day'
			},
			day: {
				text: datetime.toFormat('HH:mm'),
				newScope: false
			}
		}[scope]),

		holiday: ({ datetime, scope }) => (scope === 'month' || scope === 'week') &&
			datetime.weekday > 5,

		mainClassName: ({ $options, holiday }) => {
			const classes = [$options.name];

			return holiday ? concat(classes, `${$options.name}--holiday`) : classes;
		}
	},

	methods: {
		buttonClassName (type) {
			return {
				[this.elementClassName(`${type}-title`)]: true,
				[`${this.$options.name}--clickable`]: get(this[type], 'newScope')
			};
		},
		changeScope ({ newScope = '' }) {
			const scope = newScope || this.scope;
			document.activeElement.blur(); // loose focus on activated button

			this.dispatch(A.SET_SCOPE, scope);
			this.dispatch(A.SET_DATETIME, this.datetime);
		}
	}
});
</script>
