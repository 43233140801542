import CirclesView from './circles-list/tile';
import CirclesInfoBox from './circles-info';
import CircleCreate from './circle-create/tile';
import CircleJoin from './circle-join/tile';
import Circle from './circle/tile';
import CircleEdit from './circle-edit/tile';
import CircleMembers from './circle-members/index';
import MiniCirclesShortcut from './mini-circles-shortcut/tile';
import CirclesProfile from './circles-profile/tile';
import CircleJoinRequests from './circle-join-requests/tile';
import CirclesBanned from './circles-banned/tile';

export default {
	'circles-list': CirclesView,
	'circles-info': CirclesInfoBox,
	'circle-create': CircleCreate,
	'circle-join': CircleJoin,
	'circle': Circle,
	'circle-edit': CircleEdit,
	'circle-members': CircleMembers,
	'mini-circles-shortcut': MiniCirclesShortcut,
	'circles-profile': CirclesProfile,
	'circle-join-requests': CircleJoinRequests,
	'circles-banned': CirclesBanned
};
