import TileView from 'tile-view';
import formView from 'components/form-view/form-view';
import t from 'translate';
import settings from 'system-settings';
import can from 'acl-can';
import store from 'store';

export default TileView.extend({
	tagName: 'section',
	className: 'my-profile__container',
	userType: 'clinician',
	title: t('clinician.my-profile.title'),
	acl: [{
		op: 'read',
		checkpoint: 'system.administratorpreferences.preferences'
	}],

	cardData: () => ['model', 'userType'],

	loaded: ({ tile }) => {
		let restartOnSubmit = false;
		let readonly = false;
		let cantChangeLanguage = false;
		let cantChangeTimezone = false;

		if (tile.userType === 'administrator') {
			readonly = !can.edit('system.administratorpreferences.preferences');
			cantChangeLanguage = readonly || !settings.getBoolean('ALLOW_ADMIN_LANGUAGE_SWITCH');
			cantChangeTimezone = readonly || !settings.getBoolean('ALLOW_ADMIN_TIMEZONE_SWITCH');

		} else if (tile.userType === 'respondent') {
			cantChangeLanguage = !settings.getBoolean('ALLOW_RESPONDENT_LANGUAGE_SWITCH');
			cantChangeTimezone = !settings.getBoolean('ALLOW_RESPONDENT_TIMEZONE_SWITCH');
			tile.model.set('respondentPassword', null);
			tile.model.set('attributes', {});
		}

		tile.model.on(`change:${tile.userType}LanguageId`, () => {
			restartOnSubmit = true;
		});

		tile.model.on('sync', () => {
			restartOnSubmit && window.location.reload();
		});

		tile.form = formView({
			name: 'edit-user-settings',
			model: tile.model,
			readonly,
			classNames: ['no-fieldset-margin'],
			saveIndicator: true,

			fields: [{
				key: `${tile.userType}LanguageId`,
				label: t('system.adminprofile.Language'),
				hint: !cantChangeLanguage ?
					t('my-profile.lang-switch-will-restart-the-application') :
					null,
				type: 'select',
				values: store.getters['languages/available'],
				readonly: cantChangeLanguage
			}, {
				key: `${tile.userType}Password`,
				label: t('general-list.Password'),
				type: 'password'
			}, {
				key: `${tile.userType}Phone`,
				label: t('general-list.Phone'),
				type: 'tel'
			}, {
				key: `${tile.userType}Email`,
				label: t('general-list.EmailAddress'),
				type: 'email'
			}, {
				key: `${tile.userType}TimezoneName`,
				label: t('system.adminprofile.TimeZone'),
				type: 'select',
				values: store.state.timezones.data,
				labelField: 'timezoneName',
				keyField: 'timezoneName',
				readonly: cantChangeTimezone
			}]
		});

		tile.form.$el.appendTo(tile.$el);
	}
});

