import appContext from 'app-context';
import { assign } from 'lodash';
import { param } from 'jquery';
import urlify from 'service/urlify/urlify';

const prefix = 'backend-commons/assessmentinstance/rest';
const isExercise = (bool) => `filter[isExercise]=${+bool}`;

export default {
	assessmentInstance: {
		url: `${prefix}/assessmentInstancePopup/`
	},

	newAssessmentInstance: {
		url: `${prefix}/assessmentinstance/`
	},

	allAssessmentInstances: {
		url: `${prefix}/assessmentinstance/all`
	},

	expired: {
		url: (params) => `${prefix}/assessmentinstance/expired?${urlify(params)}`
	},

	ownedByCurrentClinician: {
		url: (params) => {
			const queryParams = {
				documentOwner: 1,
				sort: [
					'assessmentInstanceIsSubmitted',
					'-assessmentInstanceHasStarted',
					'-assessmentInstanceLastModified'
				].join(',')
			};
			assign(queryParams, params);

			return `${prefix}/assessmentinstance/all?${param(queryParams)}`;
		}
	},

	myList: {
		url: () => `${prefix}/assessmentinstance/available/administrator/${appContext.getUserId()}`
	},

	byRespondentId: {
		url: (
			respondentId, { withExercises } = { withExercises: false }
		) => `${prefix}/assessmentinstance/respondent/${respondentId}?${isExercise(withExercises)}`
	},

	mediaReferences: {
		url: (id) => `backend-commons/media/rest/media/assessmentinstance/${id}`
	},

	attachments: {
		url: (id) => `${prefix}/assessmentinstance/attachment/${id}`
	},
	uploadedFiles: {
		url: (id) => `${prefix}/upload-file/by-assessment/${id}`
	},

	uploadedFile: {
		url: `${prefix}/upload-file/`
	},

	unseenAssessmentInstances: {
		url: (params) => `${prefix}/assessmentinstance/unseen?${urlify(params)}`
	},

	markAsSeen: {
		url (clinicianId) {
			return `${prefix}/assessmentInstancePopup/${this.getId()}/mark-as-seen/${clinicianId}`;
		}
	},

	treatmentAssessmentInstances: {
		url: (
			treatmentId,  { withExercises } = { withExercises: false }
		) => `${prefix}/assessmentinstance/treatment/${treatmentId}?${isExercise(withExercises)}`
	},

	eraseResponses: {
		url: (id) => `backend-commons/assessmentinstance/response/assessment-instance-erase/${id}`
	},

	assessmentPortalCode: {
		url: (id) => `backend-commons/assessmentinstance/${id}/assessment-portal/code/`
	},

	sendAssessmentPortalCode: {
		url: (
			id
		) => `backend-commons/assessmentinstance/${id}/assessment-portal/code/notify-respondent`
	},

	assessmentValues: {
		url: `backend-commons/assessmentinstance/value/`
	},

	create: {
		url: `backend-commons/assessmentinstance/`
	},

	single: (id) => `${prefix}/assessmentInstancePopup/${id}`, // #justb💩ckendthings

	single2: (id) => `${prefix}/assessmentinstance/${id}`,

	ibdocToken: (id) =>
		`backend-commons/remote-scoring/ibd/patient/?assessmentInstanceId=${id}`
};
