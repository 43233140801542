import TileView from 'tile-view';
import { assign, map } from 'lodash';
import { Collection } from 'backbone';
import t from 'translate';
import cardUrl from 'cwcardurl';
import { compile } from 'handlebars';
import repository from 'repository';
import systemSettings from 'system-settings';
import { TREATMENT } from 'store/treatments/rp-treatment';
import store from 'store';

const template = compile(`
{{#each contextsOrTypes}}
	<section class="assessment-context-or-type__container">
		{{#if title }}
			<h3 class="assessment-context-or-type__title">{{ title }}</h3>
		{{/if}}
		<div class="assessment-context-or-type__list-container">
			<ul class="generic-list__list assessment-list__list">
				{{#each assessments}}
					<li class="
						generic-list__item
						assessment-list__item
						{{#if isOverdue}}assessment-list__item--overdue{{/if}}
					">
						<a class="generic-list__action assessment-list__action"
							href="{{ url }}">
							{{{ icon 'assessment' iconName }}}
							<div class="
								generic-list__action-title-container
								assessment-list__action-title-container
							">
								<div>
									<h1 class="
										generic-list__action-title
										assessment-list__action-title
									">
										{{ assessmentTitle }}
									</h1>
									<time class="
										generic-list__action-description
										assessment-list__action-description
									">
										{{ endDate }}
									</time>
								</div>
							</div>
						</a>
					</li>
				{{/each}}
			</ul>
		</div>
	</section>
{{/each}}
`);

export default TileView.extend({
	features: ['ENABLE_RESPONDENT_ASSESSMENTS'],
	title: t('Assessments'),

	data: {
		groupBy: 'context',
		order: {}
	},

	tileData () {
		this.config() && assign(this.data, this.config());
		this.data.groupBy = systemSettings.getBoolean('ASSESSMENT_TYPES') ?
			this.data.groupBy :
			'context';
		return {
			assessments: repository.getSortedAssessmentInstances({
				...this.data,
				treatmentId: store.getters[TREATMENT.ID]
			}),
			contextsOrTypes: {
				context: () => repository.getContexts(),
				type: () => repository.getAssessmentTypes()
			}[this.data.groupBy]()
		};
	},

	loaded () {
		const groupedAssessments = [{ assessments: this.getOrphanAssessments() }];

		// prepare contexts or types with nested assessments
		// eslint-disable-next-line lodash/prefer-lodash-method
		const parentedAssessments = this.contextsOrTypes
			.filter((contextOrType) => contextOrType.getId() !== null)
			.map((contextOrType) => {
				const assessments = this.getParentedAssessments(contextOrType);

				return {
					title: contextOrType.getId() && assessments.length ?
						contextOrType.getLabel() :
						'',
					assessments
				};
			});

		this.$el.html(template({
			contextsOrTypes: groupedAssessments.concat(parentedAssessments)
		}));
	},

	// assessments without contexts or types
	getOrphanAssessments () {
		return this._getFilteredAssessments({
			context: {
				assessmentInstanceContextId: ''
			},
			type: {
				type: null
			}
		});
	},

	// assessments filtered by id of a given context or type
	getParentedAssessments (contextOrType) {
		return this._getFilteredAssessments({
			context: {
				assessmentInstanceContextId: `${contextOrType.getId()}`
			},
			type: {
				type: contextOrType.getId()
			}
		});
	},

	_getFilteredAssessments (filterDefinition) {
		const filter = filterDefinition[this.data.groupBy];
		// assessment model.toJSON is overridden, look into its definition for more details
		return map(
			(new Collection(this.assessments.where(filter))).toJSON(), (assessmentInstance) => ({
				...assessmentInstance,
				url: cardUrl.buildUrl('assessment', {
					id: assessmentInstance.assessmentInstanceId,
					treatmentId: store.getters[TREATMENT.ID]
				})
			})
		);
	}
});
