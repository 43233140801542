import repository from 'repository';
import initEntities from '../__helpers/init-entities';

export default {
	namespaced: true,

	state: {
		data: [],
		collection: {}
	},

	getters: {
		size: (state) => state.data.length
	},

	mutations: {
		set: (state, { planInstances, collection }) => {
			state.data = planInstances;
			state.collection = collection;
		}
	},

	actions: {
		initForTreatment: ({ commit }, { treatmentId }) => {
			const collection = repository.treatmentPlanInstances(treatmentId);

			return collection.then(() => {
				commit('set', { planInstances: collection.toJSON(), collection });
			});
		},

		initForRespondent: ({ commit }, { respondentId }) => {
			const collection = repository.respondentPlanInstances(respondentId);

			return collection.then(() => {
				commit('set', { planInstances: collection.toJSON(), collection });
			});
		},

		init: initEntities('planInstances/init', {
			acl: 'administrator.respondents.workflowplans'
		}),

		terminateInTreatment: (context, { treatmentId }) =>
			repository.terminateTreatmentPlans(treatmentId)
	}
};
