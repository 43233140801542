import { assign } from 'lodash';
import { View } from 'marionette';
import { compile } from 'handlebars';
import systemSettings from 'system-settings';
import t from 'translate';
import ChangeView from './change';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';

export default View.extend({
	template: compile(`
		<section class="diary-entry" tabindex="0">
			<h3 class="diary-entry__title">
				{{#if mood}}
					<span class="diary-entry__mood">
						<span
							class="icon icon--{{ mood }}"
							title="{{ moodName }}"
							alt="{{ moodName }}"
							aria-label="{{ moodName }}"
						/>
					</span>
				{{/if}}
				<time
					class="diary-entry__created-at"
					datetime="{{ dateFormat createdAt "YYYY-MM-DD hh:mm" false }}"
					aria-label="{{ dateFormat createdAt "ll" false }}"
				>
					{{ dateFormat createdAt "ll" false }}
				</time>
			</h3>

			{{#if editable}}
				<div class="diary-entry__controls">
					<button
						class="diary-entry__change icon--personal-diary"
						tabindex="0"
						title="{{#t "Edit"}}{{/t}}"
					></button>
					<button
						class="diary-entry__remove icon--times"
						tabindex="0"
						title="{{#t "Remove"}}{{/t}}"
					></button>
				</div>
			{{/if}}
			<p class="diary-entry__content">{{ content }}</p>
		</section>
		<div class="diary-entry__form-change"></div>
	`),
	tagName: 'li',
	ui: {
		formChange: '.diary-entry__form-change',
		entry: '.diary-entry'
	},
	events: {
		'click .diary-entry__remove': 'onDeleteClick',
		'click .diary-entry__change': 'onChangeClick'
	},
	modelEvents: {
		'update cancel-update': 'render',
		'cancel-update': 'onUpdateCancel'
	},

	serializeData () {
		return assign(this.model.toJSON(), {
			editable: systemSettings.getBoolean('RESPONDENT_DIARY_EDITABLE'),
			moodName: this.model.getMoodName(),
			// transform deprecated values to standard ones
			mood: {
				iconSmile: 'smile',
				iconMeh: 'meh',
				iconFrown: 'frown'
			}[this.model.get('mood')] || this.model.get('mood')
		});
	},

	onChangeClick () {
		// enable "edit in place" functionality
		this.changeView = new ChangeView({
			model: this.model
		});
		this.changeView.render();

		this.ui.formChange.html(this.changeView.$el);
		this.ui.entry.addClass('diary-entry--change');
	},

	onDeleteClick () {
		confirmation({
			icon: 'delete',
			warning: true,
			title: t('Delete entry'),
			message: t('Are you sure you wish to delete this entry?')
		}).done(() => {
			this.model.destroy().done(() => {
				this.remove();
				cwalert.success(t('Diary entry deleted successfully'));
			});
		});

		return false;
	},

	onUpdateCancel () {
		this.changeView.remove();
		this.ui.entry.removeClass('diary-entry--change');
	}
});

