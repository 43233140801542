import Backbone from 'backbone';
import { includes, replace, startsWith } from 'lodash';

// in: 'elo/clinician//siema/assessment//5'
// out: 'elo/clinician/siema/assessment/5'
const mergeSlashes = (str) => replace(str, /\/\//g, '/');

const replacePrefix = (str, replacement) => replacement ?
	replace(str, /clinician|respondent/, replacement) :
	str;

/*
 * Generate proper urls when e.g. embedding  background images. The service prepends provided
 * url with a proper backend address.
 * @param url
 * @param opts
 * @returns String
 */
export default (
	rawUrl,
	{ forcePrepend, forcePrefix } = { forcePrepend: false, forcePrefix: false }
) => {
	if (!rawUrl) {
		return '';
	}
	const url = replacePrefix(rawUrl, forcePrefix);
	const appUrl = Backbone.config().appUrl;
	const containsAppUrl = includes(url, appUrl);
	const containsAnyUrl = startsWith(url, 'http');
	const absolute = startsWith(url, '/');
	const notNeeded = () => containsAppUrl || containsAnyUrl || absolute;

	return notNeeded() && !forcePrepend ? url : mergeSlashes(appUrl + url);
};
