import Modernizr from 'modernizr';
import $ from 'jquery';

/*
 * on iOS devices, add a class to <body> when a text input is focused
 * it's used for e.g. hiding an appbar since it behaves strange with
 * screen keyboard
 */
export default function () {
	const isTouchIosDevice = Modernizr.touch && Modernizr.isiOs;

	if (!isTouchIosDevice) {
		return;
	}

	const $body = $('body');
	const className = 'input-focus';

	$(document).on('focus', 'input:text, textarea', () => {
		$body.addClass(className);
	});
	$(document).on('blur', 'input:text, textarea', () => {
		$body.removeClass(className);
	});
}
