var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.groupedPages, function(pages, groupName) {
      return _c(
        "section",
        {
          key: groupName,
          staticClass: "information-package-elements__container"
        },
        [
          groupName !== "undefined"
            ? _c("h3", { staticClass: "information-package-elements__title" }, [
                _vm._v("\n\t\t\t" + _vm._s(groupName) + "\n\t\t")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "ul",
            _vm._l(pages, function(page) {
              return _c(
                "li",
                {
                  key: page.id,
                  staticClass: "generic-list__item assessment-list__item"
                },
                [
                  _c(
                    "a",
                    {
                      class: _vm.linkClassName(page),
                      attrs: { href: _vm.url(page) }
                    },
                    [
                      _c("span", { staticClass: "icon icon--page" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "\n\t\t\t\t\t\t\tgeneric-list__action-title-container\n\t\t\t\t\t\t\tinformation-package-element__action-title-container\n\t\t\t\t\t\t"
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "\n\t\t\t\t\t\t\t\tgeneric-list__action-title\n\t\t\t\t\t\t\t\tinformation-package-element__action-title\n\t\t\t\t\t\t\t"
                            },
                            [_vm._v(_vm._s(page.elementVersion.alias))]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "information-package-element__state-label"
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.stateLabel(page)) +
                                  "\n\t\t\t\t\t\t"
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            }),
            0
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }