<template>
	<edit-goal
		:goal="goal"
		:categories="categories"
		:on-submit="onSubmit"
		:on-cancel="onCancel"
		:treatments="[]"
	/>
</template>

<script>
import { cloneDeep, get } from 'lodash';
import EditGoal from '../../components/edit-goal';
import cwalert from 'service/cwalert';
import t from 'translate';
import { EDIT } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints.json';

export default {
	title: t('goals.editGoal.editTitle'),
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.GOALS_ACTIVITIES,
		op: EDIT
	}],
	actions: ({ $store }) => [
		['goal/init', {
			goalId: get($store.getters.urlParams, 'goalId'),
			respondentId: $store.getters['respondent/respondentId']
		}],
		'goalCategories/init',
		'respondent/init'
	],
	components: { EditGoal },
	data: ({ $store }) => ({
		goal: $store.getters['goal/item'],
		cachedGoal: cloneDeep($store.getters['goal/item'])
	}),
	computed: {
		categories: ({ $store }) => $store.getters['goalCategories/items']
	},

	methods: {
		onSubmit () {
			this.setDataLoading();
			this.$store.dispatch('goal/update', this.goal).then(() => {
				cwalert.success(t('goals.editGoal.saveSuccess'));
				this.setDataLoaded();
			});
		},

		onCancel () {
			this.goal = cloneDeep(this.cachedGoal);
		}
	}
};
</script>
