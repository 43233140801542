import MultipleEntities from '../shared/multiple-entities';
import MediaModel from './entity';
import urls from './urls';

const Media = MultipleEntities.extend({
	model: MediaModel,
	urls,
	eventNamespace: 'media',

	getList () {
		return this.retrieve('list');
	},

	getAll () {
		return this.retrieve('listAll');
	},

	search (params = {}) {
		// force fetch hack
		params.timestamp = new Date().valueOf();
		return this.retrieve('search', params);
	}
});

export default new Media();
