import '../styles/vendor-styles.scss';
import '../styles/main.scss';
import '../styles/engine/engine.scss';
import '../styles/components/components.scss';
import '../styles/modules/modules.scss';

import acl from 'acl';
import systemSettings from 'system-settings';
import appContext from 'app-context';
import moment from 'moment';
import app from 'core/app';
import { history } from 'backbone';
import { when } from 'jquery';
import i18nLoad from 'service/lang/i18n-load';
import passwordExpire from 'service/password-expire/password-expire';
import cache from 'service/cache/cache';
import store from 'store';
import config from './core/config';
import focused from './service/focused-elements';
import { Settings } from 'luxon';
import initSentry from 'config/sentry';
import { noop } from 'lodash';
import env from 'env';
import autoLogout from 'service/auto-logout';

if (env.sentry) {
	initSentry();
}

export default function ({ type, user, modules, afterFetch = noop, afterStart = noop }) {
	config(type);

	store.dispatch('initUser', { user, type })
		.then(() => store.dispatch('engine/cards/init', { type }))
		.then(async () => {
			await afterFetch();
			acl.init(store.state.user.checkpoints);
			systemSettings.reload(store.state.user.systemSettings);

			store.subscribe((mutation, state) => {
				if (mutation.type === 'systemSettings/updateSetting') {
					systemSettings.reload(state.systemSettings.data);
				}
			});

			appContext.set({ user, type });

			return i18nLoad();
		})
		.then(() => {
			const langCode = store.state.user.userLanguageCode;
			moment.locale(langCode);
			Settings.defaultLocale = langCode;

			return when(cache);
		})
		.then(async () => {
			app.start({ type, modules });
			await afterStart();
			history.start();
			focused.init();
			autoLogout();

			if (type !== 'assessment') {
				passwordExpire();
			}
		});
}
