import $, { Deferred } from 'jquery';
import t from 'translate';
import { assign, escape, map, replace, isFunction } from 'lodash';
import { compile } from 'handlebars';
import { Model } from 'backbone';
import { View } from 'marionette';

const ConfirmationView = View.extend({
	template: compile(`
		<div
			role="dialog"
			{{#if title}}aria-labelledby="confirmation-title"{{/if}}
			 aria-describedby="confirmation-message"
			 class="confirmation__container
			{{#if warning}}confirmation__container--warning{{/if}}
			{{#if preventCancel}}confirmation__container--prevent-cancel{{/if}}"
		>
			<div class="confirmation__window">
				{{#if title}}
					<h2 id="confirmation-title" class="confirmation__title">
						{{#if icon}}{{{icon icon}}}{{/if}} {{title}}
					</h2>
				{{/if}}
				<p id="confirmation-message" class="confirmation__message">{{{message}}}</p>
				<div class="confirmation__buttons-container">
					<button class="confirmation__confirm{{#if warning }}--warning{{/if}}">
						{{confirm}}
					</button>
					{{#unless preventCancel}}
						<button class="confirmation__cancel">{{cancel}}</button>
					{{/unless}}
				</div>

			</div>
		</div>
	`),
	events: {
		'click @ui.cancel': 'onCancel',
		'click @ui.confirm': 'onConfirm'
	},
	ui: {
		cancel: '.confirmation__cancel',
		confirm: '.confirmation__confirm,.confirmation__confirm--warning',
		message: '.confirmation__message'
	},
	onCancel () {
		this.options.dff.reject();
		this.remove();
	},
	onConfirm () {
		this.options.dff.resolve();
		this.remove();
	}
});

const tagsWhitelist = ['b', 'strong', 'i', 'em'];

const unescapeTags = (str, whitelist = []) => {
	const tagsToCheck = [...whitelist, ...map(whitelist, (tagName) => `/${tagName}`)];

	// matches pairs of escaped tags from whitelist e.g. `&lt;b&gt;` and `&lt;/b&gt;`
	const tagRegex = new RegExp(`&lt;(${tagsToCheck.join('|')})&gt;`, 'g');

	return replace(str, tagRegex, '<$1>');
};

const getMessage = (message) => {
	// Handle translation function
	const msg = isFunction(message) ? message() : message;

	return unescapeTags(escape(msg), tagsWhitelist);
};

export default (params) => {
	const parameters = assign({
		confirm: t('Yes, confirm'),
		cancel: t('No, cancel')
	}, params, {
		message: getMessage(params.message)
	});

	const model = new Model(parameters);
	const dff = Deferred();
	const view = new ConfirmationView({ model, dff });
	view.render();
	$('.app__container').append(view.$el);
	document.activeElement.blur();
	view.ui.confirm.focus();

	// on ESC ...
	$(document).on('keyup', (e) => {
		if (e.keyCode === 27) {
			view.remove();
			dff.reject();
		}
	});

	return dff.promise();
};
