export default ({ type, method }) => ({
	props: {
		params: {
			type: Object,
			required: true
		},
		responsiveOptions: {
			type: Array,
			default: () => []
		},
		options: {
			type: Object
		},
		thresholdLine: {
			type: [Number, Array]
		},
		thresholdRange: {
			type: Array
		}
	},
	template: `
		<chart
			:type="type"
			:method="method"
			:params="params"
			:responsive-options="responsiveOptions"
			:options="options"
			:threshold-line="thresholdLine"
			:threshold-range="thresholdRange"
		/>
	`,
	data: () => ({ type, method })
});
