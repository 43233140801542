import { assign, get } from 'lodash';
import repository from 'repository';
import initWith from '../__helpers/init-with';

export default {
	namespaced: true,

	state: {
		data: null,
		model: null
	},

	getters: {
		flagId: (state, getters, rootState) => get(rootState.cardData, 'urlParams.flagId')
	},

	mutations: {
		set: (state, { flag, model }) => {
			state.data = flag;
			state.model = model;
		},

		reset: (state) => {
			state.data = null;
			state.model = null;
		},

		update: (state, payload) => {
			state.data = assign({}, state.data, payload);
			state.model.set(payload);
		}
	},

	actions: {
		init: (context, { flagId } = { flagId: 0 }) => {
			const { commit, getters } = context;
			const model = repository.flag(flagId || getters.flagId);

			return initWith(context, {
				actionName: 'flag/init',
				resource: model,
				action: () => {
					const flag = model.toJSON();
					commit('set', { model, flag });

					return {
						treatmentId: flag.treatment.id,
						respondentId: flag.respondent.respondentId
					};
				}
			});
		},

		initPure: ({ commit }, { flagId }) => {
			const model = repository.flag(flagId);

			return model.then(() => {
				commit('set', { model, flag: model.toJSON() });
			});
		},

		update: ({ commit }, payload) => {
			commit('update', payload);
		},

		destroy: ({ commit, state }) => state.model.destroy().then(() => {
			commit('reset');
		})
	}
}
