<template>
	<section>
		<h2 v-if="title" class="chart-tile__title">
			{{title}}
		</h2>
		<chart
			:type="config.chartType"
			:method="method"
			:params="params"
			:options="options"
			:responsive-options="responsiveOptions"
			:threshold-line="config.thresholdLine"
			:threshold-range="config.thresholdRange"
		/>
	</section>
</template>

<script>
import { assign, filter, flow, groupBy, isArray, keys, map, reduce, set, sum, uniq } from 'lodash';
import {
	BAR, BAR_METHOD, DONUT, HORIZONTAL_BAR, LINE_METHOD, PIE, PIE_METHOD, PLOT, STACKED_BAR
} from 'components/charts/constants';
import { DateTime } from 'luxon';
import cubeId from '../../shared/cube-id';
import actions from '../../shared/actions';
import { CUBE } from 'store/cube/cube';

export default {
	acl: [],
	actions: (tile) => actions({
		path: tile.path,
		title: tile.config().title,
		query: tile.config().cubeQuery,
		ignoreAccessRules: tile.config().ignoreAccessRules
	}),
	data: ({ tile }) => ({
		config: { ...tile.config(), path: tile.path }
	}),
	computed: {
		basicType: ({ config }) => ({
			[BAR]: BAR,
			[DONUT]: PIE,
			[HORIZONTAL_BAR]: BAR,
			[PIE]: PIE,
			[PLOT]: PLOT,
			[STACKED_BAR]: BAR
		}[config.chartType]),

		title: ({ maybeTranslate, config }) => maybeTranslate(config.title),
		results: ({ config, $store }) => $store.getters[CUBE.RESULTS][cubeId(config)].result,

		groupedResults: ({ config, results }) => config.seriesBy ?
			flow([
				(items) => groupBy(items, config.seriesBy),
				(items) => reduce(items, (memo, val, key) => {
					if (`${key}` !== 'null') {
						memo[key] = val;
						return memo;
					}
					return memo;
				}, {})
			])(results) :
			{ results },

		seriesBy: ({ legendNames, labels, groupedResults, config }) => map(
			legendNames,
			(key) => map(labels, flow([
				(label) => filter(
					groupedResults[key],
					(result) => result[config.groupLabels] === label
				),
				(items) => map(items, config.series),
				sum
			]))
		),

		series: ({ config, results, seriesBy, basicType }) => {
			if (config.seriesBy) {
				return seriesBy;
			}

			const pie = () => isArray(config.series) ?
				map(config.series, (seriesItem) => results[0][seriesItem]) :
				map(results, (result) => result[config.series]);

			const plotBar = () => map(config.series, (seriesItem, index) => {
				if (isArray(results[index])) {
					return map(results[index], (result) => result[seriesItem]);
				}
				return map(results, (result) => result[seriesItem]);
			});

			return {
				[BAR]: plotBar,
				[PIE]: pie,
				[PLOT]: plotBar
			}[basicType]();
		},

		labels: ({ config, maybeTranslate, results, generateLabels }) => {
			if (isArray(config.groupLabels)) {
				return map(config.groupLabels, maybeTranslate);
			}

			return isArray(results) && uniq(generateLabels({ results }));
		},

		legendNames: ({ config, groupedResults, maybeTranslate, results, generateLabels }) => {
			if (config.seriesBy) {
				return keys(groupedResults);
			}

			if (isArray(config.legendNames)) {
				return map(config.legendNames, maybeTranslate);
			}

			return generateLabels({ results, cfgField: 'legendNames' });
		},

		options: ({ config }) => {
			const opts = {
				seriesBarDistance: 30,
				low: config.min,
				high: config.max,
				height: config.chartHeight,
				horizontalLabels: config.horizontalLabels
			};

			assign(opts, {
				[DONUT]: {
					donut: true,
					donutWidth: 40,
					startAngle: 0,
					showLabel: true
				},
				[STACKED_BAR]: { stackBars: true },
				[HORIZONTAL_BAR]: { horizontalBars: true }
			}[config.chartType]);

			if (config.axisXDateFormat) {
				set(
					opts,
					'axisX.labelInterpolationFnc',
					(value) => DateTime.fromISO(value).isValid ?
						DateTime.fromISO(value).toFormat(config.axisXDateFormat) :
						value
				);
			}

			if (config.axisXOffset) {
				set(opts, 'axisX.offset', config.axisXOffset);
			}

			return opts;
		},

		responsiveOptions: ({ config }) => [
			[
				// layout(2column, true)
				'screen and (max-width: 72em) and (min-width: 36em)',
				{ seriesBarDistance: 16 }
			], [
				// layout(1column, true)
				'screen and (max-width: 36em)',
				{ seriesBarDistance: 10 }
			], [
				// layout(4columnNarrow, true)
				'screen and (max-width: 72em) and (min-width: 36em) and (orientation: landscape)',
				{ seriesBarDistance: 24 }
			], [
				'screen and (max-width: 72em)',
				assign({}, (config.chartType === STACKED_BAR ||
					config.chartType === HORIZONTAL_BAR ||
					config.chartType === BAR ||
					config.chartType === PLOT) && {

					axisX: {
						labelInterpolationFnc: (value, index, collection) => {
							const result = config.axisXDateFormat &&
								DateTime.fromISO(value).isValid ?
								DateTime.fromISO(value).toFormat(config.axisXDateFormat) :
								value;

							if (collection.length > 7) {
								const mod = Math.ceil(collection.length / 6);

								return index % mod === 0 ? result : null;
							}

							return result;
						}
					}
				})
			]
		],

		params: ({ config, labels, legendNames, series }) => ({
			dataColors: config.dataColors,
			labels: config.groupLabels && labels,
			legendNames: config.legendNames && legendNames,
			series,
			thresholdLineColors: config.thresholdLineColors,
			thresholdRangeColors: config.thresholdRangeColors
		}),

		method: ({ basicType }) => ({
			[BAR]: BAR_METHOD,
			[PIE]: PIE_METHOD,
			[PLOT]: LINE_METHOD
		}[basicType])
	},

	methods: {
		maybeTranslate (text = '') {
			return this.config.translate ? this.t(text) : text;
		},

		generateLabels ({ results, cfgField = 'groupLabels' }) {
			return map(
				isArray(this.config.cubeQuery) ? results[0] : results,
				(result) => isArray(result) ?
					this.maybeTranslate(result[0][this.config[cfgField]]) :
					this.maybeTranslate(result[this.config[cfgField]])
			);
		}
	}
};
</script>
