<template>
	<div class="treatment-selection">
		<h2 v-if="enabledCount && !loading" class="treatment-selection__title">
			{{$t('treatmentSelection.title')}}
		</h2>

		<span v-if="loading" v-loader-spinner />

		<template v-else-if="enabledCount">
			<checkbox-input
				v-model="asDefault"
				:translate="false"
				:label="$t('treatmentSelection.asDefault')"
			/>

			<treatment-selection-list
				:treatments="enabledTreatments"
				@click="select"
			/>
		</template>

		<div v-else class="treatment-selection__empty">
			<div>
				<img
					:src="emptyIcon"
					:alt="$t('treatmentSelection.noTreatments')"
					class="treatment-selection__empty-image"
				/>
			</div>

			<p>{{$t('treatmentSelection.noTreatments')}}</p>
		</div>

		<ul v-if="!loading" class="treatment-selection__action-items">
			<li class="treatment-selection__action-item">
				<button
					v-if="consentsCount"
					class="treatment-selection__action"
					tabindex="0"
					@click="openConsents"
				>
					<icon class="treatment-selection__action-icon" name="consent-template" />
					<span class="treatment-selection__action-text">{{$t('consents.my')}}</span>
				</button>
			</li>
			<li class="treatment-selection__action-item">
				<button
					class="treatment-selection__action"
					tabindex="0"
					@click="logout"
				>
					<icon class="treatment-selection__action-icon" name="logout" />
					<span class="treatment-selection__action-text">{{$t('Logout')}}</span>
				</button>
			</li>
		</ul>
	</div>
</template>

<script>
import logoutHref from 'modules/profile/util/logout-href';
import CheckboxInput from 'components/form/checkbox-input';
import { TREATMENTS } from 'store/treatments/treatments';
import { TREATMENT } from 'store/treatments/rp-treatment';
import TreatmentIcon from './treatment.svg';
import TreatmentSelectionList from './treatment-selection-list';
import { size } from 'lodash';
import cardUrl from 'cwcardurl';
import { RESPONDENT_CONSENT_INSTANCES as CONSENTS } from 'store/consents/rp-consent-instances';

export default {
	components: {
		CheckboxInput,
		TreatmentSelectionList
	},

	data: () => ({
		asDefault: false,
		loading: true
	}),

	computed: {
		emptyIcon: () => TreatmentIcon,
		allTreatments: ({ $store }) => $store.getters[TREATMENTS.ALL],
		enabledTreatments: ({ $store }) => $store.getters[TREATMENTS.ENABLED],
		disabledTreatments: ({ $store }) => $store.getters[TREATMENTS.DISABLED],
		enabledCount: ({ enabledTreatments }) => +(enabledTreatments && size(enabledTreatments)),
		consents: ({ $store }) => $store.getters[CONSENTS.ITEMS],
		consentsCount: ({ consents }) => consents.length,
		consentsFromRelations: ({ $store }) => $store.getters[CONSENTS.FROM_RELATIONS],
		consentsFromDisabledTreatments: (
			{ $store }
		) => $store.getters[CONSENTS.FROM_DISABLED_TREATMENTS],

		// simple and intuitive conditions needed for auto-selection
		canAutoselect: (
			{ enabledCount, consentsFromDisabledTreatments, consentsFromRelations }
		) => (
			enabledCount === 1 &&
			!consentsFromDisabledTreatments.length &&
			!consentsFromRelations.length
		)
	},

	async created () {
		this.loading = true;
		await this.initData();

		if (this.canAutoselect) {
			if (this.disabledTreatments.length) {
				await this.select(this.enabledTreatments[0]);

			} else if (size(this.allTreatments) === 1) {
				await this.select(this.allTreatments[0]);
			}
		}

		this.loading = false;
	},

	methods: {
		async select (treatment) {
			this.loading = true;

			if (this.asDefault) {
				await this.$store.dispatch(TREATMENT.SAVE_DEFAULT, treatment.id);
			}

			await this.$store.dispatch(TREATMENT.INIT, treatment.id);
			this.loading = false;
		},

		async initData () {
			await Promise.all([
				this.$store.dispatch(TREATMENTS.INIT, { includeDisabled: true }),
				this.$store.dispatch(CONSENTS.INIT_ALL)
			]);
		},

		logout () {
			window.location = logoutHref();
		},

		openConsents () {
			this.$emit('openConsents');
			cardUrl.openCard('available-consents');
		}
	}
};
</script>
