<template>
	<nav class="table-view__filters">
		<items-count
			v-if="totalCountExists"
			:current="currentCount"
			:total="totalCount"
		/>

		<div class="table-view__filter-controls">
			<filter-input
				v-for="filterCfg in config.filters"
				:key="filterCfg.name"
				v-model="filters[filterCfg.name]"
				:config="filterCfg"
				:filter-id="filterId(filterCfg.name)"
			/>
		</div>

		<filter-search
			v-if="config.searchable"
			v-model="search"
		/>

		<clear-filters
			v-if="config.clearable"
			:can-clear="canClear"
			@click="clear"
		/>
	</nav>
</template>

<script>
import { get, identity, isEmpty, isNil, map, pick, reduce, some } from 'lodash';
import ClearFilters from './clear-filters';
import ItemsCount from './items-count';
import FilterSearch from './filter-search';
import FilterInput from './filter-input';
import { SEARCH } from 'store/general/search';
import { searchParams } from '../helpers';
import { CARD } from 'store/engine/card';

const pristineValues = (filters) => reduce(
	filters,
	(acc, filterCfg) => ({ ...acc, [filterCfg.name]: '' }),
	{}
);

export default {
	components: { ClearFilters, FilterInput, FilterSearch, ItemsCount },
	props: {
		config: {
			type: Object,
			required: true
		},
		currentCount: {
			type: Number,
			required: true
		},
		tilePath: {
			type: String,
			required: true
		},
		totalCount: {
			type: Number,
			required: true
		}
	},

	data () {
		const existingValues = pick(
			this.$store.getters[SEARCH.DATA][this.tilePath],
			map(this.config.filters, 'name')
		);

		return {
			filters: isEmpty(existingValues) ? pristineValues(this.config.filters) : existingValues
		};
	},

	computed: {
		canClear: ({ search, filters }) => !!search.length || some(filters, identity),
		totalCountExists: ({ totalCount }) => !isNil(totalCount),

		search: {
			get: ({ $store, tilePath }) => decodeURIComponent(
				searchParams({ $store, id: tilePath }).search
			),
			set (newVal) {
				this.$store.dispatch(SEARCH.SET_PARAM, {
					id: this.tilePath,
					param: {
						search: encodeURIComponent(newVal),
						start: 0
					}
				});
				this.emitChange();
			}
		}
	},

	watch: {
		filters: {
			handler (filters) {
				this.$store.dispatch(SEARCH.SET_PARAM, {
					id: this.tilePath,
					param: {
						...filters,
						start: 0
					}
				});
				this.emitChange();
			},
			deep: true
		}
	},

	created () {
		this.init();
	},

	methods: {
		init () {
			this.$store.dispatch(SEARCH.INIT, {
				id: this.tilePath,
				data: {
					start: 0,
					limit: get(this.config, 'limit', 10),
					search: this.$store.getters[CARD.SEARCH_QUERY] || '',
					...this.filters
				}
			});
		},

		clear () {
			this.search = '';
			this.filters = pristineValues(this.config.filters);
		},

		emitChange () {
			this.$emit('change');
		},

		filterId (name) {
			return `${this.tilePath}-filter-${name}`;
		}
	}
};
</script>
