import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import RemoteScoringProperty from './remote-scoring-property';

const RemoteScoringPropertiesEntity = MultipleEntities.extend({
	urls,
	model: RemoteScoringProperty,
	idName: 'propertyId',
	eventNamespace: 'remote-scoring-property',

	getForAssessment (assessmentId) {
		return this.retrieve('remoteScoringProperties', assessmentId);
	}

});

export default new RemoteScoringPropertiesEntity();
