var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("select-input", {
    attrs: {
      "no-drop": _vm.noDrop,
      "empty-search-dropdown": false,
      exceeded: _vm.exceeded,
      async: true,
      loading: _vm.loading,
      options: _vm.list,
      "get-option-label": _vm.respondentLabel,
      label: "Respondent",
      "item-label": "respondentId",
      placeholder: _vm.t("Type respondent name"),
      required: _vm.required
    },
    on: {
      input: function($event) {
        return _vm.$v.respondent.$touch()
      },
      search: _vm.onInput
    },
    model: {
      value: _vm.respondent,
      callback: function($$v) {
        _vm.respondent = $$v
      },
      expression: "respondent"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }