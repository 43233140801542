import { get, noop } from 'lodash';
import store from 'store';
import cardsProps from 'core/engine/card/services/cards-props';

/**
 * Perform the redirect when viable config is provided.
 *
 * @param {object} params         - Parameters.
 * @param {object} params.config  - Configuration that is supposed to maybe contain 'referral' prop.
 * @param {Array} params.fallback - Parameters that are to be passed to cardUrl.openCard.
 * @param {Function} params.otherwise - Callback called when there's no `redirect` config param.
 * @example
 * 		redirectOnSave();
 */
export default ({ config = {}, fallback = [], otherwise = noop }) => {
	if (config.redirect) {
		const encodedReferral = get(store.getters, 'urlParams.referral');
		const [card, params] = config.followReferral && encodedReferral ?
			JSON.parse(decodeURI(encodedReferral)) :
			fallback;

		const eventBus = cardsProps.currentOf('views').model.get('context');
		eventBus.trigger('card:close', { openCard: card, cardQuery: params });

	} else {
		otherwise();
	}
};
