var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.datetimePolyfill
    ? _c(
        "div",
        { class: _vm.containerClassName },
        [
          _c("label", { class: _vm.labelClassName }, [
            _c("p", { class: _vm.labelTextClassName }, [
              _vm.translate
                ? _c(
                    "span",
                    {
                      directives: [
                        { name: "translate", rawName: "v-translate" }
                      ]
                    },
                    [_vm._v(_vm._s(_vm.label))]
                  )
                : _c("span", [_vm._v(_vm._s(_vm.label))]),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.model,
                      expression: "model"
                    }
                  ],
                  class: [
                    "datetime-input__clear-button",
                    { "datetime-input__clear-button--disabled": _vm.disabled }
                  ],
                  attrs: { type: "button" },
                  on: { click: _vm.clearValue }
                },
                [
                  _c("i", { staticClass: "fa fa-trash" }),
                  _vm._v(
                    "\n\t\t\t\t" + _vm._s(_vm.titles.clearBtn) + "\n\t\t\t"
                  )
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("datetime", {
            ref: "datetime",
            attrs: {
              type: _vm.type,
              "input-id": _vm.datetimeId,
              "input-class": _vm.inputClassName,
              "value-zone": "local",
              format: _vm.dateFormat,
              disabled: _vm.disabled,
              readonly: _vm.readonly
            },
            on: {
              input: function($event) {
                return _vm.updateValue()
              },
              close: _vm.onClose
            },
            model: {
              value: _vm.model,
              callback: function($$v) {
                _vm.model = $$v
              },
              expression: "model"
            }
          })
        ],
        1
      )
    : _c("div", { class: _vm.containerClassName }, [
        _c("p", { class: _vm.labelTextClassName }, [
          _vm.translate
            ? _c(
                "span",
                { directives: [{ name: "translate", rawName: "v-translate" }] },
                [_vm._v(_vm._s(_vm.label))]
              )
            : _c("span", [_vm._v(_vm._s(_vm.label))])
        ]),
        _vm._v(" "),
        _c("input", {
          ref: "input",
          class: _vm.inputClassName,
          attrs: {
            type: _vm.inputType,
            disabled: _vm.disabled,
            readonly: _vm.readonly
          },
          domProps: { value: _vm.value },
          on: {
            input: function($event) {
              return _vm.updateValue()
            },
            blur: _vm.onClose
          }
        })
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }