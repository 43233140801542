<template>
	<div>
		<p v-if="contentPackages.length" class="information-package__list-uncompleted-label">
			{{$t('Uncompleted')}}
		</p>
		<ul class="information-packages__list">
			<li
				v-for="contentPackage in contentPackages"
				:key="contentPackage.id"
				class="information-package__list-item"
			>
				<a
					class="generic-list__action information-package__action"
					:href="url(contentPackage)"
				>
					<icon name="content-package" />
					<div
						class="
							generic-list__action-title-container
							information-package__action-title-container
						"
					>
						<p class="generic-list__action-title information-package__action-title">
							{{contentPackage.name}}
						</p>
						<span
							class="information-package__items-counter"
							:title="$t('Information package elements')"
						>
							<span class="information-package__items-counter--enlarge">
								{{uncompleted(contentPackage)}}
							</span> / {{contentPackage.elementsTotal}}
						</span>
					</div>
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
import cardUrl from 'cwcardurl';
import t from 'translate';
import {
	RESPONDENT_CONTENT_PACKAGE_INSTANCES
} from 'store/content-packages/rp-content-package-instances';
import { TREATMENT } from 'store/treatments/rp-treatment';

export default {
	title: t('Information packages'),
	features: ['ENABLE_RESPONDENT_INFORMATION_PACKAGES'],
	actions: () => [RESPONDENT_CONTENT_PACKAGE_INSTANCES.INIT],

	computed: {
		contentPackages: ({ $store }) => $store.getters[RESPONDENT_CONTENT_PACKAGE_INSTANCES.ITEMS]
	},
	methods: {
		url (contentPackage) {
			return cardUrl.buildUrl('information-package', {
				contentPackageId: contentPackage.id,
				treatmentId: this.$store.getters[TREATMENT.ID]
			});
		},
		uncompleted: ({ elementsTotal, elementsCompleted }) => elementsTotal - elementsCompleted
	}
};
</script>
