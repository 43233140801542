import SingleEntity from '../shared/single-entity';
import urls from './urls';
import datetime from 'datetime';
import appContext from 'app-context';
import Backbone from 'backbone';
import { ajax } from 'jquery';
import { isString, replace, toLower } from 'lodash';

const eventNames = [
	'change:assessmentInstanceClosed',
	'change:assessmentInstanceIsSubmitted',
	'change:assessmentInstanceHasStarted'
].join(' ');

export default SingleEntity.extend({
	urlRoot: urls.assessmentInstance.url,
	idAttribute: 'assessmentInstanceId',
	eventNamespace: 'assessment-instance',

	defaults: {
		treatment: null
	},

	events: {
		[eventNames] () {
			this.trigger('change:assessmentInstanceStatus');
		}
	},

	onSync (method, model) {
		const fieldName = isString(model.url) ? 'url' : 'urlRoot';

		if (method === 'create' || method === 'delete') {
			model[fieldName] = replace(
				model[fieldName],
				'assessmentInstancePopup',
				'assessmentinstance'
			);

		} else {
			model[fieldName] = replace(
				model[fieldName],
				'/rest/assessmentinstance/',
				'/rest/assessmentInstancePopup/'
			);
		}

		// looks like sh💩tty solution, but it works for now
		if (method === 'delete') {
			model.urlRoot = replace(
				model.urlRoot,
				'assessmentInstancePopup',
				'assessmentinstance'
			);
		}

		// eslint-disable-next-line prefer-rest-params
		return Backbone.sync.apply(this, arguments);
	},

	setStartDate (date, timezone) {
		const newDate = timezone ?
			datetime(date).toUtcFromTimezone(timezone).toObject() :
			datetime(date).toObject();

		this.set({
			assessmentInstanceStartDate: newDate,
			startDate: newDate
		});
		return this;
	},

	setEndDate (date, timezone) {
		const newDate = timezone ?
			datetime(date).toUtcFromTimezone(timezone).toObject() :
			datetime(date).toObject();

		this.set({
			assessmentInstanceEndDate: newDate,
			endDate: newDate
		});
		return this;
	},

	_getField (normalFieldName, statusFieldName) {
		if (this.has('assessmentInstanceStatus')) {
			return this.get('assessmentInstanceStatus')[statusFieldName];
		}
		return this.get(normalFieldName);

	},

	_setField (normalFieldName, statusFieldName) {
		if (this.has('assessmentInstanceStatus')) {
			return function (value) {
				this.get('assessmentInstanceStatus')[statusFieldName] = value;
			}.bind(this);
		}
		return function (value) {
			this.set(normalFieldName, value);
		}.bind(this);

	},

	isClosed () {
		return this._getField('assessmentInstanceClosed', 'assessmentInstanceClosed');
	},

	isSubmitted () {
		return this._getField('assessmentInstanceIsSubmitted', 'isSubmitted');
	},

	isCompleted () {
		return this.isClosed() || this.isSubmitted();
	},

	isStarted () {
		return this._getField('assessmentInstanceHasStarted', 'hasStarted');
	},

	_setClosed (bool) {
		this._setField('assessmentInstanceClosed', 'assessmentInstanceClosed')(bool);
		return this;
	},

	_setSubmitted (bool) {
		this._setField('assessmentInstanceIsSubmitted', 'isSubmitted')(bool);
		return this;
	},

	close () {
		this._setClosed(true)._setSubmitted(true);
		return this;
	},

	getRespondentId () {
		return this.get('respondentId') || this.get('respondent').respondentId;
	},

	getStartDate () {
		return this.getThisOrThat('assessmentInstanceStartDate', 'startDate');
	},

	getEndDate () {
		return this.getThisOrThat('assessmentInstanceEndDate', 'endDate');
	},

	getLastModifiedDate () {
		return this.getThisOrThat('assessmentInstanceLastModified', 'lastModifiedDate');
	},

	getDeliveredDate () {
		return this.getThisOrThat('assessmentInstanceDeliveredDate', 'deliveredDate');
	},

	getStatus () {
		return toLower(this.get('status'));
	},

	isClinicianOnly () {
		return !!this.get('assessmentInstanceClinicianOnly');
	},

	markAsSeen () {
		this.set('isSeen', true);
		const url = Backbone.config().backendRootUrl['default'] +
			urls.markAsSeen.url.call(this, appContext.get('user').get('userId'));

		return ajax({
			url,
			type: 'PUT'
		}).done(() => {
			appContext.trigger('assessment-instance.__update', this);
		});
	},

	eraseResponses () {
		const url = Backbone.config().backendRootUrl['default'] +
			urls.eraseResponses.url.call(this, this.getId());

		return ajax({
			url,
			dataType: 'text'
		}).then(() => {
			appContext.trigger('assessment-instance.__update', this);
		});
	}
});
