<template>
	<div :class="containerClassName">
		<button
			v-translate
			:disabled="!canSubmit"
			:class="buttonClassName"
			type="submit"
			@click="$emit('click')"
		>{{label}}</button>
	</div>
</template>

<script>
import Vue from 'vue';
import { className } from './_helpers';

const COMPONENT_NAME = 'submit-button';

export default Vue.component(COMPONENT_NAME, {
	props: {
		classPrefix: {
			type: String,
			default: ''
		},
		canSubmit: {
			type: Boolean,
			default: true
		},
		label: {
			type: String,
			default: 'Submit'
		},
		containerClass: {
			type: String,
			default: ''
		}
	},

	computed: {
		containerClassName: ({ className, containerClass }) => {
			const classes = [...className('field-container')];

			if (containerClass) {
				classes.push(className(containerClass));
			}
			return classes;
		},

		buttonClassName: ({ className }) => [
			...className('button'),
			...className('button--submit')
		]
	},

	methods: {
		className: className(COMPONENT_NAME)
	}
});
</script>
