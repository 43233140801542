<template>
	<bar-chart
		:params="params"
		:options="options"
		:threshold-line="config.thresholdLine"
		:threshold-range="config.thresholdRange"
	/>
</template>

<script>
import { assign, get, isUndefined, map, filter, includes } from 'lodash';
import { mapGetters } from 'vuex';
import assessmentValueChart from '../../shared/assessment-value-chart';

export default {
	...assessmentValueChart(),

	actions: (tile) => [[
		'assessmentValueBarSeries/getValues',
		assign({}, tile.config()),
		{},
		{ instant: true }
	]],
	data: ({ tile }) => ({
		config: tile.config()
	}),
	computed: {
		...mapGetters('assessmentValueBarSeries', ['valuesByRef']),
		options: ({ config }) => ({
			seriesBarDistance: 30,
			low: config.min,
			high: config.max,
			height: config.chartHeight,
			horizontalLabels: config.horizontalLabels
		}),

		// mapping tile config and data from backend by reference name ('ref')
		// INPUT [{ref: 'val1', value: 2}, {ref: 'val2', value: 4}]
		// [['val2', 'val1']]
		// output [[4, 2]]
		series: ({ config, valuesByRef }) => map(config.series, (singleSet) => map(
			singleSet,
			(ref) => get(valuesByRef[ref], 'value')
		)),

		params: ({ config, series }) => ({
			dataColors: config.dataColors,
			labels: config.groupLabels,
			legendNames: config.legendNames,
			series,
			thresholdLineColors: config.thresholdLineColors,
			thresholdRangeColors: config.thresholdRangeColors
		})
	},
	created () {
		!isUndefined(this.config.title) && this.tile.setTitle(this.config.title);
		// disable tile if all the values are null
		const checkValues = filter(this.series, (value) => !includes(value, null));
		!checkValues.length && !this.config.showIfEmpty && this.tile.disable();
	}
};
</script>
