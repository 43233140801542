import appBar from 'app-bar';
import t from 'translate';
import store from 'store';
import { TREATMENT } from 'store/treatments/rp-treatment';

const card = {
	title: t('Messages'),
	name: 'messages',
	url: 'treatment/:treatmentId/messages',

	ctrl: ({ populate }) => {
		if (!store.state.user.userSettings['respondent.disable-communication']) {
			appBar.addCardLink({
				sourceCard: card.name,
				card: 'new-message',
				title: t('New message'),
				icon: 'add',
				query: {
					treatmentId: store.getters[TREATMENT.ID]
				}
			});
		}

		populate();
	}
};

export { card as Messages };
