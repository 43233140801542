import repository from 'repository';
import { find, get, groupBy, map, transform } from 'lodash';
import { when } from 'jquery';
import { TREATMENT } from 'store/treatments/rp-treatment';

// deal with long property names
const CONTEXT_ID = 'assessmentInstanceContextId';
const LAST_MODIFIED = 'assessmentInstanceLastModified';

export default {
	namespaced: true,

	state: {
		data: [],
		groupingData: [],
		types: [],
		group: false,
		all: [],
		__hack__reportsList: []
	},

	getters: {
		__hack__reportsList: (state) => state.__hack__reportsList.length,

		allCount: (state) => state.all.length,

		all: (state) => map(state.data, (report) => {
			const GROUP_ID = {
				context: CONTEXT_ID,
				treatment: 'id'
			}[state.group];
			const REPORT_GROUP_ID_PATH = {
				context: CONTEXT_ID,
				treatment: 'treatment.id'
			}[state.group];
			const CONTEXT_LABEL = {
				context: 'assessmentInstanceContextLabel',
				treatment: 'name'
			}[state.group];

			return {
				reportId: +report.id,
				assessmentInstanceId: +report.assessmentInstanceId,
				contextId: +report[CONTEXT_ID],
				title: report.title,
				lastModified: report[LAST_MODIFIED].date,
				groupLabel: get(find(
					state.groupingData,
					// b💩💩💩ckend -> id is sometimes a [String] and sometimes is [Number]
					(item) => +item[GROUP_ID] === +get(report, REPORT_GROUP_ID_PATH)
				), CONTEXT_LABEL)
			};
		}),

		grouped: (state, getters) => {
			const grouped = groupBy(getters.all, 'groupLabel');

			// { a: arr1, b: arr2 } ➡ [{ label: 'a', reports: arr1 }, { label: 'b', reports: arr2 }]
			return transform(grouped, (result, value, key) => {
				result.push({
					label: key === 'undefined' ? '' : key,
					reports: value
				});
			}, []);
		}
	},

	mutations: {
		set: (state, { reports, groupingData }) => {
			state.data = reports;
			state.groupingData = groupingData;
		},

		setGrouping: (state, group) => {
			state.group = group;
		},

		setSorting: (state, sort) => {
			state.sort = sort;
		},

		setAll: (state, reports) => {
			state.all = reports;
		},

		__hack__setReportsList: (state, reports) => {
			state.__hack__reportsList = reports;
		}
	},

	actions: {
		// used in Respondent Portal
		initForCurrentRespondent: (
			{ commit, rootGetters },
			{
				group = false,
				sortDirection = false,
				groupByLatest = false,
				treatmentId = rootGetters[TREATMENT.ID]
			} = {}
		) => {
			const reports = repository.getReports({
				sortDirection,
				group,
				groupByLatest,
				treatmentId
			});
			const method = {
				context: 'getContexts',
				treatment: 'treatments'
			}[group];

			const groupingData = group ?
				repository[method]() :
				{ toJSON: () => [] };

			commit('setGrouping', group);

			return when(reports, groupingData).then(() => {
				commit('set', {
					reports: reports.toJSON(),
					groupingData: groupingData.toJSON()
				});
			});
		},

		// used in Respondent Portal
		initAllForCurrentRespondent: ({ commit, rootGetters }, {
			treatmentId = rootGetters[TREATMENT.ID]
		} = {}) => {
			const reports = repository.getReports({
				treatmentId
			});

			reports.then(() => {
				commit('setAll', reports.toJSON());
			});

			return reports;
		},

		/* Used in Respondent Portal.
		 * This one is a special case, do not duplicate this pattern, no matter the circumstances.
		 * It's in the store only for a convenience reasons. If need for getting tile config outside
		 * the tile will resurface, then a service for doing just that should be created, not
		 * another store action & getter.
		 */
		__hack__initFromReportsList: ({ commit, rootGetters }) => {
			const treatmentId = rootGetters[TREATMENT.ID];
			const CARD_NAME = 'reports-list';
			const TILE_NAME = 'reports-list';

			const tiles = get(
				find(rootGetters['engine/cards/current'], { 'card-name': CARD_NAME }),
				'tiles',
				[]
			);

			const { group = false, groupByLatest = false } = get(
				find(tiles, { tileName: TILE_NAME }),
				'tileConfig',
				{}
			);

			return repository.getReports({ group, groupByLatest, treatmentId }).then((reports) => {
				commit('__hack__setReportsList', reports);
			});
		}
	}
};
