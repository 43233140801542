import repository from 'repository';
import { cleanAssessmentAttribute } from '../__helpers/clean';
import { map } from 'lodash';
import { set } from '../__helpers/mutations';
import translateItems from '../__helpers/translate-items';

export default {
	namespaced: true,

	state: {
		data: []
	},

	getters: {
		list: (state) => map(state.data, cleanAssessmentAttribute),

		translatedList: (state, getters) => translateItems({
			items: getters.list,
			prefix: 'backendDictionary.'
		})
	},

	mutations: {
		set: set()
	},

	actions: {
		init: ({ commit }) => repository.getAssessmentFields().then((response) => {
			commit('set', response);
		})
	}
};
