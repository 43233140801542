import appContext from 'app-context';

const AppTitle = function () {
	let appTitle = '';
	let appName = '';
	let appSection = '';

	const construct = function () {
		let glue = ' ';

		if (!appSection || appSection.length < 1) {
			appSection = '';
			glue = '';
		}
		appTitle = appSection + glue + appName;
		return appTitle;
	};

	const render = () => {
		document.title = construct();
	};

	this.setAppName = function (newAppName) {
		appName = `(${newAppName})`;
		render();
		return this;
	};

	this.setAppSection = function (newAppSection) {
		appSection = newAppSection;
		render();
		return this;
	};

	appContext.on('title.change', (newAppSection) => {
		this.setAppSection(newAppSection);
	});
};

export default new AppTitle();

