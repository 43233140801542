import AssessmentAutospawn from '../../entities/assessment-autospawn';
import cardUrl from 'cwcardurl';
import AttachmentsButton from 'components/attachments-button/attachments-button';
import appBar from 'app-bar';
import appContext from 'app-context';
import { forEach, noop, toLower } from 'lodash';
import cwalert from 'cwalert';
import t from 'translate';
import store from 'store';
import { TREATMENT } from 'store/treatments/rp-treatment';

const handleError = (err, close) => {
	if (err.responseText) {
		const msg = toLower(JSON.parse(err.responseText).exception.msg);
		cwalert.error(t(`backendDictionary.errors.${msg}`));
	} else {
		cwalert.error(t(`Error`));
	}
	close();
};

const openAction = ({ id, cardData, close }) =>
	import(`../../entities/assessment-runtime-instance`)
		.then(async (module) => {
			try {
				const AssessmentContentModel = module.default;
				const assessmentContent = new AssessmentContentModel({ assessmentInstanceId: id });

				await assessmentContent.fetch();

				cardData.set({
					'assessment-model': assessmentContent,
					'assessmentInstanceId': id,
					'roleSummary': assessmentContent.get('roleInfo')
				});

				if (assessmentContent.get('instructionFiles').length) {
					const attachmentsButton = new AttachmentsButton({ model: assessmentContent });
					appBar.add('assessment', attachmentsButton, { order: 1 });
				}
			} catch (err) {
				handleError(err, close);
			}
		});

const spawnAction = async ({
	autospawnId,
	navigate,
	cardData,
	fieldName = 'identifier',
	close
}) => {
	try {
		const referral = 'autospawn';
		const autospawn = new AssessmentAutospawn();
		await autospawn.save({ [fieldName]: autospawnId });

		navigate(cardUrl.buildUrl('assessment', {
			id: autospawn.get('assessmentInstanceId'),
			treatmentId: store.getters[TREATMENT.ID],
			referral
		}));

		cardData.set({ referral });

		await openAction({
			id: autospawn.get('assessmentInstanceId'),
			cardData
		});

		appContext.trigger('assessment.autospawn');

	} catch (err) {
		handleError(err, close);
	}
};

const exerciseAction = async ({ exercise, navigate, cardData, close }) => {
	try {
		const autospawn = new AssessmentAutospawn();

		await spawnAction(
			{ autospawnId: exercise, navigate, cardData, fieldName: 'exercise' }
		);
		// trigger custom exercise event when assessment is exercise (used in respondent portal)
		appContext.trigger(
			'exercise.autospawn',
			autospawn.get('informationPackageElementInstanceId')
		);
	} catch (err) {
		handleError(err, close);
	}
};

const card = {
	name: 'assessment',
	url: [
		'treatment/:treatmentId/assessment/:id/open',
		'treatment/:treatmentId/assessment/:id/open?ref=:referral',
		'autospawn,:autospawnId',
		'treatment/:treatmentId/exercise/:exercise',
		'assessment/:id/open',
		'assessment/:id/open?ref=:referral',
		'exercise/:exercise'
	],
	title: 'Assessment',

	ctrl: async (
		{ cardConfig, cardData, navigate, populate, close },
		{ id, autospawnId, exercise, treatmentId, referral }
	) => {
		cardData.set({
			referral,
			treatmentId,
			openNextAssessment: cardConfig.openNextAssessment
		});

		let fn = noop;

		const routes = [{
			when: id,
			then: openAction
		}, {
			when: autospawnId,
			then: spawnAction
		}, {
			when: exercise,
			then: exerciseAction
		}];

		forEach(routes, (route) => {
			if (route.when) {
				fn = route.then;
			}

			return !route.when;
		});

		await fn({ id, autospawnId, exercise, navigate, cardData, close });

		populate();
	}
};

export default card;
