import repository from 'repository';
import AbuseReport from 'repo/circles/abuse-report';
import statuses from './abuse-report-statuses';
import { push } from './helpers';
import { assign, find, pick } from 'lodash';

export default {
	namespaced: true,

	modules: { statuses },

	state: {
		current: [],
		currentReport: {
			circlePost: {},
			model: {}
		},
		currentIdentity: {}
	},

	getters: {
		currentUser: (state) => state.currentReport.circleUser
	},

	mutations: {
		set: (state, { reports }) => {
			state.current = reports;
		},

		add: (state, { reports }) => {
			push({ from: reports, to: state.current });
		},

		updateReport: (state, { report }) => {
			assign(find(state.current, { id: report.id }), report);
		},

		setCurrentReport: (state, { model, report }) => {
			state.currentReport = report;
			state.currentReport.model = model;
		},

		setBanned: (state, { isBanned }) => {
			state.currentReport.circleUser.isBanned = isBanned;
		},

		deletePost: (state) => {
			// @TODO(2017-04-18) Handle removal as it is done in backend (not available now)
			state.currentReport.circlePost.isDeleted = true;
		},

		setCurrentIdentity: (state, { user }) => {
			state.currentIdentity = user;
		}
	},

	actions: {
		init: ({ commit }, { limit }) => {
			const collection = repository.circleAbuseReports({ start: 0, limit });

			return collection.then(() => {
				commit('set', { reports: collection.toJSON() });
			});
		},

		initSingle: ({ commit }, { id }) => {
			const report = new AbuseReport({ id });

			return report.retrieve().then(() => {
				commit('setCurrentReport', { model: report, report: report.toJSON() });
			});
		},

		setStatus: ({ commit }, { report, status }) => {
			assign(report, { status });
			commit('updateReport', { report: pick(report, 'id', 'status') });

			return new AbuseReport(report).save();
		},

		setResolved: ({ commit }, { report }) => {
			commit('updateReport', { report: pick(report, 'id', 'isResolved') });

			return new AbuseReport(report).save();
		},

		banCurrentUser: ({ commit, getters }) => {
			commit('setBanned', { isBanned: true });
			return repository.banCircleUser(getters.currentUser.id);
		},

		unBanCurrentUser: ({ commit, getters }) => {
			commit('setBanned', { isBanned: false });
			return repository.unBanCircleUser(getters.currentUser.id);
		},

		deletePost: ({ commit }, { report }) => {
			commit('deletePost');
			return repository.deleteAbuseReportPost(report.id);
		},

		revealIdentity: ({ commit }, { report }) => {
			const user = repository.revealAbuseAuthorIdentity(report.id);
			return user.then(() => {
				commit('setCurrentIdentity', { user: user.toJSON() });
			});
		},

		add: ({ commit }, { reports, collection }) => {
			commit('add', { reports, collection });
		}
	}
};
