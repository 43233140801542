import SingleEntity from '../shared/single-entity';
import urls from './urls';

export default SingleEntity.extend({
	idAttribute: 'AssessmentQuestionId',
	eventNamespace: 'assessment-question',
	urlRoot: urls.question.url,

	getName () {
		return `[${this.get('assessmentQuestionCode')}] ${this.get('assessmentQuestionLabel')}`;
	}
});
