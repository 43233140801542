var render = function(_h, _vm) {
  var _c = _vm._c
  return _vm.props.link
    ? _c(
        "a",
        {
          staticClass: "mini-value-tile__link mini-value-tile__container",
          attrs: { href: _vm.props.link }
        },
        [_vm._t("default")],
        2
      )
    : _c(
        "div",
        { staticClass: "mini-value-tile__container" },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }