<template>
	<text-input
		v-model="search"
		icon="search"
		:label="$t('table.searchLabel')"
		class="table-view__search"
	/>
</template>

<script>
import TextInput from 'components/form/text-input';

export default {
	components: { TextInput },

	props: {
		value: {
			type: String,
			default: ''
		}
	},

	computed: {
		search: {
			get: ({ value }) => value,
			set (newVal) {
				this.$emit('input', newVal);
			}
		}
	}
};
</script>
