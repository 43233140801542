import lang from 'service/lang/lang';
import i18next from 'i18next';
import executeIfFunction from 'execute-if-function';
import { isString, isUndefined } from 'lodash';

/**
 * Returns the translation of the message.
 * @function t
 * @param {string} message
 * @param {object} [variables] variables used to render the string if the string contains any
 *     variables
 */

export default function (message, variables) {
	const translate = () =>
		isString(message) &&
		i18next.isInitialized() &&
		isUndefined(variables) &&
		i18next.exists(message) ?
			i18next.t(message) :
			executeIfFunction(lang.get(message, message, variables));

	return translate() || translate;
}
