import { EMPTY_CHAR } from 'lib/chars';
import datetime from 'service/datetime/datetime';
import t from 'translate';

/**
 * @param {string} date     - Input date.
 * @param {string} timezone - Timezone name.
 * @returns {string}        - Formatted date.
 * @example
 * 		formatDate('2019-01-01T01:01', 'Europe/Warsaw');
 */
export default  (date = '', timezone = '') => date && timezone ?
	datetime(date).setTimezone(timezone).toMoment().format(t('date.formats.dateTime')) :
	EMPTY_CHAR;
