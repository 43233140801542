import repository from 'repository';
import { set, update } from '../__helpers/mutations';
import { RESPONDENT } from 'store/respondents/respondent';
import { TREATMENT } from 'store/treatments/rp-treatment';
import prefixify from '../__helpers/prefixify';
import { exact } from '../__helpers/getters';
import { find } from 'lodash';
import { STATE_COMPLETED } from 'modules/respondent-information-package/constants';

const prefix = prefixify('respondentMedia');

const DATA = 'data';
const SIZE = 'size';

const INIT = 'init';
const COMPLETE = 'complete';

const SET = 'set';
const UPDATE = 'update';

export const RESPONDENT_MEDIA = {
	DATA: prefix(DATA),
	SIZE: prefix(SIZE),

	INIT: prefix(INIT),
	COMPLETE: prefix(COMPLETE)
};

export default {
	namespaced: true,

	state: {
		[DATA]: []
	},

	getters: {
		...exact([DATA]),
		[SIZE]: (state) => state[DATA].length
	},

	mutations: {
		[SET]: set(),
		[UPDATE]: update()
	},

	actions: {
		[INIT]: async ({ commit, rootGetters }) => {
			const media = await repository.getAssignedMedia({
				respondentId: rootGetters[RESPONDENT.RESPONDENT_ID],
				treatmentId: rootGetters[TREATMENT.ID]
			});
			commit(SET, media);
		},
		[COMPLETE]: async (
			{ commit, state },
			{ contentPackageId, elementId, transform = true }
		) => {
			await repository.elementMarkAsCompleted({ contentPackageId, elementId });

			if (transform) {
				const media = find(state[DATA], { id: elementId });
				media.state = STATE_COMPLETED;
				commit(UPDATE, { item: media });
			}
		}

	}
};
