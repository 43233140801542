import { GET, PUT } from 'service/ajax/ajax';
import urls from './urls';

export default {
	/**
	 * @param {object} params - Params.
	 * @param {object} params.respondentId - Respondent Id.
	 * @param {object} params.treatmentId - Treatment Id.
	 * @returns {object} Promise.
	 * @example
	 * 	Repository.getContentPackages(params);
	 */
	getContentPackages: ({ respondentId, treatmentId }) => GET({
		url: urls.contentPackages({ respondentId, treatmentId })
	}),

	/**
	 * Get all Exercises.
	 *
	 * @returns {object} Promise.
	 * @example
	 *        Repository.getExercises(params);
	 */
	getExercises: ({ respondentId, treatmentId }) => GET({
		url: urls.elements({ respondentId, treatmentId, type: 'EXERCISE' })
	}),

	/**
	 * Get all assigned Pages.
	 *
	 * @function getAssignedPages
	 * @returns {object} Promise.
	 * @example
	 *        Repository.getAssignedPages(params);
	 */
	getAssignedPages: ({ respondentId, treatmentId }) => GET({
		url: urls.elements({ respondentId, treatmentId, type: 'PAGE' })
	}),

	/**
	 * Get all assigned Media.
	 *
	 * @function getAssignedMedia
	 * @returns {object} Promise.
	 * @example
	 *        Repository.getAssignedMedia(params);
	 */
	getAssignedMedia: ({ respondentId, treatmentId }) => GET({
		url: urls.elements({ respondentId, treatmentId, type: 'MEDIA' })
	}),

	/**
	 * Get all assigned Links.
	 *
	 * @function getAssignedLinks
	 * @returns {object} Promise.
	 * @example
	 *        Repository.getAssignedLinks(params);
	 */
	getAssignedLinks: ({ respondentId, treatmentId }) => GET({
		url: urls.elements({ respondentId, treatmentId, type: 'LINK' })
	}),

	/**
	 * @param {number} id - Content package id.
	 * @returns {object} Promise.
	 * @example
	 * 		repository.contentPackage(44);
	 */
	contentPackage: (id) => GET({
		url: urls.contentPackage(id)
	}),

	/**
	 * @param {object} params - Params.
	 * @param {number} params.cpInstanceId - Content package id.
	 * @param {number} params.respondentId - Respondent id.
	 * @returns {object} Promise.
	 * @example
	 * 		repository.getContentPackageElements(44);
	 */
	getContentPackageElements: ({ cpInstanceId, respondentId }) => GET({
		url: urls.byCPInstance({
			cpInstanceId,
			respondentId
		})
	}),

	/**
	 * @param {object} params - Params.
	 * @param {number} params.contentPackageId - Content package instance id.
	 * @param {number} params.elementId - Element Id.
	 * @returns {object} Promise.
	 * @example
	 *        repository.elementMarkAsCompleted(params);
	 */
	elementMarkAsCompleted: ({ contentPackageId, elementId }) => PUT({
		url: urls.complete({ contentPackageId, elementId })
	}),

	/**
	 * @param {object} params - Params.
	 * @param {number} params.contentPackageId - Content package instance id.
	 * @param {number} params.elementId - Element Id.
	 * @returns {object} Promise.
	 * @example
	 *        repository.contentPackageElement(params);
	 */
	contentPackageElement: ({ contentPackageId, elementId }) => GET({
		url: urls.contentPackageElement({ contentPackageId, elementId })
	})

};
