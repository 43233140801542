import t from 'translate';
import TileView from 'tile-view';
import Table from 'components/table/table';
import cardurl from 'cwcardurl';
import store from 'store';
import $ from 'jquery';
import { forEach } from 'lodash';
import enableCirclesCheck from '../../helpers/enable-circles-check';
import settings from 'system-settings';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints.json';
import listFields from '../../shared/list-fields';
import treatmentContextParam from 'modules/treatments/util/treatment-context-param';

export default TileView.extend({
	title: t('My circles'),
	acl: [{
		op: READ,
		checkpoint: CLINICIAN.CIRCLES.CIRCLES
	}],
	features: enableCirclesCheck,
	instant: true,

	loaded: ({ tile }) => {
		tile.buttons = [];
		const $container = $('<div class="search-results-list__container" />').appendTo(tile.$el);

		const emptyListMessage = () => {
			if (store.state.user.type === 'respondent' &&
				!settings.getBoolean('ENABLE_RESPONDENT_CREATE_CIRCLE')) {

				return t('You are not connected to any circles__ ' +
					'{joinCircleLinkStart}Join an existing circle{joinCircleLinkEnd}__', {
					joinCircleLinkStart: `<a href="${
						cardurl.buildUrl('circle-join', treatmentContextParam())
					}">`,
					joinCircleLinkEnd: '</a>'
				});
			}

			return t('You are not connected to any circles__ ' +
				'{createCircleLinkStart}Create your own circle{createCircleLinkEnd} or ' +
				'{joinCircleLinkStart}join an existing circle{joinCircleLinkEnd}__', {
				createCircleLinkStart: `<a href="${
					cardurl.buildUrl('circle-create', treatmentContextParam())
				}">`,
				joinCircleLinkStart: `<a href="${
					cardurl.buildUrl('circle-join', treatmentContextParam())
				}">`,
				createCircleLinkEnd: '</a>',
				joinCircleLinkEnd: '</a>'
			});
		};

		const table = new Table({
			items: store.getters['circles/my'],
			sortable: true,
			sortOrder: tile.config().tableSort,
			emptyListMessage: emptyListMessage(),
			click: (item) => {
				cardurl.openCard('circle', {
					circleId: item.id,
					...treatmentContextParam()
				});
			},
			parent: $container[0],
			columns: listFields(tile)
		});

		store.subscribe((mutation) => {
			if (mutation.type === 'circles/leave') {
				table.setItems(store.getters['circles/my']);
			}
		});
	},

	onRemove: ({ tile }) => {
		forEach(tile.buttons, (button) => {
			button.$destroy();
		});
	}
});
