import QueryParams from 'lib/url/query-params';
import Backbone from 'backbone';

/**
 * A helper. Builds custom urls.
 *
 * @class Url
 * @requires lib/url
 * @param {string} url - To start with.
 * @param {object} params - Will be appended to the baseUrl.
 * @param {object} [opts] - Options.
 * @param {boolean} [opts.prependBackendRoot] - (deprecated, ignored) use if the backend is
 * hosted on a different root url then the application.
 * @param {string} [opts.querySeparator] - Default is "?", you may want to use "#".
 * @author Marcin
 * @example
 * new Url()
 */
const Url = function (url, params, opts = {}) {

	const querySeparator = (opts.querySeparator === undefined) ? '?' : opts.querySeparator;

	/**
	 * @function Url#toString
	 * @returns {string} - String.
	 * @example
	 * Url.toString()
	 */
	this.toString = function () {
		if (params) {
			return Backbone.getBackendRoot()['default'] + url + querySeparator + new QueryParams(params);
		}
		return Backbone.getBackendRoot()['default'] + url;

	};

	/**
	 * Changes window.location to the url.
	 *
	 * @function Url#go
	 * @example
	 * Url.go()
	 */
	this.go = function () {
		window.location = `${this}`;
	};
};

export default Url;

