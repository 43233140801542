import { warning } from 'service/log/log';
import ValueReader from '../../../helpers/value-reader';
import BooleanResult from '../results/Boolean';
import DateResult from '../results/Date';
import NumberResult from '../results/Number';
import StringResult from '../results/string';
import { assign, constant, get, noop, replace } from 'lodash';

export default function (definition) {
	const type = replace(replace(definition.type, /^questionnaire:/, ''), /BasicType$/, '');

	/* debug */
	this.__debug = {
		raw: definition,
		type
	};

	let result;
	let value;

	assign(this, {
		getType: () => type,
		getClass: constant('Literal'),
		getInvolvedQuestionCodesMap: (questionMap) => questionMap
	});

	switch (type) {
	case 'String':
		value = new ValueReader(definition.value).getString();
		result = new StringResult(value);
		break;

	case 'Boolean':
		value = new ValueReader(definition.value).getBoolean();
		result = new BooleanResult(value);
		break;

	case 'Number':
		value = new ValueReader(definition.value).getFloat();
		result = new NumberResult(value);
		break;

	case 'Date':
		value = new ValueReader(definition.value).getDate();
		result = new DateResult(value);
		break;

	default:
		warning(`Literal type ${type} not implemented!`);
	}

	assign(this, {
		getBoolean: get(result, 'getBoolean', noop),
		getNumber: get(result, 'getNumber', noop),
		getString: get(result, 'getString', noop),
		toString: get(result, 'toString', noop),
		getValue: () => value,
		getRawValue: get(result, 'getRawValue', noop)
	});
}

