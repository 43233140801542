
import Messages from '../../shared/messages-tile';
import { TREATMENT } from 'store/treatments/rp-treatment';

export default Messages.extend({
	provideMarkAnswered: false,
	actions: ({ $store }) => [
		['messages/init', { treatmentId: $store.getters[TREATMENT.ID] }]
	]
});
