<template>
	<input
		v-model="checkedValue"
		:disabled="disabled"
		type="checkbox"
	/>
</template>

<script>
export default {
	model: {
		prop: 'checked',
		event: 'change'
	},

	props: {
		checked: {
			type: Boolean,
			default: false
		},

		disabled: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		checkedValue: {
			get () {
				return this.checked;
			},

			set (value) {
				this.$emit('change', value);
			}
		}
	}
};
</script>
