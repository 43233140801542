<template>
	<modal-dialog
		:show="show"
		:on-close="onClose"
		:loading="loading"
	>
		<template #title>
			<span v-translate>{{title}}</span>
		</template>

		<template #content>
			<edit-goal
				:goal="goal"
				:treatments="treatments"
				:categories="categories"
				:on-submit="onSubmit"
				:on-cancel="onClose"
			/>
		</template>
	</modal-dialog>
</template>

<script>
import DialogMixin from './__dialog-mixin';
import EditGoal from './edit-goal';

export default {
	name: 'GoalDialog',
	components: { EditGoal },
	mixins: [DialogMixin],
	props: {
		goal: {
			type: Object,
			required: true
		},
		treatments: {
			type: Array,
			required: true
		},
		categories: {
			type: Array,
			required: true
		}
	}
};
</script>
