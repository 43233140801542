import { assign, isNil } from 'lodash';

const ValueReader = function (val, defaultValue) {
	const value = isNil(val) ? defaultValue : val;

	assign(this, {
		getBoolean: () => (value === true) || (value === 'true') || (value === 1),
		getInteger: () => parseInt(value, 10),
		getFloat: () => parseFloat(value),
		getString: () => value || '',
		getDate: () => new Date(value)
	});
};

export default ValueReader;

