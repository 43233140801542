import SingleEntity from '../shared/single-entity';
import urls from './urls';
import datetime from 'datetime';
import t from 'translate';

export default SingleEntity.extend({
	urlRoot: urls.uploadedFile,
	idAttribute: 'uploadFileId',

	getDownloadURL (id) {
		return `backend-commons/assessmentinstance/rest/upload-file/download/${
			id || this.getId()
		}`;
	},

	getFileName () {
		return this.get('uploadFileName');
	},

	getFileSize (formatted) {
		if (formatted) {
			if (this.get('uploadSize')) {
				return `${Math.round(this.get('uploadSize') / 1024)}kB`;
			}
			return '';
		}
		return this.get('uploadSize') || 0;
	},

	getFileDate (formatted) {
		if (formatted) {
			if (!this.get('uploadDate')) {
				return null;
			}
			return datetime(this.get('uploadDate'))
				.toMoment()
				.format(t('date.formats.dateTime'));
		}
		return this.get('uploadDate') || null;
	}
});
