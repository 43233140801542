import { get } from 'lodash';
import t from 'service/lang/translate';

export default {
	props: {
		tile: {
			type: Object,
			required: true
		}
	},
	methods: {
		get,
		t,
		setLoading () {
			this.tile.setLoading();
		},
		setLoaded () {
			this.tile.setLoaded();
		},
		setDataLoading () {
			this.tile.setDataLoading();
		},
		setDataLoaded () {
			this.tile.setDataLoaded();
		},
		closeCard (params) {
			this.tile.cardContext().trigger('card:close', params);
		}
	}
};
