<template>
	<div v-if="cpElement.exercises.length">
		<a
			v-if="isFinished"
			:href="mainUrl"
			class="exercise__run flat-button__button flat-button__button--submit"
		>
			{{$t('Repeat exercise')}}
		</a>
		<a
			v-else
			:href="mainUrl"
			class="exercise__run flat-button__button flat-button__button--submit"
		>
			{{$t('Finish exercise')}}
		</a>
		<ul class="generic-list__list exercise__list">
			<li
				v-for="exercise in cpElement.exercises"
				:key="exercise.id"
				:class="[
					`generic-list__item`,
					`exercise__list-element`,
					`exercise__list-element--${exercise.status}`
				]"
			>
				<a
					:href="exerciseUrl(exercise)"
					class="generic-list__action exercise__list-element-action"
				>
					<icon
						name="exercise"
						:title="$t('Exercise')"
						class="exercise__icon"
					/>
					<div
						class="
							generic-list__action-title-container
							exercise__action-title-container
						"
					>
						<p class="generic-list__action-title exercise__creation-date">
							{{createdAt(exercise)}}
						</p>
						<span class="exercise__status">
							{{statusLabel(exercise)}}
						</span>
					</div>
				</a>
			</li>
		</ul>
	</div>
	<div v-else>
		<a
			:href="mainUrl"
			class="exercise__run flat-button__button flat-button__button--submit"
		>
			{{$t('Start exercise')}}
		</a>
		<p class="exercise__empty-list-notify">
			{{$t('You haven’t done this exercise yet.')}}
		</p>
	</div>
</template>

<script>
import Icon from 'components/icon';
import {
	RESPONDENT_CONTENT_PACKAGE_ELEMENT
} from 'store/content-packages/rp-content-package-element';
import { reject } from 'lodash';
import datetime from 'datetime';
import cardUrl from 'cwcardurl';
import {
	STATE_COMPLETED,
	STATE_IN_PROGRESS
} from 'modules/respondent-information-package/constants';
import t from 'translate';
import { TREATMENT } from 'store/treatments/rp-treatment';

export default {
	title: t('Exercise'),
	actions: ({ $store }) => ([
		[RESPONDENT_CONTENT_PACKAGE_ELEMENT.INIT, {
			contentPackageId: $store.getters.urlParams.informationPackage,
			elementId: $store.getters.urlParams.id
		}]
	]),
	components: { Icon },
	computed: {
		cpElement: ({ $store }) => $store.getters[RESPONDENT_CONTENT_PACKAGE_ELEMENT.DATA],
		isFinished: ({ cpElement }) => cpElement.exercises ?
			reject(cpElement.exercises, { isSubmitted: true }).length === 0 :
			false,
		mainUrl: ({ cpElement, $store }) => cardUrl.buildUrl('exercise-run', {
			informationPackage: cpElement.instance.id,
			id: cpElement.id,
			treatmentId: $store.getters[TREATMENT.ID]
		})
	},
	methods: {
		statusLabel (exercise) {
			return {
				IN_PROGRESS: this.$t('In progress'),
				COMPLETED: this.$t('Completed')
			}[exercise.isSubmitted ? STATE_COMPLETED : STATE_IN_PROGRESS];
		},
		createdAt (exercise) {
			return datetime(exercise.createdAt)
				.setTimezone(this.$store.getters.userTimezone)
				.toMoment()
				.format(this.$t('date.formats.dateTime'));
		},
		exerciseUrl (exercise) {
			return cardUrl.buildUrl('assessment', {
				id: exercise.id,
				treatmentId: this.$store.getters[TREATMENT.ID]
			});
		}
	}
};
</script>
