var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("submit-button", {
    attrs: {
      label: "table.clear",
      "can-submit": _vm.canClear,
      "class-prefix": _vm.classPrefix
    },
    on: {
      click: function($event) {
        return _vm.$emit("click")
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }