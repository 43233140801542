import TreatmentTypes from './entities';

import urls from './urls';
import { DELETE, GET, POST, PUT } from 'service/ajax/ajax';
import { assign } from 'lodash';

/**
 * @class Repository
 */
export default {

	/**
	 * Add a single clinician group.
	 *
	 * @function addTreatmentTypeClinicianGroup
	 * @param {object} data - Parameters.
	 * @param {string} data.id - Id of a clinician group.
	 * @param {string} data.treatmentTypeId - Id of a treatment type.
	 * @example
	 *        repository.addTreatmentTypeClinicianGroup({id: 1, treatmentTypeId: 1});
	 */
	addTreatmentTypeClinicianGroup: (data) => PUT({
		url: urls.treatmentTypeClinicianGroup(data)
	}),

	/**
	 * Add a single TreatmentTypeRoleSets.
	 *
	 * @function addTreatmentTypeRoleSets
	 * @param {object} data - Parameters.
	 * @param {string} data.id - Id of a role set.
	 * @param {string} data.treatmentTypeId - Id of a treatment type.
	 * @example
	 *        repository.addTreatmentTypeRoleSets({id: 1, treatmentTypeId: 1});
	 */
	addTreatmentTypeRoleSets: (data) => POST({
		url: urls.treatmentTypeRoleSet(data)
	}),

	/**
	 * Delete a single treatment type.
	 *
	 * @function deleteTreatmentType
	 * @param {string} id - Id of a clinician group.
	 * @example
	 *        repository.deleteTreatmentType({id: 1});
	 */
	deleteTreatmentType: (id) => DELETE({
		url: urls.treatmentType(id)
	}),

	/**
	 * Delete a single clinician group.
	 *
	 * @function addTreatmentTypeClinicianGroup
	 * @param {object} data - Parameters.
	 * @param {string} data.id - Id of a clinician group.
	 * @param {string} data.treatmentTypeId - Id of a treatment type.
	 * @example
	 *        repository.deleteTreatmentTypeClinicianGroup({id: 1, treatmentTypeId: 1});
	 */
	deleteTreatmentTypeClinicianGroup: (data) => DELETE({
		url: urls.treatmentTypeClinicianGroup(data)
	}),

	/**
	 * Delete a single TreatmentTypeRoleSet.
	 *
	 * @function addTreatmentTypeRoleSets
	 * @param {object} data - Parameters.
	 * @param {string} data.id - Id of a role set.
	 * @param {string} data.treatmentTypeId - Id of a treatment type.
	 * @example
	 *        repository.deleteTreatmentTypeRoleSets({id: 1, treatmentTypeId: 1});
	 */
	deleteTreatmentTypeRoleSets: (data) => DELETE({
		url: urls.treatmentTypeRoleSet(data)
	}),

	/**
	 * Get all TreatmentTypes.
	 *
	 * @function treatmentTypes
	 * @returns {object} - TreatmentTypes.
	 * @example
	 *        repository.treatmentTypes();
	 */
	treatmentTypes: () => TreatmentTypes.getAll(),

	/**
	 * Get all TreatmentTypes for a currently logged-in clinician.
	 *
	 * @function treatmentTypesForCurrentClinician
	 * @returns {object} - TreatmentTypes.
	 * @example
	 *        repository.treatmentTypes();
	 */
	treatmentTypesForCurrentClinician: () => TreatmentTypes.getForCurrentClinician(),

	/**
	 * Get TreatmnetType by id.
	 *
	 * @function getTreatmentTypeById
	 * @param {number|string} id - Treatment type id.
	 * @returns {object} - TreatmentType.
	 * @example
	 *        repository.getTreatmentTypeById(1);
	 */
	getTreatmentTypeById: (id) => TreatmentTypes.getById(id),

	/**
	 * Get TreatmentTypeAttributes by their TreatmentType id.
	 *
	 * @function treatmentTypeAttributes
	 * @param {number|string} treatmentTypeId - Treatment type id.
	 * @returns {object} - TreatmentType.
	 * @example
	 *        repository.treatmentTypeAttributes(1);
	 */
	treatmentTypeAttributes: (treatmentTypeId) =>
		TreatmentTypes.getById(treatmentTypeId).getAttributes(),

	/**
	 * Get TreatmentTypeClinicianGroups by their TreatmentType id.
	 *
	 * @function getTreatmentTypeClinicianGroups
	 * @param {number|string} treatmentTypeId - Treatment type id.
	 * @returns {object} - TreatmentType.
	 * @example
	 *        repository.getTreatmentTypeClinicianGroups(1);
	 */
	getTreatmentTypeClinicianGroups: (treatmentTypeId) =>
		TreatmentTypes.getById(treatmentTypeId).getClinicianGroups(),

	/**
	 * Get TreatmentTypeRoles by their TreatmentType id.
	 *
	 * @function getTreatmentTypeRoles
	 * @param {number|string} treatmentTypeId - Treatment type id.
	 * @returns {object} - TreatmentType.
	 * @example
	 *        repository.getTreatmentTypeRoles(1);
	 */
	getTreatmentTypeRoles: (treatmentTypeId) =>
		TreatmentTypes.getById(treatmentTypeId).getRoles(),

	/**
	 * Get TreatmentTypeRoleSets by their TreatmentType id.
	 *
	 * @function treatmentTypeRoleSets
	 * @param {number|string} treatmentTypeId - Treatment type id.
	 * @returns {object} - TreatmentType.
	 * @example
	 *        repository.treatmentTypeRoleSets(1);
	 */
	treatmentTypeRoleSets: (treatmentTypeId) => {
		const url = urls.treatmentTypeRoleSets(treatmentTypeId);
		const request = GET({ url });
		const response = assign({}, { data: [] }, request.then((json) => {
			response.data = json;
			return request;
		}));
		return response;
	},

	/**
	 * Add a single treatment type.
	 *
	 * @function addTreatmentType
	 * @param {object} data - Parameters.
	 * @example
	 *        repository.addTreatmentType(data);
	 */
	addTreatmentType: (data) => POST({ url: urls.single.url, data }),

	/**
	 * Update a single treatment type.
	 *
	 * @function updateTreatmentType
	 * @param {object} data - Parameters.
	 * @example
	 *        repository.updateTreatmentType(data);
	 */
	updateTreatmentType: (data) =>
		PUT({ url: urls.treatmentType(data.id), data }),

	rawTreatmentTypeAttributes: (treatmentTypeId) => GET({
		url: urls.listAttributes.url(treatmentTypeId)
	})
};
