<template>
	<section v-if="addableItems.length" :class="mainClassName">
		<button
			:class="elementClassName('action')"
			:aria-expanded="menuToggled"
			@click="toggleMenu"
		>
			<icon :class="elementClassName('action-icon')" name="plus" />
			<span v-translate :class="elementClassName('action-text')">Add</span>
		</button>
		<transition name="fade">
			<ul
				v-if="menuToggled"
				v-on-click-outside="hideMenu"
				:class="elementClassName('items')"
			>
				<li v-for="(item, i) in addableItems" :key="i" :class="elementClassName('item')">
					<button :class="elementClassName('item-action')" @click="addItem(item)">
						<icon :name="item.icon" :class="elementClassName('item-icon')" />
						<span
							v-translate
							:class="elementClassName('item-text')"
						>{{title(item)}}</span>
					</button>
				</li>
			</ul>
		</transition>
	</section>
</template>

<script>
import Vue from 'vue';
import { requiredArray, optionalObject, optionalString } from './props';
import agendaMixin from './agenda-mixin';
import { filter, isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { AGENDA_ADD_ITEM } from './constants';

export default Vue.component(AGENDA_ADD_ITEM, {
	mixins: [agendaMixin],
	props: {
		datetime: optionalObject,
		items: requiredArray,
		scope: optionalString,
		type: optionalString
	},
	data: () => ({
		menuToggled: false
	}),

	computed: {
		mainClassName: ({ $options, type }) => [
			$options.name,
			type ? `${$options.name}--${type}` : ''
		],

		scopedItems: ({ datetime, items, scope }) => isEmpty(datetime) ?
			[] :
			filter(items, (item) => {
				const unit = {
					year: 'month',
					month: 'day',
					week: 'day',
					day: 'hour'
				}[scope];
				const nowStart = DateTime.local().startOf(unit);
				const nowEnd = DateTime.local().endOf(unit);

				return {
					past: true,
					now: nowEnd >= datetime && datetime >= nowStart,
					future: datetime >= nowStart
				}[item.timeScope];
			}),

		addableItems: ({ datetime, items, scopedItems }) => isEmpty(datetime) ? items : scopedItems
	},

	methods: {
		toggleMenu () {
			this.menuToggled = !this.menuToggled;
		},
		hideMenu () {
			this.menuToggled = false;
		},
		addItem (item) {
			item.action(this.datetime);
			this.hideMenu();
		},
		title (item) {
			return `agenda.type.${item.name}`;
		}
	}
});
</script>
