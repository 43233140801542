import SharedModel from './shared';
import { isObject, isUndefined } from 'lodash';

export default SharedModel.extend({
	defaults: {
		config: {
			valuesCount: 2
		}
	},

	onInitialize () {
		!isNaN(this.defaultLeftVal()) && this.set('leftVal', this.defaultLeftVal());
		!isNaN(this.defaultRightVal()) && this.set('rightVal', this.defaultRightVal());
	},

	defaultLeftVal () {
		return this.defaultValue('getDefaultLeftValue')();
	},

	defaultRightVal () {
		return this.defaultValue('getDefaultRightValue')();
	},

	defaultValues () {
		return [this.defaultLeftVal(), this.defaultRightVal()];
	},

	getResponse () {
		return {
			questionCode: this.get('id'),
			timeStamp: new Date().getTime(),
			responseType: 'range',
			range: this.values()
		};
	},

	setResponse (response) {
		if (!isObject(response.range)) {
			return;
		}
		/* B💩ckend returns emptied numeric range as `range: {min: 0, max: 0}` which is not true.
		 * Meaning such a case has to be treated as unanswered question.
		 */
		const minMax = !(response.range.min === 0 && response.range.max === 0) ?
			[response.range.min, response.range.max] :
			this.values();

		this.setValues(minMax);
	},

	onClear () {
		this.setValues([undefined, undefined]);
	},

	onUnclear ({ reset } = { reset: false }) {
		if (reset === true) {
			this.setValues([undefined, undefined]);

		} else if (isUndefined(this.value())) {
			this.setValues([this.defaultLeftVal(), this.defaultRightVal()], { defaultValue: true });
		}
	}
});
