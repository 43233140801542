import { sync } from 'backbone';
import { circle } from './urls';
import SingleEntity from '../shared/single-entity';
import t from 'translate';
import { isUndefined } from 'lodash';

export default SingleEntity.extend({
	urlRoot: circle.url,
	eventNamespace: 'circle',

	defaults: {
		description: ''
	},

	// backend accepts only boolean value for `exactMatch` prop, otherwise it's eternal sadness :(
	onSync (method, model) {
		const exactMatch = model.get('exactMatch');
		model.set('exactMatch', !!exactMatch);

		// eslint-disable-next-line prefer-rest-params
		return sync(...arguments);
	},

	validate: (attrs) => {
		const keys = [];
		const msgs = [];

		if (isUndefined(attrs.code) || attrs.code.length < 2) {
			keys.push('code');
			msgs.push(t('Circle code is too short'));
		}

		if (keys.length) {
			return {
				key: keys,
				msg: msgs
			};
		}
	}
});
