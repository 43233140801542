import repository from 'repository';
import Candidate from 'repo/circles/candidate';
import Member from 'repo/circles/member';
import posts from './posts';
import { toggleStatus } from './helpers';

export default {
	namespaced: true,

	modules: { posts },

	state: {
		data: {
			owner: {},
			categories: [],
			memberStatus: {}
		},
		model: {}
	},

	getters: {
		circle: (state) => state.data,
		isMember: (state) => state.data.memberStatus.isMember,
		isOwner: (state) => state.data.memberStatus.isOwner,
		profile: (
			state, getters, rootState, rootGetters // eslint-disable-next-line max-params
		) => rootGetters['circles/profile/profileData']
	},

	mutations: {
		set: (state, { circle, model }) => {
			state.data = circle;
			state.model = model;
		},

		clear: (state) => {
			state.data = {
				owner: {},
				categories: [],
				memberStatus: {}
			};
			state.model = {};
		},

		join: (state) => {
			state.data = toggleStatus({ circle: state.data, status: 'join' });
		},

		leave: (state) => {
			state.data = toggleStatus({ circle: state.data, status: 'leave' });
		}
	},

	actions: {
		init: ({ commit, dispatch, getters }, { circleId, limit }) => {
			const circle = repository.circle(circleId);

			return circle.then(() => {
				commit('set', { circle: circle.toJSON(), model: circle });
			}).then(() => {
				if (getters.isMember) {
					return dispatch('posts/init', {
						collection: repository.circlePosts({ circleId, start: 0, limit })
					});
				}
			});
		},

		update: ({ commit }, { model }) => commit('set', { model, circle: model.toJSON() }),

		join: ({ commit, state, getters }) => {
			const request = new Candidate({ userId: getters.profile.id, circleId: state.data.id });
			return request.save().then(() => {
				commit('join');
			});
		},

		leave: ({ commit, state, getters }) => {
			const member = new Member({ id: getters.profile.id, circle: state.data.id });
			return member.destroy().then(() => {
				commit('leave');
			});
		},

		remove: ({ state }) => state.model.destroy(),

		cancelRequest: ({ commit, state, getters }) => {
			const request = new Candidate({ userId: getters.profile.id, circleId: state.data.id });
			return request.destroy().then(() => {
				commit('leave');
			});
		},

		clear: ({ commit }) => commit('clear')
	}
};
