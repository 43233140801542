'use strict';

import Backbone from 'backbone';
import UploadedFileModel from './assessment-instance-uploaded-file';

const UploadedFilesList = Backbone.Collection.extend({
	name: 'AssessmentInstanceUploadedFiles',

	initialize: function (params) {
		this.url = 'backend-commons/assessmentinstance/rest/upload-file/by-assessment/' +
			params.assessmentInstanceId;
		if (params.assessmentInstanceCode) {
			this.url += '?code=' + params.assessmentInstanceCode;
		}
	},
	model: UploadedFileModel,

	getTotalFileSize: function (formatted) {
		var filesize = 0;
		this.each(function (model) {
			filesize += model.getFileSize();
		});

		if (formatted) {
			return Math.ceil(filesize / 1024) + 'kB';
		}
		return filesize;
	}
});

export default UploadedFilesList;
