import FormComponentView from '../form-component-view';
import { compile } from 'handlebars';
import $ from 'jquery';

const time = FormComponentView.extend({
	template: compile(`
		<label class="form-view__label-container">
			<p class="form-view__label-text">{{ label }}</p>
			<input
				type="time"
				class="form-view__input form-view__text form-view__time"
				name="{{ uniqueName }}"
				{{#if value}} value="{{ value }}" {{/if}}
				{{#if required}} required="required" {{/if}}
				{{#if readonly}} readonly="readonly" {{/if}}
				{{#if disabled}} disabled="disabled" {{/if}}
				{{#if min}} minlength="{{ min }}" {{/if}}
				{{#if max}} maxlength="{{ max }}" {{/if}}
				{{#if hint}} aria-describedby="{{ name }}-hint" {{/if}}
				{{#if noAutocomplete}} autocomplete="off" {{/if}} />
		</label>
		{{#if hint}}
			<div class="form-view__hint" id="{{ name }}-hint">{{ hint }}</div>
		{{/if}}
	`),

	onAfterRender () {
		const value = this.model.get('value');
		const jsDate = new Date();
		const timeString = value.length === 5 ? value : '00:00';
		jsDate.setHours(timeString.substring(0, 2), timeString.substring(3, 5));

		$(this.ui.input).cwtimepicker('setDate', jsDate);
	}
});

export { time };
