import cfg from 'core/config';

const customUrl = (name) => ({
	prepend: true,
	url: `backend-commons/custom-json/${name}-cards.json`
});
const localUrl = (name) => ({
	prepend: false,
	url: `${cfg().cardsDefinitionRoot + name}.json`
});

const user = 'backend-commons/custom-json/user.json';

const blueprintAssessment = localUrl('assessment');
const blueprintClinician = localUrl('clinician');
const blueprintRespondent = localUrl('respondent');
const blueprintAtomic = localUrl('atomic');

const customAssessment = customUrl('assessment');
const customClinician = customUrl('clinician');
const customRespondent = customUrl('respondent');
const customAtomic = customUrl('atomic');

const availableCards = localUrl('available-cards');
const availableTiles = localUrl('available-tiles');
const availableCardOptions = localUrl('available-card-options');
const availableTileOptions = localUrl('available-tile-options');

const features = localUrl('features');

export {
	availableCardOptions,
	availableCards,
	availableTileOptions,
	availableTiles,
	blueprintAssessment,
	blueprintAtomic,
	blueprintClinician,
	blueprintRespondent,
	customAssessment,
	customAtomic,
	customClinician,
	customRespondent,
	features,
	user
};
