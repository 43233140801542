import { get, size } from 'lodash';
import { parseStr } from './parse-str';

/**
 * @param {object} params        - Parameters.
 * @param {object} params.entity - Source entity.
 * @param {string} params.key    - Entity key that should be processed.
 * @param {object} params.vars   - Variables that should be applied.
 * @returns {*}           - Parsed entity key.
 * @example
 *        parseGet({ entity: { foo: 'b${a}r' }, key: 'foo', vars: { a: 'EAAAOO' }});
 *        -> 'bEAAAOOr'
 */
export const parseGet = ({ entity = {}, key = '', vars = {} }) => {
	const literal = /^'(.*)'$/.exec(key);

	if (literal) {
		return literal[1];
	}

	if (!size(vars)) {
		return get(entity, key);
	}

	const parsed = parseStr(key, vars);
	return get(entity, parsed, parsed);
};
