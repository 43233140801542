import { forEach, isArray, isFunction, isObject, isString, noop } from 'lodash';
import registerEvent from './register-event';
import tileRedraw from './tile-redraw';

/**
 * Register `appContext` event listeners.
 *
 * @function TileView.appEvents
 * @deprecated
 * @param {*} evts - Event listeners.
 * @example
 * // call `render` function on `respondent.add` or `clinician.remove` `appContext` events
 * export default TileView.extend({
 *     appEvents: 'respondent.add clinician.remove'
 * });
 *
 * @example
 * // call `render` function on `respondent.add` or `clinician.remove` `appContext` events
 * export default TileView.extend({
 *     appEvents: ['respondent.add', 'clinician.remove']
 * });
 *
 * @example
 * // call `render` function on `respondent.add` or `clinician.remove` `appContext` events
 * export default TileView.extend({
 *     appEvents: {
 *         'respondent.add': 'render',
 *         'clinician.remove': 'render'
 *     }
 * });
 *
 * @example
 * // call `render` function on `respondent.add` or `clinician.remove` `appContext` events
 * export default TileView.extend({
 *     appEvents: {
 *         'respondent.add' () {
 *             this.render();
 *         },
 *         'clinician.remove': {
 *             this.render();
 *         }
 *     }
 * });
 */
export default function (evts) {
	const tile = this;
	const events = isArray(evts) ? evts.join(' ') : evts;

	if (isString(events)) {
		registerEvent(tile, events, tileRedraw(tile));

	} else if (isObject(events)) {
		forEach(events, (eventAction, eventName) => {
			let fn = noop;

			if (isString(eventAction)) {
				fn = tile[eventAction];

			} else if (isFunction(eventAction)) {
				fn = eventAction;
			}
			registerEvent(tile, eventName, fn);
		});
	}
}
