import cardUrl from 'cwcardurl';
import repository from 'repository';
import settings from 'system-settings';
import { forEach } from 'lodash';
import store from 'store';
import { TREATMENT } from 'store/treatments/rp-treatment';
import handleOpenOption from 'modules/respondent/utils/handle-open-option';
import cardControls from 'core/engine/card/services/card-controls';
import { HOME_RESPONDENT } from 'modules/home/constants';

const assessmentUrl = ({
	assessmentInstanceId,
	contextTreatmentId,
	referral
}) => {
	const params = { id: assessmentInstanceId };

	if (contextTreatmentId) {
		params.treatmentId = contextTreatmentId;
	}

	if (referral) {
		params.referral = referral;
	}

	return cardUrl.buildUrl('assessment', params);
};

const enterTreatment = (treatmentId) => {
	if (!handleOpenOption({ treatmentId })) {
		cardControls.closeCard({
			openCard: HOME_RESPONDENT,
			cardQuery: { treatmentId }
		});
	}
};

const card = {
	name: 'latest-assessment',
	url: [
		'latest-assessment',
		'latest-assessment?ref=:referral',
		'treatment/:treatmentId/latest-assessment',
		'treatment/:treatmentId/latest-assessment?ref=:referral'
	],

	ctrl: ({ cardConfig, close }, { treatmentId, referral }) => {
		const contextTreatmentId = treatmentId;
		const config = cardConfig || {};
		const data = {
			limit: 1
		};

		if (config.firstAssessment) {
			forEach(config.firstAssessment, (val, key) => {
				data[`order[${key}]`] = val;
			});
		}

		if (contextTreatmentId) {
			data.treatmentId = contextTreatmentId;
		}

		const assessmentInstances = repository.getSortedAssessmentInstances(data);

		assessmentInstances.then(async () => {
			if (
				assessmentInstances.length &&
				settings.getBoolean('ENABLE_RESPONDENT_ASSESSMENTS')
			) {
				const assessmentInstance = assessmentInstances.at(0);
				await store.dispatch(TREATMENT.INIT, +assessmentInstance.get('treatment'));

				close({
					go: assessmentUrl({
						assessmentInstanceId: +assessmentInstance.get('assessmentInstanceId'),
						contextTreatmentId,
						referral
					})
				});
			} else {
				store.dispatch(TREATMENT.CLEAR);
				await store.dispatch(TREATMENT.INIT_DEFAULT);

				if (contextTreatmentId) {
					enterTreatment(contextTreatmentId);
					return;
				}

				close({ openCard: 'start' });
			}
		});
	}
};

export { card as respondentLatestAssessment };
