<template>
	<div class="tree-controls__buttons">
		<button
			v-tooltip.top-center="t(buttons.save)"
			:class="buttonClasses('save', false)"
			@click="save(localItems)"
		>
			<icon name="save" class-name="tree-controls__icon" />
		</button>
		<button
			v-if="copy.length"
			v-shortkey="['esc']"
			v-tooltip.top-center="t(buttons.copyCancel)"
			:class="buttonClasses('copy', !enabledCopy)"
			@click="performCopy(false)"
			@shortkey="performCopy(false)"
		>
			<icon name="copy-cancel" class-name="tree-controls__icon" />
		</button>
		<button
			v-if="!copy.length"
			v-shortkey="{ std: ['ctrl', 'c'], mac: ['meta', 'c'] }"
			v-tooltip.top-center="t(buttons.copy)"
			:disabled="enabledCopy"
			:class="buttonClasses('copy', enabledCopy)"
			@click="performCopy(true)"
			@shortkey="performCopy(true)"
		>
			<icon name="copy" class-name="tree-controls__icon" />
		</button>
		<button
			v-shortkey="{ std: ['ctrl', 'v'], mac: ['meta', 'v'] }"
			v-tooltip.top-center="t(buttons.paste)"
			:disabled="enabledPaste"
			:class="pasteClasses('paste', enabledPaste)"
			@click="performPaste"
			@shortkey="performPaste"
		>
			<icon name="paste" class-name="tree-controls__icon" />
		</button>
		<button
			v-shortkey="{ std: ['ctrl', 's'], mac: ['meta', 's'] }"
			v-tooltip.top-center="t(buttons.download)"
			:class="buttonClasses('download', !enableDownload)"
			class="tree-controls__buttons-item"
			:disabled="!enableDownload"
			@click="download"
			@shortkey="download"
		>
			<icon name="download" class-name="tree-controls__icon" />
		</button>
		<button
			v-shortkey="{ std: ['ctrl', 'd'], mac: ['meta', 'd'] }"
			v-tooltip.top-center="t(buttons.mirror)"
			:disabled="!enabledMirror"
			:class="buttonClasses('mirror', !enabledMirror)"
			@click="createMirror"
			@shortkey="onCreateMirror"
		>
			<icon name="mirror" class-name="tree-controls__icon" />
		</button>
		<button
			v-shortkey="['del']"
			v-tooltip.top-center="t(buttons.delete)"
			:disabled="!enabledDelete"
			:class="buttonClasses('delete', !enabledDelete)"
			class="tree-controls__buttons-item tree-view__button-delete"
			@click="deleteSelected"
			@shortkey="deleteSelected"
		>
			<icon name="delete" class-name="tree-controls__icon" />
		</button>
	</div>
</template>

<script>
import Vue from 'vue';
import cwalert from 'service/cwalert';
import t from 'translate';
import { assign, cloneDeep, isEqual, noop, reduce } from 'lodash';

export default Vue.component('tree-controls', {
	props: {
		copy: {
			type: Array,
			default: () => []
		},
		createMirror: {
			type: Function,
			required: true
		},
		deleteSelected: {
			type: Function,
			required: true
		},
		downloadUrl: {
			type: String,
			default: ''
		},
		enabledCopy: {
			type: Boolean,
			default: false
		},
		enabledMirror: {
			type: Boolean,
			default: false
		},
		enabledPaste: {
			type: Boolean,
			default: false
		},
		localItems: {
			type: Object,
			required: true
		},
		modifiedNotSaved: {
			type: Boolean,
			default: false
		},
		performCopy: {
			type: Function,
			required: true
		},
		performPaste: {
			type: Function,
			required: true
		},
		saveAllowed: {
			type: Boolean,
			default: true
		},
		saveTemplate: {
			type: Function,
			default: noop
		},
		selected: {
			type: Array,
			default: () => []
		},
		selectedMirror: {
			type: Boolean,
			default: false
		},
		setModified: {
			type: Function,
			default: noop
		}
	},
	data () {
		return {
			buttons: {
				copy: 'Copy content',
				copyCancel: 'Clear clipboard',
				delete: 'Delete selected',
				download: 'Download template',
				mirror: 'Create a mirror',
				paste: 'Paste content',
				save: 'Save template'
			},
			originalItems: this.setItems(this.localItems)
		};
	},
	computed: {
		enabledDelete () {
			return !this.selectedMirror && this.selected.length;
		},
		enableDownload () {
			return this.saveAllowed && !this.modifiedNotSaved;
		},
		modifiedItems () {
			const curItems = this.setItems(this.localItems);
			return this.modifiedNotSaved || !isEqual(curItems, this.originalItems);
		}
	},
	methods: {
		buttonClasses (name, disabled) {
			const classes = ['tree-controls__buttons-item', `tree-controls__button-${name}`];

			if (disabled) {
				classes.push('tree-controls__button--disabled');
			}

			if (this.modifiedItems && name === 'save') {
				classes.push('tree-controls__button--modified');
			}
			return classes;
		},
		download () {
			location.href = this.downloadUrl;
		},
		onCreateMirror () {
			if (this.enabledMirror) {
				this.createMirror();
			}
		},
		pasteClasses (name, disabled) {
			const classes = this.buttonClasses(name, disabled);

			if (this.copy.length) {
				classes.push('tree-controls__button--highlighted');
			}
			return classes;
		},
		removeRedundantProps: (element) => {
			const objToCheck = (element) => reduce(element, (result, child) => {
				delete (child.checked);
				delete (child.expanded);
				delete (child.indeterminate);

				if (child.children) {
					child.children = objToCheck(child.children);
				}
				result.push(child);
				return result;
			}, []);
			return objToCheck(element.children);
		},
		save (items) {
			this.saveTemplate(items).then(() => {
				this.setModified(false);
				this.originalItems = this.setItems(items);
				cwalert.success('Saved successfully');
			});
		},
		setItems (items) {
			const clonedItems = cloneDeep(items);
			return assign({}, clonedItems, {
				children: this.removeRedundantProps(clonedItems)
			});
		},
		t
	}
});
</script>
