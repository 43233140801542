import { Model } from 'backbone';
import Marionette from 'backbone.marionette';
import { forEach, invoke, isFunction, isObject, map } from 'lodash';
import cardManager from './card-manager';
import appbarManager from '../../appbar/appbar-manager';
import CardView from '../views/card-view';
import cardUrl from './card-url';
import ControllerContext from './extensions/controller-context';
import cardConfig from '../config/card-config';
import store from 'store';
import { when } from 'jquery';
import extractCardParams from './extract-card-params';
import selectTreatment from './extensions/select-treatment';
import establishTreatment from './extensions/establish-treatment';

/**
* A card controller. `card.ctrl` is called when card route has been matched and
* card is ready to be shown. The function should return promise in order for the
* tiles to initialize. However, manually calling `populate()` is also an option.
*
* @function card.ctrl
* @param {object} controllerContext
* @param {object} controllerContext.cardData -     Object storing card related data, is being
*                                                  passed down to tiles.
* @param {Function} controllerContext.setLoading - Set card to 'loading' state.
* @param {Function} controllerContext.setLoaded -  Unset card 'loading' state.
* @param {Function} controllerContext.populate -   Populate card with tiles.
* @param {Function} controllerContext.navigate -   Navigate to a specific URL.
* @param {Function} controllerContext.close -      Close card.
* @param {object} cardParams -                     Parameters passed in card URL.
* @returns {object} Promise.
* @example
*  import store from 'store';
*
*  const card = {
*  	url: 'respondent/:respondentId/status',
*  	ctrl: (context, { respondentId }) =>
* 			store.dispatch('respondent/init', { respondentId })
*  };
*
* @example
*  const card = {
*  	url: 'respondent/:respondentId/status,
*
* 		ctrl: ({ setLoading, setLoaded, populate }, { respondentId }) => {
* 			setLoading();
*
* 			setTimeout(() => {
* 				setLoaded();
* 				alert(`Respondent ID: ${respondentId}`);
* 				populate();
* 			}, 1000);
* 		}
*  };
*/

const controller = new Marionette.Object();
export const router = new Marionette.AppRouter({ controller });

const cleanContext = ({ contexts, name }) => {
	if (contexts[name]) {
		contexts[name].stopListening();
		delete contexts[name];
	}
};

const registerController = ({ card, contexts }) => {
	const { appEvents, name, url } = card;
	const title = (isFunction(card.title) ? card.title() : card.title) || '';

	// 👇 Reworked on `development` branch anyway
	// eslint-disable-next-line max-statements
	controller[name] = async function (...args) {
		const cardParams = extractCardParams({ url, action: this.action, args });
		try {
			await selectTreatment({ card, cardParams });
		} catch (e) {
			return false;
		}
		await establishTreatment({ cardParams });
		appbarManager.toggle(true);
		store.dispatch('setAutoVariant', !!card.autoVariant);

		const cardModel = new Model({
			title,
			context: new Model({
				cardId: name,
				cardName: name,
				urlParams: cardParams,
				...cardParams
			})
		});

		const view = new (CardView.extend({
			className: `card ${name}`,
			model: cardModel,
			card
		}))({
			cardName: name,
			cardUrl: url
		});

		cardManager.registerCard(view);
		appbarManager.registerCard(name);
		cleanContext({ contexts, name });

		const context = contexts[name] = new ControllerContext({
			view,
			cardName: name,
			context: cardModel.get('context'),
			router,
			action: this && this.action,
			definition: card,
			appEvents
		});

		when(...map(store.getters.variantActions, store.dispatch)).then(() => {
			const showCard = () => {
				cardManager.showCard(view, { controllerContext: context });
			};

			if (isFunction(card.ctrl)) {
				const dfd = card.ctrl(context, cardParams) || {};
				return invoke(dfd, 'then', showCard);

			} else if (isFunction(card.controller)) {
				return card.controller.apply(context, [...args]);
			}

			return showCard();
		});
	};
};

const Card = function (card) {
	const { name } = card;
	let urlParams;

	registerController({ card, contexts: {} });

	if (isObject(card.url)) {
		forEach(card.url, (url, action) => {
			const controllerId = `${name}.${action}`;
			controller[controllerId] = (...args) => {
				urlParams = [];

				for (let i = 0; i < args.length; i++) {
					urlParams.push(args[i]);
				}

				controller[name].apply({ action }, urlParams);
			};

			router.appRoute(url, controllerId);
		});

	} else {
		router.appRoute(card.url, name);
	}

	cardUrl.registerCard(card);
};

export default {
	card (card) {
		const cardCfg = cardConfig(card.name);

		if (!cardCfg || cardCfg.disabled) {
			return null;
		}

		return new Card(card);
	},

	cards (cards) {
		store.dispatch('engine/cards/register', { cards });
		forEach(cards, (card) => {
			this.card(card);
		});
	}
};
