import $ from 'jquery';
import { warning } from 'service/log/log';
import icon from 'service/icon';
import t from 'translate';
import executeIfFunction from 'execute-if-function';
import { defer, escape, isObject } from 'lodash';

const className = 'alert';

const pendingAlerts = {};

const alertActions = ({ $alert, params, remove }) => {
	const hideTimeoutId = setTimeout(remove, params.timeout || 10000);

	$alert.on('dblclick', () => {
		clearTimeout(hideTimeoutId);
		remove();
	});

	// Sample code for notification removal
	$alert.children('.close').on('click', () => {
		remove();
	});

	defer(() => {
		$alert.addClass('show');
	});
};

const createAlert = ({ alertClassName, message }) => {
	const $alert = $(`<div class="${alertClassName}" />`).attr({
		'role': 'alert',
		'aria-live': 'assertive',
		'aria-atomic': 'true',
		'tabindex': 0
	});

	$alert
		.append($('<button />').addClass('close').append(icon('cancel', {
			title: t('Close')
		})))
		.append(`<h1 class="${className}__message">${escape(message)}</h1>`);

	// Mount to notification area. Make sure that subsequent messages come out on top (later in the
	// DOM) Hence it's injected before the card-layout.
	$(`.${className}__container`).append($alert);
	return $alert;
};

const updatePendingAlerts = ({ message, remove }) => {
	if (pendingAlerts[message]) {
		pendingAlerts[message].remove();
	}
	pendingAlerts[message] = { remove };
};

const alertFactory = (params = {}) => {
	let message = executeIfFunction(params.message);
	const alertClassName = [
		className,
		`${className}--${params.variant}`
	].join(' ');

	if (isObject(message)) {
		switch (message.type) {
		case 'changes-saved':
			message = t('Changes successfully saved');
			params.data = params.data || {
				notificationtype: 'save-successful'
			};
			break;

		default:
			warning({
				msg: `Unknown alert message type: ${message.type}`,
				dump: { message, params }
			});
		}
	}

	const $alert = createAlert({ alertClassName, message });

	const remove = () => {
		$alert.removeClass('show');
		$alert.remove();
	};

	updatePendingAlerts({ message, remove });

	alertActions({ $alert, params, remove });

	// return the dom node in case if any further adjustments are needed by
	// the caller
	return $alert[0];
};

export default {
	custom: (message, type, timeout = 3000) => alertFactory({
		message,
		variant: type,
		timeout
	}),

	notice: (message) => alertFactory({
		message,
		variant: 'notice',
		timeout: 3000
	}),

	warning: (message) => alertFactory({
		message,
		variant: 'warning',
		timeout: 3000
	}),

	error: (message) => alertFactory({
		message,
		variant: 'failure',
		timeout: 7000
	}),

	success: (message) => alertFactory({
		message,
		variant: 'success',
		timeout: 3000
	}),

	failure: (message) => alertFactory({
		message,
		variant: 'failure',
		timeout: 3000
	})
};

