import $ from 'jquery';

let activeElements = [];
let last;

export default ({
	init: () => {
		$(document).on({
			focusout: (e) => {
				activeElements.push(e.target);
				last = e.target;
			},
			unload: () => {
				activeElements = [];
			}
		});
	},
	last: () => last,
	list: () => activeElements
});
