import extensions from 'service/app-bar/extensions/extensions';
import AppBarManager from 'core/engine/appbar/appbar-manager';
import { assign } from 'lodash';

/**
 * Application bar helper. Mounts views into the card context bar.
 *
 * @class appbar
 * @author Waldemar
 *
 */

const AppBar = function () {
	this.appbar = AppBarManager;

	/**
	 * Adds a Backbone.View to the specified card instance.
	 * @method appbar#add
	 * @author Waldemar
	 * @param {string} cardName
	 * @param {Backbone.View} view
	 */
	this.add = this.appbar.addElement.bind(this.appbar);
};

export default assign(new AppBar(), extensions);
