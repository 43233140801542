import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import RespondentMessageDraftAttachment from './message-draft-attachment';

/**
 * RespondentMessageDraftAttachments Entity
 * @class RespondentMessageDraftAttachments
 * @extends MultipleEntities
 */
const RespondentMessageDraftAttachments = MultipleEntities.extend({
	model: RespondentMessageDraftAttachment,
	urls,
	eventNamespace: 'respondent-message-draft-attachment'
});

export default new RespondentMessageDraftAttachments();
