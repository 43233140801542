import repository from 'repository';

import { when } from 'jquery';

export default {
	namespaced: true,

	state: {
		allRelations: [],
		relations: [],
		respondentId: null
	},

	getters: {
		allRelations: (state) => state.allRelations,
		relations: (state) => state.relations,
		substituted: (state) => state.substituted
	},

	mutations: {
		setAllRelations: (state, payload) => {
			state.allRelations = payload;
		},
		setRelations: (state, payload) => {
			state.relations = payload;
		},
		setRespondentId: (state, id) => {
			state.respondentId = id;
		}
	},

	actions: {
		addRelation: (context, data) => repository.addRespondentRelation(data),
		addRelationAndRefresh: ({ dispatch }, data) =>
			when(dispatch('addRelation', data)).then(() => dispatch('getRespondentRelations')),
		deleteRelation: (context, { relationTypeId, respondent1Id, respondent2Id }) =>
			repository.removeRespondentRelation({ relationTypeId, respondent1Id, respondent2Id }),
		deleteRelationAndRefresh: ({ dispatch }, data) =>
			when(dispatch('deleteRelation', data))
				.then(() => dispatch('getRespondentRelations')),
		getAllRelations: ({ commit }) => {
			const results = repository.getAllRelationTypes();
			return results.then((result) => {
				commit('setAllRelations', result);
			});
		},
		getRespondentRelations: ({ commit, state }, respondentId = null) => {
			if (respondentId) {
				commit('setRespondentId', respondentId);
			}
			const results = repository.searchRespondentRelations(state.respondentId);
			return results.then((result) => {
				commit('setRelations', result);
			});
		}
	}
};
