import $ from 'jquery';
import moment from 'moment';
import t from 'translate';
import { split } from 'lodash';

export default ({ el, fractionDigits }) => {
	const $el = $(el);
	const $body = $('body');
	const $tooltip = $('<div class="plot-chart__tooltip"></div>');

	const position = {
		left: (e, tooltip) => (e.pageX || e.originalEvent.layerX) - (tooltip.width() / 2) - 10,
		top: (e, tooltip) => (e.pageY || e.originalEvent.layerY) - tooltip.height() - 40
	};

	$el.on('mouseenter', '.ct-point', function (event) {
		$body.append($tooltip);
		const $this = $(this);
		const valueAttr = split($this.attr('ct:value'), ',');

		const [_date, _value] = {
			1: [null, valueAttr[0]],
			2: valueAttr
		}[valueAttr.length];
		const date = _date && moment(+_date);
		const value = (+_value).toFixed(fractionDigits);
		const gap = $this.hasClass('ct-point--gap');
		const tooltipFormat = $this.attr('ct:meta') || t('date.formats.chartDate');
		const dateHtml = date ?
			`<p class="plot-chart__tooltip-date">${date.format(tooltipFormat)}</p>` :
			'';

		$tooltip
			.html(`
				${dateHtml}
				<p class="plot-chart__tooltip-value">
					${gap ? t('No value') : t('Value - {value}', { value })}
				</p>
			`)
			.css({
				left: position.left(event, $tooltip),
				top: position.top(event, $tooltip)
			});
	});

	$el.on('mouseleave', '.ct-point', () => {
		// eslint-disable-next-line lodash/prefer-lodash-method
		$body.find($tooltip).remove();
	});

	$el.on('mousemove', (event) => {
		$tooltip.css({
			left: position.left(event, $tooltip),
			top: position.top(event, $tooltip)
		});
	});
};
