import { find } from 'lodash';

const fileTypes = [{
	name: 'excel',
	rule: /.*excel.*/
}, {
	name: 'pdf',
	rule: /.*pdf.*/
}, {
	name: 'audio',
	rule: /.*audio.*/
}, {
	name: 'word',
	rule: /.*word.*/
}, {
	name: 'archive',
	rule: /.*(zip)|(rar).*/
}, {
	name: 'text',
	rule: /.*text.*/
}, {
	name: 'code',
	rule: /.*code.*/
}, {
	name: 'powerpoint',
	rule: /.*powerpoint.*/
}, {
	name: 'video',
	rule: /.*video.*/
}, {
	name: 'image',
	rule: /.*image.*/
}];

const getFileType = function (mimeType) {
	const fileType = 'other';

	if (mimeType) {
		const found = find(fileTypes, (type) => mimeType.match(type.rule));
		return found ? found.name : fileType;
	}

	return fileType;
};

export default getFileType;
