<template>
	<div class="loader-overlay">
		<div v-if="enabled" class="loader-overlay__body">
			<div class="loader-overlay__content">
				<p class="loader-overlay__text">
					{{label}}
				</p>
				<div v-loader-spinner class="loader-overlay__spinner" />
			</div>
		</div>
		<slot />
	</div>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			default () {
				return this.$t('Processing data');
			}
		},

		enabled: {
			type: Boolean,
			default: true
		}
	}
};
</script>
