/**
 * Set `data-loading` state on the tile. Used when obtaining search results etc.
 *
 * @function TileView.setDataLoading
 * @returns {Object} - Tile object.
 * @example
 * export default TileView.extend({
 *     loaded: ({ tile }) => {
 *         tile.setDataLoading();
 *     }
 * });
 */
export default function () {
	this.__params.tileContainerModel.set('data-loading', true);
	return this;
}
