import { assign, isUndefined } from 'lodash';
import { Collection, Model } from 'backbone';
import appContext from 'app-context';
import appLayout from '../../views/app-layout-view.es6';
import AppBarItems from './views/appbar-items-collection-view';
import appbarLayout from './views/appbar-layout';

const AppBarManager = function () {
	const views = {};

	appContext.on({
		'dialog.open': () => {
			this.hideContext();
		},
		'dialog.close': () => {
			this.showContext();
		},
		'card.render': ({ name } = {}) => {
			this.show(name);
		},
		'card.close': ({ name }) => {
			this.close(name);
		}
	});

	assign(this, {
		addElement (cardName, view, opts = {}) {
			const type = opts.type || 'main';
			const model = new Model({ view });
			const collectionView = this.getOrSetViews(cardName)[type];
			const at = !isUndefined(opts.order) ? { at: opts.order } : {};
			model.set({ preventHide: !!opts.preventHide	});
			collectionView.collection.add(model, at);
			const layout = appbarLayout();
			layout.setContextCollection(this.getView({ cardName, type: 'main' }).collection);
			return this;
		},

		getView ({ cardName, type }) {
			return this.getOrSetViews(cardName)[type];
		},

		registerView (cardName, viewsToAdd) {
			views[cardName] = viewsToAdd;
			return this;
		},

		registerCard (cardName) {
			const view = (type) => new AppBarItems({
				collection: new Collection(),
				type
			});

			return this.registerView(cardName, {
				main: view('main'),
				outer: view('outer')
			});
		},

		show (cardName) {
			if (!cardName) {
				return this;
			}
			const view = (type) => this.getOrSetViews(cardName)[type];
			const layout = appbarLayout();

			layout.getRegion('contextContainer').show(view('main'), {
				preventDestroy: true
			});
			layout.getRegion('outerContainer').show(view('outer'), {
				preventDestroy: true
			});
			layout.setContextCollection(this.getView({ cardName, type: 'main' }).collection);

			return this;
		},

		close (cardName) {
			if (!cardName || !views[cardName]) {
				return this;
			}
			views[cardName].main.remove();
			views[cardName].outer.remove();
			delete views[cardName];
			return this;
		},

		getViews (cardName) {
			return views[cardName];
		},

		getOrSetViews (cardName) {
			!views[cardName] && this.registerCard(cardName);
			return this.getViews(cardName);
		},

		hideContext () {
			appbarLayout().toggleContainersVisibility(false);
		},

		showContext () {
			appbarLayout().toggleContainersVisibility(true);
		},

		toggle (bool) {
			appLayout.getRegion('appBar').$el.toggleClass('app-appbar__container--hidden', !bool);
		}
	});
};

export default new AppBarManager();

