import { isFunction } from 'lodash';

/**
 * @param {string | Array | object | number | Function} possibleFunc - Function.
 * @param {object} context - Context.
 * @returns {string | Array | object | number | Function} - Return.
 * @example executeIfFunction(possibleFunction, context)
 */
const executeIfFunction = function (possibleFunc, context) {
	let possibleFunction = possibleFunc;

	if (isFunction(possibleFunction)) {
		// eslint-disable-next-line prefer-rest-params
		const args = [].slice.call(arguments, 1);
		possibleFunction = possibleFunction.apply(context || null, args);
	}
	return possibleFunction;
};

export default executeIfFunction;
