<template>
	<li class="items-view__element-container">
		<div class="items-view__label-container">
			<label class="items-view__value">{{label}}</label>
		</div>
		<div class="items-view__value-container">
			<p class="items-view__value">
				<slot />
			</p>
		</div>
	</li>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			required: true
		}
	}
};
</script>
