import t from 'translate';
import { compile } from 'handlebars';
import formView from 'components/form-view/form-view';
import categoryTag from 'components/category-tag/category-tag';
import TileView from 'tile-view';
import { EMPTY_CHAR } from 'lib/chars';
import store from 'store';
import { defer, get } from 'lodash';

let unsubscribe;

const toggleLabel = (label) => {
	defer(() => {
		label.toggleClass('goal-achieved-label--hide', !store.getters['goal/item'].isCompleted);
	});
};

const subscribe = (label) => {
	unsubscribe = store.subscribe((mutation) => {
		if (mutation.type === 'goal/set') {
			toggleLabel(label);
		}
	});
};

export default TileView.extend({
	actions: () => [
		['goal/init', {
			goalId: get(store.getters.urlParams, 'goalId'),
			respondentId: store.getters['respondent/respondentId']
		}],
		'respondent/init'
	],
	template: compile(`
		<p class="goal-achieved-label">
			{{ t "Goal achieved" }}
			{{{ icon "achieved" "Goal achieved" false "goal-achieved-icon" }}}
		</p>
		<section class="goal-details-container"></section>
	`),
	title: t('Goal details'),
	acl: [{
		checkpoint: 'administrator.respondents.goals-activities',
		op: 'READ'
	}],

	loaded: ({ tile }) => {
		const goal = store.getters['goal/item'];
		tile.$el.html(tile.template());
		// eslint-disable-next-line lodash/prefer-lodash-method
		const $achievedLabel = tile.$el.find('.goal-achieved-label');
		// eslint-disable-next-line lodash/prefer-lodash-method
		const $detailsContainer = tile.$el.find('.goal-details-container');

		toggleLabel($achievedLabel);
		subscribe($achievedLabel);

		const form = formView({
			name: 'goal',
			item: goal,
			fields: [{
				key: 'name',
				type: 'paragraph',
				label: t('Goal name'),
				multiline: true
			}, {
				key: 'deadline',
				type: 'paragraph',
				label: t('Goal deadline'),
				multiline: true,
				transform: (value) => value || EMPTY_CHAR
			}, {
				key: 'description',
				type: 'paragraph',
				label: t('Description'),
				multiline: true,
				transform: (value) => value || EMPTY_CHAR
			}, {
				key: 'activities',
				type: 'paragraph',
				label: t('Activities'),
				multiline: true,
				transform: () => {
					if (!goal.activitiesNotCompletedCount && !goal.activitiesCompletedCount) {
						return t('There are no activities defined for this goal');
					}

					return t('{completedCount} / {sum} completed', {
						completedCount: goal.activitiesCompletedCount,
						sum: goal.activitiesNotCompletedCount + goal.activitiesCompletedCount
					});
				}
			}, {
				key: 'categories',
				type: 'paragraph',
				label: t('Categories')
			}]
		});

		const categories = categoryTag(goal.category) || EMPTY_CHAR;
		$detailsContainer.html(form.$el).append(categories);
	},

	onRemove () {
		unsubscribe();
	}
});
