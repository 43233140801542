import repository from 'repository';
import {
	filter, find, findIndex, get, isNull, isString, pick, pickBy, identity, reject, reduce
} from 'lodash';
import { TREATMENT } from 'store/treatments/rp-treatment';

export default {
	namespaced: true,

	state: {
		storeAppsData: [],
		activated: [],
		activatedCollection: null,
		connected: [],
		connectedCollection: null,
		currentApp: {},
		currentAppModel: null
	},

	getters: {
		available: (state) => reduce(state.storeAppsData, (acc, curr) => {
			if (!find(state.activated, { clientId: curr.id })) {
				acc.push(curr);
			}
			return acc;
		}, []),
		activated: (state) => state.activated,
		connected: (state) => state.connected,
		downloadUrls: (state) => !isNull(state.currentApp) ?
			pickBy(pick(state.currentApp.activeApp, [
				'storeAndroidUrl',
				'storeIosUrl',
				'storeWindowsUrl'
			]), isString) :
			{},

		treatmentApps: (state, getters, rootState) => filter(
			state.activated, (app) =>
				find(app.treatmentTypes, { id: get(rootState.treatment.data, 'treatmentType.id') })
		)
	},

	mutations: {
		setStoreApps: (state, { storeApps }) => {
			state.storeAppsData = storeApps;
		},

		setActivated: (state, { activated, collection }) => {
			state.activated = activated;
			state.activatedCollection = collection;
		},

		setConnected: (state, { connected, collection }) => {
			state.connected = connected;
			state.connectedCollection = collection;
		},

		setDetails: (state, { details, model }) => {
			state.currentApp = details;
			state.currentAppModel = model;
		},

		update: (state, connection) => {
			const index = findIndex(state.activated, { id: connection.id });
			state.activated[index] = connection;
		},

		activate: (state, connection) => {
			state.activated.push(connection);
		},

		deactivate: (state, { clientId }) => {
			const index = findIndex(state.activated, { clientId });
			state.activated.splice(index, 1);
		},

		addConnection: (state, app) => {
			state.connectedCollection.add(app);
			state.connected.push(app);
		},
		disconnect: (state, id) => {
			state.connected = reject(state.connected, { id: +id });
		}
	},

	actions: {
		initStoreApps: ({ commit }) => {
			const collection = repository.getStoreApps();

			return collection.then(() => {
				commit('setStoreApps', { storeApps: collection.toJSON() });
			});
		},

		initActivatedForTreatment: ({ dispatch, rootGetters }, {
			treatmentId = rootGetters[TREATMENT.ID]
		} = {}) => {
			dispatch('initActivated', { treatmentId });
		},

		initActivated: ({ commit }, params = {}) => {
			const collection = repository.getActivatedApps(params);

			return collection.then(() => {
				commit('setActivated', { collection, activated: collection.toJSON() });
			});
		},

		initConnectedForTreatment: ({ commit, rootGetters }, {
			treatmentId = rootGetters[TREATMENT.ID]
		} = {}) => {
			const collection = repository.getConnectedApps({ treatmentId });

			return collection.then(() => {
				commit('setConnected', { collection, connected: collection.toJSON() });
			});
		},

		initConnected: ({ commit }, params) => {
			const collection = repository.getConnectedApps({
				filterEq: pickBy(params, identity) // $.params(obj.obj) -> obj[key]=value
			});

			return collection.then(() => {
				commit('setConnected', { collection, connected: collection.toJSON() });
			});
		},

		initDetails: async ({ commit }, id) => {
			const model = repository.getAppDetails(id);
			const details = await model;

			commit('setDetails', { model, details });
		},

		deactivate: ({ state, commit }, { connection }) =>
			state.activatedCollection.get(connection.id).destroy().then(() => {
				commit('deactivate', connection);
			}),

		activate: ({ commit }, { model }) => model.save().then(() => {
			commit('activate', model.toJSON());
		}),

		update: ({ commit }, { model }) => model.save().then(() => {
			commit('update', model.toJSON());
		}),

		disconnect: ({ state, commit }, { id }) => {
			state.connectedCollection.get(id).destroy();
			commit('disconnect', id);
		},
		disconnectCurrent: ({ state }) => state.currentAppModel.destroy(),

		addConnection: ({ commit }, { model }) => {
			commit('addConnection', model);
		}
	}
};
