var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "treatment-grid-item",
    { attrs: { image: _vm.treatmentImage }, on: { click: _vm.click } },
    [
      !_vm.isMainRespondent
        ? _c("treatment-grid-aux", [
            _c("div", { staticClass: "treatment-selection__role-container" }, [
              _c("span", { staticClass: "treatment-selection__role-name" }, [
                _vm._v("\n\t\t\t\t" + _vm._s(_vm.roleName) + "\n\t\t\t")
              ]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "treatment-selection__main-respondent" },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.$t("treatmentSelection.of")) +
                      "\n\t\t\t\t"
                  ),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.respondentName(_vm.mainRespondent)))
                  ])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("treatment-grid-header", { attrs: { icon: "treatment" } }, [
        _vm._v("\n\t\t" + _vm._s(_vm.title) + "\n\t")
      ]),
      _vm._v(" "),
      _c(
        "treatment-grid-label",
        { attrs: { label: _vm.$t("general-list.StartDate") } },
        [_vm._v("\n\t\t" + _vm._s(_vm.startAt) + "\n\t")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }