/**
 * Enable the tile.
 *
 * @function TileView.enable
 * @example
 * export default TileView.extend({
 *     loaded: ({ tile }) => {
 *         tile.enable();
 *     }
 * });
 */
export default function () {
	this.__params.tileContainerModel.set('disabled', false);
}

