import { set } from '../__helpers/mutations';
import repository from 'repository';

export default {
	namespaced: true,

	state: {
		data: []
	},

	getters: {
		items: (state) => state.data
	},

	mutations: {
		set: set()
	},

	actions: {
		init: ({ commit }) => repository.goalCategories().then((categories) => {
			commit('set', categories);
		})
	}
};
