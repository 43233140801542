import BaseComponent from '../base-component';
import { isUndefined } from 'lodash';

export default BaseComponent.extend({
	template: `
		<div class="filter__container filter__container--search">
			<label>
				<span class="filter__input-label filter__input-label--search">{{ label }}</span>

				<input
					tabindex="0"
					class="filter__input filter__input--search"
					type="text"
					:value="query"
					@keyup="onKeyup"
					ref="input"
				/>
				<icon
					name="search"
					:className="['filter__input-icon', 'filter__input-icon--search']"
					:small="true"
				/>
			</label>
		</div>
	`,

	data: () => ({ cachedValue: '' }),

	methods: {
		onKeyup (e) {
			this.setValues({ query: e.target.value });
			this.onChange(e.target.value);
		},

		clear () {
			this.$refs.input.value = '';
		}
	},

	computed: {
		query () {
			return !isUndefined(this.viewValue.query) ? this.viewValue.query : this.startValue;
		}
	}
});
