import t from 'translate';
import TileView from 'tile-view';
import formView from 'components/form-view/form-view';
import store from 'store';
import enableCirclesCheck from '../../helpers/enable-circles-check';

export default TileView.extend({
	title: t('Edit circle'),
	instant: true,
	acl: [{
		op: 'READ',
		checkpoint: 'administrator.circles.circles'
	}],

	features: enableCirclesCheck,

	loaded: ({ tile }) => {
		const model = store.state.circles.currentCircle.model;

		tile.form = formView({
			model,
			loader: true,
			listenTo: ['submit'],
			saveIndicator: true,
			autocomplete: false,
			fields: [{
				key: 'name',
				label: t('Name'),
				mandatory: true
			}, {
				key: 'code',
				type: 'username',
				variant: 'circle',
				propName: 'code',
				label: t('Code'),
				mandatory: true,
				customize (view) {
					view.ui.input.on('change', () => {
						// Prevent displaying form overlay on blur
						// to allow clicking on submit button
						view.setLoaded();
					});
				},
				description: {
					available: t('This code is available'),
					unavailable: t('This code is already in use'),
					error: t('Failed to check the code'),
					progress: t('Checking the code availability')
				}
			}, {
				key: 'categories',
				type: 'multiselect',
				label: t('Category'),
				collection: store.state.circles.categories.collection,
				title: 'circle'
			}, {
				key: 'exactMatch',
				type: 'checkbox',
				label: t('Exact match of code only')
			}, {
				key: 'description',
				type: 'textarea',
				label: t('Description')
			}],
			buttons: [{
				caption: t('Save'),
				name: 'save',
				key: 'save',
				type: 'submit'
			}]
		});

		tile.$el.append(tile.form.$el);
	}
});
