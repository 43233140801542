import MultipleEntities from '../shared/multiple-entities';
import RespondentAttributeValue from './attribute-value';
import urls from './urls';

// Disclaimer: the name is clearly ugly. It's on purpose. Backend API and
// the whole architecture around these 'custom attributes' is really bad.
// The name is a little remainder of that.

const RespondentAttributeValues = MultipleEntities.extend({
	model: RespondentAttributeValue,
	urls,
	eventNamespace: 'respondent-attribute-value',
	idName: 'respondentAttributeId',

	canAdd: () => false,

	getAll (respondentId) {
		return this.retrieve('listAttributeValues', respondentId);
	}
});

export default new RespondentAttributeValues();

