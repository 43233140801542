import noUiSlider from 'nouislider';
import {
	assign, compact, debounce, delay, forEach, isArray, isNumber, map, max, min, noop, range, values
} from 'lodash';
import $ from 'jquery';
import { compile } from 'handlebars';
import tpl from './slider-template';
import slugify from 'slugify';
import { View } from 'backbone';
import t from 'translate';
import layoutAdjustment from '../../../rendering/layout-adjustment';
import { cwtagsNl2br } from '../../../helpers/string';
import collision from '../helpers/collision';
import { questionTextId } from '../../shared/helpers';

const isIntPolyfill = (value) => isNumber(value) &&
	isFinite(value) &&
	Math.floor(value) === value;
const isInt = Number.isInteger || isIntPolyfill;

export default View.extend({
	className: 'slider__wrapper assessment-container',
	events: {
		'click .slider__reset': 'reset'
	},

	getLabels: () => ({
		answer: t('Answer'),
		none: t('(none)')
	}),

	orderedAnswers () {
		return values(this.model.answers);
	},

	initialize ({ model, assessment }) {
		assign(this, {
			model,
			assessment,
			labels: this.getLabels()
		});

		return this.render({ firstRender: true });
	},

	setOptions () {
		this.opts = assign({}, {
			orientation: this.model.get('orientation'),
			direction: this.model.get('orientation') === 'vertical' ? 'rtl' : 'ltr'
		}, this.options());
	},

	template: compile(tpl),

	render ({ firstRender } = { firstRender: false }) {
		this.determineVisibility();

		this.$el.attr({
			// consider removing `data-id`, not that much used anyway
			'data-id': this.model.get('id'),
			'id': this.model.get('id')
		});
		this.setOptions();

		this.$el.html($(this.template(assign({}, this.model.toJSON(), {
			title: cwtagsNl2br(this.model.getQuestionText()),
			variant: slugify(this.type),
			labels: this.labels,
			qTextId: questionTextId(this.model)
		})))); // )))))))))))))))))))))))))))))))))))) (☞ﾟヮﾟ)☞

		assign(this, {
			// eslint-disable-next-line lodash/prefer-lodash-method
			$container: this.$el.find('.slider__container'),
			// eslint-disable-next-line lodash/prefer-lodash-method
			$controls: this.$el.find('.slider__controls'),
			// eslint-disable-next-line lodash/prefer-lodash-method
			$answer: this.$el.find('.slider__answer'),
			$slider: $('<div />')
		});

		this.slider = this.$slider[0];
		this.mountSlider({ el: this.slider, opts: this.opts });
		this[this.model.get('readonly') ? 'disable' : 'bindKeyboardEvents']();
		this.adjustments({ firstRender });

		return this;
	},

	mountSlider ({ el, opts }) {
		this.$slider.prependTo(this.$controls);
		noUiSlider.create(el, opts);
	},

	adjustments ({ firstRender }) {
		layoutAdjustment(this.model, this.el);
		this.setLength();
		this.adjustValues();
		this.realignValueLabels({ timeout: firstRender ? 500 : 0 });
		this.setNumbering();
		this.onRender && this.onRender.call(this);
		this.bindSliderChangeEvents();
		this.digitalPointsPolicy();
		this.handleAnswered();
		this.aria();
	},

	adjustValues () {
		// eslint-disable-next-line lodash/prefer-lodash-method
		const $values = this.$el.find('.noUi-value');

		$values.css('width', `${100 / $values.length}%`);
		$values.each(function () {
			const $value = $(this);
			// as of nouislider 10, value returned by formatter is appended as text in domnode
			// as long as formatter in cw slider returns html in some cases, it needs to be 'parsed'
			$value.html($value.text());
		});

		this.realignMainLabel();
		$(window).on('resize', debounce(() => {
			this.realignValueLabels();
			this.realignMainLabel();
		}, 100));
	},

	answer (answer) {
		return `${this.labels.answer}: ${answer}`;
	},

	changeValue: noop,

	disable () {
		this.slider.setAttribute('disabled', true);
	},

	determineVisibility () {
		this.model.setVisible();

		if (!this.model.get('visible')) {
			this.$el.hide();
		}
	},

	bindKeyboardEvents () {
		const handles = this.slider.querySelectorAll('.noUi-handle');

		forEach(handles, (handle, index) => {
			const decrease = this.changeValue({ decrease: true });
			const increase = this.changeValue();

			handle.setAttribute('tabindex', 0);
			handle.addEventListener('keydown', (e) => {
				const keyPress = {
					37: 'left',
					38: 'up',
					39: 'right',
					40: 'down'
				}[e.which];
				const vertical = this.model.get('orientation') === 'vertical';
				const horizontal = this.model.get('orientation') === 'horizontal';
				const shouldIncrease = () => (vertical && keyPress === 'up') ||
					(horizontal && keyPress === 'right');
				const shouldDecrease = () => (vertical && keyPress === 'down') ||
					(horizontal && keyPress === 'left');

				if (shouldIncrease()) {
					increase(index);

				} else if (shouldDecrease()) {
					decrease(index);
				}
				// prevent scroll but leave other default behaviours
				keyPress && e.preventDefault();
			});
		});
	},

	bindSliderChangeEvents () {
		if (this.model.get('answerVisible')) {
			forEach(['slide', 'end', 'set'], (eventName) => {
				this.slider.noUiSlider.on(eventName, (values, handle) => {
					// determine arg passed to `renderLabel` depending on `valuesCount` cfg prop
					const arg = {
						1: values[handle],
						2: values
					}[this.model.cfg('valuesCount')];

					this.renderLabel(arg);
				});
			});
		}
	},

	clear () {
		this.model.set('valueSetByInput', true);
		this.$el.empty();
		return this;
	},

	unclear () {
		this.render();
		this.model.set('valueSetByInput', false);
		return this;
	},

	reset () {
		if (!this.model.get('readonly')) {
			this.model.reset();
			this.clear().unclear();
		}

		return this;
	},

	handleAnswered () {
		const defaultValueOnStart = !this.model.get('valueSetByInput') &&
			(!!this.model.defaultValue()() || !!compact(this.model.defaultValues()).length);

		this.toggleAnswered(
			this.model.isAnswered() ||
			defaultValueOnStart
		);
	},

	toggleAnswered (bool) {
		this.$container
			.toggleClass('slider--answered', !!bool)
			.toggleClass('slider--unanswered', !bool);
	},

	getHandle (index = 0) {
		// eslint-disable-next-line lodash/prefer-lodash-method
		return this.$slider.find(`[data-handle="${index}"]`);
	},

	aria () {
		this.$slider.attr({
			'role': 'slider',
			'aria-valuemin': this.opts.range.min,
			'aria-valuemax': this.opts.range.max,
			'aria-orientation': this.model.get('orientation'),
			'aria-live': 'polite'
		});

		const handles = this.slider.querySelectorAll('.noUi-handle');

		forEach(handles, (handle) => {
			handle.setAttribute('aria-labelledby', questionTextId(this.model));
		});
	},

	setAriaValue ({ value, values, text }) {
		const handles = this.slider.querySelectorAll('.noUi-handle');
		const sanitize = (val) => {
			if ((val || val === 0) && val <= this.opts.range.max && val >= this.opts.range.min) {
				return val;
			}
			return '';
		};

		isNumber(value) && this.$slider.attr('aria-valuenow', sanitize(value));
		isArray(values) && this.$slider.attr({
			'aria-valuetext': `${sanitize(values[0])}-${sanitize(values[1])}`
		});

		if (text) {
			forEach(handles, (handle) => {
				handle.setAttribute('aria-valuetext', text);
			});
		}
	},

	setLength () {
		if (this.model.get('length')) {
			if (this.opts.orientation === 'horizontal') {
				this.$slider.width(this.model.get('length'));

			} else if (this.opts.orientation === 'vertical') {
				this.$slider.height(this.model.get('length'));
			}
		}
	},

	setNumbering () {
		if (isNumber(this.model.number())) {
			// eslint-disable-next-line lodash/prefer-lodash-method
			this.$el.find('.slider__number').html(`${this.model.number()}.`);
		}
	},

	updateText () {
		this.$('.ar-component__title').html(cwtagsNl2br(this.model.getQuestionText()));
	},

	realignMainLabel () {
		const OFFSET = 30;
		// eslint-disable-next-line lodash/prefer-lodash-method
		const $valueLabels = this.$el.find('.value-label');
		// eslint-disable-next-line lodash/prefer-lodash-method
		const $mainLabel = this.$el.find('.slider__label');
		const collidingValueLabelHeights = [];

		setTimeout(() => {
			$valueLabels.each(function () {
				const { collides, height } = collision($mainLabel, $(this));

				if (collides) {
					collidingValueLabelHeights.push(height);
				}
			});

			const offsetCandidate = max(collidingValueLabelHeights);

			if (offsetCandidate > OFFSET) {
				$mainLabel.css('margin-bottom', offsetCandidate - OFFSET);
			}
		}, 400);
	},

	realignValueLabels ({ timeout } = { timeout: 0 }) {
		// eslint-disable-next-line lodash/prefer-lodash-method
		const $valueLabels = this.$el.find('.value-label');
		$valueLabels.each(function () {
			$(this).addClass('value-label--hidden');
		});

		const firstOrLast = (i) => {
			if (i === 0) {
				return 'left';

			} else if (i === $valueLabels.length - 1) {
				return 'right';
			}
			return '';
		};

		const calculateTopHeight = (
			{ $valueLabel, topHeight }
		) => $valueLabel.height() > topHeight ? $valueLabel.height() : topHeight;

		if (this.model.get('orientation') !== 'horizontal' || !$valueLabels.length) {
			return;
		}

		// properly align value labels
		const $slider = this.$slider;

		delay(() => {
			const sliderLeftOffset = $slider.offset().left;
			const sliderRightOffset = $slider.offset().left + $slider.width();
			let topHeight = 0;

			$valueLabels.each(function (i) {
				const $valueLabel = $(this);
				topHeight = calculateTopHeight({ $valueLabel, topHeight });
				$valueLabel.removeClass('value-label--hidden');

				// realign first and last ones
				if (firstOrLast(i)) {
					$valueLabel.css(firstOrLast(i), $valueLabel.parent().width() / 2);
					return;

				}

				const offsets = {
					left: $valueLabel.offset().left,
					right: $valueLabel.offset().left + $valueLabel.width()
				};

				// when labels are placed outside a slider move them so they fit inside
				if (offsets.left < sliderLeftOffset) {
					$valueLabel.css({
						'left': sliderLeftOffset - offsets.left,
						'text-align': 'left',
						'margin-top': $($valueLabels.get(0)).height() + 10
					});

				} else if (offsets.right > sliderRightOffset) {
					$valueLabel.css({
						'left': -1 * (offsets.right - sliderRightOffset),
						'text-align': 'right',
						'margin-top': $($valueLabels.get(-1)).height() + 10
					});
				}
			});

			topHeight && this.$el.css('margin-bottom', topHeight + 10);
		}, timeout);
	},

	noLabels () {
		return !(this.model.get('labelsVisible') || this.model.get('endLabelsVisible'));
	},

	shouldShowValuesAsLabels () {
		return (
			this.noLabels() || this.model.get('endLabelsOnly')
		) &&
			this.model.get('valueLabels').length &&
			!this.model.get('digitalPoints');
	},

	pipStep () {
		return min([+this.model.get('valueStep'), +this.model.get('labelStep')]);
	},

	shouldShowEndLabelsOnly () {
		return this.model.get('endLabelsOnly') && !this.model.get('digitalPoints');
	},

	displayValues () {
		let displayValues = range(
			+this.model.get('min'),
			+this.model.get('max') + this.pipStep(),
			this.pipStep()
		);

		if (!isInt(this.pipStep())) {
			for (let i = 0; i < displayValues.length; i++) {
				displayValues[i] = this.model.get('min') + (i * this.pipStep());
			}
		}

		if (this.shouldShowValuesAsLabels()) {
			displayValues = map(
				this.model.get('valueLabels'),
				(valueLabel) => parseFloat(valueLabel.value)
			);

		} else if (this.shouldShowEndLabelsOnly()) {
			displayValues = [this.model.get('min'), this.model.get('max')];
		}

		return displayValues;
	},

	digitalPointsPolicy () {
		if (!this.model.get('digitalPoints')) {
			return;
		}

		const displayValues = this.displayValues();
		const valueStep = +this.model.get('valueStep');

		if (displayValues.length) {
			// eslint-disable-next-line lodash/prefer-lodash-method
			this.$el.find('.noUi-marker').each(function (index) {
				if (displayValues[index] % valueStep !== 0) {
					$(this).addClass('no-digital-point');
				}
			});
		}
	}
});
