const avatar = {
	props: ['name', 'color', 'classes'],
	template: `
		<span
			class="avatar"
			:class="[name, classes]"
			:style="{ color: color }"
		/>
	`
};

const avatarNickname = {
	props: ['user'],
	components: { avatar },
	template: `
		<div class="avatar-nickname__container">
			<div class="avatar-nickname__avatar">
				<avatar :name="user.avatarName" :color="user.avatarColour" />
			</div>
			<p
				class="avatar-nickname__nickname"
				:style="{ color: user.avatarColour }"
			>{{ user.nickname }}</p>
		</div>
	`
};

export default { avatar, 'avatar-nickname': avatarNickname };
