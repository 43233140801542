import TileView from 'tile-view';
import repository from 'repository';
import { get } from 'lodash';
import acl from 'acl';

export default TileView.extend({
	acl: {},
	cardData: () => ['id', 'pageId'],
	init: ({ tile }) => {
		tile.cfg = tile.config() || {};

		(tile.cfg.ACL || tile.cfg.acl) &&
		!acl.checkAccess(tile.cfg.ACL || tile.cfg.acl) &&
		tile.disable();
	},

	tileData: ({ tile }) => {
		const permalinkFromCfg = get(tile.config(), 'pageByPermalink');
		return {
			page: permalinkFromCfg ?
				repository.getPageByPermalink(permalinkFromCfg) :
				repository.getPageById(tile.pageId || tile.id)
		}
	},

	loaded: ({ tile }) => {
		tile.$el.html(tile.page.get('content'));
	}
});
