const PREFIX = 'diary-filter__action';
const CLASSNAMES = {
	month: (modifier) => `${PREFIX}--month${modifier ? `--${modifier}` : ''}`,
	year: (modifier) => `${PREFIX}--year${modifier ? `--${modifier}` : ''}`,
	all: (modifier) => `${PREFIX}--all${modifier ? `--${modifier}` : ''}`
};
export default {
	basic: PREFIX,
	month: CLASSNAMES.month(),
	monthSelected: CLASSNAMES.month('selected'),
	monthHidden: CLASSNAMES.month('hide'),
	year: CLASSNAMES.year(),
	yearSelected: CLASSNAMES.year('selected'),
	all: CLASSNAMES.all(),
	allSelected: CLASSNAMES.all('selected')
};
