'use strict';

import Backbone from 'backbone';

const LastSeenPageModel = Backbone.Model.extend({

	idAttribute: 'assessmentInstanceId',

	url: function () {
		return 'backend-commons/assessmentinstance/rest/assessmentinstance/view/' + this.get(this.idAttribute);
	}
});

export default LastSeenPageModel;

