var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ol",
    { staticClass: "sidebar-items" },
    _vm._l(_vm.items, function(item) {
      return _c(
        "li",
        {
          key: item.label + ":" + item.icon,
          staticClass: "sidebar-items__item"
        },
        [
          _c("sidebar-item", {
            attrs: {
              item: item,
              "can-access": _vm.canAccess(item),
              type: _vm.type,
              clickable: _vm.clickable
            }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }