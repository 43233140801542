import { assign, get, omit, reduce, size } from 'lodash';
import t from 'service/lang/translate';
import datetime from 'service/datetime/datetime';
import cardUrl from 'cwcardurl';
import store from 'store';
import { TREATMENT } from 'store/treatments/rp-treatment';

const urlParams = (params) => store.getters.userType === 'clinician' ?
	omit(params, 'treatmentId') :
	omit(params, 'respondentId');

export const labels = ({ config, entity }) => reduce(config, (result, entry) => {
	const DATE_FORMAT = 'DD MMM YYYY HH mm';

	result.push({
		completedGoalActivities: {
			icon: 'activity',
			title: t('goals.openActivities'),
			value: entity.activitiesNotCompletedCount
		},
		uncompletedGoalActivities: {
			icon: 'activity',
			title: t('goals.completedActivities'),
			value: entity.activitiesCompletedCount
		},
		goalDeadline: {
			icon: 'activity',
			title: t('goals.deadline'),
			value: entity.deadline ?
				datetime(entity.deadline).toMoment().format(DATE_FORMAT) :
				t('goals.noDeadline')
		},
		goalCategories: {
			icon: 'categories',
			title: t('goals.categories'),
			value: size(entity.category) ?
				reduce(
					entity.category,
					(result, category) => `${result} ${category.name}`,
					''
				) :
				t('goals.noCategories')
		},
		activityStartDate: {
			icon: 'activity',
			title: t('goals.startDate'),
			value: entity.startDate ?
				datetime(entity.startDate).toMoment().format(DATE_FORMAT) :
				t('goals.noStartDate')
		},
		activityEndDate: {
			icon: 'activity',
			title: t('goals.endDate'),
			value: entity.deadline ?
				datetime(entity.deadline).toMoment().format(DATE_FORMAT) :
				t('goals.noEndDate')
		}
	}[entry]);
	return result;
}, []);

export const actions = (
	{ config, entity, openActivityDialog, respondentId, toggleGoalStatus, toggleActivityStatus }
) => reduce(config, (result, entry) => {
	const EDIT_LABEL = t('goals.actions.edit');

	result.push({
		editGoal: {
			label: EDIT_LABEL,
			action: () => {
				cardUrl.openCard('edit-goal', urlParams({
					goalId: entity.id,
					respondentId,
					treatmentId: store.getters[TREATMENT.ID]
				}));
			}
		},
		addGoalActivity: {
			label: t('goals.actions.addActivity'),
			action: () => {
				openActivityDialog({ goal: entity });
			}
		},
		toggleGoalStatus: {
			label: entity.isCompleted ?
				t('goals.actions.markOpen') :
				t('goals.actions.markCompleted'),
			action: () => {
				toggleGoalStatus(entity);
			}
		},
		editGoalActivity: {
			label: EDIT_LABEL,
			action: () => {
				cardUrl.openCard('edit-activity', urlParams({
					activityId: entity.id,
					goalId: entity.goal,
					respondentId,
					treatmentId: store.getters[TREATMENT.ID]
				}));
			}
		},
		toggleActivityStatus: {
			label: entity.isCompleted ?
				t('goals.actions.markOpen') :
				t('goals.actions.markCompleted'),
			action: () => {
				toggleActivityStatus(entity);
			}
		}
	}[entry]);
	return result;
}, []);

export const activitiesMapping = (
	{ activity, config, respondentId, toggleActivityStatus }
) => assign({}, activity, {
	icon: 'activity',
	labels: labels({ config: get(config, 'activityLabels'), entity: activity }),
	openAction: {
		action: () => {
			cardUrl.openCard('edit-activity', urlParams({
				activityId: activity.id,
				goalId: activity.goal,
				respondentId,
				treatmentId: store.getters[TREATMENT.ID]
			}));
		}
	},
	actions: actions({
		config: config.activityActions,
		entity: activity,
		respondentId,
		toggleActivityStatus
	})
});
