import CounterMiniTile from 'components/mini-tiles/counter';
import t from 'translate';
import store from 'store';
import {
	RESPONDENT_CONTENT_PACKAGE_INSTANCES
} from 'store/content-packages/rp-content-package-instances';
import { TREATMENT } from 'store/treatments/rp-treatment';

export default CounterMiniTile.extend({
	acl: [],
	features: ['ENABLE_RESPONDENT_INFORMATION_PACKAGES'],
	actions: () => [RESPONDENT_CONTENT_PACKAGE_INSTANCES.INIT],
	count: () => store.getters[RESPONDENT_CONTENT_PACKAGE_INSTANCES.SIZE],
	caption: t('Information packages'),
	targetCard: () => ['information-packages', {
		treatmentId: store.getters[TREATMENT.ID]
	}]
});
