import { isNumber, isUndefined, replace } from 'lodash';
import { warn } from '../../../service/log/log';

export default ({ text, guiAttributes }) => replace(text, /{(\d+)}/g, (match, id) => {
	const expression = guiAttributes.textExpression(id);

	if (isUndefined(expression)) {
		warn(`Invalid textExpression reference in text: ${text}. Couldn't find match for ${match}`);
		return '';
	}

	if (isNumber(expression) && isNaN(expression)) {
		return '';
	}

	return replace(expression, '&', '&amp;');
});
