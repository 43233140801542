import Vue from 'vue';
import { toInteger } from 'lodash';
import ibdocButton from './button';

// Quick workaround to extend assessment (and assessment studio)
// with support of IBDoc CalApp integration
// https://www.ibdoc.net/
//
// Uses Text element with special key to transform into button.

const matchType = 'Text';
const matchContent = '{external.ibd.key}';

export default ({ renderer, element, assessmentInstanceId }) => {
	if (!element || !element.instance) {
		return false;
	}

	const type = element.instance.getType();
	const content = element.instance.getContent();

	if (type === matchType && content === matchContent) {
		const el = renderer.getElementBox().firstChild;

		return new Vue({
			el,
			...ibdocButton,
			propsData: {
				assessmentInstanceId: toInteger(assessmentInstanceId)
			}
		});
	}

	return false;
};
