import { assign, isArray, reduce } from 'lodash';

const singleField = (fieldName) => ({ [fieldName]: (state) => state[fieldName] });

export const exact = (fieldName) => {
	if (isArray(fieldName)) {
		return reduce(
			fieldName,
			(result, field) => assign(result, singleField(field)),
			{}
		);
	}
	return singleField(fieldName);
};
