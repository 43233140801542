<template>
	<table v-if="items.length" :class="classPrefix" class="table table--long-text">
		<thead>
			<tr>
				<th
					v-for="(field, i) in visibleFields"
					:key="i"
					:class="`${classPrefix}__header-cell--${field.key}`"
				>
					<slot name="header" :label="field.label" />
				</th>
			</tr>
		</thead>
		<tbody>
			<tr
				v-for="item in items"
				:key="item.id"
				class="clickable-row"
				:class="`${classPrefix}__row--${item.status.toLowerCase()}`"
				tabindex="0"
				@click="onClick(item)"
			>
				<td
					v-for="field in visibleFields"
					:key="field.key + item.id"
					:class="`${classPrefix}__cell--${field.key}`"
					:aria-label="ariaLabel(field, item)"
				>
					<slot name="cell" :fieldKey="field.key" :item="item" />
				</td>
			</tr>
		</tbody>
	</table>
	<p v-else>
		{{$t('consents.noItems')}}
	</p>
</template>

<script>
import { compact, noop, get } from 'lodash';

export default {
	name: 'ConsentInstances',
	props: {
		items: {
			type: Array,
			required: true
		},
		fields: {
			type: Array,
			required: true
		},
		classPrefix: {
			type: String,
			default: 'consent-instances'
		},
		onClick: {
			type: Function,
			default: noop
		},
		givenBy: {
			type: Function,
			default: noop
		}
	},
	computed: {
		visibleFields: ({ fields }) => compact(fields)
	},
	methods: {
		ariaLabel (field, item) {
			if (field.key === 'treatment') {
				return `${field.label} ${get(item, 'treatment.name')}`;
			} else if (field.key === 'firstSubmittedBy') {
				return `${field.label} ${this.givenBy(item)}`;
			}
			return `${field.label} ${item[field.key]}`;
		}
	}
};
</script>
