import { push, remove } from './helpers';
import repository from 'repository';
import Candidate from 'repo/circles/candidate';

export default {
	namespaced: true,

	state: {
		circleId: 0,
		current: [],
		collection: {},
		totalCount: 0
	},

	getters: {
		list: (state) => state.current
	},

	mutations: {
		set: (state, { circleId, collection }) => {
			state.circleId = circleId;
			state.current = collection.toJSON();
			state.collection = collection;
			state.totalCount = +collection.total;
		},

		add: (state, { requests }) => {
			push({ from: requests, to: state.current });
		},

		remove: (state, { request }) => {
			remove({ item: request, from: state.current });
		},

		decreaseTotalCount: (state) => {
			state.totalCount--;
		}
	},

	actions: {
		init: ({ commit }, { circleId, limit }) => {
			const collection = repository.circleCandidates({ circleId, start: 0, limit });
			collection.then(() => {
				commit('set', { circleId, collection });
			});
		},

		create: ({ commit }, { userId, circleId }) => {
			const newRequest = new Candidate({ id: userId, circle: circleId });

			return newRequest.save().then(() => {
				commit('add', { requests: [newRequest.toJSON()] });
			});
		},

		loadMore: ({ commit, state }) => {
			const requests = state.collection.more();
			return requests.then(() => {
				commit('add', { requests: requests.toJSON() });
			});
		},

		accept: ({ commit }, { request }) => {
			commit('remove', { request });
			commit('decreaseTotalCount');
		},

		reject: ({ commit, state }, { request }) =>
			state.collection.get(request.id).set('circle', state.circleId).destroy().then(() => {
				commit('remove', { request });
				commit('decreaseTotalCount');
			})
	}
}
