import appContext from 'app-context';

const userId = () => appContext.getUserId();

export const message = {
	url () {
		return `backend-commons/treatment/${this.get('treatment')}/respondent/${userId()}/message/`;
	}
};

export const messages = {
	url: ({ treatment }) =>
		`backend-commons/treatment/${treatment}/message/`
};

export const messageDrafts = {
	url: ({ treatment }) =>
		`backend-commons/treatment/${treatment}/respondent/${userId()}/message/draft/`
};

export const messageDraft = {
	url () {
		return `backend-commons/treatment/${this.get('treatment')}/respondent/${
			userId()
		}/message/draft/`;
	}
};

export const messageAttachment = {
	url () {
		return `backend-commons/treatment/${this.get('treatmentId')}/respondent/${
			userId()
		}}/message/draft/${this.get('messageId')}/attachment/`;
	}
};

export const messageAttachments = {
	url: ({ treatmentId, messageId }) =>
		`backend-commons/treatment/${treatmentId}/respondent/${
			userId()
		}/message/draft/${messageId}/attachment/`
};

export const messageDraftAttachment = {
	url () {
		return `backend-commons/treatment/${this.get('treatmentId')}/respondent/${
			userId()
		}/message/draft/${this.get('messageDraftId')}/attachment/`;
	}
};

export const messageDraftAttachments = {
	url: ({ treatmentId, messageDraftId }) =>
		`backend-commons/treatment/${treatmentId}/respondent/${
			userId()
		}/message/draft/${messageDraftId}/attachment/`
};

export default {
	message,
	messages,
	messageDrafts,
	messageDraft,
	messageAttachment,
	messageAttachments,
	messageDraftAttachment,
	messageDraftAttachments
};
