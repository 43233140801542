import SingleEntity from '../shared/single-entity';
import urls from './urls';

/**
 * TreatmentTypeRole Entity
 * @class TreatmentTypeRole
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	url: function () {
		return urls.singleRole.url(this.get('treatmentType'), this.getId());
	},
	eventNamespace: 'treatment-type-role'
});

