import { find, get, isBoolean, reduce } from 'lodash';
import acl from 'service/acl/acl';

/**
 * @param {object} params -         Parameters.
 * @param {object|string} params.field -   Field to be (maybe) shown.
 * @param {string} params.matchBy - Optional key to match config with field.
 * @param {Array} params.config -   List of field configs.
 * @returns {object|boolean} -      Field or `false`.
 * @example
 * 		showField({
 *				config: get(this.config(), 'fields', {}),
 *				matchBy: 'key',
 *				field: {
 *					label: t('User name'),
 *					key: 'respondentUsername',
 *					css: `${className}username`,
 *					render: (val, { $td }) => {
 *						$td.text(val);
 *				}
 *			})
 *
 */
export default ({ field, matchBy = 'key', config }) => {
	const usernameCfg = find(config, { [matchBy]: field[matchBy] });

	if (!get(usernameCfg, 'showConditions')) {
		return field;
	}

	const show = reduce(usernameCfg.showConditions, (show, condition) => {
		const check = usernameCfg[condition];

		if (condition === 'ACL' && check) {
			return isBoolean(check) ?
				check :
				acl.checkAccess(check);
		}
		return false;
	}, true);

	return show && field;
};
