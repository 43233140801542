import { post } from './urls';
import SingleEntity from '../shared/single-entity';
import { sync } from 'backbone';

export default SingleEntity.extend({
	url: post.url,
	eventNamespace: 'circle-post',

	// replace nested objects with their respective IDs so b💩ckend doesn't cry in a pillow (maybe)
	onSync (method, model) {
		if (method === 'update' || (method === 'create')) {
			const circle = model.get('circle').id || model.get('circle');
			const circleUser = model.get('circleUser').id || model.get('circleUser');

			model.set({ circle, circleUser });
		}
		// eslint-disable-next-line prefer-rest-params
		return sync(...arguments);
	}
});
