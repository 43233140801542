<template>
	<section v-if="difference" class="warning timezone-warning">
		<p>
			<span>{{$t('The timezone of')}}</span>
			<strong> {{displayName}} </strong>
			<span>{{$t('is displayed')}}</span>
		</p>
		<p>{{timezoneText}}</p>
	</section>
</template>

<script>
import t from 'translate';
import timezoneDifference from 'service/timezone-difference';
import { clinicianName, respondentName } from 'service/display-name/display-name';
import { assign, constant } from 'lodash';

export default {
	props: {
		personType: {
			type: String,
			default: 'respondent'
		},
		respondent: {
			type: Object,
			default: constant({})
		},
		clinician: {
			type: Object,
			default: constant({})
		}
	},
	computed: {
		person: ({ personType, respondent, clinician }) => personType === 'clinician' ?
			assign(
				{},
				clinician,
				{
					timezone: clinician.timezoneName,
					computedDisplayName: clinicianName(clinician, { preventEscape: true })
				}
			) :
			assign(
				{},
				respondent,
				{
					timezone: respondent.respondentTimezoneName,
					computedDisplayName: respondentName(respondent, { preventEscape: true })
				}
			),

		userTimezone: ({ $store }) => $store.getters['userTimezone'],

		difference: ({ person, userTimezone }) =>
			timezoneDifference(person.timezone, userTimezone),

		displayName: ({ person }) => person.computedDisplayName,

		timezoneText () {
			return t(
				'The timezone ({tz1}) differs from your own ({tz2}) by {difference}',
				{
					tz1: this.person.timezone,
					tz2: this.userTimezone,
					difference: this.difference
				}
			);
		}
	}
};
</script>
