export default function (value) {
	this.getRawValue = function () {
		return (value instanceof Date) ?
			value :
			undefined;
	};

	this.getBoolean = function () {
		return typeof value == 'object';
	};

	this.getNumber = function () {
		return (typeof value == 'object') ?
			value.getTime() :
			'Invalid date';
	};

	this.getString = function () {
		return (value instanceof Date) ?
			value.toLocaleDateString() :
			'Invalid date';
	};

	this.toString = function () {
		return `{Date:${this.getString()}}`;
	};

}
