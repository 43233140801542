<template>
	<ol :class="mainClassName">
		<transition-group :name="transitionName" tag="div">
			<li
				v-for="item in processedItems"
				:key="itemIndex(item)"
				:class="elementClassName('item')"
			>
				<agenda-item
					:actions="item.actions"
					:collapsed="collapsed"
					:description="item.description"
					:horizontal-labels="item.horizontalLabels"
					:icon="item.icon"
					:item-type="item.itemType"
					:labels="item.labels"
					:layout="item.layout"
					:on-click="item.onClick"
					:status="item.status"
					:store-path="storePath"
					:subtitle="item.subtitle"
					:title="item.title"
					:type-label="item.typeLabel"
				/>
			</li>
		</transition-group>
	</ol>
</template>

<script>
import './agenda-item';
import Vue from 'vue';
import { requiredArray, requiredString } from './props';
import agendaMixin from './agenda-mixin';
import { AGENDA_ITEM, AGENDA_ITEMS } from './constants';
import { assign, get, includes, keys, map, some, filter } from 'lodash';
import canAccess from 'core/engine/card/services/can-access';
import cardUrl from 'core/engine/card/services/card-url';

const actionContainsProps = (
	{ action, props }
) => some(keys(action), (key) => includes(props, key));

export default Vue.component(AGENDA_ITEMS, {
	mixins: [agendaMixin],
	props: {
		items: requiredArray,
		collapsed: {
			type: [Boolean, String],
			default: false
		},
		direction: requiredString,
		storePath: requiredString
	},
	data: () => ({
		type: 'default'
	}),

	computed: {
		mainClassName: ({ $options, type }) => [$options.name, `${$options.name}--${type}`],
		transitionName: ({ direction }) => `fade-${direction}`,

		processedItems: ({ items }) => map(items, (item) => assign({}, item, {
			actions: filter(map(
				item.actions,
				(action) => {
					if (
						!action.component &&
						(
							actionContainsProps({ action, props: ['action', 'request'] }) ||
							canAccess(get(action, 'card'))
						)
					) {
						return {
							title: action.label,
							action: action.action || function () {
								cardUrl.openCard(action.card, action.args);
							}
						};
					}
					return action;
				}

			), (action) => (
				(!action.component && canAccess(get(action, 'card'))) ||
					action.component ||
					action.title
			)),
			onClick: () => {
				const action = item.openAction;

				if (!action) {
					return;
				}

				if (action.action) {
					action.action();

				} else {
					cardUrl.openCard(action.card, action.args);
				}
			}
		}))
	},

	methods: {
		itemIndex: (item) => item.itemType + item.id,
		itemClassName: (suffix) => `${AGENDA_ITEM}__${suffix}`
	}
});
</script>
