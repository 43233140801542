import repository from 'repository';
import moment from 'moment';
import { filter, size } from 'lodash';
import initEntities from '../__helpers/init-entities';

export default {
	namespaced: true,

	state: {
		data: [],
		collection: {}
	},

	getters: {
		size: (state) => state.data.length,
		available: (state) => filter(state.data, { assessmentInstanceIsSubmitted: false }),
		expired: (state) => filter(state.data, (assessmentInstance) =>
			assessmentInstance.assessmentInstanceEndDate.unixtime * 1000 < moment().valueOf() &&
			assessmentInstance.assessmentInstanceIsSubmitted === false
		),
		notSubmittedCount: (state) => size(filter(state.data, (assessmentInstance) =>
			!assessmentInstance.assessmentInstanceIsSubmitted && !assessmentInstance.isSubmitted
		))
	},

	mutations: {
		set: (state, { assessmentInstances, collection }) => {
			state.data = assessmentInstances;
			state.collection = collection;
		},

		add: (state, assessmentInstance) => {
			state.data.push(assessmentInstance);
		}
	},

	actions: {
		initForTreatment: ({ commit }, { treatmentId }) => {
			const collection = repository.treatmentAssessmentInstances(treatmentId);

			return collection.then(() => {
				commit('set', { assessmentInstances: collection.toJSON(), collection });
			});
		},

		initForRespondent: ({ commit }, { respondentId }) => {
			const collection = repository.respondentAssessmentInstances(respondentId);

			return collection.then(() => {
				commit('set', { assessmentInstances: collection.toJSON(), collection });
			});
		},

		init: initEntities('assessmentInstances/init', {
			acl: 'administrator.respondents.assessmentplans'
		}),

		add: ({ commit, state }, assessmentInstance) => {
			state.collection.add(assessmentInstance);
			commit('add', assessmentInstance);
		}
	}
};
