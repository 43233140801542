import { forEach, includes, isUndefined } from 'lodash';
import t from 'translate';

/*
Example config
itemConfig = {
	"type": "parsed",
	"parse": [{
		"when": [true],
		"then": "Yes"
	}, {
		"when": [false],
		"then": "No"
	}]
}
*/

function mt (key, should) {
	return should ? t(key) : key;
}

function parse (tileConfig, itemConfig, rawContent) {
	const shouldTranslate = isUndefined(tileConfig.translate) ? true : tileConfig.translate;
	let readableValue = rawContent;

	if (!itemConfig.parse || itemConfig.type !== 'parsed') {
		return readableValue;
	}

	forEach(itemConfig.parse, (parseRule) => {
		if (includes(parseRule.when, rawContent)) {
			readableValue = mt(parseRule.then, shouldTranslate);
			return false;
		}

		return true;
	});

	return readableValue;
}

export default ({ tileConfig, itemConfig, rawContent }) => ({
	component: 'span',
	props: {},
	value: parse(tileConfig, itemConfig, rawContent)
});
