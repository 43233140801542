<template>
	<span v-if="display" class="sidebar-counter">
		<span v-if="loaded">{{count}}</span>
		<span v-else v-loader-spinner:small />
	</span>
</template>

<script>
import { get, has, isUndefined, isFunction } from 'lodash';
import counters from '../counters';

export default {
	props: {
		counter: {
			type: String,
			default: ''
		},
		type: {
			type: String,
			required: true
		}
	},

	data: () => ({
		loaded: false,
		watchGetter: null
	}),

	computed: {
		display: ({ count }) => !isUndefined(count),

		counterDefinition: ({ type, counter }) => get(counters, `[${type}][${counter}]`),

		count: ({ $store, counterDefinition }) => {
			if (!counterDefinition) {
				return undefined;
			}
			const { getter, value } = counterDefinition.count();

			return getter ? $store.getters[getter] : value;
		},

		watcher: ({ $store, watchGetter }) => $store.getters[watchGetter]
	},
	watch: {
		watcher (val) {
			if (val) {
				this.updateCounter();
			}
		}
	},

	mounted () {
		this.assignWatcher();

		if (!this.watchGetter) {
			this.updateCounter();
		}
	},
	methods: {
		async updateCounter () {
			this.loaded = false;

			if (has(this.counterDefinition, 'before')) {
				await this.counterDefinition.before();
			}
			this.loaded = true;
		},
		assignWatcher () {
			if (this.counterDefinition && isFunction(this.counterDefinition.watch)) {
				const { getter, value } = this.counterDefinition.watch();
				this.watchGetter = getter || value;
			}
		}
	}
};
</script>
