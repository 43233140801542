var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("consent-instances", {
    attrs: {
      items: _vm.consentInstances,
      fields: _vm.fields,
      "on-click": _vm.openConsent,
      "given-by": _vm.givenBy
    },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function(ref) {
          var label = ref.label
          return [_vm._v("\n\t\t" + _vm._s(label) + "\n\t")]
        }
      },
      {
        key: "cell",
        fn: function(ref) {
          var item = ref.item
          var fieldKey = ref.fieldKey
          return [
            _c("p", { staticClass: "table-cell-label" }, [
              _vm._v("\n\t\t\t" + _vm._s(_vm.label(fieldKey)) + "\n\t\t")
            ]),
            _vm._v(" "),
            _c(
              "span",
              [
                fieldKey === "name"
                  ? _c("icon", {
                      attrs: {
                        name: _vm.iconClass(item),
                        title: _vm.description(item)
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                fieldKey === "treatment"
                  ? _c("span", [_vm._v(_vm._s(_vm.treatmentName(item)))])
                  : fieldKey === "firstSubmittedBy"
                  ? _c("span", [_vm._v(_vm._s(_vm.givenBy(item)))])
                  : _c("span", [_vm._v(_vm._s(item[fieldKey]))])
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }