import { clinicianName, respondentName } from 'service/display-name/display-name';
import datetime from 'datetime';
import t from 'translate';
import { assign, filter, find, forEach, isFunction, map, size } from 'lodash';
import repository from 'repository';
import { RESPONDENT } from 'store/respondents/respondent';

export default {
	namespaced: true,

	state: {
		data: [],
		collection: null,
		drafts: [],
		draftsCollection: null
	},

	getters: {
		// eslint-disable-next-line max-params
		processed: (state, getters, rootState, rootGetters) => map(state.data, (message) => {
			if (message.senderType === 'CLINICIAN') {
				message.author = clinicianName(message.administrator);

			} else if (message.senderType === 'RESPONDENT') {
				message.author = respondentName(message.respondent);
			}

			const timezone = rootGetters[RESPONDENT.TIMEZONE] || rootGetters.userTimezone;

			message.date = datetime(message.sentAt)
				.setTimezone(timezone)
				.toMoment()
				.format(`${t('date.formats.dateOnly')}<br /> ${t('date.formats.timeOnly')}`);

			return message;
		}),

		respondents: (state) => {
			const respondents = [];

			forEach(state.data, (message) => {
				const respondent = find(respondents, {
					respondentId: message.respondent.respondentId
				});

				if (!respondent) {
					respondents.push(assign(message.respondent, {
						displayName: respondentName(message.respondent),
						messagesCount: 1
					}));

				} else {
					respondent.messagesCount += 1;
				}
			});

			return respondents;
		},

		unansweredCount: (state) => filter(state.data, { answered: false }).length,
		respondentUnansweredCount: (state) => filter(
			state.data,
			{ answered: false, senderType: 'RESPONDENT' }
		).length,
		size: (state) => size(state.data)
	},

	mutations: {
		set: (state, { messages, collection }) => {
			state.data = messages;
			state.collection = collection;
		},

		setDrafts: (state, { drafts, collection }) => {
			state.drafts = drafts;
			state.draftsCollection = collection;
		},

		toggleAnswered: (state, { id, answered }) => {
			find(state.data, { id }).answered = !answered;
		}
	},

	actions: {
		init: ({ commit }, { respondentId, treatmentId }) => {
			const collection = repository.messages({ respondentId, treatmentId });

			return collection.then(() => {
				commit('set', { messages: collection.toJSON(), collection });
			});
		},

		initUnreplied: ({ commit }) => {
			const collection = repository.unrepliedMessages();

			return collection.then(() => {
				commit('set', { messages: collection.toJSON(), collection });
			});
		},

		initDrafts: ({ commit, rootGetters }) => {
			const collection = repository.messageDrafts({
				respondent: rootGetters.respondent.respondentId,
				treatment: rootGetters.treatment.id
			});

			return collection.then(() => {
				commit('setDrafts', { drafts: collection.toJSON(), collection });
			});
		},

		set: ({ commit }, payload) => {
			commit('set', payload);
		},

		markAnswered: ({ commit, dispatch }, { message, callback }) => {
			commit('toggleAnswered', message);
			const callbackResult = callback(message);

			if (callbackResult.then && isFunction(callbackResult.then)) {
				callbackResult.then(() => {
					dispatch('notifications/init', { type: 'messages' }, { root: true });
				});
			}
		}
	}
};
