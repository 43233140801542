import t from 'translate';
import { assign, find, findIndex, split } from 'lodash';
import repository from 'repository';
import TileView from 'tile-view';
import moment from 'moment';
import { compile } from 'handlebars';
import tpl from './tile-template';
import cls from './class-names';

const events = {
	[`click .${cls.month}`]: 'filterByMonth',
	[`click .${cls.year}`]: 'filterByYear',
	[`click .${cls.all}`]: 'showAll'
};

const ARIA_SELECTED = 'aria-selected';

export default TileView.extend({
	template: compile(tpl),
	className: 'diary__filter',
	title: t('Filter diary'),
	events,
	features: ['RESPONDENT_DIARY'],

	appEvents: {
		'diary-entry.create diary-entry.update diary-entry.delete': 'redraw'
	},

	cardData: () => ['filter'],

	tileData: () => ({ entries: repository.getDiaryEntries() }),

	clearSelected () {
		this.$(`.${cls.month}`).removeClass(cls.monthSelected);
		this.$(`.${cls.year}`).removeClass(cls.yearSelected);
		this.$(`.${cls.all}`).removeClass(cls.allSelected);

		this.$(`.${cls.month}`).attr(ARIA_SELECTED, 'false');
		this.$(`.${cls.year}`).attr(ARIA_SELECTED, 'false');
		this.$(`.${cls.all}`).attr(ARIA_SELECTED, 'false');
	},

	$current (unit) {
		const $current = this.$(`.${cls[unit]}[data-${unit}=${this.filter[unit]}]`);

		$current.setSelected = () => {
			$current.addClass(cls[`${unit}Selected`]);
			$current.attr(ARIA_SELECTED, 'true');
		};

		return $current;
	},

	redraw () {
		this.calculateFilters();
		this.clearSelected();
		const { month, year } = this.filter;

		this.$el.html(this.template({
			filters: this.filters,
			counterAll: this.entries.size()
		}));

		if (year) {
			this.$current('year').setSelected();
		}

		if (month) {
			this.$(`.${cls.month}[data-year!="${year}"]`).addClass(cls.monthHidden);
			this.$(`.${cls.month}[data-year="${year}"][data-month="${month}"]`)
				.removeClass(cls.monthHidden)
				.addClass(cls.monthSelected)
				.attr(ARIA_SELECTED, 'true');

		} else {
			this.$(`.${cls.month}`).addClass(cls.monthHidden);
		}
	},

	loaded () {
		// show only entries from current month
		const date = new Date();

		assign(this.filter, {
			month: date.getMonth(),
			year: date.getFullYear()
		});

		this.redraw();

		this.cardContext().trigger('diary:filter-by-month', this.filter.month, this.filter.year);
	},

	calculateFilters () {
		this.filters = [];
		this.collection = this.entries.sort();

		this.collection.each((model) => {
			const date = moment(model.get('createdAt'), 'YYYY-MM-DD HH:mm:ss');
			this.increaseFilter(`${date.year()}`, `${date.month()}`);
		});

		return this.filters;
	},

	increaseFilter (year, month) {
		// if year doesn't exist
		let filterYear = find(this.filters, { year });

		if (!filterYear) {
			filterYear = {
				year,
				months: [],
				counter: 1
			};
			this.filters.push(filterYear);

		} else {
			filterYear.counter++;
		}

		// if month doesn't exist
		const monthIdx = findIndex(filterYear.months, { month });

		if (monthIdx !== -1) {
			filterYear.months[monthIdx].counter++;

		} else {
			const monthNames = split(t('gui.calendar.monthFullNames'), '\n');

			filterYear.months.push({
				month,
				counter: 1,
				label: monthNames[parseInt(month, 10)]
			});
		}
	},

	filterByYear (e) {
		e.stopPropagation();
		this.clearSelected();
		assign(this.filter, {
			year: this.$(e.currentTarget).data('year'),
			month: null
		});
		this.cardContext().trigger('diary:filter-by-year', parseInt(this.filter.year, 10));

		this.$current('year').setSelected();
		this.$(`.${cls.month}`).addClass(cls.monthHidden);
		this.$(`.${cls.month}[data-year="${this.filter.year}"]`).removeClass(cls.monthHidden);
	},

	filterByMonth (e) {
		e.stopPropagation();
		this.clearSelected();
		this.filter.month = this.$(e.currentTarget).data('month');
		this.cardContext().trigger(
			'diary:filter-by-month',
			parseInt(this.filter.month, 10),
			parseInt(this.filter.year, 10)
		);

		this.$(e.currentTarget).addClass(cls.monthSelected);
		this.$(e.currentTarget).attr(ARIA_SELECTED, 'true');
	},

	showAll (e) {
		e.stopPropagation();
		this.clearSelected();
		assign(this.filter.year, {
			month: null,
			year: null
		});

		this.cardContext().trigger('diary:show-all');

		this.$(`.${cls.all}`).addClass(cls.allSelected);
		this.$(`.${cls.all}`).attr(ARIA_SELECTED, 'true');
		this.$(`.${cls.month}`).addClass(cls.monthHidden);
	}
});
