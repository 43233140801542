import t from 'translate';
import appBar from 'app-bar';
import store from 'store';
import { RESPONDENT } from 'store/respondents/respondent';
import { toggleGoalComplete, editGoal, removeGoal } from '../shared/appbar-buttons';

const card = {
	name: 'goal',
	url: 'treatment/:treatmentId/goal/:goalId',
	title: t('goals.goal'),

	ctrl: ({ actions, close }, { goalId }) => actions([
		['goal/init', {
			respondentId: store.getters[RESPONDENT.RESPONDENT_ID],
			goalId
		}]
	]).then(() => {
		const mountButtons = () => {
			appBar.clear(card.name);

			appBar.addButton(toggleGoalComplete({ card, done: mountButtons }));
			appBar.addButton(editGoal({ card, goalId }));
			appBar.addButton(removeGoal({ card, done: close }));
		};

		mountButtons();
	})
};

export { card as Goal };
