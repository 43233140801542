<template>
	<div
		class="inline-edit__component"
		:class="{'inline-edit__component--before-hide': animationOut}"
	>
		<div
			ref="label"
			class="inline-edit__label"
			:class="{'inline-edit__label--hidden': inputVisible}"
		>
			<button
				class="inline-edit__label-button"
				:aria-label="titles.edit"
				:aria-pressed="inputVisible.toString()"
				tabindex="0"
				:disabled="!allowEdit"
				@click="showInput"
			>
				<span class="inline-edit__label-text">{{name}}</span>
			</button>
			<button
				v-if="allowDelete"
				class="inline-edit__remove-button"
				:title="titles.remove"
				tabindex="0"
				@click="remove"
			>
				<span class="icon--times" />
			</button>
		</div>

		<form
			class="inline-edit__input"
			:class="[
				{'inline-edit__input--visible': inputVisible},
				{'inline-edit__input--invalid': !valid}
			]"
			@submit.prevent="save"
		>
			<label>
				<input
					ref="input"
					v-focus-when="inputVisible"
					v-blur-when="!inputVisible"
					v-model="name"
					class="inline-edit__input-element"
					type="text"
					:maxlength="maxSize"
					:style="{width: inputWidth + 'px'}"
					:aria-invalid="!valid"
					tabindex="0"
					@keydown="onKeydown"
					@keydown.esc="cancel"
					@keydown.enter="save"
				/>
				<i class="inline-edit__input-edit icon--pencil" />
				<button
					v-once
					class="inline-edit__input-save"
					type="submit"
					:title="titles.save"
					tabindex="0"
				>
					<i class="icon--check" />
				</button>
				<button
					v-once
					class="inline-edit__input-cancel"
					:title="titles.cancel"
					tabindex="0"
					@click.prevent="cancel"
				>
					<i class="icon--times" />
				</button>
				<p v-once class="inline-edit__input-description">{{titles.inputDescription}}</p>
			</label>
		</form>
	</div>
</template>

<script>
import t from 'translate';
import Vue from 'vue';
import { Model } from 'backbone';
import { clone, debounce, noop } from 'lodash';

/*
*	Inline-edit component (global scope)
*
*	@usage <inline-edit :model="model" />
*
*	@param {object} model *required - model to be edited
*	@param {function} onSave - action called on saving the model
*	@param {function} onDelete - action called on deleting the model
*	@param {number} maxSize - input field max length
*
 */

export default Vue.component('inline-edit', {
	props: {
		model: {
			type: Object,
			required: true,
			validator: (value) => value instanceof Model
		},

		onSave: {
			type: Function,
			default: noop
		},
		onDelete: {
			type: Function,
			default: noop
		},
		maxSize: {
			type: Number,
			default: 64
		},
		allowEdit: {
			type: Boolean,
			default: true
		},
		allowDelete: {
			type: Boolean,
			default: true
		}
	},

	data () {
		return {
			inputVisible: false,
			animationOut: false,
			titles: {
				inputDescription: t(
					'Maximum length {maxSize} characters',
					{ maxSize: this.maxSize }
				),
				edit: t('Click to edit'),
				save: t('Save'),
				cancel: t('Cancel'),
				remove: t('Remove')
			},

			backupName: '',
			inputWidth: '',
			valid: true,

			name: this.model.attributes.name
		};
	},

	mounted () {
		this.recalculateWidth(1000);
	},

	methods: {
		showInput () {
			this.$data.inputVisible = true;
			this.$data.backupName = clone(this.$data.name);
			this.recalculateWidth();
		},

		hideInput () {
			this.$data.animationOut = true;
			this.recalculateWidth();

			setTimeout(() => {
				this.$data.animationOut = false;
				this.$data.inputVisible = false;
			}, 250);
		},

		recalculateWidth (delay = 0) {
			setTimeout(() => {
				this.$data.inputWidth = this.$refs.label.offsetWidth;
			}, delay);
		},

		save: debounce(function () {
			this.validate(this.$data.name) && this.onSave(
				{ model: this.model, data: this.$data }
			).then(() => {
				this.hideInput();
			});
		}, 100),

		cancel () {
			this.$data.name = this.$data.backupName;
			this.setValid();
			this.hideInput();
		},

		remove () {
			this.onDelete({ model: this.model, data: this.$data }).then(() => {
				this.$emit('deleteComponent');
				this.$destroy();
			});
		},

		onKeydown () {
			this.recalculateWidth();
			this.setValid();
		},

		validate (str) {
			return str.length ? this.setValid() : this.setInvalid();
		},

		setValid () {
			this.$data.valid = true;
			return true;
		},

		setInvalid () {
			this.$data.valid = false;
			return false;
		}
	}
});
</script>
