import repository from 'repository';
import { set, reset } from '../__helpers/mutations';
import translateItems from '../__helpers/translate-items';
import { filter, find, includes, map, reduce } from 'lodash';

export default {
	namespaced: true,

	state: {
		currentTreatment: {},
		data: [],
		defaultValues: []
	},

	getters: {
		list: (state) => state.data,
		translatedList: (state, getters) =>  translateItems({
			items: getters.list,
			prefix: 'backendDictionary.'
		}),
		currentTreatment: (state) => state.currentTreatment,
		defaultValues: (state) => state.defaultValues
	},

	mutations: {
		set: set(),
		reset: reset(),
		setTreatment: set('currentTreatment'),
		setDefaultValues: set('defaultValues'),
		setSingleAttribute: (state, payload) => {
			state.data.push(payload);
		}
	},

	actions: {
		init: ({ commit }) => repository.getTreatmentFields().then((response) => {
			commit('set', response);
		}),

		reset: ({ commit }) => {
			commit('reset');
		},

		// used in respondent portal
		initForCurrentRespondent: async (
			{ commit },
			{ treatmentType, treatmentTypeAttributes }
		) => {
			const treatments = await repository.treatments();

			const treatment = find(
				treatments,
				(treatment) => +treatment.treatmentType.id === +treatmentType
			);
			commit('setTreatment', treatment);

			let allAttributes;

			try {
				allAttributes = await repository.treatmentAttributeValues(treatment.id);
			} catch (e) {
				return false;
			}

			const attributes = filter(
				allAttributes,
				(attr) => includes(map(treatmentTypeAttributes, (a) => +a), +attr.typeAttribute)
			);

			commit('set', attributes);
			return attributes;
		},

		// used in respondent portal
		saveSingle: ({ commit }, attr) =>
			repository.saveSingleTreatmentAttribute(attr).then((attribute) => {
				commit('setSingleAttribute', attribute);
			}),

		// used in respondent portal
		saveForCurrentRespondent: async ({ commit, dispatch }, attrs) => {
			const promises = reduce(attrs, (acc, curr) => {
				if (!curr.isReadonly) {
					acc.push(dispatch('saveSingle', curr));
				}
				return acc;
			}, []);
			const values = await Promise.all(promises);
			commit('set', values);
			return values;
		},

		// used in respondent portal
		initDefaultValues: async ({ commit }, { treatmentTypeId }) => {
			const defaultValues = await repository.defaultTreatmentAttributeValues(treatmentTypeId);
			commit('setDefaultValues', defaultValues);
			return defaultValues;
		}
	}
};
