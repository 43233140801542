<template>
	<consent-instances
		:items="consentInstances"
		:fields="fields"
		:on-click="openConsent"
		:given-by="givenBy"
	>

		<template #header="{ label }">
			{{label}}
		</template>

		<template #cell="{ item, fieldKey }">
			<p class="table-cell-label">
				{{label(fieldKey)}}
			</p>
			<span>
				<icon
					v-if="fieldKey === 'name'"
					:name="iconClass(item)"
					:title="description(item)"
				/>
				<span v-if="fieldKey === 'treatment'">{{treatmentName(item)}}</span>
				<span v-else-if="fieldKey === 'firstSubmittedBy'">{{givenBy(item)}}</span>
				<span v-else>{{item[fieldKey]}}</span>
			</span>
		</template>

	</consent-instances>
</template>

<script>
import ConsentInstances from '../../components/consent-instances';
import t from 'translate';
import { assign, find, forEach, get, kebabCase, map } from 'lodash';
import cardUrl from 'cwcardurl';
import { NOT_SUBMITTED, labels } from '../../shared/statuses';
import { respondentName } from 'service/display-name/display-name';
import formatDate from '../../shared/format-date';
import { EMPTY_CHAR } from 'lib/chars';
import { description } from '../../shared/get-title';
import { RESPONDENT_CONSENT_INSTANCES } from 'store/consents/rp-consent-instances';
import treatmentContextParam from 'modules/treatments/util/treatment-context-param';
import { TREATMENT } from 'store/treatments/rp-treatment';

export default {
	title: t('consents.available'),
	acl: [{
		checkpoint: 'administrator.respondents.consents',
		op: 'READ'
	}],
	actions: ({ $store }) => {
		if ($store.getters[TREATMENT.ID]) {
			return [RESPONDENT_CONSENT_INSTANCES.INIT];
		}

		return [RESPONDENT_CONSENT_INSTANCES.INIT_ALL];
	},
	components: { ConsentInstances },
	data: ({ $store }) => ({
		fields: [
			{ key: 'name', label: t('consents.name') },
			$store.getters[TREATMENT.ID] ?
				null :
				{ key: 'treatment', label: t('consents.treatment') },
			{ key: 'statusLabel', label: t('consents.status') },
			{ key: 'assignedTo', label: t('consents.assignedTo') },
			{ key: 'firstSubmittedBy', label: t('consents.givenBy') },
			{ key: 'submittedOn', label: t('consents.submittedOn') },
			{ key: 'lastModifiedOn', label: t('consents.lastModifiedOn') }
		]
	}),
	computed: {
		consentInstances: ({ $store, assignedToMe, roleAndName }) => map(
			$store.getters[RESPONDENT_CONSENT_INSTANCES.ITEMS],
			(item) => assign({}, item, {
				statusLabel: labels()[item.status],
				assignedTo: assignedToMe(item) ?
					t('consents.me') :
					roleAndName(item.respondent),
				submittedOn: formatDate(item.firstSubmittedAt, $store.state.user.timezoneName),
				lastModifiedOn: formatDate(item.updatedAt, $store.state.user.timezoneName)
			})
		)
	},
	methods: {
		openConsent (consent) {
			cardUrl.openCard('consent', {
				consentInstanceId: consent.id,
				...treatmentContextParam()
			});
		},

		assignedToMe (consent) {
			return get(consent, 'respondent.id') === this.$store.getters.userId;
		},

		roleAndName (respondentItem) {
			const relation = get(respondentItem, 'relationRoleName', '');
			const respondent = respondentName(respondentItem, { preventEscape: true });
			return `${relation} (${respondent})`;
		},

		givenBy (consent) {
			let givenByDescription = '';
			const userId = this.$store.getters.userId;
			const givenBy = consent.givenBy;
			const givenByMe = find(givenBy, { id: userId });
			const assignedToMe = this.assignedToMe(consent);

			const routes = [{
				when: () => consent.status === NOT_SUBMITTED,
				then: () => EMPTY_CHAR
			}, {
				when: () => givenByMe && assignedToMe,
				then: () => t('consents.me')
			}, {
				when: () => !givenByMe && assignedToMe,
				then: () => map(givenBy, this.roleAndName).join(', ')
			}, {
				when: () => givenByMe && !assignedToMe,
				then: () => [
					t('consents.meOnBehalf'),
					this.roleAndName(consent.respondent)
				].join(' ')
			}];

			forEach(routes, (route) => {
				const when = route.when();

				if (when) {
					givenByDescription = route.then();
				}
				return !when;
			});

			return givenByDescription;
		},

		treatmentName: (item) => get(item, 'treatment.name'),

		label (key) {
			return get(find(this.fields, { key }), 'label', '');
		},
		description,
		iconClass: (item) => {
			const status = kebabCase(item.status);
			return `consent-${status}`;
		}
	}
};
</script>
