import guiAttributesFactory from '../core/gui-attributes-factory';
import { assign, isString, replace, split } from 'lodash';

const AnswerOption = function (answerOption) {
	const boundsCoords = split(
		isString(answerOption.bounds) ? answerOption.bounds : '',
		/\s*,\s*/
	);
	const bounds = [];

	for (let i = 0; i < (boundsCoords.length - 1); i += 2) {
		bounds.push({
			x: parseFloat(boundsCoords [i]),
			y: parseFloat(boundsCoords [i + 1])
		});
	}

	assign(this, {
		guiAttributes: guiAttributesFactory(answerOption.code),
		getCode: () => answerOption.code,
		getType: () => replace(answerOption.type, /^questionnaire:/, ''),
		getDescription: () => answerOption.description,
		getLabel: () => answerOption.label,
		getReverseValue: () => parseFloat(answerOption.reverseValue),
		getValue: () => parseFloat(answerOption.value),
		getExpressionMap: () => answerOption.expressionMap.getMap(),
		getBounds: () => bounds,
		image: () => answerOption.imageFile
	});
};

const answerOptions = {
	ImageOption: AnswerOption,
	TextOption: AnswerOption,
	ZoneOption: AnswerOption
};

export default answerOptions;
