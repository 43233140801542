import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import RespondentReportInstance from './entity';

const RespondentReportsEntity = MultipleEntities.extend({
	urls,
	model: RespondentReportInstance,
	idName: 'reportInstanceIdentifier',
	eventNamespace: 'respondent-report-instance',

	getAll (params) {
		return this.retrieve('allReports', params);
	}
});

export default new RespondentReportsEntity();

