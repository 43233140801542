<template>
	<form @submit.prevent="onSubmit">
		<slot />
		<text-input
			v-model="goal.name"
			:required="true"
			:mandatory="true"
			:invalid="$v.goal.name.$error"
			label="goals.editGoal.name"
			@input="$v.goal.name.$touch()"
		/>
		<select-input
			v-if="showTreatmentSelect"
			v-model="goal.treatment"
			:options="treatments"
			:required="true"
			:mandatory="true"
			:invalid="$v.goal.treatment.$error"
			:reduce="(treatment) => treatment.id"
			label="goals.editGoal.chooseTreatment"
			item-label="name"
			@input="$v.goal.treatment.$touch()"
		/>
		<select-input
			v-model="goal.category"
			multiple
			:options="categories"
			label="goals.editGoal.categories"
			item-label="name"
		/>
		<datetime-input
			v-model="deadlineDatetime"
			label="goals.editGoal.deadline"
		/>
		<text-input
			v-model="goal.description"
			label="goals.editGoal.description"
			:multiline="true"
		/>
		<button-group>
			<submit-button
				:can-submit="!$v.goal.$invalid"
				label="goals.editGoal.submit"
			/>
			<plain-button :on-click="onCancel" />
		</button-group>
	</form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { fromUTC, toUTC } from '../shared/date-utils';

export default {
	name: 'EditGoal',
	props: {
		goal: {
			type: Object,
			required: true
		},
		treatments: {
			type: Array,
			required: true
		},
		categories: {
			type: Array,
			required: true
		},
		onCancel: {
			type: Function,
			required: true
		},
		onSubmit: {
			type: Function,
			required: true
		}
	},

	computed: {
		deadlineDatetime: {
			get: ({ goal }) => goal.deadline ? fromUTC(goal.deadline) : '',
			set (newVal) {
				this.goal.deadline = toUTC(newVal);
			}
		},

		showTreatmentSelect: (
			{ $store, treatments }
		) => $store.getters.userType === 'clinician' && treatments.length
	},

	validations: {
		goal: {
			name: { required },
			treatment: { required }
		}
	}
};
</script>
