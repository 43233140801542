export default {
	'access-control': 'access-control',
	'achieved': 'achieved',
	'activated-apps': 'activated-apps',
	'activity': 'activity',
	'activity-start-date': 'activity-start-date',
	'adapters': 'adapters',
	'add': 'plus',
	'agenda-widget': 'agenda-widget',
	'angle-down': 'angle-down',
	'angle-down-circle': 'angle-down-circle',
	'angle-left': 'angle-left',
	'angle-left-circle': 'angle-left-circle',
	'angle-right': 'angle-right',
	'angle-right-circle': 'angle-right-circle',
	'angle-up': 'angle-up',
	'angle-up-circle': 'angle-up-circle',
	'archive': 'archive',
	'arrow-down': 'arrow-down',
	'arrow-down-circle': 'arrow-down-circle',
	'arrow-left': 'arrow-left',
	'arrow-left-circle': 'arrow-left-circle',
	'arrow-right': 'arrow-right',
	'arrow-right-circle': 'arrow-right-circle',
	'arrow-up': 'arrow-up',
	'arrow-up-circle': 'arrow-up-circle',
	'assessment': 'assessment',
	'assessment-attribute': 'assessment-attribute',
	'assessment-calculation': 'assessment-calculation',
	'assessment-data': 'assessment-data',
	'assessment-flag': 'assessment-flag',
	'assessment-portal': 'assessment-portal',
	'assessment-question': 'assessment-question',
	'assessment-status': 'assessment-status',
	'assessment-store': 'assessment-store',
	'assessment-types': 'assessment-types',
	'assigned-active-treatments': 'assigned-active-treatments',
	'attachment': 'attachment',
	'backward': 'backward',
	'ban': 'ban',
	'cam': 'cam',
	'cam-slashed': 'cam-slashed',
	'card-configuration': 'card-configuration',
	'cards': 'cards',
	'categories': 'categories',
	'change-view': 'change-view',
	'check': 'check',
	'check-circle': 'check-circle',
	'checkbox': 'checkbox',
	'checkbox-checked': 'checkbox-checked',
	'circle': 'circle',
	'circle-abuse-report': 'circle-abuse-report',
	'circle-abuse-report-status': 'circle-abuse-report-status',
	'circle-cancel': 'circle-cancel',
	'circle-categories': 'circle-categories',
	'circle-color': 'circle-color',
	'circle-join': 'circle-join',
	'circle-leave': 'circle-leave',
	'circles': 'users',
	'clinician': 'clinician',
	'clinician-attribute': 'clinician-attribute',
	'clinician-group': 'clinician-group',
	'clinician-label': 'clinician-label',
	'clinician-settings': 'clinician-settings',
	'close': 'arrow-left-circle',
	'code': 'code',
	'codes': 'codes',
	'code-types': 'code-types',
	'completed': 'plus',
	'comunication-message': 'comunication-message',
	'consent-given': 'consent-given',
	'consent-not-given': 'consent-not-given',
	'consent-not-submitted': 'consent-not-submitted',
	'consent-partially-given': 'consent-partially-given',
	'consent-template': 'consent-template',
	'content-package': 'content-package',
	'content-package-category': 'content-package-category',
	'context': 'context',
	'copy': 'copy',
	'copy-cancel': 'copy-cancel',
	'dashboard': 'dashboard',
	'data-export': 'data-export',
	'data-mapping': 'data-mapping',
	'date': 'date',
	'decision': 'decision',
	'decision-submitted': 'decision-submitted',
	'delete': 'delete',
	'download': 'download',
	'downward': 'downward',
	'edit': 'edit',
	'enter-full-screen': 'enter-full-screen',
	'enterprises': 'enterprises',
	'exit-full-screen': 'exit-full-screen',
	'export': 'export',
	'eraser': 'eraser',
	'error': 'error',
	'external-link': 'external-link',
	'exercises': 'exercises',
	'exercise': 'exercises',
	'export-template': 'export-template',
	'female': 'female',
	'file-archive': 'file-archive',
	'file-audio': 'file-audio',
	'file-blank': 'file-blank',
	'file-categories': 'file-categories',
	'file-doc': 'file-doc',
	'file-gif': 'file-gif',
	'file-image': 'file-image',
	'file-jpg': 'file-jpg',
	'file-jpeg': 'file-jpeg',
	'file-pdf': 'file-pdf',
	'file-png': 'file-png',
	'file-ppt': 'file-ppt',
	'file-txt': 'file-txt',
	'file-xls': 'file-xls',
	'file-video': 'file-video',
	'filter': 'filter',
	'flag': 'flag',
	'flag-reason-template': 'flag-reason-template',
	'fold': 'fold',
	'forward': 'forward',
	'frown': 'frown',
	'goal': 'goal',
	'goal-category': 'goal-category',
	'group': 'group',
	'hide': 'hide',
	'history': 'history',
	'home': 'home',
	'image': 'image',
	'in_progress': 'in-progress',
	'in-progress': 'in-progress',
	'information': 'information',
	'language': 'language',
	'link': 'link',
	'link-categories': 'link-categories',
	'list': 'list',
	'lock': 'lock',
	'log': 'log',
	'login': 'login',
	'logout': 'logout',
	'lowered-flag': 'lowered-flag',
	'male': 'male',
	'media': 'media',
	'media-categories': 'media-categories',
	'meh': 'meh',
	'menu': 'menu',
	'menu-composer': 'menu-composer',
	'message': 'message',
	'message-open': 'message-open',
	'mic': 'mic',
	'mic-slashed': 'mic-slashed',
	'minus': 'minus',
	'minus-circle': 'minus-circle',
	'mirror': 'mirror',
	'mirrored-group': 'mirrored-group',
	'more-actions': 'more-actions',
	'no': 'no',
	'not-agreed': 'not-agreed',
	'not_started': 'minus',
	'open-folder': 'open-folder',
	'overdue': 'overdue',
	'page': 'page',
	'pages': 'pages',
	'page-category': 'page-category',
	'paste': 'paste',
	'pause': 'pause',
	'pause-circle': 'pause-circle',
	'pencil': 'pencil',
	'personal-diary': 'personal-diary',
	'phone': 'phone',
	'phone-off': 'phone-off',
	'plan': 'plan',
	'play': 'play',
	'play-circle': 'play-circle',
	'plus': 'plus',
	'plus-circle': 'plus-circle',
	'question-mark': 'question-mark',
	'relation-types': 'relation-types',
	'remote-scoring': 'remote-scoring',
	'report': 'report',
	'reports': 'reports',
	'respondent': 'respondent',
	'respondent-attribute': 'respondent-attribute',
	'respondent-data': 'respondent-data',
	'respondent-group': 'respondent-group',
	'respondent-label': 'respondent-label',
	'respondent-settings': 'respondent-settings',
	'roles': 'role',
	'role-set': 'role-set',
	'rule-sets': 'rule-sets',
	'save': 'save',
	'search': 'search',
	'send': 'send',
	'show': 'show',
	'sitemap': 'sitemap',
	'smile': 'smile',
	'sort': 'sort',
	'sort-angles': 'sort-angles',
	'sort-angles-thin': 'sort-angles-thin',
	'sort-asc': 'sort-asc',
	'sort-asc-transparent': 'sort-asc-transparent',
	'sort-desc': 'sort-desc',
	'sort-desc-transparent': 'sort-desc-transparent',
	'sort-list-asc': 'sort-list-asc',
	'sort-list-desc': 'sort-list-desc',
	'sort-thin': 'sort-thin',
	'sound': 'sound',
	'status': 'status',
	'stop': 'stop',
	'stop-circle': 'stop-circle',
	'system': 'system',
	'switch': 'switch',
	'switch-circle': 'switch-circle',
	'switch-circle-thin': 'switch-circle-thin',
	'times': 'times',
	'times-circle': 'times-circle',
	'treatment': 'treatment',
	'treatment-activity': 'treatment-activity',
	'treatment-attribute': 'treatment-attribute',
	'treatment-back': 'treatment-back',
	'treatment-data': 'treatment-data',
	'treatment-type': 'treatment-type',
	'tree-view': 'tree-view',
	'unfold': 'unfold',
	'unlock': 'unlock',
	'upload': 'upload',
	'upward': 'upward',
	'user': 'user',
	'user-ban': 'user-ban',
	'user-circle': 'user-circle',
	'user-unban': 'user-unban',
	'users': 'users',
	'wizard': 'wizard',
	'yes': 'yes'
};
