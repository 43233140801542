import MultipleEntities from '../shared/multiple-entities';
import AbuseReport from './abuse-report';
import urls from './urls';
import getTotalResults from 'service/search/get-total-results';

const CircleAbuseReports = new (MultipleEntities.extend({
	model: AbuseReport,
	eventNamespace: 'circle-abuse-report',
	urls,

	list ({ start, limit }) {
		const list = this.retrieve('abuseReports', { start, limit });

		list.then((response, status, xhrObj) => {
			list.total = getTotalResults({
				xhrObj,
				url: this.url
			});
		});

		list.more = (start) => {
			const newReports = this.retrieve('abuseReports', { start, limit });

			newReports.then((response) => {
				list.add(response);
			});

			return newReports;
		};

		list.all = () => {
			const allReports = this.retrieve('abuseReports');

			allReports.then((response) => {
				list.add(response);
			});

			return allReports;
		};

		return list;
	},

	search (params) {
		return this.retrieve('searchAbuseReports', params);
	}
}));

export { CircleAbuseReports };
