import layoutAdjustment from '../../rendering/layout-adjustment';
import $ from 'jquery';
import appUrl from 'service/app-url/app-url';

export default function (model) {
	const inMatrix = model.getSection().isMatrix();

	const $el = $(inMatrix ? '<tr />' : '<div />')
		.attr('id', model.getElementId())
		.addClass('assessment-container assessment-image-container');

	const $img = $(`
		<img
			src="${appUrl(model.getSourceUrl())}"
			alt="${model.getAlt()}"
			class="assessment-image assessment"
		/>
	`);

	if (!model.isVisible()) {
		$el.hide();
	}

	layoutAdjustment(model, $el[0]);

	if (inMatrix) {
		$('<td class="question-text-cell" colspan="100%" />').append($img).appendTo($el);

	} else {
		$el.append($img);
	}

	this.getElementBox = () => $el[0];
}
