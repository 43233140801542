import treatmentSelectionDialog from 'modules/treatments/components/treatment-selection/dialog';
import cardConfig from '../../config/card-config';

export default async ({ card, cardParams }) => {
	await treatmentSelectionDialog({
		config: cardConfig(card.name),
		cardParams,
		cardName: card.name
	});
};
