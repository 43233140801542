import MultipleEntities from '../shared/multiple-entities';
import Language from './entity';
import urls from './urls';

/**
 * Languages Entity
 * @class Languages
 * @extends MultipleEntities
 */

const LanguagesEntity = MultipleEntities.extend({
	model: Language,
	urls,
	idName: 'languageId',
	eventNamespace: 'language',

	/**
	 * Get all Languages
	 * @method getAll
	 * @return {Object} new Languages Collection
	 * @example
	 *        Repository.Languages.getAll();
	 */
	getAll: function () {
		return this.retrieve('allLanguages');
	},

	/**
	 * Get enabled Languages
	 * @method getEnabled
	 * @return {Object} new Languages Collection
	 * @example
	 *        Repository.Languages.getEnabled();
	 */
	getEnabled: function () {
		return this.retrieve('enabledLanguages');
	},

	/**
	 * Get available Languages (enabled + currently selected which doesn't have to be enabled)
	 * @method getAvailable
	 * @param {Object} opts
	 * @return {Object} new Languages Collection
	 * @example
	 *        Repository.Languages.getAvailable({ selectedLanguageId: 5 });
	 */
	getAvailable: function (opts = {}) {
		const selectedLanguageId = opts.selectedLanguageId;

		return this
			.filter((item) => +item.get('languageEnabled') || (+item.get('languageId') === +selectedLanguageId))
			.map((item) => ({
				[`${item.get('languageId')}`]: item.get('languageLabel')
			}));
	}
});

export default new LanguagesEntity();
