<template>
	<label :class="labelClassName">
		<input
			ref="input"
			:class="inputClassName"
			:value="valueName"
			type="radio"
			:checked="value == valueName"
			:name="name"
			:disabled="disabled"
			@change="updateValue()"
		/>
		<span v-translate :class="labelTextClassName">{{label}}</span>
	</label>
</template>

<script>

import Vue from 'vue';
import { className } from './_helpers';
import { isString, isBoolean, isNull } from 'lodash';

const COMPONENT_NAME = 'radio-input';
const validator = (prop) =>
	isString(prop) ||
	isBoolean(prop) ||
	isNull(prop);

export default Vue.component(COMPONENT_NAME, {
	props: {
		value: {
			required: true,
			validator
		},
		valueName: {
			required: true,
			validator
		},
		classPrefix: {
			type: String,
			default: ''
		},
		label: {
			type: String,
			required: true
		},
		name: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		labelClassName () {
			return [
				...this.className('label-container'),
				this.invalid && 'invalid'
			];
		},

		labelTextClassName () {
			return this.className('label-text');
		},

		inputClassName () {
			return this.className('input');
		}
	},

	methods: {
		updateValue () {
			this.$emit('input', this.valueName);
		},
		className: className(COMPONENT_NAME)
	}
});
</script>
