import autosize from 'autosize';
import $ from 'jquery';
import { mapState, mapGetters, mapActions } from 'vuex';
import t from 'translate';
import TileView from 'tile-view';
import appLayout from 'core/views/app-layout-view';
import store from 'store';
import post from './post';
import avatarNickname from '../../components/avatar-nickname';
import statusButton from '../../components/status-button';
import { get } from 'lodash';
import enableCirclesCheck from '../../helpers/enable-circles-check';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints.json';
import Vue from 'vue';

const CURRENT_CIRCLE_POSTS = 'circles/currentCircle/posts';

export default TileView.extend({
	title: t('Circle'),
	cardData: () => ['limit'],
	acl: [{
		op: READ,
		checkpoint: CLINICIAN.CIRCLES.CIRCLES
	}],
	features: enableCirclesCheck,

	init ({ tile }) {
		tile.closeMenuEvent({ tile });
	},

	Vue: ({ tile }) => ({
		store,
		components: {
			...avatarNickname,
			post,
			statusButton
		},
		data: {
			newPostContent: '',
			postEditedId: 0,
			postMenuVisible: {}
		},

		mounted () {
			autosize(this.$refs.newPostTextarea);

			this.$on('hide-menu', () => {
				this.postMenuVisible = {};
			});
		},

		computed: {
			...mapState('circles', ['profile']),
			...mapState(CURRENT_CIRCLE_POSTS, ['current', 'totalCount', 'loading']),
			...mapGetters(CURRENT_CIRCLE_POSTS, ['sorted']),
			...mapGetters('circles/profile', ['profileData']),
			...mapGetters('circles/currentCircle', ['isMember']),

			circle () {
				return this.$store.state.circles.currentCircle.data;
			},

			needMore () {
				return this.totalCount > this.current.length;
			},
			morePostsCount () {
				const limit = get(tile, 'limit', 0);
				return this.totalCount - this.current.length >= limit ?
					limit :
					this.totalCount - this.current.length;
			}
		},

		methods: {
			...mapActions(CURRENT_CIRCLE_POSTS, ['loadMore', 'loadAll']),

			postMenuToggle (postId) {
				Vue.set(this.postMenuVisible, postId, !this.postMenuVisible[postId]);
			},

			submitPost () {
				if (!this.newPostContent.length) {
					return;
				}

				this.$store.dispatch('circles/currentCircle/posts/create', {
					content: this.newPostContent
				});

				this.newPostContent = '';
			}
		},

		template: `
			<section class="circle__container">
				<p class="circle__description">{{ circle.description }}</p>

				<section v-if="isMember">
					<section class="new-post">
						<avatar-nickname :user="profileData" />
						<div
							class="new-post__loader"
							v-if="loading.create"
							v-loader-spinner
						></div>
						<div class="form-view__field-container new-post__field-container">
							<textarea
								ref="newPostTextarea"
								v-model="newPostContent"
								class="form-view__input form-view__textarea new-post__textarea"
								placeholder="${t('Write new post…')}"
							></textarea>
						</div>

						<div class="form-view__field-container">
							<button
								@click="submitPost"
								class="form-view__button form-view__button--submit"
								type="submit"
							>${t('Post')}</button>
						</div>
					</section>

					<section class="circle-posts ">
						<ol class="circle-posts__list">
							<li class="circle-post" v-for="post in sorted">
								<post
									:post="post"
									:profile="profileData"
									:circleId="circle.id"
									:post-menu-visible="postMenuVisible"
									@toggle-menu="postMenuToggle(post.id)"
								></post>
							</li>
						</ol>
						<div
							class="circle-posts__loader"
							v-if="loading.list"
							v-loader-spinner
						></div>
						<button
							class="circle-posts__action"
							v-if="needMore"
							@click="loadMore"
							:disabled="loading.list"
						>${t('Show more posts')} ({{ morePostsCount }})</button>
						<button
							class="circle-posts__action"
							v-if="needMore"
							@click="loadAll"
							:disabled="loading.list"
						>${t('Show all posts')} ({{ totalCount }})</button>
					</section>
				</section>

				<section v-else class="circle__no-access">
					<i class="icon--lock" aria-hidden="true" />
					<p class="circle__no-access-description">
						${t('You\'re not a member of this circle.')}
					</p>
					<status-button :circle="circle" />
				</section>
			</section>
		`
	}),

	loaded: ({ tile }) => {
		tile.setTitle(store.state.circles.currentCircle.data.name);
	},

	closeMenuEvent ({ tile }) {
		const isPostMenuAction = ($target) =>
			$target.is('.circle-post__menu-action') ||
			$target.is('.circle-post__menu-trigger') ||
			$target.is('.icon--more-actions');
		// hide post menu when clicked somewhere else
		tile.listenTo(appLayout, 'click', (e) => {
			!isPostMenuAction($(e.target)) && tile.vueInstance.$emit('hide-menu');
		});
	}
});
