import { cwtagsNl2br } from '../../helpers/string';
import layoutAdjustment from '../../rendering/layout-adjustment';
import $ from 'jquery';
import { assign, forEach } from 'lodash';
import { warn } from 'service/log/log';
import appUrl from 'service/app-url/app-url';
import { QUESTION_TAG_NAME } from '../../config';
import { questionTextId } from '../shared/helpers';

export default function (runtime, assessment) {
	const isMatrix = runtime.getSection().isMatrix();
	const isColumns = (!isMatrix && (runtime.getPreferredNumberOfColumns() > 1)) ?
		runtime.getPreferredNumberOfColumns() :
		false;

	const qTextId = questionTextId(runtime);
	const $container = isMatrix ?
		$(`<tr role="group" aria-labelledby="${qTextId}" />`) :
		$('<div />');
	$container
		.attr('id', runtime.getElementId())
		.addClass('assessment-container assessment-checkbox-container');

	layoutAdjustment(runtime, $container[0]);

	if (!runtime.isVisible()) {
		$container.hide();
	}

	const $question = $(
		`<${QUESTION_TAG_NAME} class="questiontext" id="${qTextId}" />`
	);

	if (isMatrix) {
		$('<td class="question-text-cell" />').appendTo($container).append($question);

	} else {
		$container.append($question);
	}

	if (runtime.getNumber()) {
		$question.append(`<span class="question-numbering question-numbering-checkbox">
			${runtime.getNumber()}.
		</span>`);
	}

	const $text = $(`<span class="question">${cwtagsNl2br(runtime.getQuestionText())}</span>`)
		.appendTo($question);

	let $answers;
	let $tbody;

	if (!isMatrix) {
		$answers = $(`<div
			class="questionanswers"
			role="group"
			aria-labelledby="${qTextId}"
		/>`).appendTo($container);

		if (isColumns) {
			$tbody = $('<tbody />');
			$('<table class="column-options" />').appendTo($answers).append($tbody);
		}
	}

	const highlightIfAnswered = () => {
		if (!isMatrix) {
			return;
		}

		if (runtime.isAnswered()) {
			$container.addClass('highlightedRow');

		} else {
			$container.removeClass('highlightedRow');
		}
	};

	highlightIfAnswered();

	let i = 0;
	let $tr;

	forEach(runtime.getAnswers(), (answer) => {
		if (isColumns && ((i++ % isColumns) === 0)) {
			$tr = $('<tr />').appendTo($tbody);
		}

		const optionType = {
			TextOption: 'checkbox-text-option',
			ImageOption: 'checkbox-image-option'
		}[answer.getType()];

		const $td = $('<td />');
		const $option = $(`<div
			class="${answer.getTemplateId()} ${optionType}"
			id="${answer.getId()}"
		/>`);

		if (isColumns) {
			$td.appendTo($tr).append($option);

		} else {
			if (isMatrix) {
				$td.addClass('radio-td').appendTo($container).append($option);

			} else {
				$answers.append($option);
			}
		}

		const $checkbox = $(`<input
			type="checkbox"
			id="${answer.getId()}-control"
			class="assessment assessment-checkbox autowidth"
			name="${runtime.getElementId()}"
			aria-describedby="${runtime.getElementId()}-questiontext"
		/>`).prop({
			checked: answer.isChecked(),
			disabled: runtime.isReadonly()
		}).appendTo($option);

		const uncheck = () => {
			// Model is initiated once, view is re-initiated on every page render.
			// "Real time" logic has to be done here, e.g. deselecting the answer when it's hidden
			answer.uncheck();
			$checkbox.prop('checked', false);
		};

		const hide = () => {
			uncheck();
			$option.hide();
		};

		if (!answer.isVisible()) {
			hide();
		}

		$option.data('onHide', () => {
			hide();
		});

		$option.data('onShow', () => {
			uncheck();
			$option.show();
		});

		$checkbox.prop('disabled', runtime.isReadonly());
		let $labelContent;

		if (answer.getType() === 'TextOption') {
			$labelContent = cwtagsNl2br(answer.getLabel());

		} else if (answer.getType() === 'ImageOption') {
			const imgUrl = appUrl(runtime.mediaManager.getUrl(answer.image()));
			$labelContent = $(`<img src="${imgUrl}" alt="${
				runtime.mediaManager.getAlt(answer.image())
			}" />`);

		} else {
			warn(`Answer type ${answer.getType()} not implemented!`);
		}

		if (isMatrix) {
			$checkbox.attr('aria-label', $labelContent);
		}

		$(`
			<label
				class="radios radio-label"
				id="${answer.getId()}-optiontext"
				for="${$checkbox.attr('id')}"
			></label>
		`).html($labelContent).appendTo($option);

		$checkbox.on({
			click: () => {
				$checkbox.prop('checked') ?
					answer.check() :
					answer.uncheck();

				highlightIfAnswered();
				assessment.startSaving();
			}
		});
	});

	// uncheck a checkbox when answer limit has been reached
	runtime.on('limit', (e) => {
		$(`.${e.answeredCode} input`).prop('checked', false);
	});

	assign(this, {
		updateText: () => {
			$text.html(cwtagsNl2br(runtime.getQuestionText()));
		},
		getElementBox: () => $container[0],
		clear: () => {
			$('input', $container).prop('checked', false);
		}
	});
}
