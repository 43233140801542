<template>
	<h3 class="treatment-grid-header">
		<icon v-if="icon" :name="icon" />
		<span><slot /></span>
	</h3>
</template>

<script>
import Icon from 'components/icon';

export default {
	components: {
		Icon
	},

	props: {
		icon: {
			type: String,
			default: null
		}
	}
};
</script>
