import requests from './requests-store';
import repository from 'repository';
import Member from 'repo/circles/member';
import { push, remove } from './helpers';
import { assign } from 'lodash';

export default {
	namespaced: true,

	modules: { requests },

	state: {
		current: [],
		collection: {},
		totalCount: 0,
		circleId: 0
	},

	getters: {
		list: (state) => state.current
	},

	mutations: {
		set: (state, { collection, circleId }) => {
			state.circleId = circleId;
			state.current = collection.toJSON();
			state.collection = collection;
			state.totalCount = +collection.total;
		},

		setLoading: (state, loading) => {
			state.loading = assign({}, state.loading, loading);
		},

		add: (state, { members }) => {
			state.collection.add(members);
			push({ from: members, to: state.current });
		},

		remove: (state, { member }) => {
			remove({ item: member, from: state.current });
		},

		decreaseTotalCount: (state) => {
			state.totalCount--;
		},

		increaseTotalCount: (state) => {
			state.totalCount++;
		}
	},

	actions: {
		init: ({ commit }, { circleId, limit }) => {
			const collection = repository.circleMembers({ circleId, start: 0, limit });
			return (collection).then(() => {
				commit('set', { collection, circleId });
			});
		},

		set: ({ commit }, payload) => {
			commit('set', payload);
		},

		add: ({ commit }, payload) => {
			commit('add', payload);
		},

		loadMore: ({ commit, state }) => {
			const members = state.collection.more(state.collection.length);
			members.then(() => {
				commit('add', { members: members.toJSON() });
			});
		},

		acceptRequest: ({ commit, dispatch }, { request, circleId }) => {
			const member = new Member({ id: request.id, circle: circleId });
			return member.save()
				.then(() => dispatch('requests/accept', { request }))
				.then(() => {
					commit('add', { members: [request] });
					commit('increaseTotalCount');
				});
		},

		remove: ({ commit, state }, { member }) =>
			state.collection.get(member.id).set('circle', state.circleId).destroy().then(() => {
				commit('remove', { member });
				commit('decreaseTotalCount');
			})
	}
}
