import repository from 'repository';
import { filter, size, sortBy, isArray, cloneDeep, forEach } from 'lodash';
import { set, idx, update, remove } from '../__helpers/mutations';
import compareParams from '../__helpers/compare-params';

const prefix = 'dataIntegration/ruleSets';

const SINGLE_ITEM = 'singleData';
const WITH_ENTERPRISE = 'withEnterprise';

export const RULE_SETS = {
	SINGLE_ITEM: `${prefix}/${SINGLE_ITEM}`,
	WITH_ENTERPRISE: `${prefix}/${WITH_ENTERPRISE}`
};

export default {
	namespaced: true,

	state: {
		availableTreatmentTypes: [],
		defaultRuleSet: [],
		ruleSetData: {
			allow: false,
			code: {
				id: null,
				name: ''
			},
			enabled: false,
			treatmentType: {
				id: null,
				name: '',
				enterprise: ''
			}
		},
		list: [],
		total: 0,
		params: {},
		initialParams: null,
		ruleSetsWithEnterprise: [],
		ruleSetsWithTreatmentTypes: [],
		singleData: null
	},

	getters: {
		default: (state) => state.defaultRuleSet,
		list: (state) => state.list,
		[SINGLE_ITEM]: (state) => state.singleData,
		sorted: (state, getters) => sortBy(getters.list, ['name']),
		params: (state) => state.params,
		withEnterprise: (state) => state.ruleSetsWithEnterprise,
		withTreatmentTypes: (state) => state.ruleSetsWithTreatmentTypes,
		total: (state) => state.total,
		count: (state, getters) => size(getters.list),
		enabled: (state, getters) => filter(getters.list, { enabled: true })
	},

	mutations: {
		setList: (state, list) => {
			if (isArray(list)) {
				state.list = list;
			} else {
				state.list.push(list);
			}
		},
		setParams: set('params'),
		setTotal: set('total'),
		setDefaultRuleset: set('defaultRuleSet'),
		setRuleSetsWithEnterprise: set('ruleSetsWithEnterprise'),
		setRuleSetsWithTreatmentTypes: set('ruleSetsWithTreatmentTypes'),
		setSingle: set('singleData'),
		setInitialParams: (state, params) => {
			state.initialParams = params;
		},
		update: update('list'),
		remove: remove('list')
	},

	actions: {
		add: (context, data) => repository.addRuleSet(data),

		addAndRefreshWithTreatmentTypes: ({ dispatch }, data) =>
			dispatch('add', data).then(() => {
				dispatch('withTreatmentTypes');
			}),

		checkDefaultRuleSet: ({ commit }) => repository.checkDefaultRuleSet()
			.then((response) => commit('setDefaultRuleset', response)),

		delete: async ({ commit, state }, id) => {
			const index = idx(state.list, id);
			const result = await repository.deleteRuleSet(id);
			commit('remove', { index });
			return result;
		},

		getSingle: ({ commit }, id) => repository.dataIntegrationRuleSet(id)
			.then((rawResults) => commit('setSingle', rawResults)),

		initWithEnterprise: ({ commit }) => repository.getRuleSetsWithEnterprise()
			.then((response) => {
				commit('setRuleSetsWithEnterprise', response);
			}),

		withTreatmentTypes: ({ commit }) => repository.getRuleSetsWithTreatmentTypes()
			.then((response) => {
				commit('setRuleSetsWithTreatmentTypes', response);
			}),

		search: ({ commit, state }, searchParams = state.params) => {
			if (searchParams) {
				commit('setParams', searchParams);
			}

			if (!state.initialParams) {
				const initialParams = cloneDeep(searchParams);
				delete initialParams.start;
				commit('setInitialParams', initialParams);
			}
			const noFilters = compareParams(state, searchParams);
			const results = repository.searchRuleSets(state.params);

			return results.then((rawResults, statusText, xhrObj) => {
				const total = xhrObj.getResponseHeader('X-Total-Count');
				commit('setTotal', total);

				if (statusText === 'success') {
					if (noFilters) {
						forEach(rawResults, (item) => {
							const index = idx(state.list, item.id);

							if (index > -1) {
								commit('update', item);
							} else {
								commit('setList', item);
							}
						});
					} else {
						commit('setList', rawResults);
					}
				}
				return [results, statusText, xhrObj];
			});
		},

		update: (context, data) => repository.updateRuleSet(data)
	}
};
