import { isNaN } from 'lodash';

const roleSet = 'backend-commons/role-set/';
const treatmentTypeRoleSet = `backend-commons/treatment-type`;

export default {
	single: {
		url: 'backend-commons/treatment-type/'
	},

	listAll: {
		url: 'backend-commons/treatment-type/'
	},

	listForCurrentClinician: {
		url: 'backend-commons/treatment-type/current-clinician/'
	},

	singleAttribute: {
		url: (treatmentTypeId, id) => {
			const suffix = !isNaN(id) ? `/${id}` : `/`;
			return `backend-commons/treatment-type/${treatmentTypeId}/attribute${suffix}`;
		}
	},

	listAttributes: {
		url: (treatmentTypeId) => `backend-commons/treatment-type/${treatmentTypeId}/attribute/`
	},

	singleRole: {
		url: (treatmentTypeId, id) => {
			const suffix = !isNaN(id) ? `/${id}` : `/`;
			return `backend-commons/treatment-type/${treatmentTypeId}/role${suffix}`;
		}
	},

	listRoles: {
		url: (treatmentTypeId) => `backend-commons/treatment-type/${treatmentTypeId}/role/`
	},

	listClinicianGroups: {
		url: (treatmentTypeId) =>
			`backend-commons/treatment-type/${treatmentTypeId}/clinician-group/`
	},

	singleClinicianGroup: {
		url: (treatmentTypeId, clinicianGroupId) =>
			`backend-commons/treatment-type/${treatmentTypeId}/clinician-group/${clinicianGroupId}`
	},

	roleSets: roleSet,

	treatmentTypeClinicianGroup: (data) => {
		const suffix = !isNaN(data.id) ? `/${data.id}` : `/`;
		return `${treatmentTypeRoleSet}/${data.treatmentTypeId}/clinician-group${suffix}`;
	},

	treatmentTypeRoleSet: (data) => {
		const suffix = !isNaN(data.id) ? `/${data.id}` : `/`;
		return `${treatmentTypeRoleSet}/${data.treatmentTypeId}/role-set${suffix}`;
	},

	treatmentTypeRoleSets: (treatmentTypeId) =>
		`${treatmentTypeRoleSet}/${treatmentTypeId}/role-set/`,

	treatmentType: (treatmentTypeId) => `backend-commons/treatment-type/${treatmentTypeId}`
};
