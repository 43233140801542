import { clone, first, forEach, intersection, keys } from 'lodash';

export default (queryParams, urlPatterns) => {
	const queryKeys = keys(queryParams);

	const score = clone(urlPatterns);
	forEach(score, (pattern) => {
		const points = intersection(queryKeys, pattern.params).length;
		pattern.score = points - ((pattern.params.length - points) * 0.01);
	});

	const url = first(score.sort((a, b) => b.score - a.score));

	return url;
};
