import layoutAdjustment from '../layout-adjustment';
import { assign, forEach, isFunction, some } from 'lodash';
import $ from 'jquery';
import executeIfFunction from 'execute-if-function';
import formLayout from './section/form-layout';
import background from './section/background';
import matrix from './section/matrix';

const getClass = (ele) => isFunction(ele.getClass) ?
	ele.getClass() :
	ele.get('class');

const id = (element) => isFunction(element.get) ? element.get('id') : element.getElementId();

const domNode = ($container) => {
	if (!$container.hasClass('assessment-container-gridlayout')) {
		return $container;
	}

	const $row = $('<div class="row" />');
	$container.removeClass('row').append($row);
	return $row;
};

const matrixChecks = {
	question: ({ element }) => getClass(element) === 'Question',
	text: ({ element, $tBody }) => getClass(element) === 'Text' && $tBody[0].children.length,
	image: ({ element }) => getClass(element) === 'ImageElement'
};

const insideMatrix = ({ element, $tBody }) => some(
	matrixChecks,
	(check) => check({ element, $tBody })
);

export default function (runtime) {
	const layout = runtime.getLayout();
	const $container = $(`<div id="${runtime.getElementId()}" />`);
	!runtime.isVisible() && $container.hide();
	layoutAdjustment(runtime, $container[0]);

	const $domNode = domNode($container);

	if (layout.type === 'questionnaire:FormLayout') {
		formLayout({ layout, $container, runtime });
	}

	runtime.hasBackground() && background({ runtime, $container });
	runtime.isMatrix() && matrix({ view: this, $container, runtime, getClass });

	assign(this, {
		children: {},
		addChild: (child, element, renderer) => {
			this.children[id(element)] = renderer;

			if (runtime.isMatrix()) {
				if (insideMatrix({ element, $tBody: this.$tBody })) {
					this.$tBody.append(child);
					return;
				}

				$domNode.append([child, this.$table[0]]);

			} else {
				$domNode.append(child);
			}
		},
		getElementBox: () => $container[0],
		getItemBox: () => runtime.isMatrix() ? this.$tBody[0] : $container[0],
		clear: () => {
			forEach(this.children, (child) => {
				executeIfFunction(child.clear, child);
			});

			$('.matrix__table').trigger('reflow');
		},
		unclear: () => {
			forEach(this.children, (child) => {
				executeIfFunction(child.unclear, child);
			});

			$('.matrix__table').trigger('reflow');
		}
	});
}
