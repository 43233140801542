<template>
	<div class="tree-view__tree-add-item tree-add-item">
		<button
			:ref="path"
			class="tree-item__button tree-add-item__action"
			@click="show"
		>
			<icon name="tree-view" />
			<span class="tree-item__button-title">{{buttonTitle}}</span>
		</button>
		<ul v-if="visible" v-on-click-outside="hide" class="tree-add-item__list">
			<li
				v-for="item in blueprints()"
				:key="getName(item)"
				class="tree-add-item__item"
				:class="itemClassName(item)"
			>
				<button class="tree-add-item__item-action" @click="create(item)">
					<icon :name="iconName(item)" class="tree-add-item__item-icon" />
					<span class="tree-add-item__item-text">{{getName(item)}}</span>
				</button>
			</li>
		</ul>
	</div>
</template>

<script>
import t from 'translate';
import { get, noop } from 'lodash';

export default {
	props: {
		blueprints: {
			type: Function,
			required: true
		},
		icons: {
			type: Object,
			default: () => ({})
		},
		path: {
			type: String,
			default: 'children'
		},
		namePath: {
			type: String,
			default: 'name'
		},
		onAdd: {
			type: Function,
			default: noop
		}
	},

	data () {
		return {
			buttonTitle: t('Add more'),
			visible: false
		};
	},

	methods: {
		show () {
			this.visible = true;
			this.$emit('addButtonRefs', this.$refs[this.path]);
		},

		hide () {
			this.visible = false;
		},

		create (item) {
			this.onAdd({
				path: this.path,
				item
			});
			this.hide();
		},

		itemClassName ({ type }) {
			return `tree-add-item__item--${type}`;
		},

		iconName ({ type }) {
			return this.icons[type] || 'question-mark';
		},

		getName (item) {
			return t(get(item, this.namePath));
		}
	}
};
</script>
