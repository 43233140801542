import repository from 'repository';
import { clone, find, map, remove } from 'lodash';
import { cleanClinicianGroup } from '../__helpers/clean';

// @TODO(2018-11-26): Shouldn't be needed, remove when terrifying deadlines allow
const dirtyClinicianGroup = (group) => ({
	administratorGroupId: group.id,
	administratorGroupName: group.name,
	administratorGroupCategory: group.category || ''
});

export default {
	namespaced: true,

	state: {
		data: {}
	},

	getters: {
		clinicianGroups: (state) => map(state.data.administratorGroup, cleanClinicianGroup)
	},

	mutations: {
		clear: (state) => {
			state.data = {};
		},

		set: (state, group) => {
			state.data = group;
		},

		addClinicianGroup: (state, group) => {
			state.data.administratorGroup.push(dirtyClinicianGroup(group));
		},

		removeClinicianGroup: (state, group) => {
			const cloned = clone(state.data.administratorGroup);
			// eslint-disable-next-line lodash/prefer-immutable-method
			remove(cloned, dirtyClinicianGroup(group));
			state.data.administratorGroup = cloned;
		}
	},

	actions: {
		init: ({ commit }, id) => {
			commit('clear');
			return repository.respondentGroupWithClinicianGroups(id).then((group) => {
				commit('set', group);
			});
		},

		addClinicianGroup: ({ commit, state, dispatch }, group) => {
			commit('addClinicianGroup', group);

			return repository.createClinicianGroupRespondentGroup(
				{ clinicianGroupId: group.id, respondentGroupId: state.data.respondentGroupId }
			).then(() => dispatch('init', state.data.respondentGroupId));
		},

		removeClinicianGroup: ({ commit, state }, group) => {
			commit('removeClinicianGroup', group);

			const relations = repository.getRespondentGroupsForClinicianGroup(group.id);
			return relations.then(() => {
				const relation = find(
					relations.toJSON(),
					(item) => +item.respondentGroupId === +state.data.respondentGroupId
				);

				if (relation.relationId) {
					return repository.deleteClinicianGroupRespondentGroup(relation.relationId);
				}
			});
		}
	}
};
