var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "sidebar-container",
      class: _vm.activeClass,
      on: {
        click: _vm.handleClick,
        keyup: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") &&
            _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
          ) {
            return null
          }
          $event.preventDefault()
          return _vm.handleClick($event)
        },
        "!blur": function($event) {
          return _vm.onBlur($event)
        }
      }
    },
    [
      _c("sidebar-items", {
        attrs: { items: _vm.items, "can-access": _vm.canAccess, type: _vm.type }
      }),
      _vm._v(" "),
      _c("section", { staticClass: "sidebar-logo__container" }, [
        _vm._m(0),
        _vm._v(" "),
        _vm.i18nReady
          ? _c("p", { staticClass: "sidebar-logo__version-text" }, [
              _c(
                "span",
                { directives: [{ name: "translate", rawName: "v-translate" }] },
                [_vm._v("Version")]
              ),
              _vm._v(" " + _vm._s(_vm.appVersion) + "\n\t\t")
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "sidebar-logo__image-container" }, [
      _c("img", {
        staticClass: "sidebar-logo__image",
        attrs: { src: "images/checkware.png", alt: "CheckWare" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }