var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "loader-overlay" },
    [
      _vm.enabled
        ? _c("div", { staticClass: "loader-overlay__body" }, [
            _c("div", { staticClass: "loader-overlay__content" }, [
              _c("p", { staticClass: "loader-overlay__text" }, [
                _vm._v("\n\t\t\t\t" + _vm._s(_vm.label) + "\n\t\t\t")
              ]),
              _vm._v(" "),
              _c("div", {
                directives: [
                  { name: "loader-spinner", rawName: "v-loader-spinner" }
                ],
                staticClass: "loader-overlay__spinner"
              })
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }