import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';

const Attachments = MultipleEntities.extend({
	urls,

	getByAssessmentInstanceId (assessmentInstanceId) {
		return this.retrieve('attachments', assessmentInstanceId);
	}
});

export default new Attachments();

