import t from 'translate';
import store from 'store';
import { when } from 'jquery';
import banRedirect from '../helpers/ban-redirect';

const card = {
	title: t('Join an existing circle'),
	url: [
		'treatment/:treatmentId/circle/join',
		'circle/join'
	],
	name: 'circle-join',

	ctrl: ({ close }) => {
		if (banRedirect(close)) {
			return;
		}

		return when(
			store.dispatch('circles/categories/init'),
			store.dispatch('circles/profile/init'),
			store.dispatch('circles/currentCircle/clear')
		);
	}
};

export { card as circleJoin };
