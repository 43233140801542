import { View } from 'marionette';
import { compile } from 'handlebars';

export default View.extend({
	template: compile(`
		<li class="category-tag__list-item">
			<i class="category-tag__list-item-icon fa {{iconClassName}}" aria-hidden="true"></i>
			<span class="category-tag__list-item-description">{{ name }}</span>
		</li>
	`),

	initialize (options) {
		this.model.set('iconClassName', options.iconClassName);
	}
});
