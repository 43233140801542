import TileView from 'tile-view';
import enableCirclesCheck from '../../helpers/enable-circles-check';

export default TileView.extend({
	acl: [{
		op: 'READ',
		checkpoint: 'administrator.circles.circles'
	}],
	features: enableCirclesCheck,
	instant: true,

	Vue: () => ({
		template: `<h2 class="circles-banned__info" v-translate>You are banned from Circles</h2>`
	})
});
