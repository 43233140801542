var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "items-view__content" }, [
    _c(
      "ul",
      { staticClass: "items-view__container" },
      _vm._l(_vm.itemsConfig, function(itemConfig, index) {
        return _c(
          "item-label",
          { key: itemConfig.key, attrs: { label: _vm.mt(itemConfig.label) } },
          [
            _c(
              _vm.componentItems[index].component,
              _vm._b(
                { tag: "component" },
                "component",
                _vm.componentItems[index].props,
                false
              ),
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.componentItems[index].value) +
                    "\n\t\t\t"
                )
              ]
            )
          ],
          1
        )
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }