import FormView from 'components/form-view/form-view';
import CardDialog from 'service/card-dialog/card-dialog';
import t from 'translate';
import appContext from 'app-context';
import executeIfFunction from 'execute-if-function';
import focused from './focused-elements';
import { when } from 'jquery';
import { assign, defer, noop } from 'lodash';

let focusedElement;

const closeDialog = () => {
	document.activeElement.blur();
	appContext.trigger('dialog:close');
};

const handleModalClose = (formView) => {
	formView.model.revert();

	if (focusedElement) {
		defer(() => {
			focusedElement.focus();
		});
	}
};

const processCfg = ({ formCfg, captions, onAfterSave }) => {
	const config = executeIfFunction(formCfg);

	return assign({}, config, {
		listenTo: config.listenTo ? config.listenTo : ['submit'],
		onAfterSave (...args) {
			const fn = onAfterSave || config.onAfterSave ? config.onAfterSave.bind(this) : noop;
			fn(...args);
			closeDialog();
		},
		buttons: config.buttons || [{
			caption: captions.submit,
			type: 'submit',
			name: 'save-button',
			role: 'submit'
		}, {
			caption: captions.cancel,
			type: 'button',
			name: 'cancel-button',
			role: 'cancel',

			action () {
				closeDialog();
			}
		}]
	});
};

const createDialog = (dialogConfig, loading = false) => {
	const dialog = new CardDialog(dialogConfig);

	if (loading) {
		dialog.setLoading();
	}
	return dialog;
};

const formViewDialog = ({
	formConfig = {},
	dialogConfig = {},
	wait = {},
	onAfterSave,
	captions = {
		submit: t('Save'),
		cancel: t('Cancel')
	}
}) => {
	let formView;
	focusedElement = focused.last();

	if (wait.length) {
		const dialog = createDialog(dialogConfig, true);

		when(...wait).done(() => {
			formView = new FormView(processCfg({ formCfg: formConfig, captions, onAfterSave }));
			dialog.onCancel = () => {
				handleModalClose(formView);
			};
			dialog.setContent(formView.el).setLoaded();
		});

	} else {
		formView = new FormView(processCfg({ formCfg: formConfig, captions, onAfterSave }));
		dialogConfig.onCancel = () => {
			handleModalClose(formView);
		};
		dialogConfig.content = formView.el;
		createDialog(dialogConfig);
	}
};

export default formViewDialog;
export { closeDialog };
