import Backbone from 'backbone';
import cacheService from 'service/cache/cache';
import { ajax, Deferred } from 'jquery';

const contentType = 'application/json';
const URI = ({ url, prepend = true }) => prepend ?
	Backbone.config().backendRootUrl['default'] + url :
	url;
const timestamp = () => new Date().getTime();
const cachedData = {};

const request = (type) => ({ data, url, prepend, headers = {} }) => ajax({
	headers,
	data: JSON.stringify(data),
	contentType,
	url: URI({ url, prepend }),
	type
});

/*
 * Add timestamp param to go around 304 backend default behaviour
 * @param cache
 * @param separator
 */
const timestampParam = ({ cache = false, separator = '?' }) =>
	!cache ? `${separator}_=${timestamp()}` : '';

const GET = ({ url, cache = false, prepend, separator, cachedKey, headers = {} }) => {
	if (cacheService[cachedKey] && cachedData[url]) {
		const dfd = Deferred();
		return dfd.resolve(cachedData[url]);
	}
	const suffix = timestampParam({ cache, separator });
	const request = ajax({
		url: URI({ url, prepend }) + suffix,
		type: 'GET',
		headers
	});

	request.then((response) => {
		if (cacheService[cachedKey]) {
			cachedData[url] = response;
		}

		return response;
	});

	return request;
};

const PUT = request('PUT');
const POST = request('POST');
const DELETE = request('DELETE');
const PATCH = request('PATCH');

export { DELETE, GET, PATCH, POST, PUT };
export default { DELETE, GET, PATCH, POST, PUT };
