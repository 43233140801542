import { forEach, isObject, isUndefined } from 'lodash';

const Event = function (eventName, params) {
	forEach(params, (value, key) => {
		this[key] = value;
	});

	if (isUndefined(this.type)) {
		this.type = eventName;
	}

	this.getEventName = () => eventName;
};
/*
 * Event manager. The callback calls are not deffered.
 *
 * @class EventManager
 * @requires cweventmanager
 * @author Marcin
 * @param {object}
 *            params
 * @param {object}
 *            [params.context] the callbacks context.
 * @param {object}
 *            [params.extend] extends the specified object with all the
 *            EventManager's methods
 */
export default function (params = {}) {
	const defaultContext = isObject(params.context) ? params.context : window;
	const eventCallbacks = {};

	/*
	 * Bind a callback. Id of the callback is returned. The id is used to
	 * unbind.
	 *
	 * @function EventManager#on
	 * @param {string}
	 *            eventName
	 * @param {eventmanager-callback}
	 *            callback
	 * @param {object}
	 *            [context] if specified it will be used regardless of the
	 *            instance wide context for callbacks. {@see EventManager}.
	 * @returns {integer}
	 */
	this.on = function (eventName, callback, context) {

		if (isUndefined(eventCallbacks[eventName])) {
			eventCallbacks[eventName] = {};
		}

		eventCallbacks[eventName][(`${Math.random()}`).substr(2)] = {
			context: !isObject(context) ? defaultContext : context,
			callback
		};

		return this;
	};

	/*
	 * Trigger an event.
	 *
	 * @function EventManager#trigger
	 * @param {string}
	 *            eventName
	 * @param {object}
	 *            event custom object associated with the event.
	 * @returns {EventManager}
	 * @author Marcin
	 */
	this.trigger = function (eventName, event) {
		const eventObject = new Event(eventName, event);

		const call = function (callbackObject) {
			callbackObject.callback.call(callbackObject.context, eventObject);
		};

		forEach(eventCallbacks['*'], (callback) => {
			call(callback);
		});

		if (eventCallbacks[eventName]) {
			forEach(eventCallbacks[eventName], (callback) => {
				call(callback);
			});
		}

		return this;
	};
}
