import repository from 'repository';

export default {
	namespaced: true,

	state: {
		data: null,
		collection: null
	},

	getters: {
		list: (state) => state.data
	},

	mutations: {
		set: (state, { collection, categories }) => {
			state.data = categories;
			state.collection = collection;
		}
	},

	actions: {
		init: ({ commit }) => {
			const collection = repository.mediaCategories();

			return collection.then(() => {
				commit('set', { collection, categories: collection.toJSON() });
			});
		}
	}
}
