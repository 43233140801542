import repository from 'repository';
import { sortBy } from 'lodash';
import prefixify from '../__helpers/prefixify';
import { exact } from '../__helpers/getters';

const prefix = prefixify('types');

// getters
const SORTED = 'sorted';
const DATA = 'data';

// mutations
const SET = 'set';

// actions
const INIT = 'init';

export const TYPES = {
	// getters
	SORTED: prefix(SORTED),
	DATA: prefix(DATA),

	// actions
	INIT: prefix(INIT)
};

export default {
	namespaced: true,

	state: {
		[DATA]: null,
		collection: null
	},

	getters: {
		...exact([DATA]),
		[SORTED]: (state) => sortBy(state[DATA], 'order')
	},

	mutations: {
		[SET]: (state, { types, collection }) => {
			state.data = types;
			state.collection = collection;
		}
	},

	actions: {
		[INIT]: ({ commit }) => {
			const collection = repository.getAssessmentTypes();

			return collection ?
				collection.then(() => {
					commit(SET, { types: collection.toJSON(), collection });
				}) :
				commit(SET, { types: null, collection: null });
		}
	}
};
