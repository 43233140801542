import activities from './activities';
import addGoal from './add-goal';
import editActivity from './edit-activity';
import editGoal from './edit-goal';
import goalCategories from './system-settings-goal-categories/tile';
import goalDetailTile from './goal-details/tile';
import goals from './goals';

export default {
	activities,
	'add-goal': addGoal,
	'edit-activity': editActivity,
	'edit-goal': editGoal,
	'goal-details': goalDetailTile,
	goals,
	'system-settings-goal-categories': goalCategories
};
