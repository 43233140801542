export default function (definition) {
	if (typeof definition.question === 'object') {
		const href = definition.question;

		definition.question = href.code;
		definition.target = href.target;
	}

	/* debug */
	this.__debug = {
		raw: definition,
		type: 'Question',
		question: definition.question
	};

	this.getType = function () {
		return 'Question';
	};

	this.getClass = function () {
		return 'Reference';
	};

	this.getInvolvedQuestionCodesMap = function (questionMap) {
		questionMap[definition.question] = 'question';

		return questionMap;
	};

	this.getQuestionCode = function () {
		return (typeof definition.question === 'object') ?
			definition.question.code :
			definition.question;
	};

	this.isExternal = function () {
		return (typeof definition.question === 'object') && (definition.question.targetId);
	};

	this.getTargetId = function () {
		return (typeof definition.question === 'object') ?
			definition.question.targetId :
			undefined;
	};

	this.getBoolean = function () {
		return (typeof this.getQuestionCode() === 'string') && (this.getQuestionCode().length > 0);
	};

	this.getNumber = function () {
		return NaN;
	};

	this.getString = function () {
		return (typeof this.getQuestionCode() !== 'string') ?
			'Undefined question reference' :
			this.getQuestionCode();
	};

	this.getRawValue = function () {
		return definition.question;
	};

	this.toString = function () {
		return `{Question:${this.getString()}}`;
	};

}
