import repository from 'repository';
import { map } from 'lodash';

export default {
	namespaced: true,

	state: {
		data: [],
		collection: {}
	},

	mutations: {
		set: (state, { timezones, collection }) => {
			state.data = timezones;
			state.collection = collection;
		}
	},

	getters: {
		list: (state) => map(state.data, (item) => ({ [item.timezoneName]: item.timezoneName }))
	},

	actions: {
		init: ({ commit }) => {
			const collection = repository.getTimezones();

			return collection.then(() => {
				commit('set', { timezones: collection.toJSON(), collection });
			});
		}
	}
};
