import $ from 'jquery';
import icon from 'service/icon';
import dom from 'cwdom';
import ButtonHelper from 'service/button/helper';

/**
 * Button builder service.
 *
 * @function button
 * @author Marcin
 * @requires button
 * @param {string} [opts.css] - Custom CSS classNames for box.
 * @param {string} [buttonClass]
 * @param opts
 * @param click
 * @param {string} [opts.tagName]
 * @param {Bool} [opts.preventCwbutton] - Wether or not prevent
 *                                           usage the almighty 'cwbutton' class.
 * @param {click-callback} click
 * @returns DOMNode.
 */

const ButtonBuilder = function (opts, click) {
	const options = opts || {};
	const tagName = options.tagName || 'a';
	const classes = options.preventCwbutton ?
		{
			box: '',
			button: ''
		} :
		{
			box: 'cwbutton-box',
			button: 'cwbutton'
		};
	const buttonClass = options.buttonClass || '';

	const css = classes.box + ((options.css) ? ` ${options.css}` : '');

	const box = dom.createElement({
		tag: 'div',
		css,
		parent: options.parent
	});
	const button = $(`<${tagName} />`)
		.addClass([buttonClass, classes.button].join(''))
		.attr('tabindex', '0')
		.on('click', function (ev) {
			const event = ev || window.event;

			if (event.stopPropagation) {
				event.stopPropagation();

			} else {
				event.cancelBubble = true;
			}

			if (!$(this).hasClass('disabled')) {
				click.call(this, event, new ButtonHelper(this));
			}

		})
		.dblclick((event) => {
			event.preventDefault();
		}).get(0);

	if (options.big) {
		$(button).addClass('big-button');
	}

	$(box).append(button);

	if (options.icon) {
		icon(options.icon, {
			element: button
		});
	}

	if (options.caption) {
		$(button).text(options.caption);
	}

	if (options.href) {
		$(button).attr('href', options.href);
	}

	if (options.primary) {
		$(button).addClass('primary');
	}

	if (options.title) {
		$(button).attr('title', options.title);
	}

	return box;
};

export default ButtonBuilder;
