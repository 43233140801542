import $ from 'jquery';
import Marionette from 'marionette';
import TileContainerLayout from './TileContainerLayout';

export default Marionette.CollectionView.extend({
	className: 'tiles__container',
	childView: TileContainerLayout,

	onAfterItemAdded (itemView) {
		itemView.model.set('attached', true);
		this.el.className = `${this.className} tile-count-${this.collection.length}`;
	},

	attachHtml (collectionView, itemView, index) {
		if (collectionView.isBuffering) {
			collectionView.elBuffer.appendChild(itemView.el);

		} else {
			const currCfg = itemView.model.get('containerConfig') || {};

			if (index > 0 && currCfg.column === true) {
				this.columnize(collectionView, itemView, index, currCfg);

			} else {
				collectionView.$el.append(itemView.el);
			}
		}
	},

	columnize (collectionView, itemView, index, currCfg) {
		const prevView = collectionView.children.findByIndex(index - 1);
		const currWidth = currCfg.width || 2;
		const columnClass = 'tile__column tile__column-or-container';
		const $els = prevView.$el.add(itemView.$el);
		const $prevParent = prevView.$el.parent();
		const $wrapper = $('<div />')
			.addClass(columnClass)
			.addClass(`width-${currWidth}`);

		if ($prevParent.hasClass(columnClass)) {
			itemView.$el.appendTo($prevParent);

		} else {
			$els.wrapAll($wrapper);
		}
	}
});

