const cache = {};

export default ({ xhrObj, url }) => {
	let total = 0;

	if (xhrObj.status === 200) {
		total = +xhrObj.getResponseHeader('X-Total-Count');
		cache[url] = total;
	}

	return total;
};
