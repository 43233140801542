var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      directives: [
        {
          name: "on-click-outside",
          rawName: "v-on-click-outside",
          value: _vm.hide,
          expression: "hide"
        }
      ],
      class: _vm.mainClassName
    },
    [
      _c(
        "div",
        {
          class: _vm.elementClassName("toggle-action"),
          attrs: {
            role: "button",
            tabindex: "0",
            "aria-label": _vm.description,
            "aria-expanded": _vm.toggled
          },
          on: {
            click: _vm.toggle,
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.toggle($event)
            }
          }
        },
        [
          _vm._v("\n\t\t" + _vm._s(_vm.description) + "\n\t\t"),
          _c("icon", {
            class: _vm.elementClassName("toggle-indicator"),
            attrs: { small: true, name: "angle-down" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.toggled
          ? _c(
              "ul",
              { class: _vm.elementClassName("items") },
              _vm._l(_vm.filterItems, function(filterItem, i) {
                return _c(
                  "li",
                  { key: i, class: _vm.elementClassName("item") },
                  [
                    _c(
                      "div",
                      {
                        class: _vm.elementClassName("action"),
                        attrs: {
                          role: "button",
                          tabindex: "0",
                          "aria-label": filterItem.label,
                          "aria-pressed": filterItem.checked
                        },
                        on: {
                          click: function($event) {
                            return _vm.toggleItem(filterItem)
                          },
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.toggleItem(filterItem)
                          }
                        }
                      },
                      [
                        _vm.multi
                          ? _c("icon", {
                              class: _vm.elementClassName("action-icon"),
                              attrs: { name: _vm.checkboxIcon(filterItem) }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "span",
                          { class: _vm.elementClassName("action-text") },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(filterItem.label) +
                                "\n\t\t\t\t\t"
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }