<template>
	<ul class="generic-list__list information-package-elements">
		<content-package-element
			v-for="item in contentPackages"
			:key="item.id"
			:item="item"
			:icons-only="iconsOnly"
		/>
	</ul>
</template>

<script>
import { RESPONDENT_CONTENT_PACKAGE } from 'store/content-packages/rp-content-package';
import {
	RESPONDENT_CONTENT_PACKAGE_ELEMENTS
} from 'store/content-packages/rp-content-package-elements';
import ContentPackageElement from './element.vue';
import { map, assign, get } from 'lodash';
import TYPE_LABELS from '../../shared/type-labels';
import getFileType from 'service/get-file-type';
import { RP_CONTENT_PACKAGE_ID } from '../../constants';
import t from 'translate';

export default {
	title: t('Information package elements'),
	actions: ({ $store }) => ([
		[RESPONDENT_CONTENT_PACKAGE.INIT, {
			contentPackageId: $store.getters.urlParams[RP_CONTENT_PACKAGE_ID]
		}],
		[RESPONDENT_CONTENT_PACKAGE_ELEMENTS.INIT, {
			contentPackageId: $store.getters.urlParams[RP_CONTENT_PACKAGE_ID]
		}]
	]),
	components: { ContentPackageElement },
	computed: {
		iconsOnly: ({ tile }) => get(tile.config(), 'iconsOnly'),
		contentPackage: ({ $store }) => $store.getters[RESPONDENT_CONTENT_PACKAGE.DATA],
		contentPackages: ({ $store, defineTypes }) => map(
			$store.getters[RESPONDENT_CONTENT_PACKAGE_ELEMENTS.DATA], (item) => ({
				...item,
				...defineTypes(item)
			})
		)
	},
	created () {
		this.tile.setTitle(this.contentPackage.name);
	},
	methods: {
		defineTypes (item) {
			const isMedia = item.type === 'MEDIA';
			const types = {
				isAssessment: item.type === 'ASSESSMENT',
				isExercise: item.type === 'EXERCISE',
				isLink: item.type === 'LINK',
				isMedia,
				isPage: item.type === 'PAGE'
			};

			if (isMedia) {
				const fileType = getFileType(item.uploadedMedia.file.mimeType);
				assign(types, {
					fileType,
					typeLabel: this.$t(TYPE_LABELS[fileType])
				});
			}
			return types;
		}
	}
};
</script>
