import SingleEntity from '../shared/single-entity';
import urls from './urls';
import systemSettings from 'system-settings';
import t from 'translate';
import { isNull } from 'lodash';

const CurrentRespondentEntity = SingleEntity.extend({
	idAttribute: 'respondentId',
	url: urls.currentRespondent.url,
	getTimezone () {
		return this.get('respondentTimezoneName');
	},

	displayName () {
		const firstName = this.get('respondentFirstName');
		const lastName = this.get('respondentLastName');

		if (firstName || lastName) {
			return `${firstName} ${lastName}`;
		}
		return this.get('respondentUsername');
	},

	validate (attrs) {
		const keys = [];
		const msgs = [];
		const passwordRegex = new RegExp(systemSettings.getString('RESPONDENT_PASSWORD_REGEX'));

		if (!isNull(attrs.respondentPassword) && !passwordRegex.test(attrs.respondentPassword)) {
			keys.push('respondentPassword');
			msgs.push(t('Password has to match required pattern'));
		}

		if (keys.length) {
			return {
				key: keys,
				msg: msgs
			};
		}
	}
});

export default new CurrentRespondentEntity();

