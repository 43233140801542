import repository from 'repository';
import { find, get, findIndex, reduce, some } from 'lodash';

export default {
	namespaced: true,

	state: {
		data: [],
		treatmentTypeRoleId: null
	},
	mutations: {
		set: (state, payload) => {
			state.data = payload;
		},
		add: (state, payload) => {
			state.data.push(payload);
		},
		remove: (state, denialId) => {
			const index = findIndex(state.data, ({ id: denialId }));
			state.data.splice(index, 1);
		},
		setTreatmentTypeRoleId: (state, payload) => {
			state.treatmentTypeRoleId = payload;
		}
	},
	getters: {
		deniedClinicians: (state) => state.data,
		// eslint-disable-next-line max-params
		deniedInRoles: (state, getters, rootState, rootGetters) => some(state.data, (denial) => {
			const currentRole = get(getters.denialsWithCurrentRole, 'respondent.id');
			const access = find(
				rootGetters['treatment/treatmentRoles'],
				['respondent', currentRole]
			);

			// check if clinician has access to a respondent
			if (!access || !access.displayName) {
				return true;
			}
			return currentRole === +denial.respondent.id;
		}),
		denialsWithCurrentRole: (state) =>
			state.treatmentTypeRoleId && reduce(
				state.data,
				// configured role equals one of a treatment's role
				(result, denial) => {
					const found = find(
						denial.treatment.treatmentRoles,
						['treatmentTypeRole.id', state.treatmentTypeRoleId]
					);
					return found || result;
				}, false
			),
		// eslint-disable-next-line max-params
		userDenied: (state, getters, rootState, rootGetters) => some(
			state.data,
			['administrator.administratorId', rootGetters.userId]
		),
		userDeniedByRole: (state, getters) => getters.userDenied && getters.deniedInRoles
	},
	actions: {
		init: ({ commit, rootGetters }) =>
			repository.treatmentDenied({
				treatmentId: rootGetters['treatment/treatmentId']
			}).then((clinicians) => {
				commit('set', clinicians);
			}),

		add: ({ commit, rootGetters }, { clinicianId: id, treatmentRole }) => {
			const respondent = find(rootGetters['treatment/roles'], {
				id: +treatmentRole
			});
			const respondentId = get(respondent, 'respondent.id');

			return repository.addTreatmentDenied({
				treatmentId: rootGetters['treatment/treatmentId'],
				treatmentAccessDenial: {
					administrator: {
						id
					},
					respondent: {
						id: respondentId
					}
				}
			}).then((data) => {
				commit('add', data);
			});
		},

		remove: ({ commit, rootGetters }, { id: denialId }) =>
			repository.removeTreatmentDenied({
				treatmentId: rootGetters['treatment/treatmentId'],
				denialId
			}).then(() => {
				commit('remove', denialId);
			}),

		setTreatmentTypeRoleId: ({ commit }, id) => commit('setTreatmentTypeRoleId', id)
	}
};
