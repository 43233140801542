<template>
	<div :class="containerClassName">
		<label :class="labelClassName">
			<icon v-if="icon !== ''" :name="icon" :class-name="iconClass" />
			<p v-if="showLabel" :class="labelTextClassName">
				<span v-if="translate" v-translate>{{label}}</span>
				<span v-else>{{label}}</span>
			</p>
			<textarea
				v-if="multiline"
				ref="input"
				:value="value"
				:class="textareaClassName"
				:disabled="disabled"
				:readonly="readonly"
				:pattern="pattern"
				:placeholder="placeholder"
				@input="updateValue()"
			/>
			<input
				v-else
				ref="input"
				:type="inputType"
				:value="value"
				:class="inputClassName"
				:disabled="disabled"
				:readonly="readonly"
				:pattern="pattern"
				:placeholder="placeholder"
				@input="updateValue()"
			/>
			<div :class="validationsClassName">
				<slot name="validations" />
			</div>
			<div :class="hintsClassName">
				<slot name="hints" />
			</div>
		</label>
	</div>
</template>
<script>
import Vue from 'vue';
import { className } from './_helpers';
import { helpers, required } from 'vuelidate/lib/validators';
import { constant, get, invoke, isUndefined } from 'lodash';

const COMPONENT_NAME = 'text-input';

export default Vue.component(COMPONENT_NAME, {
	props: {
		value: {
			type: String,
			default: ''
		},
		classAdditional: {
			type: String,
			default: null
		},
		classPrefix: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		icon: {
			type: String,
			default: ''
		},
		invalid: {
			type: Boolean,
			required: false,
			default: undefined
		},
		label: {
			type: String,
			required: true
		},
		mandatory: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		},
		showLabel: {
			type: Boolean,
			default: true
		},
		multiline: {
			type: Boolean,
			default: false
		},
		numeric: {
			type: Boolean,
			default: false
		},
		tel: {
			type: Boolean,
			default: false
		},
		email: {
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		},
		translate: {
			type: Boolean,
			default: true
		},
		pattern: {
			type: [String, Boolean],
			default: false
		},
		placeholder: {
			type: String,
			default: ''
		}
	},

	computed: {
		containerClassName () {
			const element = 'field-container';
			const classes = this.className(element);

			if (this.classAdditional && this.classPrefix) {
				classes.push(...this.className(this.classAdditional));
			}

			if (this.required) {
				classes.push(...this.className(`${element}--mandatory`));
			}
			return classes;
		},

		inputType () {
			if (this.numeric) {
				return 'number';

			} else if (this.tel) {
				return 'tel';

			} else if (this.email) {
				return 'email';
			}

			return 'text';
		},

		iconClass () {
			return this.icon !== '' ? this.className(`icon-${this.icon}`) : null;
		},

		labelClassName () {
			return [
				...this.className('label-container'),
				this.invalidValue && 'invalid'
			];
		},

		labelTextClassName () {
			const classes = [this.className('label-text')];

			if (this.mandatory) {
				classes.push(this.className('label--mandatory'));
			}

			if (this.classPrefix) {
				classes.push(`${this.classPrefix}__label-text`);
			}
			return classes;
		},

		inputClassName () {
			const classes = [this.className('input')];

			if (this.classAdditional) {
				classes.push(this.className(this.classAdditional));
			}
			return classes;
		},

		textareaClassName () {
			return this.className('textarea');
		},

		validationsClassName () {
			return this.className('validations');
		},

		hintsClassName () {
			return this.className('hints');
		},

		localInvalid () {
			return get(this.$v, 'value.$dirty') && get(this.$v, 'value.$invalid');
		},

		invalidValue () {
			return !isUndefined(this.invalid) ? this.invalid : this.localInvalid;
		}
	},

	methods: {
		updateValue () {
			this.$emit('input', this.$refs.input.value);
			invoke(this.$v, 'value.$touch');
		},
		className: className(COMPONENT_NAME)
	},

	validations () {
		const validations = {};
		const pattern = this.pattern ?
			helpers.regex('pattern', new RegExp(this.pattern)) :
			constant(true);

		if (this.required) {
			validations.value = { pattern, required };
		}

		return validations;
	}
});
</script>
