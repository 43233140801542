import { forEach, includes, noop, reject, some } from 'lodash';
import { View } from 'backbone';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import $ from 'jquery';
import appLayout from 'core/views/app-layout-view';
import t from 'translate';
import appContext from 'app-context';

import filterComponents from '../components/init-filter-components';
import store from 'store';
import template from './template';

export default View.extend({
	tagName: 'section',

	initialize (params) {
		this.initializeVue(this, params);
		this.bindEvents(this);

		return this;
	},

	initializeVue: (tile, {
		title = {
			filterResults: t('Filter results'),
			filters: t('Filter by'),
			clearAll: t('Clear all'),
			search: t('Find')
		},
		filters = [],
		searchFields = [],
		onClear = noop,
		onRestore = noop,
		results = {},
		id = ''
	}) => {

		tile.vue = new Vue({
			store,
			el: document.createElement('div'),
			components: filterComponents(),
			data: {
				title,
				filters,
				searchFields,
				results
			},
			computed: {
				...mapGetters({ focusedFilter: 'filter/focusedFilter' }),
				resultsCount () {
					return this.$store.getters['filter/resultsCount'];
				},
				invalid () {
					return !!reject(this.$store.state.filter.validity, 'valid').length;
				},
				...mapState('filter', ['filterControls', 'isFilterToggled', 'isSomethingSelected'])
			},

			created () {
				if (id !== this.$store.state.filter.id) {
					this.$store.dispatch('filter/setId', id);
					this.$store.dispatch('filter/clearValues');

				} else {
					this.restore();
				}

				this.$store.dispatch('filter/setResults', { results: this.results });
			},

			methods: {
				clearFilters () {
					forEach(this.searchFields, (searchField) => {
						searchField.startValue = '';
					});
					this.$store.dispatch('filter/clearValues');
					appContext.trigger('filter.controls.clear');
					onClear();
				},

				hideControls () {
					this.$store.dispatch('filter/hideControls');
				},

				restore () {
					onRestore();
				}
			},
			template
		});

		tile.$el.html(tile.vue.$el);
	},

	bindEvents: (tile) => {
		const filterClassNames = [
			'filter__toggle',
			'filter__toggle-number',
			'filter__action',
			'filter__remove-action',
			'filtering__button',
			'filtering__action',
			'filter__input'
		];
		const isFilterAction = (target) =>
			$(target).parents('.vdatetime').length ||
			some(
				filterClassNames,
				(filterClassName) => includes(target.className, filterClassName)
			);

		// hide controls on click outside filtering controls
		tile.listenTo(appLayout, 'click', (e) => {
			if (store.getters['filter/anyFilterToggled'] && !isFilterAction(e.target)) {
				tile.vue.hideControls();
			}
		});
	},

	close () {
		this.remove();
	}
});
