import { View } from 'backbone';
import formView from 'components/form-view/form-view';
import t from 'translate';
import systemSettings from 'system-settings';
import moment from 'moment';
import cwalert from 'cwalert';
import moodField from '../shared/mood-field';

const period = (periodName) => (date) => moment(date)[periodName]();

export default View.extend({
	className: 'diary-entry__form',

	render () {
		this.editModel = this.model.clone();

		const currMonth = period('month')(this.model.get('createdAt'));
		const currYear = period('year')(this.model.get('createdAt'));

		const fields = [{
			key: 'createdAt',
			type: 'date',
			required: true
		}, {
			key: 'content',
			type: 'textarea',
			placeholder: t('Add text to diary here'),
			required: true,
			autoresize: true
		}];

		if (systemSettings.getBoolean('RESPONDENT_DIARY_MOOD')) {
			fields.push(moodField());
		}

		this.form = formView({
			model: this.editModel,
			successMessage: t('Diary entry changed'),
			listenTo: ['submit'],
			loader: true,
			onAfterSave: () => {
				const monthChanged = period('month')(this.editModel.get('createdAt')) !== currMonth;
				const yearChanged = period('year')(this.editModel.get('createdAt')) !== currYear;

				if (monthChanged || yearChanged) {
					cwalert.notice(
						t(`Diary entry post date has been changed so it has been moved`)
					);
				}
			},
			fields,
			buttons: [{
				caption: t('Save'),
				name: 'save',
				type: 'submit'
			}, {
				caption: t('Cancel'),
				name: 'cancel',
				type: 'button',
				role: 'reset',
				action: () => {
					this.model.trigger('cancel-update');
				}
			}]
		});
		this.$el.html(this.form.$el);
	}
});
