import repository from 'repository';
import { filter } from 'lodash';
import initEntities from '../__helpers/init-entities';

export default {
	namespaced: true,

	state: {
		data: [],
		collection: {}
	},

	getters: {
		raised: (state) => filter(state.data, { isRaised: true }),
		raisedCount: (state, getters) => getters.raised.length,
		size: (state) => state.data.length
	},

	mutations: {
		set: (state, { flags, collection }) => {
			state.data = flags;
			state.collection = collection;
		}
	},

	actions: {
		initForTreatment: ({ commit }, { treatmentId }) => {
			const collection = repository.treatmentFlags(treatmentId);

			return collection.then(() => {
				commit('set', { flags: collection.toJSON(), collection });
			});
		},

		initForRespondent: ({ commit }, { respondentId }) => {
			const collection = repository.respondentFlags(respondentId);

			return collection.then(() => {
				commit('set', { flags: collection.toJSON(), collection });
			});
		},

		init: initEntities('flags/init')
	}
};
