import SingleEntity from '../shared/single-entity';
import urls from './urls';
import t from 'translate';

// Disclaimer: the name is clearly ugly. It's on purpose. Backend API and
// the whole architecture around these 'custom attributes' is really bad.
// The name is a little reminder of that.

/**
 * RespondentAttributeDefinition Entity
 * @class RespondentAttributeDefinition
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	urlRoot: urls.attributeDefinition.url,
	eventNamespace: 'respondent-attribute-definition',
	idAttribute: 'respondentAttributeMetaId',

	label: {
		respondentAttributeMetaLabel: t('system-manager.respondentAttributeMetaLabel'),
		respondentAttributeMetaReadonly: t('system-manager.respondentAttributeMetaReadonly'),
		respondentAttributeMetaRequired: t('system-manager.respondentAttributeMetaRequired'),
		respondentAttributeMetaType: t('system-manager.respondentAttributeMetaType'),
		respondentAttributeMetaOrder: t('system-manager.respondentAttributeMetaOrder'),
		respondentAttributeMetaParameters: t('system-manager.respondentAttributeMetaParameters'),
		respondentAttributeMetaShow: t('system-manager.respondentAttributeMetaShow'),
		respondentAttributeMetaKeepHistory: t('system-manager.respondentAttributeMetaKeepHistory')
	},

	defaults: {
		respondentAttributeMetaReadonly: false,
		respondentAttributeMetaRequired: false,
		respondentAttributeMetaType: 'CHECKBOX',
		respondentAttributeMetaParameters: '',
		respondentAttributeMetaShow: true,
		respondentAttributeMetaKeepHistory: false
	}
});

