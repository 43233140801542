import user from 'core/entities/user-respondent';
import appStart from './app-start';
import modules from 'modules/respondent';
import backboneConfig from './config/backbone-config';
import jqueryConfig from 'config/jquery-config';
import respondentConfig from 'config/respondent-config';
import store from 'store';
import { TREATMENT } from 'store/treatments/rp-treatment';
import { TREATMENTS } from './store/treatments/treatments';

jqueryConfig({ type: 'respondent' });

backboneConfig({ type: 'respondent' });

const userEntity = user();

userEntity.then((response = {}) => {
	if (response.respondentHasRequiredTreatments === false) {
		const redirectLocation = response['backend-config'].selfRegisterUrl;
		const rootUrl = redirectLocation[0] === '/' ?
			respondentConfig.config().baseUrl :
			respondentConfig.config().authUrl;

		window.location = `${rootUrl}${redirectLocation}?from=${window.location}`;
	}
});

const afterStart = async () => {
	await Promise.all([
		store.dispatch(TREATMENT.INIT_DEFAULT),
		store.dispatch(TREATMENTS.INIT, { includeDisabled: true })
	]);
};

appStart({ user: userEntity, type: 'respondent', modules, afterStart });
