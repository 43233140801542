<template>
	<div>
		<section
			v-for="({mediaArr, label}) in groupedMedia"
			:key="label"
			class="information-package-elements__container"
		>
			<h3 v-if="label" class="information-package-elements__title">
				{{label}}
			</h3>
			<ul class="generic-list__list information-package-elements">
				<li
					v-for="media in mediaArr"
					:key="media.id"
					class="generic-list__item assessment-list__item"
				>
					<div
						:class="mediaClassName(media)"
						tabindex="0"
						@click="openMedia(media)"
						@keyup.enter="openMedia(media)"
					>
						<icon :name="media.fileType" :title="media.typeLabel" />
						<div
							class="
								generic-list__action-title-container
								information-package-element__action-title-container
							"
						>
							<span
								class="
										information-package-element__type-label
										information-package-element__media-content
									"
							>
								{{media.typeLabel}}
							</span>
							<p
								class="
									generic-list__action-title
									information-package-element__action-title
									information-package-element__media-content
								"
							>
								{{media.elementVersion.alias}}
							</p>
							<icon
								name="external-link"
								:title="$t('Open in new window')"
								class="information-package-element__open-external"
							/>
							<p class="information-package-element__state-label">
								{{stateLabel(media)}}
							</p>
						</div>
					</div>
				</li>
			</ul>
		</section>
	</div>
</template>

<script>
import systemSettings from 'system-settings';
import { CONTEXTS } from 'store/general/contexts';
import { TYPES } from 'store/general/types';
import { RESPONDENT_MEDIA } from 'store/media/rp-media';
import { TREATMENT } from 'store/treatments/rp-treatment';
import cardUrl from 'cwcardurl';
import Icon from 'components/icon';
import { filter, get, map, size } from 'lodash';
import getFileType from 'service/get-file-type';
import fileUrl from 'service/file-url/file-url';
import TYPE_LABELS from '../../shared/type-labels';
import t from 'translate';

export default {
	title: t('Media and documents'),
	actions: () => [
		[RESPONDENT_MEDIA.INIT],
		systemSettings.getBoolean('ASSESSMENT_TYPES') ? [TYPES.INIT] : [CONTEXTS.INIT]
	],
	features: ['ENABLE_RESPONDENT_MEDIA'],
	components: { Icon },
	computed: {
		groupBy: () => systemSettings.getBoolean('ASSESSMENT_TYPES') ? 'type' : 'context',
		rpMedia: ({ $store, $t }) => map($store.getters[RESPONDENT_MEDIA.DATA],
			(media) => {
				const fileType = getFileType(media.uploadedMedia.file.mimeType);

				return {
					...media,
					fileType,
					typeLabel: $t(TYPE_LABELS[fileType])
				};
			}),
		groups: ({ $store }) => systemSettings.getBoolean('ASSESSMENT_TYPES') ?
			$store.getters[TYPES.DATA] :
			$store.getters[CONTEXTS.DATA],
		groupedMedia: ({ rpMedia, groupBy, groups }) => {
			const array = [];

			const mediaWithoutGroup = filter(rpMedia, (media) => {
				const cpInstance = media.instance;
				return !cpInstance[groupBy];
			});

			array.push({
				label: '',
				mediaArr: mediaWithoutGroup
			});

			const mediaWithGroup = map(groups, (group) => {
				const id = +group.id || +group.assessmentInstanceContextId;
				const mediaArr = filter(rpMedia, (media) => {
					const cpInstance = media.instance;
					return +get(cpInstance[groupBy], 'id') === id;
				});

				return {
					label: group.assessmentInstanceContextLabel || group.label,
					mediaArr
				};
			});

			array.push(...mediaWithGroup);

			return filter(array, (group) => size(group.mediaArr));
		}
	},
	methods: {
		mediaClassName: (media) => `generic-list__action
				information-package-element__action
				information-package-element--${media.state}`,
		url (media) {
			return cardUrl.buildUrl('information-package-element', {
				informationPackage: media.instance.id,
				id: media.id,
				treatmentId: this.$store.getters[TREATMENT.ID]
			});
		},
		stateLabel (media) {
			return {
				NOT_STARTED: this.$t('Not started'),
				IN_PROGRESS: this.$t('In progress'),
				COMPLETED: this.$t('Completed')
			}[media.state];
		},
		async openMedia (media) {
			// mark as complete
			await this.$store.dispatch(
				RESPONDENT_MEDIA.COMPLETE,
				{
					contentPackageId: media.instance.id,
					elementId: media.id
				}
			);

			// improve after decision from defect 12191 - use a tag with url() method
			window.open(fileUrl(media.uploadedMedia.file.url), '_blank');
		}
	}
};
</script>
