import MultipleEntities from '../shared/multiple-entities';
import Treatment from './treatment';
import urls from './urls';

const TreatmentsEntity = MultipleEntities.extend({
	model: Treatment,
	urls,
	eventNamespace: 'treatment',
	order: 'name',

	search (params) {
		return this.retrieve('search', params);
	}
});

export default new TreatmentsEntity();
