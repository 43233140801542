import $ from 'jquery';
import { cwtagsNl2br } from '../../helpers/string';
import { assign, forEach, noop } from 'lodash';
import { init } from '../shared/view';
import { questionTextId } from '../shared/helpers';

export default function (model, assessment) {
	const { $container, $questionText, $answerContainer } = init({
		className: 'assessment-country-container',
		model
	});

	const $select = $(`<select aria-labelledby="${questionTextId(model)}" />`)
		.prop('disabled', model.isReadonly())
		.appendTo($answerContainer)
		.append($('<option />'));

	const highlightIfAnswered = () => {
		$container.toggleClass('highlightedRow', model.isAnswered());
	};

	highlightIfAnswered();

	const answers = model.getAnswers();
	forEach(answers, (answer) => {
		const $option = $(
			`<option
				id="${answer.getId()}"
				value="${answer.getTemplateId()}"
				class="${answer.getTemplateId()}"
			>${answer.getLabel()}</option>`
		)
			.prop('selected', answer.isSelected())
			.appendTo($select);

		if (!answer.isVisible()) {
			$option.hide();
		}
	});

	$select.on('change', (event) => {
		const code = event.target[event.target.selectedIndex].value;

		if (answers[code]) {
			answers[code].select();

		} else {
			model.resetAnswer();
		}

		highlightIfAnswered();
		assessment.startSaving();
	});

	assign(this, {
		clear: () => {
			$('option:selected', $container).prop('selected', false);
		},
		getElementBox: () => $container[0],
		unclear: noop,
		updateText: () => {
			$questionText.html(cwtagsNl2br(model.getQuestionText()));
		}
	});
}

