import { when } from 'jquery';
import repository from 'repository';
import { sortBy, toArray, unionBy } from 'lodash';
import { capitalize } from 'lib/string';

export default {
	namespaced: true,
	state: {
		type: '',
		blueprint: [],
		custom: [],
		registered: []
	},

	getters: {
		current: (state) => unionBy(state.custom, state.blueprint, 'card-name'),
		registered: (state) => sortBy(state.registered, 'name')
	},

	mutations: {
		set: (state, { type, blueprint, custom }) => {
			state.type = type;
			state.blueprint = blueprint;
			state.custom = custom;
		},

		setCustom: (state, custom) => {
			state.custom = custom;
		},

		registerMulti: (state, cards) => {
			state.registered.push(...toArray(cards));
		},

		setVariants: (state, variants) => {
			state.variants = variants;
		}
	},

	actions: {
		init: ({ commit, dispatch }, { type }) => {
			const blueprint = repository.cardConfig({ type: `blueprint${capitalize(type)}` });
			const custom = repository.cardConfig({ type: `custom${capitalize(type)}` });
			const variantsPromise = type === 'assessment' ? {} : dispatch('initVariants');

			return when(blueprint, custom, variantsPromise).then(() => {
				commit('set', { blueprint: blueprint.data, custom: custom.data, type });
			});
		},

		register: ({ commit }, { cards } = {}) => {
			cards && commit('registerMulti', cards);
		},

		initVariants: () => {
			const variants = repository.cardVariants();

			return variants.then(() => {
				window.localStorage.setItem('cardVariants', JSON.stringify(variants.data || {}));
			});
		}
	}
};
