import { View } from 'backbone';
import $ from 'jquery';
import { isArray, isFunction, isString, noop } from 'lodash';
import cardUrl from 'cwcardurl';
import canAccess from 'core/engine/card/services/can-access';

const tileClass = ['mini-tile', 'shortcut-tile'];
const tClass = (name) => `${tileClass[0]}__${name} ${tileClass[1]}__${name}`;

export default View.extend({
	tagName: 'a',
	className: tClass('container'),
	onInit: noop,
	init () {
		this.listenTo(this, 'show', () => {
			$(this.box()).addClass(tileClass.join(' '));
		});
		this.onInit(this);
	},
	render () {
		const $content = $('<p />')
			.addClass(tClass('caption'))
			.html(this.content);

		if (this.targetCard) {
			let params = [];

			isFunction(this.targetCard) && (params = this.targetCard.call(this));
			isString(this.targetCard) && params.push(this.targetCard);
			isArray(this.targetCard) && (params = this.targetCard);

			if (!canAccess(params[0])) {
				this.disable();
				return;
			}

			const url = params ? cardUrl.buildUrl.apply(null, params) : false;

			if (isString(url)) {
				this.url = url;
			}

		} else if (isFunction(this.url)) {
			this.url = this.url.call(this);
		}

		this.$el
			.attr('href', this.url)
			.append($content);
	}
});
