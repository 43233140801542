var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h1", { staticClass: "consent-content__header" }, [
        _vm._v("\n\t\t" + _vm._s(_vm.consent.name) + "\n\t")
      ]),
      _vm._v(" "),
      _c("consent-content", {
        attrs: {
          options: _vm.options,
          "on-submit": _vm.onSubmit,
          "on-option-toggle": _vm.onOptionToggle,
          submittable: _vm.submittable
        },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function() {
                return [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.consent.content) }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "optionContent",
              fn: function(ref) {
                var content = ref.content
                return [_c("div", { domProps: { innerHTML: _vm._s(content) } })]
              }
            },
            !_vm.consent.canSubmit
              ? {
                  key: "checkbox",
                  fn: function(ref) {
                    var option = ref.option
                    return [
                      _c("icon", {
                        staticClass: "option__answer",
                        class: _vm.className(option.status),
                        attrs: { name: _vm.iconName(option.status) }
                      })
                    ]
                  }
                }
              : null
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }