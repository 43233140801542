import FormComponentView from '../form-component-view';
import { compile } from 'handlebars';
import { Deferred } from 'jquery';
import Validation from '../validation';

const email = FormComponentView.extend({
	template: compile(`
		<label class="form-view__label-container">
			<p class="form-view__label-text">{{ label }}</p>
			<input
				type="email"
				class="form-view__input form-view__email"
				name="{{ uniqueName }}"
				value="{{ value }}"
				{{#if required}} required="required" {{/if}}
				{{#if readonly}} readonly="readonly" {{/if}}
				{{#if disabled}} disabled="disabled" {{/if}}
				{{#if hint}} aria-describedby="{{ name }}-hint"{{/if}} />
		</label>
		{{#if hint}}
			<div class="form-view__hint" id="{{ name }}-hint">{{ hint }}</div>
		{{/if}}
	`),

	validate () {
		const dff = Deferred();

		if (this.model.get('mandatory') || !!this.ui.input.val().length) {
			if (Validation.email(this.ui.input.val())) {
				dff.resolve();
			} else {
				dff.reject();
				this.setInvalid(Validation.invalidMessages.email);
			}
		} else {
			dff.resolve();
		}

		return [dff.promise()];
	}
});

export { email };
