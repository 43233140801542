import TileView from 'tile-view';
import t from 'translate';
import cardUrl from 'cwcardurl';
import datetime from 'datetime';
import store from 'store';
import { TREATMENT } from 'store/treatments/rp-treatment';

export default TileView.extend({
	className: 'my-reports__container',
	title: t('Reports'),
	features: ['ENABLE_RESPONDENT_REPORTS_PAGE'],
	actions: (tile) => [['reports/initForCurrentRespondent', tile.config()]],

	Vue: () => ({
		template: `
		<div>
			<section v-for="group in groupedReports">
				<h2 v-if="group.label.length" class="reports-list__group-label">{{group.label}}</h2>
				<table class="table reports-list__table">
					<thead>
						<tr>
							<th v-translate>Title</th>
							<th v-translate>Date</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="report in group.reports"
							class="clickable-row"
							tabindex="0"
							@click="openReport(report)"
						>
							<td>{{report.title}}</td>
							<td>{{formatDate(report)}}</td>
						</tr>
					</tbody>
				</table>
			</section>
		</div>
		`,
		computed: {
			groupedReports () {
				return this.$store.getters['reports/grouped'];
			}
		},

		methods: {
			formatDate (report) {
				return datetime({ date: report.lastModified })
					.setTimezone(this.$store.state.user.timezoneName)
					.toMoment()
					.format(t('date.formats.longDateTime'));
			},
			openReport (report) {
				cardUrl.openCard('report-content', {
					assessmentInstanceId: report.assessmentInstanceId,
					reportId: report.reportId,
					treatmentId: store.getters[TREATMENT.ID]
				});
			}
		}
	})
});
