<template>
	<div :class="classes.labels">
		<ul :class="classes.list">
			<li v-for="(label, index) in labels" :key="index" :class="classes.item">
				<icon :name="label.icon" :class-name="classes.icon" />
				<span :class="classes.text" :title="title(label)">
					<template v-if="label.title">{{label.title}}: </template>{{label.value}}
				</span>
			</li>
		</ul>
	</div>
</template>

<script>
import { requiredArray } from './props';
import { AGENDA_ITEM_LABELS } from './constants';
import { get } from 'lodash';

export default {
	name: AGENDA_ITEM_LABELS,
	props: {
		labels: requiredArray
	},
	data: () => ({
		classes: {
			labels: AGENDA_ITEM_LABELS,
			list: `${AGENDA_ITEM_LABELS}__list`,
			item: `${AGENDA_ITEM_LABELS}__list-item`,
			icon: `${AGENDA_ITEM_LABELS}__icon`,
			text: `${AGENDA_ITEM_LABELS}__text`
		}
	}),

	methods: {
		title: (label) => `${get(label, 'title', '')}: ${get(label, 'value', '')}`
	}
};
</script>
