import urlify from 'service/urlify/urlify';
import { param } from 'jquery';
import { assign } from 'lodash';

export default {
	single: {
		url () {
			return `backend-commons/page/${this.getId() ? this.getId() + '?include=category' : ''}`;
		}
	},

	singleCategory: {
		url: 'backend-commons/page-category/'
	},

	list: {
		url: 'backend-commons/page/?filter[deleted]=0&include=category'
	},

	listAll: {
		url: 'backend-commons/page/'
	},

	listAllCategories: {
		url: 'backend-commons/page-category/'
	},

	listWithRoles: {
		url: (params) => `backend-commons/information-package/instance/element/?filter[type]=PAGE&${param(params)}`
	},

	homepage: {
		url: (name) => `backend-commons/page/${name}/`
	},

	permalink: {
		url: (alias) => `backend-commons/page/permalink/${alias}`
	},

	random: {
		url: (category) => `backend-commons/page/random?filter[category]=${category}`
	},

	search: {
		url: (params = {}) => `backend-commons/page/?include=category&${urlify(assign({ deleted: 0 }, params))}`
	}
};
