var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    [
      _vm._l(_vm.objVal, function(row, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass:
              "edit-object__row edit-object__row--edit array-row__field"
          },
          [
            _c("p", { staticClass: "array-row__index" }, [
              _vm._v("\n\t\t\t" + _vm._s(index) + "\n\t\t")
            ]),
            _vm._v(" "),
            _vm._l(row, function(rowItem, i) {
              return _c(
                "input",
                _vm._g(
                  {
                    directives: [{ name: "trim", rawName: "v-trim" }],
                    key: i,
                    attrs: { type: "text", readonly: _vm.readonly },
                    domProps: { value: rowItem },
                    on: {
                      blur: function($event) {
                        return _vm.onArrayArrayValueChange(index, i, $event)
                      }
                    }
                  },
                  _vm.inputListeners
                )
              )
            }),
            _vm._v(" "),
            !_vm.readonly
              ? _c(
                  "button",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }],
                    staticClass:
                      "edit-object__button--add edit-object__button--add-item",
                    on: {
                      click: function($event) {
                        return _vm.addArrayArrayItem(index)
                      }
                    }
                  },
                  [_vm._v("editObject.addItem\n\t\t")]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.readonly
              ? _c(
                  "button",
                  {
                    directives: [{ name: "translate", rawName: "v-translate" }],
                    staticClass:
                      "edit-object__button--remove edit-object__button--remove-item",
                    on: {
                      click: function($event) {
                        return _vm.removeArrayArrayItem(index)
                      }
                    }
                  },
                  [_vm._v("editObject.removeLastItem\n\t\t")]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.readonly
              ? _c("button", {
                  staticClass:
                    "edit-object__button edit-object__button--remove",
                  on: {
                    click: function($event) {
                      return _vm.removeArrayArrayRow(index)
                    }
                  }
                })
              : _vm._e()
          ],
          2
        )
      }),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "edit-object__row edit-object__row--add array-row__field"
        },
        [
          _vm._l(_vm.newArrayArrayRow, function(newItem, i) {
            return _c(
              "input",
              _vm._g(
                {
                  directives: [{ name: "trim", rawName: "v-trim" }],
                  key: i,
                  attrs: { type: "text", readonly: _vm.readonly },
                  on: {
                    blur: function($event) {
                      return _vm.onNewArrayArrayRowChange(i, $event)
                    }
                  }
                },
                _vm.inputListeners
              )
            )
          }),
          _vm._v(" "),
          !_vm.readonly
            ? _c(
                "button",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  staticClass:
                    "edit-object__button--add edit-object__button--add-item",
                  on: { click: _vm.addNewArrayArrayItem }
                },
                [_vm._v("editObject.addItem\n\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.readonly
            ? _c(
                "button",
                {
                  directives: [{ name: "translate", rawName: "v-translate" }],
                  staticClass:
                    "edit-object__button--remove edit-object__button--remove-item",
                  on: { click: _vm.removeNewArrayArrayItem }
                },
                [_vm._v("editObject.removeLastItem\n\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.readonly
            ? _c("button", {
                staticClass: "edit-object__button--add",
                on: { click: _vm.addArrayArrayRow }
              })
            : _vm._e()
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }