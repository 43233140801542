import urlify from 'service/urlify/urlify';
import QueryParams from 'lib/url/query-params';

export default {
	single: {
		url: 'backend-commons/treatment/'
	},
	list: (params = '') => `backend-commons/treatment/${
		params ? `?${new QueryParams(params)}` : ''
	}`,
	search: {
		url: (params) => `backend-commons/treatment/?${urlify(params)}`
	},
	attributes: (treatmentId) => `backend-commons/treatment/${treatmentId}/attribute/`,
	attribute: ({ treatmentId, attributeId, treatmentTypeAttributeId }) =>
		`backend-commons/treatment/${treatmentId}/attribute/${
			treatmentTypeAttributeId || attributeId
		}/`,
	defaultAttributeValues: (treatmentTypeId) =>
		`/backend-commons/treatment-type/${treatmentTypeId}/attribute/default-values/`
};
