import ReportInstance from '../reports/instance';

/**
 * RespondentReportInstance Entity
 * @class RespondentReportInstance
 * @extends ReportInstance
 */
export default ReportInstance.extend({
	eventNamespace: 'respondent-report-instance'
});
