import FormView from './views/form';
import { debounce } from 'lodash';

export default (params) => {
	const formView = new FormView(params);

	// since save function needs to be debounced (to avoid multiple saves
	// for every input change), it has to be taken out of prototype chain
	// and copied for each instance so multiple form view instances save
	// calls don't interfere with each other
	formView.save = debounce(formView.save, formView.SAVE_TIME_THRESHOLD);

	return formView.render();
};
