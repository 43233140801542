import { keepalive, idleTime } from './urls';
import { GET } from 'service/ajax/ajax';

export default {
	/**
	 * Keeps the user logged in.
	 *
	 * @returns {Promise<void>} - The Emptiness.
	 * @example
	 * 		repository.keepalive();
	 */
	keepalive: () => GET({ url: keepalive }),

	/**
	 * Get idle time and max idle time.
	 *
	 * @returns {Promise<object>} - Session time data.
	 * @example
	 * 		repository.idleTime();
	 */
	idleTime: () => GET({ url: idleTime })
};
