var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { class: _vm.classes.actions },
    _vm._l(_vm.actions, function(action, index) {
      return _c(
        "li",
        { key: index + ":" + action.title, class: _vm.classes.actionContainer },
        [
          action.component
            ? _c(action.component, {
                tag: "component",
                attrs: { action: action }
              })
            : _c("agenda-item-action", {
                attrs: {
                  action: action.action,
                  title: action.title,
                  classes: _vm.classes.action
                }
              })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }