import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import MessageAttachment from './message-attachment';

/**
 * RespondentMessageAttachments Entity
 * @class RespondentMessageAttachments
 * @extends MultipleEntities
 */
const RespondentMessageAttachments = MultipleEntities.extend({
	model: MessageAttachment,
	urls,
	eventNamespace: 'respondent-message-attachment'
});

export default new RespondentMessageAttachments();
