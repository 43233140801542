import { assign } from 'lodash';
import { Deferred, getJSON, when } from 'jquery';

const cache = Deferred();
const cacheXHR = getJSON('data/cache.json');
const customCacheXHR = getJSON('data/cache-custom.json');

when(cacheXHR, customCacheXHR).then(() => {
	assign(cache, cacheXHR.responseJSON, customCacheXHR.responseJSON);
	cache.resolve();
});

export default cache;
