var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.containerClassName }, [
    _c(
      "label",
      { class: _vm.labelClassName },
      [
        _vm.showLabel
          ? [
              _vm.icon
                ? _c("icon", {
                    attrs: { name: _vm.icon, "class-name": _vm.iconClass }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.translate
                ? _c(
                    "p",
                    {
                      directives: [
                        { name: "translate", rawName: "v-translate" }
                      ],
                      class: _vm.labelTextClassName
                    },
                    [_vm._v(_vm._s(_vm.label))]
                  )
                : _c("p", { class: _vm.labelTextClassName }, [
                    _vm._v(_vm._s(_vm.label))
                  ])
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.async && _vm.loading
          ? _c("div", {
              directives: [
                {
                  name: "loader-spinner",
                  rawName: "v-loader-spinner:small",
                  arg: "small"
                }
              ],
              staticClass: "select-input__loader"
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.readonly
          ? _c("input", {
              staticClass: "text-input__input",
              attrs: {
                disabled: _vm.disabled,
                readonly: "",
                placeholder: _vm.placeholder
              },
              domProps: { value: _vm.value }
            })
          : _c(
              "v-select",
              _vm._b(
                {
                  ref: "vSelect",
                  attrs: {
                    clearable: _vm.clearable,
                    disabled: _vm.disabled,
                    filterable: !_vm.async,
                    "get-option-label": _vm.getOptionLabel,
                    label: _vm.itemLabel,
                    loading: _vm.loading,
                    multiple: _vm.multiple,
                    "no-drop": !_vm.dropdownVisible,
                    options: _vm.options,
                    placeholder: _vm.placeholder,
                    searchable: _vm.searchable,
                    value: _vm.value
                  },
                  on: { input: _vm.updateValue, search: _vm.onSearch },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function() {
                        return [_vm._v(_vm._s(_vm.$t("selectInput.noResults")))]
                      },
                      proxy: true
                    }
                  ])
                },
                "v-select",
                _vm._d({}, [_vm.maybereduce, _vm.reduce])
              )
            )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }