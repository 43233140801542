import t from 'translate';
import ShortcutTile from 'components/mini-tiles/shortcut';
import enableCirclesCheck from '../../helpers/enable-circles-check';

export default ShortcutTile.extend({
	targetCard: 'circles-startpage',
	content: t('Circles'),
	acl: [{
		op: 'READ',
		checkpoint: 'administrator.circles.circles'
	}],
	features: enableCirclesCheck
});
