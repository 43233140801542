<template>
	<div class="mini-value-tile" :class="className">
		<content-container :subtitle="subtitle" :link="link">
			<div class="mini-value-tile__text-container">
				<p class="mini-value-tile__title">
					{{title}}
				</p>
				<p v-if="subtitle" class="mini-value-tile__subtitle">
					<span>{{subtitle}}</span>
				</p>
			</div>

			<div class="mini-value-tile__value-container">
				<span class="mini-value-tile__value">{{value}}</span>
				<i
					v-if="symbol"
					v-once
					class="mini-value-tile__symbol fa"
					:class="symbol"
					:style="symbolStyle"
				/>
				<span v-else-if="indicator" class="mini-value-tile__subvalue">
					{{indicator}}
				</span>
			</div>
		</content-container>
	</div>
</template>

<script>
import {
	assign, constant, find, forEach, get, isNil, isNull, isNumber, isString, isUndefined, map
} from 'lodash';
import t from 'translate';
import cardUrl from 'cwcardurl';
import { sprintf } from 'sprintf';
import between from 'lib/between';
import { EMPTY_CHAR } from 'lib/chars';
import datetime from 'service/datetime/datetime';
import ContentContainer from './content-container';
import { READ } from 'service/acl/access-levels';

const isValid = (arg) => isString(arg) || isNumber(arg);
const substitute = (arg, fallback) => isValid(arg) ? fallback : NaN;
const cast = ({ arg, dependency, fallback = 0 }) => isValid(arg) ?
	+arg :
	substitute(dependency, fallback);

export default {
	acl: [{
		checkpoint: 'administrator.respondents.assessmentplans',
		op: READ
	}, {
		checkpoint: 'administrator.respondents.assessmentcontent',
		op: READ
	}],
	actions: (tile) => {
		const actions = [[
			'assessmentValues/getValues',
			assign({}, tile.config()),
			{},
			{ instant: true }
		]];

		if (!isUndefined(get(tile.config(), 'dataSources[0].roleId'))) {
			actions.push(['treatment/init']);
		}

		return actions;
	},
	components: { ContentContainer },
	data: ({ tile }) => ({
		dataSources: tile.config().dataSources,
		config: tile.config()
	}),

	computed: ({
		typeLabel: ({ dataSources }) => {
			const labels = {
				last: constant('Latest'),
				previous: constant('Previous'),
				average: constant('Average'),
				first: constant('First'),
				minimum: constant('Minimum'),
				maximum: constant('Maximum'),
				def: constant(' ')
			};

			return (labels[dataSources[0].occurrence] || labels.def)();
		},

		className ({ indicator, link, ruleset, subtitle }) {
			const className = get(ruleset, 'className', false);

			return {
				'mini-value-tile--with-subtitle': !!subtitle.length,
				'mini-value-tile--with-link': !!link.length,
				'mini-value-tile--without-link': !link.length,
				'mini-value-tile--with-subvalue': !!indicator.length || ruleset,
				...(!isNull(this.value) && { [className]: className })
			};
		},

		title: ({ cfg2txt, config, dataSources, typeLabel }) => {
			if (!config.title) {
				return `${t(typeLabel)} ${dataSources[0].assessmentCode} ${t('score')}`;
			}

			return cfg2txt(config.title);
		},

		subtitle: ({ cfg2txt, config }) => config.subtitle ? cfg2txt(config.subtitle) : '',

		link () {
			const assessmentInstanceId = get(
				this.result,
				'assessmentInstance.assessmentInstanceId'
			);
			const reportId = get(this.result, 'assessmentInstance.report.id');

			if (!this.config.link || this.config.link === 'none' || !assessmentInstanceId) {
				return '';
			}

			return {
				assessment: cardUrl.buildUrl('assessment-properties', { assessmentInstanceId }),
				report: reportId ?
					cardUrl.buildUrl('assessment-report', { assessmentInstanceId, reportId }) :
					''
			}[this.config.link];
		},

		result () {
			const values = this.$store.getters['assessmentValues/valuesByRef'];
			const ref = this.dataSources[0].ref;
			return values[ref];
		},

		value () {
			if (this.config.value) {
				return this.cfg2txt(this.config.value) || EMPTY_CHAR;
			}
			const value = get(this.result, 'value');

			return isNil(value) ? EMPTY_CHAR : value;
		},

		indicator () {
			if (!this.config.indicator) {
				return false;
			}

			return this.cfg2txt(this.config.indicator);
		},

		variables () {
			const variables = {};

			forEach(this.config.variables, (variable) => {
				variables[variable.name] = this.variable(variable.name);
			});
			return variables;
		},

		ruleset () {
			if (!this.config.ruleSets) {
				return false;
			}
			return find(this.config.ruleSets, (rule) => between({
				start: this.argument(rule.start) || '',
				end: this.argument(rule.end) || '',
				value: this.value
			}));
		},

		symbol () {
			return get(this.ruleset, 'symbol');
		},

		symbolStyle () {
			const color = `#${get(this.ruleset, 'color', '666')}`;
			return this.ruleset && { color };
		}
	}),

	beforeCreated () {
		if (!this.config.dataSources) {
			this.tile.disable();
		}
	},

	created () {
		const treatmentTypeRoleId = +get(this.config, 'dataSources[0].roleId', 0);

		if (treatmentTypeRoleId) {
			const found = find(
				this.$store.getters['treatment/assignedRoles'],
				{ treatmentTypeRoleId }
			);

			if (!found) {
				this.tile.disable();
			}
		}

		if (!this.config.showIfEmpty && isNil(get(this.result, 'value'))) {
			this.tile.disable();
		}
	},

	methods: {
		cfg2txt (cfg) {
			const { argument, text } = cfg;
			const value = argument && this.argument(argument);

			const valid = isNumber(value) ?
				isFinite(value) :
				!isNull(value);

			return valid ? sprintf(text, value) : EMPTY_CHAR;
		},

		valueByRef (ref) {
			return this.$store.getters['assessmentValues/valuesByRef'][ref];
		},

		variable (name) {
			const variable = find(this.config.variables, { name });

			if (!variable) {
				return undefined;
			}

			const [arg1, arg2] = map(variable.arguments, this.argument);

			return {
				'+': () =>
					cast({ arg: arg1, dependency: arg2 }) +
					cast({ arg: arg2, dependency: arg1 }),

				'-': () => cast({ arg: arg1 }) - cast({ arg: arg2, dependency: arg1 }),

				'/': () =>
					cast({ arg: arg1 }) /
					cast({ arg: arg2, dependency: arg1, fallback: 1 }),

				'*': () =>
					cast({ arg: arg1, dependency: arg2 }) *
					cast({ arg: arg2, dependency: arg1 }),

				'PATH': () => get(this.valueByRef(variable.arguments[0]), arg2, null),

				'DATETIME': () => arg1 ?
					datetime(this.argument(arg1))
						.setTimezone(this.$store.getters['respondent/timezone'])
						.toMoment()
						.format(t('date.formats.dateTime')) :
					EMPTY_CHAR
			}[variable.operation]();
		},

		argument (name) {
			const value = get(this.valueByRef(name), 'value', this.variable(name));
			return !isUndefined(value) ? value : name;
		}
	}
};
</script>
