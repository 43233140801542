import { Router } from 'backbone';
import $ from 'jquery';
import appLayout from '../../../views/app-layout-view';
import appContext from 'app-context';
import cardsProps from './cards-props';
import tileManager from 'tileManager';
import executeIfFunction from 'execute-if-function';
import cardLayout from '../views/card-layout';

const router = new Router();

/*
 * @function renderCurrent
 * @private
 * @param {Object} options
 */
export default function (options = {}) {
	const view = cardsProps.currentOf('views');
	const name = cardsProps.currentOf('names');

	if (!view || view._isDestroyed) {
		return;
	}

	tileManager.populateCard(view, options);

	setTimeout(() => {
		appLayout.getRegion('appBar').$el.removeClass('loading');
		view.loading(false);
	}, 200);

	router.navigate(cardsProps.currentOf('routes'));
	cardLayout.getRegion('cardContent').show(view, { preventDestroy: true });
	appContext
		.trigger('card.render', { name })
		.trigger('title.change', executeIfFunction(view.model.get('title')));

	$('.app__container')
		.removeClass((index, css) => (css.match(/(^|\s)card-\S+/g) || []).join(' '))
		.addClass(`card-${name}`);
}
