var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "more-results" }, [
    _c(
      "button",
      {
        staticClass: "flat-button__button more-results__button",
        on: {
          click: function($event) {
            return _vm.$emit("more")
          }
        }
      },
      [_vm._v("\n\t\t" + _vm._s(_vm.$t("table.moreResults")) + "\n\t")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }