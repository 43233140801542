var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.detached
    ? _c(
        "li",
        {
          directives: [
            {
              name: "on-click-outside",
              rawName: "v-on-click-outside",
              value: _vm.hide,
              expression: "hide"
            }
          ],
          staticClass: "appbar-system-buttons__item menu-button"
        },
        [
          _c("sidebar-trigger", {
            attrs: { expanded: _vm.show },
            on: { click: _vm.toggle }
          }),
          _vm._v(" "),
          _c("sidebar-container", {
            attrs: {
              show: _vm.show,
              items: _vm.items,
              "on-click": _vm.hide,
              "can-access": _vm.canAccess,
              type: _vm.type
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }