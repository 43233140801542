import { forEach } from 'lodash';
import Cursor from 'core/engine/helpers/cursor';

/**
 * Cards Properties data container with additional helpers
 * @author Waldemar
 * @namespace cardsProps
 *
 * @prop {Array} views Array of registered views
 * @prop {Array} routes Array of registered routes
 * @prop {Array} names Array of registered card names
 * @prop {Object} urls Hashmap of registered routes
 * @prop {Array} unclosableCardName Array of names of cards that can't be closed
 * @prop {Cursor} cursor Cursor instance for getting and changing properties
 */

const cardsProps = {
	views: [],
	routes: [],
	urls: {},
	names: [],
	unclosableCardNames: [
		'home', 'home-respondent', 'home-clinician', 'assessment-portal-assessment'
	],

	/*
	 * Get current property value.
	 *
	 * @memberOf cardsProps
	 * @param  {string} propName - Property name.
	 * @return {string|cardView}
	 */
	currentOf (propName) {
		return this[propName][this.cursor.val];
	},
	/*
	 * Add a value to a list.
	 *
	 * @memberOf cardsProps
	 * @param {string} propName - Property name.
	 * @param {string|cardView} value - Value to be added.
	 * @return {cardsProps}
	 */
	addTo (propName, value) {
		this[propName].push(value);
		return this;
	},
	/*
	 * Add a hashmap of values into a lists
	 * @memberOf cardsProps
	 * @param {Object} hashMap
	 * @return {cardsProps}
	 */
	add (hashMap) {
		forEach(hashMap, (val, key) => {
			this.addTo(key, val);
		});
		return this;
	},

	update ({ props, index }) {
		forEach(props, (val, key) => {
			this[key][index] = val;
		});
	},

	/*
	 * Remove all properties on the given index
	 * @memberOf cardsProps
	 * @param  {number} index
	 * @return {cardsProps}
	 */
	removeAllAtIndex (index) {
		forEach(['views', 'routes', 'names'], (propName) => {
			cardsProps[propName].splice(index, 1);
		});
		return this;
	},

	/*
	 * Move all properties from the given index to the highest index possible.
	 *
	 * @param  {number} index
	 * @return {cardsProps}
	 */
	moveToTopFromIndex (index) {
		if (this.names.length > 1) {
			forEach(['views', 'routes', 'names'], (propName) => {
				const item = cardsProps[propName][index];
				cardsProps[propName].splice(index, 1);
				cardsProps[propName].push(item);
			});
			this.cursor.val = this.names.length - 1;

		} else {
			this.cursor.val = index;
		}
	}
};

cardsProps.cursor = new Cursor({
	container: cardsProps.views
});

export default cardsProps;

