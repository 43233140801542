import repository from 'repository';

export default {
	namespaced: true,

	state: {
		data: [],
		collection: {},
		selectedLanguageId: 0
	},

	mutations: {
		set: (state, { languages, collection }) => {
			state.data = languages;
			state.collection = collection;
		},

		setSelected: (state, selectedLanguageId) => {
			state.selectedLanguageId = selectedLanguageId;
		}
	},

	getters: {
		available: (state) => state.data
			.filter((lang) => +lang.languageEnabled ||
				+lang.languageId === +state.selectedLanguageId)
			.map((lang) => ({
				[`${lang.languageId}`]: lang.languageLabel
			}))
	},

	actions: {
		init: ({ commit }, { includeDisabled } = { includeDisabled: false } ) => {
			const collection = includeDisabled ?
				repository.getLanguages() :
				repository.getEnabledLanguages();

			return collection.then(() => {
				commit('set', { languages: collection.toJSON(), collection });
			});
		},

		setSelected: ({ commit }, selectedLanguageId) => {
			commit('setSelected', selectedLanguageId);
		}
	}
};
