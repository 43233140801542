var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.component(_vm.item.type),
    _vm._b(
      {
        tag: "component",
        attrs: {
          label: _vm.item.label || "",
          translate: false,
          multiline: _vm.isMultiline(_vm.item),
          numeric: _vm.isNumeric(_vm.item),
          options: _vm.getOptions(_vm.item),
          mandatory: _vm.isRequired(_vm.item),
          required: _vm.isRequired(_vm.item),
          readonly: _vm.isReadonly(_vm.item),
          tel: _vm.config.type === "tel",
          email: _vm.config.type === "email",
          pattern: _vm.pattern,
          placeholder: _vm.config.placeholder
        },
        on: {
          input: function($event) {
            _vm.$emit("input", _vm.format(_vm.attrVal, _vm.item.type))
          }
        },
        model: {
          value: _vm.val,
          callback: function($$v) {
            _vm.val = $$v
          },
          expression: "val"
        }
      },
      "component",
      _vm._d({}, [
        _vm.maybeDateInputType(_vm.item.type),
        "date",
        _vm.maybeDateFormat(_vm.item.type),
        _vm.t("date.formats.dateTimeLuxon")
      ])
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }