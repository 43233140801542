import { forEach, isArray, isObject } from 'lodash';
import { warning } from 'service/log/log';
import Expression from './expression';
import { CONTINUE_IF, SHOW, HIDE, TEXT_EXPRESSION } from '../../core/constants';

export default function (item = {}) {
	const textExpressions = { count: 0	};
	const map = {};
	this.map = map;

	if (isObject(item.show)) {
		map.show = new Expression(item.show);
		map.show.expressionClass = SHOW;
	}

	if (isObject(item.hide)) {
		map.hide = new Expression(item.hide);
		map.hide.expressionClass = HIDE;
	}

	this.setContinueIf = function (expr) {
		map.continueIf = expr;
		map.continueIf.expressionClass = CONTINUE_IF;
	};

	if (isObject(item.continueIf)) {
		this.setContinueIf(new Expression(item.continueIf));
	}

	if (isObject(item.textExpression)) {
		if (!isArray(item.textExpression) && item.textExpression.elements) {
			item.textExpression = [item.textExpression];
		}
		forEach(item.textExpression, (expr) => {
			const id = ++textExpressions.count;

			map[`textExpression${id}`] = textExpressions[id] = new Expression(expr);
			textExpressions[id].expressionClass = TEXT_EXPRESSION;
			textExpressions[id].expressionId = id;
		});
	}

	this.getTextExpressionByNumber = function (n) {
		if (isObject(textExpressions[n])) {
			return textExpressions[n];

		}
		warning('Invalid textExpression reference');

	};

	this.getMap = () => map;
}

