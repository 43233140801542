import { assign, get } from 'lodash';
import repository from 'repository';
import initWith from '../__helpers/init-with';

export default {
	namespaced: true,

	state: {
		data: null,
		model: null,
		created: {}
	},

	getters: {
		assessmentInstanceId: (state, getters, rootState) =>
			get(rootState.cardData, 'urlParams.assessmentInstanceId'),
		assessmentPortalCode: (state) => get(state.data, 'assessmentPortalCode.code'),
		completed: (state) =>
			get(state.data, 'assessmentInstanceStatus.assessmentInstanceClosed') ||
			get(state.data, 'assessmentInstanceStatus.isSubmitted')
	},

	mutations: {
		set: (state, { assessmentInstance, model }) => {
			state.data = assessmentInstance;
			state.model = model;
		},

		reset: (state) => {
			state.data = null;
			state.model = null;
		},

		update: (state, payload) => {
			state.data = assign({}, state.data, payload);
			state.model.set(payload);
		},

		setCreated: (state, assessmentInstance) => {
			state.created = assessmentInstance;
		}
	},

	actions: {
		init: (context, { assessmentInstanceId } = { assessmentInstanceId: 0 }) => {
			const { commit, getters } = context;
			commit('reset');

			const model = repository.assessmentInstance(
				assessmentInstanceId ||
				getters.assessmentInstanceId
			);
			return initWith(context, {
				actionName: 'assessmentInstance/init',
				resource: model,
				action: () => {
					const assessmentInstance = model.toJSON();
					commit('set', { model, assessmentInstance });

					return {
						treatmentId: assessmentInstance.treatment,
						respondentId: assessmentInstance.respondent.respondentId
					};
				}
			});
		},

		update: ({ commit }, payload) => {
			commit('update', payload);
		},

		destroy: ({ commit, state }) => state.model.destroy().then(() => {
			commit('reset');
		}),

		generateAssessmentPortalCode: ({ commit, state }) =>
			repository.createAssessmentPortalCode(state.data.assessmentInstanceId)
				.then((response) => {
					commit('update', { assessmentPortalCode: response });
				}),

		removeAssessmentPortalCode: ({ commit, state }) =>
			repository.deleteAssessmentPortalCode(state.data.assessmentInstanceId).then(() => {
				commit('update', { assessmentPortalCode: null });
			}),

		sendAssessmentPortalCode: ({ state }, data) => repository.sendAssessmentPortalCode({
			assessmentInstanceId: state.data.assessmentInstanceId,
			data
		}),

		createByCode: (
			{ commit, rootGetters },
			{ assessmentCode, roleId, deadlineOffset }
		) => {
			const treatmentId = rootGetters['treatment/treatmentId'];
			const request = repository.createAssessmentInstance({
				assessmentCode, roleId, deadlineOffset, treatmentId
			});

			return request.then((response) => {
				commit('setCreated', response);
				return assign(request, response);
			});
		}
	}
};
