import MultipleEntities from '../shared/multiple-entities';
import MessageDraft from './message-draft';
import urls from './urls';

/**
 * RespondentMessageDrafts Entity
 * @class RespondentMessageDrafts
 * @extends MultipleEntities
 */
const RespondentMessageDrafts = MultipleEntities.extend({
	model: MessageDraft,
	urls,
	eventNamespace: 'respondent-message-draft',

	/**
	 * Get a collection of all MessageDrafts for a given treatment
	 * @method getForTreatment
	 * @param {Object} {treatment}
	 * @return {Object} new {{#crossLink "MessageDrafts"}}{{/crossLink}} Collection
	 * @example
	 *        repository.MessageDrafts.getForTreatment({ treatmentId: 7 });
	 */
	getForTreatment ({ treatment }) {
		return this.retrieve('messageDrafts', { treatment });
	}
});

export default new RespondentMessageDrafts();
