import TableItemCardLink from './components/table-item-card-link';
import TableItemCheckbox from './components/table-item-checkbox';
import TableItemClinician from './components/table-item-clinician';
import TableItemDateTime from './components/table-item-date-time';
import TableItemIcon from './components/table-item-icon';
import TableItemMarkdown from './components/table-item-markdown';
import TableItemRespondent from './components/table-item-respondent';
import TableItemRespondentLink from './components/table-item-respondent-link';
import TableItemSpan from './components/table-item-span';
import TableItemSprintf from './components/table-item-sprintf';

const components = {
	cardLink: TableItemCardLink,
	checkbox: TableItemCheckbox,
	clinician: TableItemClinician,
	date: TableItemDateTime,
	icon: TableItemIcon,
	markdown: TableItemMarkdown,
	respondent: TableItemRespondent,
	respondentLink: TableItemRespondentLink,
	span: TableItemSpan,
	sprintf: TableItemSprintf
};

export default (column) => components[column.type] || TableItemSpan;
