import prefixify from '../__helpers/prefixify';
import { exact } from '../__helpers/getters';
import { set, updateHashMap } from '../__helpers/mutations';

const prefix = prefixify('search');

const CURRENT_ID = 'currentId';
const DATA = 'data';
const INIT = 'init';
const PARAMS = 'params';
const SET_CURRENT_ID = 'setCurrentId';
const SET_DATA = 'setData';
const SET_PARAM = 'setParam';

export const SEARCH = {
	CURRENT_ID: prefix(CURRENT_ID),
	DATA: prefix(DATA),
	INIT: prefix(INIT),
	PARAMS: prefix(PARAMS),
	SET_CURRENT_ID: prefix(SET_CURRENT_ID),
	SET_DATA: prefix(SET_DATA),
	SET_PARAM: prefix(SET_PARAM)
};

export default {
	namespaced: true,

	state: {
		currentId: '',
		data: {}
	},

	getters: {
		...exact(CURRENT_ID),
		...exact(DATA)
	},

	mutations: {
		[SET_DATA]: updateHashMap(),
		[SET_CURRENT_ID]: set(CURRENT_ID)
	},

	actions: {
		[SET_DATA]: ({ commit }, { data, id }) => {
			commit(SET_CURRENT_ID, id);
			commit(SET_DATA, { id, payload: { ...data } });
		},

		[SET_PARAM]: ({ dispatch, getters }, { param, id }) => {
			const params = getters[DATA][id];
			dispatch(SET_DATA, { data: { ...params, ...param }, id });
		},

		[INIT]: ({ commit, dispatch, getters }, { data, id }) => {
			commit(SET_CURRENT_ID, id);

			if (!getters[DATA][id]) {
				dispatch(SET_DATA, { data, id });

			} else {
				dispatch(SET_PARAM, { param: { start: 0 }, id });
			}
		}
	}
};
