import $ from 'jquery';
import t from 'translate';
import { replace } from 'lodash';

const footnote = function (param) {
	const html = replace(param, '&amp;copy;', '&copy;');

	const $html = $(html);
	const $footer = $html.find('.footer');

	$footer.find('hr').remove();
	$footer.find('br').remove();

	$footer.find('.footnote').attr('role', 'contentinfo');
	$footer.find('.copyright').attr('data-label', t('copyright'));

	return $html;
};

export default footnote;
