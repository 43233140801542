<template>
	<!-- eslint-disable vue/no-v-html -->
	<ol :class="className('list', 'level1')">
		<li
			v-for="item in items"
			:key="item.gotoCode"
			:class="className('list-item', 'level1')"
		>
			<a
				:class="className('list-link', 'level1')"
				@click.prevent="onClick(item)"
				v-html="item.title"
			/>
			<ol
				v-if="item.items.length"
				:class="className('list', 'level2')"
			>
				<li
					v-for="subItem in item.items"
					:key="subItem.gotoCode"
					:class="className('list-item', 'level2')"
				>
					<a
						:class="className('list-link', 'level2')"
						@click.prevent="onClick(subItem)"
						v-html="subItem.title"
					/>
				</li>
			</ol>
		</li>
	</ol>
</template>

<script>
import Vue from 'vue';
import { assign, compact, constant, flow, map, noop, reject } from 'lodash';
import { cwtags } from '../../helpers/string';

const filterItems = (items) => reject(items, { canGoto: false });

export default Vue.component('TableOfContents', {
	props: {
		entries: {
			type: Object,
			default: constant({})
		},
		jumpTo: {
			type: Function,
			default: noop
		}
	},

	computed: {
		items: flow([
			({ entries }) => filterItems(entries),
			(entries) => map(entries, (entry) => assign(entry, {
				title: cwtags(entry.title),
				items: flow([
					filterItems,
					(items) => map(items, (item) => assign(item, {
						title: cwtags(item.title)
					}))
				])(entry.items)
			}))
		])
	},

	methods: {
		className: (element = '', modifier = '') => compact([
			`assessment-runtime-toc__${element}`,
			modifier && `assessment-runtime-toc__${element}--${modifier}`
		]),
		onClick (item) {
			return this.jumpTo(item.gotoCode);
		}
	}
});
</script>
