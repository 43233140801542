import MultipleEntities from '../shared/multiple-entities';
import TreatmentType from './entity';
import urls from './urls';

const TreatmentTypes = MultipleEntities.extend({
	model: TreatmentType,
	urls,
	eventNamespace: 'treatment-type',

	getAll () {
		return this.retrieve('listAll');
	},

	getForCurrentClinician () {
		return this.retrieve('listForCurrentClinician');
	}
});

export default new TreatmentTypes();
