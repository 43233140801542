<template>
	<div class="dashboard-progress">
		<h2 class="dashboard-progress__title">
			{{title}}
		</h2>
		<circle-progress
			:percent="percent"
			:animation-delay="config.animationDelay"
			:size="config.size"
			:background-stroke="config.backgroundStroke"
			:stroke="config.stroke"
			:stroke-width="config.strokeWidth"
		/>
	</div>

</template>

<script>
import cubeId from '../../shared/cube-id';
import actions from '../../shared/actions';
import { CUBE } from 'store/cube/cube';

export default {
	acl: [],
	actions: (tile) => actions({
		title: tile.config().title,
		query: tile.config().cubeQuery,
		ignoreAccessRules: tile.config().ignoreAccessRules
	}),
	data: ({ tile }) => ({
		config: tile.config()
	}),

	computed: {
		result: ({ config, $store }) => $store.getters[CUBE.RESULTS][cubeId(config)].result[0],
		title: ({ config, t }) => config.translate ? t(config.title) : config.title,
		percent: ({ result, config }) => {
			if (!result[config.count]) {
				return 0;
			}
			return Math.round(result[config.count] / result[config.total] * 100);
		}
	}
};
</script>
