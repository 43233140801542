import TileView from 'tile-view';
import Vue from 'vue';
import t from 'translate';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import can from 'acl-can';

export default TileView.extend({
	title: t('Goal categories'),
	acl: [{
		checkpoint: 'system.settings.goals',
		op: 'read'
	}],

	cardData: () => ['goalCategories'],

	loaded: ({ tile }) => {
		tile.vm = new Vue({
			el: tile.el,
			data: {
				categories: tile.goalCategories,
				maxSize: 64
			},

			computed: {
				allowEdit () {
					return can.edit('system.settings.goals');
				},

				allowDelete () {
					return can.delete('system.settings.goals');
				}
			},

			methods: {
				deleteComponent (category) {
					this.categories.splice(this.categories.indexOf(category), 1);
				},

				onSave: ({ model, data }) => {
					model.set('name', data.name);
					return model.save().then(() => {
						cwalert.success(t('Success'));
					}).fail(() => {
						cwalert.error(t('Error'));
					});
				},

				onDelete: ({ model, data }) =>
					confirmation({
						title: t('Remove item'),
						icon: 'category',
						warning: true,
						message: t('Are you sure you want to remove category {category}?', {category: data.name})
					}).then(() => model.destroy().then(() => {
							cwalert.success(t('Category has been deleted'));
						}, () => {
							cwalert.error(t('Error'));
						})
					)
			},

			template:
				`<div>
					<p v-if="!categories.length">${t('Goal categories list is empty')}</p>
					<ul>
						<li
							v-for="category in categories.models"
							:key="category.getId()"
							v-on:deleteComponent="deleteComponent(category)"
						>
							<inline-edit
								:model="category"
								:onSave="onSave"
								:onDelete="onDelete"
								:maxSize="maxSize"
								:allowEdit="allowEdit"
								:allowDelete="allowDelete"
							/>
						</li>
					</ul>
				</div>`
		});

		tile.$el.html(tile.vm);
	}
});
