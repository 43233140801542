import $ from 'jquery';
import {
	assign, defer, filter, forEach, get, isFunction, isString, noop, split, unescape
} from 'lodash';
import { Collection } from 'backbone';

import spin from 'components/loaders/spin';
import { compile } from 'handlebars';
import tpl from './search-template';
import t from 'translate';

import FormComponentView from '../form-component-view';

const getClass = (suffix) => `.form-view__search-${suffix}`;
const ui = {
	caption: getClass('caption'),
	changeItem: getClass('change-icon'),
	clearItem: getClass('clear-icon'),
	loader: getClass('loader'),
	removeItem: getClass('remove-icon'),
	searchResults: getClass('results'),
	searchResultsContainer: getClass('results-container'),
	selectedItem: getClass('selected-item')
};

const search = FormComponentView.extend({
	template: compile(tpl),

	events: {
		'keyup @ui.input': 'onKeyup',
		'keydown @ui.input': 'onKeydown',
		'focus @ui.input': 'onFocus',
		'blur @ui.input': 'onBlur',
		'click .form-view__search-change-icon': 'changeItem',
		'click .form-view__search-selected-item': 'changeItem',
		'click .form-view__search-remove-icon': 'removeItem',
		'click .form-view__search-clear-icon': 'clearItem'
	},
	results: new Collection(),

	initialize () {
		this.model.set({
			txt: {
				remove: t('Remove'),
				search: t('Search')
			}
		});

		assign(this, {
			provideResults: this.model.get('provideResults'),
			lastQuery: false,
			itemLabel: this.model.get('itemLabel'),
			limit: this.model.get('limit')
		});

		this.ui = assign({}, this.ui, ui);

		// if field is readonly - disable few methods
		if (this.model.get('readonly')) {
			forEach(['select', 'clear', 'change', 'remove'], (fnName) => {
				this[`${fnName}Item`] = noop;
			});
		}
	},

	onAfterRender () {
		!this.model.get('value') && this.hideEl('removeItem');
		this.model.get('readonly') && this.hideEls(['remove', 'changeItem', 'removeItem']);
		defer(() => {
			this.ui.loader.html(spin({ variant: 'small' }));
		});
		this.$('.form-view__search-change-icon').on('click', () => {
			this.ui.input.val(' ').trigger('keyup');
		});
	},

	onKeyup () {
		if (this.ui.input.val() !== '') {
			if (this.lastQuery !== this.ui.input.val()) {
				this.showEl('loader');
				this.results = this.provideResults(this.ui.input.val());
				this.results.then((resultsArray, status, xhrObj) => {
					this.total = get(this.results, 'total');

					if (xhrObj) {
						this.total = +xhrObj.getResponseHeader('X-Total-Count');
					}
					this.renderItems(this.results);
				});

				this.lastQuery = this.ui.input.val();
			}
			this.showEl('clearItem');

		} else {
			this.lastQuery = false;
			this.clear();
		}
	},

	onKeydown (event) {
		// if there is no results
		if (!this.results.size()) {
			return;
		}

		const arrowAction = (direction) => () => {
			// eslint-disable-next-line lodash/prefer-lodash-method
			const $selected = this.ui.searchResults.find('li.selected');
			const jqMethod = direction === 'up' ? 'prev' : 'next';
			const selector = direction === 'up' ? 'last' : 'first';
			const $el = $selected.length && $selected.removeClass('selected')[jqMethod]();
			$selected.attr('aria-live', 'off');

			if ($el.length) {
				$el.addClass('selected')[0].scrollIntoView(false);
				$el[0].setAttribute('aria-live', 'polite');

			} else {
				// eslint-disable-next-line lodash/prefer-lodash-method
				this.ui.searchResults
					.find(`li:${selector}`)
					.addClass('selected')[0]
					.scrollIntoView(false);

				// eslint-disable-next-line lodash/prefer-lodash-method
				this.ui.searchResults.find(`li:${selector}`)[0].setAttribute('aria-live', 'polite');
			}
		};
		({
			13: () => { // enter
				// eslint-disable-next-line lodash/prefer-lodash-method
				this.ui.searchResults.find('li.selected').trigger('click');
				event.preventDefault();
			},
			27: this.clearItem.bind(this, event), // escape
			38: arrowAction('up'),
			40: arrowAction('down')
		}[event.keyCode] || noop)();
	},

	onFocus () {
		this.hideEl('changeItem');
	},

	onBlur () {
		const isNotEmpty = !!this.ui.input.val() &&
			isString(this.model.get('itemLabel')) &&
			this.model.get('itemLabel').length;

		if (!isNotEmpty) {
			if (!this.ui.input.val()) {
				this.hideEl(['clearItem']);
				this.showEls(['changeItem', 'removeItem']);
			}
			return;
		}
		this.hideEls(['input', 'clearItem']);
		this.showEls(['selectedItem', 'changeItem', 'removeItem']);
	},

	renderItems () {
		this.ui.searchResults.empty();
		this.$limitInfo && this.$limitInfo.detach();
		this.hideEl('loader');

		const renderItems = () => {
			if (this.model.get('filterItems')) {
				this.results.reset(filter(this.results.toJSON(), this.model.get('filterItems')));
				this.total = this.results.size();
			}

			this.results.each(this.renderItem, this);

			this.total > this.limit && this.renderLimitExceeded();
		};

		if (this.results.size()) {
			renderItems();

		} else {
			// render empty message
			this.renderNoItem();
		}
		this.showEl('searchResultsContainer');
	},

	renderItem (model) {
		const fn = this.model.get('sanitizeLabel') === false ? 'html' : 'text';
		$('<li />')[fn](this.itemLabel(model))
			.addClass(`${this.model.get('formClass')}__search-item`)
			.on('click', () => {
				this.selectItem(model.id);
			})
			.appendTo(this.ui.searchResults);
	},

	renderLimitExceeded () {
		this.$limitInfo = $('<p />')
			.text(t('{count} results omitted, try narrowing down search criteria', {
				count: this.total - this.limit
			}))
			.addClass(`${this.model.get('formClass')}__search-item`)
			.addClass(`${this.model.get('formClass')}__search-item--limit-exceeded`)
			.attr('tabindex', '-1')
			.prependTo(this.ui.searchResultsContainer);
	},

	renderNoItem () {
		$('<li />')
			.text(t('No matching records found…'))
			.addClass(`no-results ${this.model.get('formClass')}__search-item`)
			.appendTo(this.ui.searchResults);
	},

	clear () {
		this.model.unset('value');
		this.ui.searchResults.empty();
		this.results.reset();
		this.hideEls(['searchResultsContainer', 'clearItem', 'loader']);
	},

	selectItem (id) {
		if (!id) {
			this.clear();
			return;
		}

		const model = this.results.get(id);
		this.showItem(model);
		const onSelectItem = this.model.get('onSelectItem');

		if (isFunction(onSelectItem)) {
			onSelectItem(model, this);
		}
	},

	showItem (model) {
		// clear and hide results
		this.clear();
		const id = (model) ? (model.get('id') || model.id) : null;
		const label = (model) ? this.itemLabel(model) : t('(None)');

		// display selected item
		this.model.set({
			value: id,
			itemLabel: label
		});

		if (this.model.get('sanitizeLabel') === false) {
			this.ui.caption.html(label);

		} else {
			this.ui.caption.text(label);
		}

		this.hideEl('input');
		this.showEls(['selectedItem', 'changeItem', 'removeItem']);
		this.inputChanged();
	},

	clearItem (e) {
		e.preventDefault();
		this.model.get('onClearItem') && this.model.get('onClearItem').apply(this);
		this.clear();
		this.ui.input.val('');
		this.lastQuery = false;
		this.model.set('itemLabel', false);
		this.showEl('input');
		this.hideEl('selectedItem');
	},

	changeItem () {
		if (!this.model.get('value')) {
			this.model.set('itemLabel', null);
		}
		const itemLabel = isString(this.model.get('itemLabel')) ? this.model.get('itemLabel') : '';
		this.ui.input.val(
			this.model.get('sanitizeLabel') === false ? unescape(itemLabel) : itemLabel
		);
		this.showEls(['input', 'clearItem']);
		this.hideEls(['selectedItem', 'changeItem']);
	},

	removeItem () {
		this.clear();
		this.hideEl('input');
		this.showEls(['selectedItem', 'changeItem']);
		this.model.set({
			itemLabel: false,
			value: false
		});
		this.ui.caption.text(this.model.get('removeItemCaption') || t('(None)'));
	},

	toggleEl (uiItem, bool) {
		const item = this.ui[uiItem];

		if (!item || !item.length) {
			return;
		}
		let hideClass = /\S*--hide/.exec(item.attr('class'));
		hideClass = hideClass ? hideClass[0] : `${split(item.attr('class'), ' ')[0]}--hide`;

		item.toggleClass(hideClass, bool);
	},

	hideEl (item) {
		this.toggleEl(item, true);
	},

	hideEls (items) {
		forEach(items, this.hideEl.bind(this));
	},

	showEl (item) {
		this.toggleEl(item, false);
	},

	showEls (items) {
		forEach(items, this.showEl.bind(this));
	}
});

export { search };
