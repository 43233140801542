import { clone, get, set, transform } from 'lodash';

/**
 * @param {object} params         - Parameters.
 * @param {object} params.entity  - Source entity.
 * @param {object} params.mapping - Transformation mapping.
 * @returns {object}              - Mapped entity that contains original keys + mapped ones.
 * @example
 *        mapEntity({
 * 			entity: { iDontWantThisName: 'val1', thatsOk: 'val2', butThisNot: 'val3' },
 * 			mapping: { iDontWantThisName: 'niceKey1', butThisNot: 'niceKey2' }
 * 		});
 *        -> {
 * 			iDontWantThisName: 'val1',
 * 			thatsOk: 'val2',
 * 			butThisNot: 'val3',
 * 			niceKey1: 'val1',
 * 			niceKey2: 'val3'
 * 		}
 */
export const mapEntity = ({ entity = {}, mapping = {} }) => transform(
	mapping,
	(result, to, from) => {
		set(result, to, get(result, from));
	},
	clone(entity)
);
