var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.viable
    ? _c("menu-toggle-button", {
        staticClass: "toggle-treatment",
        attrs: {
          "is-selected": _vm.isSelected,
          items: _vm.treatments,
          "is-loading": _vm.isLoading,
          "allow-toggle": _vm.treatments.length > 1,
          name: "switch-treatment",
          title: _vm.$t("treatmentSelection.treatmentToggleTitle")
        },
        on: {
          toggleClick: _vm.toggleMenuOrClearTreatment,
          firstItemClick: _vm.clearTreatment,
          itemClick: _vm.selectTreatment
        },
        scopedSlots: _vm._u(
          [
            {
              key: "buttonContent",
              fn: function() {
                return [_c("icon", { attrs: { name: "treatment-type" } })]
              },
              proxy: true
            },
            {
              key: "firstItemContent",
              fn: function() {
                return [
                  _c("icon", { attrs: { name: "treatment-back" } }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("treatmentSelection.backToTreatmentSelection")
                      )
                    )
                  ])
                ]
              },
              proxy: true
            },
            {
              key: "itemContent",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v("\n\t\t" + _vm._s(_vm.title(item)) + "\n\t")]
              }
            }
          ],
          null,
          false,
          1436012348
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }