import { assign, filter, find } from 'lodash';

// returns a predicate function that returns entities that are not present in a given array
const unique = (entities) => (entity) => !find(entities, { id: entity.id });

/**
 * Push new entities to array.
 *
 * @param {object} opts     - Options.
 * @param {Array} opts.from - Source array.
 * @param {Array} opts.to   - Target array.
 * @example
 * 		push({ from: reports, to: state.current });
 */
const push = ({ from, to }) => {
	to.push(...(filter(from, unique(to))));
};

/**
 * Remove item from array.
 *
 * @param {object} opts     - Options.
 * @param {*} opts.item     - Item to be removed.
 * @param {Array} opts.from - Target array.
 * @deprecated
 * @example
 * 		remove({ item: request, from: state.current });
 */
const remove = ({ item, from }) => {
	from.splice(from.indexOf(item), 1);
};

const toggleStatus = ({ circle, status }) => ({
	join: () => assign(circle, {
		memberStatus: {
			isMember: false,
			isNone: false,
			isCandidate: true
		}
	}),

	leave: () => assign(circle, {
		memberStatus: {
			isMember: false,
			isNone: true,
			isCandidate: false
		}
	})
})[status]();

export { push, remove, toggleStatus };
