import glob from './glob';

export default function (code = '') {
	const attrs = {
		results: {},
		isStop: false,
		isDisplayed: true,

		show: () => {
			attrs.isDisplayed = true;
		},
		hide: () => {
			attrs.isDisplayed = false;
		},
		setNoStop: () => {
			attrs.isStop = false;
		},
		setStop: () => {
			attrs.isStop = true;
		},
		isVisible: () => attrs.isDisplayed,
		setTextExpressionResult: (id, result) => {
			attrs.results[+id] = result;
		},
		textExpression: (id) => attrs.results[+id]
	};

	if (!glob.guiAttrs[code]) {
		glob.guiAttrs[code] = attrs;
	}

	return glob.guiAttrs[code];
}
