var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.groupedMedia, function(ref) {
      var mediaArr = ref.mediaArr
      var label = ref.label
      return _c(
        "section",
        { key: label, staticClass: "information-package-elements__container" },
        [
          label
            ? _c("h3", { staticClass: "information-package-elements__title" }, [
                _vm._v("\n\t\t\t" + _vm._s(label) + "\n\t\t")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "generic-list__list information-package-elements" },
            _vm._l(mediaArr, function(media) {
              return _c(
                "li",
                {
                  key: media.id,
                  staticClass: "generic-list__item assessment-list__item"
                },
                [
                  _c(
                    "div",
                    {
                      class: _vm.mediaClassName(media),
                      attrs: { tabindex: "0" },
                      on: {
                        click: function($event) {
                          return _vm.openMedia(media)
                        },
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.openMedia(media)
                        }
                      }
                    },
                    [
                      _c("icon", {
                        attrs: { name: media.fileType, title: media.typeLabel }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "\n\t\t\t\t\t\t\tgeneric-list__action-title-container\n\t\t\t\t\t\t\tinformation-package-element__action-title-container\n\t\t\t\t\t\t"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "\n\t\t\t\t\t\t\t\t\tinformation-package-element__type-label\n\t\t\t\t\t\t\t\t\tinformation-package-element__media-content\n\t\t\t\t\t\t\t\t"
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(media.typeLabel) +
                                  "\n\t\t\t\t\t\t"
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "\n\t\t\t\t\t\t\t\tgeneric-list__action-title\n\t\t\t\t\t\t\t\tinformation-package-element__action-title\n\t\t\t\t\t\t\t\tinformation-package-element__media-content\n\t\t\t\t\t\t\t"
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(media.elementVersion.alias) +
                                  "\n\t\t\t\t\t\t"
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "information-package-element__open-external",
                            attrs: {
                              name: "external-link",
                              title: _vm.$t("Open in new window")
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "information-package-element__state-label"
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.stateLabel(media)) +
                                  "\n\t\t\t\t\t\t"
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            }),
            0
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }