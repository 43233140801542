import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import Calculation from './calculation';

const AssessmentCalculations = MultipleEntities.extend({
	urls,
	model: Calculation,
	idName: 'assessmentCalculationId',
	eventNamespace: 'assessment-calculation',

	getByAssessment (assessmentId) {
		return this.retrieve('calculationsOfAssessment', assessmentId);
	}
});

export default new AssessmentCalculations();
