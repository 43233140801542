import { param } from 'jquery';

const prefix = 'backend-commons/assessmentinstance/rest/assessmentinstance';

export default {
	single: {
		url: prefix
	},
	assessmentInstances: {
		url: (data = {}) => `${prefix}/available/collection/respondent?${param(data)}`
	},

	assessmentInstance: (id) => `${prefix}/${id}`
};
