<template>
	<div :class="containerClasses" @keyup.esc="removeEditItem">
		<tree-search
			:change-position="changePosition"
			:copy="copy"
			:create-mirror="createMirror"
			:delete-selected="deleteSelected"
			:download-url="downloadUrl"
			:enabled-copy="enabledCopy"
			:enabled-mirror="enabledMirror"
			:enabled-paste="enabledPaste"
			:local-items="localItems"
			:matched="matched"
			:modified-not-saved="modifiedNotSaved"
			:on-remove-selected="onRemoveSelected"
			:on-search-key-up="onSearchKeyUp"
			:perform-copy="performCopy"
			:perform-paste="performPaste"
			:position="position"
			:save-allowed="saveAllowed"
			:selected="selected"
			:selected-mirror="selectedNestedMirror"
			:save-template="saveTemplate"
			:set-modified="setModified"
		/>
		<ol class="tree-view__list">
			<tree-item
				:blueprints="blueprints"
				:checked-items="checkedItems"
				:copy="copy"
				:copy-path="copyPath"
				:dispatch="dispatch"
				:getters="getters"
				:icons="icons"
				:incomplete-paths="incompletePaths"
				:indeterminate="indeterminate"
				:item="localItems"
				:mirror-details="mirrorDetails"
				:mirrors="mirrors"
				:pass-button-refs="passButtonRefs"
				:position="position"
				:root-mirrors="rootMirrors"
				:selected="selected"
				:set-copy-path="setCopyPath"
				:toggle="toggle"
				:toggle-edit="onItemClick"
				:warning-paths="invalidItems"
				:on-add="onItemAdd"
				name-path="fields.name"
				@passButtonRefs="passButtonRefs"
				@dispatch="dispatch"
			/>
		</ol>
	</div>
</template>

<script>
import './components/tree-add';
import './components/tree-item';
import './components/tree-list';
import './components/tree-search';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import storeModuleBlueprint from 'store/components/tree-view/module-blueprint';
import t from 'translate';
import { assign, constant, get, includes, noop, reduce } from 'lodash';

const getterNames = [
	'checkedItems',
	'copy',
	'copyPath',
	'editItem',
	'enabledMirror',
	'incompletePaths',
	'indeterminate',
	'localItems',
	'matched',
	'mirrorDetails',
	'mirrors',
	'modifiedNotSaved',
	'position',
	'rootMirrors',
	'selected',
	'invalidItems',
	'saveAllowed'
];

const getters = reduce(getterNames, (result, getter) => assign(result, {
	[getter]: ({ name, $store }) => $store.getters[`components/tree-view/${name}/${getter}`]
}), {});

export default {
	name: 'TreeView',
	props: {
		blueprints: {
			type: Function,
			required: true
		},
		downloadUrl: {
			type: String,
			default: ''
		},
		file: {
			type: Object,
			default: constant({})
		},
		icons: {
			type: Object,
			default: constant({})
		},
		name: {
			type: String,
			default: 'default-name'
		},
		items: {
			type: Object,
			required: true
		},
		onAdd: {
			type: Function,
			default: noop
		},
		saveTemplate: {
			type: Function,
			default: noop
		},
		treeStoreConfig: {
			type: Object,
			default: constant({})
		},
		types: {
			type: Object,
			default: constant({})
		},
		updateHelpers: {
			type: Object,
			default: constant({})
		}
	},

	data () {
		return {
			excludedFromDownload: ['checked', 'expanded', 'highlighted', 'indeterminate', 'path'],
			storeName: `components/tree-view/${this.name}`
		};
	},

	computed: {
		...getters,

		containerClasses: ({ editItem }) => editItem ?
			['tree-view tree-view__edit-active'] :
			['tree-view'],

		enabledCopy: ({ selected, copy, selectedMirror }) => !selected.length ||
			!!copy.length ||
			selectedMirror,

		enabledPaste: ({ copy, copyPath }) => !copy || !copyPath,

		selectedMirror: ({ selected, mirrors }) => reduce(
			selected,
			(result, path) => {
				const mirror = includes(mirrors, path);
				return mirror || result;
			},
			false
		),

		selectedNestedMirror: ({ selected, localItems, checkMirrorsMirror }) => reduce(
			selected,
			(result, path) => {
				const element = get(localItems, path);
				return element.mirror ? checkMirrorsMirror(element) : result;
			},
			false
		),

		checkedCount: ({ getters }) => getters('checkedItems').length,

		checkedTopItemsCount: ({ getters }) => getters('checkedTopItems').length,

		checkedTopParentsCount: ({ getters }) => getters('checkedTopParents').length
	},

	created () {
		this.storeModulePath = ['components', 'tree-view', this.name];

		// inner store may be already registered so only if there are no localItems
		if (!this.$store.getters[`${this.storeModulePath.join('/')}/localItems`]) {
			this.$store.registerModule(this.storeModulePath, storeModuleBlueprint());
		}
		const data = {
			items: assign({}, this.items),
			types: this.types,
			update: this.updateHelpers,
			config: this.treeStoreConfig
		};
		// http://jsben.ch/bWfk9
		this.$store.dispatch(`${this.storeModulePath.join('/')}/init`, data);
		this.$store.dispatch(`${this.storeModulePath.join('/')}/removeEditItem`);
		this.$store.dispatch(`${this.storeModulePath.join('/')}/setModified`, false);
	},

	destroyed () {
		this.$store.unregisterModule(this.storeModulePath);
	},

	methods: {
		getters (getterName) {
			return this.$store.getters[`${this.storeName}/${getterName}`];
		},

		dispatch (action, args) {
			return this.$store.dispatch(`${this.storeName}/${action}`, args);
		},

		changePosition (position) {
			this.dispatch('setPosition', position);
		},

		// if a mirror-origin el is a mirror itself delete needs to be disabled
		checkMirrorsMirror (element) {
			if (!element.mirrorSuffix) {
				return true;
			}
			const mirrorElement = get(this.localItems, element.mirror);
			return !!mirrorElement.mirror;
		},

		createMirror () {
			if (this.selected.length === 1) {
				const selected = this.selected[0];
				this.dispatch('setMirror', { path: selected });
			}
		},

		deleteSelected () {
			if (this.selected.length) {
				confirmation({
					icon: 'delete',
					warning: true,
					title: t('Remove selected items'),
					message: t('Are you sure you want to delete selected items?')
				}).done(() => {
					this.dispatch('delete').then(() => {
						cwalert.success(t('Items deleted successfully'));
					});
				});
			}
		},

		handleCheck (item) {
			if (!this.copy.length && !item.root) {
				const action = includes(this.checkedItems, item.path) ? 'uncheckSingle' : 'check';
				this.dispatch(action, item);
			}
		},

		onRemoveSelected () {
			this.dispatch('restoreItems');
		},

		onSearchKeyUp (phrase) {
			this.dispatch('search', phrase);
		},

		performCopy (toggle = true) {
			if (toggle && !this.copy.length) {
				this.dispatch('copyNode', {
					paths: this.selected,
					action: this.setCopySuccess
				});

			} else {
				this.dispatch('cancelCopy');
			}
		},

		performPaste () {
			if (this.copy && this.copyPath) {
				this.dispatch('paste');
				this.dispatch('uncheckAll');
			}
		},

		removeEditItem () {
			this.dispatch('removeEditItem');
		},

		setCopyPath (path) {
			this.dispatch('setCopyPath', path);
		},

		setCopySuccess (copy) {
			if (copy) {
				cwalert.success(t('Copied successfully'));
			}
		},

		setModified (bool) {
			this.dispatch('setModified', bool);
		},

		toggle (path) {
			this.dispatch('toggle', path);
		},

		edit (item) {
			this.dispatch('check', item);
			this.dispatch('toggleEdit', { item });
			this.dispatch('setActivePath', item.path);
		},

		maybeSelectParent () {
			const singleParent = this.getters('singleParentOfChecked');

			if (
				singleParent &&
				singleParent.children.length === this.checkedTopItemsCount &&
				!singleParent.root
			) {
				this.onCheckboxClick(singleParent);
			}
		},

		onItemClick (item, { source }) {
			// item name or create item or the only checkbox clicked
			if (source === 'name' || !this.checkedCount) {
				this.dispatch('uncheckAll');
				this.edit(item);

				if (source !== 'name') {
					this.maybeSelectParent();
				}

			} else {
				this.onCheckboxClick(item);
			}
		},

		onCheckboxClick (item) {
			this.handleCheck(item);
			this.maybeSelectParent();

			if (this.checkedCount === 1) { // checkbox unchecked leaving only other one checked
				this.edit(this.getters('checked')[0].element);

			} else if (this.checkedTopParentsCount === 1 && this.checkedTopItemsCount === 1) {
				this.edit(this.getters('checkedTopParents')[0].element);

			} else {
				this.removeEditItem();
			}
		},

		onItemAdd ({ path, item }) {
			this.onAdd({ path, item });

			if (!item.preventAdd) {
				this.onAfterAdd(assign(item, { path }));
			}
		},

		onAfterAdd (item) {
			const allItems = this.getters('localItems');
			const lastItemIdx = (items) => items.children.length - 1;
			let path = '';

			if (item.path === 'root') {
				path = `children.${lastItemIdx(allItems)}`;

			} else {
				const itemIndex = lastItemIdx(get(allItems, item.path));
				path = `${item.path}.children.${itemIndex}`;
			}

			this.onItemClick(get(allItems, path), { source: 'name' });
		},

		passButtonRefs (ref) {
			this.$emit('passButtonRefs', ref);
		}
	}
};
</script>
