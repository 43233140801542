var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mini-value-tile", class: _vm.className },
    [
      _c(
        "content-container",
        { attrs: { subtitle: _vm.subtitle, link: _vm.link } },
        [
          _c("div", { staticClass: "mini-value-tile__text-container" }, [
            _c("p", { staticClass: "mini-value-tile__title" }, [
              _vm._v("\n\t\t\t\t" + _vm._s(_vm.title) + "\n\t\t\t")
            ]),
            _vm._v(" "),
            _vm.subtitle
              ? _c("p", { staticClass: "mini-value-tile__subtitle" }, [
                  _c("span", [_vm._v(_vm._s(_vm.subtitle))])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mini-value-tile__value-container" }, [
            _c("span", { staticClass: "mini-value-tile__value" }, [
              _vm._v(_vm._s(_vm.value))
            ]),
            _vm._v(" "),
            _vm.symbol
              ? _vm._m(0)
              : _vm.indicator
              ? _c("span", { staticClass: "mini-value-tile__subvalue" }, [
                  _vm._v("\n\t\t\t\t" + _vm._s(_vm.indicator) + "\n\t\t\t")
                ])
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("i", {
      staticClass: "mini-value-tile__symbol fa",
      class: _vm.symbol,
      style: _vm.symbolStyle
    })
  }
]
render._withStripped = true

export { render, staticRenderFns }