import MultipleEntities from '../shared/multiple-entities';
import RespondentGroup from './respondent-group';
import urls from './urls';

const RespondentGroupsEntity = MultipleEntities.extend({
	model: RespondentGroup,
	urls,
	eventNamespace: 'respondent-group',
	idName: 'respondentGroupId',

	getAll () {
		return this.retrieve('listGroups');
	},

	getByCurrentClinician () {
		return this.retrieve('listGroupsByCurrentClinician');
	},

	currentClinicianWithCommon () {
		return this.retrieve('currentClinicianGroupsWithCommon');
	}

});

export default new RespondentGroupsEntity();
