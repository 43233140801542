import sanitize from 'service/sanitize-string/sanitize-string';

/**
 * Set title of tile. Used when title is generated after tile initialization.
 *
 * @function TileView.setTitle
 * @param {string} title - New tile title.
 * @returns {object}     - Tile object.
 * @example
 * import t from 'translate';
 *
 * export default TileView.extend({
 *     title: t('loading'),
 *     loaded: ({ tile }) => {
 *         tile.setTitle(t('loaded');
 *     }
 * });
 */
export default function (title) {
	this.__params.tileContainerModel.set('title', sanitize(title));
	return this;
}
