/**
 * Tile title.
 *
 * @name TileView.title
 * @param {string} title - Tile title.
 * @example
 * import t from 'translate';
 *
 * export default TileView.extend({
 *     title: t('Title')
 * });
 */
export default function (title) {
	this.__params.tileContainerModel.set('title', title);
}
