var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onSubmit($event)
        }
      }
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("text-input", {
        attrs: {
          required: true,
          mandatory: true,
          invalid: _vm.$v.goal.name.$error,
          label: "goals.editGoal.name"
        },
        on: {
          input: function($event) {
            return _vm.$v.goal.name.$touch()
          }
        },
        model: {
          value: _vm.goal.name,
          callback: function($$v) {
            _vm.$set(_vm.goal, "name", $$v)
          },
          expression: "goal.name"
        }
      }),
      _vm._v(" "),
      _vm.showTreatmentSelect
        ? _c("select-input", {
            attrs: {
              options: _vm.treatments,
              required: true,
              mandatory: true,
              invalid: _vm.$v.goal.treatment.$error,
              reduce: function(treatment) {
                return treatment.id
              },
              label: "goals.editGoal.chooseTreatment",
              "item-label": "name"
            },
            on: {
              input: function($event) {
                return _vm.$v.goal.treatment.$touch()
              }
            },
            model: {
              value: _vm.goal.treatment,
              callback: function($$v) {
                _vm.$set(_vm.goal, "treatment", $$v)
              },
              expression: "goal.treatment"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("select-input", {
        attrs: {
          multiple: "",
          options: _vm.categories,
          label: "goals.editGoal.categories",
          "item-label": "name"
        },
        model: {
          value: _vm.goal.category,
          callback: function($$v) {
            _vm.$set(_vm.goal, "category", $$v)
          },
          expression: "goal.category"
        }
      }),
      _vm._v(" "),
      _c("datetime-input", {
        attrs: { label: "goals.editGoal.deadline" },
        model: {
          value: _vm.deadlineDatetime,
          callback: function($$v) {
            _vm.deadlineDatetime = $$v
          },
          expression: "deadlineDatetime"
        }
      }),
      _vm._v(" "),
      _c("text-input", {
        attrs: { label: "goals.editGoal.description", multiline: true },
        model: {
          value: _vm.goal.description,
          callback: function($$v) {
            _vm.$set(_vm.goal, "description", $$v)
          },
          expression: "goal.description"
        }
      }),
      _vm._v(" "),
      _c(
        "button-group",
        [
          _c("submit-button", {
            attrs: {
              "can-submit": !_vm.$v.goal.$invalid,
              label: "goals.editGoal.submit"
            }
          }),
          _vm._v(" "),
          _c("plain-button", { attrs: { "on-click": _vm.onCancel } })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }