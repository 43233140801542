import footnote from './fixes/footnote';

/*
 * Since report content template is stored in a DB any markup fixes has to
 * be done here
 */

var fixes = function (html) {
	var $html = footnote(html);

	return $html.get();
};

export default fixes;
