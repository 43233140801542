import t from 'translate';
import store, { APP } from 'store';
import { TREATMENT } from 'store/treatments/rp-treatment';
import { when } from 'jquery';

const card = {
	name: 'available-consents',
	title: t('consents.available'),
	url: [
		'treatment/:treatmentId/consents',
		'consents'
	],

	ctrl: (context, { treatmentId }) => {
		if (treatmentId) {
			return store.dispatch(TREATMENT.INIT, treatmentId);
		}

		// Workaround for refreshing no-treatment-context consents directly
		return when([
			store.dispatch(APP.SET_DETACHED, true),
			store.dispatch(TREATMENT.CLEAR)
		]);
	}
};

export { card as availableConsents };
