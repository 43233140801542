import { includes, isArray, isMatch, reduce } from 'lodash';

/**
 * Get tiles config. If card config contains alternative cards, return such a config based on
 * data passed. Otherwise -- return default config.

 *
 * @param {Array} defaultTiles - Default tile config for a card.
 * @param {Array} alternativeCards - List of alternative tile configs for a card.
 * @param {object} variantData - Data to be matched against.
 * @returns {Array} - Tiles.
 */

export default (
	{ defaultTiles, alternativeCards, variantData = {} }
) => reduce(alternativeCards, (memo, cfg) =>
	reduce(cfg.when, (previous, values, key) => {
		const foundInArray = isArray(values) && includes(values, variantData[key]);

		return foundInArray || isMatch(cfg.when, variantData) ? cfg.tiles : previous;
	}, false) || memo
, defaultTiles);
