import t from 'translate';
import appBar from 'app-bar';
import store from 'store';
import { when } from 'jquery';
import appConnectionDialog from '../dialogs/app-connection';

const card = {
	url: 'treatment/:treatmentId/connected-apps',
	name: 'connected-apps',
	title: t('Connected Apps'),

	ctrl: () => {
		appBar.addButton(card.name, {
			icon: 'add',
			title: t('Connect a new App'),
			click: appConnectionDialog
		});

		return when(
			store.dispatch('apps/initConnectedForTreatment'),
			store.dispatch('apps/initActivatedForTreatment')
		);
	}
};

export { card as connectedApp };
