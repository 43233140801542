import FormComponentView from '../form-component-view';
import { compile } from 'handlebars';

const number = FormComponentView.extend({
	template: compile(`
		<label class="form-view__label-container">
			<p class="form-view__label-text">{{ label }}</p>
			<input
				type="number"
				class="form-view__input form-view__number"
				name="{{ uniqueName }}"
				value="{{ value }}"
				{{#if required}} required="required" {{/if}}
				{{#if readonly}} readonly="readonly" {{/if}}
				{{#if disabled}} disabled="disabled" {{/if}}
				{{#if hint}} aria-describedby="{{ name }}-hint"{{/if}}
				{{#if min }} min="{{ min }}" {{/if}}
				{{#if max }} max="{{ max }}" {{/if}}
				{{#if step }} step="{{ step }}" {{/if}} />
		</label>
		{{#if hint}}
			<div class="form-view__hint" id="{{ name }}-hint">{{ hint }}</div>
		{{/if}}
	`)
});

export { number };
