import Marionette from 'marionette';

export default Marionette.View.extend({
	template () {},
	tagName: 'option',
	modelEvents: {
		'change:fieldValue': 'onFieldValueChange'
	},
	onRender () {
		const fieldKey = this.model.get('fieldKey') || this.options.fieldKey;
		const fieldValue = this.model.get('fieldValue') || this.options.fieldValue;
		const isSelected = !!this.model.get('selected') || this.options.selected;

		this.$el
			.val(`${fieldKey}`)
			.text(fieldValue)
			.addClass(`${this.options.formClass}__option`)
			.prop({
				disabled: !!this.model.get('disabled') || this.options.disabled,
				selected: isSelected
			});
		isSelected && this.$el.attr('selected', 'selected'); // needed for form reset to work properly
	},

	onFieldValueChange () {
		this.$el.text(this.model.get('fieldValue'));
	}
});
