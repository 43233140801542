import FormComponentView from '../form-component-view';
import { compile } from 'handlebars';

const paragraph = FormComponentView.extend({
	template: compile(`
		<div class="
			form-view__paragraph-container
			{{! use multiline true property two display value below label
			 @TODO prepare something more sexy ... maybe multiline-paragraph component }}
			{{#if multiline }} form-view__paragraph-container--multiline{{/if}}
			{{#if spread}} form-view__paragraph-container--spread {{/if}}">
			<p class="form-view__paragraph-label form-view__label-text">{{ label }}</p>
			<p
				class="form-view__paragraph-value"
				{{#if hint}} aria-describedby="{{ name }}-hint"{{/if}}>
				{{ value }}
			</p>
			{{#if hint}}
				<div class="form-view__hint" id="{{ name }}-hint">{{ hint }}</div>
			{{/if}}
		</div>
	`),
	customClassName: 'form-view__field-container--collapsible',

	valueChanged (model, value) {
		this.setText(this.transformValue(value));
	},

	onAfterRender () {
		this.$text = this.$(`p.${this.model.get('formClass')}__paragraph-value`);
		this.setText(this.transformValue(this.model.get('value')));
	},

	setText (text) {
		this.$text.text(text);
	},

	transformValue (value) {
		if (this.model.get('transform')) {
			return this.model.get('transform')(value);
		}
		return value;
	}
});

export { paragraph };

