import { Model } from 'backbone';
import { assign } from 'lodash';

const Respondent = Model.extend({
	url: 'backend-commons/auth/respondent.json',

	getTimezone () {
		return this.get('timezoneName');
	},

	getId () {
		return this.get('userId');
	}
});

const respondent = new Respondent();

export default () => assign(respondent, respondent.fetch());
