import { DateTime } from 'luxon';
import { filter, includes, isUndefined } from 'lodash';
import datetimeSvc from 'service/datetime/datetime';

export const agendaView = {
	namespaced: true
};

// getters
const COLLAPSED = 'collapsed';
const COMPRESSED = 'compressed';
const CURRENT = 'current';
const END = 'end';
const FILTERED_ITEMS = 'filteredItems';
const GROUPS = 'groups';
const GROUPS_COUNT = 'groupsCount';
const NEXT = 'next';
const PREV = 'prev';
const SCOPE = 'scope';
const SMALL_UNIT = 'smallUnit';
const START = 'start';
const STATUSES = 'statuses';
const TYPES = 'types';
const UNIT = 'unit';

// actions
const INIT = 'init';
const SET_ITEMS = 'setItems';
const SET_SCOPE = 'setScope';
const SET_COMPRESSED = 'setCompressed';
const SET_COLLAPSED = 'setCollapsed';
const GO_NEXT = 'next';
const GO_PREV = 'prev';
const GO_UP = 'up';
const GO_DOWN = 'down';
const SET_DATETIME = 'setDateTime';
const ADD = 'add';
const FILTER_BY_TYPE = 'filterByType';
const FILTER_BY_STATUS = 'filterByStatus';

export const GETTERS = {
	COLLAPSED,
	COMPRESSED,
	CURRENT,
	END,
	FILTERED_ITEMS,
	GROUPS,
	GROUPS_COUNT,
	NEXT,
	PREV,
	SCOPE,
	SMALL_UNIT,
	START,
	STATUSES,
	TYPES,
	UNIT
};

export const ACTIONS = {
	ADD,
	FILTER_BY_STATUS,
	FILTER_BY_TYPE,
	GO_DOWN,
	GO_NEXT,
	GO_PREV,
	GO_UP,
	INIT,
	SET_COLLAPSED,
	SET_COMPRESSED,
	SET_DATETIME,
	SET_ITEMS,
	SET_SCOPE
};

export const store = () => ({
	namespaced: true,

	state: {
		items: [],
		scope: 'month',
		compressed: false,
		collapsed: false,
		dateTime: '',
		filters: {
			type: [],
			status: []
		},
		reverseTimeline: false,
		timezone: 'UTC'
	},

	getters: {
		[CURRENT]: (state) => DateTime.fromISO(state.dateTime),

		[NEXT]: (state, getters) => getters[CURRENT].plus({ [getters.unit]: 1 }),
		[PREV]: (state, getters) => getters[CURRENT].minus({ [getters.unit]: 1 }),

		[START]: (state, getters) => ({
			year: getters[CURRENT].startOf('year'),
			month: getters[CURRENT].startOf('month'),
			week: getters[CURRENT].minus({ days: getters[CURRENT].weekday - 1 }).startOf('day'),
			day: getters[CURRENT].startOf('day')
		})[state.scope],

		[END]: (state, getters) => ({
			year: getters[CURRENT].endOf('year'),
			month: getters[CURRENT].endOf('month'),
			week: getters[CURRENT].plus({ days: 7 - getters[CURRENT].weekday }).set(
				{ hour: 23, minute: 59, second: 59 }
			),
			day: getters[CURRENT].endOf('day')
		})[state.scope],

		[GROUPS_COUNT]: (state, getters) => ({
			year: 11,
			month: getters[END].daysInMonth - 1,
			week: 6,
			day: 23
		})[state.scope],

		[UNIT]: (state) => ({
			year: 'years',
			month: 'months',
			week: 'weeks',
			day: 'days'
		})[state.scope],

		[SMALL_UNIT]: (state) => ({
			year: 'months',
			month: 'days',
			week: 'days',
			day: 'hours'
		})[state.scope],

		[FILTERED_ITEMS]: (state) =>
			filter(
				state.items,
				(item) => {
					const byType = state.filters.type.length ?
						includes(state.filters.type, item.itemType) :
						true;
					const byStatus = state.filters.status.length ?
						includes(state.filters.status, item.status) :
						true;

					return byType && byStatus;
				}
			),

		[GROUPS]: (state, getters) => {
			const startingIndex = {
				year: 1,
				month: 1,
				week: 1,
				day: 1
			}[state.scope];

			const groups = [];

			for (let i = 0; i <= getters.groupsCount; i++) {
				const min = getters[START].plus({ [getters[SMALL_UNIT]]: i });
				const max = getters[START].plus({ [getters[SMALL_UNIT]]: i + 1 });

				groups.push({
					datetime: min,
					items: filter(
						getters[FILTERED_ITEMS],
						({ datetime }) => {
							const localDatetime = datetimeSvc(datetime)
								.setTimezone(state.timezone)
								.toMoment()
								.toISOString();

							const date = datetime ?
								DateTime.fromISO(localDatetime) :
								DateTime.local();

							return date[state.scope] === getters[CURRENT][state.scope] &&
								date >= min &&
								date < max;
						}

					),
					index: startingIndex + i
				});
			}
			return state.reverseTimeline ? groups.reverse() : groups;
		},
		[SCOPE]: (state) => state.scope,
		[COMPRESSED]: (state) => state.compressed,
		[COLLAPSED]: (state) => state.collapsed,
		[TYPES]: (state) => state.filters.type,
		[STATUSES]: (state) => state.filters.status
	},

	mutations: {
		setItems: (state, items) => {
			state.items = items;
		},

		addItem: (state, item) => {
			state.items.push(item);
		},

		setScope: (state, scope) => {
			state.scope = scope;
		},

		setCompressed: (state, compressed) => {
			state.compressed = compressed !== false;
		},

		setCollapsed: (state, collapsed) => {
			state.collapsed = collapsed !== false;
		},

		increaseScope: (state) => {
			state.scope = {
				year: 'year',
				month: 'year',
				week: 'month',
				day: 'week'
			}[state.scope];
		},

		decreaseScope: (state) => {
			state.scope = {
				year: 'month',
				month: 'week',
				week: 'day',
				day: 'day'
			}[state.scope];
		},

		setDateTime: (state, dateTime) => {
			state.dateTime = dateTime;
		},

		setFiltersByType: (state, filters) => {
			state.filters.type = filters;
		},

		setFiltersByStatus: (state, filters) => {
			state.filters.status = filters;
		},

		setReverseTimeline: (state, value) => {
			state.reverseTimeline = value;

		},

		setTimezone: (state, timezone) => {
			state.timezone = timezone;
		}
	},

	actions: {
		[INIT]: ({ commit }, {
			items,
			initialScope,
			initialCompressed = false,
			initialCollapsed = false,
			reverseTimeline = false,
			timezone
		}) => {
			!isUndefined(initialScope) && commit('setScope', initialScope);
			commit('setCompressed', initialCompressed);
			commit('setCollapsed', initialCollapsed);
			commit('setReverseTimeline', reverseTimeline);
			!isUndefined(timezone) && commit('setTimezone', timezone);
			commit('setItems', items);
			commit('setDateTime', DateTime.local().toString());
		},

		[SET_ITEMS]: ({ commit }, { items }) => {
			commit('setItems', items);
		},

		[SET_SCOPE]: ({ commit }, scope) => {
			commit('setScope', scope);
		},

		[SET_COMPRESSED]: ({ commit }, compressed) => {
			commit('setCompressed', compressed);
		},

		[SET_COLLAPSED]: ({ commit }, collapsed) => {
			commit('setCollapsed', collapsed);
		},

		[GO_NEXT]: ({ dispatch, getters }) => dispatch('setDateTime', getters[NEXT]),

		[GO_PREV]: ({ dispatch, getters }) => dispatch('setDateTime', getters[PREV]),

		[GO_UP]: ({ commit }) => {
			commit('increaseScope');
		},

		[GO_DOWN]: ({ commit }) => {
			commit('decreaseScope');
		},

		[SET_DATETIME]: ({ commit }, datetime) => {
			commit('setDateTime', datetime.toString());
		},

		[ADD]: ({ commit }, item) => {
			commit('addItem', item);
		},

		[FILTER_BY_TYPE]: ({ commit }, filters) => {
			commit('setFiltersByType', filters);
		},

		[FILTER_BY_STATUS]: ({ commit }, filters) => {
			commit('setFiltersByStatus', filters);
		}
	}
});
