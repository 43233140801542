import ActivatedApps from './activated-apps';
import ConnectedApps from './connected-apps';

/**
 * @class Repository
 */
export default {
	/**
	 * Get all available Store Apps.
	 *
	 * @function getStoreApps
	 * @param {Object} params - Params.
	 * @returns {Array} Store Apps.
	 * @example
	 *        repository.getStoreApps(params);
	 */
	getStoreApps: (params) => ActivatedApps.listStoreApps(params),

	/**
	 * Get all available Activated Apps.
	 *
	 * @function getActivatedApps
	 * @param {Object} params - Params.
	 * @returns {Array} Activated Store Apps.
	 * @example
	 *        repository.getActivatedApps(params);
	 */
	getActivatedApps: (params) => ActivatedApps.listActivated(params),

	/**
	 * Get all available Activated Apps.
	 *
	 * @function getConnectedApps
	 * @param {Object} params - Params.
	 * @returns {Array} Connected Store Apps.
	 * @example
	 *        repository.getConnectedApps(params);
	 */
	getConnectedApps: (params) => ConnectedApps.listConnected(params),

	/**
	 * Get single App details by id.
	 *
	 * @function getActivatedApps
	 * @param {int} id - App id.
	 * @returns {Object} Activated Store Apps.
	 * @example
	 *        repository.getAppDetails(2);
	 */
	getAppDetails: (id) => ConnectedApps.getById(id)
};
