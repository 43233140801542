var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("card-link", { attrs: { card: _vm.card, params: _vm.params } }, [
    _vm._v("\n\t" + _vm._s(_vm.contentParsed) + "\n")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }