import config from 'core/config';
import { assign } from 'lodash';
import urlify from 'service/urlify/urlify';

export default {
	single: {
		url: 'backend-commons/uploaded-media/'
	},
	list: {
		url: 'backend-commons/uploaded-media/?deleted=0&include=category'
	},
	listAll: {
		url: 'backend-commons/uploaded-media/?includeDeleted=1 '
	},
	permalink: {
		url: (permalink) => `${
			config().backendRootUrl.default
		}backend-commons/uploaded-media/permalink/${permalink}`
	},

	search: {
		url: (params = {}) => `backend-commons/uploaded-media/?${urlify(
			assign({ deleted: 0 }, params),
			{ omit: ['timestamp'] }
		)}`
	},

	isUnique: {
		url: (alias) => `backend-commons/uploaded-media/permalink-available/${alias}`
	},

	category: {
		url: 'backend-commons/uploaded-media-category/'
	},

	categories: {
		url: 'backend-commons/uploaded-media-category/'
	}
};
