<template>
	<section>
		<agenda-view
			:name="name"
			:items="items"
			:addable="addable"
			:types="types"
			:tile-path="tilePath"
			:settings="settings"
			:refresh="refresh"
			:loading="loading"
			:disabled="!enabled"
			:timezone="timezone"
			:statuses="['completed', 'open', 'overdue']"
		>
			<template #disabled>
				<p class="incomplete-treatment">
					{{$t('treatment.labels.unavailable')}}
				</p>
			</template>
		</agenda-view>

		<goal-dialog
			:show="addingGoal"
			:goal="newGoal"
			:on-close="closeGoalDialog"
			:loading="addGoalLoading"
			:on-submit="addGoal"
			:categories="categories"
			:treatments="treatments"
			title="goals.editGoal.addTitle"
		/>

		<activity-dialog
			:show="addingActivity"
			:activity="newActivity"
			:on-close="closeActivityDialog"
			:loading="addActivityLoading"
			:on-submit="addActivity"
			:goals="goals"
		/>
	</section>
</template>

<script>
import t from 'service/lang/translate';
import { assign, cloneDeep, concat, find, get, isEmpty, map } from 'lodash';
import cardUrl from 'core/engine/card/services/card-url';
import { actions, activitiesMapping, labels } from '../../shared/agenda-config';
import agendaMixin from '../../shared/tile-mixin';
import ActivityDialog from '../../components/activity-dialog';
import GoalDialog from '../../components/goal-dialog';
import cwalert from 'service/cwalert';
import Vue from 'vue';
import { READ } from 'service/acl/access-levels';
import { CLINICIAN } from 'service/acl/checkpoints.json';
import { RESPONDENT } from 'store/respondents/respondent';
import { GOALS } from 'store/goals/goals';
import { ACTIVITIES } from 'store/goals/activities';
import { TREATMENT } from 'store/treatments/rp-treatment';

const state = Vue.observable({
	addingActivity: false,
	addingGoal: false
});

export default {
	acl: [{
		checkpoint: CLINICIAN.RESPONDENTS.GOALS_ACTIVITIES,
		op: READ
	}],
	title: t('goals.goals'),
	features: ['ENABLE_RESPONDENT_GOALS'],
	actions: ({ $store }) => {
		const actionsList = [['goals/init'], ['activities/init'], ['goalCategories/init']];

		if (
			$store.getters.userType === 'clinician' &&
			$store.getters[RESPONDENT.RESPONDENT_ID] &&
			!$store.getters['treatment/treatmentId']) {
			actionsList.push(['treatments/init']);
		}

		return actionsList;
	},
	components: { ActivityDialog, GoalDialog },
	mixins: [agendaMixin],
	data ({ $store }) {
		return {
			addActivityLoading: false,
			addGoalLoading: false,
			name: 'goals-tile',
			newActivity: {},
			newGoal: {},
			tilePath: [
				this.tile.path,
				`respondent-${$store.getters[RESPONDENT.RESPONDENT_ID]}`,
				`treatment-${$store.getters['treatment/treatmentId']}`
			].join('|'),
			config: cloneDeep(this.tile.config()),
			loading: false,
			types: [{ name: 'goal' }, { name: 'activity' }],
			addable: [{
				icon: 'activity',
				name: 'activity',
				action: (datetime) => {
					this.openActivityDialog({ datetime });
				},
				timeScope: this.tile.config().activityTimeScope
			}, {
				icon: 'goal',
				name: 'goal',
				action: (datetime) => {
					this.openGoalDialog({ datetime });
				},
				timeScope: this.tile.config().goalTimeScope
			}]
		};
	},

	computed: {
		addingActivity: {
			get: () => state.addingActivity,
			set: (newVal) => {
				Vue.set(state, 'addingActivity', newVal);
			}
		},
		addingGoal: {
			get: () => state.addingGoal,
			set: (newVal) => {
				Vue.set(state, 'addingGoal', newVal);
			}
		},
		treatments: ({ $store }) => $store.getters['treatments/mainRespondentReady'],
		items: ({ activities, goals }) => concat(goals, activities),
		categories: ({ $store }) => $store.getters['goalCategories/items'],
		treatment: ({ $store }) => $store.getters['treatment'],
		enabled: ({ $store, treatment = {}, treatments, isTreatmentReady }) =>
			$store.getters.userType === 'respondent' || !treatment ?
				!!find(treatments, (isTreatmentReady)) :
				isTreatmentReady(treatment),

		goals ({ $store, config, openActivityDialog, respondentId, toggleGoalStatus }) {
			return map(
				$store.getters[GOALS.ITEMS],
				(goal) => assign({}, goal, {
					icon: 'goal',
					labels: labels({ config: config.goalLabels, entity: goal }),
					openAction: {
						action: () => {
							cardUrl.openCard('goal', {
								goalId: goal.id,
								respondentId,
								treatmentId: $store.getters[TREATMENT.ID]
							});
						}
					},
					actions: actions({
						context: this,
						config: config.goalActions,
						entity: goal,
						respondentId,
						openActivityDialog,
						toggleGoalStatus
					})
				})
			);
		},

		activities: ({ $store, config, respondentId, toggleActivityStatus }) => map(
			$store.getters[ACTIVITIES.ITEMS],
			(activity) => assign(
				{},
				activity,
				activitiesMapping({ activity, config, respondentId, toggleActivityStatus })
			)
		)
	},

	methods: {
		async refresh () {
			this.loading = true;
			await Promise.all([
				this.$store.dispatch('goals/init'),
				this.$store.dispatch('activities/init')
			]);
			this.loading = false;
		},

		openActivityDialog ({ datetime, goal } = {}) {
			const params = isEmpty(datetime) ?
				{} :
				{ startDate: datetime.toFormat(t('date.formats.activity')) };

			this.newActivity = assign(
				{},
				this.emptyActivity,
				params,
				goal ? { goal } : {}
			);
			this.addingActivity = true;
		},

		closeActivityDialog () {
			this.addingActivity = false;
		},

		addActivity () {
			this.addActivityLoading = true;
			this.$store.dispatch('activities/create', { activity: this.newActivity }).then(() => {
				this.addActivityLoading = false;
				cwalert.success(t('goals.editActivity.addSuccess'));
				this.closeActivityDialog();
			});
		},

		openGoalDialog ({ datetime }) {
			const params = isEmpty(datetime) ?
				{} :
				{
					deadline: datetime.toISO({
						includeOffset: false,
						suppressSeconds: true
					})
				};

			if (this.treatmentId) {
				params.treatment = this.treatmentId;
			}
			this.newGoal = assign({}, this.emptyGoal, params);
			this.addingGoal = true;
		},

		closeGoalDialog () {
			this.addingGoal = false;
		},

		addGoal () {
			this.addGoalLoading = true;
			this.$store.dispatch('goals/create', this.newGoal).then(() => {
				this.addGoalLoading = false;
				cwalert.success(t('goals.editGoal.addSuccess'));
				this.closeGoalDialog();
			});
		},

		isTreatmentReady: (treatment) => get(treatment, 'complete') && get(treatment, 'enabled')
	}
};
</script>
