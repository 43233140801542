import { Model, sync } from 'backbone';
import urls from './urls';

export default Model.extend({
	url: function () {
		return urls.isUnique.url(this.get('alias'));
	},

	sync: function (method, model, options) {
		if (method === 'create') {
			options.type = 'GET';
		}

		return sync.apply(this, arguments);
	}
});
