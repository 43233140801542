import t from 'translate';
import { when } from 'jquery';
import store from 'store';
import appBar from 'app-bar';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import banRedirect from '../helpers/ban-redirect';
import treatmentContextParam from 'modules/treatments/util/treatment-context-param';

const card = {
	title: t('Edit circle'),
	url: [
		'treatment/:treatmentId/circle/:circleId/edit',
		'circle/:circleId/edit'
	],
	name: 'circle-edit',

	ctrl: ({ cardConfig, close }, { circleId }) => {
		if (banRedirect(close)) {
			return;
		}

		const limit = cardConfig.limit || 10;

		return when(
			store.dispatch('circles/currentCircle/init', { circleId }),
			store.dispatch('circles/members/init', { circleId, limit }),
			store.dispatch('circles/members/requests/init', { circleId, limit }),
			store.dispatch('circles/categories/init')
		).then(() => {
			appBar.addButton(card.name, {
				title: t('Delete circle'),
				icon: 'delete',
				click: () => {
					confirmation({
						icon: 'delete',
						warning: true,
						title: t('Delete circle'),
						message: t('Are you sure?')
					}).then(() => {
						store.dispatch('circles/currentCircle/remove').then(() => {
							cwalert.success(t('Circle has been removed'));
							close({
								openCard: 'circles-startpage',
								cardQuery: treatmentContextParam()
							});
						});
					});
				}
			});

		});
	}
};

export { card as circleEdit };
