import iconsDefault from './icon-mapping-default';
import iconsFa from './icon-mapping-fa';

export default [
	{
		name: 'default',
		small: 'icon-2x',
		source: iconsDefault,
		action: (name) => `icon--default icon--${name}`
	},
	{
		name: 'fontawesome',
		small: 'fa-2x',
		source: iconsFa,
		action: (name) => `fa fa-${name}`
	}
];
