/**
 * @class Repository
 */
export default {
	/**
	 * Get a collection of all MessageDrafts for a given treatment.
	 *
	 * @function getDrafts
	 * @param {Object} {treatmentId} - Treatment ID.
	 * @returns {Object}             - Message Drafts Collection.
	 * @example
	 *        repository.getDrafts({ treatmentId: 7 });
	 */
	messageDrafts ({ treatment }) {
		return this.MessageDrafts.getForTreatment({ treatment });
	},

	/**
	 * Get a collection of all Messages for a given treatment.
	 *
	 * @function getMessages
	 * @param {Object} {treatmentId} - Treatment ID.
	 * @returns {Object}             - Messages Collection.
	 * @example
	 *        repository.getMessages({ treatmentId: 7 });
	 */
	messages ({ treatmentId }) {
		return this.Messages.getForTreatment({ treatment: treatmentId });
	}
};
