import MultipleEntities from '../shared/multiple-entities';
import RespondentInRespondentGroup from './respondent-in-respondent-group';
import urls from './urls';

const RespondentInRespondentGroups = MultipleEntities.extend({
	model: RespondentInRespondentGroup,
	urls,
	eventNamespace: 'respondent-in-respondent-group',
	idName: 'relationId',

	canAdd (model) {
		return +model.get('respondentId') === +this.ownerId;
	},

	getForRespondent (respondentId) {
		return this.retrieve('respondentInGroups', respondentId);
	}

});

export default new RespondentInRespondentGroups();
