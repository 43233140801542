<template>
	<pie-chart
		:params="params"
		:options="options"
	/>
</template>

<script>
import { assign, filter, get, isUndefined, map, round, isNull } from 'lodash';
import { mapGetters } from 'vuex';
import assessmentValueChart from '../../shared/assessment-value-chart';

export default {
	...assessmentValueChart(),

	actions: (tile) => [[
		'assessmentValuePieSeries/getValues',
		assign({}, tile.config()),
		{},
		{ instant: true }
	]],

	data: ({ tile }) => ({
		config: tile.config()
	}),

	computed: {
		...mapGetters('assessmentValuePieSeries', ['valuesByRef']),

		// mapping tile config and data from backend by reference name ('ref')
		// INPUT [{ref: 'val1', value: 2}, {ref: 'val2', value: 4}]
		// ['val2', 'val1']
		// output [4, 2]
		series: ({ config, valuesByRef }) => map(
			config.series,
			(ref) => get(valuesByRef[ref], 'value')
		),
		options: ({ config }) => ({
			chartPadding: 30,
			labelOffset: 70,
			height: config.chartHeight,
			horizontalLabels: config.horizontalLabels
		}),
		params: ({ config, series }) => ({
			dataColors: config.dataColors,
			labels: config.groupLabels,
			legendNames: config.legendNames,
			series: map(series, (piece) => round(piece, 2))
		})
	},
	created () {
		!isUndefined(this.config.title) && this.tile.setTitle(this.config.title);
		const checkValues = filter(this.series, (value) => !isNull(value));
		!checkValues.length && !this.config.showIfEmpty && this.tile.disable();
	}
};
</script>
