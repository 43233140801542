import { assign, filter, find, forEach, get, includes, isFunction, last } from 'lodash';

export default function ({ elements = [], itemsPerPage = 20 }) {

	const pageFactory = () => ({
		elementIds: [],
		questionIds: [],
		setStartId (id) {
			if (!this.startId) {
				this.startId = id;
			}
			return this;
		},
		addElement ({ id, type }) {
			this.elementIds.push(id);

			if (type === 'Question') {
				this.questionIds.push(id);
			}
		}
	});
	const pages = [pageFactory()];
	const currentPage = () => last(pages);
	const addPage = () => {
		const newPage = pageFactory();
		pages.push(newPage);
		return newPage;
	};

	const props = (element) => {
		const isProper = isFunction(element.get);

		return {
			id: isProper ? element.get('id') : element.getElementId(),
			type: isProper ? element.get('class') : element.getClass(),
			getSection: () => isProper ? element.section() : element.getSection()
		};
	};

	const maybeAddPage = (element) => {
		if (currentPage().questionIds.length >= itemsPerPage) {
			let shouldAddPage = false;
			const { getSection } = props(element);

			for (let section = getSection(); section; section = section.getSection()) {
				if (section.isNoPageBreaks()) {
					return;
				}
				shouldAddPage = true;
			}

			shouldAddPage && addPage();
		}
	};
	forEach(elements, (element) => {
		const { id, type } = props(element);

		if (type === 'Section' && element.isNewPageRequired()) {
			addPage().addElement({ id, type });
			return;
		}

		currentPage().setStartId(id).addElement({ id, type });
		maybeAddPage(element);
	});

	// cleanup the pages list
	this.pages = filter(pages, (page) => page.elementIds.length || page.startId);

	assign(this, {
		getPageStartId: (n) => {
			const index = Math.min(n, pages.length) - 1;
			return get(this.pages, `[${index}]`, 0).startId;
		},

		getPageNumberByElementId: (elementId) => {
			const parentPage = find(this.pages, (page) => includes(page.elementIds, elementId));

			return this.pages.indexOf(parentPage) + 1;
		},

		pagesCount: () => this.pages.length
	});
}

