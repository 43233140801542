import moment from 'moment-timezone';
import { assign, clone, isDate, isObject, isString, map, replace } from 'lodash';

const cloneDate = (date) => {
	if (isDate(date)) {
		return new Date(date.valueOf());

	} else if (moment.isMoment(date)) {
		return date.clone();

	} else if (isString(date)) {
		// sometimes backend returns date with trailing `+0000` or `+00:00` which confuses moment.js
		// into different timezone handling; this is not wanted
		// eslint-disable-next-line no-useless-escape
		return replace(date, /\+00\:?00$/, '');

	}

	return clone(date);

};

const Datetime = function (_date) {
	const initialDate = _date;
	let date = moment(_date);

	assign(this, {
		utcString: date.format('YYYY-MM-DDTHH:mm'),
		unixTimestamp: date.unix(),

		/*
		 * Get Checkware Date Representation Array™, can be passed to
		 * moment.js.
		 *
		 * @returns {Array} - Checkware Date Representation Array™.
		 * @example
		 * 		datetime('1984-10-14').getDateArray();
		 */
		getDateArray: () => [
			date.year(),
			date.month() + 1,
			date.date(),
			date.hours(),
			date.minutes(),
			date.seconds()
		],

		/*
		 * Get glorious Checkware Date Representation Object™.
		 *
		 * @returns {Object} - Checkware Date Representation Object™.
		 * @example
		 * 		datetime('1984-10-14').toObject();
		 */
		toObject () {
			return {
				date: this.getDateArray(),
				utcString: this.utcString
			};
		},

		/*
		 * Get JavaScript Date object.
		 *
		 * @returns {Object} - JavaScript Date object.
		 * @example
		 * 		datetime('1984-10-14').toDate();
		 */
		toDate: () => date.toDate(),

		/*
		 * Get moment.js object.
		 *
		 * @returns {Object} - Moment.js object.
		 * @example
		 * 		datetime('1984-10-14').toMoment();
		 */
		toMoment: () => date,

		/*
		 * Adjust datetime to a given timezone. Use it on UTC datetimes.
		 * Typically used for showing a datetime.
		 *
		 * @param {string} timezone - Timezone name.
		 * @returns {Object} - Datetime instance.
		 * @example
		 * 		datetime('1984-10-14').setTimezone('Europe/Warsaw');
		 */
		setTimezone (timezone) {
			if (isString(timezone) && timezone.length) {
				this.timezone = timezone;
				const offset = date.clone().tz(this.timezone).utcOffset();
				date.add(offset, 'minutes');
			}
			return this;
		},

		/*
		 * Adjust datetime to an UTC time. Use it on datetimes with
		 * known timezone. Typically used for storing via REST API.
		 *
		 * @param {string} timezone - Timezone name.
		 * @returns {Object} - Datetime instance.
		 * @example
		 * 		datetime('1984-10-14').toUtcFromTimezone('Europe/Warsaw');
		 */
		toUtcFromTimezone (timezone) {
			if (isString(timezone)) {
				const offset = date.clone().tz(timezone).utcOffset();
				date.subtract(offset, 'minutes');
				this.utcString = date.format('YYYY-MM-DDTHH:mm');
			}
			return this;
		},

		/*
		 * Set datetime to given timezone.
		 * Used in case when you have isoDate string and want to use it with timezone.
		 *
		 * @param {string} timezone - Timezone name.
		 * @returns {Object} - Datetime instance.
		 * @example
		 * 		datetime('1984-10-14').useTimezone('Europe/Warsaw');
		 */
		useTimezone (timezone) {
			if (isString(timezone)) {
				date = moment.tz(initialDate, timezone);
			}
			return this;
		}
	});
};

/*
 * Datetime conversion service.
 *
 * Meant for keeping datetime management pretty close to being reasonable.
 * Datetime is stored internally in a moment.js object. Once REST API
 * starts to keep all datetimes in a standard way (i.e. ISO string) this
 * service shall become deprecated.
 *
 * @chainable
 * @param  {string|number|Object|Array|Date|moment} inputDate
 * @returns {Object} Instantiated date.
 */
const datetime = (inputDate) => {
	// do not mutate original date
	const rawDate = cloneDate(inputDate);
	let date;

	if (isObject(rawDate)) {
		if (rawDate.hasOwnProperty('date')) {
			date = map(rawDate.date, (val) => val ? val : 0);
			// backend returns months in [1-12] range while moment operates
			// on [0-11] range
			date[1]--;

		} else if (rawDate.hasOwnProperty('unixtime')) {
			date = rawDate.unixtime * 1000;
		}
	}

	return new Datetime(date || rawDate);
};

export default datetime;

export const toUtc = (
	{ date, timezone = 'UTC', format = 'YYYY-MM-DDTHH:mm' }
) => datetime(date).toUtcFromTimezone(timezone).toMoment().format(format);

export const fromUtc = (
	{ date, timezone = 'UTC', format = 'YYYY-MM-DD HH:mm' }
) => datetime(date).setTimezone(timezone).toMoment().format(format);
