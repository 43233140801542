<template>
	<div v-on-click-outside="hideList" class="view-report">
		<agenda-item-action
			:action="showReport"
			:title="action.label"
		/>
		<transition name="fade">
			<span
				v-if="loading"
				v-loader-spinner:small
				class="view-report__loader"
			/>
		</transition>
		<transition name="fade">
			<div
				v-if="toggled && !loading"
				class="view-report__popup"
			>
				<ul
					v-if="generatedReports.length > 1"
					class="view-report__list"
				>
					<li
						v-for="report in reports"
						:key="report.id"
						class="view-report__list-item"
					>
						<a
							:href="buildUrl(report)"
							class="view-report__link"
						>
							<icon name="report" />
							<span class="view-report__link-text">{{report.name}}</span>
						</a>
					</li>
				</ul>
				<p
					v-else-if="pendingCount"
					class="view-report__info"
				>
					{{$t('assessmentInstance.reports.pending', { pendingCount })}}
				</p>
				<p
					v-else
					class="view-report__info"
				>
					{{$t('assessment.properties.no-reports')}}
				</p>
			</div>
		</transition>
	</div>
</template>

<script>
import AgendaItemAction from 'components/agenda-view/agenda-item-action';
import {
	ASSESSMENT_INSTANCE_REPORTS as REPORTS
} from 'store/assessments/assessment-instance-reports';
import cardUrl from 'core/engine/card/services/card-url';

export default {
	components: { AgendaItemAction },
	props: {
		action: {
			type: Object,
			required: true
		}
	},
	data: () => ({
		loading: false,
		toggled: false
	}),

	computed: {
		assessmentId: ({ action }) => action.entity.assessmentId,
		assessmentInstanceId: ({ action }) => action.entity.assessmentInstanceId,
		reports: ({ $store }) => $store.getters[REPORTS.GETTERS.ASSESSMENT_REPORTS],
		generatedReports: ({ $store }) => $store.getters[REPORTS.GETTERS.REPORT_INSTANCES],
		pendingCount: ({ $store }) => $store.getters[REPORTS.GETTERS.PENDING_COUNT]
	},

	methods: {
		hideList () {
			this.toggle(false);
		},

		toggle (bool = false) {
			this.toggled = bool;
		},

		async showReport () {
			this.toggle(!this.toggled);

			if (this.toggled) {
				this.loading = true;

				await this.$store.dispatch(REPORTS.ACTIONS.INIT, {
					assessmentInstanceId: this.assessmentInstanceId,
					assessmentId: this.assessmentId
				});
				this.loading = false;

				if (this.generatedReports.length === 1) {
					cardUrl.go(this.buildUrl(this.generatedReports[0]));
				}
			}
		},

		buildUrl (report) {
			return cardUrl.buildUrl('assessment-report', {
				assessmentInstanceId: this.assessmentInstanceId,
				reportId: report.id
			});
		}
	}
};
</script>
