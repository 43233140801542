var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "treatment-grid-item" }, [
    _c(
      "button",
      {
        staticClass: "treatment-grid-item__button",
        attrs: { tabindex: "0" },
        on: { click: _vm.click }
      },
      [
        _c(
          "div",
          {
            staticClass:
              "treatment-grid-item__body treatment-grid-item__body--with-image"
          },
          [
            _c(
              "div",
              {
                staticClass: "treatment-grid-item__image",
                style: {
                  "background-image": "url(" + _vm.image + ")"
                }
              },
              [
                !_vm.image ? _c("icon", { attrs: { name: "image" } }) : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "treatment-grid-item__content" },
              [_vm._t("default")],
              2
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }