import t from 'translate';
import store, { APP } from 'store';
import { TREATMENT } from 'store/treatments/rp-treatment';
import { when } from 'jquery';

const card = {
	title: t('consents.consent'),
	name: 'consent',
	url: [
		'treatment/:treatmentId/consent/:consentInstanceId',
		'consent/:consentInstanceId'
	],

	ctrl: ({ populate }, { treatmentId }) => {
		// Workaround for refreshing no-treatment-context consents directly
		if (!treatmentId) {
			return when([
				store.dispatch(APP.SET_DETACHED, true),
				store.dispatch(TREATMENT.CLEAR)
			]);
		}

		return populate();
	}
};

export { card as consent };
