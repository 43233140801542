import { isObject, isEmpty, forEach } from 'lodash';

const QueryParams = function (params) {
	let queryString = '';

	if (isObject(params) && !isEmpty(params)) {
		const elements = [];
		forEach(params, (v, k) => {
			if (v !== undefined) {
				elements.push(`${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
			}
		});
		queryString = elements.join('&');
	}

	this.toString = function () {
		return queryString;
	};
};

export default QueryParams;
