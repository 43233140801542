import { exact } from '../__helpers/getters';
import { set } from '../__helpers/mutations';

export const EXCEEDED = 'exceeded';
export const LIMIT = 'limit';
export const LIST = 'list';
export const TOTAL = 'total';

export const DELETE_LIST = 'deleteList';
export const SET_LIMIT = 'setLimit';
export const SET_LIST = 'setList';
export const SET_TOTAL = 'setTotal';

export const PROVIDE_RESULTS = 'provideResults';
export const SET_SEARCH_LIST = 'setSearchList';

const store = (repositoryFn) => ({
	namespaced: true,
	state: {
		[EXCEEDED]: false,
		[LIMIT]: 0,
		[LIST]: [],
		[TOTAL]: 0
	},
	getters: {
		...exact([EXCEEDED, LIMIT, LIST, TOTAL])
	},
	mutations: {
		[DELETE_LIST]: (state) => {
			state[LIST] = [];
			state[EXCEEDED] = false;
		},
		[SET_LIMIT]: set(LIMIT),
		[SET_LIST]: set(LIST),
		[SET_TOTAL]: (state, payload) => {
			state[TOTAL] = payload;
			state[EXCEEDED] = state[TOTAL] > state[LIMIT];
		}
	},
	actions: {
		[DELETE_LIST]: ({ commit }) => commit(DELETE_LIST),
		[PROVIDE_RESULTS]: ({ commit }, payload) => {
			const results = repositoryFn(payload);

			return results.then((data, statusText, xhr) => {
				const list = xhr.status === 200 ? data : [];
				commit(SET_LIST, list);
				let total = 0;

				if (list.length > 0) {
					total = +xhr.getResponseHeader('x-total-count');
				}

				commit(SET_TOTAL, total);
			});
		},
		[SET_LIMIT]: ({ commit }, payload) => commit(SET_LIMIT, payload),
		[SET_SEARCH_LIST]: ({ commit }, payload) => commit(SET_LIST, payload)
	}
});

export default store;
