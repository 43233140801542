import ActivityDialog from 'modules/goals/components/activity-dialog';
import cwalert from 'service/cwalert';
import t from 'service/lang/translate';
import store from 'store';

// split those below into separate files once their count reaches three
export default {
	addActivity: ({ show, entity, loading }) => {
		const props = {
			show,
			onClose: () => {
				props.show = false;
			},
			activity: {
				name: '',
				description: '',
				startDate: '',
				deadline: '',
				goal: entity
			},
			goals: [],
			loading,
			onSubmit: async () => {
				props.loading = true;
				await store.dispatch(
					'activities/create',
					{ activity: props.activity, inGoal: true }
				);

				cwalert.success(t('goals.editActivity.addSuccess'));
				props.loading = false;
				props.show = false;
			}
		};

		return {
			component: ActivityDialog,
			props
		};
	}
};
