var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes.content },
    [
      _vm.title
        ? _c(
            "h3",
            { class: _vm.classes.title },
            [
              _c("icon", {
                attrs: { name: _vm.icon, "class-name": _vm.classes.icon }
              }),
              _c("span", { class: _vm.classes["title-text"] }, [
                _vm._v(_vm._s(_vm.title))
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("afterTitle"),
      _vm._v(" "),
      !_vm.isCollapsed && _vm.subtitle
        ? _c("h4", { class: _vm.classes.subtitle }, [
            _vm._v(_vm._s(_vm.subtitle))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.descriptionVisible
        ? _c("p", { class: _vm.classes.description }, [
            _vm._v(_vm._s(_vm.description))
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }