var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "appbar-system-buttons__action",
      attrs: {
        tabindex: "0",
        "aria-expanded": _vm.expanded,
        "aria-controls": "sidebar-container",
        title: _vm.$t("Main menu")
      },
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [_c("icon", { attrs: { name: "menu" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }