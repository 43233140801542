var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    [
      _vm._l(_vm.objVal, function(value, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass: "edit-object__row edit-object__row--edit"
          },
          [
            _c("p", { staticClass: "array-row__index" }, [
              _vm._v("\n\t\t\t" + _vm._s(index) + "\n\t\t")
            ]),
            _vm._v(" "),
            _c(
              "input",
              _vm._g(
                {
                  directives: [{ name: "trim", rawName: "v-trim" }],
                  attrs: { type: "text", readonly: _vm.readonly },
                  domProps: { value: value },
                  on: {
                    blur: function($event) {
                      return _vm.onArrayValueChange(index, $event)
                    }
                  }
                },
                _vm.inputListeners
              )
            ),
            _vm._v(" "),
            !_vm.readonly
              ? _c("button", {
                  staticClass: "edit-object__button--remove",
                  on: {
                    click: function($event) {
                      return _vm.removeArrayValue(index)
                    }
                  }
                })
              : _vm._e()
          ]
        )
      }),
      _vm._v(" "),
      _c("li", { staticClass: "edit-object__row edit-object__row--add" }, [
        _c(
          "input",
          _vm._g(
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newArrayValue,
                  expression: "newArrayValue"
                },
                { name: "trim", rawName: "v-trim" }
              ],
              attrs: { type: "text", readonly: _vm.readonly },
              domProps: { value: _vm.newArrayValue },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.newArrayValue = $event.target.value
                }
              }
            },
            _vm.inputListeners
          )
        ),
        _vm._v(" "),
        !_vm.readonly
          ? _c("button", {
              staticClass: "edit-object__button--add",
              on: { click: _vm.addArrayValue }
            })
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }