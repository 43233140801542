import datetime from 'service/datetime/datetime';
import { clinicianName, respondentName } from 'service/display-name/display-name';

/**
 * Format the value.
 *
 * @param {object} params                     - Parameters.
 * @param {object|string|number} params.value - The value.
 * @param {string} params.type                - Type of value.
 * @param {string} params.dateFormat          - Optional date format.
 * @param {string} params.whenEmpty           - Optional fallback for empty value.
 * @returns {string}                          - Formatted value.
 * @example
 *        formatValue({
 * 			value: '2019-02-11T15:22',
 * 			type: 'date',
 * 			dateFormat: 'DD-MM-YY',
 * 			whenEmpty: t('None')
 * 		});
 *        -> '11-02-2019'
 */
export const formatValue = ({ value, type = '', dateFormat = '', whenEmpty = '', timezone }) => ({
	date: () => {
		const date = datetime(value);

		if (isNaN(date.unixTimestamp)) {
			return whenEmpty;
		}

		return dateFormat ?
			date.setTimezone(timezone).toMoment().format(dateFormat) :
			date.utcString;
	},
	respondent: () => value ? respondentName(value, { preventEscape: true }) : '',
	clinician: () => value ? clinicianName(value, { preventEscape: true }) : ''
})[type];
