import repository from 'repository';
import { filter, get, size, sortBy, isArray, cloneDeep, forEach } from 'lodash';
import { set, update, remove, idx } from '../__helpers/mutations';
import compareParams from '../__helpers/compare-params';

const prefix = 'dataIntegration/codes';

const BY_ENTERPRISE = 'byEnterprise';
const WITH_ENTERPRISE = 'withEnterprise';
const WITHOUT_ENTERPRISE = 'withoutEnterprise';

export const CODES = {
	BY_ENTERPRISE: `${prefix}/${BY_ENTERPRISE}`,
	WITH_ENTERPRISE: `${prefix}/${WITH_ENTERPRISE}`,
	WITHOUT_ENTERPRISE: `${prefix}/${WITHOUT_ENTERPRISE}`
};

export default {
	namespaced: true,

	state: {
		codesByEnterprise: [],
		codesWithoutEnterprise: [],
		list: [],
		params: {},
		data: null,
		total: 0,
		initialParams: null
	},

	getters: {
		[BY_ENTERPRISE]: (state) => state.codesByEnterprise,
		[WITH_ENTERPRISE]: (state) => state.codesByEnterprise,
		codeVariations: (state) => get(state.data, 'variants'),
		data: (state) => state.data,
		list: (state) => state.list,
		params: (state) => state.params,
		sorted: (state, getters) => sortBy(getters.list, ['name']),
		total: (state) => state.total,
		[WITHOUT_ENTERPRISE]: (state) => filter(state.codesWithoutEnterprise, 'enabled'),
		count: (state, getters) => size(getters.list),
		enabled: (state, getters) => filter(getters.list, { enabled: true })
	},

	mutations: {
		setList: (state, list) => {
			if (isArray(list)) {
				state.list = list;
			} else {
				state.list.push(list);
			}
		},
		setParams: set('params'),
		setTotal: set('total'),
		setCodesByEnterprise: set('codesByEnterprise'),
		setCodesWithoutEnterprise: set('codesWithoutEnterprise'),
		setSingle: set('data'),
		setInitialParams: (state, params) => {
			state.initialParams = params;
		},
		update: update('list'),
		remove: remove('list')
	},

	actions: {
		add: (context, data) => repository.addCode(data),

		delete: (context, id) => repository.deleteCode(id),

		deleteCodesByEnterprise: ({ commit }) => {
			commit('setCodesByEnterprise', []);
		},

		getSingle: ({ commit }, id) => repository.dataIntegrationCode(id)
			.then((rawResults) => commit('setSingle', rawResults)),

		search: ({ commit, state }, searchParams = null) => {
			if (searchParams) {
				commit('setParams', searchParams);
			}

			if (!state.initialParams) {
				const initialParams = cloneDeep(searchParams);
				delete initialParams.start;
				commit('setInitialParams', initialParams);
			}
			const noFilters = compareParams(state, searchParams);

			const results = repository.searchCodes(state.params);

			return results.then((rawResults, statusText, xhrObj) => {
				const total = xhrObj.getResponseHeader('X-Total-Count');
				commit('setTotal', total);

				if (statusText === 'success') {
					if (noFilters) {
						forEach(rawResults, (item) => {
							const index = idx(state.list, item.id);

							if (index > -1) {
								commit('update', item);
							} else {
								commit('setList', item);
							}
						});
					} else {
						commit('setList', rawResults);
					}
				}
				return [results, statusText, xhrObj];
			});
		},

		searchCodesByEnterprise: ({ commit }, enterpriseId) => {
			const method = enterpriseId ?
				'searchCodesByEnterprise' :
				'emptyEntepriseCodes';
			return repository[method](enterpriseId).then((response) => {
				commit('setCodesByEnterprise', response);

				if (!enterpriseId) {
					commit('setCodesWithoutEnterprise', response);
				}
			});
		},

		searchEnabledCodes: ({ commit }) => repository.searchEnabledCodes().then((response) => {
			commit('setList', response);
		}),

		update: (context, data) => repository.updateCode(data)
	}
};
