var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.wrapperClassName },
    [
      _c(
        "div",
        {
          class: _vm.mainClassName,
          attrs: { role: "link", tabindex: "0" },
          on: {
            click: _vm.onClick,
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") &&
                _vm._k($event.keyCode, "space", 32, $event.key, [
                  " ",
                  "Spacebar"
                ])
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.onClick($event)
            }
          }
        },
        [
          _vm.statusVisible
            ? _c("span", { class: _vm.elementClassName("status") })
            : _vm._e(),
          _vm._v(" "),
          _c("transition", { attrs: { name: "appear" } }, [
            !_vm.isCollapsed
              ? _c("span", { class: _vm.elementClassName("item-type-label") }, [
                  _vm._v(_vm._s(_vm.itemTypeLabel))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.elementClassName("content-container") },
            [
              _c("agenda-item-content", {
                attrs: {
                  title: _vm.title,
                  subtitle: _vm.subtitle,
                  description: _vm.description,
                  icon: _vm.icon,
                  classes: _vm.classNames.content,
                  "is-collapsed": _vm.isCollapsed
                },
                scopedSlots: _vm._u([
                  {
                    key: "afterTitle",
                    fn: function() {
                      return [
                        !_vm.isCollapsed && _vm.horizontalLabels.length
                          ? _c("agenda-item-labels", {
                              attrs: { labels: _vm.horizontalLabels }
                            })
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _vm._v(" "),
              !_vm.isCollapsed && _vm.labels.length
                ? _c("agenda-item-labels", { attrs: { labels: _vm.labels } })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.actionsVisible
        ? _c("agenda-item-actions", {
            attrs: { actions: _vm.actions, classes: _vm.classNames.actions }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          class: _vm.elementClassName("collapse-action"),
          attrs: { "aria-label": _vm.toggleCollapsedLabel },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.toggleCollapsed($event)
            }
          }
        },
        [_c("span", { class: _vm.elementClassName("collapse-chevron") })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }