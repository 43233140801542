import MultipleEntities from '../shared/multiple-entities';
import AssessmentClinicianGroup from './clinician-group';
import urls from './urls';

const AssessmentClinicianGroups = MultipleEntities.extend({
	model: AssessmentClinicianGroup,
	urls,
	eventNamespace: 'assessment-clinician-group',
	idName: 'relationId',

	canAdd (model) {
		return +model.get('assessmentId') === +this.ownerId;
	},

	getForAssessment (assessmentId) {
		return this.retrieve('clinicianGroups', assessmentId);
	}

});

export default new AssessmentClinicianGroups();
