import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import Flag from './flag';

const AssessmentFlags = MultipleEntities.extend({
	urls,
	model: Flag,
	idName: 'assessmentFlagId',
	eventNamespace: 'assessment-flag',
	canAdd (model) {
		return +model.get('assessmentId') === +this.ownerId;
	},

	getAll () {
		return this.retrieve('flags');
	},

	getByAssessment (assessmentId) {
		return this.retrieve('flagsOfAssessment', assessmentId);
	}
});

export default new AssessmentFlags();
