import repository from 'repository';
import { set } from '../__helpers/mutations';

export default {
	state: {
		assessmentList: [],
		dataMappingList: [],
		defaultRuleSet: [],
		ruleSetId: 0
	},

	getters: {
		assessmentList: (state) => state.assessmentList,
		dataMappingList: (state) => state.dataMappingList
	},

	mutations: {
		setAssessments: set('assessmentList'),
		setDataMappingList: set('dataMappingList'),
		setRuleSetId: set('ruleSetId')
	},

	actions: {
		addAndRefreshDataMapping: ({ state, dispatch }, data) =>
			dispatch('addDataMapping', data)
				.then(() => dispatch('searchDataMapping', state.ruleSetId)),
		addDataMapping: (context, data) => repository.addDataMapping(data),
		deleteAndRefreshDataMapping: ({ state, dispatch }, id) =>
			dispatch('deleteDataMapping', id)
				.then(() => dispatch('searchDataMapping', state.ruleSetId)),
		deleteDataMapping: (context, id) => repository.deleteDataMapping(id),
		getAssessments: ({ commit }) => repository.getAssessments()
			.then((response) => commit('setAssessments', response)),
		searchDataMapping: ({ commit, state }, ruleSetId) => {
			if (ruleSetId) {
				commit('setRuleSetId', ruleSetId);
			}
			const request = state.ruleSetId ?
				repository.dataMapping(state.ruleSetId) :
				repository.dataMappingAll();
			request.then((response) => commit('setDataMappingList', response));
		},
		updateAndRefreshDataMapping: ({ state, dispatch }, data) =>
			dispatch('updateDataMapping', data)
				.then(() => dispatch('searchDataMapping', state.ruleSetId)),
		updateDataMapping: (context, data) => repository.updateDataMapping(data)
	}
};
