import $ from 'jquery';

/**
 * Show the "modal" layer put above the tile.
 *
 * @function TileView.showModal
 * @param {*} content - Anything that `jQuery.append` accepts.
 * @returns {Object}  - Tile object.
 * @example
 * import $ from 'jquery';
 *
 * export default TileView.extend({
 *     loaded: ({ tile }) => {
 *         tile.showModal($('<div />');
 *     }
 * });
 */
export default function (content) {
    const $modalContainer = $('.tile__modal', this.el.parentElement.parentElement);
    const $contentContainer = $(this.el.parentElement);

	$contentContainer.hide();
	$modalContainer.show();

    const $box = $('<div />').appendTo($modalContainer);

    if (content) {
        $box.append(content);
    }
}
