<template>
	<span v-if="contentParsed">{{contentParsed}}</span>
	<span v-else>{{emptyChar}}</span>
</template>

<script>
import { mtParse } from '../helpers/parse';
import TableComponentMixin from './_mixin';

export default {
	mixins: [
		TableComponentMixin
	],

	computed: {
		contentParsed: ({ column, content, item, tileConfig }) =>
			mtParse({ column, content, tileTranslate: tileConfig.translate, item })
	},

	methods: {
		sortValue: ({ itemValue }) => itemValue
	}
};
</script>
