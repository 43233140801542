import Vue from 'vue';
import { findIndex, find } from 'lodash';

export default {
	namespaced: true,

	state: {
		peers: []
	},

	getters: {
		peers: (state) => state.peers,
		peerById: (state) => (socketId) => find(state.peers, { socketId })
	},

	mutations: {
		updatePeers: (state, payload) => {
			state.peers = payload;
		},

		resetPeers: (state) => {
			state.peers.length = 0;
		},

		updatePeer: (state, { peer, mute }) => {
			const index = findIndex(state.peers, { socketId: peer.id });
			const mediaType = {
				audio: 'audioMuted',
				video: 'videoMuted'
			}[peer.name];

			Vue.set(state.peers[index], mediaType, mute);
		}
	},

	actions: {
		updatePeers: ({ commit }, payload) => {
			commit('updatePeers', payload);
		},

		resetPeers: ({ commit }) => {
			commit('resetPeers');
		},

		updatePeer: ({ commit }, payload) => {
			commit('updatePeer', payload);
		}
	}
};
