import repository from 'repository';
import { findIndex, isArray, keyBy, map } from 'lodash';

const prefix = 'cube';

const RESULTS = 'results';
const LOAD = 'load';

export const CUBE = {
	RESULTS: `${prefix}/${RESULTS}`,
	LOAD: `${prefix}/${LOAD}`
};

export default {
	namespaced: true,

	state: {
		data: []
	},

	getters: {
		[RESULTS]: (state) => keyBy(state.data, 'id')
	},

	mutations: {
		setResult: (state, { id, result }) => {
			const index = findIndex(state.data, { id });

			if (index > -1) {
				state.data.splice(index, 1, { id, result });

			} else {
				state.data.push({ id, result });
			}
		}
	},

	actions: {
		[LOAD]: ({ commit }, { id, query, ignoreAccessRules = false }) => {
			const promise = isArray(query) ?
				Promise.all(map(
					query,
					(queryEntry) => repository.cube({ query: queryEntry, ignoreAccessRules })
				)) :
				repository.cube({ query, ignoreAccessRules });

			return promise.then((result) => {
				commit('setResult', { id, result });
			});
		}
	}
};
