import { assign, filter, map, truncate } from 'lodash';
import repository from 'repository';
import attributes from './assessment-attributes';
import { set, reset } from '../__helpers/mutations';

const formatName = ({ code, label }) =>
	truncate(`[${code}] ${label}`, { length: 50, separator: ' ' });

export default {
	namespaced: true,

	modules: {
		attributes
	},

	state: {
		calculations: [],
		flags: [],
		questions: []
	},

	getters: {
		calculations: (state) => map(state.calculations, (calc) => {
			const name = formatName({
				code: calc.assessmentCalculationCode,
				label: calc.assessmentCalculationName
			});
			return assign(calc, { name, code: calc.assessmentCalculationCode });
		}),

		assignableCalculations: (state, getters) => filter(
			getters.calculations,
			{ assignedByVariable: true }
		),

		flags: (state) => map(state.flags, (flag) => {
			const name = formatName({
				code: flag.assessmentFlagCode,
				label: flag.assessmentFlagName
			});
			return assign(flag, { name, code: flag.assessmentFlagCode });
		}),

		questions: (state) => map(state.questions, (question) => {
			const name = formatName({
				code: question.assessmentQuestionCode,
				label: question.assessmentQuestionLabel
			});
			return assign(question, { name, code: question.assessmentQuestionCode });
		})
	},

	mutations: {
		setCalculations: set('calculations'),
		setQuestions: set('questions'),
		setFlags: set('flags'),
		resetCalculations: reset('calculations'),
		resetFlags: reset('flags'),
		resetQuestions: reset('questions')
	},

	actions: {
		getCalculations: ({ commit }, { assessmentId }) => {
			const calculations = repository.assessmentCalculations(assessmentId);
			return calculations.then(() => {
				commit('setCalculations', calculations.toJSON());
			});
		},

		getFlags: ({ commit }, { assessmentId }) => {
			const flags = repository.assessmentFlags(assessmentId);
			return flags.then(() => {
				commit('setFlags', flags.toJSON());
			});
		},

		getQuestions: ({ commit }, { assessmentId }) => {
			const questions = repository.assessmentQuestions(assessmentId);
			return questions.then(() => {
				commit('setQuestions', questions.toJSON());
			});
		},

		resetCalculations: ({ commit }) => {
			commit('resetCalculations');
		},

		resetFlags: ({ commit }) => {
			commit('resetFlags');
		},

		resetQuestions: ({ commit }) => {
			commit('resetQuestions');
		}
	}
};
