import Vue from 'vue';
import t from 'translate';
import { mapGetters, mapActions } from 'vuex';
import { escape, isFunction, replace } from 'lodash';
import store from 'store';
import attachmentsComponent from './attachments-component';
import systemSettings from 'system-settings';

export default ({ provideUrl, provideMarkAnswered, canMarkAnswered = false }) => {
	const actionTagName = isFunction(provideUrl) ? 'a' : 'div';
	const maybeHref = isFunction(provideUrl) ? `:href="url(message)"` : ``;
	const maybeMarkAnswered = provideMarkAnswered ?
		`
		<div 
			v-if="message.senderType === 'RESPONDENT'" 
			class="message__mark-answered-container"
		>
			<label class="message__mark-answered-label">
				<input 
					type="checkbox"
					class="message__mark-answered-control"
					:value="message.answered"
					:checked="message.answered"
					:disabled="${!canMarkAnswered}"
					@change="markAnswered(message)" 
				/>
				${t('Answered')}
			</label>
		</div>
		` :
		``;

	return new Vue({
		el: document.createElement('div'),
		store,

		components: {
			'attachments-list': attachmentsComponent()
		},

		computed: {
			...mapGetters('messages', {
				messages: 'processed'
			}),
			enableAttachments: () => systemSettings.getBoolean('ENABLE_ATTACHMENTS')
		},

		methods: {
			...mapActions('messages', ['markAnswered']),

			url: (message) => provideUrl(message),
			markAnswered: (message) => {
				store.dispatch('messages/markAnswered', { message, callback: provideMarkAnswered });
			},

			className: (message) => ({
				'message__action--answered': message.answered,
				'message__action--unanswered': !message.answered,
				'message__action--sender-respondent': message.senderType === 'RESPONDENT',
				'message__action--sender-clinician': message.senderType === 'CLINICIAN',
				'message__action--not-link': !isFunction(provideUrl)
			}),

			sanitize: (content) => replace(escape(content), /\n/g, '<br />')
		},

		template: `
			<ul class="message__list" v-if="messages.length">
				<li
					v-for="message in messages"
					class="message__list-item generic-list__item"
					v-once
				>
					<${actionTagName}
						${maybeHref}
						class="message__action generic-list__action"
						:class="className(message)"
					>
						<p class="message__date">
							<time v-html="message.date"></time>
						</p>

						<div class="generic-list__action-title-container message__container">
							<p class="message__author generic-list__action-title">
								{{ message.author }}
							</p>
							<p
								class="message__text generic-list__action-description"
								v-html="sanitize(message.content)"
							></p>
							${maybeMarkAnswered}
							<attachments-list
								v-if="enableAttachments"
								:attachments="message.attachment"
							/>
						</div>

					</${actionTagName}>
				</li>
			</ul>
			<p v-else v-translate>No Items</p>
		`
	});
};

