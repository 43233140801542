import * as types from './urls';
import { GET, PUT } from 'service/ajax/ajax';
import { assign, find } from 'lodash';

// used for identifying object in array that contains variants config
const VARIANTS_ID = { __name: 'cardVariants' };

// Only card variants are kept in the 'custom-json' user feed. If it changes, be sure to change how
// it works (on b💩ckend or fr👌ntend) so that this method won't overwrite any excessive data
const saveCardVariants = ({ cardVariants }) => PUT({
	url: types.user,
	data: [assign(cardVariants, VARIANTS_ID)]
});

export default {
	cardConfig: ({ type }) => {
		const { url, prepend } = types[type];
		const request = GET({ url, prepend });
		const response = assign({}, { data: [] }, request.then((json) => {
			response.data = json;
			return request;
		}));

		return response;
	},

	saveCardConfig: ({ type, data, logs: { logType: typeId, logMessage: message } }) => PUT({
		data,
		url: types[type].url,
		headers: {
			'Changes-Description': JSON.stringify({
				typeId,
				message
			})
		}
	}),

	cardVariants: () => {
		const request = GET({ url: types.user, prepend: true });
		const response = assign({}, { data: [] }, request.then(
			(json) => {
				response.data = find(json, VARIANTS_ID);
				return request;
			},

			// on first GET ever, there's no data and b💩ckend throws error (logical), remove this
			// when b💩ckend starts acting in a normal way
			() => {
				response.data = [{ __name: 'cardVariants' }];
				return saveCardVariants({ cardVariants: VARIANTS_ID });
			}
		));

		return response;
	},

	saveCardVariants
};
