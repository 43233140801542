import repository from 'repository';
import { set } from '../__helpers/mutations';
import initWith from '../__helpers/init-with';
import { assign } from 'lodash';

export default {
	namespaced: true,

	state: {
		data: {}
	},

	getters: {
		item: (state) => state.data
	},

	mutations: {
		set: set()
	},

	actions: {
		init: (
			{ commit, rootState, dispatch },
			{ goalId, respondentId }
		) => {
			const resource = repository.goal({ goalId, respondentId });

			return initWith({ rootState, dispatch }, {
				actionName: 'goal/init',
				resource,
				action: (goal) => {
					commit('set', goal);

					return {
						treatmentId: goal.treatment,
						respondentId: goal.respondent
					};
				}
			});
		},

		update: ({ commit }, goal) => {
			commit('set', goal);
			return repository.updateGoal(goal);
		},

		toggleStatus: ({ commit, getters }, goal) => {
			commit('update', assign({}, goal, { isCompleted: !goal.isComplete }));

			return repository.updateGoal(getters.item).then((data) => {
				commit('update', data);
			});
		}
	}
};
