import SingleEntity from '../shared/single-entity';
import urls from './urls';

/**
 * GoalCategory
 * @class GoalCategory
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	eventNamespace: 'goal-category',
	urlRoot: urls.singleCategory.url
});
