<template>
	<icon
		v-if="!isEmpty"
		small
		:title="column.label"
		:name="name"
		:color="color"
	/>
	<span v-else>{{emptyChar}}</span>
</template>

<script>
import { get } from 'lodash';
import { parse } from '../helpers/parse';
import Icon from 'components/icon/index';
import TableComponentMixin from './_mixin';

export default {
	components: {
		Icon
	},

	mixins: [
		TableComponentMixin
	],

	computed: {
		name: ({ column, content, item }) => parse({ column, content, item }),
		color: ({ column, content, item }) => get(column, 'iconColors') ?
			parse({ column, content, item, field: 'iconColors' }) :
			null
	},

	methods: {
		sortValue: ({ itemValue }) => itemValue
	}
};
</script>
