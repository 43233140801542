var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("text-input", {
    staticClass: "table-view__search",
    attrs: { icon: "search", label: _vm.$t("table.searchLabel") },
    model: {
      value: _vm.search,
      callback: function($$v) {
        _vm.search = $$v
      },
      expression: "search"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }