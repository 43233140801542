var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "activity" },
    [
      _vm.isCompleted
        ? _c(
            "div",
            { staticClass: "activity-achieved-label" },
            [
              _vm._v(
                "\n\t\t" + _vm._s(_vm.$t("Activity completed")) + "\n\t\t"
              ),
              _c("icon", {
                attrs: {
                  name: "achieved",
                  "class-name": "activity-achieved-icon"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("edit-activity", {
        attrs: {
          activity: _vm.activity,
          goals: _vm.goals,
          "is-new": false,
          "on-submit": _vm.onSubmit,
          "on-cancel": _vm.onCancel
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }