var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ol",
    { class: _vm.className("list", "level1") },
    _vm._l(_vm.items, function(item) {
      return _c(
        "li",
        { key: item.gotoCode, class: _vm.className("list-item", "level1") },
        [
          _c("a", {
            class: _vm.className("list-link", "level1"),
            domProps: { innerHTML: _vm._s(item.title) },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.onClick(item)
              }
            }
          }),
          _vm._v(" "),
          item.items.length
            ? _c(
                "ol",
                { class: _vm.className("list", "level2") },
                _vm._l(item.items, function(subItem) {
                  return _c(
                    "li",
                    {
                      key: subItem.gotoCode,
                      class: _vm.className("list-item", "level2")
                    },
                    [
                      _c("a", {
                        class: _vm.className("list-link", "level2"),
                        domProps: { innerHTML: _vm._s(subItem.title) },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onClick(subItem)
                          }
                        }
                      })
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }