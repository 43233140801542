export default function (value) {

	this.getRawValue = function () {
		return value;
	};

	this.getBoolean = function () {
		return !!value;
	};

	this.getNumber = function () {
		return value ?
			1 :
			0;
	};

	this.getString = function () {
		return value ?
			'true' :
			'false';
	};

	this.toString = function () {
		return `{Boolean:${this.getString()}}`;
	};

}