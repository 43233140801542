import t from 'translate';
import TileView from 'tile-view';
import Vue from 'vue';
import $ from 'jquery';
import { mapState, mapGetters, mapActions } from 'vuex';
import store from 'store';
import cwalert from 'cwalert';
import confirmation from 'components/confirmation/confirmation';
import avatarNickname from '../../components/avatar-nickname';
import enableCirclesCheck from '../../helpers/enable-circles-check';

export default TileView.extend({
	title: t('Join requests'),
	acl: [{
		op: 'READ',
		checkpoint: 'administrator.circles.circles'
	}],
	features: enableCirclesCheck,

	cardData: () => ['circleId'],

	loaded: ({ tile }) => {
		tile.vue = new Vue({
			el: $('<div />')[0],
			store,
			components: { ...avatarNickname },
			data: {
				loadingId: 0
			},
			computed: {
				...mapState('circles/members/requests', ['current', 'totalCount']),
				...mapGetters('circles/members/requests', ['list']),

				needMore () {
					return this.totalCount > this.current.length;
				},

				resultsCount () {
					return t('{current} of {total} records displayed', {
						current: this.current.length,
						total: this.totalCount
					})
				}
			},

			methods: {
				...mapActions('circles/members/requests', ['loadMore', 'loadAll']),

				setLoading (requestId) {
					this.loadingId = requestId;
				},

				setLoaded () {
					this.loadingId = 0;
				},

				accept (request) {
					this.setLoading(request.id);
					this.$store.dispatch('circles/members/acceptRequest',
						{ request, circleId: tile.circleId }).then(() => {
							cwalert.success(t('Join request has been accepted'));
							this.setLoaded();
						})
				},

				reject (request) {
					confirmation({
						icon: 'delete',
						warning: true,
						title: t('Remove member'),
						message: t('Are you sure you want to reject this request?')
					})
						.then(() => {
							this.setLoading(request.id);
							return this.$store.dispatch('circles/members/requests/reject',
								{ request });
						})
						.then(() => {
							cwalert.success(t('Join request has been rejected'));
							this.setLoaded();
						});
				},

				isLoading (requestId) {
					return this.loadingId === requestId;
				}
			},

			template: `
				<section class="circle-requests" v-if="list.length">
					<p class="circle-requests__member-list-counter">({{resultsCount}})</p>
					<ul class="circle-requests__list">
						<li v-for="request in list" class="circle-requests__list-item">
							<avatar-nickname :user="request" />
							<div class="flat-button__buttons-container">
								<button 
									@click="accept(request)" 
									class="
										circle-requests__action 
										circle-requests__action--accept
									"
									>${t('Accept')}</button>
								<button 
									@click="reject(request)" 
									class="
										circle-requests__action 
										circle-requests__action--reject
									"
								>${t('Reject')}</button>
								<div
									class="circle-requests__loader"
									v-if="isLoading(request.id)"
									v-loader-spinner
								></div>
							</div>
							
						</li>
					</ul>
					<button 
						class="circle-requests__request-list-action flat-button__button"
						v-if="needMore" 
						@click="loadMore"
					>${t('More results')}</button>
				</section>
				<p v-else>${t('There are no requests')}</p>
			`
		});

		tile.$el.html(tile.vue.$el);
	}
});
