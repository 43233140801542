import store from 'store';
import t from 'translate';
import { TREATMENT } from 'store/treatments/rp-treatment';

const card = {
	url: 'treatment/:treatmentId/video-chat',
	name: 'video-chat',
	title: t('Live video stream'),
	ctrl: ({ populate, cardData }) => {
		cardData.set({
			treatmentId: store.getters[TREATMENT.ID]
		});
		populate();
	}
};

export { card as chat };
