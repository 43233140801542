/**
 * @class Repository
 */
export default {
	/**
	 * Get all diary entries.
	 *
	 * @function getDiaryEntries
	 * @returns {Object} {{#crossLink "DiaryEntries"}}{{/crossLink}}.
	 * @example
	 *        Repository.getDiaryEntries();
	 */
	getDiaryEntries () {
		return this.DiaryEntries.getAll();
	}
};
