import Vue from 'vue';
import fileUrl from 'service/file-url/file-url';
import t from 'translate';

export default () => Vue.extend({
	props: {
		attachments: {
			type: Array,
			default: () => []
		},

		remove: {
			type: Function
		}
	},
	template:
		`<ul class="message-attachments__list">
			<li 
				class="message-attachments__list-item" 
				v-for="attachment in attachments"
			>
					<a 
						class="message-attachments__link"
						target="_blank"
						:href="url(attachment.url)"
					>
						{{ attachment.name }}
					</a>
					<button 
						class="message-attachments__remove-action"
						v-if="remove"
						@click.prevent="remove(attachment.id)"
					>
						${t('Remove')}
					</button>
			</li>
		</ul>`,

	methods: {
		url: (link) => fileUrl(link)
	}
});
