import appContext from 'app-context';

/**
 * So called backend config. A config for the frontend provided by the backend when user logs
 * in.
 * @class backendconfig
 * @author Marcin
 * @requires backend-config
 */
var BackendConfig = function () {

	/**
	 * Returns a value specified by the key
	 * @param {string} key
	 * @returns {any}
	 * @author Marcin
	 * @method backendconfig#getBackendConfigValue
	 */
	this.getBackendConfigValue = function (key) {
		return appContext.get('user').get('backend-config')[key];
	};

	/**
	 * Returns the entire config object
	 * @method backendconfig#getbackendconfig
	 * @author Marcin
	 * @returns {object}
	 */
	this.getBackendConfig = function () {
		return appContext.get('user').get('backend-config');
	};

};

export default new BackendConfig();
