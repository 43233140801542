import { assign } from 'lodash';

/**
 * Repository service
 * @class Repository
 * @static
 */
export default function ({ entities, methods } = { entities: {}, methods: {}}) {
	assign(this, entities);
	assign(this, methods);
}
