import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import RespondentAssessmentInstance from './entity';

const RespondentAssessmentInstancesEntity = MultipleEntities.extend({
	urls,
	model: RespondentAssessmentInstance,
	idName: 'assessmentInstanceId',
	eventNamespace: 'respondent-assessment-instance',

	/**
	 * Get a new sorted collection of all AssessmentInstances.
	 *
	 * @param {object} data - Data.
	 * @returns {object} New {{#crossLink "AssessmentInstances"}}{{/crossLink}} Collection.
	 * @example
	 *        Repository.AssessmentInstances.getSorted({ name: 'ASC' });
	 */
	getSorted (data) {
		return this.retrieve('assessmentInstances', data);
	}
});

export default new RespondentAssessmentInstancesEntity();
