var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.content
    ? _c("time-content", {
        attrs: {
          format: _vm.column.dateFormat,
          time: _vm.content,
          timezone: _vm.timezone
        }
      })
    : _c("span", [_vm._v(_vm._s(_vm.emptyChar))])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }