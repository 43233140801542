<template>
	<span v-if="content">{{content}}</span>
	<span v-else>{{emptyChar}}</span>
</template>

<script>
import { respondentName } from 'service/display-name/display-name';
import jsonQuery from 'json-query';
import TableComponentMixin from './_mixin';

export default {
	mixins: [
		TableComponentMixin
	],

	computed: {
		content: ({ column, item }) => respondentName(jsonQuery(column.key, { data: item }).value)
	},

	methods: {
		sortValue: ({ itemValue }) => respondentName(itemValue)
	}
};
</script>
