import { get, toInteger } from 'lodash';
import repository from 'repository';
import { exact } from '../__helpers/getters';
import { set } from '../__helpers/mutations';
import prefixify from '../__helpers/prefixify';
import { RESPONDENT } from 'store/respondents/respondent';

const prefix = prefixify('respondentTreatment');

// getters
const DATA = 'data';
const ID = 'id';

const SET = 'set';

const SAVE_DEFAULT = 'saveDefault';
const INIT_DEFAULT = 'initDefault';
const INIT = 'init';
const CLEAR = 'clear';

export const TREATMENT = {
	DATA: prefix(DATA),
	ID: prefix(ID),

	SAVE_DEFAULT: prefix(SAVE_DEFAULT),
	INIT_DEFAULT: prefix(INIT_DEFAULT),
	INIT: prefix(INIT),
	CLEAR: prefix(CLEAR),
	SET: prefix(SET)
};

export default {
	namespaced: true,

	state: {
		[DATA]: null
	},

	getters: {
		...exact([DATA]),
		[ID]: (state, getters) => get(getters[DATA], 'id')
	},

	mutations: {
		[SET]: set()
	},

	actions: {
		[INIT_DEFAULT]: async ({ dispatch, rootGetters }) => {
			const respondent = await repository.respondent(rootGetters.userId);

			if (respondent.defaultTreatmentId) {
				await dispatch(INIT, respondent.defaultTreatmentId);
			}
		},

		[SAVE_DEFAULT]: async ({ rootGetters }, treatmentId) => {
			// TODO Use patch method when backend allows it.
			const respondent = await repository.respondent(
				rootGetters[RESPONDENT.RESPONDENT_ID]
			);
			await repository.updateRespondent(
				rootGetters[RESPONDENT.RESPONDENT_ID],
				{
					...respondent,
					respondentPassword: '',
					attributes: {},
					defaultTreatmentId: treatmentId
				}
			);
		},

		[INIT]: async ({ commit, getters }, treatmentId) => {
			if (toInteger(getters[ID]) !== toInteger(treatmentId)) {
				const treatment = await repository.getTreatment(treatmentId);
				commit(SET, treatment);
			}
		},

		[CLEAR]: ({ commit }) => {
			commit(SET, null);
		},

		[SET]: async ({ dispatch }, treatmentId) => {
			dispatch(CLEAR);
			await dispatch(INIT, treatmentId);
		}
	}
};
