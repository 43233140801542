import { userBan } from './urls';
import SingleEntity from '../shared/single-entity';
import { sync } from 'backbone';

export default SingleEntity.extend({
	url: userBan.url,
	eventNamespace: 'circle-user',
	idAttribute: 'circleUserId',

	onSync (method, model, options) {
		if (method === 'create') {
			options.type = 'PUT';
		}

		// eslint-disable-next-line prefer-rest-params
		return sync(...arguments);
	}
});
