import SimpleWebRTC from 'simplewebrtc';
import $ from 'jquery';
import store from 'store';
import attachMediaStream from 'attachmediastream';
import { get } from 'lodash';

const extendPrototypePeerAdded = () => {
	// copy/paste with custom adjustments from simpleWebRTC source code
	SimpleWebRTC.prototype.handlePeerStreamAdded = function (peer) {
		// There are ~50 websocket messages when you join a chat with 2 peers connected
		// and somehow/somewhere peer with type: "audio" drops here.
		// That's why it duplicated last video (fixed in d10408) and
		// stopped displaying all connected peers after reconnect (regression)
		// fix for an unsolved backend(?) mystery 🧙 ╰( ͡° ͜ʖ ͡° )つ──☆*:・ﾟ
		if (peer.type === 'audio') {
			return;
		}

		const container = this.getRemoteVideoContainer();
		const videoEl = attachMediaStream(peer.stream);
		const username = get(store.getters['videoChat/peerById'](peer.id), 'displayName', '');

		const videoContainer = $(
			`<div id="${this.getDomId(peer)}" class="video-chat__video-wrapper"></div>`
		).append(
			videoEl,
			`<p class="video-chat__video-username">${username}</p>`
		)[0];

		// store video element as part of peer for easy removal
		peer.videoEl = videoContainer;

		container.appendChild(videoContainer);
		this.emit('videoAdded', videoContainer, peer);

		// send our mute status to new peer if we're muted
		// currently called with a small delay because it arrives before
		// the video element is created otherwise (which happens after
		// the async setRemoteDescription-createAnswer)
		setTimeout(() => {
			if (!this.webrtc.isAudioEnabled()) {
				peer.send('mute', { name: 'audio' });
			}

			if (!this.webrtc.isVideoEnabled()) {
				peer.send('mute', { name: 'video' });
			}
		}, 250);
	};
};

export {
	extendPrototypePeerAdded
};
