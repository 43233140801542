import { Collection } from 'backbone';
import datetime from 'datetime';

const ViewCollection = Collection.extend({
	comparator: (item) => -datetime(item.get('createdAt')).toDate()
});

let viewCollection = {};

export default (basicCollection, { limit } = { limit: 5 }) => {
	const LIMIT = limit || Infinity;

	// returns first {LIMIT} models from basic collection
	const limitedModels = () => basicCollection.first(LIMIT);

	if (!(viewCollection instanceof Collection)) {
		viewCollection = new ViewCollection(limitedModels());

		viewCollection.listenTo(basicCollection, 'add', (model) => {
			viewCollection.add(model, { at: 0 });

			if (viewCollection.size() > LIMIT) {
				viewCollection.pop();
			}
		});

		viewCollection.listenTo(basicCollection, 'remove', () => {
			const model = basicCollection.at(LIMIT - 1);

			if (!!model && viewCollection.size() <= LIMIT) {
				viewCollection.add(model);
			}
		});

		viewCollection.listenTo(basicCollection, 'change', () => {
			viewCollection.sort();
			viewCollection.reset(limitedModels());
		});
	}

	return viewCollection;
};
