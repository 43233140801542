export default {
	decision: {
		url: 'backend-commons/decision/'
	},
	allDecisions: {
		url: 'backend-commons/decision/'
	},
	byRespondentId: {
		url: (respondentId) => `backend-commons/decision/?filter[respondent]=${respondentId}`
	},
	byTreatmentId: {
		url: (treatmentId) => `backend-commons/decision/?filter[treatment]=${treatmentId}`
	},
	forCurrentClinician: {
		url: 'backend-commons/decision/?hideAnswered=1&notifications=1'
	}
};
