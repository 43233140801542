import { get, isObject } from 'lodash';

export default {
	onInitialize () {
		// set treatmentId attributes on model if they're present on collection -- because b💩ckend
		if (this.collection && !this.get('treatment')) {
			this.set('treatment', get(this.collection, 'ownerId.treatment'));
		}
	},

	/**
	 * Save a message props using PATCH method
	 * @method patchSave
	 * @param {Object} obj object to patch-save
	 * @return {Object} promise
	 * @example
	 *    message.patchSave({
	 * 		answered: true,
	 * 		read: true
	 * 	});
	 */
	patchSave (obj) {
		return this.save(obj, { patch: true });
	},

	/**
	 * Get respondentId of a message
	 * @method getRespondentId
	 * @return {Number|String} respondent id
	 * @example
	 *    message.getRespondentId();
	 */
	getRespondentId () {
		const respondent = this.get('respondent');
		return isObject(respondent) ?
			respondent.id || respondent.respondentId :
			respondent;
	},

	/**
	 * Mark `answered` state of a message
	 * @method markAnswered
	 * @param {Boolean} answered
	 * @return {Object} promise
	 * @example
	 *    message.markAnswered(true);
	 */
	markAnswered (answered) {
		return this.patchSave({ answered });
	},

	/**
	 * Mark message as read
	 * @method markAsRead
	 * @return {Object} promise
	 * @example
	 *    message.markAsRead();
	 */
	markAsRead () {
		return this.patchSave({ read: true });
	}
};
