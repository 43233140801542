import moment from 'moment';

import MultipleEntities from '../shared/multiple-entities';
import urls from './urls';
import Message from './message';

/**
 * RespondentMessages Entity
 * @class RespondentMessages
 * @extends MultipleEntities
 */
const Messages = MultipleEntities.extend({
	model: Message,
	urls,
	eventNamespace: 'respondent-message',
	order: (message) => -1 * moment(message.get('sentAt')),

	getForTreatment ({ treatment }) {
		return this.retrieve('messages', { treatment });
	}
});

export default new Messages();
