<template>
	<section class="items-view__content">
		<ul class="items-view__container">
			<item-label
				v-for="(itemConfig, index) in itemsConfig"
				:key="itemConfig.key"
				:label="mt(itemConfig.label)"
			>
				<component
					:is="componentItems[index].component"
					v-bind="componentItems[index].props"
				>
					{{componentItems[index].value}}
				</component>
			</item-label>
		</ul>
	</section>
</template>

<script>
import { get, template, assign, map, isUndefined } from 'lodash';
import config from 'core/config';
import ItemLabel from './item-label';
import { RAW_ENTITIES } from 'store/general/raw-entities';
import componentFactory from '../../shared/generic-components/component-factory';

export default {
	title: 'Items list', // Will be set on mount,

	actions () {
		const tileConfig = this.config();
		const dataUrl = get(tileConfig, 'data.url', '');

		if (isUndefined(tileConfig.translate)) {
			tileConfig.translate = true;
		}

		return [[
			RAW_ENTITIES.FETCH, {
				id: this.path,
				url: template(dataUrl)(assign(
					{ ...this.$store.getters.urlParams },
					{ root: config().backendRootUrl.default }
				))
			}
		]];
	},

	components: {
		ItemLabel
	},

	data () {
		const tileConfig = this.tile.config();

		return {
			tileConfig,
			tilePath: this.tile.path
		};
	},

	computed: {
		itemsConfig: ({ tileConfig }) => get(tileConfig, 'labelItems'),
		rawItems: ({ $store, tilePath }) => get($store.getters[RAW_ENTITIES.DATA], tilePath),
		componentItems: ({ tileConfig, rawItems, itemsConfig }) =>
			map(itemsConfig, (itemConfig) => {
				const rawContent = get(rawItems, itemConfig.key, null);

				return componentFactory({ tileConfig, itemConfig, rawContent });
			})
	},

	created () {
		this.tile.setTitle(this.mt(this.tileConfig.title));
	},

	methods: {
		// Moved from 5.0 vue mixin
		mt (text, opts = {}) { // maybeTranslate
			const { override } = opts;
			const should = !isUndefined(override) ? override : this.tileConfig.translate;
			return should ? this.$t(text) : text;
		}
	}
};
</script>
