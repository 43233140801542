import SharedModel from './shared';
import { assign, every, find, forEach } from 'lodash';
import answerMixin from '../shared/answer';

export default SharedModel.extend({
	defaults: {
		config: {
			valuesCount: 1
		}
	},

	onInitialize () {
		this.selectedAnswer = null;
		forEach(this.get('tplOptions'), (option) => {
			this.answers[option.getCode()] = this.answerFactory(option);
		});
	},

	defaultAnswerCode () {
		return this.instance.getDefaultAnswerOptionCode()[0];
	},

	values () {
		const values = [];
		const answers = this.answers;

		every(answers, (answer, i) => {
			if (
				answer.isVisible() &&
				answer.isSelected() &&
				i !== this.get('ignoredOptionCode')
			) {
				const val = this.instance.isReverseScore() ?
					this.get('tplOptions')[i].getReverseValue() :
					this.get('tplOptions')[i].getValue();
				values.push(val);

				return false; // only 1 answer can be selected anyway.
			}

			return true;
		});

		return values;
	},

	answerFactory (option) {
		const answer = {
			selected: null,
			isSelected: () => answer.selected,
			select: ({ byInput } = { byInput: false }) => {
				this.set('valueSetByInput', byInput);

				if (this.selectedAnswer) {
					this.selectedAnswer.deselect();
				}

				answer.selected = true;
				this.selectedAnswer = answer;

				this.trigger('change', {
					answerCode: option.getCode(),
					newState: 'checked'
				});

				return answer;
			},

			deselect: () => {
				if (answer.selected) {
					answer.selected = false;
					this.selectedAnswer = null;
				}

				this.trigger('change', {
					answerCode: option.getCode(),
					newState: 'checked'
				});

				return answer;
			}
		};

		assign(answer, answerMixin({ answer, option }));

		if (option.getCode() === this.defaultAnswerCode()) {
			answer.select({ byInput: true });
		}

		return answer;
	},

	getResponse () {
		const response = {
			questionCode: this.get('id'),
			timeStamp: new Date().getTime(),
			responseType: 'options',
			options: {}
		};

		forEach(this.answers, (answer, i) => {

			if (answer.isSelected()) {
				const option = this.get('tplOptions')[i];
				response.options[option.getCode()] = this.instance.isReverseScore() ?
					option.getReverseValue() :
					option.getValue();
			}
		});

		return response;
	},

	setResponse (response) {
		const answers = this.answers;

		forEach(response.options, (opt, code) => {
			answers[code].select({ byInput: true });
		});
	},

	onClear () {
		const selected = find(this.answers, { selected: true });
		selected && selected.deselect();
	},

	onUnclear ({ reset } = { reset: false }) {
		if (reset === true) {
			return;
		}

		const answers = this.answers;

		if (!this.response.initialized) {
			answers[this.defaultAnswerCode()] && answers[this.defaultAnswerCode()].select();
		}
	}
});
