import Modernizr from 'modernizr';
import $ from 'jquery';
import t from 'translate';
import moment from 'moment';
import { forEach, isObject, isString } from 'lodash';

export default function () {
	(function ($) {
		$.fn.cwtimepicker = function (params = {}) {
			// native time support
			if (Modernizr.inputtypes.time) {
				const $input = this;
				const input = this[0];
				input.type = 'time';
				$input.off('blur').one('blur', function () {
					input.checkValidity();

					if (input.validity.rangeUnderflow) {
						input.value = input.min;

					} else if (input.validity.rangeOverflow) {
						input.value = input.max;
					}
				});
				const stringDate = (date) => moment(date).format('HH:mm');

				if (isObject(params)) {

					if (params.minDate) {
						input.min = stringDate(params.minDate);
					}

					if (params.maxDate) {
						input.max = stringDate(params.maxDate);
					}

					if (params.onSelect) {
						$input.on('change', function () {
							params.onSelect($(this).val());
						});
					}
				}

				if (isString(params)) {
					const fn = {
						getDate: () => {

							if (isString(input.value) && input.value.length > 0) {
								const time = input.value.split(':');
								return new Date(0, 0, 0, time[0], time[1]);
							}
						},

						setDate: (newDate) => {
							input.value = newDate ? stringDate(newDate) : null;
						}
					}[params];

					return fn.call(this, arguments[1]);
				}

				// jQuery datepicker fallback
			} else {

				if (!isObject(params)) {
					return $(this).datetimepicker.apply(this, arguments);
				}

				return this.each(function () {
					const settings = {
						closeText: t('gui.calendar.Close'),
						currentText: t('gui.calendar.time.Now'),

						timeOnlyTitle: t('gui.calendar.time.ChooseTime'),
						timeText: t('gui.calendar.time.Time'),
						hourText: t('gui.calendar.time.Hour'),
						minuteText: t('gui.calendar.time.Minute'),
						secondText: t('gui.calendar.time.Second'),

						showButtonPanel: true,
						constrainInput: true
					};

					forEach(params, (value, key) => {
						settings[key] = value;
					});

					$(this).timepicker(settings);
				});
			}
		};
	})($);
}
