import Backbone from 'backbone';
import spin from 'components/loaders/spin';
import t from 'translate';

export default Backbone.View.extend({
	className: 'form-view__loader',
	render () {
		this.$el
			.append('<p />').text(t('Processing data'))
			.append(spin());
		return this;
	}
});
