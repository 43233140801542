var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { class: _vm.labelClassName }, [
    _c("input", {
      ref: "input",
      class: _vm.inputClassName,
      attrs: { type: "radio", name: _vm.name, disabled: _vm.disabled },
      domProps: { value: _vm.valueName, checked: _vm.value == _vm.valueName },
      on: {
        change: function($event) {
          return _vm.updateValue()
        }
      }
    }),
    _vm._v(" "),
    _c(
      "span",
      {
        directives: [{ name: "translate", rawName: "v-translate" }],
        class: _vm.labelTextClassName
      },
      [_vm._v(_vm._s(_vm.label))]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }