<template>
	<span v-if="contentParsed">{{format(contentParsed)}}</span>
	<span v-else>{{emptyChar}}</span>
</template>

<script>
import { mtParse } from '../helpers/parse';
import TableComponentMixin from './_mixin';
import { sprintf } from 'sprintf';
import { isNull } from 'lodash';
import { EMPTY_CHAR } from 'lib/chars';

export default {
	mixins: [
		TableComponentMixin
	],

	computed: {
		contentParsed: ({ column, content, item, tileConfig }) =>
			mtParse({ column, content, tileTranslate: tileConfig.translate, item })
	},

	methods: {
		sortValue: ({ itemValue }) => itemValue,

		format (value) {
			return isFinite(value) && !isNull(value) ?
				sprintf(this.column.sprintfFormat, value) :
				EMPTY_CHAR;
		}
	}
};
</script>
