import SingleEntity from '../shared/single-entity';
import urls from './urls';
import { assign } from 'lodash';

export default SingleEntity.extend({
	urlRoot: urls.respondentInGroup.url,
	eventNamespace: 'respondent-in-respondent-group',
	idAttribute: 'relationId',

	initialize (rawObj) {
		// thank you b💩ckend!
		this.respondentId = rawObj.respondentId;
		this.groupId = rawObj.groupId;

		if (rawObj.respondentGroupId) {
			this.set('groupId', rawObj.respondentGroupId);
		}

		if (rawObj.group) {
			this.attributes = assign(this.attributes, rawObj.group);
		}
		this.attributes = assign(this.attributes, rawObj.group);
	}
});

