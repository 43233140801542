import repository from 'repository';
import { forEach, isArray, cloneDeep } from 'lodash';
import { update, remove, idx } from '../__helpers/mutations';
import compareParams from '../__helpers/compare-params';

export default {
	namespaced: true,
	state: {
		list: [],
		searchParams: {},
		initialParams: null,
		total: 0,
		types: [{
			id: 'CLINICIAN',
			name: 'Clinician'
		}, {
			id: 'RESPONDENT',
			name: 'Respondent'
		}]
	},
	getters: {
		count: (state) => state.list.length,
		list: (state) => state.list,
		model: (state) => state.model,
		total: (state) => state.total,
		types: (state) => state.types
	},
	mutations: {
		setList: (state, list) => {
			if (isArray(list)) {
				state.list = list;
			} else {
				state.list.push(list);
			}
		},
		setSearchParams: (state, searchParams) => {
			state.searchParams = searchParams;
		},
		setTotal: (state, total) => {
			state.total = total;
		},
		setInitialParams: (state, params) => {
			state.initialParams = params;
		},
		update: update('list'),
		remove: remove('list')
	},
	actions: {
		addRole: (context, data) => repository.addRole(data),
		deleteRole: async ({ commit, state }, id) => {
			const index = idx(state.list, id);
			const result = await repository.deleteRole(id);
			commit('remove', { index });
			return result;
		},
		getAllRoles: ({ commit }) => {
			const results = repository.roles();
			return results.then((rawResults) => {
				commit('setList', rawResults);
			});
		},
		search: ({ commit, state }, searchParams = state.searchParams) => {
			if (searchParams) {
				commit('setSearchParams', searchParams);
			}

			if (!state.initialParams) {
				// On longer lists 'start' key will be changed upon pressing 'more results' button,
				// so its value cannot be checked here.
				const initialParams = cloneDeep(searchParams);
				delete initialParams.start;
				commit('setInitialParams', initialParams);
			}

			const noFilters = compareParams(state, searchParams);

			const results = repository.searchRoles(searchParams);
			return results.then((rawResults, statusText, xhrObj) => {
				const total = xhrObj.getResponseHeader('X-Total-Count');
				commit('setTotal', total);

				if (statusText === 'success') {
					// Items in search-tile-view do not update correctly.
					// TOBE refined after CWTable removal
					if (noFilters) {
						forEach(rawResults, (item) => {
							const index = idx(state.list, item.id);

							if (index > -1) {
								commit('update', item);
							} else {
								commit('setList', item);
							}
						});
					} else {
						commit('setList', rawResults);
					}
				}

				return [results, statusText, xhrObj];
			});
		},
		updateRole: (context, data) => repository.updateRole(data)
	}
};
