import SingleEntity from '../shared/single-entity';
import urls from './urls';
import t from 'translate';

/**
 * SystemSetting
 * @class SystemSetting
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	idAttribute: 'systemSettingId',
	urlRoot: urls.systemSetting.url,
	eventNamespace: 'system-setting',

	validate: function (attrs) {
		const keys = [];
		const msgs = [];

		if (!!attrs.systemSettingMaxLength && attrs.systemSettingValue.length > attrs.systemSettingMaxLength) {
			keys.push('systemSettingValue');
			msgs.push(t('Text too long, max. {num} characters', {
				num: attrs.systemSettingMaxLength
			}));
		}

		if (!!attrs.systemSettingMinLength && attrs.systemSettingValue.length < attrs.systemSettingMinLength) {
			keys.push('systemSettingValue');
			msgs.push(t('Text too short, min. {num} characters', {
				num: attrs.systemSettingMinLength
			}));
		}

		if (keys.length) {
			return {
				key: keys,
				msg: msgs
			};
		}
	}
});

