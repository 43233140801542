var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "input",
    _vm._g(
      {
        directives: [{ name: "trim", rawName: "v-trim" }],
        staticClass: "edit-object__text",
        attrs: { type: "text", readonly: _vm.readonly },
        domProps: { value: _vm.objVal },
        on: { blur: _vm.onTextValueChange }
      },
      _vm.inputListeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }