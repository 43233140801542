<template>
	<form
		v-if="attributeValues.length"
		class="form-view"
		@submit.prevent="submit"
	>
		<edit-attribute
			v-for="item in treatmentAttributes"
			:key="item.id"
			v-model="values[item.id]"
			:attr="item"
			@input="$v.$touch()"
		/>
		<button-group>
			<submit-button
				label="Save"
				:can-submit="canSubmit"
			/>
			<plain-button
				:on-click="reset"
				label="Reset"
			/>
		</button-group>
	</form>
	<p v-else>
		{{t('No attributes available')}}
	</p>
</template>

<script>
import t from 'service/lang/translate';
import EditAttribute from 'components/edit-attribute';
import { assign, filter, find, includes, map, reduce, forEach, get } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import cwalert from 'service/cwalert';

export default {
	title: t('Edit treatment attributes'),
	actions: (tile) => [
		[
			'treatment/attributes/initForCurrentRespondent',
			{
				treatmentType: tile.config().treatmentType,
				treatmentTypeAttributes: map(tile.config().treatmentTypeAttributes, (a) => +a)
			}
		],
		['treatmentType/initAttributesOnly', { treatmentTypeId: tile.config().treatmentType }]
	],

	components: { EditAttribute },
	data ({ tile }) {
		return {
			cfg: tile.config(),
			values: {}
		};
	},

	computed: {
		allTreatmentAttributes: ({ $store }) => $store.getters['treatmentType/visibleAttributes'],
		treatmentAttributes: ({ allTreatmentAttributes, cfg }) => filter(
			allTreatmentAttributes,
			(attr) => includes(map(cfg.treatmentTypeAttributes, (a) => +a), +attr.id)
		),

		attributeValues: ({ $store }) => $store.getters['treatment/attributes/list'],
		currentTreatment: ({ $store }) => $store.getters['treatment/attributes/currentTreatment'],

		canSubmit: ({ $v }) => !$v.values.$invalid,

		dirtyValues: (
			{ attributeValues, currentTreatment, values, allTreatmentAttributes }
		) => map(values, (value, key) => assign(
			{
				treatment: currentTreatment.id,
				typeAttribute: key
			},
			find(attributeValues, (attr) => +attr.typeAttribute === +key),
			{ value },
			{ isReadonly: get(find(allTreatmentAttributes, { id: +key }), 'isReadonly') }
		))
	},
	async mounted () {
		await this.verifyAttributes();
		this.reset();
	},

	methods: {
		pristineValues () {
			return reduce(this.$store.getters['treatment/attributes/list'], (result, attr) => {
				result[+attr.typeAttribute] = attr.value;
				return result;
			}, {});
		},

		submit () {
			this.$store.dispatch(
				'treatment/attributes/saveForCurrentRespondent',
				this.dirtyValues
			).then(() => {
				cwalert.success(this.t('Success'));
			}, () => {
				cwalert.error(this.t('Error'));
			});
		},

		reset () {
			this.values = this.pristineValues();
		},
		async verifyAttributes () {
			const unsavedAttributes = [];
			forEach(this.allTreatmentAttributes, (item) => {
				if (!find(this.attributeValues, { typeAttribute: item.id }) && !item.isReadonly) {
					unsavedAttributes.push(this.$store.dispatch(
						'treatment/attributes/saveSingle',
						{
							treatment: this.currentTreatment.id,
							typeAttribute: item.id,
							value: null
						}
					));
				}
			});
			await Promise.all(unsavedAttributes);
		}
	},

	validations () {
		const requiredItems = filter(this.treatmentAttributes, { isRequired: true });
		const validateCheckbox = (value) => includes([true, 1, '1', 'true'], value);

		const values = reduce(requiredItems, (memo, item) => {
			const validation = item.type === 'CHECKBOX' ?
				{ validateCheckbox } :
				{ required };
			memo[item.id] = validation;
			return memo;
		}, {});

		return { values };
	}
};
</script>
