import EntityModel from 'repo/apps/connected-app';
import t from 'translate';
import formViewDialog from 'service/form-view-dialog';
import store from 'store';
import appContext from 'app-context';
import { Collection } from 'backbone';
import { find, reduce, get } from 'lodash';
import { TREATMENT } from 'store/treatments/rp-treatment';

export default function () {
	const appModel = new EntityModel();

	// remove already connected apps for select dialog
	const filteredCollection = new Collection(reduce(
		get(store.state.apps, 'activatedCollection.models', []),
		(acc, curr) => {
			if (!find(
				store.getters['apps/connected'],
				({ activeApp }) => activeApp.id === curr.id
			)) {
				acc.push(curr);
			}
			return acc;
		},
		[]
	));

	const formConfig = {
		name: 'name',
		model: appModel,
		successMessage: t('Successfully added'),
		onBeforeSave () {
			// because b💩ckend
			appModel.set({
				activeApp: { id: +appModel.get('_activeApp') },
				respondent: { id: store.getters['userId'] },
				treatment: { id: store.getters[TREATMENT.ID] }
			});
		},

		onAfterSave (response) {
			store.dispatch('apps/addConnection', { model: response });
			appContext.trigger('dialog:close');
		},

		fields: [{
			key: '_activeApp',
			type: 'select',
			collection: filteredCollection,
			mandatory: true,
			determineTheOnlyOption: false,
			label: t('App'),
			description: t('Select App\u2026')
		}]
	};

	const dialogConfig = {
		title: t('Connect App')
	};

	formViewDialog({ formConfig, dialogConfig });
}
