var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.viable
    ? _c("menu-toggle-button", {
        attrs: {
          "is-selected": _vm.isSelected,
          items: _vm.variants,
          name: "switch-variant",
          title: _vm.$t("Change variant")
        },
        on: { firstItemClick: _vm.selectVariant, itemClick: _vm.selectVariant },
        scopedSlots: _vm._u(
          [
            {
              key: "buttonContent",
              fn: function() {
                return [_c("icon", { attrs: { name: "copy" } })]
              },
              proxy: true
            },
            {
              key: "firstItemContent",
              fn: function() {
                return [
                  _vm._v("\n\t\t" + _vm._s(_vm.defaultVariantName) + "\n\t")
                ]
              },
              proxy: true
            },
            {
              key: "itemContent",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm.card.translate
                    ? _c(
                        "span",
                        {
                          directives: [
                            { name: "translate", rawName: "v-translate" }
                          ]
                        },
                        [_vm._v(_vm._s(item.name))]
                      )
                    : _c("span", [_vm._v(_vm._s(item.name))])
                ]
              }
            }
          ],
          null,
          false,
          1208758750
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }