import t from 'translate';
import appBar from 'app-bar';
import store from 'store';
import confirmation from 'components/confirmation/confirmation';
import cwalert from 'cwalert';
import banRedirect from '../helpers/ban-redirect';
import treatmentContextParam from 'modules/treatments/util/treatment-context-param';

const card = {
	url: [
		'treatment/:treatmentId/circle/:circleId',
		'circle/:circleId'
	],
	name: 'circle',
	title: t('Circle'),

	ctrl: ({ cardConfig, cardData, close }, { circleId }) => {
		if (banRedirect(close)) {
			return;
		}

		const limit = cardConfig.limit;

		cardData.set({ limit });

		return store.dispatch('circles/initSingle', { circleId, limit }).then(() => {
			const isOwner = store.getters['circles/currentCircle/isOwner'];
			const isMember = store.getters['circles/currentCircle/isMember'];

			if (isOwner) {
				appBar.addCardLink({
					sourceCard: card.name,
					title: t('Edit circle'),
					icon: 'edit',
					card: 'circle-edit',
					query: {
						circleId,
						...treatmentContextParam()
					}
				});
			} else if (isMember && !isOwner) {
				appBar.addButton(card.name, {
					title: t('Leave the circle'),
					icon: 'circle-leave',
					click: () => {
						confirmation({
							icon: 'circle-leave',
							warning: true,
							title: t('Leave circle'),
							message: t('Are you sure?')
						}).then(() => {
							store.dispatch('circles/currentCircle/leave', {
								userId: store.state.circles.profile.id
							}).then(() => {
								cwalert.success(t('Circle has been left'));
								close();
							});
						});
					}
				});
			}
		});
	}
};

export { card as circle };
