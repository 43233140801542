<template>
	<ul class="edit-object__rows">
		<li
			v-for="(row, index) in objVal"
			:key="index"
			class="array-row edit-object__row edit-object__row--edit"
		>
			<p class="array-row__index">
				{{index}}
			</p>
			<ul class="edit-object__fields">
				<li
					v-for="(rowVal, rowKey) in row"
					:key="rowKey"
					class="array-row__field"
				>
					<input
						v-trim
						class="array-row__text"
						type="text"
						:value="rowKey"
						:readonly="readonly"
						@blur="onRowKeyChange(index, rowKey, $event)"
						v-on="inputListeners"
					/>

					<input
						v-if="rowValType(rowVal) === 'string' || rowValType(rowVal) === 'number'"
						v-trim
						class="array-row__text"
						type="text"
						:value="rowVal"
						:readonly="readonly"
						@blur="onRowValChange(index, rowKey, $event)"
						v-on="inputListeners"
					/>

					<input
						v-if="rowValType(rowVal) === 'boolean'"
						class="array-row__array"
						type="checkbox"
						:value="rowVal"
						:checked="rowVal"
						:readonly="readonly"
						@change="onRowValChange(index, rowKey, $event)"
					/>

					<array-input
						v-if="rowValType(rowVal) === 'array'"
						class="array-row__array"
						:obj-val="rowVal"
						:readonly="readonly"
						:on-value-change="onRowArrayChange(index, rowKey)"
						:path="path"
					/>

					<button
						v-if="!readonly"
						v-translate
						class="edit-object__button--remove edit-object__button--remove-item"
						@click="removeRowValue(index, rowKey)"
					>editObject.removeItem</button>
				</li>
				<li class="array-row__field array-row__field--add">
					<label>
						<p v-translate class="add-item-description">editObject.newStringVal</p>
						<input
							v-model="newTextRowKey[index]"
							v-trim
							class="array-row__text"
							type="text"
							:readonly="readonly"
							v-on="inputListeners"
						/>
					</label>

					<input
						v-model="newTextRowValue[index]"
						v-trim
						class="array-row__text"
						type="text"
						:readonly="readonly"
						v-on="inputListeners"
					/>

					<button
						v-if="!readonly"
						v-translate
						class="edit-object__button--add edit-object__button--add-item"
						@click="addRowValue(index, 'newTextRowKey', 'newTextRowValue')"
					>editObject.addItem</button>
				</li>
				<li class="array-row__field array-row__field--add">
					<label>
						<p v-translate class="add-item-description">editObject.newBooleanVal</p>
						<input
							v-model="newBooleanRowKey[index]"
							v-trim
							class="array-row__text"
							type="text"
							:readonly="readonly"
							v-on="inputListeners"
						/>
					</label>

					<input
						v-model="newBooleanRowValue[index]"
						class="array-row__checkbox"
						type="checkbox"
						:readonly="readonly"
					/>

					<button
						v-if="!readonly"
						v-translate
						class="edit-object__button--add edit-object__button--add-item"
						@click="addRowValue(index, 'newBooleanRowKey', 'newBooleanRowValue')"
					>editObject.addTheItem</button>
				</li>
				<li class="array-row__field array-row__field--add">
					<label>
						<p v-translate class="add-item-description">editObject.newArrayVal</p>
						<input
							v-model="newArrayRowKey[index]"
							v-trim
							class="array-row__text"
							type="text"
							:readonly="readonly"
							v-on="inputListeners"
						/>
					</label>

					<array-input
						class="array-row__array"
						:obj-val="newArrayRowValue[index]"
						:readonly="readonly"
						:on-value-change="onNewArrayRowValueChange(index)"
						:path="path"
					/>

					<button
						v-if="!readonly"
						v-translate
						class="edit-object__button--add edit-object__button--add-item"
						@click="addRowValue(index, 'newArrayRowKey', 'newArrayRowValue')"
					>editObject.addTheItem</button>
				</li>
			</ul>

			<button
				v-if="!readonly"
				class="edit-object__button--remove"
				@click="removeArrayValue(index)"
			/>
		</li>
		<li class="edit-object__row edit-object__row--add">
			<label>
				<p v-translate class="add-item-description">editObject.newObjectVal</p>
				<input
					v-model="newKey"
					v-trim
					type="text"
					:readonly="readonly"
					v-on="inputListeners"
				/>
			</label>
			<input
				v-model="newValue"
				v-trim
				type="text"
				:readonly="readonly"
				v-on="inputListeners"
			/>
			<button
				v-if="!readonly"
				class="edit-object__button--add"
				@click="addObjectArrayValue"
			/>
		</li>
	</ul>
</template>

<script>
import Vue from 'vue';
import { assign, cloneDeep, fill, isUndefined, mapKeys, trim } from 'lodash';
import { valueType } from '../checks';
import inputMixin from './__input-mixin';

export default Vue.component('objectArrayInput', {
	mixins: [inputMixin],
	props: {
		objVal: {
			type: Array,
			required: true
		}
	},
	data () {
		const fillWith = (value) => fill(Array(this.objVal.length), value);
		return {
			newTextRowKey: fillWith(''),
			newTextRowValue: fillWith(''),
			newBooleanRowKey: fillWith(''),
			newBooleanRowValue: fillWith(false),
			newArrayRowKey: fillWith(''),
			newArrayRowValue: fillWith([]),
			newKey: '',
			newValue: ''
		};
	},
	methods: {
		onRowKeyChange (index, rowKey, e) {
			const row = mapKeys(this.objVal[index], (val, key) =>
				key === rowKey ? trim(e.target.value) : key);

			Vue.set(this.objVal, index, row);
			this.onValueChange({ path: this.path, value: this.objVal });
		},

		onRowValChange (index, rowKey, e) {
			if (isUndefined(this.objVal[index][rowKey])) {
				return;
			}

			const value = this.rowValType(this.objVal[index][rowKey]) === 'boolean' ?
				!JSON.parse(e.target.value) :
				trim(e.target.value);

			this.objVal[index][rowKey] = value;

			this.onValueChange({ path: this.path, value: this.objVal });
		},

		rowValType: (rowVal) => valueType(rowVal),

		addObjectArrayValue () {
			const value = cloneDeep(this.objVal);
			value.push({ [this.newKey]: this.newValue });
			this.onValueChange({ path: this.path, value });
		},

		removeArrayValue (index) {
			const value = cloneDeep(this.objVal);
			value.splice(index, 1);
			this.onValueChange({ path: this.path, value });
		},

		addRowValue (index, keyName, valueName) {
			const isArrayInput = valueName === 'newArrayRowValue';
			const objVal = cloneDeep(this.objVal);
			const value = assign({}, objVal[index], {
				[this[keyName][index]]: this[valueName][index]
			});
			objVal[index] = value;

			this.onValueChange({ path: this.path, value: objVal });

			this[keyName][index] = '';
			this[valueName][index] = isArrayInput ? [] : '';
		},

		removeRowValue (index, propKey) {
			const objVal = cloneDeep(this.objVal);
			delete objVal[index][propKey];
			this.onValueChange({ path: this.path, value: objVal });
		},

		onRowArrayChange (index, rowKey) {
			return ({ value }) => {
				this.objVal[index][rowKey] = value;
				this.onValueChange({ path: this.path, value: this.objVal });
			};
		},

		onNewArrayRowValueChange (index) {
			return ({ value }) => {
				Vue.set(this.newArrayRowValue, index, value);
			};
		}
	}
});
</script>
