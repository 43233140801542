const RESPONDENT = 'backend-commons/respondent';
const TREATMENT = 'backend-commons/treatment';
const GOAL_CATEGORY = 'backend-commons/goal-category/';

export default {
	goal: ({ respondentId = 0, goalId, includeCategory = true }) =>
		`${RESPONDENT}/${respondentId}/goal/${goalId}${includeCategory ? '?include=category' : ''}`,

	respondentGoals: (respondentId) => `${RESPONDENT}/${respondentId}/goal/?include=category`,
	treatmentGoals: (treatmentId) => `${TREATMENT}/${treatmentId}/goal/?include=category`,

	activity: ({ respondentId, goalId, activityId }) =>
		`${RESPONDENT}/${respondentId}/goal/${goalId}/activity/${activityId}`,

	goalActivities: ({ respondentId, goalId }) =>
		`${RESPONDENT}/${respondentId}/goal/${goalId}/activity/`,

	respondentActivities: (respondentId) => `${RESPONDENT}s/${respondentId}/activity/`, // 🚽ackend
	treatmentActivities: (treatmentId) => `${TREATMENT}/${treatmentId}/goal/activity/`,

	categories: GOAL_CATEGORY,
	singleCategory: { url: GOAL_CATEGORY },
	listAllCategories: { url: GOAL_CATEGORY }
};
