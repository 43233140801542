import MultipleEntities from '../shared/multiple-entities';
import RespondentAttributeDefinition from './attribute-definition';
import urls from './urls';

// Disclaimer: the name is clearly ugly. It's on purpose. Backend API and
// the whole architecture around these 'custom attributes' is really bad.
// The name is a little remainder of that.
const RespondentAttributeDefinitions = MultipleEntities.extend({
	model: RespondentAttributeDefinition,
	urls,
	eventNamespace: 'respondent-attribute-definition',
	idName: 'respondentAttributeMetaId',

	getAll () {
		return this.retrieve('attributeDefinition');
	},

	getVisible () {
		return this.where({ respondentAttributeMetaShow: true });
	}
});

export default new RespondentAttributeDefinitions();

