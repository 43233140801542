import { cwtagsNl2br } from '../../helpers/string';
import layoutAdjustment from '../../rendering/layout-adjustment';
import { compile } from 'handlebars';
import { View } from 'backbone';
import { assign, isNumber } from 'lodash';
import { QUESTION_TAG_NAME } from '../../config';
import { questionTextId } from '../shared/helpers';

export default View.extend({
	type: 'StringInput',
	initialize ({ model, assessment }) {
		assign(this, { model, assessment });

		return this.render();
	},

	tagName () {
		return this.isInMatrix() ? 'tr' : 'div';
	},

	isInMatrix () {
		const section = this.model.section();
		return section.isMatrix() && section.getChildIndex(this.model) > 0;
	},

	template () {
		const tagName = this.isInMatrix() ? 'td' : 'div';

		return compile(`
			<${tagName}
				class="
					ar-component
					string-input
					string-input--{{size}}
					{{#if visible}}ar-component--visible string-input--visible
					{{else}}ar-component--hidden string-input--hidden
					{{/if}}
			"
				${this.isInMatrix() ? 'colspan="100%"' : ''}
			>
				<${QUESTION_TAG_NAME}
					id="${questionTextId(this.model)}"
					class="ar-component__label string-input__label"
				>
					<span class="ar-component__number string-input__number"></span>
					<span class="ar-component__title string-input__title">{{{title}}}</span>
				</${QUESTION_TAG_NAME}>

				<div class="ar-component__controls string-input__controls">
					{{#if isInput}}
					<input
						type="text"
						class="ar-component__input string-input__input"
						aria-labelledby="${questionTextId(this.model)}"
					/>
					{{/if}}
					{{#if isTextarea}}<textarea
						class="ar-component__input string-input__input"
						aria-labelledby="${questionTextId(this.model)}"
					></textarea>{{/if}}
				</div>
			</${tagName}>
		`);
	},

	render () {
		this.$el
			.addClass('assessment-container assessment-string-container')
			.attr({
				'data-id': this.model.get('id'),
				'id': this.model.get('id')
			});
		this.determineVisibility();

		const tplParams = assign({}, this.model.toJSON(), {
			title: cwtagsNl2br(this.model.getQuestionText())
		});

		this.$el.html(this.template()(tplParams));
		assign(this, {
			// eslint-disable-next-line lodash/prefer-lodash-method
			$container: this.$el.find('.string-input__container'),
			// eslint-disable-next-line lodash/prefer-lodash-method
			$controls: this.$el.find('.string-input__controls'),
			// eslint-disable-next-line lodash/prefer-lodash-method
			$input: this.$el.find('.string-input__input')
		});

		this.$input.prop(
			'readonly',
			this.model.get('readonly') || this.assessment.isReadonlyMode()
		);

		layoutAdjustment(this.model, this.el);
		this.setNumbering();

		this.$input
			.val(this.model.get('val'))
			.on('change blur', () => {
				this.save();
			});
	},

	updateText () {
		this.$('.ar-component__title').html(cwtagsNl2br(this.model.getQuestionText()));
	},

	setNumbering () {
		if (isNumber(this.model.number())) {
			// eslint-disable-next-line lodash/prefer-lodash-method
			this.$el.find('.string-input__number').html(`${this.model.number()}.`);
		}
	},

	determineVisibility () {
		this.model.setVisible();

		if (!this.model.get('visible')) {
			this.$el.hide();
		}
	},

	save () {
		this.model.setText(this.$input.val());

		this.assessment.startSaving();
	},

	clear () {
		this.$el.empty();
		return this;
	},

	unclear () {
		this.render();
		return this;
	}
});
