import {
	after, assign, defer, isFunction, forEach, isNaN, isNumber, isArray, isEmpty, isObject,
	isString, isUndefined
} from 'lodash';
import { Collection, Model, View } from 'backbone';
import $, { when } from 'jquery';
import icon from 'service/icon';
import cardurl from 'cwcardurl';
import executeIfFunction from 'execute-if-function';
import store from 'store';

const tileClass = ['mini-tile', 'counter-tile'];

const tClass = (el, mod) => {
	const element = el.length ? `__${el}` : '';
	const modifier = mod ? `--${mod}` : '';
	return `${tileClass[0] + element + modifier} ${tileClass[1]}${element}${modifier}`;
};

export default View.extend({
	tagName: 'a',
	className: tClass('container'),
	enabled: true,
	acl: [],
	actions: [],

	init () {
		this.tileConfig = this.config() || {};
		this.__actions = isFunction(this.actions) ? this.actions(this) : this.actions;
		store.dispatch('addCurrentCardActions', {
			actions: this.__actions,
			source: `tile:${this.tileName()}`,
			type: 'tile'
		});

		if (isFunction(this.disabled) && this.disabled() === true) {
			$(this.el.parentNode).addClass('mini-tile--disabled');
			return false;
		}

		let resource = this.collections || this.collection || this.model;
		this.clear();
		this.initialLoading = true;

		if (!isEmpty(this.__actions)) {
			this.render = function () {
				this.setLoading();
			};
			const promises = store.state.currentCard.promises[`tile:${this.tileName()}`] || [];
			when(...promises).then(() => {
				this.redraw();
			});
		}

		if (resource) {
			if (isFunction(resource)) {
				resource = resource.call(this);
			}
			this.resource = resource;
			const loaded = this.loaded || function () {
				this.count = this.resource.size();
				this.redraw();
			};

			this.render = function () {
				this.setLoading();
				this.fetchOnRender !== true && this.resource.then(this.setLoaded.bind(this));
			};

			if (this.resource instanceof Model || this.resource instanceof Collection) {
				this.listenTo(this.resource, 'add remove change sync', loaded);
				this.fetchOnRender === true && this.resource.fetch();
				this.fetchOnRender !== true && this.resource.then(loaded.bind(this));

			} else if (isObject(this.resource) || isArray(this.resource)) {
				const whenAll = (objects, event, callback, context) => {
					const callbackWrapper = after(objects.length, callback);
					const that = context || this;

					forEach(objects, (obj) => {
						obj.once(event, callbackWrapper, that);
					});
				};

				const resources = [];
				forEach(this.resource, (rsrc) => {
					resources.push(rsrc);
					rsrc.fetch();
				});

				whenAll(resources, 'add remove change sync', loaded, this);
			}
		}
		this.listenTo(this, 'show', () => {
			$(this.box()).addClass('mini-tile counter-tile').toggleClass('disabled', !this.enabled);
		});
	},

	refresh () {
		// check if element is visible
		if (this.$el.width() === 0) {
			return;
		}

		assign(this.resource, this.resource.fetch());
		this.render();
	},

	render () {
		this.redraw();
	},

	redraw () {
		if (this.listen) {
			this.stopListening();
			this.listenToOnce(this.listen(), 'add remove change sync', () => {
				defer(() => {
					this.redraw();
				});
			});
		}

		if (this.initialLoading) {
			this.setLoaded();
			this.initialLoading = false;

		} else {
			this.$el.empty();
		}

		if (this.targetCard) {
			let params = [];

			isFunction(this.targetCard) && (params = this.targetCard.call(this));
			isString(this.targetCard) && params.push(this.targetCard);
			isArray(this.targetCard) && (params = this.targetCard);
			const url = params ? cardurl.buildUrl.apply(null, params) : false;

			if (isString(url)) {
				this.url = url;
			}
		}

		const count = isNaN(this.count) || isUndefined(this.count) ?
			'' :
			executeIfFunction(this.count, this);

		const total = isNaN(this.total) || isUndefined(this.total) ?
			'' :
			executeIfFunction(this.total, this);

		if (count > 0) {
			this.$el.removeClass('no-items');

			if (count >= this.tileConfig.warning) {
				this.$el.addClass('new-items');
			}

			if (count >= this.tileConfig.alarm) {
				this.$el.addClass('new-items important');
			}

		} else {
			this.$el.removeClass('new-items').addClass('no-items');
		}

		this.$el.append(
			`<span class="${tClass('caption')}">${executeIfFunction(this.caption, this)}</span>`
		);

		if (this.url) {
			this.$el.attr('href', this.url);

		} else {
			this.$el.removeAttr('href');
		}

		const $counterTpl = $(`<p class="${tClass('counter')}"><span class="${tClass('counter-value')}">${count}</span></p>`)
			.append(isNumber(total) ? `<span class="${tClass('total')}">/${total}</span>` : '')
			.append($(`<span class="${tClass('icon')}" />`).append(icon('angle-right')));

		this.$el.append($counterTpl);
	},

	tileName () {
		return this.__params.tileContainerModel.get('tileName');
	}
});
