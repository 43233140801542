import { isUndefined } from 'lodash';

export default ({ start, end, value }) => {
	if (isUndefined(start) || isUndefined(end) || isUndefined(value) || value === '') {
		return false;
	}
	const _start = start === '' ? -Infinity : +start;
	const _end = end === '' ? Infinity : +end;

	return value >= _start && value <= _end;
};
