import { assign, filter, findIndex, toLower } from 'lodash';
import BaseComponent from '../base-component';

const REG = /^\w/;

export default (variant) => BaseComponent.extend({
	template: `
		<div
			:class="containerClassNames"
			@keyup.27="onEscape"
			@keyup.38="onArrowUp"
			@keyup.40="onArrowDown"
		>
			<button
				tabindex="0"
				class="filter__toggle"
				:class="filterClasses"
				:ref="selfName"
				@click.prevent="toggleFilters({ label: label, toggle: !toggled})"
				@keyup="onCharPressed"
			>
				{{ label }}
				<span class="filter__toggle-number">({{ values.length }})</span>
			</button>

			<ul
				class="filter__list"
				:class="{
					'filter__list--active': toggled,
					'filter__list--dormant': !toggled
				}">
				<li
					v-for="option in values" :key="option.id"
					class="filter__list-item"
					:class="{
						'filter__list-item--active': viewValue[option.id],
						'filter__list-item--dormant': !viewValue[option.id]
					}"
				>
					<button
						type="button"
						:class="buttonClasses(option)"
						:ref="option.id"
						tabindex="-1"
						@click.prevent="optionToggle(option.id)"
						@keyup="onCharPressed"
					>
						{{ option.label }}
					</button>

					<div class="filter__remove">
						<button
							tabindex="0"
							class="filter__remove-action"
							:title="title.removeFilter"
							@click="removeFilter(option.id)"
						></button>
					</div>
				</li>
			</ul>
		</div>
	`,

	data: () => ({ variant }),

	methods: {
		buttonClasses (option) {
			return {
				'filter__action': true,
				'filter__action--active': this.viewValue[option.id],
				'filter__action--dormant': !this.viewValue[option.id]
			};
		},

		findObjIndex (id, list = null) {
			const finalList = list ? list : this.values;
			return findIndex(
				finalList,
				(single) => toLower(single.id) === toLower(id)
			);
		},

		onArrowDown () {
			if (this.focused + 1 < this.values.length) {
				this.focused++;
				this.setFocus(this.values[this.focused].id);
			}
		},

		onCharPressed (e) {
			const char = String.fromCharCode(e.keyCode);
			const valid = !!REG.test(char);

			const filtered = valid ?
				filter(
					this.values,
					(single) => toLower(single.label.charAt(0)) === toLower(char)
				) :
				[];

			if (filtered.length) {
				this.setNewFocus(filtered);
			} else if (valid) {
				this.focused = -1;
				this.setFocus(this.selfName, true);
			}
		},

		onArrowUp () {
			if (this.focused > 0) {
				this.focused--;
				this.setFocus(this.values[this.focused].id);
			} else if (this.focused === 0) {
				this.focused = -1;
				this.setFocus(this.selfName, true);
			}
		},

		optionToggle (id) {
			const changed = { [id]: !this.viewValue[id] };

			if (this.variant === 'single') {
				this.onChange(assign({}, changed));
				this.setValues(assign({}, changed));

			} else {
				this.onChange(assign(this.viewValue, changed));
				this.setValues(assign(this.viewValue, changed));
			}
		},

		removeFilter (index) {
			this.optionToggle(index);
		},

		setNewFocus (filtered) {
			const curIndex = this.focused !== -1 ?
				this.findObjIndex(this.values[this.focused].id, filtered) :
				-1;

			if (curIndex === -1 || curIndex + 1 === filtered.length) {
				this.focused = this.findObjIndex(filtered[0].id);
				this.setFocus(filtered[0].id);
			} else {
				this.focused++;
				this.setFocus(this.values[this.focused].id);
			}
		}
	},

	computed: {
		containerClassNames () {
			return {
				'filter__container': true,
				'filter__container--active': this.anySelected,
				'filter__container--dormant': !this.anySelected,
				'filter__container--multi-select': this.variant === 'multi',
				'filter__container--single-select': this.variant === 'single'
			};
		},

		filterClasses () {
			return {
				'filter__toggle--active': this.toggled,
				'filter__toggle--dormant': !this.toggled,
				'filter__toggle--sibling-active': this.anyToggled
			};
		}
	}
});
