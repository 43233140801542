/**
 * @class Repository
 */
export default {
	/**
	 * Get all Timezones
	 * @method getTimezones
	 * @return {Object} {{#crossLink "Timezones"}}{{/crossLink}}
	 * @example
	 *        Repository.getTimezones();
	 */
	getTimezones: function () {
		return this.Timezones.getAll();
	}
};
