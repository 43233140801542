<template>
	<div :class="className">
		<custom-tag :name="labelTag">
			<p v-if="keyLabel" class="edit-object__label-text">
				{{keyLabel}}
			</p>
		</custom-tag>
		<div class="edit-object__toggle-container">
			<button
				v-if="allowToggle"
				class="edit-object__toggle"
				@click="toggle"
			>{{toggleLabel}}</button>
		</div>
		<transition name="slide">
			<component
				:is="componentType"
				v-show="toggled"
				v-if="componentExists(componentType)"
				:obj-key="objKey"
				:obj-val="objVal"
				:values="values"
				:readonly="readonly"
				:on-value-change="onValueChange"
				:on-object-key-change="onObjectKeyChange"
				:config="config"
				:path="path"
				@focus="onFocus"
				@blur="onBlur"
			/>
		</transition>
	</div>
</template>

<script>
import Vue from 'vue';
import { constant, forEach, includes, isArray, isUndefined, keys, noop } from 'lodash';
import { valueType } from './checks';
import * as components from './inputs/inputs';
import t from 'translate';

export default Vue.component('edit-object', {
	components,
	props: {
		objKey: {
			type: String,
			required: true
		},
		objVal: {
			type: [String, Number, Object, Array, Boolean],
			required: true,
			default: ''
		},
		type: {
			type: String,
			required: false,
			default: ''
		},
		onChange: {
			type: Function,
			default: noop
		},
		readonly: {
			type: Boolean,
			required: false,
			default: false
		},
		label: {
			type: [String, Boolean],
			required: false,
			default: undefined
		},
		values: {
			type: Array,
			required: false,
			default: constant([])
		},
		config: {
			type: Object,
			required: false,
			default: constant({})
		},
		initialPath: {
			type: String,
			default: ''
		},
		allowToggle: {
			type: Boolean,
			default: false
		}
	},

	data: () => ({ toggled: true }),

	computed: {
		path: ({ initialPath, objKey }) => initialPath ? `${initialPath}.${objKey}` : objKey,

		className: ({ valType }) => `edit-object edit-object--${valType}`,

		keyLabel: ({ label, objKey }) => !isUndefined(label) ? label : objKey,

		valType: ({ type, objVal, values }) => {
			if (type) {
				return type;
			}

			let valType = valueType(objVal);

			if (isArray(values) && values.length) {
				valType = 'select';
			}
			return valType;
		},

		componentType: ({ valType }) => `${valType}Input`,

		labelTag: ({ valType }) => includes(['boolean'], valType) ? 'div' : 'label',

		toggleLabel: ({ toggled }) => ({
			true: t('editObject.collapse'),
			false: t('editObject.expand')
		}[toggled])
	},

	methods: {
		componentExists: (componentType) => !!components[componentType],

		onValueChange ({ path, value }) {
			this.onChange({ path, value, key: this.objKey });
		},

		onObjectKeyChange (valKey, newKey) {
			const value = {};
			forEach(keys(this.objVal), (key) => {
				value[key === valKey ? newKey : key] = this.objVal[key];
			});

			this.onValueChange({ path: this.path, value });
		},

		toggle () {
			this.toggled = !this.toggled;
		},

		onFocus (e) {
			e.target.classList.add('focused');
		},

		onBlur (e) {
			e.target.classList.remove('focused');
		}
	}
});
</script>
