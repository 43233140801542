import {
	clone, defer, find, forEach, get, includes, isArray, isFunction, keys, replace
} from 'lodash';
import { Deferred } from 'jquery';
import repository from 'repository';
import { TREATMENT_TYPE_ID } from './variant-criteria';
import acl from 'service/acl/acl';
import { CLINICIAN } from 'service/acl/checkpoints.json';
import { READ } from 'service/acl/access-levels';
import { set } from '../__helpers/mutations';
import prefixify from '../__helpers/prefixify';

const variantsHandlers = {
	[TREATMENT_TYPE_ID]: {
		action: acl.checkAccess({
			op: READ,
			checkpoint: CLINICIAN.RESPONDENTS.TREATMENTS
		}) && 'treatmentTypes/initForCurrentClinician',
		getterName: 'treatmentTypes/sortedByName',
		getterTransform: (treatmentType) => treatmentType.id
	}
};

const prefix = prefixify('engine/card');

const ACTIONS_COUNT = 'actionsCount';
const AUTO_VARIANT = 'autoVariant';
const CARD_DEFINITION = 'cardDefinition';
const CARD_NAME = 'cardName';
const CARD_URL = 'cardUrl';
const CONFIG = 'config';
const CONTEXT = 'context';
const LOADING = 'loading';
const PROMISES = 'promises';
const SEARCH_QUERY = 'searchQuery';
const TILE_CONTAINERS = 'tileContainers';
const TITLE = 'title';
const VARIANT_ACTIONS = 'variantActions';
const VARIANT_GETTERS = 'variantGetters';
const VARIANT_INDEX = 'variantIndex';

const ADD_CURRENT_CARD_ACTIONS = 'addCurrentCardActions';
const CLEAR_CURRENT_CARD = 'clearCurrentCard';
const INIT = 'init';
const RESET_TILE_CONTAINERS = 'resetTileContainers';
const SET_AUTO_VARIANT = 'setAutoVariant';
const SET_LOADING = 'setLoading';
const SET_SEARCH_QUERY = 'setSearchQuery';
const SET_TILE_CONTAINERS = 'setTileContainers';
const SET_VARIANT_INDEX = 'setVariantIndex';

export const CARD = {
	CONTEXT: prefix(CONTEXT),
	CARD_NAME: prefix(CARD_NAME),
	CARD_URL: prefix(CARD_URL),
	CONFIG: prefix(CONFIG),
	LOADING: prefix(LOADING),
	TITLE: prefix(TITLE),
	TILE_CONTAINERS: prefix(TILE_CONTAINERS),
	ACTIONS_COUNT: prefix(ACTIONS_COUNT),
	SEARCH_QUERY: prefix(SEARCH_QUERY),
	CARD_DEFINITION: prefix(CARD_DEFINITION),
	VARIANT_INDEX: prefix(VARIANT_INDEX),
	VARIANT_ACTIONS: prefix(VARIANT_ACTIONS),
	VARIANT_GETTERS: prefix(VARIANT_GETTERS),
	AUTO_VARIANT: prefix(AUTO_VARIANT),
	PROMISES: prefix(PROMISES),

	INIT: prefix(INIT),
	RESET_TILE_CONTAINERS: prefix(RESET_TILE_CONTAINERS),
	SET_TILE_CONTAINERS: prefix(SET_TILE_CONTAINERS),
	SET_LOADING: prefix(SET_LOADING),
	CLEAR_CURRENT_CARD: prefix(CLEAR_CURRENT_CARD),
	ADD_CURRENT_CARD_ACTIONS: prefix(ADD_CURRENT_CARD_ACTIONS),
	SET_SEARCH_QUERY: prefix(SET_SEARCH_QUERY),
	SET_VARIANT_INDEX: prefix(SET_VARIANT_INDEX),
	SET_AUTO_VARIANT: prefix(SET_AUTO_VARIANT)
};

export default {
	namespaced: true,
	state: {
		actions: [],
		autoVariant: false,
		cardName: '',
		cardUrl: '',
		config: {},
		context: {},
		counts: {},
		dfd: {},
		cardLoading: true,
		promises: {},
		searchQueries: {},
		[TILE_CONTAINERS]: [],
		title: '',
		variantIndex: null
	},

	getters: {
		[ACTIONS_COUNT]: (state) => {
			const count = {};

			forEach(state.actions, (actionsDefinition) => {
				const actionName = isArray(actionsDefinition.action) ?
					actionsDefinition.action[0] :
					actionsDefinition.action;

				if (!count[actionName]) {
					count[actionName] = 1;

				} else {
					count[actionName]++;
				}
			});

			return count;
		},

		[SEARCH_QUERY]: (state, getters, rootState) =>
			get(state.searchQueries, `[${rootState.cardData.cardId}]`, ''),

		// eslint-disable-next-line max-params
		[CARD_DEFINITION]: (state, getters, rootState, rootGetters) => {
			const name = getters[CARD_NAME];
			const card = find(rootGetters['engine/cards/current'], { 'card-name': name });
			return card || {};
		},

		[VARIANT_INDEX]: (state, getters) => {
			if (state.variantIndex !== null) {
				return state.variantIndex;
			}

			const name = getters[CARD_NAME];
			const variants = JSON.parse(window.localStorage.getItem('cardVariants'));
			const savedIndex = get(variants, `card:${name}`, null);
			const variantExists = get(getters[CARD_DEFINITION], `alternativeCards[${savedIndex}]`);

			return savedIndex !== null && (variantExists || state.autoVariant) ? +savedIndex : -1;
		},

		[VARIANT_ACTIONS]: (state, getters) => {
			const variants = getters.cardDefinition.alternativeCards;
			const actions = [];

			if (variants) {
				forEach(variants, (variant) => {
					forEach(keys(variant.when), (criterion) => {
						const action = get(variantsHandlers[criterion], 'action');

						if (action && !includes(actions, action)) {
							actions.push(action);
						}
					});
				});
			}

			return actions;
		},

		[VARIANT_GETTERS]: (state, getters) => {
			const variants = getters.cardDefinition.alternativeCards;
			const variantGetters = [];

			if (variants) {
				forEach(variants, (variant) => {
					forEach(keys(variant.when), (criterion) => {
						const getter = {
							[criterion]: {
								name: get(variantsHandlers[criterion], 'getterName'),
								transform: get(variantsHandlers[criterion], 'getterTransform')
							}
						};

						variantGetters.push(getter);
					});
				});
			}

			return variantGetters;
		},

		[AUTO_VARIANT]: (state) => state.autoVariant,
		[PROMISES]: (state) => state.promises,
		[TILE_CONTAINERS]: (state) => state[TILE_CONTAINERS],
		[CONTEXT]: (state) => state.context,
		[CARD_NAME]: (state) => state.cardName,
		[CARD_URL]: (state) => state.cardUrl,
		[LOADING]: (state) => state.cardLoading,
		[TITLE]: (state) => state.title,
		[CONFIG]: (state) => state.config
	},

	mutations: {
		addActionsAndPromises: (state, { source, actions, type, fireDispatch }) => {
			forEach(actions, (action) => {
				const promise = fireDispatch(isArray(action) ? action : [action]);

				if (!state.promises[source]) {
					state.promises[source] = [];
				}
				state.promises[source].push(promise);
				state.actions.push({ source, action, type });
			});
		},

		addAction: (state, { source, action, type }) => {
			state.actions.push({ source, action, type });
		},

		setDfd: (state, { actionName, reset }) => {
			if (!state.dfd[actionName] || reset) {
				state.dfd[actionName] = Deferred();
			}
		},

		addToCounts: (state, actionName) => {
			if (!state.counts[actionName]) {
				state.counts[actionName] = 1;

			} else {
				state.counts[actionName]++;
			}
		},

		resetCounts: (state, actionName) => {
			state.counts[actionName] = 0;
		},

		clear: (state) => {
			state.actions = [];
			state.promises = {};
			state.counts = {};
			state.dfd = {};
			state.variantIndex = null;
		},

		setSearchQuery: (state, { cardName, query }) => {
			state.searchQueries[cardName] = query;
		},

		setVariantIndex: (state, variantIndex) => {
			state.variantIndex = variantIndex;
		},

		setAutoVariant: (state, bool) => {
			state.autoVariant = bool;
		},

		[SET_TILE_CONTAINERS]: (state, containers) => {
			state[TILE_CONTAINERS] = containers;
		},

		init: (state, data) => {
			forEach(
				['config', 'context', 'cardName', 'cardUrl', 'loading', 'title'],
				(prop) => {
					state[prop] = data[prop];
				}
			);
		},

		setLoading: set('cardLoading')
	},

	actions: {
		[CLEAR_CURRENT_CARD]: ({ commit }) => {
			commit('clear');
		},

		[ADD_CURRENT_CARD_ACTIONS]: (
			{ commit, dispatch, getters, state },
			{ source, type, actions = [] }
		) => {
			const actionsToAdd = isFunction(actions) ? actions() : clone(actions);
			const now = (new Date()).valueOf();

			const makeDispatch = ({ action, actionName }) => {
				action[0] && dispatch(action[0], action[1], { root: true }).then(() => {
					if (!state.dfd[actionName]) {
						return;
					}

					state.dfd[actionName].resolve();
					commit('setDfd', { actionName, reset: true });
				});
			};

			const fireDispatch = (action = []) => {
				const instant = get(action[3], 'instant');
				// make sure that instant action promises are unique
				const actionName = instant ? `${action[0]}:${now}` : action[0];

				commit('setDfd', { actionName });

				const dispatchWhenViable = () => {
					if (state.counts[actionName] === getters.actionsCount[actionName]) {
						makeDispatch({ action, actionName });
					}
				};

				if (instant) {
					commit('addToCounts', replace(actionName, `:${now}`, ''));
					makeDispatch({ action, actionName });

				} else {
					setTimeout(() => {
						commit('addToCounts', actionName);
						dispatchWhenViable();
					}, 100);
				}

				return state.dfd[actionName];
			};

			if (!actions.length) {
				commit('addAction', { action: '', source, type });

			} else {
				commit(
					'addActionsAndPromises',
					{ actions: actionsToAdd, fireDispatch, source, type }
				);
			}
		},

		[SET_SEARCH_QUERY]: ({ commit, rootState }, { cardName, query }) => {
			commit('setSearchQuery', { cardName: cardName || rootState.cardData.cardId, query });
		},

		[SET_VARIANT_INDEX]: ({ commit, getters }, variantIndex) => {
			const name = getters[CARD_NAME];
			const cardVariants = JSON.parse(window.localStorage.getItem('cardVariants'));
			cardVariants[`card:${name}`] = variantIndex;

			commit('setVariantIndex', variantIndex);
			window.localStorage.setItem('cardVariants', JSON.stringify(cardVariants));

			return repository.saveCardVariants({ cardVariants });
		},

		[SET_AUTO_VARIANT]: ({ commit }, bool) => {
			commit('setAutoVariant', bool);
		},

		[SET_TILE_CONTAINERS]: ({ commit, dispatch }, containers) => {
			dispatch(RESET_TILE_CONTAINERS);

			defer(() => {
				commit(SET_TILE_CONTAINERS, containers);
			});
		},

		[RESET_TILE_CONTAINERS]: ({ commit }) => {
			commit(SET_TILE_CONTAINERS, []);
		},

		[INIT]: ({ commit, dispatch }, card) => {
			commit('init', card);
			dispatch(RESET_TILE_CONTAINERS);
			dispatch('resetCardData', {}, { root: true });
			dispatch(CLEAR_CURRENT_CARD);
		},

		[SET_LOADING]: ({ commit }, loading) => {
			commit('setLoading', loading);
		}
	}
};
