import TileView from 'tile-view';
import appUrl from 'service/app-url/app-url';
import { assign, get, isUndefined } from 'lodash';
import appBar from 'app-bar';
import AttachmentsButton from 'components/attachments-button/attachments-button';
import reportFixes from 'service/report/fixes';
import repository from 'repository';
import { warn } from 'service/log/log';
import $ from 'jquery';
import store from 'store';
import t from 'translate';
import { TREATMENT } from 'store/treatments/rp-treatment';

export default TileView.extend({
	tagName: 'section',
	features: ['ENABLE_RESPONDENT_REPORTS_PAGE'],
	className: 'report__container',
	// eslint-disable-next-line max-statements
	init () {
		const { assessmentInstanceId, reportId } = this.cardContext().toJSON();
		const { assessmentCode, reportCode } = this.config();

		let params = {};

		if (assessmentInstanceId && reportId) {
			params = { assessmentInstanceId, reportId };

		} else if (assessmentCode && reportCode) {
			assign(params, {
				assessmentCode,
				reportCode,
				treatmentId: store.getters[TREATMENT.ID]
			});

		} else {
			this.disable();
			return;
		}

		this.report = repository.getReportContent(params);
		this.report.then(async () => {
			this.setLoaded();

			if (this.report.getHTML()) {
				this.renderContent();
				this.renderAppbarButtons();

			} else {
				this.disable();
			}

		});
	},

	renderContent () {
		const paths = this.report.get('paths');
		// eslint-disable-next-line lodash/prefer-lodash-method
		const html = (`${this.report.getHTML()}`).replace(/{(\w+)?}/g, (match, key) => {
			if (isUndefined(paths[key])) {
				warn('Report html parsing: unknown variable:', key);

			} else {
				return appUrl(paths[key]);
			}
		});

		const $body = $('<div class="report__body" />').html(reportFixes(html)).appendTo(this.el);
		// eslint-disable-next-line lodash/prefer-lodash-method
		$body.find('td.sidebar').attr('valign', 'top');
	},

	renderAppbarButtons () {
		appBar.addButton(this.cardContext().get('cardId'), {
			url: appUrl(this.report.get('pdfUrl')),
			icon: 'file-pdf',
			title: t('Download pdf')
		});

		if (this.report.get('instructionFiles').length) {
			const attachmentsButton = new AttachmentsButton({
				model: this.report
			});
			appBar.add('report-content', attachmentsButton);
		}
	}
});
