import optionLabel from '../helpers/option-label';
import valueLabel from '../helpers/value-label';
import { find } from 'lodash';

const numericPips = ({
	displayValues, endLabelsVisible, getUrl, labelStep, labelsVisible, max, min, valueLabels
}) => ({
	mode: 'values',
	density: 100,
	values: displayValues,
	format: {
		to: (value) => valueLabel({ getUrl, value, valueLabels }),
		from: (value) => parseFloat(value)
	},
	filter: (value) => {
		const foundInValueLabels = find(valueLabels, (valueLabel) =>
			parseFloat(valueLabel.value) === parseFloat(value));

		if (valueLabels.length && foundInValueLabels) {
			return 1;
		}

		// show end labels
		if (value === min || value === max) {
			return +endLabelsVisible;
		}

		// do not show labels
		if (!labelsVisible) {
			return 0;
		}

		if (!labelStep) {
			return 1;

		// show labels that are dividable by `labelStep`
		} else if ((value - min) % labelStep === 0) {
			return 1;
		}
		return 0;
	}
});

const isMinOrMax = ({ value, orderedAnswers }) =>
	value === 0 || value === orderedAnswers.length - 1;

const fixedPips = ({ orderedAnswers, getUrl, endLabelsVisible, labelsVisible }) => ({
	mode: 'steps',
	density: 100,
	format: {
		to: (value) => optionLabel({
			option: orderedAnswers[value],
			getUrl,
			clickable: true
		})
	},
	filter: (value) => {
		if (isMinOrMax({ value, orderedAnswers })) {
			return +endLabelsVisible;
		}

		return +labelsVisible;
	}
});

export { fixedPips, numericPips };
