import { messageAttachment } from './urls';
import SingleEntity from '../shared/single-entity';
import fileUrl from 'service/file-url/file-url';

/**
 * MessageAttachment entity
 *
 * @class MessageAttachment
 * @extends SingleEntity
 */
export default SingleEntity.extend({
	urlRoot: messageAttachment.url,
	eventNamespace: 'message-attachment',

	getUrl: function () {
		return fileUrl(this.get('url'));
	}
});
