/**
 * Set `loading` state on the tile. Used when tile gets the essential data etc.
 *
 * @function TileView.setLoading
 * @returns {Object} - Tile object.
 * @example
 * export default TileView.extend({
 *     loaded: ({ tile }) => {
 *         tile.setLoading();
 *     }
 * });
 */
export default function () {
	this.__params.tileContainerModel.set('loading', true);
	return this;
}

